import {
  TASK_LOG_DELETE_FAIL,
  TASK_LOG_DELETE_REQUEST,
  TASK_LOG_DELETE_SUCCESS,
  TASK_LOG_INSERT_FAIL,
  TASK_LOG_INSERT_REQUEST,
  TASK_LOG_INSERT_SUCCESS,
  TASK_LOG_CONTACT_LIST_FAIL,
  TASK_LOG_CONTACT_LIST_REQUEST,
  TASK_LOG_CONTACT_LIST_SUCCESS,
  TASK_LOG_DEAL_LIST_FAIL,
  TASK_LOG_DEAL_LIST_REQUEST,
  TASK_LOG_DEAL_LIST_SUCCESS,
  TASK_LOG_UPDATE_FAIL,
  TASK_LOG_UPDATE_REQUEST,
  TASK_LOG_UPDATE_SUCCESS,
  TASK_LOG_GET_ALL_REQ,
  TASK_LOG_GET_ALL_FAIL,
  TASK_LOG_GET_ALL_SUCCESS,
  TASK_LOG_SUBAGENT_LIST_REQUEST,
  TASK_LOG_SUBAGENT_LIST_SUCCESS,
  TASK_LOG_SUBAGENT_LIST_FAIL,
} from "../Constants/taskLogConstants";
import api from "../core/api";

export const listTaskLogContact = (id) => async (dispatch) => {
  try {
    dispatch({ type: TASK_LOG_CONTACT_LIST_REQUEST });
    const { data } = await api.get(`/contact/task/${id}`);
    dispatch({
      type: TASK_LOG_CONTACT_LIST_SUCCESS,
      payload: data.Result,
    });
  } catch (error) {
    dispatch({
      type: TASK_LOG_CONTACT_LIST_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const listTaskLogDeal = (id) => async (dispatch) => {
  try {
    dispatch({ type: TASK_LOG_DEAL_LIST_REQUEST });
    const { data } = await api.get(`/tasklog/getDealRecords/${id}`);
    dispatch({
      type: TASK_LOG_DEAL_LIST_SUCCESS,
      payload: data.Result,
    });
  } catch (error) {
    dispatch({
      type: TASK_LOG_DEAL_LIST_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const listTaskLogSubAgent = (id) => async (dispatch) => {
  try {
    dispatch({ type: TASK_LOG_SUBAGENT_LIST_REQUEST });
    const { data } = await api.get(`/tasklog/getSubAgentRecords/${id}`);
    dispatch({
      type: TASK_LOG_SUBAGENT_LIST_SUCCESS,
      payload: data.Result,
    });
  } catch (error) {
  
    dispatch({
      type: TASK_LOG_SUBAGENT_LIST_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const saveTaskLogDetails = (TaskLogJson) => async (dispatch) => {
  try {
    dispatch({ type: TASK_LOG_INSERT_REQUEST });
    const { data } = await api.post(`/contact/task`, TaskLogJson);
    dispatch({
      type: TASK_LOG_INSERT_SUCCESS,
      payload: data.Result,
    });
  } catch (error) {
    dispatch({
      type: TASK_LOG_INSERT_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const updateTaskLogDetails = (id, TaskLogjson) => async (dispatch) => {
  try {
    dispatch({ type: TASK_LOG_UPDATE_REQUEST });
    const { data } = await api.put(`/contact/task/${id}`, TaskLogjson);

    dispatch({
      type: TASK_LOG_UPDATE_SUCCESS,
      payload: data.Result,
    });
  } catch (error) {
    dispatch({
      type: TASK_LOG_UPDATE_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const deleteTaskLogDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: TASK_LOG_DELETE_REQUEST });
    const { data } = await api.delete(`/contact/task/${id}`);
    dispatch({
      type: TASK_LOG_DELETE_SUCCESS,
      payload: data.Result,
    });
  } catch (error) {
    dispatch({
      type: TASK_LOG_DELETE_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const TaskLogReminder = (id) => async (dispatch) => {
  try {
    dispatch({ type: "TASK_REMINDER_REQ" });
    const { data } = await api.put(`/tasklog/reminder/${id}`);
    dispatch({
      type: "TASK_REMINDER_SUCCESS",
      payload: data.Result,
    });
  } catch (error) {
    dispatch({
      type: "TASK_REMINDER_FAIL",
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const getAllListTask = (InputJson) => async (dispatch) => {
  try {
    InputJson.page_size = +InputJson.page_size;
    InputJson.Priority = InputJson.Priority
      ? +InputJson.Priority
      : InputJson.Priority;
    dispatch({ type: TASK_LOG_GET_ALL_REQ });
    const { data } = await api.post(`/tasklog/getalltask`, InputJson);
    dispatch({
      type: TASK_LOG_GET_ALL_SUCCESS,
      payload: data.Result,
      Total: data?.Total,
    });
  } catch (error) {
    dispatch({
      type: TASK_LOG_GET_ALL_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};
