import {
  PSYCHOMETRIC_QUESTION_CREATE_FAILED,
  PSYCHOMETRIC_QUESTION_CREATE_REQ,
  PSYCHOMETRIC_QUESTION_CREATE_SUCCESS,
  PSYCHOMETRIC_QUESTION_DELETE_FAILED,
  PSYCHOMETRIC_QUESTION_DELETE_REQ,
  PSYCHOMETRIC_QUESTION_DELETE_SUCCESS,
  PSYCHOMETRIC_QUESTION_LIST_FAILED,
  PSYCHOMETRIC_QUESTION_LIST_REQ,
  PSYCHOMETRIC_QUESTION_LIST_SUCCESS,
  PSYCHOMETRIC_QUESTION_UPDATE_FAILED,
  PSYCHOMETRIC_QUESTION_UPDATE_REQ,
  PSYCHOMETRIC_QUESTION_UPDATE_SUCCESS,
  PSYCHOMETRIC_RESULT_DETAILS_FAILED,
  PSYCHOMETRIC_RESULT_DETAILS_REQ,
  PSYCHOMETRIC_RESULT_DETAILS_SUCCESS,
} from "../Constants/PsychometricQuestionConstant";
import api from "../core/api";

export const CreatePsychometricQuestion =
  (QuestionData) => async (dispatch) => {
    try {
      dispatch({ type: PSYCHOMETRIC_QUESTION_CREATE_REQ });

      const { data } = await api.post("/psychometricQuestion", QuestionData);
      dispatch({
        type: PSYCHOMETRIC_QUESTION_CREATE_SUCCESS,
        payload: data?.Result,
      });
    } catch (error) {
      dispatch({
        type: PSYCHOMETRIC_QUESTION_CREATE_FAILED,
        payload:
          error.response && error.response.data.Result
            ? error.response.data.Result
            : error.message,
      });
    }
  };

export const listPsychometricQuestion = () => async (dispatch) => {
  try {
    dispatch({ type: PSYCHOMETRIC_QUESTION_LIST_REQ });

    const { data } = await api.get("/psychometricQuestion");
    dispatch({
      type: PSYCHOMETRIC_QUESTION_LIST_SUCCESS,
      payload: data?.Result,
    });
  } catch (error) {
    dispatch({
      type: PSYCHOMETRIC_QUESTION_LIST_FAILED,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const UpdatePsychometricQuestion = (Id, Data) => async (dispatch) => {
  try {
    dispatch({ type: PSYCHOMETRIC_QUESTION_UPDATE_REQ });

    const { data } = await api.put(`/psychometricQuestion/${Id}`, Data);
    dispatch({
      type: PSYCHOMETRIC_QUESTION_UPDATE_SUCCESS,
      payload: data?.Result,
    });
  } catch (error) {
    dispatch({
      type: PSYCHOMETRIC_QUESTION_UPDATE_FAILED,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const DeletePsychometricQuestion = (Id) => async (dispatch) => {
  try {
    dispatch({ type: PSYCHOMETRIC_QUESTION_DELETE_REQ });

    const { data } = await api.delete(`/psychometricQuestion/${Id}`);
    dispatch({
      type: PSYCHOMETRIC_QUESTION_DELETE_SUCCESS,
      payload: data?.Result,
    });
  } catch (error) {
    dispatch({
      type: PSYCHOMETRIC_QUESTION_DELETE_FAILED,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const PsychometricResult = (Id) => async (dispatch) => {
  try {
    dispatch({ type: PSYCHOMETRIC_RESULT_DETAILS_REQ });

    const { data } = await api.get(`/psychometricQuestion/${Id}`);
    dispatch({
      type: PSYCHOMETRIC_RESULT_DETAILS_SUCCESS,
      payload: data?.Result,
    });
  } catch (error) {
    dispatch({
      type: PSYCHOMETRIC_RESULT_DETAILS_FAILED,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};
