export const TIMINGS_LIST_REQUEST = 'TIMINGS_LIST_REQUEST'
export const TIMINGS_LIST_SUCCESS = 'TIMINGS_LIST_SUCCESS'
export const TIMINGS_LIST_FAIL = 'TIMINGS_LIST_FAIL'

export const TIMINGS_GET_REQUEST = 'TIMINGS_GET_REQUEST'
export const TIMINGS_GET_SUCCESS = 'TIMINGS_GET_SUCCESS'
export const TIMINGS_GET_FAIL = 'TIMINGS_GET_FAIL'

export const TIMINGS_INSERT_REQUEST = 'TIMINGS_INSERT_REQUEST'
export const TIMINGS_INSERT_SUCCESS = 'TIMINGS_INSERT_SUCCESS'
export const TIMINGS_INSERT_FAIL = 'TIMINGS_INSERT_FAIL'
export const TIMINGS_INSERT_RESET = 'TIMINGS_INSERT_RESET'

export const TIMINGS_UPDATE_REQUEST = 'TIMINGS_UPDATE_REQUEST'
export const TIMINGS_UPDATE_SUCCESS = 'TIMINGS_UPDATE_SUCCESS'
export const TIMINGS_UPDATE_FAIL = 'TIMINGS_UPDATE_FAIL'
export const TIMINGS_UPDATE_RESET = 'TIMINGS_UPDATE_RESET'

export const TIMINGS_DELETE_REQUEST = 'TIMINGS_DELETE_REQUEST'
export const TIMINGS_DELETE_SUCCESS = 'TIMINGS_DELETE_SUCCESS'
export const TIMINGS_DELETE_FAIL = 'TIMINGS_DELETE_FAIL'
export const TIMINGS_DELETE_RESET = 'TIMINGS_DELETE_RESET'