export const MONTH_LIST_REQUEST = 'MONTH_LIST_REQUEST'
export const MONTH_LIST_SUCCESS = 'MONTH_LIST_SUCCESS'
export const MONTH_LIST_FAIL = 'MONTH_LIST_FAIL'

export const MONTH_INSERT_REQUEST = 'MONTH_INSERT_REQUEST'
export const MONTH_INSERT_SUCCESS = 'MONTH_INSERT_SUCCESS'
export const MONTH_INSERT_FAIL = 'MONTH_INSERT_FAIL'
export const MONTH_INSERT_RESET = 'MONTH_INSERT_RESET'

export const MONTH_UPDATE_REQUEST = 'MONTH_UPDATE_REQUEST'
export const MONTH_UPDATE_SUCCESS = 'MONTH_UPDATE_SUCCESS'
export const MONTH_UPDATE_FAIL = 'MONTH_UPDATE_FAIL'
export const MONTH_UPDATE_RESET = 'MONTH_UPDATE_RESET'

export const MONTH_DELETE_REQUEST = 'MONTH_DELETE_REQUEST'
export const MONTH_DELETE_SUCCESS = 'MONTH_DELETE_SUCCESS'
export const MONTH_DELETE_FAIL = 'MONTH_DELETE_FAIL'
export const MONTH_DELETE_RESET = 'MONTH_DELETE_RESET'
