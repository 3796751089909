export const BRANCH_LIST_REQUEST = 'BRANCH_LIST_REQUEST'
export const BRANCH_LIST_SUCCESS = 'BRANCH_LIST_SUCCESS'
export const BRANCH_LIST_FAIL = 'BRANCH_LIST_FAIL'

export const BRANCH_GET_REQUEST = 'BRANCH_GET_REQUEST'
export const BRANCH_GET_SUCCESS = 'BRANCH_GET_SUCCESS'
export const BRANCH_GET_FAIL = 'BRANCH_GET_FAIL'

export const BRANCH_INSERT_REQUEST = 'BRANCH_INSERT_REQUEST'
export const BRANCH_INSERT_SUCCESS = 'BRANCH_INSERT_SUCCESS'
export const BRANCH_INSERT_FAIL = 'BRANCH_INSERT_FAIL'
export const BRANCH_INSERT_RESET = 'BRANCH_INSERT_RESET'

export const BRANCH_UPDATE_REQUEST = 'BRANCH_UPDATE_REQUEST'
export const BRANCH_UPDATE_SUCCESS = 'BRANCH_UPDATE_SUCCESS'
export const BRANCH_UPDATE_FAIL = 'BRANCH_UPDATE_FAIL'
export const BRANCH_UPDATE_RESET = 'BRANCH_UPDATE_RESET'

export const BRANCH_DELETE_REQUEST = 'BRANCH_DELETE_REQUEST'
export const BRANCH_DELETE_SUCCESS = 'BRANCH_DELETE_SUCCESS'
export const BRANCH_DELETE_FAIL = 'BRANCH_DELETE_FAIL'
export const BRANCH_DELETE_RESET = 'BRANCH_DELETE_RESET'
