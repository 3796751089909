import {
  TIMINGS_DELETE_FAIL,
  TIMINGS_DELETE_REQUEST,
  TIMINGS_DELETE_SUCCESS,
  TIMINGS_INSERT_FAIL,
  TIMINGS_INSERT_REQUEST,
  TIMINGS_INSERT_SUCCESS,
  TIMINGS_LIST_FAIL,
  TIMINGS_LIST_REQUEST,
  TIMINGS_LIST_SUCCESS,
  TIMINGS_UPDATE_FAIL,
  TIMINGS_UPDATE_REQUEST,
  TIMINGS_UPDATE_SUCCESS,
} from "../Constants/timingsConstant";
import api from "../core/api";

export const listTimings = (Query) => async (dispatch) => {
  try {
    dispatch({
      type: TIMINGS_LIST_REQUEST,
    });
    const { data } = await api.get(
      Query
        ? `/student/timings?courseid=${Query?.courseid}&classmodeid=${
            Query?.classmodeid
          }&centerlocation=${
            Query?.centerlocation ? Query?.centerlocation : ""
          }`
        : `/timings`
    );

    var dt = data.Result;

    dispatch({
      type: TIMINGS_LIST_SUCCESS,
      payload: dt,
    });
  } catch (error) {
    dispatch({
      type: TIMINGS_LIST_FAIL,
      payload:
        error.response && error?.response?.data?.Result
          ? error?.response?.data?.Result
          : error.message,
    });
  }
};

export const createTimings = (inputJson) => async (dispatch) => {
  for (let key in inputJson) {
    if (inputJson[key] === "" || inputJson[key] === undefined) {
      inputJson[key] = null;
    }
  }
  try {
    dispatch({
      type: TIMINGS_INSERT_REQUEST,
    });
    const { data } = await api.post(`/timings`, inputJson);
    var dt = data.Result;

    dispatch({
      type: TIMINGS_INSERT_SUCCESS,
      payload: dt,
    });
  } catch (error) {
    dispatch({
      type: TIMINGS_INSERT_FAIL,
      payload:
        error.response && error?.response?.data?.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const updateTimings = (id, inputJson) => async (dispatch) => {
  try {
    dispatch({
      type: TIMINGS_UPDATE_REQUEST,
    });
    const { data } = await api.put(`/timings/${id}`, inputJson);
    var dt = data.Result;

    dispatch({
      type: TIMINGS_UPDATE_SUCCESS,
      payload: dt,
    });
  } catch (error) {
    dispatch({
      type: TIMINGS_UPDATE_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const deleteTimings = (id) => async (dispatch) => {
  try {
    dispatch({
      type: TIMINGS_DELETE_REQUEST,
    });
    const { data } = await api.delete(`/timings/${id}`);
    var dt = data.Result;

    dispatch({
      type: TIMINGS_DELETE_SUCCESS,
      payload: dt,
    });
  } catch (error) {
    dispatch({
      type: TIMINGS_DELETE_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};
