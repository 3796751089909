import { useEffect, useState } from "react";
import { LoginForm_Model, Login_Form_Validator } from "../../Models/LoginModel";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { toastOptions } from "../../Models/LocalData";
import { Link, useNavigate } from "react-router-dom";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { userLogin } from "../../Actions/userActions";

export default function Login() {
  const [LoginForm, setLoginForm] = useState(LoginForm_Model);
  const [PasswordVisible, setPasswordVissible] = useState(false);
  const { loading, userRec, error } = useSelector((state) => state.loginUsers);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleFormChange = (e) => {
    setLoginForm({ ...LoginForm, [e.target.name]: e.target.value });
  };
  
  const handleFormSubmit = (e) => {
    e.preventDefault();
    const Login_Form_Check = Login_Form_Validator(LoginForm);
    if (Login_Form_Check === "success") {
      dispatch(userLogin(LoginForm));
    } else {
      toast.error(Login_Form_Check, toastOptions);
    }
  };

  useEffect(() => {
    if (userRec) {
      navigate("/contact");
    }
  });

  useEffect(() => {
    if (userRec) {
      toast.success("Logged in successfully !", toastOptions);
      setTimeout(() => {
        navigate("/contact");
        navigate(0);
      }, 1500);
    }
    if (error) {
      toast.error(error, toastOptions);
    }
  }, [error, userRec]);

  return (
    <div>
      <div className="Login">
        <div id="particles-js"></div>
        <div className="">
          <div className="container_new">
            <form name="form1" className="box" onSubmit={handleFormSubmit}>
              <img
                style={{ width: "40%", marginBottom: "30px" }}
                src="./assets/images/Testifyi_logo1.jpg"
                alt="logo"
              />
              <h5>Sign in to your account.</h5>
              <input
                type="text"
                name="EmailAddress"
                value={LoginForm.EmailAddress}
                onChange={handleFormChange}
                placeholder="EmailAddress"
                autoComplete="off"
                required
              />
              <i className="typcn typcn-eye" id="eye"></i>
              <div style={{ position: "relative" }}>
                <input
                  type={PasswordVisible ? "text" : "password"}
                  name="Password"
                  placeholder="Passsword"
                  value={LoginForm.Password}
                  onChange={handleFormChange}
                  id="pwd"
                  autoComplete="off"
                  required
                />
                <div
                  class
                  style={
                    PasswordVisible
                      ? { display: "none" }
                      : {
                          color: "black",
                          position: "absolute",
                          right:  window.innerWidth<=480 ? "20px" :"60px",
                          top: "10px",
                        }
                  }
                  onClick={() => setPasswordVissible(true)}
                >
                  <BsEyeSlash />
                </div>
                <div
                  onClick={() => setPasswordVissible(false)}
                  style={
                    !PasswordVisible
                      ? { display: "none" }
                      : {
                          color: "black",
                          position: "absolute",
                          right: window.innerWidth<=480 ? "20px" :"60px",
                          top: "10px",
                        }
                  }
                >
                  <BsEye />
                </div>
              </div>

              <div
                className="remember"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div style={{ marginLeft: "15px" }}>
                  <input type="checkbox" />
                  <label
                    style={{
                      color: "black",
                      marginLeft: "5px",
                      fontSize: "14px",
                    }}
                  >
                    Remember me
                  </label>
                </div>
                <div>
                  <Link to="/forgotpassword" className="forgetpass">
                    Forgot Password?
                  </Link>
                </div>
              </div>

              {/*<label className="remember">
              <input type="checkbox" />
              <span></span>
              <small className="rmb">Remember me</small>
            </label>
            <a href="#" className="forgetpass">
              Forget Password?
  </a>*/}
              <input
                type="submit"
                disabled={loading}
                value={loading ? "Loading..." : "Sign in"}
                className="btn1"
              />
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
