import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { emailLogJson } from "../../../Models/EmailLogModels";
import {
  listEmailLogContact,
  listEmailLogDeal,
  listEmailLogSubagent,
  saveEmailLogDetails,
  updateEmailLogDetails,
} from "../../../Actions/emailLogActions";
import {
  EMAIL_LOG_INSERT_RESET,
  EMAIL_LOG_UPDATE_RESET,
} from "../../../Constants/emailLogConstants";
import ReactQuill from "react-quill";
import {
  getContactActivity,
  getDealActivity,
  getSubAgentActivity,
} from "../../../Actions/activityActions";
import {
  toastOptions,
  toolbarOptions,
} from "../../../Models/ToastEditiorOption";
import { toast } from "react-toastify";

export default function EmailInsert({
  emailEdit,
  contactId,
  subagentId,
  dealId,
  closePopup,
}) {
  const dispatch = useDispatch();
  const [emailData, setEmailData] = useState(emailLogJson);
  const [EmailBody, setEmailBody] = useState("");
  const { loading, error, success } = useSelector((s) => s.emailLogSave);
  const {
    loading: loadingUpdt,
    success: successUpdt,
    error: errorUpdt,
  } = useSelector((s) => s.emailLogUpdate);

  const getActivities = useCallback(() => {
    if (subagentId) {
      dispatch(getSubAgentActivity(subagentId));
    } else if (contactId) {
      dispatch(getContactActivity(contactId));
    } else {
      dispatch(getDealActivity(dealId));
    }
  }, [dispatch, contactId, dealId, subagentId]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      saveEmailLogDetails({
        ...emailData,
        EmailBody: EmailBody,
        ContactId: contactId,
        // Deal_Id: dealId,
      })
    );
  };

  const handleChange = (e) => {
    e.preventDefault();
    setEmailData({
      ...emailData,
      ContactId: contactId,
      // Deal_Id: dealId,
      // SubAgent_Id: subagentId,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (success) {
      toast.success(success, toastOptions);
      if (contactId) dispatch(listEmailLogContact(contactId));
      else if (dealId) dispatch(listEmailLogDeal(dealId));
      else if (subagentId) dispatch(listEmailLogSubagent(subagentId));

      getActivities();
      setEmailData({ ...emailLogJson });
      setEmailBody("");
      setTimeout(() => {
        dispatch({ type: EMAIL_LOG_INSERT_RESET });
        closePopup();
      }, 3000);
    }

    if (error) {
      toast.error(error, toastOptions);
    }
  }, [success, error]);

  useEffect(() => {
    if (emailEdit) {
      setEmailData(emailEdit);
      setEmailBody(emailEdit?.EmailBody);
    } else {
      setEmailData(emailLogJson);
      setEmailBody("");
    }
  }, [emailEdit]);

  // Email Log Update
  const handleEmailUpdate = (e) => {
    e.preventDefault();
    dispatch(
      updateEmailLogDetails(emailEdit?._id, {
        ...emailData,
        EmailBody: EmailBody,
      })
    );
  };

  useEffect(() => {
    if (successUpdt) {
      toast.success(successUpdt, toastOptions);
      if (contactId) dispatch(listEmailLogContact(contactId));
      else if (dealId) dispatch(listEmailLogDeal(dealId));
      else if (subagentId) dispatch(listEmailLogSubagent(subagentId));
      getActivities();
      setTimeout(() => {
        setEmailData({ ...emailLogJson });
        setEmailBody("");
        dispatch({ type: EMAIL_LOG_UPDATE_RESET });
        closePopup();
      }, 3000);
    }
    if (errorUpdt) {
      toast.error(errorUpdt, toastOptions);
    }
  }, [successUpdt, errorUpdt]);

  return (
    <>
      <div className="overlay-note-content">
        <div className="note-title">
          <h4 className="text-left text-white pl-3">
            {emailEdit ? "Update Email" : " Create Email"}
          </h4>
          <div className="closebtn">
            <Link id="hide-email-mini" className="text-right text-white"></Link>
            <Link id="hide-email-max" className="text-right text-white"></Link>
            <Link className="text-right text-white" onClick={closePopup}>
              <i className="fas fa-times"></i>
            </Link>
          </div>
        </div>
        <div className="note-body text-center" id="EmailBody">
          <div className="log-call">
            <div className="row">
              <div className="col-md-12 form-group">
                <input
                  type="text"
                  name="EmailSubject"
                  value={emailData.EmailSubject}
                  className="w-100 mb-2 py-3 display-3"
                  onChange={handleChange}
                  placeholder="Subject.."
                />
              </div>
              {window.innerWidth <= 480 ? (
                <>
                  {" "}
                  <br />
                  <br />{" "}
                </>
              ) : (
                ""
              )}
              <div style={{ marginTop: "40px" }} className="col-md-12 mt-3">
                <div style={{ padding: "0" }} className="rmv-toolbar">
                  <ReactQuill
                    placeholder={"Enter Email Message"}
                    modules={{ toolbar: toolbarOptions }}
                    name="EmailBody"
                    value={EmailBody}
                    onChange={(e) => setEmailBody(e)}
                    required
                  />
                </div>
              </div>
            </div>
          </div>

          {emailEdit ? (
            <button
              onClick={handleEmailUpdate}
              className="btn btn-sm btn-deep-orange waves-effect waves-light"
              disabled={loadingUpdt}
            >
              {loadingUpdt ? "Loading..." : "Update"}
            </button>
          ) : (
            <button
              onClick={handleSubmit}
              className="btn btn-sm btn-deep-orange waves-effect waves-light"
              disabled={loading}
            >
              {loading ? "Loading..." : "Send"}
            </button>
          )}
        </div>
        <div>
          {/* <p style={{ paddingLeft: "10px" }}>
            <span className="clrgrn">{success || successUpdt}</span>
            <span className="clrrd">{error || errorUpdt}</span>
          </p> */}
        </div>
      </div>
    </>
  );
}
