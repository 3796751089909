import {
  CALL_LOG_DELETE_FAIL,
  CALL_LOG_DELETE_REQUEST,
  CALL_LOG_DELETE_SUCCESS,
  CALL_LOG_INSERT_FAIL,
  CALL_LOG_INSERT_REQUEST,
  CALL_LOG_INSERT_SUCCESS,
  CALL_LOG_CONTACT_LIST_FAIL,
  CALL_LOG_CONTACT_LIST_REQUEST,
  CALL_LOG_CONTACT_LIST_SUCCESS,
  CALL_LOG_DEAL_LIST_FAIL,
  CALL_LOG_DEAL_LIST_REQUEST,
  CALL_LOG_DEAL_LIST_SUCCESS,
  CALL_LOG_UPDATE_FAIL,
  CALL_LOG_UPDATE_REQUEST,
  CALL_LOG_UPDATE_SUCCESS,
  CALL_LOG_SUBAGENT_LIST_REQUEST,
  CALL_LOG_SUBAGENT_LIST_SUCCESS,
  CALL_LOG_SUBAGENT_LIST_FAIL,
} from "../Constants/callLogConstants";
import api from "../core/api";

export const listCallLogContact = (id) => async (dispatch) => {
  try {
    dispatch({ type: CALL_LOG_CONTACT_LIST_REQUEST });
    const { data } = await api.get(`/contact/calllogs/${id}`);
    dispatch({
      type: CALL_LOG_CONTACT_LIST_SUCCESS,
      payload: data.Result,
    });
  } catch (error) {
    dispatch({
      type: CALL_LOG_CONTACT_LIST_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const listCallLogDeal = (id) => async (dispatch) => {
  try {
    dispatch({ type: CALL_LOG_DEAL_LIST_REQUEST });
    const { data } = await api.get(`/calllog/getDealRecords/${id}`);
    dispatch({
      type: CALL_LOG_DEAL_LIST_SUCCESS,
      payload: data.Result,
    });
  } catch (error) {
    dispatch({
      type: CALL_LOG_DEAL_LIST_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const listCallLogSubagent = (id) => async (dispatch) => {

  try {
   
    dispatch({ type: CALL_LOG_SUBAGENT_LIST_REQUEST });
    const { data } = await api.get(`/calllog/getSubAgentRecords/${id}`);

    dispatch({
      type: CALL_LOG_SUBAGENT_LIST_SUCCESS,
      payload: data.Result,
    });
  } catch (error) {
  
    dispatch({
      type: CALL_LOG_SUBAGENT_LIST_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const saveCallLogDetails = (CallLogJson) => async (dispatch) => {
  try {
    dispatch({ type: CALL_LOG_INSERT_REQUEST });
    const { data } = await api.post(`/contact/calllogs`, CallLogJson);
    
    dispatch({
      type: CALL_LOG_INSERT_SUCCESS,
      payload: data.Result,
    });
  } catch (error) {
   
    dispatch({
      type: CALL_LOG_INSERT_FAIL,
      payload:
        error.response && error?.response?.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const updateCallLogDetails = (id, CallLogjson) => async (dispatch) => {
  try {
    dispatch({ type: CALL_LOG_UPDATE_REQUEST });
    const { data } = await api.put(`/contact/calllogs/${id}`, CallLogjson);
    dispatch({
      type: CALL_LOG_UPDATE_SUCCESS,
      payload: data.Result,
    });
  } catch (error) {
    dispatch({
      type: CALL_LOG_UPDATE_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const deleteCallLogDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: CALL_LOG_DELETE_REQUEST });
    const { data } = await api.delete(`/contact/calllogs/${id}`);
    dispatch({
      type: CALL_LOG_DELETE_SUCCESS,
      payload: data.Result,
    });
  } catch (error) {
    dispatch({
      type: CALL_LOG_DELETE_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};
