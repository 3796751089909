import {
  MATERIAL_TITLE_CREATE_FAILED,
  MATERIAL_TITLE_CREATE_REQ,
  MATERIAL_TITLE_CREATE_SUCCESS,
  MATERIAL_TITLE_LIST_ALL_FAILED,
  MATERIAL_TITLE_LIST_ALL_REQ,
  MATERIAL_TITLE_LIST_ALL_SUCCESS,
  MATERIAL_TITLE_LIST_FAILED,
  MATERIAL_TITLE_LIST_REQ,
  MATERIAL_TITLE_LIST_SUCCESS,
  MATERIAL_TITLE_REMOVE_FAILED,
  MATERIAL_TITLE_REMOVE_REQ,
  MATERIAL_TITLE_REMOVE_SUCCESS,
  MATERIAL_TITLE_UPDATE_FAILED,
  MATERIAL_TITLE_UPDATE_REQ,
  MATERIAL_TITLE_UPDATE_SUCCESS,
} from "../Constants/MaterialTitleConstant";
import api from "../core/api";

export const createMaterialTitle = (MaterialData) => async (dispatch) => {
  try {
    dispatch({ type: MATERIAL_TITLE_CREATE_REQ });
    const { data } = await api.post("/material/title", MaterialData);
    dispatch({ type: MATERIAL_TITLE_CREATE_SUCCESS, payload: data?.Result });
  } catch (error) {
    dispatch({
      type: MATERIAL_TITLE_CREATE_FAILED,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const listMaterialTitle = (Id) => async (dispatch) => {
  try {
    dispatch({ type: MATERIAL_TITLE_LIST_REQ });
    const { data } = await api.get(`/material/title/course/${Id}`);
    dispatch({ type: MATERIAL_TITLE_LIST_SUCCESS, payload: data?.Result });
  } catch (error) {
    dispatch({
      type: MATERIAL_TITLE_LIST_FAILED,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const listMaterialTitleAll = () => async (dispatch) => {
  try {
    dispatch({ type: MATERIAL_TITLE_LIST_ALL_REQ });
    const { data } = await api.get(`/material/title`);

    dispatch({ type: MATERIAL_TITLE_LIST_ALL_SUCCESS, payload: data?.Result });
  } catch (error) {
    dispatch({
      type: MATERIAL_TITLE_LIST_ALL_FAILED,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const deleteMaterialTitle = (Id) => async (dispatch) => {
  try {
    dispatch({ type: MATERIAL_TITLE_REMOVE_REQ });
    const { data } = await api.delete(`/material/title/${Id}`);
    dispatch({ type: MATERIAL_TITLE_REMOVE_SUCCESS, payload: data?.Result });
  } catch (error) {
    dispatch({
      type: MATERIAL_TITLE_REMOVE_FAILED,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const updateMaterialTitle = (Id, MaterialData) => async (dispatch) => {
  try {
    dispatch({ type: MATERIAL_TITLE_UPDATE_REQ });
    const { data } = await api.put(
      `/material/title/updateTitle/${Id}`,
      MaterialData
    );
    dispatch({ type: MATERIAL_TITLE_UPDATE_SUCCESS, payload: data?.Result });
  } catch (error) {
    dispatch({
      type: MATERIAL_TITLE_UPDATE_FAILED,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};
