import {
  CONTACT_LIST_REQUEST,
  CONTACT_LIST_SUCCESS,
  CONTACT_LIST_FAIL,
  CONTACT_CREATE_REQUEST,
  CONTACT_CREATE_SUCCESS,
  CONTACT_CREATE_FAIL,
  CONTACT_UPDATE_REQUEST,
  CONTACT_UPDATE_SUCCESS,
  CONTACT_UPDATE_FAIL,
  CONTACT_GET_REQUEST,
  CONTACT_GET_FAIL,
  CONTACT_GET_SUCCESS,
  CONTACT_DET_BY_IDS_REQUEST,
  CONTACT_DET_BY_IDS_SUCCESS,
  CONTACT_DET_BY_IDS_FAIL,
  CONTACT_TOTAL_LIST_REQUEST,
  CONTACT_TOTAL_LIST_FAIL,
  CONTACT_TOTAL_LIST_SUCCESS,
} from "../Constants/contactConstants";
import api from "../core/api";

export const listContacts = (inputJson) => async (dispatch) => {
  try {
    dispatch({
      type: CONTACT_LIST_REQUEST,
    });
    const { data } = await api.get(
      `/contacts?page_size=${inputJson?.page_size}&page_no=${inputJson?.page_no}`
    );
    const res = data.Result;

    dispatch({
      type: CONTACT_LIST_SUCCESS,
      payload: res,
      total: res?.length,
    });
  } catch (error) {
    dispatch({
      type: CONTACT_LIST_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const totalContacts = () => async (dispatch) => {
  try {
    dispatch({ type: CONTACT_TOTAL_LIST_REQUEST });
    const { data } = await api.get("/contacts/total");
    
    dispatch({ type: CONTACT_TOTAL_LIST_SUCCESS, payload: data?.Result });
  } catch (error) {
    dispatch({
      type: CONTACT_TOTAL_LIST_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const createContact = (inputJson) => async (dispatch) => {
  try {
    dispatch({
      type: CONTACT_CREATE_REQUEST,
    });
    const { data } = await api.post(`/contacts`, inputJson);
    const res = data.Result;
    dispatch({
      type: CONTACT_CREATE_SUCCESS,
      payload: res,
    });
  } catch (error) {
    dispatch({
      type: CONTACT_CREATE_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const getContactDetails = (id) => async (dispatch) => {
  try {
    dispatch({
      type: CONTACT_GET_REQUEST,
    });
    const { data } = await api.get(`/contacts/${id}`);

    const res = data.Result;
    dispatch({
      type: CONTACT_GET_SUCCESS,
      payload: res,
    });
  } catch (error) {
    dispatch({
      type: CONTACT_GET_FAIL,
      payload:
        error.response && error?.response?.data?.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const updateContact = (id, inputJson) => async (dispatch) => {
  try {
    dispatch({
      type: CONTACT_UPDATE_REQUEST,
    });
    const { data } = await api.put(`/contacts/${id}`, inputJson);
    const res = data.Result;
    dispatch({
      type: CONTACT_UPDATE_SUCCESS,
      payload: res,
    });
  } catch (ex) {
    dispatch({
      type: CONTACT_UPDATE_FAIL,
      payload: "Error - " + ex.message,
    });
  }
};

export const getContactDetByIds =
  (dealId, applicationId) => async (dispatch) => {
    try {
      dispatch({ type: CONTACT_DET_BY_IDS_REQUEST });
      const { data } = await api.get(`/contacts/${dealId}/${applicationId}`);

      dispatch({ type: CONTACT_DET_BY_IDS_SUCCESS, payload: data.Result });
    } catch (error) {
      dispatch({
        type: CONTACT_DET_BY_IDS_FAIL,
        payload:
          error.response && error.response.data.Result
            ? error.response.data.Result
            : error.message,
      });
    }
  };
