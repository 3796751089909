import {
  MATERIAL_CREATE_FAILED,
  MATERIAL_CREATE_REQ,
  MATERIAL_CREATE_SUCCESS,
  MATERIAL_DETAILS_FAILED,
  MATERIAL_DETAILS_REQ,
  MATERIAL_DETAILS_SUCCESS,
  MATERIAL_LIST_FAILED,
  MATERIAL_LIST_REQ,
  MATERIAL_LIST_SUCCESS,
  MATERIAL_REMOVE_FAILED,
  MATERIAL_REMOVE_REQ,
  MATERIAL_REMOVE_SUCCESS,
  MATERIAL_TOTAL_FAILED,
  MATERIAL_TOTAL_REQ,
  MATERIAL_TOTAL_SUCCESS,
  MATERIAL_UPDATE_FAILED,
  MATERIAL_UPDATE_REQ,
  MATERIAL_UPDATE_SUCCESS,
} from "../Constants/materialConstants";
import api from "../core/api";

export const createMaterial = (MaterialData) => async (dispatch) => {
  try {
    dispatch({ type: MATERIAL_CREATE_REQ });
    const { data } = await api.post("/materials", MaterialData);

    dispatch({ type: MATERIAL_CREATE_SUCCESS, payload: data?.Result });
  } catch (ex) {
    dispatch({
      type: MATERIAL_CREATE_FAILED,
      payload:
        ex.response && ex.response.data.Result
          ? ex.response.data.Result
          : ex.message,
    });
  }
};

export const totalMaterial = () => async (dispatch) => {
  try {
    dispatch({ type: MATERIAL_TOTAL_REQ });
    const { data } = await api.get("/materials/getmaterials/total");
    dispatch({ type: MATERIAL_TOTAL_SUCCESS, payload: data?.Result });
  } catch (ex) {
    dispatch({
      type: MATERIAL_TOTAL_FAILED,
      payload:
        ex.response && ex?.response?.data?.Result
          ? ex.response.data.Result
          : ex.message,
    });
  }
};

export const listMaterial = () => async (dispatch) => {
  try {
    dispatch({ type: MATERIAL_LIST_REQ });
    const { data } = await api.get("/materials/getmaterials");

    dispatch({ type: MATERIAL_LIST_SUCCESS, payload: data?.Result });
  } catch (ex) {
    dispatch({
      type: MATERIAL_LIST_FAILED,
      payload:
        ex.response && ex?.response?.data?.Result
          ? ex.response.data.Result
          : ex.message,
    });
  }
};

export const updateMaterial = (MaterialData, Id) => async (dispatch) => {

  try {
    dispatch({ type: MATERIAL_UPDATE_REQ });
    const { data } = await api.put(`/materials/${Id}`, MaterialData);
    dispatch({ type: MATERIAL_UPDATE_SUCCESS, payload: data?.Result });
  } catch (ex) {
    dispatch({
      type: MATERIAL_UPDATE_FAILED,
      payload:
        ex.response && ex.response.data.Result
          ? ex.response.data.Result
          : ex.message,
    });
  }
};

export const removeMaterial = (Id) => async (dispatch) => {
  try {
    dispatch({ type: MATERIAL_REMOVE_REQ });
    const { data } = await api.delete(`/materials/${Id}`);
    dispatch({ type: MATERIAL_REMOVE_SUCCESS, payload: data?.Result });
  } catch (ex) {
    dispatch({
      type: MATERIAL_REMOVE_FAILED,
      payload:
        ex.response && ex.response.data.Result
          ? ex.response.data.Result
          : ex.message,
    });
  }
};
export const materialDetails = (Id) => async (dispatch) => {
  try {
    dispatch({ type: MATERIAL_DETAILS_REQ });
    const { data } = await api.get(`/materials/material/${Id}`);
    dispatch({ type: MATERIAL_DETAILS_SUCCESS, payload: data?.Result });
  } catch (ex) {
    dispatch({
      type: MATERIAL_DETAILS_FAILED,
      payload:
        ex.response && ex.response.data.Result
          ? ex.response.data.Result
          : ex.message,
    });
  }
};
