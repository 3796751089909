import {
  SUBSCRIBE_DELETE_FAIL,
  SUBSCRIBE_DELETE_REQUEST,
  SUBSCRIBE_DELETE_RESET,
  SUBSCRIBE_DELETE_SUCCESS,
  SUBSCRIBE_INSERT_FAIL,
  SUBSCRIBE_INSERT_REQUEST,
  SUBSCRIBE_INSERT_RESET,
  SUBSCRIBE_INSERT_SUCCESS,
  SUBSCRIBE_LIST_FAIL,
  SUBSCRIBE_LIST_REQUEST,
  SUBSCRIBE_LIST_SUCCESS,
  SUBSCRIBE_UPDATE_FAIL,
  SUBSCRIBE_UPDATE_REQUEST,
  SUBSCRIBE_UPDATE_RESET,
  SUBSCRIBE_UPDATE_SUCCESS,
} from "../Constants/subscribeConstants";

export const subscribeListReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case SUBSCRIBE_LIST_REQUEST:
      return { loading: true };
    case SUBSCRIBE_LIST_SUCCESS:
      return { loading: false, subscribeRecs: payload };
    case SUBSCRIBE_LIST_FAIL:
      return { loading: false, error: payload };
    default:
      return state;
  }
};

export const subscribeSaveReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case SUBSCRIBE_INSERT_REQUEST:
      return { loading: true };
    case SUBSCRIBE_INSERT_SUCCESS:
      return { loading: false, success: payload };
    case SUBSCRIBE_INSERT_FAIL:
      return { loading: false, error: payload };
    case SUBSCRIBE_INSERT_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const subscribeUpdateReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case SUBSCRIBE_UPDATE_REQUEST:
      return { loading: true };
    case SUBSCRIBE_UPDATE_SUCCESS:
      return { loading: false, success: payload };
    case SUBSCRIBE_UPDATE_FAIL:
      return { loading: false, error: payload };
    case SUBSCRIBE_UPDATE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const subscribeDeleteReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case SUBSCRIBE_DELETE_REQUEST:
      return { loading: true };
    case SUBSCRIBE_DELETE_SUCCESS:
      return { loading: false, success: payload };
    case SUBSCRIBE_DELETE_FAIL:
      return { loading: false, error: payload };
    case SUBSCRIBE_DELETE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};
