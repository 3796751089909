import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SettingLeftSection from "../Components/SettingLeftSection";
import DeletePopup from "../Components/DeletePoup";
import SidePanel from "../../Components/SidePanel";
import TopBar from "../../Components/TopBar";
import SEDLoading from "../../Components/SEDLoading";
import { MaterialTitleJson } from "../../Models/MaterialModel";
import {
  MATERIAL_TITLE_CREATE_RESET,
  MATERIAL_TITLE_REMOVE_RESET,
  MATERIAL_TITLE_UPDATE_RESET,
} from "../../Constants/MaterialTitleConstant";
import CourseDropDown from "../Components/CourseDropDown";
import {
  createMaterialTitle,
  deleteMaterialTitle,
  listMaterialTitleAll,
  updateMaterialTitle,
} from "../../Actions/MaterialTitleAction";
import { listCourses } from "../../Actions/courseAction";

export default function MaterialTitle() {
  const dispatch = useDispatch();
  const [MaterialTitleData, setMaterialTitleData] = useState(MaterialTitleJson);
  const { loading, error, MaterialTitleAllRec } = useSelector(
    (s) => s.listMaterialTitleAllReducer
  );
  const [isSubCate, setisSubCate] = useState(false);
  const [btnText, setbtnText] = useState("Save");
  const [cancelHide, setcancelHide] = useState(false);
  const [OpenNext, setOpenNext] = useState(false);
  const [DeleteAlert, setDeleteAlert] = useState({ msg: "", Delete: () => "" });

  const {
    loading: loadingsv,
    error: errorsv,
    success: successsv,
  } = useSelector((s) => s.createMaterialTitleReducer);

  const {
    loading: loadingud,
    error: errorud,
    success: successud,
  } = useSelector((s) => s.updateMaterialTitleReducer);

  const {
    loading: loadingdet,
    error: errordet,
    success: successdet,
  } = useSelector((s) => s.deleteMaterialTitleReducer);
  const { CouresesRec } = useSelector((s) => s.CourseListAllRec);
  const handleChange = (e) => {
    e.preventDefault();
    setMaterialTitleData({
      ...MaterialTitleData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (btnText == "Save") {
      dispatch(createMaterialTitle(MaterialTitleData));
    } else if (btnText == "Update") {
      dispatch(
        updateMaterialTitle(MaterialTitleData._id, {
          ...MaterialTitleData,
        })
      );
    } else alert("Error on handleSubmit");
  };

  const getMaterials = useCallback(() => {
    dispatch(listMaterialTitleAll());
  }, [dispatch]);

  useEffect(() => {
    dispatch(listCourses());
  }, []);
  const handleDelete = (e, _id) => {
    e.preventDefault();
    dispatch(deleteMaterialTitle(_id));
  };

  const handleEdit = (e, _id, MaterialTitle, CourseId, SubCategoryId) => {
    e.preventDefault();
    setbtnText("Update");
    setcancelHide(true);
    setMaterialTitleData({
      ...MaterialTitleData,
      MaterialTitle,
      CourseId,
      SubCategoryId,
      _id,
    });
  };

  const handleCancel = () => {
    setMaterialTitleData(MaterialTitleJson);
    setbtnText("Save");
    setcancelHide(false);
  };

  useEffect(() => {
    getMaterials();
  }, [getMaterials]);

  useEffect(() => {
    const CourseName = CouresesRec?.find(
      (ev) => ev?._id == MaterialTitleData?.CourseId
    )?.CourseName;

    if (CourseName?.toLowerCase() == "ielts") {
      setisSubCate("ielts");
    } else if (CourseName?.toLowerCase() == "pte") {
      setisSubCate("pte");
    } else {
      setisSubCate(false);
      setMaterialTitleData({ ...MaterialTitleData, SubCategoryId: "" });
    }
  }, [CouresesRec, MaterialTitleData?.CourseId]);

  useEffect(() => {
    if (successdet)
      setTimeout(() => {
        dispatch({ type: MATERIAL_TITLE_REMOVE_RESET });
      }, 4000);

    if (successud)
      setTimeout(() => {
        dispatch({ type: MATERIAL_TITLE_UPDATE_RESET });
      }, 4000);

    if (successsv)
      setTimeout(() => {
        dispatch({ type: MATERIAL_TITLE_CREATE_RESET });
      }, 4000);

    if (successdet || successsv || successud) {
      handleCancel();
      getMaterials();
    }
  }, [successdet, getMaterials, successsv, dispatch, successud]);

  return (
    <>
      <SidePanel page={"Material Title"} />
      <section className="home-section">
        {loading ? <SEDLoading /> : ""}
        <TopBar Heading={"Material Title"} />
        <div
          className="home-content"
          style={{ paddingTop: window.innerWidth <= 480 ? "20px" : "100px" }}
        >
          <div className="content-body">
            {/* <!--Body-content--> */}
            <div className="container-fluid">
              <div className="heading-title">
                <div className="row">
                  <div className="col-md-12">
                    <div className="home-table-search float-right d-flex">
                      {/* <!--    <button>right side</button> --> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="inner-body settings-details">
                <div className="row">
                  <div className="col-md-3">
                    <SettingLeftSection />
                  </div>

                  <div className="col-md-9">
                    <div className="body-content-with-md-9">
                      <div>
                        <div className="container">
                          <div className="row">
                            <div className="col-md-12 text-left body-content-with-md-9-title">
                              <h4>Material Title </h4>
                            </div>
                          </div>

                          <div className="row mt-3 px-5">
                            <div className="col-md-3 pt-1">
                              <label className="form-group">Course:</label>
                            </div>
                            <div className="col-md-9">
                              <select
                                className="form-control form-control-sm"
                                name="CourseId"
                                value={MaterialTitleData.CourseId}
                                onChange={(e) => handleChange(e)}
                              >
                                <option>select course</option>
                                <CourseDropDown />
                              </select>
                            </div>
                          </div>

                          {isSubCate ? (
                            <div className="row mt-3 px-5">
                              <div className="col-md-3 pt-1">
                                <label className="form-group">
                                  Sub Category:
                                </label>
                              </div>
                              <div className="col-md-9">
                                {isSubCate == "ielts" ? (
                                  <select
                                    className="form-control form-control-sm"
                                    name="SubCategoryId"
                                    value={MaterialTitleData.SubCategoryId}
                                    onChange={(e) => handleChange(e)}
                                  >
                                    <option value="">select SubCategory</option>

                                    <option value="Academic">Academic</option>
                                    <option value="General">General</option>
                                  </select>
                                ) : isSubCate == "pte" ? (
                                  <select
                                    className="form-control form-control-sm"
                                    name="SubCategoryId"
                                    value={MaterialTitleData.SubCategoryId}
                                    onChange={(e) => handleChange(e)}
                                  >
                                    <option value="">select SubCategory</option>
                                    <option value="PTE Academic">
                                      PTE Academic
                                    </option>
                                    <option value="PTE CORE">PTE CORE</option>
                                  </select>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          ) : (
                            ""
                          )}

                          <div className="row mt-3 px-5">
                            <div className="col-md-3 pt-1">
                              <label className="form-group">
                                Material Title:
                              </label>
                            </div>
                            <div className="col-md-9">
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                name="MaterialTitle"
                                placeholder="Enter Title"
                                value={MaterialTitleData.MaterialTitle}
                                onChange={(e) => handleChange(e)}
                              />
                            </div>
                          </div>

                          <div className="row">
                            <div
                              className="col-md-12"
                              style={{ textAlign: "center" }}
                            >
                              <p>
                                <a
                                  className="btn btn-deep-orange btn-md"
                                  onClick={(e) => handleSubmit(e)}
                                >
                                  {loadingsv || loadingud || loadingdet
                                    ? "Loading..."
                                    : btnText}
                                </a>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                {/* {cancelHide ? ( */}
                                <a
                                  className="btn btn-outline-deep-orange btn-md"
                                  onClick={() => handleCancel()}
                                >
                                  Cancel
                                </a>
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="container">
                          <div className="row">
                            <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 ">
                              {errordet || errorsv || errorud ? (
                                <label className="alert-danger" id="SpanError">
                                  {errordet || errorsv || errorud}
                                </label>
                              ) : (
                                ""
                              )}
                              {successdet || successud || successsv ? (
                                <label
                                  className="alert-success"
                                  id="SpanSuccess"
                                >
                                  {successdet || successud || successsv}
                                </label>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="container">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="table-header-md-9 table-responsive">
                                <table className="table table-striped table-bordered">
                                  <thead>
                                    <tr>
                                      <th>Material Title</th>
                                      <th>Course</th>
                                      <th>Sub Category</th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {MaterialTitleAllRec ? (
                                      MaterialTitleAllRec.map((dd) => (
                                        <tr key={dd._id}>
                                          <td>{dd.MaterialTitle}</td>
                                          <td>{dd?.CourseId?.CourseName}</td>
                                          <td>{dd?.SubCategoryId}</td>
                                          <td align="center">
                                            <input
                                              type="button"
                                              className="btn btn-info btn-sm"
                                              value={
                                                loadingud
                                                  ? "Loading.."
                                                  : "Update"
                                              }
                                              disabled={loadingud}
                                              onClick={(e) =>
                                                handleEdit(
                                                  e,
                                                  dd._id,
                                                  dd.MaterialTitle,
                                                  dd?.CourseId?._id,
                                                  dd?.SubCategoryId
                                                )
                                              }
                                            />
                                            &nbsp;&nbsp;&nbsp;
                                            <input
                                              type="button"
                                              className="btn btn-info btn-sm"
                                              value={
                                                loadingdet
                                                  ? "Loading.."
                                                  : "Delete"
                                              }
                                              disabled={loadingdet}
                                              onClick={(e) => {
                                                setDeleteAlert({
                                                  msg: `Do you really want to delete this material title (${dd.MaterialTitle}) ?`,
                                                  Delete: () =>
                                                    handleDelete(e, dd._id),
                                                });
                                                setOpenNext(true);
                                              }}
                                            />
                                          </td>
                                        </tr>
                                      ))
                                    ) : (
                                      <tr>
                                        <td colSpan={2}>{error}</td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!--/ body-content-with-md-9 --> */}
                  </div>
                  {/* <!--/ col-md-9 --> */}
                </div>
              </div>
            </div>
            <DeletePopup
              OpenNext={OpenNext}
              setOpenNext={setOpenNext}
              DeleteData={DeleteAlert}
            />
          </div>
        </div>
      </section>
    </>
  );
}
