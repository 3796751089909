import {
  EMAIL_TEMPLATE_CREATE_FAILED,
  EMAIL_TEMPLATE_CREATE_REQ,
  EMAIL_TEMPLATE_CREATE_SUCCESS,
  EMAIL_TEMPLATE_DETAILS_FAILED,
  EMAIL_TEMPLATE_DETAILS_REQ,
  EMAIL_TEMPLATE_DETAILS_SUCCESS,
  EMAIL_TEMPLATE_LIST_ALL_FAILED,
  EMAIL_TEMPLATE_LIST_ALL_REQ,
  EMAIL_TEMPLATE_LIST_ALL_SUCCESS,
  EMAIL_TEMPLATE_LIST_FAILED,
  EMAIL_TEMPLATE_LIST_REQ,
  EMAIL_TEMPLATE_LIST_SUCCESS,
  EMAIL_TEMPLATE_REMOVE_FAILED,
  EMAIL_TEMPLATE_REMOVE_REQ,
  EMAIL_TEMPLATE_REMOVE_SUCCESS,
  EMAIL_TEMPLATE_UPDATE_FAILED,
  EMAIL_TEMPLATE_UPDATE_REQ,
  EMAIL_TEMPLATE_UPDATE_SUCCESS,
} from "../Constants/EmailTemplateConstants";
import api from "../core/api";

export const createEmailTemplate = (EmailTemplateData) => async (dispatch) => {
  try {
    dispatch({ type: EMAIL_TEMPLATE_CREATE_REQ });
    const { data } = await api.post("/material/subtitle", EmailTemplateData);
    dispatch({ type: EMAIL_TEMPLATE_CREATE_SUCCESS, payload: data?.Result });
  } catch (error) {
    dispatch({
      type: EMAIL_TEMPLATE_CREATE_FAILED,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const listEmailTemplate = (Id) => async (dispatch) => {
  try {
    dispatch({ type: EMAIL_TEMPLATE_LIST_REQ });
    const { data } = await api.get(`/material/subtitle/${Id}`);
    dispatch({ type: EMAIL_TEMPLATE_LIST_SUCCESS, payload: data?.Result });
  } catch (error) {
    dispatch({
      type: EMAIL_TEMPLATE_LIST_FAILED,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const listEmailTemplateAll = () => async (dispatch) => {
  try {
    dispatch({ type: EMAIL_TEMPLATE_LIST_ALL_REQ });
    const { data } = await api.get(`/material/subtitle`);

    dispatch({
      type: EMAIL_TEMPLATE_LIST_ALL_SUCCESS,
      payload: data?.Result,
    });
  } catch (error) {
    dispatch({
      type: EMAIL_TEMPLATE_LIST_ALL_FAILED,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const deleteEmailTemplate = (Id) => async (dispatch) => {
  try {
    dispatch({ type: EMAIL_TEMPLATE_REMOVE_REQ });
    const { data } = await api.delete(`/material/subtitle/${Id}`);
    dispatch({ type: EMAIL_TEMPLATE_REMOVE_SUCCESS, payload: data?.Result });
  } catch (error) {
    dispatch({
      type: EMAIL_TEMPLATE_REMOVE_FAILED,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const updateEmailTemplate = (Id, MaterialData) => async (dispatch) => {
  try {
    dispatch({ type: EMAIL_TEMPLATE_UPDATE_REQ });
    const { data } = await api.put(`/material/subtitle/${Id}`, MaterialData);
    dispatch({
      type: EMAIL_TEMPLATE_UPDATE_SUCCESS,
      payload: data?.Result,
    });
  } catch (error) {
    dispatch({
      type: EMAIL_TEMPLATE_UPDATE_FAILED,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const singleEmailTemplateDetails = (Id) => async (dispatch) => {
  try {
    dispatch({ type: EMAIL_TEMPLATE_DETAILS_REQ });
    const { data } = await api.get(`/material/subtitle/getsingle/${Id}`);
    dispatch({
      type: EMAIL_TEMPLATE_DETAILS_SUCCESS,
      payload: data?.Result,
    });
  } catch (error) {
    dispatch({
      type: EMAIL_TEMPLATE_DETAILS_FAILED,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};
