export const CAMPAIGN_LIST_REQUEST = 'CAMPAIGN_LIST_REQUEST'
export const CAMPAIGN_LIST_SUCCESS = 'CAMPAIGN_LIST_SUCCESS'
export const CAMPAIGN_LIST_FAIL = 'CAMPAIGN_LIST_FAIL'

export const CAMPAIGN_GET_REQUEST = 'CAMPAIGN_GET_REQUEST'
export const CAMPAIGN_GET_SUCCESS = 'CAMPAIGN_GET_SUCCESS'
export const CAMPAIGN_GET_FAIL = 'CAMPAIGN_GET_FAIL'

export const CAMPAIGN_INSERT_REQUEST = 'CAMPAIGN_INSERT_REQUEST'
export const CAMPAIGN_INSERT_SUCCESS = 'CAMPAIGN_INSERT_SUCCESS'
export const CAMPAIGN_INSERT_FAIL = 'CAMPAIGN_INSERT_FAIL'
export const CAMPAIGN_INSERT_RESET = 'CAMPAIGN_INSERT_RESET'

export const CAMPAIGN_UPDATE_REQUEST = 'CAMPAIGN_UPDATE_REQUEST'
export const CAMPAIGN_UPDATE_SUCCESS = 'CAMPAIGN_UPDATE_SUCCESS'
export const CAMPAIGN_UPDATE_FAIL = 'CAMPAIGN_UPDATE_FAIL'
export const CAMPAIGN_UPDATE_RESET = 'CAMPAIGN_UPDATE_RESET'

export const CAMPAIGN_UPDATE_STATUS_REQUEST = 'CAMPAIGN_UPDATE_STATUS_REQUEST'
export const CAMPAIGN_UPDATE_STATUS_SUCCESS = 'CAMPAIGN_UPDATE_STATUS_SUCCESS'
export const CAMPAIGN_UPDATE_STATUS_FAIL = 'CAMPAIGN_UPDATE_STATUS_FAIL'
export const CAMPAIGN_UPDATE_STATUS_RESET = 'CAMPAIGN_UPDATE_STATUS_RESET'