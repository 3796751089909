import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { remindersTotal } from "../../Actions/taskActions";

export default function ReminderTotal() {
  const dispatch = useDispatch();

  const { loading, error, reminderTotalValue } = useSelector(
    (s) => s.taskReminderTotal
  );

  const { userInfo, exdt, userDet } = useSelector((s) => s.loginUsers);

  const getReminderTotals = () => {
    // if (userDet[0]?.Users_Id) {
    //   dispatch(remindersTotal(userDet?.Users_Id));
    // }
  };
  useEffect(() => {
    if (!reminderTotalValue) {
      getReminderTotals();
    }
  }, [dispatch, reminderTotalValue]);

  return (
    <div className="float-right" style={{ paddingTop: "3px" }}>
      <div className="reminde-task">
        <h5 style={{ color: "#000000" }}> Total</h5>{" "}
        <span
          className="badge badge-pill indigo ml-2"
          style={{
            color: "#000000",
            borderColor: "#000000",
          }}
        >
          {reminderTotalValue ? reminderTotalValue[0]?.Sum_Due_Today : "0"}
        </span>
      </div>
      <div className="reminde-task" style={{ backgroundColor: "#5D0080" }}>
        <h5> Due Today</h5>{" "}
        <span className="badge badge-pill orange ml-2">
          {reminderTotalValue ? reminderTotalValue[0]?.Sum_Total_Due : "0"}
        </span>
      </div>
      <div className="reminde-task" style={{ backgroundColor: "#02841B" }}>
        <h5> Overdue</h5>{" "}
        <span className="badge badge-pill light-blue ml-2">
          {reminderTotalValue ? reminderTotalValue[0]?.Sum_Overdue : "0"}
        </span>
      </div>
    </div>
  );
}
