import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Package_Type_Data } from "../../Actions/PacakgeAction";

const PackageTypeDropDown = React.memo(() => {
  const dispatch = useDispatch();
  const { PackageTypeRec } = useSelector((s) => s.Package_Type_Data_Reducer);

  useEffect(() => {
    dispatch(Package_Type_Data());
  }, [dispatch]);

  return PackageTypeRec ? (
    PackageTypeRec?.map((recs) => (
      <option value={recs.id} key={recs.id}>
        {recs.name}
      </option>
    ))
  ) : (
    <></>
  );
});
export default PackageTypeDropDown;
