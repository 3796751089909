import {
  UPDATE_PAYMENT_DETAILS_FAILED,
  UPDATE_PAYMENT_DETAILS_REQ,
  UPDATE_PAYMENT_DETAILS_RESET,
  UPDATE_PAYMENT_DETAILS_SUCCESS,
} from "../Constants/PaymentUpdate";

export const updatePaymentReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case UPDATE_PAYMENT_DETAILS_REQ: {
      return { loading: true };
    }

    case UPDATE_PAYMENT_DETAILS_SUCCESS: {
      return { loading: false, success: payload };
    }

    case UPDATE_PAYMENT_DETAILS_FAILED: {
      return { laoding: false, error: payload };
    }

    case UPDATE_PAYMENT_DETAILS_RESET: {
      return { loading: false, success: "" };
    }
    default:
      return state;
  }
};
