export const CREATE_PACAKGE_REQ = "CREATE_PACAKGE_REQ";
export const CREATE_PACAKGE_SUCCESS = "CREATE_PACAKGE_SUCCESS";
export const CREATE_PACAKGE_FAILED = "CREATE_PACAKGE_FAILED";
export const CREATE_PACAKGE_RESET = "CREATE_PACAKGE_RESET";

export const UPDATE_PACAKGE_REQ = "UPDATE_PACAKGE_REQ";
export const UPDATE_PACAKGE_SUCCESS = "UPDATE_PACAKGE_SUCCESS";
export const UPDATE_PACAKGE_FAILED = "UPDATE_PACAKGE_FAILED";
export const UPDATE_PACAKGE_RESET = "UPDATE_PACAKGE_RESET";

export const PACKAGE_LIST_REQ = "PACKAGE_LIST_REQ";
export const PACKAGE_LIST_SUCCESS = "PACKAGE_LIST_SUCCESS";
export const PACKAGE_LIST_FAILED = "PACKAGE_LIST_FAILED";
export const PACKAGE_LIST_RESET = "PACKAGE_LIST_RESET";

export const PACKAGE_DETAILS_REQ = "PACKAGE_DETAILS_REQ";
export const PACKAGE_DETAILS_SUCCESS = "PACKAGE_DETAILS_SUCCESS";
export const PACKAGE_DETAILS_FAILED = "PACKAGE_DETAILS_FAILED";
export const PACKAGE_DETAILS_RESET = "PACKAGE_DETAILS_RESET";

export const PACKAGE_TYPE_LIST_REQ = "PACKAGE_TYPE_LIST_REQ";
export const PACKAGE_TYPE_LIST_SUCCESS = "PACKAGE_TYPE_LIST_SUCCESS";
export const PACKAGE_TYPE_LIST_FAILED = "PACKAGE_TYPE_LIST_FAILED";

export const REMOVE_PACAKGE_REQ = "REMOVE_PACAKGE_REQ";
export const REMOVE_PACAKGE_SUCCESS = "REMOVE_PACAKGE_SUCCESS";
export const REMOVE_PACAKGE_FAILED = "REMOVE_PACAKGE_FAILED";
export const REMOVE_PACAKGE_RESET = "REMOVE_PACAKGE_RESET";
