import {
  EMAIL_LOG_DELETE_FAIL,
  EMAIL_LOG_DELETE_REQUEST,
  EMAIL_LOG_DELETE_RESET,
  EMAIL_LOG_DELETE_SUCCESS,
  EMAIL_LOG_INSERT_FAIL,
  EMAIL_LOG_INSERT_REQUEST,
  EMAIL_LOG_INSERT_RESET,
  EMAIL_LOG_INSERT_SUCCESS,
  EMAIL_LOG_CONTACT_LIST_FAIL,
  EMAIL_LOG_CONTACT_LIST_REQUEST,
  EMAIL_LOG_CONTACT_LIST_SUCCESS,
  EMAIL_LOG_DEAL_LIST_FAIL,
  EMAIL_LOG_DEAL_LIST_REQUEST,
  EMAIL_LOG_DEAL_LIST_SUCCESS,
  EMAIL_LOG_UPDATE_FAIL,
  EMAIL_LOG_UPDATE_REQUEST,
  EMAIL_LOG_UPDATE_RESET,
  EMAIL_LOG_UPDATE_SUCCESS,
  EMAIL_LOG_SUBAGENT_LIST_REQUEST,
  EMAIL_LOG_SUBAGENT_LIST_SUCCESS,
  EMAIL_LOG_SUBAGENT_LIST_FAIL,
} from "../Constants/emailLogConstants";

export const emailLogContactListReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case EMAIL_LOG_CONTACT_LIST_REQUEST:
      return { loading: true };
    case EMAIL_LOG_CONTACT_LIST_SUCCESS:
      return { loading: false, emailLogContactRec: payload };
    case EMAIL_LOG_CONTACT_LIST_FAIL:
      return { loading: false, error: payload };
    default:
      return state;
  }
};

export const emailLogDealListReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case EMAIL_LOG_DEAL_LIST_REQUEST:
      return { loading: true };
    case EMAIL_LOG_DEAL_LIST_SUCCESS:
      return { loading: false, emailLogDealRec: payload };
    case EMAIL_LOG_DEAL_LIST_FAIL:
      return { loading: false, error: payload };
    default:
      return state;
  }
};

export const emailLogSubAgentListReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case EMAIL_LOG_SUBAGENT_LIST_REQUEST:
      return { loading: true };
    case EMAIL_LOG_SUBAGENT_LIST_SUCCESS:
      return { loading: false, emailLogSubAgentRec: payload };
    case EMAIL_LOG_SUBAGENT_LIST_FAIL:
      return { loading: false, error: payload };
    default:
      return state;
  }
};

export const emailLogSaveReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case EMAIL_LOG_INSERT_REQUEST:
      return { loading: true };
    case EMAIL_LOG_INSERT_SUCCESS:
      return { loading: false, success: payload };
    case EMAIL_LOG_INSERT_FAIL:
      return { loading: false, error: payload };
    case EMAIL_LOG_INSERT_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const emailLogUpdateReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case EMAIL_LOG_UPDATE_REQUEST:
      return { loading: true };
    case EMAIL_LOG_UPDATE_SUCCESS:
      return { loading: false, success: payload };
    case EMAIL_LOG_UPDATE_FAIL:
      return { loading: false, error: payload };
    case EMAIL_LOG_UPDATE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const emailLogDeleteReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case EMAIL_LOG_DELETE_REQUEST:
      return { loading: true };
    case EMAIL_LOG_DELETE_SUCCESS:
      return { loading: false, success: payload };
    case EMAIL_LOG_DELETE_FAIL:
      return { loading: false, error: payload };
    case EMAIL_LOG_DELETE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};
