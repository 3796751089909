export const Sidebar_Reducer = (
  state = { sidebar: true },
  { type, payload }
) => {
  switch (type) {
    case "SIDEBAR_OPEN": {
      return { sidebar: payload };
    }

    default:
      return state;
  }
};
