import {
  HIERARCHY_DELETE_FAIL,
  HIERARCHY_DELETE_REQUEST,
  HIERARCHY_DELETE_SUCCESS,
  HIERARCHY_INSERT_FAIL,
  HIERARCHY_INSERT_REQUEST,
  HIERARCHY_INSERT_SUCCESS,
  HIERARCHY_LIST_FAIL,
  HIERARCHY_LIST_REQUEST,
  HIERARCHY_LIST_SUCCESS,
  HIERARCHY_UPDATE_FAIL,
  HIERARCHY_UPDATE_REQUEST,
  HIERARCHY_UPDATE_SUCCESS
} from "../Constants/HierarchyConstants";
import api from "../core/api";

export const listHierarchy = () => async (dispatch) => {
  try {
    dispatch({ type: HIERARCHY_LIST_REQUEST });
    const { data } = await api.get(`/hierarchy`);
    dispatch({
      type: HIERARCHY_LIST_SUCCESS,
      payload: data.Result,
    });
  } catch (error) {
    dispatch({
      type: HIERARCHY_LIST_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const saveHierarchyDetails =
  (inputJson) => async (dispatch) => {
    try {
      dispatch({ type: HIERARCHY_INSERT_REQUEST });
      const { data } = await api.post(`/hierarchy`, inputJson);
      dispatch({
        type: HIERARCHY_INSERT_SUCCESS,
        payload: data.Result,
      });
    } catch (error) {
      dispatch({
        type: HIERARCHY_INSERT_FAIL,
        payload:
          error.response && error.response.data.Result
            ? error.response.data.Result
            : error.message,
      });
    }
  };

export const updateHierarchyDetails =
  (id, inputJson) => async (dispatch) => {
    try {
      dispatch({ type: HIERARCHY_UPDATE_REQUEST });
      const { data } = await api.put(`/hierarchy/${id}`, inputJson);
      dispatch({
        type: HIERARCHY_UPDATE_SUCCESS,
        payload: data.Result,
      });
    } catch (error) {
      dispatch({
        type: HIERARCHY_UPDATE_FAIL,
        payload:
          error.response && error.response.data.Result
            ? error.response.data.Result
            : error.message,
      });
    }
  };

export const deleteHierarchyDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: HIERARCHY_DELETE_REQUEST });
    const { data } = await api.delete(`/hierarchy/${id}`);
    dispatch({
      type: HIERARCHY_DELETE_SUCCESS,
      payload: data.Result,
    });
  } catch (error) {
    dispatch({
      type: HIERARCHY_DELETE_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};
