import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listClassCenter } from "../../Actions/classCenterAction";

const ClassCenterDropDown = React.memo(() => {
  const dispatch = useDispatch();
  const { classCenterRecs } = useSelector((s) => s.classCenterList);
  useEffect(() => {
    dispatch(listClassCenter());
  }, [dispatch]);
  return classCenterRecs ? (
    classCenterRecs?.map((recs) => (
      <option value={recs._id} key={recs._id}>
        {recs.classCenter_Name}
      </option>
    ))
  ) : (
    <></>
  );
});

export default ClassCenterDropDown;
