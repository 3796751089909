import {
  OFFER_ACCEPTED_DELETE_FAIL,
  OFFER_ACCEPTED_DELETE_REQUEST,
  OFFER_ACCEPTED_DELETE_RESET,
  OFFER_ACCEPTED_DELETE_SUCCESS,
  OFFER_ACCEPTED_INSERT_FAIL,
  OFFER_ACCEPTED_INSERT_REQUEST,
  OFFER_ACCEPTED_INSERT_RESET,
  OFFER_ACCEPTED_INSERT_SUCCESS,
  OFFER_ACCEPTED_LIST_FAIL,
  OFFER_ACCEPTED_LIST_REQUEST,
  OFFER_ACCEPTED_LIST_SUCCESS,
  OFFER_ACCEPTED_UPDATE_FAIL,
  OFFER_ACCEPTED_UPDATE_REQUEST,
  OFFER_ACCEPTED_UPDATE_RESET,
  OFFER_ACCEPTED_UPDATE_SUCCESS,
} from "../Constants/OfferAcceptedConstants";

export const offerAcceptedListReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case OFFER_ACCEPTED_LIST_REQUEST:
      return { loading: true };
    case OFFER_ACCEPTED_LIST_SUCCESS:
      return { loading: false, offerAcceptedRecs: payload };
    case OFFER_ACCEPTED_LIST_FAIL:
      return { loading: false, error: payload };
    default:
      return state;
  }
};

export const offerAcceptedSaveReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case OFFER_ACCEPTED_INSERT_REQUEST:
      return { loading: true };
    case OFFER_ACCEPTED_INSERT_SUCCESS:
      return { loading: false, success: payload };
    case OFFER_ACCEPTED_INSERT_FAIL:
      return { loading: false, error: payload };
    case OFFER_ACCEPTED_INSERT_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const offerAcceptedUpdateReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case OFFER_ACCEPTED_UPDATE_REQUEST:
      return { loading: true };
    case OFFER_ACCEPTED_UPDATE_SUCCESS:
      return { loading: false, success: payload };
    case OFFER_ACCEPTED_UPDATE_FAIL:
      return { loading: false, error: payload };
    case OFFER_ACCEPTED_UPDATE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const offerAcceptedDeleteReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case OFFER_ACCEPTED_DELETE_REQUEST:
      return { loading: true };
    case OFFER_ACCEPTED_DELETE_SUCCESS:
      return { loading: false, success: payload };
    case OFFER_ACCEPTED_DELETE_FAIL:
      return { loading: false, error: payload };
    case OFFER_ACCEPTED_DELETE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};
