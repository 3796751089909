import {
  MONTH_LIST_REQUEST,
  MONTH_LIST_SUCCESS,
  MONTH_LIST_FAIL,
  MONTH_INSERT_REQUEST,
  MONTH_INSERT_SUCCESS,
  MONTH_INSERT_FAIL,
  MONTH_UPDATE_REQUEST,
  MONTH_UPDATE_SUCCESS,
  MONTH_UPDATE_FAIL,
  MONTH_DELETE_REQUEST,
  MONTH_DELETE_SUCCESS,
  MONTH_DELETE_FAIL,
  MONTH_INSERT_RESET,
  MONTH_UPDATE_RESET,
  MONTH_DELETE_RESET,
} from "../Constants/monthConstants";

export const monthListReducer = (state = { months: [] }, action) => {
  switch (action.type) {
    case MONTH_LIST_REQUEST:
      return { loading: true };
    case MONTH_LIST_SUCCESS:
      return { loading: false, monthRecs: action.payload };
    case MONTH_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const monthInsertReducer = (state = {}, action) => {
  switch (action.type) {
    case MONTH_INSERT_REQUEST:
      return { loading: true };
    case MONTH_INSERT_SUCCESS:
      return { loading: false, success: action.payload };
    case MONTH_INSERT_FAIL:
      return { loading: false, error: action.payload };
    case MONTH_INSERT_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const monthUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case MONTH_UPDATE_REQUEST:
      return { loading: true };
    case MONTH_UPDATE_SUCCESS:
      return { loading: false, success: action.payload };
    case MONTH_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case MONTH_UPDATE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const monthDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case MONTH_DELETE_REQUEST:
      return { loading: true };
    case MONTH_DELETE_SUCCESS:
      return { loading: false, success: action.payload };
    case MONTH_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case MONTH_DELETE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};
