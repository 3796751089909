export const EMAIL_INSERT_REQUEST = "EMAIL_INSERT_REQUEST";
export const EMAIL_INSERT_SUCCESS = "EMAIL_INSERT_SUCCESS";
export const EMAIL_INSERT_FAIL = "EMAIL_INSERT_FAIL";
export const EMAIL_INSERT_RESET = "EMAIL_INSERT_RESET";

export const EMAIL_MESSAGE_INSERT_REQUEST = "EMAIL_MESSAGE_INSERT_REQUEST";
export const EMAIL_MESSAGE_INSERT_SUCCESS = "EMAIL_MESSAGE_INSERT_SUCCESS";
export const EMAIL_MESSAGE_INSERT_FAILED = "EMAIL_MESSAGE_INSERT_FAILED";
export const EMAIL_MESSAGE_INSERT_RESET = "EMAIL_MESSAGE_INSERT_RESET";

export const EMAIL_MESSAGE_LIST_REQUEST = "EMAIL_MESSAGE_INSERT_REQUEST";
export const EMAIL_MESSAGE_LIST_SUCCESS = "EMAIL_MESSAGE_LIST_SUCCESS";
export const EMAIL_MESSAGE_LIST_FAILED = "EMAIL_MESSAGE_LIST_FAILED";
