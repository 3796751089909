export const DEFAULT_BRANCH_USER_LIST_REQUEST =
  "DEFAULT_BRANCH_USER_LIST_REQUEST";
export const DEFAULT_BRANCH_USER_LIST_SUCCESS =
  "DEFAULT_BRANCH_USER_LIST_SUCCESS";
export const DEFAULT_BRANCH_USER_LIST_FAIL = "DEFAULT_BRANCH_USER_LIST_FAIL";

export const DEFAULT_BRANCH_USER_INSERT_REQUEST =
  "DEFAULT_BRANCH_USER_INSERT_REQUEST";
export const DEFAULT_BRANCH_USER_INSERT_SUCCESS =
  "DEFAULT_BRANCH_USER_INSERT_SUCCESS";
export const DEFAULT_BRANCH_USER_INSERT_FAIL =
  "DEFAULT_BRANCH_USER_INSERT_FAIL";
export const DEFAULT_BRANCH_USER_INSERT_RESET =
  "DEFAULT_BRANCH_USER_INSERT_RESET";
