export const SHORTLISTCOURSE_LIST_REQUEST = "SHORTLISTCOURSE_LIST_REQUEST";
export const SHORTLISTCOURSE_LIST_SUCCESS = "SHORTLISTCOURSE_LIST_SUCCESS";
export const SHORTLISTCOURSE_LIST_FAIL = "SHORTLISTCOURSE_LIST_FAIL";

export const SHORTLISTCOURSE_INSERT_REQUEST = "SHORTLISTCOURSE_INSERT_REQUEST";
export const SHORTLISTCOURSE_INSERT_SUCCESS = "SHORTLISTCOURSE_INSERT_SUCCESS";
export const SHORTLISTCOURSE_INSERT_FAIL = "SHORTLISTCOURSE_INSERT_FAIL";
export const SHORTLISTCOURSE_INSERT_RESET = "SHORTLISTCOURSE_INSERT_RESET";

export const SHORTLISTCOURSE_UPDATE_REQUEST = "SHORTLISTCOURSE_UPDATE_REQUEST";
export const SHORTLISTCOURSE_UPDATE_SUCCESS = "SHORTLISTCOURSE_UPDATE_SUCCESS";
export const SHORTLISTCOURSE_UPDATE_FAIL = "SHORTLISTCOURSE_UPDATE_FAIL";
export const SHORTLISTCOURSE_UPDATE_RESET = "SHORTLISTCOURSE_UPDATE_RESET";

export const SHORTLISTCOURSE_DELETE_REQUEST = "SHORTLISTCOURSE_DELETE_REQUEST";
export const SHORTLISTCOURSE_DELETE_SUCCESS = "SHORTLISTCOURSE_DELETE_SUCCESS";
export const SHORTLISTCOURSE_DELETE_FAIL = "SHORTLISTCOURSE_DELETE_FAIL";
export const SHORTLISTCOURSE_DELETE_RESET = "SHORTLISTCOURSE_DELETE_RESET";
