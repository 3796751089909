export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL'
export const USER_LOGIN_RESET = 'USER_LOGIN_RESET'

export const USERS_LIST_REQUEST = 'USERS_LIST_REQUEST'
export const USERS_LIST_SUCCESS = 'USERS_LIST_SUCCESS'
export const USERS_LIST_FAIL = 'USERS_LIST_FAIL'

export const USERS_GET_REQUEST = 'USERS_GET_REQUEST'
export const USERS_GET_SUCCESS = 'USERS_GET_SUCCESS'
export const USERS_GET_FAIL = 'USERS_GET_FAIL'

export const USERS_GET_INTERNAL_REQUEST = 'USERS_GET_INTERNAL_REQUEST'
export const USERS_GET_INTERNAL_SUCCESS = 'USERS_GET_INTERNAL_SUCCESS'
export const USERS_GET_INTERNAL_FAIL = 'USERS_GET_INTERNAL_FAIL'

export const USERS_UPDATE_REQUEST = 'USERS_UPDATE_REQUEST'
export const USERS_UPDATE_SUCCESS = 'USERS_UPDATE_SUCCESS'
export const USERS_UPDATE_FAIL = 'USERS_UPDATE_FAIL'
export const USERS_UPDATE_RESET = 'USERS_UPDATE_RESET'

export const USERS_UPDATE_PROFILE_REQUEST = 'USERS_UPDATE_PROFILE_REQUEST'
export const USERS_UPDATE_PROFILE_SUCCESS = 'USERS_UPDATE_PROFILE_SUCCESS'
export const USERS_UPDATE_PROFILE_FAIL = 'USERS_UPDATE_PROFILE_FAIL'
export const USERS_UPDATE_PROFILE_RESET = 'USERS_UPDATE_PROFILE_RESET'

export const USERS_CREATE_REQUEST = 'USERS_CREATE_REQUEST'
export const USERS_CREATE_SUCCESS = 'USERS_CREATE_SUCCESS'
export const USERS_CREATE_FAIL = 'USERS_CREATE_FAIL'
export const USERS_CREATE_RESET = 'USERS_CREATE_RESET'

export const USERS_ACTIVATE_REQUEST = 'USERS_ACTIVATE_REQUEST'
export const USERS_ACTIVATE_SUCCESS = 'USERS_ACTIVATE_SUCCESS'
export const USERS_ACTIVATE_FAIL = 'USERS_ACTIVATE_FAIL'
export const USERS_ACTIVATE_RESET = 'USERS_ACTIVATE_RESET'

export const USERS_DEACTIVATE_REQUEST = 'USERS_DEACTIVATE_REQUEST'
export const USERS_DEACTIVATE_SUCCESS = 'USERS_DEACTIVATE_SUCCESS'
export const USERS_DEACTIVATE_FAIL = 'USERS_DEACTIVATE_FAIL'
export const USERS_DEACTIVATE_RESET = 'USERS_DEACTIVATE_RESET'

export const USERS_BLOCKED_REQUEST = 'USERS_BLOCKED_REQUEST'
export const USERS_BLOCKED_SUCCESS = 'USERS_BLOCKED_SUCCESS'
export const USERS_BLOCKED_FAIL = 'USERS_BLOCKED_FAIL'
export const USERS_BLOCKED_RESET = 'USERS_BLOCKED_RESET'

export const USERS_VERIFY_TKN_REQUEST = 'USERS_VERIFY_TKN_REQUEST'
export const USERS_VERIFY_TKN_SUCCESS = 'USERS_VERIFY_TKN_SUCCESS'
export const USERS_VERIFY_TKN_FAIL = 'USERS_VERIFY_TKN_FAIL'
export const USERS_VERIFY_TKN_RESET = 'USERS_VERIFY_TKN_RESET'

export const USERS_RESET_PASSWORD_REQUEST = 'USERS_RESET_PASSWORD_REQUEST'
export const USERS_RESET_PASSWORD_SUCCESS = 'USERS_RESET_PASSWORD_SUCCESS'
export const USERS_RESET_PASSWORD_FAIL = 'USERS_RESET_PASSWORD_FAIL'
export const USERS_RESET_PASSWORD_RESET = 'USERS_RESET_PASSWORD_RESET'

export const USERS_FORGOT_PASSWORD_REQUEST = 'USERS_FORGOT_PASSWORD_REQUEST'
export const USERS_FORGOT_PASSWORD_SUCCESS = 'USERS_FORGOT_PASSWORD_SUCCESS'
export const USERS_FORGOT_PASSWORD_FAIL = 'USERS_FORGOT_PASSWORD_FAIL'
export const USERS_FORGOT_PASSWORD_RESET = 'USERS_FORGOT_PASSWORD_RESET'

export const USERS_CHANGE_PASSWORD_REQUEST = 'USERS_CHANGE_PASSWORD_REQUEST'
export const USERS_CHANGE_PASSWORD_SUCCESS = 'USERS_CHANGE_PASSWORD_SUCCESS'
export const USERS_CHANGE_PASSWORD_FAIL = 'USERS_CHANGE_PASSWORD_FAIL'
export const USERS_CHANGE_PASSWORD_RESET = 'USERS_CHANGE_PASSWORD_RESET'
