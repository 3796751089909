import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listContacts } from "../../Actions/contactActions";

const ContactDropDown = React.memo(() => {
  const dispatch = useDispatch();
  const { contactRecs } = useSelector((s) => s.contactList);
  useEffect(() => {
    dispatch(listContacts({ page_no: 1, page_size: 100 }));
  }, [dispatch]);
  return contactRecs ? (
    contactRecs?.map((recs) => (
      <option value={recs._id} key={recs._id}>
        {recs?.First_Name
          ? recs?.First_Name + " " + (recs?.Last_Name ? recs?.Last_Name : "")
          : recs?.EmailAddress}
      </option>
    ))
  ) : (
    <></>
  );
});
export default ContactDropDown;
