export const STUDY_LEVEL_LIST_REQUEST = 'STUDY_LEVEL_LIST_REQUEST'
export const STUDY_LEVEL_LIST_SUCCESS = 'STUDY_LEVEL_LIST_SUCCESS'
export const STUDY_LEVEL_LIST_FAIL = 'STUDY_LEVEL_LIST_FAIL'

export const STUDY_LEVEL_INSERT_REQUEST = 'STUDY_LEVEL_INSERT_REQUEST'
export const STUDY_LEVEL_INSERT_SUCCESS = 'STUDY_LEVEL_INSERT_SUCCESS'
export const STUDY_LEVEL_INSERT_FAIL = 'STUDY_LEVEL_INSERT_FAIL'
export const STUDY_LEVEL_INSERT_RESET = 'STUDY_LEVEL_INSERT_RESET'

export const STUDY_LEVEL_UPDATE_REQUEST = 'STUDY_LEVEL_UPDATE_REQUEST'
export const STUDY_LEVEL_UPDATE_SUCCESS = 'STUDY_LEVEL_UPDATE_SUCCESS'
export const STUDY_LEVEL_UPDATE_FAIL = 'STUDY_LEVEL_UPDATE_FAIL'
export const STUDY_LEVEL_UPDATE_RESET = 'STUDY_LEVEL_UPDATE_RESET'

export const STUDY_LEVEL_DELETE_REQUEST = 'STUDY_LEVEL_DELETE_REQUEST'
export const STUDY_LEVEL_DELETE_SUCCESS = 'STUDY_LEVEL_DELETE_SUCCESS'
export const STUDY_LEVEL_DELETE_FAIL = 'STUDY_LEVEL_DELETE_FAIL'
export const STUDY_LEVEL_DELETE_RESET = 'STUDY_LEVEL_DELETE_RESET'
