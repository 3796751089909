import {
  PAGE_LIST_FAIL,
  PAGE_LIST_REQUEST,
  PAGE_LIST_SUCCESS
} from "../Constants/PageConstants";

export const pageListReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case PAGE_LIST_REQUEST:
      return { loading: true };
    case PAGE_LIST_SUCCESS:
      return { loading: false, pageRecs: payload };
    case PAGE_LIST_FAIL:
      return { loading: false, error: payload };
    default:
      return state;
  }
};
