import {
  CONTACT_BATCH_LIST_REQUEST,
  CONTACT_BATCH_LIST_SUCCESS,
  CONTACT_BATCH_LIST_FAIL,
  CONTACT_BATCH_CREATE_REQUEST,
  CONTACT_BATCH_CREATE_SUCCESS,
  CONTACT_BATCH_CREATE_FAIL,
  CONTACT_BATCH_UPDATE_REQUEST,
  CONTACT_BATCH_UPDATE_SUCCESS,
  CONTACT_BATCH_UPDATE_FAIL,
  CONTACT_BATCH_GET_REQUEST,
  CONTACT_BATCH_GET_FAIL,
  CONTACT_BATCH_GET_SUCCESS,
  CONTACT_BATCH_CREATE_RESET,
  CONTACT_BATCH_UPDATE_RESET,
  CONTACT_BATCH_SEND_EMAIL_REQUEST,
  CONTACT_BATCH_SEND_EMAIL_SUCCESS,
  CONTACT_BATCH_SEND_EMAIL_FAIL,
  CONTACT_BATCH_SEND_EMAIL_RESET,
} from "../Constants/contactBatchConstant";

export const ContactBatchListReducer = (
  state = {},
  { type, payload, total }
) => {
  switch (type) {
    case CONTACT_BATCH_LIST_REQUEST: {
      return { laoding: true };
    }

    case CONTACT_BATCH_LIST_SUCCESS: {
      return { laoding: true, ContactBatchRec: payload, total: total };
    }

    case CONTACT_BATCH_LIST_FAIL: {
      return { laoding: false, error: payload };
    }
    default:
      return state;
  }
};

export const ContactBatchCreateReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case CONTACT_BATCH_CREATE_REQUEST: {
      return { loading: true };
    }

    case CONTACT_BATCH_CREATE_SUCCESS: {
      return { loading: false, success: payload };
    }

    case CONTACT_BATCH_CREATE_FAIL: {
      return { loading: false, error: payload };
    }

    case CONTACT_BATCH_CREATE_RESET: {
      return { loading: false, success: "" };
    }

    default:
      return state;
  }
};

export const getContactBatchDetailsReducer = (
  state = {},
  { type, payload }
) => {
  switch (type) {
    case CONTACT_BATCH_GET_REQUEST: {
      return { loading: true };
    }

    case CONTACT_BATCH_GET_SUCCESS: {
      return { loading: false, ContactBatchSingleRec: payload };
    }

    case CONTACT_BATCH_GET_FAIL: {
      return { loading: false, error: payload };
    }

    default:
      return state;
  }
};

export const ContactBatchUpdateReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case CONTACT_BATCH_UPDATE_REQUEST: {
      return { loading: true };
    }

    case CONTACT_BATCH_UPDATE_SUCCESS: {
      return { loading: false, success: payload };
    }

    case CONTACT_BATCH_UPDATE_FAIL: {
      return { loading: false, error: payload };
    }

    case CONTACT_BATCH_UPDATE_RESET: {
      return { loading: false, success: "" };
    }
    default:
      return state;
  }
};

export const SendEmailContactBatchReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case CONTACT_BATCH_SEND_EMAIL_REQUEST: {
      return { loading: true };
    }

    case CONTACT_BATCH_SEND_EMAIL_SUCCESS: {
      return { loading: false, success: payload };
    }

    case CONTACT_BATCH_SEND_EMAIL_FAIL: {
      return { loading: false, error: payload };
    }

    case CONTACT_BATCH_SEND_EMAIL_RESET: {
      return { loading: false };
    }

    default:
      return state;
  }
};
