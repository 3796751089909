import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SettingLeftSection from "../Components/SettingLeftSection";
import DeletePopup from "../Components/DeletePoup";
import SidePanel from "../../Components/SidePanel";
import TopBar from "../../Components/TopBar";
import SEDLoading from "../../Components/SEDLoading";
import {
  createTimings,
  deleteTimings,
  listTimings,
  updateTimings,
} from "../../Actions/timingsAction";
import {
  TIMINGS_DELETE_RESET,
  TIMINGS_INSERT_RESET,
  TIMINGS_UPDATE_RESET,
} from "../../Constants/timingsConstant";
import CourseDropDown from "../Components/CourseDropDown";
import ClassModeDropDown from "../Components/ClassModeDropDown";
import ClassCenterDropDown from "../Components/ClassCenterDropDown";

const TimingJson = {
  Timings: "",
  centerLocation: "",
  classModeId: "",
  courseId: "",
};

export default function Timings() {
  const dispatch = useDispatch();
  const [TimingData, setTimingData] = useState(TimingJson);
  const { loading, error, timingsRecs } = useSelector((s) => s.timingsList);
  const [btnText, setbtnText] = useState("Save");
  const [cancelHide, setcancelHide] = useState(false);
  const [OpenNext, setOpenNext] = useState(false);
  const [DeleteAlert, setDeleteAlert] = useState({ msg: "", Delete: () => "" });

  const {
    loading: loadingsv,
    error: errorsv,
    success: successsv,
  } = useSelector((s) => s.timingsInsert);

  const {
    loading: loadingud,
    error: errorud,
    success: successud,
  } = useSelector((s) => s.timingsUpdate);

  const {
    loading: loadingdet,
    error: errordet,
    success: successdet,
  } = useSelector((s) => s.timingsDelete);

  const handleChange = (e) => {
    e.preventDefault();
    setTimingData({ ...TimingData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (btnText == "Save") {
      dispatch(createTimings(TimingData));
    } else if (btnText == "Update") {
      dispatch(updateTimings(TimingData._id, TimingData));
    } else alert("Error on handleSubmit");
  };

  const getTimings = useCallback(() => {
    dispatch(listTimings());
  }, [dispatch]);

  const handleDelete = (e, _id) => {
    e.preventDefault();
    dispatch(deleteTimings(_id));
  };

  const handleEdit = (
    e,
    _id,
    Timings,
    centerLocation,
    classModeId,
    courseId
  ) => {
    e.preventDefault();
    setbtnText("Update");
    setcancelHide(true);
    setTimingData({
      ...TimingData,
      Timings,
      centerLocation,
      classModeId,
      courseId,
      _id,
    });
  };

  const handleCancel = () => {
    setTimingData({ ...TimingJson });
    setbtnText("Save");
    setcancelHide(false);
  };

  useEffect(() => {
    if (timingsRecs == undefined) {
      getTimings();
    }
  }, [timingsRecs, getTimings]);

  useEffect(() => {
    if (successdet)
      setTimeout(() => {
        dispatch({ type: TIMINGS_DELETE_RESET });
      }, 5000);

    if (successud)
      setTimeout(() => {
        dispatch({ type: TIMINGS_UPDATE_RESET });
      }, 5000);

    if (successsv)
      setTimeout(() => {
        dispatch({ type: TIMINGS_INSERT_RESET });
      }, 5000);

    if (successdet || successsv || successud) {
      getTimings();
      setTimingData({ ...TimingJson });
      handleCancel();
    }
  }, [successdet, successsv, getTimings, dispatch, successud]);

  return (
    <>
      <SidePanel page={"Timings"} />
      <section className="home-section">
        {loading ? <SEDLoading /> : ""}
        <TopBar Heading={"Timings"} />
        <div
          className="home-content"
          style={{ paddingTop: window.innerWidth <= 480 ? "20px" : "100px" }}
        >
          <div className="content-body">
            <div className="container-fluid">
              <div className="heading-title">
                <div className="row">
                  <div className="col-md-12">
                    <div className="home-table-search float-right d-flex">
                      {/* <!--    <button>right side</button> --> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="inner-body settings-details">
                <div className="row">
                  <div className="col-md-3">
                    <SettingLeftSection />
                  </div>

                  <div className="col-md-9">
                    <div className="body-content-with-md-9">
                      <div>
                        <div className="container">
                          <div className="row">
                            <div className="col-md-12 text-left body-content-with-md-9-title">
                              <h4>Timing </h4>
                            </div>
                          </div>

                          <div className="row mt-3 px-5">
                            <div className="col-md-3 pt-1">
                              <label className="form-group">Course :</label>
                            </div>
                            <div className="col-md-9">
                              <select
                                className="form-control form-control-sm"
                                name="courseId"
                                value={TimingData.courseId}
                                onChange={(e) => handleChange(e)}
                              >
                                <option>select</option>
                                <CourseDropDown />
                              </select>
                            </div>
                          </div>

                          <div className="row mt-3 px-5">
                            <div className="col-md-3 pt-1">
                              <label className="form-group">class Mode :</label>
                            </div>
                            <div className="col-md-9">
                              <select
                                className="form-control form-control-sm"
                                name="classModeId"
                                value={TimingData.classModeId}
                                onChange={(e) => {
                                  if (e.target.value == 1) {
                                    setTimingData({
                                      ...TimingData,
                                      classModeId: e.target.value,
                                      centerLocation: null,
                                    });
                                  } else {
                                    setTimingData({
                                      ...TimingData,
                                      classModeId: e.target.value,
                                    });
                                  }
                                }}
                              >
                                <option>select</option>
                                <ClassModeDropDown />
                              </select>
                            </div>
                          </div>

                          {TimingData.classModeId == 1 ? (
                            ""
                          ) : (
                            <div className="row mt-3 px-5">
                              <div className="col-md-3 pt-1">
                                <label className="form-group">
                                  Center Location :
                                </label>
                              </div>
                              <div className="col-md-9">
                                <select
                                  className="form-control form-control-sm"
                                  name="centerLocation"
                                  value={TimingData.centerLocation}
                                  onChange={(e) => handleChange(e)}
                                >
                                  <option>select</option>
                                  <ClassCenterDropDown />
                                </select>
                              </div>
                            </div>
                          )}

                          <div className="row mt-3 px-5">
                            <div className="col-md-3 pt-1">
                              <label className="form-group">Timing :</label>
                            </div>
                            <div className="col-md-9">
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                name="Timings"
                                placeholder="Enter Timings"
                                value={TimingData.Timings}
                                onChange={(e) => handleChange(e)}
                              />
                            </div>
                          </div>

                          <div className="row">
                            <div
                              className="col-md-12"
                              style={{ textAlign: "center" }}
                            >
                              <p>
                                <a
                                  className="btn btn-deep-orange btn-md"
                                  onClick={(e) => handleSubmit(e)}
                                >
                                  {loadingsv || loadingud || loadingdet
                                    ? "Loading..."
                                    : btnText}
                                </a>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                {cancelHide ? (
                                  <a
                                    className="btn btn-outline-deep-orange btn-md"
                                    onClick={() => handleCancel()}
                                  >
                                    Cancel
                                  </a>
                                ) : (
                                  ""
                                )}
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="container">
                          <div className="row">
                            <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 ">
                              {errordet || errorsv || errorud ? (
                                <label className="alert-danger" id="SpanError">
                                  {errordet || errorsv || errorud}
                                </label>
                              ) : (
                                ""
                              )}
                              {successdet || successud || successsv ? (
                                <label
                                  className="alert-success"
                                  id="SpanSuccess"
                                >
                                  {successdet || successud || successsv}
                                </label>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="container">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="table-header-md-9 table-responsive">
                                <table className="table table-striped table-bordered">
                                  <thead>
                                    <tr>
                                      <th>Timings</th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {timingsRecs ? (
                                      timingsRecs.map((dd) => (
                                        <tr key={dd._id}>
                                          <td>{dd.Timings}</td>
                                          <td align="center">
                                            <input
                                              type="button"
                                              className="btn btn-info btn-sm"
                                              value={
                                                loadingud
                                                  ? "Loading.."
                                                  : "Update"
                                              }
                                              disabled={loadingud}
                                              onClick={(e) =>
                                                handleEdit(
                                                  e,
                                                  dd._id,
                                                  dd.Timings,
                                                  dd.centerLocation,
                                                  dd.classModeId,
                                                  dd.courseId
                                                )
                                              }
                                            />
                                            &nbsp;&nbsp;&nbsp;
                                            <input
                                              type="button"
                                              className="btn btn-info btn-sm"
                                              value={
                                                loadingdet
                                                  ? "Loading.."
                                                  : "Delete"
                                              }
                                              disabled={loadingdet}
                                              onClick={(e) => {
                                                setDeleteAlert({
                                                  msg: `Do you really want to delete this department (${dd.Timings}) ?`,
                                                  Delete: () =>
                                                    handleDelete(e, dd._id),
                                                });
                                                setOpenNext(true);
                                              }}
                                            />
                                          </td>
                                        </tr>
                                      ))
                                    ) : (
                                      <tr>
                                        <td colSpan={2}>{error}</td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!--/ body-content-with-md-9 --> */}
                  </div>
                  {/* <!--/ col-md-9 --> */}
                </div>
              </div>
            </div>
            <DeletePopup
              OpenNext={OpenNext}
              setOpenNext={setOpenNext}
              DeleteData={DeleteAlert}
            />
          </div>
        </div>
      </section>
    </>
  );
}
