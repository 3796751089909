import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function SettingLeftSection() {
  const [sections, setSections] = useState("");
  return (
    <>
      {/* <div className="filter-in-mob-view text-right">
       
        <i className="fas fa-filter mob-nav-open"></i>
      </div> */}
      {/* <!-- /filter-in-mob-view --> */}

      <div className="add-filter mob-preferences">
        <div className="filter-title list-for-md-3">
          <h5 className="text-left">Your Preferences</h5>
        </div>
        <div className="filter-body list-for-md-3 custom-scroll-right pt-0">
          <div className="ledt-side-setting-menu">
            <ul>
              <li className="click-show-menu">
                <Link
                  className="drop-menu"
                  onClick={(e) => setSections("Basic")}
                >
                  <strong>Basic Info :</strong>
                  <i className="fas fa-chevron-down"></i>
                </Link>

                <ul
                  className={sections == "Basic" ? "menu disp-block" : "menu"}
                >
                  <li className="chose">
                    <Link to="/account">Basic Info</Link>
                  </li>
                  <li className="chose">
                    <Link to="/notifications">Notifications </Link>
                  </li>
                  <li className="chose">
                    <Link to="/security">Security </Link>
                  </li>
                  <li className="chose">
                    <Link to="/account">Account Defaults</Link>
                  </li>
                </ul>
              </li>
              <li className="click-show-menu">
                <Link
                  className="drop-menu"
                  onClick={(e) => setSections("UserControl")}
                >
                  <strong>User Control :</strong>
                  <i className="fas fa-chevron-down"></i>
                </Link>

                <ul
                  className={
                    sections == "UserControl" ? "menu disp-block" : "menu"
                  }
                >
                  <li className="chose">
                    <Link to="/companies">Companies</Link>
                  </li>
                  <li className="chose">
                    <Link to="/conversations">Conversations</Link>
                  </li>
                  <li className="chose">
                    <Link to="/cookies">Cookies</Link>
                  </li>
                  <li className="chose">
                    <Link to="/urls">Domains & URLs</Link>
                  </li>
                  <li className="chose">
                    <Link to="/importexport">Import & export</Link>
                  </li>
                  <li className="chose">
                    <Link to="/integrations">Integrations</Link>
                  </li>
                  <li className="chose">
                    <Link to="/marketing">Marketing</Link>
                  </li>
                  <li className="chose">
                    <Link to="/properties">Properties</Link>
                  </li>
                  <li className="chose">
                    <Link to="/reports">Reports</Link>
                  </li>
                  <li className="chose">
                    <Link to="/sales">Sales</Link>
                  </li>
                  <li className="chose">
                    <Link to="/tickets">Tickets</Link>
                  </li>
                  <li className="chose">
                    <Link to="/qualifies">Qualifies</Link>
                  </li>
                  <li className="chose">
                    <Link to="/country">Country</Link>
                  </li>
                  <li className="chose">
                    <Link to="/month">Month</Link>
                  </li>
                  <li className="chose">
                    <Link to="/users">User</Link>
                  </li>
                  <li className="chose">
                    <Link to="/team">Team</Link>
                  </li>
                  <li className="chose">
                    <Link to="/pipeline">Pipeline</Link>
                  </li>
                  <li className="chose">
                    <Link to="/dealstage">Deal Stage</Link>
                  </li>
                  <li className="chose">
                    <Link to="/issues">Issues</Link>
                  </li>
                </ul>
              </li>
              <li className="click-show-menu">
                <Link
                  className="drop-menu"
                  onClick={(e) => setSections("Educational")}
                >
                  <strong>Educational info :</strong>
                  <i className="fas fa-chevron-down"></i>
                </Link>

                <ul
                  className={
                    sections == "Educational" ? "menu disp-block" : "menu"
                  }
                >
                  <li className="chose">
                    <Link to="/profile">Profile</Link>
                  </li>
                  <li className="chose">
                    <Link to="/reason_did_not_qualify">
                      Reason Did Not Qualify
                    </Link>
                  </li>
                  <li className="chose">
                    <Link to="/preferred_intake">Preferred Intake</Link>
                  </li>
                  <li className="chose">
                    <Link to="/educational_status">Educational Status</Link>
                  </li>
                  <li className="chose">
                    <Link to="/category_of_study">Category Of Study</Link>
                  </li>
                  <li className="chose">
                    <Link to="/country">Interested Country</Link>
                  </li>
                  <li className="chose">
                    <Link to="/interested_level_of_study">
                      Interested Level of Study
                    </Link>
                  </li>
                  <li className="chose">
                    <Link to="/interested_field_of_study">
                      Interested Field of Study
                    </Link>
                  </li>
                  <li className="chose">
                    <Link to="/english_test_attempted">
                      English Test Attempted
                    </Link>
                  </li>
                  <li className="chose">
                    <Link to="/studybatch">Study Batch</Link>
                  </li>
                  <li className="chose">
                    <Link to="/website">Website</Link>
                  </li>
                </ul>
              </li>
              <li className="click-show-menu">
                <Link
                  className="drop-menu"
                  onClick={(e) => setSections("Others")}
                >
                  <strong>Others :</strong>
                  <i className="fas fa-chevron-down"></i>
                </Link>

                <ul
                  className={
                    sections == "Others" ? "menu disp-block" : "menu"
                  }
                >
                  <li className="chose">
                    <Link to="/profile">Profile</Link>
                  </li>
                  <li className="chose">
                    <Link to="/reason_did_not_qualify">
                      Reason Did Not Qualify
                    </Link>
                  </li>
                  <li className="chose">
                    <Link to="/preferred_intake">Preferred Intake</Link>
                  </li>
                  <li className="chose">
                    <Link to="/educational_status">Educational Status</Link>
                  </li>
                  <li className="chose">
                    <Link to="/category_of_study">Category Of Study</Link>
                  </li>
                  <li className="chose">
                    <Link to="/country">Interested Country</Link>
                  </li>
                  <li className="chose">
                    <Link to="/interested_level_of_study">
                      Interested Level of Study
                    </Link>
                  </li>
                  <li className="chose">
                    <Link to="/interested_field_of_study">
                      Interested Field of Study
                    </Link>
                  </li>
                  <li className="chose">
                    <Link to="/english_test_attempted">
                      English Test Attempted
                    </Link>
                  </li>
                  <li className="chose">
                    <Link to="/studybatch">Study Batch</Link>
                  </li>
                  <li className="chose">
                    <Link to="/website">Website</Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          {/* <!-- /ledt-side-setting-menu --> */}
        </div>
      </div>
    </>
  );
}
