import {
  QUALIFIES_DELETE_FAIL,
  QUALIFIES_DELETE_REQUEST,
  QUALIFIES_DELETE_SUCCESS,
  QUALIFIES_INSERT_FAIL,
  QUALIFIES_INSERT_REQUEST,
  QUALIFIES_INSERT_SUCCESS,
  QUALIFIES_LIST_FAIL,
  QUALIFIES_LIST_REQUEST,
  QUALIFIES_LIST_SUCCESS,
  QUALIFIES_UPDATE_FAIL,
  QUALIFIES_UPDATE_REQUEST,
  QUALIFIES_UPDATE_SUCCESS,
} from "../Constants/qualifiesConstants";
import api from "../core/api";

export const listQualifies = () => async (dispatch) => {
  try {
    dispatch({ type: QUALIFIES_LIST_REQUEST });
    const { data } = await api.get(`/qualifies`);
    dispatch({
      type: QUALIFIES_LIST_SUCCESS,
      payload: data.Result,
    });
  } catch (error) {
    dispatch({
      type: QUALIFIES_LIST_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const saveQualifiesDetails =
  (InputJson) => async (dispatch) => {
    try {
      dispatch({ type: QUALIFIES_INSERT_REQUEST });
      const { data } = await api.post(`/qualifies`, InputJson);
      dispatch({
        type: QUALIFIES_INSERT_SUCCESS,
        payload: data.Result,
      });
    } catch (error) {
      dispatch({
        type: QUALIFIES_INSERT_FAIL,
        payload:
          error.response && error.response.data.Result
            ? error.response.data.Result
            : error.message,
      });
    }
  };

export const updateQualifiesDetails =
  (id, InputJson) => async (dispatch) => {
    try {
      dispatch({ type: QUALIFIES_UPDATE_REQUEST });
      const { data } = await api.put(`/qualifies/${id}`, InputJson);
      dispatch({
        type: QUALIFIES_UPDATE_SUCCESS,
        payload: data.Result,
      });
    } catch (error) {
      dispatch({
        type: QUALIFIES_UPDATE_FAIL,
        payload:
          error.response && error.response.data.Result
            ? error.response.data.Result
            : error.message,
      });
    }
  };

export const deleteQualifiesDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: QUALIFIES_DELETE_REQUEST });
    const { data } = await api.delete(`/qualifies/${id}`);
    dispatch({
      type: QUALIFIES_DELETE_SUCCESS,
      payload: data.Result,
    });
  } catch (error) {
    dispatch({
      type: QUALIFIES_DELETE_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};
