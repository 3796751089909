import {
  CONTACT_PLATFORM_DELETE_FAIL,
  CONTACT_PLATFORM_DELETE_REQUEST,
  CONTACT_PLATFORM_DELETE_RESET,
  CONTACT_PLATFORM_DELETE_SUCCESS,
  CONTACT_PLATFORM_GET_FAIL,
  CONTACT_PLATFORM_GET_REQUEST,
  CONTACT_PLATFORM_GET_SUCCESS,
  CONTACT_PLATFORM_INSERT_FAIL,
  CONTACT_PLATFORM_INSERT_REQUEST,
  CONTACT_PLATFORM_INSERT_RESET,
  CONTACT_PLATFORM_INSERT_SUCCESS,
  CONTACT_PLATFORM_LIST_FAIL,
  CONTACT_PLATFORM_LIST_REQUEST,
  CONTACT_PLATFORM_LIST_SUCCESS,
  CONTACT_PLATFORM_UPDATE_FAIL,
  CONTACT_PLATFORM_UPDATE_REQUEST,
  CONTACT_PLATFORM_UPDATE_RESET,
  CONTACT_PLATFORM_UPDATE_SUCCESS,
} from "../Constants/platformConstants";

export const contactPlatformListReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case CONTACT_PLATFORM_LIST_REQUEST:
      return { loading: true };
    case CONTACT_PLATFORM_LIST_SUCCESS:
      return { loading: false, contactPlatforms: payload };
    case CONTACT_PLATFORM_LIST_FAIL:
      return { loading: false, error: payload };
    default:
      return state;
  }
};

export const contactPlatformDetailReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case CONTACT_PLATFORM_GET_REQUEST:
      return { loading: true };
    case CONTACT_PLATFORM_GET_SUCCESS:
      return { loading: false, contactPlatformrec: payload };
    case CONTACT_PLATFORM_GET_FAIL:
      return { loading: false, error: payload };
    default:
      return state;
  }
};

export const contactPlatformSaveReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case CONTACT_PLATFORM_INSERT_REQUEST:
      return { loading: true };
    case CONTACT_PLATFORM_INSERT_SUCCESS:
      return { loading: false, success: payload };
    case CONTACT_PLATFORM_INSERT_FAIL:
      return { loading: false, error: payload };
    case CONTACT_PLATFORM_INSERT_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const contactPlatformUpdateReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case CONTACT_PLATFORM_UPDATE_REQUEST:
      return { loading: true };
    case CONTACT_PLATFORM_UPDATE_SUCCESS:
      return { loading: false, success: payload };
    case CONTACT_PLATFORM_UPDATE_FAIL:
      return { loading: false, error: payload };
    case CONTACT_PLATFORM_UPDATE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const contactPlatformDeleteReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case CONTACT_PLATFORM_DELETE_REQUEST:
      return { loading: true };
    case CONTACT_PLATFORM_DELETE_SUCCESS:
      return { loading: false, success: payload };
    case CONTACT_PLATFORM_DELETE_FAIL:
      return { loading: false, error: payload };
    case CONTACT_PLATFORM_DELETE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};
