export const EVENT_LIST_REQUEST = "EVENT_LIST_REQUEST";
export const EVENT_LIST_SUCCESS = "EVENT_LIST_SUCCESS";
export const EVENT_LIST_FAIL = "EVENT_LIST_FAIL";
export const EVENT_LIST_RESET = "EVENT_LIST_RESET";

export const EVENT_UPLOAD_IMAGE_REQUEST = "EVENT_UPLOAD_IMAGE_REQUEST";
export const EVENT_UPLOAD_IMAGE_SUCCESS = "EVENT_UPLOAD_IMAGE_SUCCESS";
export const EVENT_UPLOAD_IMAGE_FAIL = "EVENT_UPLOAD_IMAGE_FAIL";
export const EVENT_UPLOAD_IMAGE_RESET = "EVENT_UPLOAD_IMAGE_RESET";

export const EVENT_CREATE_REQUEST = "EVENT_CREATE_REQUEST";
export const EVENT_CREATE_SUCCESS = "EVENT_CREATE_SUCCESS";
export const EVENT_CREATE_FAIL = "EVENT_CREATE_FAIL";
export const EVENT_CREATE_RESET = "EVENT_CREATE_RESET";

export const EVENT_GET_REQUEST = "EVENT_GET_REQUEST";
export const EVENT_GET_SUCCESS = "EVENT_GET_SUCCESS";
export const EVENT_GET_FAIL = "EVENT_GET_FAIL";
export const EVENT_GET_RESET = "EVENT_GET_RESET";

export const EVENT_UPDATE_REQUEST = "EVENT_UPDATE_REQUEST";
export const EVENT_UPDATE_SUCCESS = "EVENT_UPDATE_SUCCESS";
export const EVENT_UPDATE_FAIL = "EVENT_UPDATE_FAIL";
export const EVENT_UPDATE_RESET = "EVENT_UPDATE_RESET";

export const EVENT_SET_VISIBILITY_REQUEST = "EVENT_SET_VISIBILITY_REQUEST";
export const EVENT_SET_VISIBILITY_SUCCESS = "EVENT_SET_VISIBILITY_SUCCESS";
export const EVENT_SET_VISIBILITY_FAIL = "EVENT_SET_VISIBILITY_FAIL";
export const EVENT_SET_VISIBILITY_RESET = "EVENT_SET_VISIBILITY_RESET";

export const EVENT_DELETE_REQUEST = "EVENT_DELETE_REQUEST";
export const EVENT_DELETE_SUCCESS = "EVENT_DELETE_SUCCESS";
export const EVENT_DELETE_FAIL = "EVENT_DELETE_FAIL";
export const EVENT_DELETE_RESET = "EVENT_DELETE_RESET";
