import {
  UPDATE_PAYMENT_DETAILS_FAILED,
  UPDATE_PAYMENT_DETAILS_REQ,
  UPDATE_PAYMENT_DETAILS_SUCCESS,
} from "../Constants/PaymentUpdate";
import api from "../core/api";

export const updatePayment = (Details) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_PAYMENT_DETAILS_REQ });
    const { data } = await api.post("/order/updatepayment", Details);
    dispatch({ type: UPDATE_PAYMENT_DETAILS_SUCCESS, payload: data?.Result });
  } catch (error) {
    dispatch({
      type: UPDATE_PAYMENT_DETAILS_FAILED,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};
