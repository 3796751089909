export const UNIVERSITY_LIST_REQUEST = "UNIVERSITY_LIST_REQUEST";
export const UNIVERSITY_LIST_SUCCESS = "UNIVERSITY_LIST_SUCCESS";
export const UNIVERSITY_LIST_FAIL = "UNIVERSITY_LIST_FAIL";

export const UNIVERSITY_DETAILS_REQ = "UNIVERSITY_DETAILS_REQ";
export const UNIVERSITY_DETAILS_SUCCESS = "UNIVERSITY_DETAILS_SUCCESS";
export const UNIVERSITY_DETAILS_FAIL = "UNIVERSITY_DETAILS_FAIL";

export const UNIVERSITY_UPDATE_REQUEST = "UNIVERSITY_UPDATE_REQUEST";
export const UNIVERSITY_UPDATE_SUCCESS = "UNIVERSITY_UPDATE_SUCCESS";
export const UNIVERSITY_UPDATE_FAIL = "UNIVERSITY_UPDATE_FAIL";
export const UNIVERSITY_UPDATE_RESET = "UNIVERSITY_UPDATE_RESET";

export const UNIVERSITY_INSERT_REQUEST = "UNIVERSITY_INSERT_REQUEST";
export const UNIVERSITY_INSERT_SUCCESS = "UNIVERSITY_INSERT_SUCCESS";
export const UNIVERSITY_INSERT_FAIL = "UNIVERSITY_INSERT_FAIL";
export const UNIVERSITY_INSERT_RESET = "UNIVERSITY_INSERT_RESET";

export const UNIVERSITY_DELETE_REQUEST = "UNIVERSITY_DELETE_REQUEST";
export const UNIVERSITY_DELETE_SUCCESS = "UNIVERSITY_DELETE_SUCCESS";
export const UNIVERSITY_DELETE_FAIL = "UNIVERSITY_DELETE_FAIL";
export const UNIVERSITY_DELETE_RESET = "UNIVERSITY_DELETE_RESET";

export const UNIVERISTY_UPLOAD_IMAGE_REQ = "UNIVERISTY_UPLOAD_IMAGE_REQ";
export const UNIVERISTY_UPLOAD_IMAGE_SUCCESS =
  "UNIVERISTY_UPLOAD_IMAGE_SUCCESS";
export const UNIVERISTY_UPLOAD_IMAGE_FAILED = "UNIVERISTY_UPLOAD_IMAGE_FAILED";
export const UNIVERISTY_UPLOAD_IMAGE_RESET = "UNIVERISTY_UPLOAD_IMAGE_RESET";
