export const NEW_APPLICATION_CREATE_REQ = "NEW_APPLICATION_CREATE_REQ";
export const NEW_APPLICATION_CREATE_SUCCESS = "NEW_APPLICATION_CREATE_SUCCESS";
export const NEW_APPLICATION_CREATE_FAILED = "NEW_APPLICATION_CREATE_FAILED";
export const NEW_APPLICATION_CREATE_RESET = "NEW_APPLICATION_CREATE_RESET";

export const NEW_APPLICATION_LIST_REQ = "NEW_APPLICATION_LIST_REQ";
export const NEW_APPLICATION_LIST_SUCCESS = "NEW_APPLICATION_LIST_SUCCESS";
export const NEW_APPLICATION_LIST_FAILED = "NEW_APPLICATION_LIST_FAILED";
export const NEW_APPLICATION_LIST_RESET = "NEW_APPLICATION_LIST_RESET";

export const NEW_CONTACT_APPLICATION_LIST_REQ =
  "NEW_CONTACT_APPLICATION_LIST_REQ";
export const NEW_CONTACT_APPLICATION_LIST_SUCCESS =
  "NEW_CONTACT_APPLICATION_LIST_SUCCESS";
export const NEW_CONTACT_APPLICATION_LIST_FAILED =
  "NEW_CONTACT_APPLICATION_LIST_FAILED";
export const NEW_CONTACT_APPLICATION_LIST_RESET =
  "NEW_CONTACT_APPLICATION_LIST_RESET";

export const NEW_APPLICATION_TEMP_CREATE_REQ =
  "NEW_APPLICATION_TEMP_CREATE_REQ";
export const NEW_APPLICATION_TEMP_CREATE_SUCCESS =
  "NEW_APPLICATION_LIST_SUCCESS";
export const NEW_APPLICATION_TEMP_CREATE_FAILED =
  "NEW_APPLICATION_TEMP_CREATE_FAILED";
export const NEW_APPLICATION_TEMP_CREATE_RESET =
  "NEW_APPLICATION_TEMP_CREATE_RESET";

export const CONTACT_ADDITIONAL_DET_REQ = "CONTACT_ADDITIONAL_DET_REQ";
export const CONTACT_ADDITIONAL_DET_SUCCESS = "CONTACT_ADDITIONAL_DET_SUCCESS";
export const CONTACT_ADDITIONAL_DET_ERROR = "CONTACT_ADDITIONAL_DET_ERROR";
export const CONTACT_ADDITIONAL_DET_RESET = "CONTACT_ADDITIONAL_DET_RESET";

export const CONTACT_WORK_DETAILS_CREATE_REQ =
  "CONTACT_WORK_DETAILS_CREATE_REQ";
export const CONTACT_WORK_DETAILS_CREATE_SUCCESS =
  "CONTACT_WORK_DETAILS_CREATE_SUCCESS";
export const CONTACT_WORK_DETAILS_CREATE_FAIL =
  "CONTACT_WORK_DETAILS_CREATE_FAIL";
export const CONTACT_WORK_DETAILS_CREATE_RESET =
  "CONTACT_WORK_DETAILS_CREATE_RESET";

export const CONTACT_WORK_DETAILS_LIST_REQ = "CONTACT_WORK_DETAILS_LIST_REQ";
export const CONTACT_WORK_DETAILS_LIST_SUCCESS =
  "CONTACT_WORK_DETAILS_LIST_SUCCESS";
export const CONTACT_WORK_DETAILS_LIST_FAIL = "CONTACT_WORK_DETAILS_LIST_FAIL";

export const APPLICATION_SUBMISSION_STATUS_REQ =
  "APPLICATION_SUBMISSION_STATUS_REQ";
export const APPLICATION_SUBMISSION_STATUS_SUCCESS =
  "APPLICATION_SUBMISSION_STATUS_SUCCESS";
export const APPLICATION_SUBMISSION_STATUS_FAILED =
  "APPLICATION_SUBMISSION_STATUS_FAILED";
export const APPLICATION_SUBMISSION_STATUS_RESET =
  "APPLICATION_SUBMISSION_STATUS_RESET";
