import {
  ORDER_DETAIL_CREATE_FAILED,
  ORDER_DETAIL_CREATE_REQ,
  ORDER_DETAIL_CREATE_SUCCESS,
  ORDER_DETAIL_REMOVE_FAILED,
  ORDER_DETAIL_REMOVE_REQ,
  ORDER_DETAIL_REMOVE_SUCCESS,
  ORDER_DETAIL_UPDATE_FAILED,
  ORDER_DETAIL_UPDATE_REQ,
  ORDER_DETAIL_UPDATE_SUCCESS,
  ORDER_DETAILS_FAILED,
  ORDER_DETAILS_REQ,
  ORDER_DETAILS_SUCCESS,
  ORDER_LIST_FAILED,
  ORDER_LIST_REQ,
  ORDER_LIST_SUCCESS,
  ORDER_LIST_TOTAL_FAILED,
  ORDER_LIST_TOTAL_REQ,
  ORDER_LIST_TOTAL_SUCCESS,
} from "../Constants/orderConstant";

import api from "../core/api";

export const listOrderRec = (orderFilter) => async (dispatch) => {
  try {
    dispatch({ type: ORDER_LIST_REQ });
    const { data } = await api.get(
      `/order?page_no=${orderFilter?.page_no}&page_size=${orderFilter?.page_size}`
    );

    dispatch({ type: ORDER_LIST_SUCCESS, payload: data?.Result });
  } catch (error) {
    dispatch({
      type: ORDER_LIST_FAILED,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const totalListOrderRec = (orderFilter) => async (dispatch) => {
  try {
    dispatch({ type: ORDER_LIST_TOTAL_REQ });
    const { data } = await api.get(`/order/total`);
    dispatch({ type: ORDER_LIST_TOTAL_SUCCESS, payload: data?.Result });
  } catch (error) {
    dispatch({
      type: ORDER_LIST_TOTAL_FAILED,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const orderDetailsData = (Id) => async (dispatch) => {
  try {
    dispatch({ type: ORDER_DETAILS_REQ });
    const { data } = await api.get(`/order/detail/${Id}`);
    dispatch({ type: ORDER_DETAILS_SUCCESS, payload: data?.Result });
  } catch (error) {
    console.log(error)
    dispatch({
      type: ORDER_DETAILS_FAILED,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const listOrderRecByContact = (contactId) => async (dispatch) => {
  try {
    dispatch({ type: ORDER_LIST_REQ });
    const { data } = await api.get(`/order/contact/${contactId}`);

    dispatch({ type: ORDER_LIST_SUCCESS, payload: data?.Result });
  } catch (error) {
    dispatch({
      type: ORDER_LIST_FAILED,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const createOrderDet = (inputJson) => async (dispatch) => {
  try {
    dispatch({ type: ORDER_DETAIL_CREATE_REQ });
    const { data } = await api.post("/order", inputJson);
    dispatch({ type: ORDER_DETAIL_CREATE_SUCCESS, payload: data?.Result });
  } catch (error) {
    dispatch({
      type: ORDER_DETAIL_CREATE_FAILED,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const updateOrderDet = (inputJson, Id) => async (dispatch) => {
  try {
    dispatch({ type: ORDER_DETAIL_UPDATE_REQ });
    const { data } = await api.put(`/order/${Id}`, inputJson);
    dispatch({ type: ORDER_DETAIL_UPDATE_SUCCESS, payload: data?.Result });
  } catch (error) {
    dispatch({
      type: ORDER_DETAIL_UPDATE_FAILED,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const removeOrderDet = (Id) => async (dispatch) => {
  try {
    dispatch({ type: ORDER_DETAIL_REMOVE_REQ });
    const { data } = await api.delete(`/order/${Id}`);
    dispatch({ type: ORDER_DETAIL_REMOVE_SUCCESS, payload: data?.Result });
  } catch (error) {
    dispatch({
      type: ORDER_DETAIL_REMOVE_FAILED,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};
