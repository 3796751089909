export const CRM_UPDATES_LIST_REQUEST = 'CRM_UPDATES_LIST_REQUEST'
export const CRM_UPDATES_LIST_SUCCESS = 'CRM_UPDATES_LIST_SUCCESS'
export const CRM_UPDATES_LIST_FAIL = 'CRM_UPDATES_LIST_FAIL'

export const CRM_UPDATES_GET_REQUEST = 'CRM_UPDATES_GET_REQUEST'
export const CRM_UPDATES_GET_SUCCESS = 'CRM_UPDATES_GET_SUCCESS'
export const CRM_UPDATES_GET_FAIL = 'CRM_UPDATES_GET_FAIL'

export const CRM_UPDATES_INSERT_REQUEST = 'CRM_UPDATES_INSERT_REQUEST'
export const CRM_UPDATES_INSERT_SUCCESS = 'CRM_UPDATES_INSERT_SUCCESS'
export const CRM_UPDATES_INSERT_FAIL = 'CRM_UPDATES_INSERT_FAIL'
export const CRM_UPDATES_INSERT_RESET = 'CRM_UPDATES_INSERT_RESET'

export const CRM_UPDATES_UPDATE_REQUEST = 'CRM_UPDATES_UPDATE_REQUEST'
export const CRM_UPDATES_UPDATE_SUCCESS = 'CRM_UPDATES_UPDATE_SUCCESS'
export const CRM_UPDATES_UPDATE_FAIL = 'CRM_UPDATES_UPDATE_FAIL'
export const CRM_UPDATES_UPDATE_RESET = 'CRM_UPDATES_UPDATE_RESET'

export const CRM_UPDATES_DELETE_REQUEST = 'CRM_UPDATES_DELETE_REQUEST'
export const CRM_UPDATES_DELETE_SUCCESS = 'CRM_UPDATES_DELETE_SUCCESS'
export const CRM_UPDATES_DELETE_FAIL = 'CRM_UPDATES_DELETE_FAIL'
export const CRM_UPDATES_DELETE_RESET = 'CRM_UPDATES_DELETE_RESET'
