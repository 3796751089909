import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SettingLeftSection from "../Components/SettingLeftSection";
import DeletePopup from "../Components/DeletePoup";
import SidePanel from "../../Components/SidePanel";
import TopBar from "../../Components/TopBar";
import SEDLoading from "../../Components/SEDLoading";
import {
  createTeam,
  deleteTeam,
  listTeam,
  updateTeam,
} from "../../Actions/teamActions";
import {
  TEAM_DELETE_RESET,
  TEAM_INSERT_RESET,
  TEAM_UPDATE_RESET,
} from "../../Constants/teamConstants";
import { teamJson } from "../../Models/TeamModels";

export default function Team() {
  const dispatch = useDispatch();
  const [TeamData, setTeamData] = useState(teamJson);
  const { loading, error, teamRecs } = useSelector((s) => s.teamList);
  const [btnText, setbtnText] = useState("Save");
  const [cancelHide, setcancelHide] = useState(false);
  const [OpenNext, setOpenNext] = useState(false);
  const [DeleteAlert, setDeleteAlert] = useState({ msg: "", Delete: () => "" });

  const {
    loading: loadingsv,
    error: errorsv,
    success: successsv,
  } = useSelector((s) => s.teamInsert);

  const {
    loading: loadingud,
    error: errorud,
    success: successud,
  } = useSelector((s) => s.teamUpdate);

  const {
    loading: loadingdet,
    error: errordet,
    success: successdet,
  } = useSelector((s) => s.teamDelete);

  const handleChange = (e) => {
    e.preventDefault();
    setTeamData({ ...TeamData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (btnText == "Save") {
      dispatch(createTeam(TeamData));
    } else if (btnText == "Update") {
      dispatch(updateTeam(TeamData._id, TeamData));
    } else alert("Error on handleSubmit");
  };

  const getDepartments = useCallback(() => {
    dispatch(listTeam());
  }, [dispatch]);

  const handleDelete = (e, _id) => {
    e.preventDefault();
    dispatch(deleteTeam(_id));
  };

  const handleEdit = (e, _id, Team_Name) => {
    e.preventDefault();
    setbtnText("Update");
    setcancelHide(true);
    setTeamData({ ...TeamData, Team_Name, _id });
  };

  const handleCancel = () => {
    setTeamData({ ...teamJson });
    setbtnText("Save");
    setcancelHide(false);
  };

  useEffect(() => {
    getDepartments();
  }, [getDepartments]);

  useEffect(() => {
    if (successdet)
      setTimeout(() => {
        dispatch({ type: TEAM_DELETE_RESET });
      }, 5000);

    if (successud)
      setTimeout(() => {
        dispatch({ type: TEAM_UPDATE_RESET });
      }, 5000);

    if (successsv)
      setTimeout(() => {
        dispatch({ type: TEAM_INSERT_RESET });
      }, 5000);
    if (successdet || successsv || successud) {
      getDepartments();
      setTeamData({ ...teamJson });
      handleCancel();
    }
  }, [successdet, successsv, dispatch, successud]);

  return (
    <>
      <SidePanel page={"Team"} />
      <section className="home-section">
        {loading ? <SEDLoading /> : ""}
        <TopBar Heading={"Team"} />
        <div
          className="home-content"
          style={{ paddingTop: window.innerWidth <= 480 ? "20px" : "100px" }}
        >
          <div className="content-body">
            {/* <!--Body-content--> */}
            <div className="container-fluid">
              <div className="heading-title">
                <div className="row">
                  <div className="col-md-12">
                    <div className="home-table-search float-right d-flex">
                      {/* <!--    <button>right side</button> --> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="inner-body settings-details">
                <div className="row">
                  <div className="col-md-3">
                    <SettingLeftSection />
                  </div>

                  <div className="col-md-9">
                    <div className="body-content-with-md-9">
                      <div>
                        <div className="container">
                          <div className="row">
                            <div className="col-md-12 text-left body-content-with-md-9-title">
                              <h4>Team </h4>
                            </div>
                          </div>
                          <div className="row mt-3 px-5">
                            <div className="col-md-3 pt-1">
                              <label className="form-group">Team Name:</label>
                            </div>
                            <div className="col-md-9">
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                name="Team_Name"
                                placeholder="Enter Team Name"
                                value={TeamData.Team_Name}
                                onChange={(e) => handleChange(e)}
                              />
                            </div>
                          </div>

                          <div className="row">
                            <div
                              className="col-md-12"
                              style={{ textAlign: "center" }}
                            >
                              <p>
                                <a
                                  className="btn btn-deep-orange btn-md"
                                  onClick={(e) => handleSubmit(e)}
                                >
                                  {loadingsv || loadingud || loadingdet
                                    ? "Loading..."
                                    : btnText}
                                </a>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                {cancelHide ? (
                                  <a
                                    className="btn btn-outline-deep-orange btn-md"
                                    onClick={() => handleCancel()}
                                  >
                                    Cancel
                                  </a>
                                ) : (
                                  ""
                                )}
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="container">
                          <div className="row">
                            <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 ">
                              {errordet || errorsv || errorud ? (
                                <label className="alert-danger" id="SpanError">
                                  {errordet || errorsv || errorud}
                                </label>
                              ) : (
                                ""
                              )}
                              {successdet || successud || successsv ? (
                                <label
                                  className="alert-success"
                                  id="SpanSuccess"
                                >
                                  {successdet || successud || successsv}
                                </label>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="container">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="table-header-md-9 table-responsive">
                                <table className="table table-striped table-bordered">
                                  <thead>
                                    <tr>
                                      <th>Team Name</th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {teamRecs ? (
                                      teamRecs.map((dd) => (
                                        <tr key={dd._id}>
                                          <td>{dd.Team_Name}</td>
                                          <td align="center">
                                            <input
                                              type="button"
                                              className="btn btn-info btn-sm"
                                              value={
                                                loadingud
                                                  ? "Loading.."
                                                  : "Update"
                                              }
                                              disabled={loadingud}
                                              onClick={(e) =>
                                                handleEdit(
                                                  e,
                                                  dd._id,
                                                  dd.Team_Name
                                                )
                                              }
                                            />
                                            &nbsp;&nbsp;&nbsp;
                                            <input
                                              type="button"
                                              className="btn btn-info btn-sm"
                                              value={
                                                loadingdet
                                                  ? "Loading.."
                                                  : "Delete"
                                              }
                                              disabled={loadingdet}
                                              onClick={(e) => {
                                                setDeleteAlert({
                                                  msg: `Do you really want to delete this department (${dd.Team_Name}) ?`,
                                                  Delete: () =>
                                                    handleDelete(e, dd._id),
                                                });
                                                setOpenNext(true);
                                              }}
                                            />
                                          </td>
                                        </tr>
                                      ))
                                    ) : (
                                      <tr>
                                        <td colSpan={2}>{error}</td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!--/ body-content-with-md-9 --> */}
                  </div>
                  {/* <!--/ col-md-9 --> */}
                </div>
              </div>
            </div>
            <DeletePopup
              OpenNext={OpenNext}
              setOpenNext={setOpenNext}
              DeleteData={DeleteAlert}
            />
          </div>
        </div>
      </section>
    </>
  );
}
