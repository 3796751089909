import {
  BRANCH_DELETE_FAIL,
  BRANCH_DELETE_REQUEST,
  BRANCH_DELETE_RESET,
  BRANCH_DELETE_SUCCESS,
  BRANCH_INSERT_FAIL,
  BRANCH_INSERT_REQUEST,
  BRANCH_INSERT_RESET,
  BRANCH_INSERT_SUCCESS,
  BRANCH_LIST_FAIL,
  BRANCH_LIST_REQUEST,
  BRANCH_LIST_SUCCESS,
  BRANCH_UPDATE_FAIL,
  BRANCH_UPDATE_REQUEST,
  BRANCH_UPDATE_RESET,
  BRANCH_UPDATE_SUCCESS,
} from "../Constants/branchConstants";

export const branchListReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case BRANCH_LIST_REQUEST:
      return { loading: true };
    case BRANCH_LIST_SUCCESS:
      return { loading: false, branchRecs: payload };
    case BRANCH_LIST_FAIL:
      return { loading: false, error: payload };
    default:
      return state;
  }
};

export const branchSaveReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case BRANCH_INSERT_REQUEST:
      return { loading: true };
    case BRANCH_INSERT_SUCCESS:
      return { loading: false, success: payload };
    case BRANCH_INSERT_FAIL:
      return { loading: false, error: payload };
    case BRANCH_INSERT_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const branchUpdateReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case BRANCH_UPDATE_REQUEST:
      return { loading: true };
    case BRANCH_UPDATE_SUCCESS:
      return { loading: false, success: payload };
    case BRANCH_UPDATE_FAIL:
      return { loading: false, error: payload };
    case BRANCH_UPDATE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const branchDeleteReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case BRANCH_DELETE_REQUEST:
      return { loading: true };
    case BRANCH_DELETE_SUCCESS:
      return { loading: false, success: payload };
    case BRANCH_DELETE_FAIL:
      return { loading: false, error: payload };
    case BRANCH_DELETE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};
