import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listTimings } from "../../Actions/timingsAction";

const TimingsDropDown = React.memo(
  ({ courseid, classmodeid, centerlocation }) => {
    const dispatch = useDispatch();
    const { timingsRecs } = useSelector((s) => s.timingsList);
    const getRecs = useCallback(() => {
      dispatch(listTimings({ courseid, classmodeid, centerlocation }));
    }, [courseid, classmodeid, centerlocation, dispatch]);

    useMemo(() => {
      getRecs();
    }, [getRecs]);

    return timingsRecs?.length ? (
      timingsRecs?.map((ev) => (
        <option key={ev?._id} value={ev?.Timings}>
          {ev?.Timings}
        </option>
      ))
    ) : (
      <></>
    );
  }
);

export default TimingsDropDown;
