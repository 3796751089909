export const APPLICATION_LIST_REQUEST = "APPLICATION_LIST_REQUEST";
export const APPLICATION_LIST_SUCCESS = "APPLICATION_LIST_SUCCESS";
export const APPLICATION_LIST_FAIL = "APPLICATION_LIST_FAIL";
export const APPLICATION_LIST_RESET = "APPLICATION_LIST_RESET";

export const APPLICATION_LIST_TOTAL_REQUEST = "APPLICATION_LIST_TOTAL_REQUEST";
export const APPLICATION_LIST_TOTAL_SUCCESS = "APPLICATION_LIST_TOTAL_SUCCESS";
export const APPLICATION_LIST_TOTAL_FAIL = "APPLICATION_LIST_TOTAL_FAIL";
export const APPLICATION_LIST_TOTAL_RESET = "APPLICATION_LIST_TOTAL_RESET";

export const APPLICATION_CREATE_REQUEST = "APPLICATION_CREATE_REQUEST";
export const APPLICATION_CREATE_SUCCESS = "APPLICATION_CREATE_SUCCESS";
export const APPLICATION_CREATE_FAIL = "APPLICATION_CREATE_FAIL";
export const APPLICATION_CREATE_RESET = "APPLICATION_CREATE_RESET";

export const APPLICATION_GET_REQUEST = "APPLICATION_GET_REQUEST";
export const APPLICATION_GET_SUCCESS = "APPLICATION_GET_SUCCESS";
export const APPLICATION_GET_FAIL = "APPLICATION_GET_FAIL";
export const APPLICATION_GET_RESET = "APPLICATION_GET_RESET";

export const APPLICATION_UPDATE_REQUEST = "APPLICATION_UPDATE_REQUEST";
export const APPLICATION_UPDATE_SUCCESS = "APPLICATION_UPDATE_SUCCESS";
export const APPLICATION_UPDATE_FAIL = "APPLICATION_UPDATE_FAIL";
export const APPLICATION_UPDATE_RESET = "APPLICATION_UPDATE_RESET";

export const APPLICATION_DELETE_REQUEST = "APPLICATION_DELETE_REQUEST";
export const APPLICATION_DELETE_SUCCESS = "APPLICATION_DELETE_SUCCESS";
export const APPLICATION_DELETE_FAIL = "APPLICATION_DELETE_FAIL";
export const APPLICATION_DELETE_RESET = "APPLICATION_DELETE_RESET";

export const APPLICATION_DET_BY_IDS_REQUEST = "APPLICATION_DET_BY_IDS_REQUEST";
export const APPLICATION_DET_BY_IDS_SUCCESS = "APPLICATION_DET_BY_IDS_SUCCESS";
export const APPLICATION_DET_BY_IDS_FAIL = "APPLICATION_DET_BY_IDS_FAIL";
