import {
  MATERIAL_SUBTITLE_CREATE_FAILED,
  MATERIAL_SUBTITLE_CREATE_REQ,
  MATERIAL_SUBTITLE_CREATE_RESET,
  MATERIAL_SUBTITLE_CREATE_SUCCESS,
  MATERIAL_SUBTITLE_DETAILS_FAILED,
  MATERIAL_SUBTITLE_DETAILS_REQ,
  MATERIAL_SUBTITLE_DETAILS_SUCCESS,
  MATERIAL_SUBTITLE_LIST_ALL_FAILED,
  MATERIAL_SUBTITLE_LIST_ALL_REQ,
  MATERIAL_SUBTITLE_LIST_ALL_SUCCESS,
  MATERIAL_SUBTITLE_LIST_FAILED,
  MATERIAL_SUBTITLE_LIST_REQ,
  MATERIAL_SUBTITLE_LIST_RESET,
  MATERIAL_SUBTITLE_LIST_SUCCESS,
  MATERIAL_SUBTITLE_REMOVE_FAILED,
  MATERIAL_SUBTITLE_REMOVE_REQ,
  MATERIAL_SUBTITLE_REMOVE_RESET,
  MATERIAL_SUBTITLE_REMOVE_SUCCESS,
  MATERIAL_SUBTITLE_UPDATE_FAILED,
  MATERIAL_SUBTITLE_UPDATE_REQ,
  MATERIAL_SUBTITLE_UPDATE_RESET,
  MATERIAL_SUBTITLE_UPDATE_SUCCESS,
} from "../Constants/materialSubTitleConstant";

export const createMaterialSubTitleReducer = (
  state = {},
  { type, payload }
) => {
  switch (type) {
    case MATERIAL_SUBTITLE_CREATE_REQ: {
      return { loading: true };
    }

    case MATERIAL_SUBTITLE_CREATE_SUCCESS: {
      return { loading: false, success: payload };
    }

    case MATERIAL_SUBTITLE_CREATE_FAILED: {
      return { loading: false, error: payload };
    }

    case MATERIAL_SUBTITLE_CREATE_RESET: {
      return { laoding: false, success: "", error: "" };
    }
    default:
      return state;
  }
};

export const listMaterialSubTitleReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case MATERIAL_SUBTITLE_LIST_REQ: {
      return { loading: true };
    }

    case MATERIAL_SUBTITLE_LIST_SUCCESS: {
      return { loading: false, MaterialSubTitleRec: payload };
    }

    case MATERIAL_SUBTITLE_LIST_FAILED: {
      return { loading: false, error: payload };
    }

    case MATERIAL_SUBTITLE_LIST_RESET: {
      return {};
    }

    default:
      return state;
  }
};

export const listMaterialSubTitleAllReducer = (
  state = {},
  { type, payload }
) => {
  switch (type) {
    case MATERIAL_SUBTITLE_LIST_ALL_REQ: {
      return { loading: true };
    }

    case MATERIAL_SUBTITLE_LIST_ALL_SUCCESS: {
      return { loading: false, MaterialSubTitleAllRec: payload };
    }

    case MATERIAL_SUBTITLE_LIST_ALL_FAILED: {
      return { loading: false, error: payload };
    }

    default:
      return state;
  }
};

export const deleteMaterialSubTitleReducer = (
  state = {},
  { type, payload }
) => {
  switch (type) {
    case MATERIAL_SUBTITLE_REMOVE_REQ: {
      return { loading: true };
    }
    case MATERIAL_SUBTITLE_REMOVE_SUCCESS: {
      return { loading: false, success: payload };
    }

    case MATERIAL_SUBTITLE_REMOVE_FAILED: {
      return { loading: false, error: payload };
    }

    case MATERIAL_SUBTITLE_REMOVE_RESET: {
      return { loading: false, error: "", success: "" };
    }
    default:
      return state;
  }
};

export const updateMaterialSubTitleReducer = (
  state = {},
  { type, payload }
) => {
  switch (type) {
    case MATERIAL_SUBTITLE_UPDATE_REQ: {
      return { loading: true };
    }

    case MATERIAL_SUBTITLE_UPDATE_SUCCESS: {
      return { loading: false, success: payload };
    }

    case MATERIAL_SUBTITLE_UPDATE_FAILED: {
      return { laoding: false, error: payload };
    }

    case MATERIAL_SUBTITLE_UPDATE_RESET: {
      return { laoding: false, error: "", success: "" };
    }

    default:
      return state;
  }
};

export const singleMaterialSubTitleDetailsReducer = (
  state = {},
  { type, payload }
) => {
  switch (type) {
    case MATERIAL_SUBTITLE_DETAILS_REQ: {
      return { loading: true };
    }

    case MATERIAL_SUBTITLE_DETAILS_SUCCESS: {
      return { loading: false, MaterialSubTitleDetails: payload };
    }

    case MATERIAL_SUBTITLE_DETAILS_FAILED: {
      return { laoding: false, error: payload };
    }

    default:
      return state;
  }
};
