import { useEffect, useState } from "react";
import { Change_Password_Model } from "../../Models/LoginModel";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { toastOptions } from "../../Models/LocalData";
import { useLocation, useNavigate } from "react-router-dom";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import {
  changePasswordUserDetails,
  verifyTknUserDetails,
} from "../../Actions/userActions";
import SEDLoading from "../../Components/SEDLoading";

export default function SetPassword() {
  const [LoginForm, setLoginForm] = useState(Change_Password_Model);
  const [PasswordVisible, setPasswordVissible] = useState(false);
  const [PasswordVisible1, setPasswordVissible1] = useState(false);
  const [PasswordVal, setPasswordVal] = useState({ Error: "", Success: "" });

  const { loading, success, error } = useSelector(
    (state) => state.userResetPassword
  );

  const {
    loading: UrlLoading,
    error: UrlError,
    success: UrlSuccess,
  } = useSelector((s) => s.userVerifyTkn);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const key = query.get("k");
  const text = query.get("t");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleFormChange = (e) => {
    if (e.target.name === "Password") {
      if (
        LoginForm?.ConfirmPassword?.length > 1 &&
        LoginForm?.ConfirmPassword !== e.target.value
      ) {
        setPasswordVal({
          Error: "Error - New Password & Confirm Password doesn't matched !",
        });
      } else if (
        LoginForm?.ConfirmPassword?.length > 1 &&
        LoginForm?.ConfirmPassword === e.target.value
      ) {
        setPasswordVal({ Success: "Password Matched !" });
      }
    } else if (e.target.name === "ConfirmPassword") {
      if (LoginForm?.Password !== e.target.value) {
        setPasswordVal({
          Error: "Error - New Password & Confirm Password doesn't matched !",
        });
      } else {
        setPasswordVal({ Success: "Password Matched !" });
      }
    }
    setLoginForm({ ...LoginForm, [e.target.name]: e.target.value });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    dispatch(
      changePasswordUserDetails({
        text,
        k: key,
        password: LoginForm?.Password,
      })
    );
  };

  useEffect(() => {
    if (success) {
      toast.success(success, toastOptions);
      setTimeout(() => {
        navigate("/");
      }, 3500);
    }
    if (error) {
      toast.error(error, toastOptions);
    }
  }, [error, success]);

  useEffect(() => {
    if (text && key) {
      dispatch(verifyTknUserDetails({ text, k: key }));
    }
  }, [text, key]);

  useEffect(() => {
    if (UrlSuccess) {
      toast.success(UrlSuccess, toastOptions);
    }
  }, [UrlSuccess]);

  return (
    <div>
      {loading || UrlLoading ? <SEDLoading /> : ""}
      <div className="Login">
        <div id="particles-js"></div>
        <div className="">
          <div className={UrlError ? "containerOld" : "container_new"}>
            {UrlError ? (
              <h4
                style={{ padding: "20px", textAlign: "center", color: "red" }}
              >
                {UrlError}
              </h4>
            ) : (
              <form name="form1" className="box" onSubmit={handleFormSubmit}>
                <img
                  style={{ width: "40%", marginBottom: "30px" }}
                  src="./assets/images/Testifyi_logo1.jpg"
                  alt="logo"
                />
                <h5>Sign in to your account.</h5>
                <i className="typcn typcn-eye" id="eye"></i>
                <div style={{ position: "relative" }}>
                  <input
                    type={PasswordVisible1 ? "text" : "password"}
                    name="Password"
                    placeholder="New Passsword"
                    value={LoginForm.Password}
                    onChange={handleFormChange}
                    id="pwd"
                    autoComplete="off"
                    required
                  />
                  <div
                    class
                    style={
                      PasswordVisible1
                        ? { display: "none" }
                        : {
                            color: "black",
                            position: "absolute",
                            right: "60px",
                            top: "10px",
                          }
                    }
                    onClick={() => setPasswordVissible1(true)}
                  >
                    <BsEyeSlash />
                  </div>
                  <div
                    onClick={() => setPasswordVissible1(false)}
                    style={
                      !PasswordVisible1
                        ? { display: "none" }
                        : {
                            color: "black",
                            position: "absolute",
                            right: "60px",
                            top: "10px",
                          }
                    }
                  >
                    <BsEye />
                  </div>
                </div>
                <i className="typcn typcn-eye" id="eye"></i>
                <div style={{ position: "relative" }}>
                  <input
                    type={PasswordVisible ? "text" : "password"}
                    name="ConfirmPassword"
                    placeholder="Confirm Passsword"
                    value={LoginForm.ConfirmPassword}
                    onChange={handleFormChange}
                    id="pwd"
                    autoComplete="off"
                    required
                  />
                  <div
                    class
                    style={
                      PasswordVisible
                        ? { display: "none" }
                        : {
                            color: "black",
                            position: "absolute",
                            right: "60px",
                            top: "10px",
                          }
                    }
                    onClick={() => setPasswordVissible(true)}
                  >
                    <BsEyeSlash />
                  </div>
                  <div
                    onClick={() => setPasswordVissible(false)}
                    style={
                      !PasswordVisible
                        ? { display: "none" }
                        : {
                            color: "black",
                            position: "absolute",
                            right: "60px",
                            top: "10px",
                          }
                    }
                  >
                    <BsEye />
                  </div>
                </div>

                {/* <div
                className="remember"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div style={{ marginLeft: "15px" }}>
                  <input type="checkbox" />
                  <label
                    style={{
                      color: "black",
                      marginLeft: "5px",
                      fontSize: "14px",
                    }}
                  >
                    Remember me
                  </label>
                </div>
                
              </div> */}
                <div style={{ padding: "0 15px" }}>
                  {PasswordVal?.Error ? (
                    <p style={{ color: "red", textAlign: "center" }}>
                      {PasswordVal?.Error}
                    </p>
                  ) : (
                    ""
                  )}

                  {PasswordVal?.Success ? (
                    <p style={{ color: "green", textAlign: "center" }}>
                      {PasswordVal?.Success}
                    </p>
                  ) : (
                    ""
                  )}
                </div>

                {/*<label className="remember">
              <input type="checkbox" />
              <span></span>
              <small className="rmb">Remember me</small>
            </label>
            <a href="#" className="forgetpass">
              Forget Password?
  </a>*/}
                <input
                  type="submit"
                  disabled={loading}
                  value={loading ? "Loading..." : "Change Password"}
                  className="btn1"
                />
              </form>
            )}
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
