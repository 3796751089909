import {
  PRIORITY_DELETE_FAIL,
  PRIORITY_DELETE_REQUEST,
  PRIORITY_DELETE_RESET,
  PRIORITY_DELETE_SUCCESS,
  PRIORITY_INSERT_FAIL,
  PRIORITY_INSERT_REQUEST,
  PRIORITY_INSERT_RESET,
  PRIORITY_INSERT_SUCCESS,
  PRIORITY_LIST_FAIL,
  PRIORITY_LIST_REQUEST,
  PRIORITY_LIST_SUCCESS,
  PRIORITY_UPDATE_FAIL,
  PRIORITY_UPDATE_REQUEST,
  PRIORITY_UPDATE_RESET,
  PRIORITY_UPDATE_SUCCESS,
} from "../Constants/priorityConstants";

export const priorityListReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case PRIORITY_LIST_REQUEST:
      return { loading: true };
    case PRIORITY_LIST_SUCCESS:
      return { loading: false, priorityRecs: payload };
    case PRIORITY_LIST_FAIL:
      return { loading: false, error: payload };
    default:
      return state;
  }
};

export const prioritySaveReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case PRIORITY_INSERT_REQUEST:
      return { loading: true };
    case PRIORITY_INSERT_SUCCESS:
      return { loading: false, success: payload };
    case PRIORITY_INSERT_FAIL:
      return { loading: false, error: payload };
    case PRIORITY_INSERT_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const priorityUpdateReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case PRIORITY_UPDATE_REQUEST:
      return { loading: true };
    case PRIORITY_UPDATE_SUCCESS:
      return { loading: false, success: payload };
    case PRIORITY_UPDATE_FAIL:
      return { loading: false, error: payload };
    case PRIORITY_UPDATE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const priorityDeleteReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case PRIORITY_DELETE_REQUEST:
      return { loading: true };
    case PRIORITY_DELETE_SUCCESS:
      return { loading: false, success: payload };
    case PRIORITY_DELETE_FAIL:
      return { loading: false, error: payload };
    case PRIORITY_DELETE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};
