import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SettingLeftSection from "../Components/SettingLeftSection";
import DeletePopup from "../Components/DeletePoup";
import SidePanel from "../../Components/SidePanel";
import TopBar from "../../Components/TopBar";
import SEDLoading from "../../Components/SEDLoading";
import ReactQuill from "react-quill";
import {
  EMAIL_TEMPLATE_CREATE_RESET,
  EMAIL_TEMPLATE_REMOVE_RESET,
  EMAIL_TEMPLATE_UPDATE_RESET,
} from "../../Constants/EmailTemplateConstants";
import { toast, ToastContainer } from "react-toastify";
import { toastOptions } from "../../Models/ToastEditiorOption";
import {
  createEmailTemplate,
  deleteEmailTemplate,
  listEmailTemplateAll,
  singleEmailTemplateDetails,
} from "../../Actions/emailTemplateAction";
import {
  CloseTemplate,
  EmailTemplate_Validator,
  EmailTemplateJson,
  OpenTemplete,
} from "../../Models/EmailTemplateModel";
import { AiFillDelete } from "react-icons/ai";
import api from "../../core/api";
import { checkDomain } from "../../Models/mockTestModel";
const Img = { image: "", preview: "", name: "" };

export default function EmailTemplate() {
  const dispatch = useDispatch();
  const [EmailTemplateData, setEmailTemplateData] = useState(EmailTemplateJson);
  const [EmailData, setEmailData] = useState([]);
  const [UploadedDesc, setUploadedDesc] = useState([]);
  const [createSec, setcreateSec] = useState(false);
  const [Load, setLoad] = useState(false);

  const [EmailContent, setEmailContent] = useState("");
  const { loading, error, EmailTemplateAllRec } = useSelector(
    (s) => s.listEmailTemplateAllReducer
  );
  const [btnText, setbtnText] = useState("Save");
  const [cancelHide, setcancelHide] = useState(false);
  const [OpenNext, setOpenNext] = useState(false);
  const [DeleteAlert, setDeleteAlert] = useState({ msg: "", Delete: () => "" });

  const {
    loading: loadingsv,
    error: errorsv,
    success: successsv,
  } = useSelector((s) => s.createEmailTemplateReducer);

  const {
    loading: loadingud,
    error: errorud,
    success: successud,
  } = useSelector((s) => s.updateEmailTemplateReducer);

  const { EmailTemplateDetails } = useSelector(
    (s) => s.singleEmailTemplateDetailsReducer
  );

  const {
    loading: loadingdet,
    error: errordet,
    success: successdet,
  } = useSelector((s) => s.deleteMaterialSubTitleReducer);

  const handleChange = (e) => {
    e.preventDefault();
    setEmailTemplateData({
      ...EmailTemplateData,
      [e.target.name]: e.target.value,
    });
  };

  const SetFiles = async (FileData, url, index) => {
    const { data } = await api.post(`/uploaddoc?url=${url}`, FileData);
    let details = [...UploadedDesc];
    details[index].url = data?.url;
    setUploadedDesc([...details]);
  };

  const extractContent = (htmlContent) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, "text/html");
    const result = [];
    const resultType = [];
    const parentDiv = doc.getElementById("Htmlcontent");

    if (parentDiv) {
      const children = parentDiv.children;
      Array.from(children).forEach((child) => {
        if (child.querySelector("img")) {
          // Extract the image URL
          const img = child.querySelector("img");
          result.push({ image: "", url: img.src });
          resultType.push("Image");
        } else if (child.innerHTML.trim()) {
          // Extract the HTML content as text
          result.push({ type: "text", desc: child.innerHTML.trim() });
          resultType.push("InputText");
        }
      });
    }

    setEmailData([...resultType]);
    setUploadedDesc([...result]);
  };

  // Example Usage:

  const handleSubmit = (e) => {
    e.preventDefault();
    if (btnText === "Save") {
      const Validator = EmailTemplate_Validator(
        {
          ...EmailTemplateData,
        },
        EmailData,
        UploadedDesc
      );
      if (Validator === "success") {
        setLoad(true);

        for (let i = 0; i < EmailData?.length; i++) {
          if (EmailData[i] === "Image") {
            const formData = new FormData();
            formData.append("UploadFile", UploadedDesc[i]?.image);
            SetFiles(formData, "/EmailTemplate/Image", i);
          }
        }

        setTimeout(async () => {
          const EmailArray = await EmailData?.map((en, i) => {
            if (en === "InputText") {
              return UploadedDesc[i]?.desc;
            } else if (en === "Image") {
              const urlData = checkDomain(
                UploadedDesc[i]?.url,
                process.env.REACT_APP_DOC_URL
              )
                ? UploadedDesc[i]?.url
                : process.env.REACT_APP_DOC_URL + UploadedDesc[i]?.url;
              return `<img src='${urlData}' alt='mock_image'/>`;
            } else {
              return "";
            }
          });

          const Descriptions = await `${OpenTemplete}
                        ${EmailArray?.map((en, i) =>
                          EmailData[i] == "InputText"
                            ? `<div style='margin: 30px 20px' class='mgt0'>${en}</div>`
                            : `<div>${en}</div>`
                        ).join("")}
                      ${CloseTemplate}`;
          dispatch(
            createEmailTemplate({
              MaterialDescription: Descriptions,
              MaterialSubTitle: "Test Material SubTitle for Email 4",
              MaterialTitleId: "6784fdc7a849773598ccc954",
            })
          );
          setLoad(false);
        }, 5000);
      } else {
        toast.error(Validator, toastOptions);
      }
    } else if (btnText === "Update") {
      const Validator = EmailTemplate_Validator(
        {
          ...EmailTemplateData,
          EmailContent,
        },
        EmailData,
        UploadedDesc
      );

      if (Validator === "success") {
        setLoad(true);

        for (let i = 0; i < EmailData?.length; i++) {
          if (EmailData[i] === "Image") {
            const formData = new FormData();
            formData.append("UploadFile", UploadedDesc[i]?.image);
            SetFiles(formData, "/MockTest/Image", i);
          }
        }

        setTimeout(async () => {
          setLoad(false);
        }, 5000);
      } else {
        toast.error(Validator, toastOptions);
      }
    } else alert("Error on handleSubmit");
  };

  const handleDescRemove = (index) => {
    let Details1 = [...EmailData];
    let UploadDetails1 = [...UploadedDesc];
    UploadDetails1.splice(index, 1);
    Details1.splice(index, 1);
    setEmailData([...Details1]);
    setUploadedDesc([...UploadDetails1]);
  };



  const handlePreview = () => {
    const newTab = window.open("", "_blank");
    newTab.document.write(OpenTemplete);

    EmailData.map((ev, index) => {
      // Image Preview
      if (
        ev == "Image" &&
        (UploadedDesc[index]?.preview || UploadedDesc[index]?.url)
      ) {
        newTab.document.write(
          `<div>  <img src="${
            UploadedDesc[index]?.preview || UploadedDesc[index]?.url
          }"   style={{ width:"100%", maxWidth: "100%", borderRadius: "8px" ,display: "block"}}></div>`
        );
      }

      // Audio Preview

      // Mock Description Preview
      if (ev == "InputText" && UploadedDesc[index]?.desc) {
        newTab.document.write(
          `<div style="margin: 30px 20px" class="mgt0">  ${UploadedDesc[index]?.desc}</div>`
        );
      }
    }).join("");

    newTab.document.write(CloseTemplate);
  };

  const getEmailTemplates = useCallback(() => {
    dispatch(listEmailTemplateAll());
  }, [dispatch]);

  const handleDelete = (e, _id) => {
    e.preventDefault();
    dispatch(deleteEmailTemplate(_id));
  };

  const handleEdit = (e, _id) => {
    e.preventDefault();
    dispatch(singleEmailTemplateDetails(_id));
    setbtnText("Update");
    setcancelHide(true);
  };

  useEffect(() => {
    if (EmailTemplateDetails) {
      setLoad(true);
      setEmailTemplateData({
        ...EmailTemplateDetails,
        MaterialTitleId: EmailTemplateDetails?.MaterialId,
      });
      extractContent(EmailTemplateDetails?.MaterialDescription);
      setEmailContent(EmailTemplateDetails?.EmailContent);
      setLoad(false);
      setbtnText("Update");
    }
  }, [EmailTemplateDetails]);

  const handleCancel = () => {
    setEmailTemplateData({ ...EmailTemplateJson });
    setEmailContent("");
    setbtnText("Save");
    setEmailData([]);
    setUploadedDesc([]);
    setcancelHide(false);
    setcreateSec(false);
  };

 

  useEffect(() => {
    getEmailTemplates();
  }, [getEmailTemplates]);

  useEffect(() => {
    if (successdet)
      setTimeout(() => {
        dispatch({ type: EMAIL_TEMPLATE_REMOVE_RESET });
      }, 2000);

    if (successud)
      setTimeout(() => {
        dispatch({ type: EMAIL_TEMPLATE_UPDATE_RESET });
      }, 2000);

    if (successsv)
      setTimeout(() => {
        dispatch({ type: EMAIL_TEMPLATE_CREATE_RESET });
      }, 2000);

    if (successdet || successsv || successud) {
      handleCancel();
      getEmailTemplates();
    }

    if (errordet || errorsv || errorud) {
      toast.error(errordet || errorsv || errorud, toastOptions);
    }
  }, [
    successdet,
    getEmailTemplates,
    successsv,
    dispatch,
    successud,
    errordet,
    errorsv,
    errorud,
  ]);

  return (
    <>
      <SidePanel page={"Email Template"} />
      <section className="home-section">
        {Load || loading || loadingsv || loadingud || loadingdet ? (
          <SEDLoading />
        ) : (
          ""
        )}
        <TopBar Heading={"Email Template"} />
        <div
          className="home-content"
          style={{ paddingTop: window.innerWidth <= 480 ? "20px" : "100px" }}
        >
          <div className="content-body">
            {/* <!--Body-content--> */}
            <div className="container-fluid">
              <div className="heading-title">
                <div className="row">
                  <div className="col-md-12">
                    <div className="home-table-search float-right d-flex">
                      {/* <!-- <button>right side</button> --> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="inner-body settings-details">
                <div className="row">
                  <div className="col-md-3">
                    <SettingLeftSection />
                  </div>
                  <div className="col-md-9">
                    <div className="body-content-with-md-9">
                      <div>
                        <div className="container">
                          <div className="row">
                            <div className="col-md-6 text-left body-content-with-md-9-title">
                              <h4>Email Template</h4>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                              className=" Create-btn col-md-6"
                            >
                              <a
                                style={{ width: "auto" }}
                                className="btn create-contact"
                                onClick={() => setcreateSec(true)}
                              >
                                Create Email Template
                              </a>
                            </div>
                          </div>
                        </div>

                        <div
                          id="CreatContact"
                          className={
                            createSec ? "overlay-note w-100" : "overlay-note"
                          }
                        >
                          <div className="overlay-add-deals contact-right-side">
                            <div className="note-title">
                              <h4 className="text-left text-white pl-3">
                                {btnText == "Update"
                                  ? "Update Email Template"
                                  : "Create Email Template"}
                              </h4>
                              <a
                                className="closebtn text-right text-white"
                                onClick={(e) => handleCancel()}
                              >
                                ×
                              </a>
                            </div>

                            <div
                              style={{ height: "95vh" }}
                              className="contact-body modify-create-contact"
                            >
                              <div className="form-group first-input">
                                <label>
                                  Email Template Name:<b>*</b>
                                </label>
                                <input
                                  type="text"
                                  className="form-control "
                                  name="TemplateName"
                                  placeholder="Template Name"
                                  value={EmailTemplateData?.TemplateName}
                                  onChange={handleChange}
                                />
                              </div>

                              <div className="form-group first-input">
                                <label>
                                  Template Content:
                                  <b>*</b>
                                </label>
                                <div>
                                  <textarea
                                    className="form-control "
                                    name="TemplateContent"
                                    style={{ height: "80px", resize: "none" }}
                                    placeholder="Enter Email Template Content"
                                    value={EmailTemplateData.TemplateContent}
                                    onChange={(e) => handleChange(e)}
                                  ></textarea>
                                  {/* <ReactQuill
                                    placeholder={"Enter Email Content"}
                                    value={EmailContent}
                                    onChange={(e) => setEmailContent(e)}
                                  /> */}
                                </div>
                              </div>

                              <div className="form-group first-input">
                                <label>
                                  Template Description :<b>*</b>
                                </label>
                                <div
                                  style={{
                                    display: "flex",
                                    gap: "5px",
                                    marginTop: "6px",
                                  }}
                                >
                                  <button
                                    onClick={() => {
                                      setEmailData([...EmailData, "InputText"]);
                                      setUploadedDesc([
                                        ...UploadedDesc,
                                        { desc: "" },
                                      ]);
                                    }}
                                    className="cst_btn"
                                  >
                                    Input Box
                                  </button>
                                  <button
                                    onClick={() => {
                                      setEmailData([...EmailData, "Image"]);
                                      setUploadedDesc([
                                        ...UploadedDesc,
                                        { ...Img },
                                      ]);
                                    }}
                                    className="cst_btn"
                                  >
                                    Image
                                  </button>
                                </div>
                                {/* <textarea
                                  className="form-control "
                                  name="TemplateDescription"
                                  style={{ height: "80px", resize: "none" }}
                                  placeholder="Enter Email Template Description"
                                  value={EmailTemplateData.TemplateDescription}
                                  onChange={(e) => handleChange(e)}
                                ></textarea> */}
                              </div>

                              {EmailData?.map((ek, i) => (
                                <div key={i + 674}>
                                  {ek == "InputText" ? (
                                    <div className="form-group ">
                                      <label>
                                        Description :<b>*</b>
                                      </label>
                                      <div>
                                        <ReactQuill
                                          placeholder={
                                            "Enter Material description"
                                          }
                                          value={UploadedDesc[i]?.desc}
                                          onChange={(e) => {
                                            let uploadDesc = [...UploadedDesc];
                                            uploadDesc[i].desc = e;
                                            setUploadedDesc([...uploadDesc]);
                                          }}
                                          required
                                        />
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "flex-end",
                                        }}
                                      >
                                        <span
                                          onClick={() => handleDescRemove(i)}
                                          style={{ color: "red" }}
                                        >
                                          <AiFillDelete />
                                        </span>
                                      </div>
                                    </div>
                                  ) : ek == "Image" ? (
                                    <div className="form-group">
                                      <label>
                                        Upload image :<b>*</b>
                                      </label>
                                      <div className="Input_With_Del">
                                        <input
                                          type="file"
                                          accept="image/*"
                                          filename={UploadedDesc[i]?.name}
                                          name={i + 7676}
                                          className="form-control"
                                          onChange={(e) => {
                                            let uploadDesc1 = [...UploadedDesc];
                                            uploadDesc1[i].image =
                                              e.target.files[0];
                                            uploadDesc1[i].name =
                                              e.target.files[0].name;
                                            uploadDesc1[i].url = "";
                                            const reader = new FileReader();

                                            reader.onload = (e) =>
                                              (uploadDesc1[i].preview =
                                                e.target.result);
                                            reader.readAsDataURL(
                                              e.target.files[0]
                                            );

                                            setUploadedDesc([...uploadDesc1]);
                                          }}
                                        />
                                        <span
                                          onClick={() => handleDescRemove(i)}
                                          style={{ color: "red" }}
                                        >
                                          <AiFillDelete />
                                        </span>
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ))}

                              <div
                                // className="contact-footer w-100"
                                style={{
                                  // padding: "50px 25px",
                                  backgroundColor: "transparent",
                                  marginTop: "26px",
                                }}
                              ></div>

                              <div className="contact-footer w-100">
                                <p>
                                  <a
                                    className="btn btn-deep-orange btn-md"
                                    onClick={(e) => handleSubmit(e)}

                                  >
                                    {loadingsv || loadingud || loadingdet
                                      ? "Loading..."
                                      : btnText}
                                  </a>
                                  <button
                                    type="button"
                                    className="btn btn-deep-orange btn-md"
                                    onClick={() => handlePreview()}
                                  >
                                    Preview
                                  </button>
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                  {/* {cancelHide ? ( */}
                                  <a
                                    className="btn btn-outline-deep-orange btn-md"
                                    onClick={() => handleCancel()}
                                  >
                                    Cancel
                                  </a>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="container">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="table-header-md-9 table-responsive">
                                <table className="table table-striped table-bordered">
                                  <thead>
                                    <tr>
                                      <th>Template Name</th>
                                      <th>Template Description</th>
                                      <th>Actions</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {EmailTemplateAllRec ? (
                                      EmailTemplateAllRec.map((dd) => (
                                        <tr key={dd._id}>
                                          <td>{dd?.TemplateName}</td>
                                          <td>{dd.TemplateDescription}</td>
                                          <td align="center">
                                            <input
                                              type="button"
                                              className="btn btn-info btn-sm"
                                              value={
                                                loadingud
                                                  ? "Loading.."
                                                  : "Update"
                                              }
                                              disabled={loadingud}
                                              onClick={(e) => {
                                                handleEdit(
                                                  e,
                                                  dd._id,
                                                  dd?.TemplateName,
                                                  dd.TemplateDescription,
                                                  dd.EmailContent
                                                );
                                                setcreateSec(true);
                                                setbtnText("Update");
                                              }}
                                            />
                                            &nbsp;&nbsp;&nbsp;
                                            <input
                                              type="button"
                                              className="btn btn-info btn-sm"
                                              value={
                                                loadingdet
                                                  ? "Loading.."
                                                  : "Delete"
                                              }
                                              disabled={loadingdet}
                                              onClick={(e) => {
                                                setDeleteAlert({
                                                  msg: `Do you really want to delete this sub title (${dd.MaterialSubTitle}) ?`,
                                                  Delete: () =>
                                                    handleDelete(e, dd._id),
                                                });
                                                setOpenNext(true);
                                              }}
                                            />
                                          </td>
                                        </tr>
                                      ))
                                    ) : (
                                      <tr>
                                        <td colSpan={2}>{error}</td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!--/ body-content-with-md-9 --> */}
                  </div>
                  {/* <!--/ col-md-9 --> */}
                </div>
              </div>
            </div>
            <DeletePopup
              OpenNext={OpenNext}
              setOpenNext={setOpenNext}
              DeleteData={DeleteAlert}
            />
          </div>
          <ToastContainer />
        </div>
      </section>
    </>
  );
}
