export const USER_ROLE_LIST_REQUEST = 'USER_ROLE_LIST_REQUEST'
export const USER_ROLE_LIST_SUCCESS = 'USER_ROLE_LIST_SUCCESS'
export const USER_ROLE_LIST_FAIL = 'USER_ROLE_LIST_FAIL'

export const USER_ROLE_INSERT_REQUEST = 'USER_ROLE_INSERT_REQUEST'
export const USER_ROLE_INSERT_SUCCESS = 'USER_ROLE_INSERT_SUCCESS'
export const USER_ROLE_INSERT_FAIL = 'USER_ROLE_INSERT_FAIL'
export const USER_ROLE_INSERT_RESET = 'USER_ROLE_INSERT_RESET'

export const USER_ROLE_UPDATE_REQUEST = 'USER_ROLE_UPDATE_REQUEST'
export const USER_ROLE_UPDATE_SUCCESS = 'USER_ROLE_UPDATE_SUCCESS'
export const USER_ROLE_UPDATE_FAIL = 'USER_ROLE_UPDATE_FAIL'
export const USER_ROLE_UPDATE_RESET = 'USER_ROLE_UPDATE_RESET'

export const USER_ROLE_DELETE_REQUEST = 'USER_ROLE_DELETE_REQUEST'
export const USER_ROLE_DELETE_SUCCESS = 'USER_ROLE_DELETE_SUCCESS'
export const USER_ROLE_DELETE_FAIL = 'USER_ROLE_DELETE_FAIL'
export const USER_ROLE_DELETE_RESET = 'USER_ROLE_DELETE_RESET'
