import {
  LIFECYCLE_LIST_FAIL,
  LIFECYCLE_LIST_REQUEST,
  LIFECYCLE_LIST_SUCCESS,
} from "../Constants/lifecycleConstants";

export const lifeCycleListReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case LIFECYCLE_LIST_REQUEST: {
      return { loading: true };
    }

    case LIFECYCLE_LIST_SUCCESS: {
      return { loading: false, lifeCycleRec: payload };
    }

    case LIFECYCLE_LIST_FAIL: {
      return { laoding: false, error: payload };
    }

    default:
      return state;
  }
};
