import SidePanel from "../../Components/SidePanel";
import TopBar from "../../Components/TopBar";
import { useDispatch, useSelector } from "react-redux";
import Meta from "../../Components/Meta";
import { useCallback, useEffect, useRef, useState } from "react";
import { contactJson } from "../../Models/ContactModels";
import { toastOptions } from "../../Models/ToastEditiorOption";
import { toast } from "react-toastify";
import { MATERIAL_CREATE_RESET } from "../../Constants/materialConstants";
import CourseDropDown from "../Components/CourseDropDown";
import {
  createOrderDet,
  listOrderRec,
  totalListOrderRec,
} from "../../Actions/orderAction";
import Pagination from "../Components/Pagination";
import { Link } from "react-router-dom";

export default function Order() {
  const [orderCreateData, setorderCreateData] = useState(contactJson);
  const [MobileFilterActive, setMobileFilterActive] = useState(false);
  const [createSec, setCreateSec] = useState(false);
  const [showActiveSec, setShowActiveSec] = useState("primary");
  const [orderFilterData, setorderFilterData] = useState({
    page_size: 10,
    page_no: 1,
  });
  const mobile_View = window.innerWidth;
  const { loading, error, orderRecs } = useSelector((s) => s.listOrderRducer);
  const { total } = useSelector((s) => s.totalListOrderReducer);

  const ErrorSuccessRef = useRef();

  const handleCancel = () => {
    setCreateSec(false);
  };

  const {
    loading: loadingsv,
    error: errorsv,
    success: successsv,
  } = useSelector((s) => s.createMaterialReducer);

  const dispatch = useDispatch();

  const MetaDes = { title: "Testfify - Orders" };

  const Get_Orders = useCallback(() => {
    dispatch(listOrderRec(orderFilterData));
    dispatch(totalListOrderRec());
  }, [dispatch, orderFilterData]);

  const handleChange = (e) => {
    setorderCreateData({
      ...orderCreateData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(createOrderDet(orderCreateData));
  };

  useEffect(() => {
    Get_Orders();
  }, [Get_Orders]);

  useEffect(() => {
    if (successsv) {
      setCreateSec(false);
      setorderCreateData(contactJson);
      toast.success(successsv, toastOptions);
      Get_Orders();
      setTimeout(() => {
        dispatch({ type: MATERIAL_CREATE_RESET });
      }, 2000);
    }
    if (errorsv) {
      toast.success(errorsv, toastOptions);
    }
  }, [successsv, errorsv, dispatch, Get_Orders]);

  return (
    <>
      <Meta MetaDes={MetaDes} />
      <SidePanel page={"Orders"} />
      <section className="home-section">
        <TopBar Heading="Orders" />
        <div className="home-content" style={{ paddingTop: "70px" }}>
          <div className="Contact-Middle">
            <div className="Contact_Top_Header">
              <div className="home-title float-left"></div>
              <div style={{ display: "flex", gap: "20px" }} className="">
                <div className="input-group search-contacts">
                  <input
                    type="Search"
                    className="form-control"
                    name="searchtxt"
                    style={{ display: "block" }}
                    // value={Search}
                    // onChange={(e) => {
                    //   if (e.target.value === "") {
                    //     setFilterData({ ...FilterData, searchtxt: "" });
                    //     setorderFilterData({
                    //       ...orderFilterData,
                    //       searchtxt: "",
                    //     });
                    //   }
                    //   setSearch(e.target.value);
                    // }}
                    // onKeyDown={(e) => {
                    //   if (
                    //     e.key === "Enter" &&
                    //     Search !== "" &&
                    //     Search !== undefined
                    //   ) {
                    //     setFilterData({ ...FilterData, searchtxt: Search });
                    //     setorderFilterData({
                    //       ...orderFilterData,
                    //       searchtxt: Search,
                    //     });
                    //   }
                    // }}
                    placeholder="search for order"
                  />
                  <i
                    className="fa fa-search"
                    // onClick={() => {
                    //   if (Search !== "" && Search !== undefined) {
                    //     setFilterData({ ...FilterData, searchtxt: Search });
                    //     setorderFilterData({
                    //       ...orderFilterData,
                    //       searchtxt: Search,
                    //     });
                    //   }
                    // }}
                    aria-hidden="true"
                  ></i>
                </div>{" "}
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-md-4 col-lg-3">
                <div
                  style={
                    mobile_View <= 480
                      ? { marginBottom: "20px" }
                      : { cursor: "auto" }
                  }
                  className="add-filter"
                >
                  <div
                    className={
                      MobileFilterActive
                        ? "filter-title  filter-title-mob filter-body-active"
                        : "filter-title  filter-title-mob"
                    }
                    style={{ cursor: "auto" }}
                  >
                    <h5>Filter Orders</h5>
                    <button
                      onClick={() => setMobileFilterActive(false)}
                      className="mob-nav-open"
                    >
                      ☰
                    </button>
                  </div>

                  <div
                    className={
                      MobileFilterActive
                        ? "filter-body filter-body-mob  filter-body-active custom-scroll-right pt-0"
                        : "filter-body filter-body-mob custom-scroll-right pt-0"
                    }
                    style={{ cursor: "auto" }}
                  >
                    <div className="sticky pt-2">
                      <p style={{ marginBottom: "0px" }}>
                        showing {total ? total : 0} Orders
                      </p>

                      <button
                        style={{ color: "white" }}
                        className="btn btn-sm btn-deep-orange"
                        onClick={() => {
                          //   setFilterData({
                          //     ...contactFilterJson,
                          //     Assigned_User_Id: userId,
                          //   });
                          setorderFilterData({
                            // ...contactFilterJson,
                            // Assigned_User_Id: userId,
                          });
                          setShowActiveSec("primary");
                        }}
                      >
                        Clear All
                      </button>
                    </div>
                    <ul className="modify-filter-title"></ul>
                  </div>
                </div>
              </div>

              <div className="col-md-8 col-lg-9">
                <div className="Contact-table-wrapper">
                  <table>
                    <thead>
                      <tr className="sticky">
                        <th>Contact Name</th>
                        <th>Email Address</th>
                        <th> Phone No</th>
                        <th>Amount</th>
                        <th>Transucation Status </th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <tr>
                          <td colSpan={10}>Laoding... </td>
                        </tr>
                      ) : error ? (
                        <tr>
                          <td colSpan={10}>{error}</td>
                        </tr>
                      ) : (
                        orderRecs &&
                        orderRecs?.map((ev) => (
                          <tr key={ev._id}>
                            <td>
                              <Link
                                style={{ textDecoration: "none" }}
                                to={`/contact/details/${ev?.ContactId}`}
                                target="_blank"
                              >
                                {(ev?.First_Name ? ev?.First_Name : "") +
                                  " " +
                                  (ev?.Last_Name ? ev?.Last_Name : "")}
                              </Link>
                            </td>
                            <td>
                              <Link
                                style={{ textDecoration: "none" }}
                                to={`/contact/details/${ev?.ContactId}`}
                                target="_blank"
                              >
                                {ev?.EmailAddress}
                              </Link>
                            </td>
                            <td>
                              <Link
                                style={{ textDecoration: "none" }}
                                to={`/contact/details/${ev?.ContactId}`}
                                target="_blank"
                              >
                                {ev?.PhoneNo}
                              </Link>
                            </td>
                            <td style={{ color: "blue" }}>{ev?.Amount}</td>
                            <td style={{ display: "flex", gap: "10px" }}>
                              <span style={{ width: "100px" }}>
                                {" "}
                                {ev?.transactionStatus}
                              </span>
                              <span
                                style={{
                                  width: "100px",
                                  display: "flex",
                                  justifyContent: "flex-start",
                                }}
                              >
                                <Link to={`/Order/details/${ev._id}`}>
                                  Check Details
                                </Link>
                              </span>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
                <div>
                  <Pagination
                    setFilterQuery={setorderFilterData}
                    FilterQuery={orderFilterData}
                    Total={total}
                  />
                </div>

                <div
                  id="CreatContact"
                  className={createSec ? "overlay-note w-100" : "overlay-note"}
                >
                  <div className="overlay-add-deals contact-right-side">
                    <div className="note-title">
                      <h4 className="text-left text-white pl-3">
                        Create Material
                      </h4>
                      <a
                        className="closebtn text-right text-white"
                        onClick={(e) => setCreateSec(false)}
                      >
                        ×
                      </a>
                    </div>

                    <div className="contact-body modify-create-contact">
                      {/* <div className="form-group">
                        <label>
                          Enquiry for:<b>*</b>
                        </label>
                        <select
                          className="form-control"
                          name="Enquiry_For"
                          value={orderCreateData.Enquiry_For}
                          onChange={(e) => handleChange(e)}
                        >
                          <option>-- Select Enquiry For --</option>
                          <EnquiryForDropdown />
                        </select>
                      </div>

                      <div className="form-group">
                        <label>
                          Lead source:<b>*</b>
                        </label>
                        <select
                          className="form-control"
                          name="Lead_Source"
                          value={orderCreateData.Lead_Source}
                          onChange={(e) => handleChange(e)}
                        >
                          <option>-- Select Lead Source --</option>
                          <LeadSourceDropdown />
                        </select>
                      </div> */}
                      <div className="form-group">
                        <label>
                          Material Title:<b>*</b>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Materia lTitle"
                          name="MaterialTitle"
                          value={orderCreateData.MaterialTitle}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>

                      <div className="form-group">
                        <label>
                          Material Detail:<b>*</b>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Material Detail"
                          name="MaterialDetail"
                          value={orderCreateData.MaterialDetail}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>

                      <div className="form-group first-input">
                        <label>
                          Course Description:<b>*</b>
                        </label>
                        {/* <button className="first-btn-plus" value="+">
                          +{" "}
                        </button> */}
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Material Description"
                          name="MaterialDescription"
                          value={orderCreateData.MaterialDescription}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>

                      <div
                        // className="contact-footer w-100"
                        style={{
                          // padding: "50px 25px",
                          backgroundColor: "transparent",
                          marginTop: "26px",
                        }}
                        ref={ErrorSuccessRef}
                      ></div>

                      <div className="form-group first-input">
                        <label>
                          Course Id:<b>*</b>
                        </label>

                        <select
                          type="text"
                          className="form-control"
                          name="Course_Id"
                          value={orderCreateData.Course_Id}
                          onChange={(e) => handleChange(e)}
                        >
                          <option>Select Course</option>
                          <CourseDropDown />
                        </select>
                      </div>
                      <div style={{ height: "215px" }}></div>

                      <div className="contact-footer w-100">
                        <div>
                          <button
                            type="button"
                            className="btn"
                            onClick={handleSubmit}
                          >
                            {loadingsv ? "Loading..." : "Create Material"}
                          </button>
                          {/* <button type="button" className="btn">
                  Create and add another
                </button> */}
                          <button
                            type="button"
                            className="btn btn-deep-orange waves-effect waves-light"
                            onClick={handleCancel}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
