import {
  MOCKTEST_CREATE_FAILED,
  MOCKTEST_CREATE_REQ,
  MOCKTEST_CREATE_RESET,
  MOCKTEST_CREATE_SUCCESS,
  MOCKTEST_DETAILS_FAILED,
  MOCKTEST_DETAILS_REQ,
  MOCKTEST_DETAILS_RESET,
  MOCKTEST_DETAILS_SUCCESS,
  MOCKTEST_LIST_FAILED,
  MOCKTEST_LIST_REQ,
  MOCKTEST_LIST_SUCCESS,
  MOCKTEST_REMOVE_FAILED,
  MOCKTEST_REMOVE_REQ,
  MOCKTEST_REMOVE_RESET,
  MOCKTEST_REMOVE_SUCCESS,
  MOCKTEST_TOTAL_FAILED,
  MOCKTEST_TOTAL_REQ,
  MOCKTEST_TOTAL_SUCCESS,
  MOCKTEST_UPDATE_FAILED,
  MOCKTEST_UPDATE_REQ,
  MOCKTEST_UPDATE_RESET,
  MOCKTEST_UPDATE_SUCCESS,
} from "../Constants/MockTestConstant";

export const createMockTestReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case MOCKTEST_CREATE_REQ: {
      return { loading: true };
    }

    case MOCKTEST_CREATE_SUCCESS: {
      return { loading: false, success: payload };
    }

    case MOCKTEST_CREATE_FAILED: {
      return { loading: false, error: payload };
    }

    case MOCKTEST_CREATE_RESET: {
      return { success: "", error: "" };
    }

    default:
      return state;
  }
};

export const listMockTestReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case MOCKTEST_LIST_REQ: {
      return { loading: true };
    }

    case MOCKTEST_LIST_SUCCESS: {
      return { loading: false, mockTestRecs: payload };
    }

    case MOCKTEST_LIST_FAILED: {
      return { loading: false, error: payload };
    }
    default:
      return state;
  }
};

export const updateMockTestReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case MOCKTEST_UPDATE_REQ: {
      return { loading: true };
    }

    case MOCKTEST_UPDATE_SUCCESS: {
      return { loading: false, success: payload };
    }

    case MOCKTEST_UPDATE_FAILED: {
      return { loading: false, error: payload };
    }

    case MOCKTEST_UPDATE_RESET: {
      return { loading: false, error: "" };
    }
    default:
      return state;
  }
};

export const removeMockTestReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case MOCKTEST_REMOVE_REQ: {
      return { loading: false };
    }

    case MOCKTEST_REMOVE_SUCCESS: {
      return { loading: false, success: payload };
    }

    case MOCKTEST_REMOVE_FAILED: {
      return { loading: false, error: payload };
    }

    case MOCKTEST_REMOVE_RESET: {
      return { loading: false, success: "", error: "" };
    }

    default:
      return state;
  }
};

export const mockTestDetailsReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case MOCKTEST_DETAILS_REQ: {
      return { loading: true };
    }

    case MOCKTEST_DETAILS_SUCCESS: {
      return { loading: false, DetMockTest: payload };
    }

    case MOCKTEST_DETAILS_FAILED: {
      return { loading: false, error: payload };
    }

    case MOCKTEST_DETAILS_RESET: {
      return { loading: false, success: "", error: "" };
    }
    default:
      return state;
  }
};

export const totalMockTestReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case MOCKTEST_TOTAL_REQ: {
      return { loading: true };
    }

    case MOCKTEST_TOTAL_SUCCESS: {
      return { loading: false, total: payload };
    }

    case MOCKTEST_TOTAL_FAILED: {
      return { loading: false, error: payload };
    }

    default:
      return state;
  }
};
