export const EmailTemplateJson = {
  TemplateName: "",
  TemplateDescription: "",
  TemplateContent: "",
};

export const OpenTemplete = `<html lang="en">
<head>
    <meta charset="UTF-8" />
    <!-- <meta http-equiv="X-UA-Compatible" content="IE=edge"> -->
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Document</title>
    <script src="https://kit.fontawesome.com/572856962d.js" crossorigin="anonymous"></script>
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap" rel="stylesheet" />
    <style>
        body {
            font-family: "poppins" !important;
        }

        .header-img {
            height: 200px;
        }

        .book-my-seat {
            margin-left: 34%;
            padding: 10px 18px
        }



        .text_size {
            font-size: 14px;
        }

        .text_size2 {
            font-size: 12px;
        }

        .footer-img {
            background-size: 100%;
        }

        .footer-img2 {
            display: none;
        }

        table {
            border-collapse: separate;
        }

        @media only screen and (max-width: 500px) and (min-width: 200px) {

            .footer-img {
                background-size: 100%;
                display: none
            }

            .footer-img2 {
                display: block;
            }


            .header-img {
                background-size: contain !important;
                width: 100%;
                height: 120px;
            }

            .text_size {
                font-size: 12px;
            }
            .book-my-seat {
                margin-left: 26%;
                padding: 6px 12px
            }

            .header-img h1 {
                font-size: 20px;
            }

            .header-p2 {
                display: flex;
                margin: 70px 18px !important;
                width: 80px !important;
                letter-spacing: 20px;
            }

            .header-p1 {
                margin: 50px 0px 20px 20px !important;
            }

            .mgt0 {
                margin: -60px 20px 30px 0px !important;
            }

            .pd3 {
                padding: 0px 2px;
            }

            .Top_Name {
                margin-top: 10px;
            }
        }
    </style>
</head>

<body class="viewframe"> <div class="margin: 0 auto; padding: 0;">
        <table style="
          max-width: 621px;
          border: 1px solid #d2d2d2;
          box-shadow: 0 5px 20px 0 rgba(12, 73, 84, 0.2);
          background-color: #fff;
          width: 100%;" align="center">
            <tr>
                <td id='Htmlcontent'>`;

export const CloseTemplate = `</td></tr></table></body></html>`;

export const BulkEmailJson = {
  Branch: "",
  Qualifies: "",
  DateFrom: "",
  DateTill: "",
  EmailSubject: "",
  EmailBody: "",
};

export const EmailTemplate_Validator = (data) => {
  let Error = "";

  //   if (!data?.TemplateName) {
  //     Error += "Template Name is require !";
  //   }

  //   if (!data?.TemplateDescription) {
  //     Error += " Template Description is required !";
  //   }

  //   if (
  //     !data?.TemplateContent &&
  //     !data?.TemplateContent?.replace(/(<([^>]+)>)/gi, "").trim()?.length
  //   ) {
  //     Error += " Template Content is required !";
  //   }

  if (Error) {
    return "Error - " + Error;
  } else {
    return "success";
  }
};
