import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listGender } from "../../Actions/genderActions";

// export default function GenderDropdown() {
//   const dispatch = useDispatch();
//   const { genderRecs } = useSelector((s) => s.genderList);
//   useEffect(() => {
//     dispatch(listGender());
//   }, []);
//   return genderRecs ? (
//     genderRecs.map((recs) => (
//       <option value={recs._id} key={recs._id}>
//         {recs.Gender}
//       </option>
//     ))
//   ) : (
//     <></>
//   );
// }

const GenderDropdown = React.memo(() => {
  const dispatch = useDispatch();
  const { genderRecs } = useSelector((s) => s.genderList);
  useEffect(() => {
    dispatch(listGender());
  }, []);
  return genderRecs ? (
    genderRecs.map((recs) => (
      <option value={recs._id} key={recs._id}>
        {recs.Gender}
      </option>
    ))
  ) : (
    <></>
  );
});
export default GenderDropdown;
