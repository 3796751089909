export const CAMPUS_LIST_REQUEST = 'CAMPUS_LIST_REQUEST'
export const CAMPUS_LIST_SUCCESS = 'CAMPUS_LIST_SUCCESS'
export const CAMPUS_LIST_FAIL = 'CAMPUS_LIST_FAIL'

export const CAMPUS_INSERT_REQUEST = 'CAMPUS_INSERT_REQUEST'
export const CAMPUS_INSERT_SUCCESS = 'CAMPUS_INSERT_SUCCESS'
export const CAMPUS_INSERT_FAIL = 'CAMPUS_INSERT_FAIL'
export const CAMPUS_INSERT_RESET = 'CAMPUS_INSERT_RESET'

export const CAMPUS_UPDATE_REQUEST = 'CAMPUS_UPDATE_REQUEST'
export const CAMPUS_UPDATE_SUCCESS = 'CAMPUS_UPDATE_SUCCESS'
export const CAMPUS_UPDATE_FAIL = 'CAMPUS_UPDATE_FAIL'
export const CAMPUS_UPDATE_RESET = 'CAMPUS_UPDATE_RESET'

export const CAMPUS_DELETE_REQUEST = 'CAMPUS_DELETE_REQUEST'
export const CAMPUS_DELETE_SUCCESS = 'CAMPUS_DELETE_SUCCESS'
export const CAMPUS_DELETE_FAIL = 'CAMPUS_DELETE_FAIL'
export const CAMPUS_DELETE_RESET = 'CAMPUS_DELETE_RESET'


export const CAMPUS_USING_UNOVERSITY_LIST_REQ="CAMPUS_USING_UNOVERSITY_LIST_REQ";
export const CAMPUS_USING_UNOVERSITY_LIST_SUUCESS="CAMPUS_USING_UNOVERSITY_LIST_SUUCESSs";
export const CAMPUS_USING_UNOVERSITY_LIST_RFAIL="CAMPUS_USING_UNOVERSITY_LIST_RFAIL";