import {
  FORM_LIST_FAIL,
  FORM_LIST_REQUEST,
  FORM_LIST_SUCCESS
} from "../Constants/formConstants";

export const formListReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case FORM_LIST_REQUEST:
      return { loading: true };
    case FORM_LIST_SUCCESS:
      return { loading: false, formRecs: payload };
    case FORM_LIST_FAIL:
      return { loading: false, error: payload };
    default:
      return state;
  }
};
