import {
  CONTACT_ACTIVITY_LIST_FAIL,
  CONTACT_ACTIVITY_LIST_REQUEST,
  CONTACT_ACTIVITY_LIST_SUCCESS,
  DEAL_ACTIVITY_LIST_FAIL,
  DEAL_ACTIVITY_LIST_REQUEST,
  DEAL_ACTIVITY_LIST_SUCCESS,
  SUBAGENT_ACTIVITY_LIST_FAIL,
  SUBAGENT_ACTIVITY_LIST_REQUEST,
  SUBAGENT_ACTIVITY_LIST_SUCCESS,
} from "../Constants/activityConstants";
import api from "../core/api";
const Time = (time) => {
  return new Date(time);
};

export const getContactActivity = (contactId) => async (dispatch) => {
  try {
    dispatch({ type: CONTACT_ACTIVITY_LIST_REQUEST });
    const { data } = await api.get(
      `/contact/activity/${contactId}`
    );
    const response = data.Result;
   
    let NewRes = await response?.sort(
      (a, b) => Time(b.createdAt) - Time(a.createdAt)
    );

    dispatch({ type: CONTACT_ACTIVITY_LIST_SUCCESS, payload: NewRes });
  } catch (error) {
   
    dispatch({
      type: CONTACT_ACTIVITY_LIST_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const getDealActivity = (dealId) => async (dispatch) => {
  try {
    dispatch({ type: DEAL_ACTIVITY_LIST_REQUEST });

    const { data } = await api.get(`/activities/GetDealActivity/${dealId}`);

    const response = data.Result;
    let NewRes = await response?.sort(
      (a, b) => Time(b.createdAt) - Time(a.createdAt)
    );

    dispatch({ type: DEAL_ACTIVITY_LIST_SUCCESS, payload: NewRes });
  } catch (error) {
    dispatch({
      type: DEAL_ACTIVITY_LIST_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const getSubAgentActivity = (subagentId) => async (dispatch) => {
  try {
    dispatch({ type: SUBAGENT_ACTIVITY_LIST_REQUEST });

    const { data } = await api.get(
      `/activities/getsubagentactivity/${subagentId}`
    );
    const response = data?.Result;
    let NewRes = await response?.sort(
      (a, b) => Time(b.createdAt) - Time(a.createdAt)
    );
    dispatch({
      type: SUBAGENT_ACTIVITY_LIST_SUCCESS,
      payload: NewRes,
    });
  } catch (error) {
    dispatch({
      type: SUBAGENT_ACTIVITY_LIST_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};
