export const MARITAL_STATUS_LIST_REQUEST = 'MARITAL_STATUS_LIST_REQUEST'
export const MARITAL_STATUS_LIST_SUCCESS = 'MARITAL_STATUS_LIST_SUCCESS'
export const MARITAL_STATUS_LIST_FAIL = 'MARITAL_STATUS_LIST_FAIL'

export const MARITAL_STATUS_INSERT_REQUEST = 'MARITAL_STATUS_INSERT_REQUEST'
export const MARITAL_STATUS_INSERT_SUCCESS = 'MARITAL_STATUS_INSERT_SUCCESS'
export const MARITAL_STATUS_INSERT_FAIL = 'MARITAL_STATUS_INSERT_FAIL'
export const MARITAL_STATUS_INSERT_RESET = 'MARITAL_STATUS_INSERT_RESET'

export const MARITAL_STATUS_UPDATE_REQUEST = 'MARITAL_STATUS_UPDATE_REQUEST'
export const MARITAL_STATUS_UPDATE_SUCCESS = 'MARITAL_STATUS_UPDATE_SUCCESS'
export const MARITAL_STATUS_UPDATE_FAIL = 'MARITAL_STATUS_UPDATE_FAIL'
export const MARITAL_STATUS_UPDATE_RESET = 'MARITAL_STATUS_UPDATE_RESET'

export const MARITAL_STATUS_DELETE_REQUEST = 'MARITAL_STATUS_DELETE_REQUEST'
export const MARITAL_STATUS_DELETE_SUCCESS = 'MARITAL_STATUS_DELETE_SUCCESS'
export const MARITAL_STATUS_DELETE_FAIL = 'MARITAL_STATUS_DELETE_FAIL'
export const MARITAL_STATUS_DELETE_RESET = 'MARITAL_STATUS_DELETE_RESET'
