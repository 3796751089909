import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  getContactActivity,
  getDealActivity,
} from "../../Actions/activityActions";
import { FaListCheck } from "react-icons/fa6";
import { deleteCallLogDetails } from "../../Actions/callLogActions";
import { deleteEmailLogDetails } from "../../Actions/emailLogActions";
import { deleteNoteLogDetails } from "../../Actions/noteLogActions";
import { MdOutlineTaskAlt } from "react-icons/md";
import { BsFillPinFill } from "react-icons/bs";
import { TimeStampConverter2 } from "./EmailHide";
import CallOutcomeDropdown from "./CallOutcomeDropdown";
import { GET_TIME_ZONE } from "./Time";
import UserDropdown from "./UserDropdown";
import {
  TaskLogReminder,
  deleteTaskLogDetails,
} from "../../Actions/taskLogActions";
import { FaRegEdit } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { MdCall } from "react-icons/md";

export default function ActivityList({
  contactId,
  dealId,
  setEditNote,
  openNoteTab,
  setEmailEdit,
  openEmailTab,
  setCallEdit,
  openCallTab,
  setEditTask,
  openTaskTab,
}) {
  const dispatch = useDispatch();
  const [activityData, setActivityData] = useState([]);
  const { activityContactRec } = useSelector((s) => s.activtyContactList);
  const { activityDealRec } = useSelector((s) => s.activityDealList);
  const { success: successClt } = useSelector((s) => s.callLogDelete);
  const { success: successElt } = useSelector((s) => s.emailLogDelete);
  const { success: successNlt } = useSelector((s) => s.noteLogDelete);
  const { success: successTlt } = useSelector((s) => s.taskLogDelete);
  const {
    loading: loadingRmnd,
    success: successRmnd,
    error: errorRmnd,
  } = useSelector((s) => s.taskLogReminder);
  const Today = new Date();
  const getActivities = useCallback(() => {
    if (contactId) {
      dispatch(getContactActivity(contactId));
    } else {
      dispatch(getDealActivity(dealId));
    }
  }, [dispatch, contactId, dealId]);

  useEffect(() => {
    if (!activityContactRec && !activityDealRec) {
      getActivities();
    }
    if (activityContactRec || contactId) {
      if (activityContactRec?.length > 0) {
        setActivityData(activityContactRec);
      }
    } else if (activityDealRec || dealId) {
      if (activityDealRec?.length > 0) {
        setActivityData(activityDealRec);
      }
    } else {
      activityContactRec([]);
    }
  }, [activityContactRec, contactId, dealId, activityDealRec]);

  const handleDeleteCall = (Id) => {
    dispatch(deleteCallLogDetails(Id));
  };

  const handleDeleteTask = (Id) => {
    dispatch(deleteTaskLogDetails(Id));
  };

  const handleDeleteEmail = (Id) => {
    dispatch(deleteEmailLogDetails(Id));
  };

  const handleDeleteNote = (Id) => {
    dispatch(deleteNoteLogDetails(Id));
  };

  useEffect(() => {
    if (successClt || successElt || successNlt || successTlt || successRmnd) {
      getActivities();
    }
  }, [
    successClt,
    successElt,
    successNlt,
    successTlt,
    successRmnd,
    getActivities,
  ]);

  // };

  // useEffect(() => {
  //   if (successdlt) {
  //     if (contactId) dispatch(listNoteLogContact());
  //     else dispatch(listNoteLogDeal());
  //   }
  // }, [successdlt]);
  return (
    <>
      <div className="activity-body">
        <h6
          style={{
            textTransform: "uppercase",
            marginTop: "5px",
            color: "#6c6c6c",
            paddingLeft: "18px",
            fontSize: "14px",
            fontWeight: "500",
          }}
        >
          {/* June 2020 */}
        </h6>
        {activityData?.map((el) => {
          if (el?.Call_Outcome) {
            return (
              <div key={el._id} className="output-task-details">
                <div className="output-task-header">
                  <div className="row">
                    <div className="col-md-6 text-left">
                      <span style={{ fontSize: "20px", marginRight: "7px" }}>
                        <MdCall />
                      </span>
                      <h5>Logged call</h5>
                    </div>
                    <div className="col-md-6 text-right">
                      <span>{GET_TIME_ZONE(el.createdAt)}</span>
                      <ul className="list-inline right-action-menu">
                        <li className="list-inline-item">
                          <Link
                            onClick={() => {
                              setCallEdit(el);
                              openCallTab();
                            }}
                          >
                            Edit
                          </Link>
                        </li>
                        <li className="list-inline-item">
                          <Link onClick={() => handleDeleteCall(el._id)}>
                            Delete
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="col-md-12 pl-5 pt-3">
                      <div
                        contentEditable="false"
                        dangerouslySetInnerHTML={{
                          __html: el?.Call_Description,
                        }}
                      ></div>
                    </div>
                  </div>
                  <hr />
                </div>

                <div>
                  <div className="output-task-body pl-4">
                    <div className="row">
                      <div className="col-md-3">
                        <span>Contacted</span>
                        <div className="form-group">
                          <p style={{ color: "#0c3eff" }} className=" w-100">
                            {" "}
                            {el?.Contact_Id?.First_Name
                              ? el?.Contact_Id?.First_Name +
                                " " +
                                el?.Contact_Id?.Last_Name
                              : el?.Contact_Id?.full_name
                              ? el?.Contact_Id?.full_name
                              : ""}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <span>Call outcome</span>
                        <div className="form-group">
                          <select
                            value={el?.Call_Outcome}
                            className="form-control w-100"
                            style={{ padding: "5px 0" }}
                            disabled={true}
                          >
                            <option value="">N/A</option>
                            <CallOutcomeDropdown />
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <span>Date</span>
                        <input
                          type="date"
                          name="task-date"
                          value={
                            el?.Call_Date
                              ? TimeStampConverter2(el.Call_Date)
                              : ""
                          }
                          className="w-100"
                          disabled={true}
                          style={{ border: "none" }}
                        />
                      </div>
                      <div className="col-md-3">
                        <span>Time</span>
                        <input
                          name="task-time"
                          className="w-100"
                          value={el.Call_Time}
                          disabled={true}
                          style={{ border: "none" }}
                        />
                      </div>
                      <div className="col-md-12">
                        <hr />
                      </div>
                      <div className="col-md-12 pl-5">
                        <h6>
                          <i
                            className="fas fa-user-circle"
                            style={{ color: "#d1d4d4" }}
                          ></i>
                          <b style={{ fontWeight: "500" }}>
                            {" "}
                            {el?.Created_By?.First_Name
                              ? el?.Created_By?.First_Name +
                                " " +
                                el?.Created_By?.Last_Name
                              : ""}
                          </b>{" "}
                          logged a call
                        </h6>
                      </div>
                    </div>
                    <hr />
                  </div>
                  <div className="output-task-footer d-flex">
                    <i
                      className="fas fa-comment-dots"
                      style={{ cursor: "pointer" }}
                    ></i>
                    <p>Add Comment</p>
                  </div>
                </div>
              </div>
            );
          } else if (el?.Email_Subject) {
            return (
              <div key={el._id} className="output-task-details show">
                <div className="output-task-header">
                  <div className="row">
                    <div className="col-md-6 text-left">
                      <span style={{ fontSize: "20px", marginRight: "7px" }}>
                        <HiOutlineMail />
                      </span>
                      <h5>Logged email</h5>
                    </div>
                    <div className="col-md-6 text-right">
                      <span>{GET_TIME_ZONE(el.createdAt)}</span>
                      <ul className="list-inline right-action-menu">
                        <li className="list-inline-item">
                          <Link
                            onClick={() => {
                              openEmailTab();
                              setEmailEdit({ ...el });
                            }}
                          >
                            Edit
                          </Link>
                        </li>
                        <li className="list-inline-item">
                          <Link onClick={() => handleDeleteEmail(el._id)}>
                            Delete
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="col-md-12 pl-5 pt-3">
                      <p>
                        Subject:{"  "} {el.Email_Subject}
                      </p>
                      <p>
                        Message:{"  "}
                        <div
                          contentEditable="false"
                          dangerouslySetInnerHTML={{
                            __html: el.Email_Message,
                          }}
                        ></div>
                      </p>
                    </div>
                  </div>
                  <hr />
                </div>
                <div className="output-task-body pl-4">
                  <div className="row">
                    <div className="col-md-4">
                      <span>Contacted</span>
                      <div className="form-group">
                        <p style={{ color: "#0c3eff" }} className=" w-100">
                          {" "}
                          {el?.Contact_Id?.First_Name
                            ? el?.Contact_Id?.First_Name +
                              " " +
                              el?.Contact_Id?.Last_Name
                            : el?.Contact_Id?.full_name
                            ? el?.Contact_Id?.full_name
                            : ""}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <span>Date</span>
                      <input
                        type="date"
                        name="task-date"
                        value={
                          el?.Email_Date
                            ? TimeStampConverter2(el.Email_Date)
                            : ""
                        }
                        disabled={true}
                        className="w-100"
                        style={{ border: "none" }}
                      />
                    </div>
                    <div className="col-md-4">
                      <span>Time</span>
                      <input
                        name="task-time"
                        value={el.Email_Time}
                        disabled={true}
                        className="w-100"
                        style={{ border: "none" }}
                      />
                    </div>
                    <div className="col-md-12">
                      <hr />
                    </div>
                    <div className="col-md-12 pl-5">
                      <h6>
                        <i
                          className="fas fa-user-circle"
                          style={{ color: "#d1d4d4" }}
                        ></i>
                        <b style={{ fontWeight: "500" }}>
                          {el?.Created_By?.First_Name
                            ? el?.Created_By?.First_Name +
                              " " +
                              el?.Created_By?.Last_Name
                            : ""}
                        </b>{" "}
                        logged a Email
                      </h6>
                    </div>
                  </div>
                  <hr />
                </div>
                <div style={{ padding: "10px 20px", fontWeight: "500" }}>
                  {el?.Comments}
                </div>
                <div className="output-task-footer d-flex">
                  <i
                    className="fas fa-comment-dots"
                    style={{ cursor: "pointer" }}
                  ></i>
                  <p>Add Comment</p>
                </div>
              </div>
            );
          } else if (el?.Note_Description) {
            return (
              <div key={el._id} className="output-task-details">
                <div className="output-task-header">
                  <div className="row">
                    <div className="col-md-6 text-left">
                      <span style={{ fontSize: "20px", marginRight: "7px" }}>
                        <FaRegEdit />
                      </span>
                      <h5>Note</h5>
                    </div>
                    <div className="col-md-6 text-right">
                      <span>{GET_TIME_ZONE(el?.createdAt)}</span>
                      <ul className="list-inline right-action-menu">
                        <li className="list-inline-item">
                          <Link
                            onClick={() => {
                              openNoteTab();
                              setEditNote(el);
                            }}
                          >
                            Edit
                          </Link>
                        </li>
                        <li className="list-inline-item">
                          <Link onClick={() => handleDeleteNote(el._id)}>
                            Delete
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="col-md-12 pl-5 pt-3">
                      <div
                        contentEditable="false"
                        dangerouslySetInnerHTML={{
                          __html: el?.Note_Description,
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
                <div className="output-task-body pl-4">
                  <div className="row">
                    <div className="col-md-12 pl-5">
                      <h6>
                        <i
                          className="fas fa-user-circle"
                          style={{ color: "#d1d4d4" }}
                        ></i>
                        <b style={{ fontWeight: "500" }}>
                          {el?.Created_By.First_Name +
                            " " +
                            el?.Created_By.Last_Name}
                        </b>
                        &nbsp; left a note
                      </h6>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="output-task-footer d-flex">
                  {/* <i
    className="fas fa-comment-dots"
    style={{ cursor: "pointer" }}
  ></i>
  <p>Add Comment</p> */}
                </div>
              </div>
            );
          } else if (el?.Task_Title) {
            return (
              <div key={el._id} className="output-task-details">
                <div className="output-task-header">
                  <div className="row">
                    <div className="col-md-7 text-left task-header-left">
                      <sapn
                        style={{
                          fontSize: "20px",
                          marginRight: "5px",
                        }}
                      >
                        <BsFillPinFill />
                      </sapn>

                      <h5>Task</h5>
                      {TimeStampConverter2(el.Due_Date) <
                        TimeStampConverter2(Today) ||
                      TimeStampConverter2(el.Due_Date) <
                        TimeStampConverter2(el.reminder_off_Date) ? (
                        <span>Overdue</span>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-md-5 text-right">
                      {el?.Reminder_Status ? (
                        <ul className="list-inline right-action-menu">
                          <li className="list-inline-item">
                            <Link
                              onClick={() => {
                                setEditTask(el);
                                openTaskTab();
                              }}
                            >
                              Edit
                            </Link>
                          </li>
                          <li className="list-inline-item">
                            <Link onClick={() => handleDeleteTask(el._id)}>
                              Delete
                            </Link>
                          </li>
                        </ul>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-md-12 pl-5 pt-3">
                      <span
                        className={
                          el?.Reminder_Status ? "Active_Task" : "Deactive_Task"
                        }
                        disabled={loadingRmnd}
                        onClick={() => dispatch(TaskLogReminder(el._id))}
                      >
                        <MdOutlineTaskAlt />
                      </span>

                      <h5
                        style={
                          el?.Reminder_Status
                            ? {
                                color: "black",
                              }
                            : {
                                color: "#0c3eff",
                                textDecoration: "line-through",
                              }
                        }
                      >
                        {el.Task_Title}
                      </h5>
                    </div>
                  </div>
                  <hr />
                </div>
                <div className="output-task-inner-details">
                  <div className="output-task-body pl-4">
                    <div className="row">
                      <div className="col-md-12 mb-2">
                        <span>Assigned to</span>
                        <br />
                        <div className="form-group d-flex w-50 mt-2">
                          <i className="far fa-user-circle"></i>
                          <select
                            value={el.Assigned_to}
                            className="form-control  Pad"
                            disabled={true}
                          >
                            <option value="">Associated with 1 record</option>
                            <UserDropdown />
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <span className="pl-2"> Due Date</span>
                        <br />
                        <input
                          name="Date-time"
                          disabled
                          value={
                            TimeStampConverter2(el.Due_Date) + " " + el.Due_Time
                          }
                          style={{ border: "none" }}
                        />
                      </div>
                      <div className="col-md-3">
                        <span className="pl-2">Type</span>
                        <br />
                        <div className="form-group" style={{ width: "65px" }}>
                          <p>{el.Task_Type}</p>
                        </div>
                      </div>
                      <div className="col-md-5">
                        <span className="pl-2">Priority</span>
                        <br />
                        <div
                          className="form-group"
                          style={{ width: "65px", padding: "10px 0" }}
                        >
                          <p>
                            {el.Priority === 1
                              ? "High"
                              : el.Priority === 0
                              ? "No"
                              : ""}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <span className="pl-2"> Reminder</span>
                        <br />
                        <input
                          name="Date-time"
                          value={
                            TimeStampConverter2(el.Due_Date) + " " + el.Due_Time
                          }
                          disabled
                          style={{ border: "none" }}
                        />
                      </div>
                      <div className="col-md-3">
                        <span className="pl-2">Queue</span>
                        <br />
                        <div className="form-group" style={{ width: "65px" }}>
                          <select disabled className="form-control Pad">
                            <option value="">None</option>
                            <option value="">---</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-5">
                        <span>Created by</span>
                        <br />
                        <h6
                          style={{
                            fontSize: "14px",
                            marginTop: "3px",
                          }}
                        >
                          {el?.Created_By?.First_Name
                            ? el?.Created_By?.First_Name +
                              " " +
                              el?.Created_By?.Last_Name
                            : ""}
                        </h6>
                      </div>
                    </div>
                    <hr />
                    <div>
                      {el?.Task_Description ? (
                        <>
                          <p>Regarding</p>
                          <div
                            contentEditable="false"
                            dangerouslySetInnerHTML={{
                              __html: el.Task_Description,
                            }}
                          ></div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="output-task-footer pl-4">
                    <p>
                      Regarding call logged on {GET_TIME_ZONE(el.createdAt)}
                    </p>
                  </div>
                </div>
              </div>
            );
          } else {
            return (
              <div key={el._id} className="activity-details show-Contact-owner">
                <div className="row">
                  <div className="col-md-7 text-left activeInner-header">
                    <FaListCheck />
                    <h6>
                      <strong>{el.ActivityTitle}</strong>
                    </h6>
                  </div>
                  <div className="col-md-5 text-right">
                    <span> {GET_TIME_ZONE(el.createdAt)}</span>
                  </div>
                  <div style={{ padding: "10px 20px" }}>
                    <div
                      contentEditable="false"
                      dangerouslySetInnerHTML={{
                        __html: el.ActivityDescription,
                      }}
                    ></div>{" "}
                  </div>
                </div>
              </div>
            );
          }
        })}
      </div>
    </>
  );
}
