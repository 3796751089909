import {
  CONFIRMATION_OF_ACCEPTANCE_DELETE_FAIL,
  CONFIRMATION_OF_ACCEPTANCE_DELETE_REQUEST,
  CONFIRMATION_OF_ACCEPTANCE_DELETE_RESET,
  CONFIRMATION_OF_ACCEPTANCE_DELETE_SUCCESS,
  CONFIRMATION_OF_ACCEPTANCE_INSERT_FAIL,
  CONFIRMATION_OF_ACCEPTANCE_INSERT_REQUEST,
  CONFIRMATION_OF_ACCEPTANCE_INSERT_RESET,
  CONFIRMATION_OF_ACCEPTANCE_INSERT_SUCCESS,
  CONFIRMATION_OF_ACCEPTANCE_LIST_FAIL,
  CONFIRMATION_OF_ACCEPTANCE_LIST_REQUEST,
  CONFIRMATION_OF_ACCEPTANCE_LIST_SUCCESS,
  CONFIRMATION_OF_ACCEPTANCE_UPDATE_FAIL,
  CONFIRMATION_OF_ACCEPTANCE_UPDATE_REQUEST,
  CONFIRMATION_OF_ACCEPTANCE_UPDATE_RESET,
  CONFIRMATION_OF_ACCEPTANCE_UPDATE_SUCCESS,
} from "../Constants/ConfirmationOfAcceptanceConstants";

export const confirmationOfAcceptanceListReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case CONFIRMATION_OF_ACCEPTANCE_LIST_REQUEST:
      return { loading: true };
    case CONFIRMATION_OF_ACCEPTANCE_LIST_SUCCESS:
      return { loading: false, confirmationOfAcceptanceRecs: payload };
    case CONFIRMATION_OF_ACCEPTANCE_LIST_FAIL:
      return { loading: false, error: payload };
    default:
      return state;
  }
};

export const confirmationOfAcceptanceSaveReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case CONFIRMATION_OF_ACCEPTANCE_INSERT_REQUEST:
      return { loading: true };
    case CONFIRMATION_OF_ACCEPTANCE_INSERT_SUCCESS:
      return { loading: false, success: payload };
    case CONFIRMATION_OF_ACCEPTANCE_INSERT_FAIL:
      return { loading: false, error: payload };
    case CONFIRMATION_OF_ACCEPTANCE_INSERT_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const confirmationOfAcceptanceUpdateReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case CONFIRMATION_OF_ACCEPTANCE_UPDATE_REQUEST:
      return { loading: true };
    case CONFIRMATION_OF_ACCEPTANCE_UPDATE_SUCCESS:
      return { loading: false, success: payload };
    case CONFIRMATION_OF_ACCEPTANCE_UPDATE_FAIL:
      return { loading: false, error: payload };
    case CONFIRMATION_OF_ACCEPTANCE_UPDATE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const confirmationOfAcceptanceDeleteReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case CONFIRMATION_OF_ACCEPTANCE_DELETE_REQUEST:
      return { loading: true };
    case CONFIRMATION_OF_ACCEPTANCE_DELETE_SUCCESS:
      return { loading: false, success: payload };
    case CONFIRMATION_OF_ACCEPTANCE_DELETE_FAIL:
      return { loading: false, error: payload };
    case CONFIRMATION_OF_ACCEPTANCE_DELETE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};
