import {
  MATERIAL_SUBTITLE_CREATE_FAILED,
  MATERIAL_SUBTITLE_CREATE_REQ,
  MATERIAL_SUBTITLE_CREATE_SUCCESS,
  MATERIAL_SUBTITLE_DETAILS_FAILED,
  MATERIAL_SUBTITLE_DETAILS_REQ,
  MATERIAL_SUBTITLE_DETAILS_SUCCESS,
  MATERIAL_SUBTITLE_LIST_ALL_FAILED,
  MATERIAL_SUBTITLE_LIST_ALL_REQ,
  MATERIAL_SUBTITLE_LIST_ALL_SUCCESS,
  MATERIAL_SUBTITLE_LIST_FAILED,
  MATERIAL_SUBTITLE_LIST_REQ,
  MATERIAL_SUBTITLE_LIST_SUCCESS,
  MATERIAL_SUBTITLE_REMOVE_FAILED,
  MATERIAL_SUBTITLE_REMOVE_REQ,
  MATERIAL_SUBTITLE_REMOVE_SUCCESS,
  MATERIAL_SUBTITLE_UPDATE_FAILED,
  MATERIAL_SUBTITLE_UPDATE_REQ,
  MATERIAL_SUBTITLE_UPDATE_SUCCESS,
} from "../Constants/materialSubTitleConstant";
import api from "../core/api";

export const createMaterialSubTitle = (MaterialData) => async (dispatch) => {
  try {
    dispatch({ type: MATERIAL_SUBTITLE_CREATE_REQ });
    const { data } = await api.post("/material/subtitle", MaterialData);
    dispatch({ type: MATERIAL_SUBTITLE_CREATE_SUCCESS, payload: data?.Result });
  } catch (error) {
    dispatch({
      type: MATERIAL_SUBTITLE_CREATE_FAILED,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const listMaterialSubTitle = (Id) => async (dispatch) => {
  try {
    dispatch({ type: MATERIAL_SUBTITLE_LIST_REQ });
    const { data } = await api.get(`/material/subtitle/course/${Id}`);
    dispatch({ type: MATERIAL_SUBTITLE_LIST_SUCCESS, payload: data?.Result });
  } catch (error) {
    dispatch({
      type: MATERIAL_SUBTITLE_LIST_FAILED,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const listMaterialSubTitleAll = () => async (dispatch) => {
  try {
    dispatch({ type: MATERIAL_SUBTITLE_LIST_ALL_REQ });
    const { data } = await api.get(`/material/subtitle`);

    dispatch({
      type: MATERIAL_SUBTITLE_LIST_ALL_SUCCESS,
      payload: data?.Result,
    });
  } catch (error) {
    dispatch({
      type: MATERIAL_SUBTITLE_LIST_ALL_FAILED,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const deleteMaterialSubTitle = (Id) => async (dispatch) => {
  try {
    dispatch({ type: MATERIAL_SUBTITLE_REMOVE_REQ });
    const { data } = await api.delete(`/material/subtitle/${Id}`);
    dispatch({ type: MATERIAL_SUBTITLE_REMOVE_SUCCESS, payload: data?.Result });
  } catch (error) {
    dispatch({
      type: MATERIAL_SUBTITLE_REMOVE_FAILED,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const updateMaterialSubTitle =
  (Id, MaterialData) => async (dispatch) => {
    try {
      dispatch({ type: MATERIAL_SUBTITLE_UPDATE_REQ });
      const { data } = await api.put(`/material/subtitle/${Id}`, MaterialData);
      dispatch({
        type: MATERIAL_SUBTITLE_UPDATE_SUCCESS,
        payload: data?.Result,
      });
    } catch (error) {
      dispatch({
        type: MATERIAL_SUBTITLE_UPDATE_FAILED,
        payload:
          error.response && error.response.data.Result
            ? error.response.data.Result
            : error.message,
      });
    }
  };

export const singleMaterialSubTitleDetails = (Id) => async (dispatch) => {
  try {
    dispatch({ type: MATERIAL_SUBTITLE_DETAILS_REQ });
    const { data } = await api.get(`/material/subtitle/getSingle/${Id}`);
    dispatch({
      type: MATERIAL_SUBTITLE_DETAILS_SUCCESS,
      payload: data?.Result,
    });
  } catch (error) {
    dispatch({
      type: MATERIAL_SUBTITLE_DETAILS_FAILED,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};
