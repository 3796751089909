import {
  APPLICATION_STATUS_DELETE_FAIL,
  APPLICATION_STATUS_DELETE_REQUEST,
  APPLICATION_STATUS_DELETE_RESET,
  APPLICATION_STATUS_DELETE_SUCCESS,
  APPLICATION_STATUS_GET_FAIL,
  APPLICATION_STATUS_GET_REQUEST,
  APPLICATION_STATUS_GET_SUCCESS,
  APPLICATION_STATUS_INSERT_FAIL,
  APPLICATION_STATUS_INSERT_REQUEST,
  APPLICATION_STATUS_INSERT_RESET,
  APPLICATION_STATUS_INSERT_SUCCESS,
  APPLICATION_STATUS_LIST_FAIL,
  APPLICATION_STATUS_LIST_REQUEST,
  APPLICATION_STATUS_LIST_SUCCESS,
  APPLICATION_STATUS_UPDATE_FAIL,
  APPLICATION_STATUS_UPDATE_REQUEST,
  APPLICATION_STATUS_UPDATE_RESET,
  APPLICATION_STATUS_UPDATE_SUCCESS,
} from "../Constants/applicationStatusConstants";

export const applicationStatusListReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case APPLICATION_STATUS_LIST_REQUEST:
      return { loading: true };
    case APPLICATION_STATUS_LIST_SUCCESS:
      return { loading: false, applicationStatuses: payload };
    case APPLICATION_STATUS_LIST_FAIL:
      return { loading: false, error: payload };
    default:
      return state;
  }
};

export const applicationStatusDetailReducer = (
  state = {},
  { type, payload }
) => {
  switch (type) {
    case APPLICATION_STATUS_GET_REQUEST:
      return { loading: true };
    case APPLICATION_STATUS_GET_SUCCESS:
      return { loading: false, applicationStatusrec: payload };
    case APPLICATION_STATUS_GET_FAIL:
      return { loading: false, error: payload };
    default:
      return state;
  }
};

export const applicationStatusSaveReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case APPLICATION_STATUS_INSERT_REQUEST:
      return { loading: true };
    case APPLICATION_STATUS_INSERT_SUCCESS:
      return { loading: false, success: payload };
    case APPLICATION_STATUS_INSERT_FAIL:
      return { loading: false, error: payload };
    case APPLICATION_STATUS_INSERT_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const applicationStatusUpdateReducer = (
  state = {},
  { type, payload }
) => {
  switch (type) {
    case APPLICATION_STATUS_UPDATE_REQUEST:
      return { loading: true };
    case APPLICATION_STATUS_UPDATE_SUCCESS:
      return { loading: false, success: payload };
    case APPLICATION_STATUS_UPDATE_FAIL:
      return { loading: false, error: payload };
    case APPLICATION_STATUS_UPDATE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const applicationStatusDeleteReducer = (
  state = {},
  { type, payload }
) => {
  switch (type) {
    case APPLICATION_STATUS_DELETE_REQUEST:
      return { loading: true };
    case APPLICATION_STATUS_DELETE_SUCCESS:
      return { loading: false, success: payload };
    case APPLICATION_STATUS_DELETE_FAIL:
      return { loading: false, error: payload };
    case APPLICATION_STATUS_DELETE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};
