import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listTestType } from "../../Actions/TestTypeAction";

const TestTypeDropDown = React.memo(() => {
  const dispatch = useDispatch();
  const { TestTypeRec } = useSelector((s) => s.listTestTypeReducer);
 
  useMemo(() => {
    dispatch(listTestType());
  }, [dispatch]);
  return TestTypeRec ? (
    TestTypeRec?.map((recs) => (
      <option value={recs.id} key={recs.id}>
        {recs.name}
      </option>
    ))
  ) : (
    <></>
  );
});

export default TestTypeDropDown;
