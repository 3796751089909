import {
  COMMON_HEADER_REQUEST,
  COMMON_HEADER_SUCCESS,
  COMMON_HEADER_RESET,
  OFFSET_REQUEST,
  OFFSET_SUCCESS,
  OFFSET_RESET,
} from "../Constants/commonConstants";

export const commonHeaderReducer = (state = { header: "" }, action) => {
  switch (action.type) {
    case COMMON_HEADER_REQUEST:
      return { header: "" };
    case COMMON_HEADER_SUCCESS:
      return { header: action.payload };
    case COMMON_HEADER_RESET:
      return { header: "" };
    default:
      return state;
  }
};

export const getOffsetReducer = (state={}, action)=>{
  switch (action.type){
    case OFFSET_REQUEST:
      return { offset: "" };
    case OFFSET_SUCCESS:
      return { offset: action.payload };
    case OFFSET_RESET:
      return { offset: "" };
    default:
      return state;
  }
}
