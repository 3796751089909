import {
  NOTIFICATIONS_GET_FAIL,
  NOTIFICATIONS_GET_REQUEST,
  NOTIFICATIONS_GET_SUCCESS,
  NOTIFICATIONS_INSERT_FAIL,
  NOTIFICATIONS_INSERT_REQUEST,
  NOTIFICATIONS_INSERT_RESET,
  NOTIFICATIONS_INSERT_SUCCESS,
} from "../Constants/notificationsConstants";

export const notificationGetReducer = (state = {}, action) => {
  switch (action.type) {
    case NOTIFICATIONS_GET_REQUEST:
      return { loading: true };
    case NOTIFICATIONS_GET_SUCCESS:
      return { loading: false, notifications: action.payload };
    case NOTIFICATIONS_GET_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const notificationCreateReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case NOTIFICATIONS_INSERT_REQUEST: {
      return { loading: true };
    }

    case NOTIFICATIONS_INSERT_SUCCESS: {
      return { loading: false, success: payload };
    }

    case NOTIFICATIONS_INSERT_FAIL: {
      return { laoding: false, error: payload };
    }

    case NOTIFICATIONS_INSERT_RESET: {
      return { loading: false, success: "" };
    }

    default:
      return state;
  }
};
