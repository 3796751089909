import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USERS_LIST_REQUEST,
  USERS_LIST_FAIL,
  USERS_LIST_SUCCESS,
  USERS_GET_REQUEST,
  USERS_GET_FAIL,
  USERS_GET_SUCCESS,
  USERS_CREATE_REQUEST,
  USERS_CREATE_SUCCESS,
  USERS_CREATE_FAIL,
  USERS_VERIFY_TKN_REQUEST,
  USERS_VERIFY_TKN_FAIL,
  USERS_VERIFY_TKN_SUCCESS,
  USERS_RESET_PASSWORD_REQUEST,
  USERS_RESET_PASSWORD_FAIL,
  USERS_RESET_PASSWORD_SUCCESS,
  USERS_FORGOT_PASSWORD_REQUEST,
  USERS_FORGOT_PASSWORD_FAIL,
  USERS_FORGOT_PASSWORD_SUCCESS,
  USERS_UPDATE_PROFILE_REQUEST,
  USERS_UPDATE_PROFILE_SUCCESS,
  USERS_UPDATE_PROFILE_FAIL,
  USERS_GET_INTERNAL_REQUEST,
  USERS_GET_INTERNAL_SUCCESS,
  USERS_GET_INTERNAL_FAIL,
  USERS_UPDATE_REQUEST,
  USERS_UPDATE_SUCCESS,
  USERS_UPDATE_FAIL,
  USERS_ACTIVATE_REQUEST,
  USERS_ACTIVATE_SUCCESS,
  USERS_ACTIVATE_FAIL,
  USERS_BLOCKED_REQUEST,
  USERS_BLOCKED_SUCCESS,
  USERS_BLOCKED_FAIL,
} from "../Constants/userConstants";
import api from "../core/api";

export const userLogin = (userJson) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });

    const { data } = await api.post(`/users/login`, userJson);
    var dt = data.Result;

    let dte = new Date().getTime();
    localStorage.setItem("_Tssat", data.accesstoken);
    localStorage.setItem("_Tsset", JSON.stringify(data.userdata));
    localStorage.setItem("_Tdaex", dte);
    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: "Login Successful!",
      rec: dt,
    });
  } catch (error) {
    // console.log(error)
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const userLogout = () => async (dispatch) => {
  localStorage.removeItem("_Tssat");
  localStorage.removeItem("_Tdaex");
  localStorage.removeItem("_Tsset");
  localStorage.clear();
  dispatch({ type: "LOGOUT" });
};

export const listUsers = () => async (dispatch) => {
  try {
    dispatch({
      type: USERS_LIST_REQUEST,
    });
    const { data } = await api.get(`/users/all`);
    var dt = data.Result;

    dispatch({
      type: USERS_LIST_SUCCESS,
      payload: dt,
    });
  } catch (error) {
    dispatch({
      type: USERS_LIST_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const getUserDetail = () => async (dispatch) => {
  try {
    dispatch({
      type: USERS_GET_REQUEST,
    });
    const { data } = await api.get(`/users`);
    var dt = data.Result;

    dispatch({
      type: USERS_GET_SUCCESS,
      payload: dt,
    });
  } catch (error) {
    dispatch({
      type: USERS_GET_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const getUserDetailInternal = (id) => async (dispatch) => {
  try {
    dispatch({
      type: USERS_GET_INTERNAL_REQUEST,
    });
    const { data } = await api.get(`/users`);

    var dt = data.Result;
    dispatch({
      type: USERS_GET_INTERNAL_SUCCESS,
      payload: dt,
    });
  } catch (error) {
    dispatch({
      type: USERS_GET_INTERNAL_FAIL,
      payload:
        error.response && error.response.data?.Result
          ? error.response.data?.Result
          : error.message,
    });
  }
};

export const saveUserDetails = (inputJson) => async (dispatch) => {
  try {
    dispatch({
      type: USERS_CREATE_REQUEST,
    });
    const { data } = await api.post(`/users`, inputJson);
    var dt = data.Result;
    dispatch({
      type: USERS_CREATE_SUCCESS,
      payload: dt,
    });
  } catch (error) {
    dispatch({
      type: USERS_CREATE_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const updateUserProfileDetails = (inputJson) => async (dispatch) => {
  try {
    dispatch({
      type: USERS_UPDATE_PROFILE_REQUEST,
    });
    const { data } = await api.put(`/users`, inputJson);
    var dt = data.Result;
    dispatch({
      type: USERS_UPDATE_PROFILE_SUCCESS,
      payload: dt,
    });
  } catch (error) {
    dispatch({
      type: USERS_UPDATE_PROFILE_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const updateUserIntDetails = (inputJson) => async (dispatch) => {
  try {
    dispatch({
      type: USERS_UPDATE_REQUEST,
    });
    for (let key in inputJson) {
      if (inputJson[key] == "" || inputJson[key] == undefined)
        inputJson[key] = null;
    }
    const { data } = await api.post(
      `/users/updateUserInternalDetails/` + inputJson._id,
      inputJson
    );
    // User_Role, DepartmentId, Hierarchy_Id, Branch_Id
    var dt = data.Result;
    dispatch({
      type: USERS_UPDATE_SUCCESS,
      payload: dt,
    });
  } catch (error) {
    dispatch({
      type: USERS_UPDATE_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const updateUserActiveStatus = (id, status) => async (dispatch) => {
  try {
    dispatch({
      type: USERS_ACTIVATE_REQUEST,
    });
    if (status == "activate") {
      const { data } = await api.put(`/users/activate/` + id);
      var dt = data.Result;
      dispatch({
        type: USERS_ACTIVATE_SUCCESS,
        payload: dt,
      });
    } else {
      const { data } = await api.put(`/users/deactivate/` + id);
      var dt = data.Result;
      dispatch({
        type: USERS_ACTIVATE_SUCCESS,
        payload: dt,
      });
    }
  } catch (error) {
    dispatch({
      type: USERS_ACTIVATE_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const updateUserBlockedStatus = (id) => async (dispatch) => {
  try {
    dispatch({
      type: USERS_BLOCKED_REQUEST,
    });

    const { data } = await api.put(`/users/unblock/` + id);
    var dt = data.Result;
    dispatch({
      type: USERS_BLOCKED_SUCCESS,
      payload: dt,
    });
  } catch (error) {
    dispatch({
      type: USERS_BLOCKED_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const verifyTknUserDetails = (inputJson) => async (dispatch) => {
  try {
    dispatch({
      type: USERS_VERIFY_TKN_REQUEST,
    });
    const { data } = await api.post(`/users/verify`, inputJson);
    var dt = data.Result;

    dispatch({
      type: USERS_VERIFY_TKN_SUCCESS,
      payload: dt,
    });
  } catch (error) {
    dispatch({
      type: USERS_VERIFY_TKN_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const resetPasswordUserDetails = (inputJson) => async (dispatch) => {
  try {
    dispatch({
      type: USERS_RESET_PASSWORD_REQUEST,
    });
    const { data } = await api.post(`/users/ResetPassword`, inputJson);
    var dt = data.Result;

    dispatch({
      type: USERS_RESET_PASSWORD_SUCCESS,
      payload: dt,
    });
  } catch (error) {
    dispatch({
      type: USERS_RESET_PASSWORD_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const changePasswordUserDetails = (inputJson) => async (dispatch) => {
  try {
    dispatch({
      type: USERS_RESET_PASSWORD_REQUEST,
    });
    const { data } = await api.post(`/users/set`, inputJson);
    var dt = data.Result;

    dispatch({
      type: USERS_RESET_PASSWORD_SUCCESS,
      payload: dt,
    });
  } catch (error) {
    dispatch({
      type: USERS_RESET_PASSWORD_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const forgotPasswordUserDetails = (inputJson) => async (dispatch) => {
  try {
    dispatch({
      type: USERS_FORGOT_PASSWORD_REQUEST,
    });
    const { data } = await api.post(`/users/forgotpassword`, inputJson);
    var dt = data.Result;
    dispatch({
      type: USERS_FORGOT_PASSWORD_SUCCESS,
      payload: dt,
    });
  } catch (error) {
    dispatch({
      type: USERS_FORGOT_PASSWORD_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};
