import {
  REASON_DID_NOT_QUALIFY_DELETE_FAIL,
  REASON_DID_NOT_QUALIFY_DELETE_REQUEST,
  REASON_DID_NOT_QUALIFY_DELETE_RESET,
  REASON_DID_NOT_QUALIFY_DELETE_SUCCESS,
  REASON_DID_NOT_QUALIFY_INSERT_FAIL,
  REASON_DID_NOT_QUALIFY_INSERT_REQUEST,
  REASON_DID_NOT_QUALIFY_INSERT_RESET,
  REASON_DID_NOT_QUALIFY_INSERT_SUCCESS,
  REASON_DID_NOT_QUALIFY_LIST_FAIL,
  REASON_DID_NOT_QUALIFY_LIST_REQUEST,
  REASON_DID_NOT_QUALIFY_LIST_SUCCESS,
  REASON_DID_NOT_QUALIFY_UPDATE_FAIL,
  REASON_DID_NOT_QUALIFY_UPDATE_REQUEST,
  REASON_DID_NOT_QUALIFY_UPDATE_RESET,
  REASON_DID_NOT_QUALIFY_UPDATE_SUCCESS,
} from "../Constants/reasonDidNotQualifyConstants";

export const reasonDidNotQualifyListReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case REASON_DID_NOT_QUALIFY_LIST_REQUEST:
      return { loading: true };
    case REASON_DID_NOT_QUALIFY_LIST_SUCCESS:
      return { loading: false, reasonDidNotQualifyRec: payload };
    case REASON_DID_NOT_QUALIFY_LIST_FAIL:
      return { loading: false, error: payload };
    default:
      return state;
  }
};

export const reasonDidNotQualifySaveReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case REASON_DID_NOT_QUALIFY_INSERT_REQUEST:
      return { loading: true };
    case REASON_DID_NOT_QUALIFY_INSERT_SUCCESS:
      return { loading: false, success: payload };
    case REASON_DID_NOT_QUALIFY_INSERT_FAIL:
      return { loading: false, error: payload };
    case REASON_DID_NOT_QUALIFY_INSERT_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const reasonDidNotQualifyUpdateReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case REASON_DID_NOT_QUALIFY_UPDATE_REQUEST:
      return { loading: true };
    case REASON_DID_NOT_QUALIFY_UPDATE_SUCCESS:
      return { loading: false, success: payload };
    case REASON_DID_NOT_QUALIFY_UPDATE_FAIL:
      return { loading: false, error: payload };
    case REASON_DID_NOT_QUALIFY_UPDATE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const reasonDidNotQualifyDeleteReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case REASON_DID_NOT_QUALIFY_DELETE_REQUEST:
      return { loading: true };
    case REASON_DID_NOT_QUALIFY_DELETE_SUCCESS:
      return { loading: false, success: payload };
    case REASON_DID_NOT_QUALIFY_DELETE_FAIL:
      return { loading: false, error: payload };
    case REASON_DID_NOT_QUALIFY_DELETE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};
