import {
  FINANCIAL_SOURCE_DELETE_FAIL,
  FINANCIAL_SOURCE_DELETE_REQUEST,
  FINANCIAL_SOURCE_DELETE_RESET,
  FINANCIAL_SOURCE_DELETE_SUCCESS,
  FINANCIAL_SOURCE_INSERT_FAIL,
  FINANCIAL_SOURCE_INSERT_REQUEST,
  FINANCIAL_SOURCE_INSERT_RESET,
  FINANCIAL_SOURCE_INSERT_SUCCESS,
  FINANCIAL_SOURCE_LIST_FAIL,
  FINANCIAL_SOURCE_LIST_REQUEST,
  FINANCIAL_SOURCE_LIST_SUCCESS,
  FINANCIAL_SOURCE_UPDATE_FAIL,
  FINANCIAL_SOURCE_UPDATE_REQUEST,
  FINANCIAL_SOURCE_UPDATE_RESET,
  FINANCIAL_SOURCE_UPDATE_SUCCESS,
} from "../Constants/financialSourceConstants";

export const financialSourceListReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case FINANCIAL_SOURCE_LIST_REQUEST:
      return { loading: true };
    case FINANCIAL_SOURCE_LIST_SUCCESS:
      return { loading: false, financialSourceRecs: payload };
    case FINANCIAL_SOURCE_LIST_FAIL:
      return { loading: false, error: payload };
    default:
      return state;
  }
};

export const financialSourceSaveReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case FINANCIAL_SOURCE_INSERT_REQUEST:
      return { loading: true };
    case FINANCIAL_SOURCE_INSERT_SUCCESS:
      return { loading: false, success: payload };
    case FINANCIAL_SOURCE_INSERT_FAIL:
      return { loading: false, error: payload };
    case FINANCIAL_SOURCE_INSERT_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const financialSourceUpdateReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case FINANCIAL_SOURCE_UPDATE_REQUEST:
      return { loading: true };
    case FINANCIAL_SOURCE_UPDATE_SUCCESS:
      return { loading: false, success: payload };
    case FINANCIAL_SOURCE_UPDATE_FAIL:
      return { loading: false, error: payload };
    case FINANCIAL_SOURCE_UPDATE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const financialSourceDeleteReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case FINANCIAL_SOURCE_DELETE_REQUEST:
      return { loading: true };
    case FINANCIAL_SOURCE_DELETE_SUCCESS:
      return { loading: false, success: payload };
    case FINANCIAL_SOURCE_DELETE_FAIL:
      return { loading: false, error: payload };
    case FINANCIAL_SOURCE_DELETE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};
