export const SUBSCRIBE_LIST_REQUEST = 'SUBSCRIBE_LIST_REQUEST'
export const SUBSCRIBE_LIST_SUCCESS = 'SUBSCRIBE_LIST_SUCCESS'
export const SUBSCRIBE_LIST_FAIL = 'SUBSCRIBE_LIST_FAIL'

export const SUBSCRIBE_INSERT_REQUEST = 'SUBSCRIBE_INSERT_REQUEST'
export const SUBSCRIBE_INSERT_SUCCESS = 'SUBSCRIBE_INSERT_SUCCESS'
export const SUBSCRIBE_INSERT_FAIL = 'SUBSCRIBE_INSERT_FAIL'
export const SUBSCRIBE_INSERT_RESET = 'SUBSCRIBE_INSERT_RESET'

export const SUBSCRIBE_UPDATE_REQUEST = 'SUBSCRIBE_UPDATE_REQUEST'
export const SUBSCRIBE_UPDATE_SUCCESS = 'SUBSCRIBE_UPDATE_SUCCESS'
export const SUBSCRIBE_UPDATE_FAIL = 'SUBSCRIBE_UPDATE_FAIL'
export const SUBSCRIBE_UPDATE_RESET = 'SUBSCRIBE_UPDATE_RESET'

export const SUBSCRIBE_DELETE_REQUEST = 'SUBSCRIBE_DELETE_REQUEST'
export const SUBSCRIBE_DELETE_SUCCESS = 'SUBSCRIBE_DELETE_SUCCESS'
export const SUBSCRIBE_DELETE_FAIL = 'SUBSCRIBE_DELETE_FAIL'
export const SUBSCRIBE_DELETE_RESET = 'SUBSCRIBE_DELETE_RESET'
