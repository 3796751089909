import {
  GURULLY_CONTACT_DELETE_FAIL,
  GURULLY_CONTACT_DELETE_REQUEST,
  GURULLY_CONTACT_DELETE_SUCCESS,
  GURULLY_CONTACT_INSERT_FAIL,
  GURULLY_CONTACT_INSERT_REQUEST,
  GURULLY_CONTACT_INSERT_SUCCESS,
  GURULLY_CONTACT_LIST_FAIL,
  GURULLY_CONTACT_LIST_REQUEST,
  GURULLY_CONTACT_LIST_SUCCESS,
  GURULLY_CONTACT_UPDATE_FAIL,
  GURULLY_CONTACT_UPDATE_REQUEST,
  GURULLY_CONTACT_UPDATE_SUCCESS,
} from "../Constants/gurullyContactConstant";
import api from "../core/api";

export const listGurullyContact = () => async (dispatch) => {
  try {
    dispatch({
      type: GURULLY_CONTACT_LIST_REQUEST,
    });
    const { data } = await api.get(`/gurully/contact`);

    var dt = data.Result;

    dispatch({
      type: GURULLY_CONTACT_LIST_SUCCESS,
      payload: dt,
    });
  } catch (error) {
    dispatch({
      type: GURULLY_CONTACT_LIST_FAIL,
      payload:
        error.response && error?.response?.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const createGurullyContact = (inputJson) => async (dispatch) => {
  try {
    dispatch({
      type: GURULLY_CONTACT_INSERT_REQUEST,
    });
    const { data } = await api.post(`/gurully/contact`, inputJson);
    var dt = data.Result;

    dispatch({
      type: GURULLY_CONTACT_INSERT_SUCCESS,
      payload: dt,
    });
  } catch (error) {
    dispatch({
      type: GURULLY_CONTACT_INSERT_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const updateGurullyContact = (id, inputJson) => async (dispatch) => {
  try {
    dispatch({
      type: GURULLY_CONTACT_UPDATE_REQUEST,
    });
    const { data } = await api.put(`/gurully/contact/${id}`, inputJson);
    var dt = data.Result;

    dispatch({
      type: GURULLY_CONTACT_UPDATE_SUCCESS,
      payload: dt,
    });
  } catch (error) {
    dispatch({
      type: GURULLY_CONTACT_UPDATE_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const deleteGurullyContact = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GURULLY_CONTACT_DELETE_REQUEST,
    });
    const { data } = await api.delete(`/gurully/contact/${id}`);
    var dt = data.Result;

    dispatch({
      type: GURULLY_CONTACT_DELETE_SUCCESS,
      payload: dt,
    });
  } catch (error) {
    dispatch({
      type: GURULLY_CONTACT_DELETE_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};
