export const SUBAGENT_USER_ASSIGNED_LIST_REQ =
  "SUBAGENT_USER_ASSIGNED_LIST_REQ";
export const SUBAGENT_USER_ASSIGNED_LIST_SUCCESS =
  "SUBAGENT_USER_ASSIGNED_LIST_SUCCESS";
export const SUBAGENT_USER_ASSIGNED_LIST_FAILED =
  "SUBAGENT_USER_ASSIGNED_LIST_FAILED";

  
export const SUBAGENT_USER_ASSIGNED_INSERT_REQ =
  "SUBAGENT_USER_ASSIGNED_INSERT_REQ";
export const SUBAGENT_USER_ASSIGNED_INSERT_SUCCESS =
  "SUBAGENT_USER_ASSIGNED_INSERT_SUCCESS";
export const SUBAGENT_USER_ASSIGNED_INSERT_FAILED =
  "SUBAGENT_USER_ASSIGNED_INSERT_FAILED";
export const SUBAGENT_USER_ASSIGNED_INSERT_RESET =
  "SUBAGENT_USER_ASSIGNED_INSERT_RESET";
