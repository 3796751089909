export const DEPARTMENT_LIST_REQUEST = 'DEPARTMENT_LIST_REQUEST'
export const DEPARTMENT_LIST_SUCCESS = 'DEPARTMENT_LIST_SUCCESS'
export const DEPARTMENT_LIST_FAIL = 'DEPARTMENT_LIST_FAIL'

export const DEPARTMENT_GET_REQUEST = 'DEPARTMENT_GET_REQUEST'
export const DEPARTMENT_GET_SUCCESS = 'DEPARTMENT_GET_SUCCESS'
export const DEPARTMENT_GET_FAIL = 'DEPARTMENT_GET_FAIL'

export const DEPARTMENT_INSERT_REQUEST = 'DEPARTMENT_INSERT_REQUEST'
export const DEPARTMENT_INSERT_SUCCESS = 'DEPARTMENT_INSERT_SUCCESS'
export const DEPARTMENT_INSERT_FAIL = 'DEPARTMENT_INSERT_FAIL'
export const DEPARTMENT_INSERT_RESET = 'DEPARTMENT_INSERT_RESET'

export const DEPARTMENT_UPDATE_REQUEST = 'DEPARTMENT_UPDATE_REQUEST'
export const DEPARTMENT_UPDATE_SUCCESS = 'DEPARTMENT_UPDATE_SUCCESS'
export const DEPARTMENT_UPDATE_FAIL = 'DEPARTMENT_UPDATE_FAIL'
export const DEPARTMENT_UPDATE_RESET = 'DEPARTMENT_UPDATE_RESET'

export const DEPARTMENT_DELETE_REQUEST = 'DEPARTMENT_DELETE_REQUEST'
export const DEPARTMENT_DELETE_SUCCESS = 'DEPARTMENT_DELETE_SUCCESS'
export const DEPARTMENT_DELETE_FAIL = 'DEPARTMENT_DELETE_FAIL'
export const DEPARTMENT_DELETE_RESET = 'DEPARTMENT_DELETE_RESET'