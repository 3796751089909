import {
  STUDY_BATCH_DELETE_FAIL,
  STUDY_BATCH_DELETE_REQUEST,
  STUDY_BATCH_DELETE_RESET,
  STUDY_BATCH_DELETE_SUCCESS,
  STUDY_BATCH_INSERT_FAIL,
  STUDY_BATCH_INSERT_REQUEST,
  STUDY_BATCH_INSERT_RESET,
  STUDY_BATCH_INSERT_SUCCESS,
  STUDY_BATCH_LIST_FAIL,
  STUDY_BATCH_LIST_REQUEST,
  STUDY_BATCH_LIST_SUCCESS,
  STUDY_BATCH_UPDATE_FAIL,
  STUDY_BATCH_UPDATE_REQUEST,
  STUDY_BATCH_UPDATE_RESET,
  STUDY_BATCH_UPDATE_SUCCESS,
} from "../Constants/studyBatchConstants";

export const studyBatchListReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case STUDY_BATCH_LIST_REQUEST:
      return { loading: true };
    case STUDY_BATCH_LIST_SUCCESS:
      return { loading: false, studyBatchRecs: payload };
    case STUDY_BATCH_LIST_FAIL:
      return { loading: false, error: payload };
    default:
      return state;
  }
};

export const studyBatchSaveReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case STUDY_BATCH_INSERT_REQUEST:
      return { loading: true };
    case STUDY_BATCH_INSERT_SUCCESS:
      return { loading: false, success: payload };
    case STUDY_BATCH_INSERT_FAIL:
      return { loading: false, error: payload };
    case STUDY_BATCH_INSERT_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const studyBatchUpdateReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case STUDY_BATCH_UPDATE_REQUEST:
      return { loading: true };
    case STUDY_BATCH_UPDATE_SUCCESS:
      return { loading: false, success: payload };
    case STUDY_BATCH_UPDATE_FAIL:
      return { loading: false, error: payload };
    case STUDY_BATCH_UPDATE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const studyBatchDeleteReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case STUDY_BATCH_DELETE_REQUEST:
      return { loading: true };
    case STUDY_BATCH_DELETE_SUCCESS:
      return { loading: false, success: payload };
    case STUDY_BATCH_DELETE_FAIL:
      return { loading: false, error: payload };
    case STUDY_BATCH_DELETE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};
