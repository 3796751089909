import {
  CONTACT_ACTIVITY_LIST_FAIL,
  CONTACT_ACTIVITY_LIST_REQUEST,
  CONTACT_ACTIVITY_LIST_SUCCESS,
  DEAL_ACTIVITY_LIST_FAIL,
  DEAL_ACTIVITY_LIST_REQUEST,
  DEAL_ACTIVITY_LIST_SUCCESS,
  SUBAGENT_ACTIVITY_LIST_FAIL,
  SUBAGENT_ACTIVITY_LIST_REQUEST,
  SUBAGENT_ACTIVITY_LIST_SUCCESS,
} from "../Constants/activityConstants";

export const activityContactListReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case CONTACT_ACTIVITY_LIST_REQUEST:
      return { loading: true };
    case CONTACT_ACTIVITY_LIST_SUCCESS:
      return { loading: false, activityContactRec: payload };
    case CONTACT_ACTIVITY_LIST_FAIL:
      return { loading: false, error: payload };
    default:
      return state;
  }
};

export const activityDealListReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case DEAL_ACTIVITY_LIST_REQUEST:
      return { loading: true };
    case DEAL_ACTIVITY_LIST_SUCCESS:
      return { loading: false, activityDealRec: payload };
    case DEAL_ACTIVITY_LIST_FAIL:
      return { loading: false, error: payload };
    default:
      return state;
  }
};

export const activitySubAgentListReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case SUBAGENT_ACTIVITY_LIST_REQUEST: {
      return { loading: true };
    }

    case SUBAGENT_ACTIVITY_LIST_SUCCESS: {
      return { loading: false, activitySubAgentRec: payload };
    }

    case SUBAGENT_ACTIVITY_LIST_FAIL: {
      return { loading: false, error: payload };
    }
    default:
      return state;
  }
};
