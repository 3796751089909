import { Route, Routes } from "react-router-dom";
import Dashboard from "../Screens/Dashboard/Index";
import Login from "../Screens/Login/Index";
import PrivateRoute from "./PrivateRoute";
import Contact from "../Screens/Contact";
import Application from "../Screens/Application";
import Deal from "../Screens/Deal";
import ContactEdit from "../Screens/Contact/Edit";
import Users from "../Screens/Users/Index";
import SetPassword from "../Screens/ForgotPassword";
import ForgotPassword from "../Screens/ForgotPassword/ForgotPassword";
import Department from "../Screens/Department/Index";
import Hierarchy from "../Screens/Hierarchy/Index";
import Settings from "../Screens/Settings";
import Material from "../Screens/Material";
import Course from "../Screens/Course";
import Account from "../Screens/Account/Index";
import CallOutCome from "../Screens/CallOutcome/Index";
import Package from "../Screens/Packages";
import CreatePacakge from "../Screens/Packages/CreatePacakge";
import PsychometricQuestion from "../Screens/PsychometricQuestion";
import CP from "../Screens/Packages/CP";
import Team from "../Screens/Team";
import UserRole from "../Screens/UserRole";
import ClassCenter from "../Screens/ClassCenter";
import Timings from "../Screens/Timings";
import Cart from "../Screens/Cart";
import Order from "../Screens/Order";
import Coupon from "../Screens/Coupon";
import MaterialTitle from "../Screens/MaterialTitle";
import Gurully from "../Screens/Gurully";
import GurullyContactLink from "../Screens/GurullyContact";
import MockTest from "../Screens/MockTest";
import ErrorPage from "../Screens/ErrorPage";
import TLATResult from "../Screens/TLatResult";
import PsychometricResult from "../Screens/PsychometricResult";
import MaterialSubTitle from "../Screens/MaterialSubTitle";
import OrderDetails from "../Screens/Order/OrderDetails";
import EmailTemplate from "../Screens/EmailTemplate";
import SendBulkEmail from "../Screens/SendBulkEmail";
export default function NavigationRoute() {
  return (
    <Routes>
      <Route
        path="/"
        element={<PrivateRoute> {<Dashboard />} </PrivateRoute>}
      />
      <Route
        path="/applications"
        element={<PrivateRoute> {<Application />}</PrivateRoute>}
      />
      <Route
        path="/Contact"
        element={<PrivateRoute> {<Contact />}</PrivateRoute>}
      />

      <Route path="/Cart" element={<PrivateRoute> {<Cart />}</PrivateRoute>} />
      <Route
        path="/Orders"
        element={<PrivateRoute> {<Order />}</PrivateRoute>}
      />

      <Route
        path="/Order/details/:id"
        element={<PrivateRoute>{<OrderDetails />}</PrivateRoute>}
      />

      <Route
        path="/Contact/details/:id"
        element={<PrivateRoute> {<ContactEdit />}</PrivateRoute>}
      />

      <Route
        path="/department"
        element={<PrivateRoute> {<Department />}</PrivateRoute>}
      />

      <Route
        path="/email-template"
        element={<PrivateRoute> {<EmailTemplate />}</PrivateRoute>}
      />

      <Route
        path="/email-bulk/send"
        element={<PrivateRoute> {<SendBulkEmail />}</PrivateRoute>}
      />
      <Route
        path="/Material"
        element={<PrivateRoute> {<Material />}</PrivateRoute>}
      />

      <Route
        path="/mock-test"
        element={<PrivateRoute> {<MockTest />}</PrivateRoute>}
      />

      <Route
        path="/Material-Title"
        element={<PrivateRoute> {<MaterialTitle />}</PrivateRoute>}
      />

      <Route
        path="/Material-Sub-Title"
        element={<PrivateRoute> {<MaterialSubTitle />}</PrivateRoute>}
      />

      <Route
        path="/Course"
        element={<PrivateRoute> {<Course />}</PrivateRoute>}
      />

      <Route
        path="/coupons"
        element={<PrivateRoute> {<Coupon />}</PrivateRoute>}
      />

      <Route
        path="/gurully"
        element={<PrivateRoute> {<Gurully />}</PrivateRoute>}
      />
      <Route
        path="/gurully-contact-link"
        element={<PrivateRoute> {<GurullyContactLink />}</PrivateRoute>}
      />

      <Route
        path="/Account"
        element={<PrivateRoute> {<Account />}</PrivateRoute>}
      />

      <Route
        path="/hierarchy"
        element={<PrivateRoute> {<Hierarchy />}</PrivateRoute>}
      />
      <Route
        path="/calloutcome"
        element={<PrivateRoute> {<CallOutCome />}</PrivateRoute>}
      />
      <Route
        path="/Packages"
        element={<PrivateRoute> {<Package />}</PrivateRoute>}
      />

      <Route
        path="/Package/create"
        element={<PrivateRoute>{<CreatePacakge />}</PrivateRoute>}
      />

      <Route path="/team" element={<PrivateRoute>{<Team />}</PrivateRoute>} />

      <Route
        path="/user-role"
        element={<PrivateRoute>{<UserRole />}</PrivateRoute>}
      />

      <Route
        path="/class-center"
        element={<PrivateRoute>{<ClassCenter />}</PrivateRoute>}
      />

      <Route
        path="/timings"
        element={<PrivateRoute>{<Timings />}</PrivateRoute>}
      />
      <Route
        path="/Package/cp"
        element={<PrivateRoute>{<CP />}</PrivateRoute>}
      />

      <Route
        path="/setting"
        element={<PrivateRoute> {<Settings />}</PrivateRoute>}
      />

      <Route
        path="/PsychometricQuestion"
        element={<PrivateRoute> {<PsychometricQuestion />}</PrivateRoute>}
      />
      <Route path="/deals" element={<PrivateRoute> {<Deal />}</PrivateRoute>} />

      <Route
        path="/users"
        element={<PrivateRoute> {<Users />}</PrivateRoute>}
      />

      <Route
        path="/T-LAT-Result/:ContactId"
        element={<PrivateRoute> {<TLATResult />}</PrivateRoute>}
      />

      <Route
        path="/T-LAT-Result"
        element={<PrivateRoute> {<PsychometricResult />}</PrivateRoute>}
      />

      <Route path="/login" element={<Login />} />
      <Route path="/set" element={<SetPassword />} />
      <Route path="/forgotpassword" element={<ForgotPassword />} />
      {/* <Route path="/sitemap" element={<ForgotPassword />} /> */}

      <Route path="/*" element={<ErrorPage />} />
    </Routes>
  );
}
