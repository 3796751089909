export const COUNTRY_LIST_REQUEST = 'COUNTRY_LIST_REQUEST'
export const COUNTRY_LIST_SUCCESS = 'COUNTRY_LIST_SUCCESS'
export const COUNTRY_LIST_FAIL = 'COUNTRY_LIST_FAIL'

export const COUNTRY_INSERT_REQUEST = 'COUNTRY_INSERT_REQUEST'
export const COUNTRY_INSERT_SUCCESS = 'COUNTRY_INSERT_SUCCESS'
export const COUNTRY_INSERT_FAIL = 'COUNTRY_INSERT_FAIL'
export const COUNTRY_INSERT_RESET = 'COUNTRY_INSERT_RESET'

export const COUNTRY_UPDATE_REQUEST = 'COUNTRY_UPDATE_REQUEST'
export const COUNTRY_UPDATE_SUCCESS = 'COUNTRY_UPDATE_SUCCESS'
export const COUNTRY_UPDATE_FAIL = 'COUNTRY_UPDATE_FAIL'
export const COUNTRY_UPDATE_RESET = 'COUNTRY_UPDATE_RESET'

export const COUNTRY_DELETE_REQUEST = 'COUNTRY_DELETE_REQUEST'
export const COUNTRY_DELETE_SUCCESS = 'COUNTRY_DELETE_SUCCESS'
export const COUNTRY_DELETE_FAIL = 'COUNTRY_DELETE_FAIL'
export const COUNTRY_DELETE_RESET = 'COUNTRY_DELETE_RESET'
