export const STUDY_BATCH_CONTACTS_LIST_REQUEST = 'STUDY_BATCH_CONTACTS_LIST_REQUEST'
export const STUDY_BATCH_CONTACTS_LIST_SUCCESS = 'STUDY_BATCH_CONTACTS_LIST_SUCCESS'
export const STUDY_BATCH_CONTACTS_LIST_FAIL = 'STUDY_BATCH_CONTACTS_LIST_FAIL'

export const STUDY_BATCH_CONTACTS_LIST_ALL_REQUEST = 'STUDY_BATCH_CONTACTS_LIST_ALL_REQUEST'
export const STUDY_BATCH_CONTACTS_LIST_ALL_SUCCESS = 'STUDY_BATCH_CONTACTS_LIST_ALL_SUCCESS'
export const STUDY_BATCH_CONTACTS_LIST_ALL_FAIL = 'STUDY_BATCH_CONTACTS_LIST_ALL_FAIL'

export const STUDY_BATCH_CONTACTS_GET_REQUEST = 'STUDY_BATCH_CONTACTS_GET_REQUEST'
export const STUDY_BATCH_CONTACTS_GET_SUCCESS = 'STUDY_BATCH_CONTACTS_GET_SUCCESS'
export const STUDY_BATCH_CONTACTS_GET_FAIL = 'STUDY_BATCH_CONTACTS_GET_FAIL'

export const STUDY_BATCH_CONTACTS_INSERT_REQUEST = 'STUDY_BATCH_CONTACTS_INSERT_REQUEST'
export const STUDY_BATCH_CONTACTS_INSERT_SUCCESS = 'STUDY_BATCH_CONTACTS_INSERT_SUCCESS'
export const STUDY_BATCH_CONTACTS_INSERT_FAIL = 'STUDY_BATCH_CONTACTS_INSERT_FAIL'
export const STUDY_BATCH_CONTACTS_INSERT_RESET = 'STUDY_BATCH_CONTACTS_INSERT_RESET'

export const STUDY_BATCH_CONTACTS_UPDATE_REQUEST = 'STUDY_BATCH_CONTACTS_UPDATE_REQUEST'
export const STUDY_BATCH_CONTACTS_UPDATE_SUCCESS = 'STUDY_BATCH_CONTACTS_UPDATE_SUCCESS'
export const STUDY_BATCH_CONTACTS_UPDATE_FAIL = 'STUDY_BATCH_CONTACTS_UPDATE_FAIL'

export const STUDY_BATCH_CONTACTS_DELETE_REQUEST = 'STUDY_BATCH_CONTACTS_DELETE_REQUEST'
export const STUDY_BATCH_CONTACTS_DELETE_SUCCESS = 'STUDY_BATCH_CONTACTS_DELETE_SUCCESS'
export const STUDY_BATCH_CONTACTS_DELETE_FAIL = 'STUDY_BATCH_CONTACTS_DELETE_FAIL'
export const STUDY_BATCH_CONTACTS_DELETE_RESET = 'STUDY_BATCH_CONTACTS_DELETE_RESET'
