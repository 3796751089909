import {
  APPLICATION_TAG_LIST_FAIL,
  APPLICATION_TAG_LIST_REQUEST,
  APPLICATION_TAG_LIST_SUCCESS,
} from "../Constants/ApplicationTagConstant";

export const applicationTagReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case APPLICATION_TAG_LIST_REQUEST: {
      return { loading: true };
    }

    case APPLICATION_TAG_LIST_SUCCESS: {
      return { loading: true, applicationTagrecs: payload };
    }

    case APPLICATION_TAG_LIST_FAIL: {
      return { loading: false, error: payload };
    }

    default:
      return state;
  }
};
