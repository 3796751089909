export const INTERESTED_FIELD_OF_STUDY_LIST_REQUEST = 'INTERESTED_FIELD_OF_STUDY_LIST_REQUEST'
export const INTERESTED_FIELD_OF_STUDY_LIST_SUCCESS = 'INTERESTED_FIELD_OF_STUDY_LIST_SUCCESS'
export const INTERESTED_FIELD_OF_STUDY_LIST_FAIL = 'INTERESTED_FIELD_OF_STUDY_LIST_FAIL'

export const INTERESTED_FIELD_OF_STUDY_INSERT_REQUEST = 'INTERESTED_FIELD_OF_STUDY_INSERT_REQUEST'
export const INTERESTED_FIELD_OF_STUDY_INSERT_SUCCESS = 'INTERESTED_FIELD_OF_STUDY_INSERT_SUCCESS'
export const INTERESTED_FIELD_OF_STUDY_INSERT_FAIL = 'INTERESTED_FIELD_OF_STUDY_INSERT_FAIL'
export const INTERESTED_FIELD_OF_STUDY_INSERT_RESET = 'INTERESTED_FIELD_OF_STUDY_INSERT_RESET'

export const INTERESTED_FIELD_OF_STUDY_UPDATE_REQUEST = 'INTERESTED_FIELD_OF_STUDY_UPDATE_REQUEST'
export const INTERESTED_FIELD_OF_STUDY_UPDATE_SUCCESS = 'INTERESTED_FIELD_OF_STUDY_UPDATE_SUCCESS'
export const INTERESTED_FIELD_OF_STUDY_UPDATE_FAIL = 'INTERESTED_FIELD_OF_STUDY_UPDATE_FAIL'
export const INTERESTED_FIELD_OF_STUDY_UPDATE_RESET = 'INTERESTED_FIELD_OF_STUDY_UPDATE_RESET'

export const INTERESTED_FIELD_OF_STUDY_DELETE_REQUEST = 'INTERESTED_FIELD_OF_STUDY_DELETE_REQUEST'
export const INTERESTED_FIELD_OF_STUDY_DELETE_SUCCESS = 'INTERESTED_FIELD_OF_STUDY_DELETE_SUCCESS'
export const INTERESTED_FIELD_OF_STUDY_DELETE_FAIL = 'INTERESTED_FIELD_OF_STUDY_DELETE_FAIL'
export const INTERESTED_FIELD_OF_STUDY_DELETE_RESET = 'INTERESTED_FIELD_OF_STUDY_DELETE_RESET'
