export const GENDER_LIST_REQUEST = 'GENDER_LIST_REQUEST'
export const GENDER_LIST_SUCCESS = 'GENDER_LIST_SUCCESS'
export const GENDER_LIST_FAIL = 'GENDER_LIST_FAIL'

export const GENDER_INSERT_REQUEST = 'GENDER_INSERT_REQUEST'
export const GENDER_INSERT_SUCCESS = 'GENDER_INSERT_SUCCESS'
export const GENDER_INSERT_FAIL = 'GENDER_INSERT_FAIL'
export const GENDER_INSERT_RESET = 'GENDER_INSERT_RESET'

export const GENDER_UPDATE_REQUEST = 'GENDER_UPDATE_REQUEST'
export const GENDER_UPDATE_SUCCESS = 'GENDER_UPDATE_SUCCESS'
export const GENDER_UPDATE_FAIL = 'GENDER_UPDATE_FAIL'
export const GENDER_UPDATE_RESET = 'GENDER_UPDATE_RESET'

export const GENDER_DELETE_REQUEST = 'GENDER_DELETE_REQUEST'
export const GENDER_DELETE_SUCCESS = 'GENDER_DELETE_SUCCESS'
export const GENDER_DELETE_FAIL = 'GENDER_DELETE_FAIL'
export const GENDER_DELETE_RESET = 'GENDER_DELETE_RESET'
