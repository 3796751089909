import {
  DOCUMENT_CHECKED_LIST_CREATE_FAILED,
  DOCUMENT_CHECKED_LIST_CREATE_REQUEST,
  DOCUMENT_CHECKED_LIST_CREATE_RESET,
  DOCUMENT_CHECKED_LIST_CREATE_SUCCESS,
  DOCUMENT_CHECKED_LIST_FAILED,
  DOCUMENT_CHECKED_LIST_REQUEST,
  DOCUMENT_CHECKED_LIST_SUCCESS,
  DOCUMENTS_DELETE_FAIL,
  DOCUMENTS_DELETE_REQUEST,
  DOCUMENTS_DELETE_RESET,
  DOCUMENTS_DELETE_SUCCESS,
  DOCUMENTS_INSERT_FAIL,
  DOCUMENTS_INSERT_REQUEST,
  DOCUMENTS_INSERT_RESET,
  DOCUMENTS_INSERT_SUCCESS,
  DOCUMENTS_LIST_FAIL,
  DOCUMENTS_LIST_REQUEST,
  DOCUMENTS_LIST_SUCCESS,
  DOCUMENTS_UPDATE_FAIL,
  DOCUMENTS_UPDATE_REQUEST,
  DOCUMENTS_UPDATE_RESET,
  DOCUMENTS_UPDATE_SUCCESS,
} from "../Constants/documentsConstants";

export const documentListReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case DOCUMENTS_LIST_REQUEST:
      return { loading: true };
    case DOCUMENTS_LIST_SUCCESS:
      return { loading: false, documentRecs: payload };
    case DOCUMENTS_LIST_FAIL:
      return { loading: false, error: payload };
    default:
      return state;
  }
};

export const documentSaveReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case DOCUMENTS_INSERT_REQUEST:
      return { loading: true };
    case DOCUMENTS_INSERT_SUCCESS:
      return { loading: false, success: payload };
    case DOCUMENTS_INSERT_FAIL:
      return { loading: false, error: payload };
    case DOCUMENTS_INSERT_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const documentUpdateReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case DOCUMENTS_UPDATE_REQUEST:
      return { loading: true };
    case DOCUMENTS_UPDATE_SUCCESS:
      return { loading: false, success: payload };
    case DOCUMENTS_UPDATE_FAIL:
      return { loading: false, error: payload };
    case DOCUMENTS_UPDATE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const documentDeleteReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case DOCUMENTS_DELETE_REQUEST:
      return { loading: true };
    case DOCUMENTS_DELETE_SUCCESS:
      return { loading: false, success: payload };
    case DOCUMENTS_DELETE_FAIL:
      return { loading: false, error: payload };
    case DOCUMENTS_DELETE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const CheckedDocumetListReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case DOCUMENT_CHECKED_LIST_REQUEST: {
      return { loading: true };
    }

    case DOCUMENT_CHECKED_LIST_SUCCESS: {
      return { loading: false, DocumentCheckedListRecs: payload };
    }

    case DOCUMENT_CHECKED_LIST_FAILED: {
      return { loading: false, error: payload };
    }

    default:
      return state;
  }
};

export const createCheckedDocumetListReducer = (
  state = {},
  { type, payload }
) => {
  switch (type) {
    case DOCUMENT_CHECKED_LIST_CREATE_REQUEST: {
      return { loading: true };
    }

    case DOCUMENT_CHECKED_LIST_CREATE_SUCCESS: {
      return { loading: false, success: payload };
    }

    case DOCUMENT_CHECKED_LIST_CREATE_FAILED: {
      return { loading: false, error: payload };
    }

    case DOCUMENT_CHECKED_LIST_CREATE_RESET: {
      return { loading: false };
    }

    default:
      return state;
  }
};
