import SidePanel from "../../Components/SidePanel";
import TopBar from "../../Components/TopBar";
import ReactDatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import Meta from "../../Components/Meta";
import { useCallback, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  createContact,
  listContacts,
  totalContacts,
} from "../../Actions/contactActions";
import { GET_TIME_ZONE } from "../Components/Time";
import UserDropdown from "../Components/UserDropdown";
import CountryDropdown from "../Components/CountryDropdown";
import GenderDropdown from "../Components/GenderDropdown";
import {
  ContactCreateValidator,
  contactJson2,
} from "../../Models/ContactModels";
import { toast, ToastContainer } from "react-toastify";
import { toastOptions } from "../../Models/ToastEditiorOption";
import { CONTACT_CREATE_RESET } from "../../Constants/contactConstants";
import Pagination from "../Components/Pagination";

export default function Contact() {
  const [contactCreateData, setcontactCreateData] = useState(contactJson2);
  // const [userInfo, setUserInfo] = useState("");
  const [MobileFilterActive, setMobileFilterActive] = useState(false);
  const [createSec, setCreateSec] = useState(false);
  const [showActiveSec, setShowActiveSec] = useState("primary");
  const Navigate = useNavigate();
  const [contactFilterData, setContactFilterData] = useState({
    page_size: 10,
    page_no: 1,
  });

  const mobile_View = window.innerWidth;
  const { loading, error, contactRecs } = useSelector((s) => s.contactList);
  const { total } = useSelector((s) => s.totalContactsReducer);

  const ErrorSuccessRef = useRef();

  const handleCancel = () => {
    setCreateSec(false);
  };

  const {
    loading: loadingsv,
    error: errorsv,
    success: successsv,
  } = useSelector((s) => s.contactCreate);

  const dispatch = useDispatch();
  const MetaDes = { title: "Testfify - Contact" };

  const Get_Contacts = useCallback(() => {
    dispatch(listContacts(contactFilterData));
    dispatch(totalContacts());
  }, [dispatch, contactFilterData]);

  const handleChange = (e) => {
    setcontactCreateData({
      ...contactCreateData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    for (let key in contactCreateData) {
      if (
        contactCreateData[key] === "" ||
        contactCreateData[key] === undefined
      ) {
        contactCreateData[key] = null;
      }
    }
    const CreateContactValidate = ContactCreateValidator(contactCreateData);
    if (CreateContactValidate === "success") {
      dispatch(createContact(contactCreateData));
    } else {
      toast.error(CreateContactValidate, toastOptions);
    }
  };

  useEffect(() => {
    Get_Contacts();
  }, [Get_Contacts]);

  useEffect(() => {
    if (successsv) {
      setCreateSec(false);
      setcontactCreateData(contactJson2);
      toast.success(successsv, toastOptions);
      Get_Contacts();
      setTimeout(() => {
        dispatch({ type: CONTACT_CREATE_RESET });
      }, 2000);
    }
    if (errorsv) {
      toast.success(errorsv, toastOptions);
    }
  }, [successsv, dispatch, errorsv, Get_Contacts]);

  return (
    <>
      <Meta MetaDes={MetaDes} />
      <SidePanel page={"Contact"} />
      <section className="home-section">
        <TopBar Heading="Contact" />
        <div className="home-content" style={{ paddingTop: "70px" }}>
          <div className="Contact-Middle">
            <div className="Contact_Top_Header">
              <div className="home-title float-left"></div>
              <div style={{ display: "flex", gap: "20px" }} className="">
                <div className="input-group search-contacts">
                  <input
                    type="Search"
                    className="form-control"
                    style={{ display: "block" }}
                    name="searchtxt"
                    // value={Search}
                    // onChange={(e) => {
                    //   if (e.target.value === "") {
                    //     setFilterData({ ...FilterData, searchtxt: "" });
                    //     setContactFilterData({
                    //       ...contactFilterData,
                    //       searchtxt: "",
                    //     });
                    //   }
                    //   setSearch(e.target.value);
                    // }}
                    // onKeyDown={(e) => {
                    //   if (
                    //     e.key === "Enter" &&
                    //     Search !== "" &&
                    //     Search !== undefined
                    //   ) {
                    //     setFilterData({ ...FilterData, searchtxt: Search });
                    //     setContactFilterData({
                    //       ...contactFilterData,
                    //       searchtxt: Search,
                    //     });
                    //   }
                    // }}
                    placeholder="search for an Contacts"
                  />
                  <i
                    className="fa fa-search"
                    // onClick={() => {
                    //   if (Search !== "" && Search !== undefined) {
                    //     setFilterData({ ...FilterData, searchtxt: Search });
                    //     setContactFilterData({
                    //       ...contactFilterData,
                    //       searchtxt: Search,
                    //     });
                    //   }
                    // }}
                    aria-hidden="true"
                  ></i>
                </div>
                <div className="right-click Create-btn">
                  <a
                    style={{ width: "180px", display: "block" }}
                    className="btn create-contact"
                    onClick={(e) => {
                      setcontactCreateData({
                        ...contactCreateData,
                      });
                      setCreateSec(true);
                    }}
                  >
                    Create Contact
                  </a>
                </div>
              </div>
            </div>
            {window.innerWidth <= 480 ? (
              <div
                className=" Create-btn"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginRight: "17px",
                }}
              >
                <a
                  style={{ width: "180px", display: "block" }}
                  className="btn create-contact"
                  onClick={(e) => {
                    setcontactCreateData({
                      ...contactCreateData,
                    });
                    setCreateSec(true);
                  }}
                >
                  Create Contact
                </a>
              </div>
            ) : (
              ""
            )}

            <hr />
            <div className="row">
              <div className="col-md-4 col-lg-3">
                <div
                  style={
                    mobile_View <= 480
                      ? { marginBottom: "20px" }
                      : { cursor: "auto" }
                  }
                  className="add-filter"
                >
                  <div
                    className={
                      MobileFilterActive
                        ? "filter-title  filter-title-mob filter-body-active"
                        : "filter-title  filter-title-mob"
                    }
                  >
                    <h5>Filter Contacts</h5>
                    <button
                      onClick={() => setMobileFilterActive(false)}
                      className="mob-nav-open"
                    >
                      ☰
                    </button>
                  </div>

                  <div
                    className={
                      MobileFilterActive
                        ? "filter-body filter-body-mob  filter-body-active custom-scroll-right pt-0"
                        : "filter-body filter-body-mob custom-scroll-right pt-0"
                    }
                    style={{ cursor: "auto" }}
                  >
                    <div className="sticky pt-2">
                      {/* <h6>
                        <Link
                          style={
                            showActiveSec === "primary"
                              ? {
                                  backgroundColor: "#007bff",
                                  color: "white",
                                  padding: "1px 3px",
                                  cursor: "pointer",
                                }
                              : { padding: "1px 3px", cursor: "pointer" }
                          }
                          onClick={(e) => handleOnwerChange(e, "primary")}
                        >
                          My Primary Contacts
                        </Link>
                      </h6> */}
                      {showActiveSec === "primary" ? (
                        <p style={{ marginBottom: "-20px" }}>
                          showing {total ? total : 0} Contacts
                        </p>
                      ) : (
                        ""
                      )}
                      <br />
                      {/* <h6>
                        <strong>
                          <Link
                            style={
                              showActiveSec === "secondary"
                                ? {
                                    backgroundColor: "#007bff",
                                    color: "white",
                                    padding: "1px 3px",
                                    cursor: "pointer",
                                  }
                                : { padding: "1px 3px", cursor: "pointer" }
                            }
                            onClick={(e) => handleOnwerChange(e, "secondary")}
                          >
                            My Secondary Contacts
                          </Link>
                        </strong>
                      </h6> */}
                      {showActiveSec === "secondary" ? (
                        <p style={{ marginBottom: "-20px" }}>
                          showing {total ? total : 0} Contacts
                        </p>
                      ) : (
                        ""
                      )}
                      <br />
                      {/* <h6>
                        <strong>
                          <Link
                            style={
                              showActiveSec === "all"
                                ? {
                                    backgroundColor: "#007bff",
                                    color: "white",
                                    padding: "1px 3px",
                                    cursor: "pointer",
                                  }
                                : { padding: "1px 3px", cursor: "pointer" }
                            }
                            onClick={(e) => handleOnwerChange(e, "all")}
                          >
                            All Contacts
                          </Link>
                        </strong>
                      </h6> */}

                      <button
                        style={{ color: "white" }}
                        className="btn btn-sm btn-deep-orange"
                        onClick={() => {
                          setContactFilterData({
                            page_no: 1,
                            page_size: 10,
                          });
                          setShowActiveSec("primary");
                        }}
                      >
                        Clear All
                      </button>
                    </div>
                    <ul className="modify-filter-title">
                      {showActiveSec === "secondary" ||
                      showActiveSec === "all" ? (
                        <li className="chose">Primary Contact Owner</li>
                      ) : (
                        ""
                      )}
                      {showActiveSec === "secondary" ||
                      showActiveSec === "all" ? (
                        <li>
                          {/* <UserMultiSelectorDropDown
                            values={
                              contactFilterData?.Assigned_User_Id
                                ? contactFilterData?.Assigned_User_Id
                                : []
                            }
                            handleChange={(e) => {
                              setContactFilterData({
                                ...contactFilterData,
                                Assigned_User_Id: e,
                              });
                            }}
                          /> */}
                        </li>
                      ) : (
                        ""
                      )}
                      {/* {showActiveSec === "primary" ||
                      showActiveSec === "all" ? (
                        <li className="chose">Secondary Contact Owner</li>
                      ) : (
                        ""
                      )} */}
                      {showActiveSec === "primary" ||
                      showActiveSec === "all" ? (
                        <li>
                          {/* <UserMultiSelectorDropDown
                            values={
                              contactFilterData?.Contact_Owner_II
                                ? contactFilterData?.Contact_Owner_II
                                : []
                            }
                            handleChange={(e) => {
                              setContactFilterData({
                                ...contactFilterData,
                                Contact_Owner_II: e,
                              });
                            }}
                          /> */}
                        </li>
                      ) : (
                        ""
                      )}
                      <li className="chose">Qualifies</li>
                      <li>
                        {/* <QualifiesMultiSelectDropdown
                          values={
                            contactFilterData?.Qualifies
                              ? contactFilterData?.Qualifies
                              : []
                          }
                          handleChange={(e) => {
                            setContactFilterData({
                              ...contactFilterData,
                              Qualifies: e,
                            });
                          }}
                        /> */}
                      </li>

                      <li className="chose">Contact Owner</li>
                      <li>
                        <select className="form-control">
                          <option value="">select</option>
                          <UserDropdown />
                        </select>

                        {/* values={
                            contactFilterData?.Team_Id
                              ? contactFilterData?.Team_Id
                              : []
                          }
                          handleChange={(e) => {
                            setContactFilterData({
                              ...contactFilterData,
                              Team_Id: e,
                            });
                          }}
                        /> */}
                      </li>
                      <li className="chose">Create Date</li>
                      <li>
                        <div className="myDatePickerContainer">
                          <ReactDatePicker
                            onKeyDown={(e) => {
                              e.preventDefault();
                            }}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            yearDropdownItemNumber={100}
                            scrollableYearDropdown
                            dropdownMode="scroll"
                            dateFormat="dd-MM-yyyy"
                            className="myDatePicker"
                            style={{ width: "100%" }}
                            selected={contactFilterData.Create_Date_From}
                            onChange={(date) => {
                              //   setFilterData({
                              //     ...FilterData,
                              //     Create_Date_From: date,
                              //   });
                              setContactFilterData({
                                ...contactFilterData,
                                Create_Date_From: date,
                              });
                            }}
                            placeholderText="dd/mm/yy"
                          />
                        </div>
                      </li>
                      <li style={{ textAlign: "center" }}>to</li>
                      <li>
                        <div className="myDatePickerContainer">
                          <ReactDatePicker
                            onKeyDown={(e) => {
                              e.preventDefault();
                            }}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            yearDropdownItemNumber={100}
                            scrollableYearDropdown
                            dropdownMode="scroll"
                            dateFormat="dd-MM-yyyy"
                            className="myDatePicker"
                            selected={contactFilterData.Create_Date_To}
                            onChange={(date) => {
                              setContactFilterData({
                                ...contactFilterData,
                                Create_Date_To: date,
                              });
                            }}
                            placeholderText="dd/mm/yy"
                          />
                        </div>
                      </li>

                      {/* 

                      <li className="chose">Contact Rating</li>
                      <li>
                        <ContactRatingMultiSelectDropdown
                          values={
                            contactFilterData?.Contact_Rating
                              ? contactFilterData?.Contact_Rating
                              : []
                          }
                          handleChange={(e) => {
                            setContactFilterData({
                              ...contactFilterData,
                              Contact_Rating: e,
                            });
                          }}
                        />
                      </li>
                      <li className="chose">Team</li>
                      <li>
                        <TeamMultiSelctDropdown
                          values={
                            contactFilterData?.Team_Id
                              ? contactFilterData?.Team_Id
                              : []
                          }
                          handleChange={(e) => {
                            setContactFilterData({
                              ...contactFilterData,
                              Team_Id: e,
                            });
                          }}
                        />
                      </li>
                      <li className="chose">Educational Status</li>
                      <li>
                        <EducationalStatusMultiSelectDropdown
                          values={
                            contactFilterData?.Educational_Status
                              ? contactFilterData?.Educational_Status
                              : []
                          }
                          handleChange={(e) => {
                            setContactFilterData({
                              ...contactFilterData,
                              Educational_Status: e,
                            });
                          }}
                        />
                      </li>
                      
                      <li className="chose">Academic Percentage</li>
                      <li>
                        <div className="row">
                          <div className="col-md-6">
                            <select
                              name="AcademicPercentage_From"
                              value={contactFilterData.AcademicPercentage_From}
                              onChange={handleFilterChange}
                              className="form-control"
                            >
                              <option value="">--Select--</option>
                              <PercentageDropdown start="40" end="95" />
                            </select>
                          </div>
                          <div className="col-md-6">
                            <select
                              name="AcademicPercentage_To"
                              onChange={handleFilterChange}
                              value={contactFilterData.AcademicPercentage_To}
                              className="form-control"
                            >
                              <option value="">--Select--</option>
                              <PercentageDropdown start="45" end="100" />
                            </select>
                          </div>
                        </div>
                      </li>
                      <li className="chose">Preferred Intake</li>
                      <li>
                        <PreferredIntakeMultiSelectDropdown
                          values={
                            contactFilterData?.Preferred_Intake
                              ? contactFilterData?.Preferred_Intake
                              : []
                          }
                          handleChange={(e) =>
                            setContactFilterData({
                              ...contactFilterData,
                              Preferred_Intake: e,
                            })
                          }
                        />
                      </li>
                      <li className="chose">Intake Year</li>
                      <li>
                        <YearIntakeMultiSelectDropdown
                          values={
                            contactFilterData?.Intake_Year
                              ? contactFilterData?.Intake_Year
                              : []
                          }
                          handleChange={(e) =>
                            setContactFilterData({
                              ...contactFilterData,
                              Intake_Year: e,
                            })
                          }
                        />
                      </li>
                      <li className="chose">First Activity Date</li>
                      <li>
                        <div className="myDatePickerContainer">
                          <ReactDatePicker
                            onKeyDown={(e) => {
                              e.preventDefault();
                            }}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            yearDropdownItemNumber={100}
                            scrollableYearDropdown
                            dropdownMode="scroll"
                            dateFormat="dd-MM-yyyy"
                            className="myDatePicker"
                            selected={
                              contactFilterData.First_Activity_Date_From
                            }
                            onChange={(date) => {
                              //   setFilterData({
                              //     ...FilterData,
                              //     First_Activity_Date_From: date,
                              //   });
                              setContactFilterData({
                                ...contactFilterData,
                                First_Activity_Date_From: date,
                              });
                            }}
                            placeholderText="dd/mm/yy"
                          />
                        </div>
                      </li>
                      <li style={{ textAlign: "center" }}>to</li>
                      <li>
                        <div className="myDatePickerContainer">
                          <ReactDatePicker
                            onKeyDown={(e) => {
                              e.preventDefault();
                            }}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            yearDropdownItemNumber={100}
                            scrollableYearDropdown
                            dropdownMode="scroll"
                            dateFormat="dd-MM-yyyy"
                            className="myDatePicker"
                            selected={contactFilterData.First_Activity_Date_To}
                            onChange={(date) => {
                              //   setFilterData({
                              //     ...FilterData,
                              //     First_Activity_Date_To: date,
                              //   });
                              setContactFilterData({
                                ...contactFilterData,
                                First_Activity_Date_To: date,
                              });
                            }}
                            placeholderText="dd/mm/yy"
                          />
                        </div>
                      </li>
                      <li className="chose">Last Activity Date</li>
                      <li>
                        <div className="myDatePickerContainer">
                          <ReactDatePicker
                            onKeyDown={(e) => {
                              e.preventDefault();
                            }}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            yearDropdownItemNumber={100}
                            scrollableYearDropdown
                            dropdownMode="scroll"
                            dateFormat="dd-MM-yyyy"
                            className="myDatePicker"
                            selected={contactFilterData.Last_Activity_Date_From}
                            onChange={(date) => {
                              //   setFilterData({
                              //     ...FilterData,
                              //     Last_Activity_Date_From: date,
                              //   });

                              setContactFilterData({
                                ...contactFilterData,
                                Last_Activity_Date_From: date,
                              });
                            }}
                            placeholderText="dd/mm/yy"
                          />
                        </div>
                      </li>
                      <li style={{ textAlign: "center" }}>to</li>
                      <li>
                        <div className="myDatePickerContainer">
                          <ReactDatePicker
                            onKeyDown={(e) => {
                              e.preventDefault();
                            }}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            yearDropdownItemNumber={100}
                            scrollableYearDropdown
                            dropdownMode="scroll"
                            dateFormat="dd-MM-yyyy"
                            className="myDatePicker"
                            selected={contactFilterData.Last_Activity_Date_To}
                            onChange={(date) => {
                              //   setFilterData({
                              //     ...FilterData,
                              //     Last_Activity_Date_To: date,
                              //   });

                              setContactFilterData({
                                ...contactFilterData,
                                Last_Activity_Date_To: date,
                              });
                            }}
                            placeholderText="dd/mm/yy"
                          />
                        </div>
                      </li>
                      <li className="chose">Profile</li>
                      <li>
                        <ProfileMultiSelectDropdown
                          values={
                            contactFilterData?.Profile_Status
                              ? contactFilterData?.Profile_Status
                              : []
                          }
                          handleChange={(e) =>
                            setContactFilterData({
                              ...contactFilterData,
                              Profile_Status: e,
                            })
                          }
                        />
                      </li>
                      <li className="chose">Enquiry For</li>
                      <li> */}
                      {/* <EnquiryForMultiSelectDropdown
                          values={
                            contactFilterData?.Enquiry_For
                              ? contactFilterData?.Enquiry_For
                              : []
                          }
                          handleChange={(e) =>
                            setContactFilterData({
                              ...contactFilterData,
                              Enquiry_For: e,
                            })
                          }
                        /> </li>*/}

                      {/* <li className="chose">Preferred Destination I</li> */}
                      <li>
                        {/* <CountryMultiSelectDropdown
                          values={
                            contactFilterData?.Interested_Country
                              ? contactFilterData?.Interested_Country
                              : []
                          }
                          handleChange={(e) =>
                            setContactFilterData({
                              ...contactFilterData,
                              Interested_Country: e,
                            })
                          }
                        /> */}
                      </li>
                      {/* <li className="chose">Preferred Destination II</li> */}
                      {/* <li>
                         <CountryMultiSelectDropdown
                          values={
                            contactFilterData?.Interested_Country2
                              ? contactFilterData?.Interested_Country2
                              : []
                          }
                          handleChange={(e) =>
                            setContactFilterData({
                              ...contactFilterData,
                              Interested_Country2: e,
                            })
                          }
                        /> 
                      </li>*/}
                      {/* <li className="chose">English Test Attempted</li>
                      <li>
                        <EnglishTestAttemptedMultiSelectDropdown
                          values={
                            contactFilterData?.English_Test_Attempted
                              ? contactFilterData?.English_Test_Attempted
                              : []
                          }
                          handleChange={(e) =>
                            setContactFilterData({
                              ...contactFilterData,
                              English_Test_Attempted: e,
                            })
                          }
                        />
                      </li>
                      <li className="chose">Month of Completion</li>
                      <li>
                        <MonthMultiSelectDropdown
                          values={
                            contactFilterData?.Month_Id
                              ? contactFilterData?.Month_Id
                              : []
                          }
                          handleChange={(e) =>
                            setContactFilterData({
                              ...contactFilterData,
                              Month_Id: e,
                            })
                          }
                        />
                      </li>
                      <li className="chose">Year of Completion</li>
                      <li>
                        <YearOfCompletionMultiSelectDropdown
                          values={
                            contactFilterData?.Year_Of_Completion
                              ? contactFilterData?.Year_Of_Completion
                              : []
                          }
                          handleChange={(e) =>
                            setContactFilterData({
                              ...contactFilterData,
                              Year_Of_Completion: e,
                            })
                          }
                        />
                      </li>
                      <li className="chose">Interested Level of Study</li>
                      <li>
                        <InterestedLevelOfStudyMultiDropdown
                          values={
                            contactFilterData?.Interested_Level_Of_Study
                              ? contactFilterData?.Interested_Level_Of_Study
                              : []
                          }
                          handleChange={(e) =>
                            setContactFilterData({
                              ...contactFilterData,
                              Interested_Level_Of_Study: e,
                            })
                          }
                        />
                      </li>
                      <li className="chose">Interested Field of Study</li>
                      <li>
                        <InterestedFieldOfStudyMultiDropdown
                          values={
                            contactFilterData?.Interested_Field_Of_Study
                              ? contactFilterData?.Interested_Field_Of_Study
                              : []
                          }
                          handleChange={(e) =>
                            setContactFilterData({
                              ...contactFilterData,
                              Interested_Field_Of_Study: e,
                            })
                          }
                        />
                      </li>
                      <li className="chose">Financial Source</li>
                      <li>
                        <FinancialSourceMultiDropdown
                          values={
                            contactFilterData?.Financial_Source
                              ? contactFilterData?.Financial_Source
                              : []
                          }
                          handleChange={(e) =>
                            setContactFilterData({
                              ...contactFilterData,
                              Financial_Source: e,
                            })
                          }
                        />
                      </li>
                      <li className="chose">Platform</li>
                      <li>
                        <PlatformMultiDropdown
                          values={
                            contactFilterData?.Contact_Platform
                              ? contactFilterData?.Contact_Platform
                              : []
                          }
                          handleChange={(e) =>
                            setContactFilterData({
                              ...contactFilterData,
                              Contact_Platform: e,
                            })
                          }
                        />
                      </li>
                      <li className="chose">All Campaign</li>
                      <li>
                        <CampaignMultiDropdown
                          values={
                            contactFilterData?.campaign_id
                              ? contactFilterData?.campaign_id
                              : []
                          }
                          handleChange={(e) =>
                            setContactFilterData({
                              ...contactFilterData,
                              campaign_id: e,
                            })
                          }
                        />
                      </li>
                      <li className="chose">Active Campaign</li>
                      <li>
                        <select
                          name="Qualifies"
                          value={contactFilterData.Qualifies}
                          onChange={(e) => handleFilterChange(e)}
                          className="form-control"
                        >
                          <option value="">--Select--</option>
                        </select>
                      </li>
                      <li className="chose">Ad</li> */}
                      {/* <li>
                        <AdMultiDropdown
                          values={
                            contactFilterData?.ad_id
                              ? contactFilterData?.ad_id
                              : []
                          }
                          handleChange={(e) =>
                            setContactFilterData({
                              ...contactFilterData,
                              ad_id: e,
                            })
                          }
                        />
                      </li> */}
                      {/* <li className="chose">Adset</li>
                      <li>
                        <AdsetMultiDropdown
                          values={
                            contactFilterData?.adset_id
                              ? contactFilterData?.adset_id
                              : []
                          }
                          handleChange={(e) =>
                            setContactFilterData({
                              ...contactFilterData,
                              adset_id: e,
                            })
                          }
                        />
                      </li> */}
                      {/* <li className="chose">Lead Source</li> */}
                      {/* <li>
                        <LeadSourceMultiDropdown
                          values={
                            contactFilterData?.Lead_Source
                              ? contactFilterData?.Lead_Source
                              : []
                          }
                          handleChange={(e) =>
                            setContactFilterData({
                              ...contactFilterData,
                              Lead_Source: e,
                            })
                          }
                        />
                      </li> */}
                      {/* <li className="chose">Priority</li> */}
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-md-8 col-lg-9">
                <div className="Contact-table-wrapper">
                  <table>
                    <thead>
                      <tr className="sticky">
                        {/* <th></th> */}
                        <th>Name</th>
                        <th>Qualifies</th>
                        <th>Contact Owner</th>
                        <th>Phone No</th>
                        <th>Email</th>
                        <th>Created Date</th>
                        <th>Team</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <tr>
                          <td colSpan={10}>Laoding... </td>
                        </tr>
                      ) : error ? (
                        <tr>
                          <td colSpan={10}>{error}</td>
                        </tr>
                      ) : (
                        contactRecs &&
                        contactRecs?.map((ev) => (
                          <tr key={ev._id}>
                            {/* <td></td> */}
                            <td>
                              <Link
                                to={`/contact/details/${ev?._id}`}
                                target="_blank"
                                style={{
                                  textDecoration: "none",
                                  color: "#0c3eff",
                                }}
                              >
                                {ev?.First_Name
                                  ? ev?.First_Name +
                                    " " +
                                    (ev?.Last_Name ? ev?.Last_Name : "")
                                  : ""}
                              </Link>
                            </td>
                            <td>---</td>
                            <td>
                              {ev?.ContactOwner ? ev?.ContactOwner : "----"}
                            </td>
                            <td>
                              <Link
                                onClick={() =>
                                  Navigate(`/contact/details/${ev?._id}`, {
                                    state: { ...ev },
                                  })
                                }
                                target="_blank"
                                style={{
                                  textDecoration: "none",
                                  color: "#0c3eff",
                                }}
                              >
                                {ev?.PhoneNo}{" "}
                              </Link>
                            </td>
                            <td>
                              <Link
                                onClick={() =>
                                  Navigate(`/contact/details/${ev?._id}`, {
                                    state: { ...ev },
                                  })
                                }
                                target="_blank"
                                style={{
                                  textDecoration: "none",
                                  color: "#0c3eff",
                                }}
                              >
                                {ev?.EmailAddress}
                              </Link>
                            </td>
                            <td>{GET_TIME_ZONE(ev?.createdAt)}</td>
                            <td>--</td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
                <div>
                  {/* <Pagination
                    Records={total}
                    setFilterQuery={setContactFilterData}
                    FilterQuery={contactFilterData}
                    CurrentPage={1}
                    NextPage={""}
                    PreviousPage={""}
                    handlePagination=""
                    setCurrentPage
                  /> */}

                  <Pagination
                    setFilterQuery={setContactFilterData}
                    FilterQuery={contactFilterData}
                    Total={total}
                  />
                </div>

                <div
                  id="CreatContact"
                  className={createSec ? "overlay-note w-100" : "overlay-note"}
                >
                  <div className="overlay-add-deals contact-right-side">
                    <div className="note-title">
                      <h4 className="text-left text-white pl-3">
                        Create Contact
                      </h4>
                      <a
                        className="closebtn text-right text-white"
                        onClick={(e) => setCreateSec(false)}
                      >
                        ×
                      </a>
                    </div>

                    <div className="contact-body modify-create-contact">
                      {/* <div className="form-group">
                        <label>
                          Enquiry for:<b>*</b>
                        </label>
                        <select
                          className="form-control"
                          name="Enquiry_For"
                          value={contactCreateData.Enquiry_For}
                          onChange={(e) => handleChange(e)}
                        >
                          <option>-- Select Enquiry For --</option>
                          <EnquiryForDropdown />
                        </select>
                      </div>

                      <div className="form-group">
                        <label>
                          Lead source:<b>*</b>
                        </label>
                        <select
                          className="form-control"
                          name="Lead_Source"
                          value={contactCreateData.Lead_Source}
                          onChange={(e) => handleChange(e)}
                        >
                          <option>-- Select Lead Source --</option>
                          <LeadSourceDropdown />
                        </select>
                      </div> */}
                      <div className="form-group">
                        <label>
                          First Name:<b>*</b>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter First Name"
                          name="First_Name"
                          value={contactCreateData.First_Name}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>

                      <div className="form-group">
                        <label>
                          Last Name:<b>*</b>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Last Name"
                          name="Last_Name"
                          value={contactCreateData.Last_Name}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>

                      <div className="form-group first-input">
                        <label>
                          Telephone:<b>*</b>
                        </label>
                        {/* <button className="first-btn-plus" value="+">
                          +{" "}
                        </button> */}
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Phone number"
                          name="PhoneNo"
                          value={contactCreateData.PhoneNo}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                      {/* <div className="form-group Second-input">
                        <button className="second-btn-plus" value="+">
                          {" "}
                          +{" "}
                        </button>

                        <button className="second-btn-minus" value="-">
                          {" "}
                          -{" "}
                        </button>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Phone number"
                          name="Phone_No1"
                          value={contactCreateData.Phone_No1}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                      <div className="form-group third-input">
                        <button className="third-btn-minus" value="-">
                          {" "}
                          -{" "}
                        </button>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Phone number"
                          name="Phone_No2"
                          value={contactCreateData.Phone_No2}
                          onChange={(e) => handleChange(e)}
                        />
                      </div> */}

                      <div className="form-group first-input-email">
                        <label>
                          Email:<b>*</b>
                        </label>
                        <button className="first-btn-plus-email" value="+">
                          {" "}
                          +{" "}
                        </button>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Enter email"
                          name="EmailAddress"
                          value={contactCreateData.EmailAddress}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                      {/* <div className="form-group Second-input-email ">
                        <button className="second-btn-plus-email" value="+">
                          {" "}
                          +{" "}
                        </button>

                        <button className="second-btn-minus-email" value="-">
                          {" "}
                          -{" "}
                        </button>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Enter email"
                          name="Email_Address1"
                          value={contactCreateData.Email_Address1}
                          onChange={(e) => handleChange(e)}
                        />
                      </div> */}
                      {/* <div className="form-group third-input-email">
                        <button className="third-btn-minus-email" value="-">
                          {" "}
                          -{" "}
                        </button>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Enter email"
                          name="Email_Address2"
                          value={contactCreateData.Email_Address2}
                          onChange={(e) => handleChange(e)}
                        />
                      </div> */}

                      <div className="form-group">
                        <label>
                          Date Of Birth:<b></b>
                        </label>
                        <div className="myDatePickerContainer">
                          <ReactDatePicker
                            onKeyDown={(e) => {
                              e.preventDefault();
                            }}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            yearDropdownItemNumber={100}
                            scrollableYearDropdown
                            dropdownMode="scroll"
                            dateFormat="dd-MM-yyyy"
                            className="myDatePicker2"
                            selected={contactCreateData.Date_Of_Birth}
                            onChange={(date) =>
                              setcontactCreateData({
                                ...contactCreateData,
                                Date_Of_Birth: date,
                              })
                            }
                            placeholderText="dd/mm/yy"
                          />
                        </div>
                      </div>

                      <div className="form-group">
                        <label>
                          Gender:<b></b>
                        </label>
                        <select
                          className="form-control"
                          name="Gender"
                          value={contactCreateData.Gender}
                          onChange={(e) => handleChange(e)}
                        >
                          <option>-- Select Gender --</option>
                          <GenderDropdown />
                        </select>
                      </div>

                      {/* <div className="form-group">
                        <label>
                          Marital Status:<b></b>
                        </label>
                        <select
                          className="form-control"
                          name="Marital_Status"
                          value={contactCreateData.Marital_Status}
                          onChange={(e) => handleChange(e)}
                        >
                          <option value="">-- Select Marital Status --</option>
                          <MaritalStatusDropdown />
                        </select>
                      </div> */}

                      <div className="form-group">
                        <label>
                          City:<b></b>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter City"
                          name="City"
                          value={contactCreateData.City}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                      <div className="form-group">
                        <label>
                          State:<b></b>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter State"
                          name="State"
                          value={contactCreateData.State}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                      <div className="form-group">
                        <label>
                          Country:<b></b>
                        </label>
                        <select
                          className="form-control"
                          name="Country"
                          value={contactCreateData.Country}
                          onChange={(e) => handleChange(e)}
                        >
                          <option value="">-- Select Country --</option>
                          <CountryDropdown />
                        </select>
                      </div>

                      {/* 

                    

                      <div className="form-group">
                        <label>
                          How did you hear about us:<b></b>
                        </label>
                        <select
                          className="form-control"
                          name="How_you_heard_About_Us"
                          value={contactCreateData.How_you_heard_About_Us}
                          onChange={(e) => handleChange(e)}
                        >
                          <option>-- Select How you Heard About Us --</option>
                          <HowYouHeardAboutUsDropdown />
                        </select>
                      </div>

                      <div className="form-group">
                        <label>
                          {" "}
                          Preferred Destination I:<b></b>
                        </label>
                        <select
                          className="form-control"
                          name="Interested_Country"
                          value={contactCreateData.Interested_Country}
                          onChange={(e) => handleChange(e)}
                        >
                          <option>-- Select Preferred Destination --</option>
                          <CountryDropdown />
                        </select>
                      </div>

                      <div className="form-group">
                        <label>
                          {" "}
                          Preferred Destination II:<b></b>
                        </label>
                        <select
                          className="form-control"
                          name="Interested_Country2"
                          value={contactCreateData.Interested_Country2}
                          onChange={(e) => handleChange(e)}
                        >
                          <option value="">
                            --Select Preferred Destination II--
                          </option>
                          <CountryDropdown />
                        </select>
                      </div>

                      <div className="form-group">
                        <label>
                          Contact Rating:<b></b>
                        </label>
                        <select
                          className="form-control"
                          name="Contact_Rating"
                          value={contactCreateData.Contact_Rating}
                          onChange={(e) => handleChange(e)}
                        >
                          <option>-- Select Contact Rating --</option>
                          <ContactRatingDropdown />
                        </select>
                      </div>

                      <div className="form-group">
                        <label>
                          {" "}
                          Qualify:<b></b>
                        </label>
                        <select
                          className="form-control"
                          name="Qualifies"
                          value={contactCreateData.Qualifies}
                          onChange={(e) => handleChange(e)}
                        >
                          <option>-- Select Qualifies--</option>
                          <QualifiesDropdown />
                        </select>
                      </div>

                      <div className="form-group">
                        <label>
                          Primary contact owner:<b>*</b>
                        </label>
                        <select
                          className="form-control"
                          name="Assigned_User_Id"
                          value={contactCreateData.Assigned_User_Id}
                          onChange={(e) => handleChange(e)}
                        >
                          <option>-- Select Primary Contact Owner --</option>
                          <UserDropdown />
                        </select>
                      </div>

                      <div className="form-group">
                        <label>
                          Subagent:<b></b>
                        </label>
                        <select
                          className="form-control"
                          name="Referral_SubAgent_Id"
                          value={contactCreateData.Referral_SubAgent_Id}
                          onChange={(e) => handleChange(e)}
                        >
                          <option>-- Select Subagent --</option>
                          <SubAgentDropDown />
                        </select>
                      </div>

                      <div className="form-group">
                        <label>
                          secondary Contact owner:<b></b>
                        </label>
                        <select
                          className="form-control"
                          name="Contact_Owner_II"
                          value={contactCreateData.Contact_Owner_II}
                          onChange={(e) => handleChange(e)}
                        >
                          <option>-- Select Secondary Owner --</option>
                          <UserDropdown />
                        </select>
                      </div>

                      <div className="form-group">
                        <label>
                          Branch:<b>*</b>
                        </label>
                        <select
                          className="form-control"
                          name="Branch"
                          value={contactCreateData.Branch}
                          onChange={(e) => handleChange(e)}
                        >
                          <option>-- Select Branch --</option>
                          <BranchDropdown />
                        </select>
                      </div> */}

                      {/* <div className="form-group">
                        <label>Note:</label>
                        <input
                          type="text"
                          className="form-control"
                          name="Note_Description"
                          placeholder="Enter Note"
                          value={notelogData.Note_Description}
                          onChange={(e) => handleNoteChange(e)}
                        />
                      </div> */}
                      {/* <div>
              <span className="" style={{ color: "green" }}>
                {successsv}
              </span>
              <span className="" style={{ color: "red" }}>
                {errorsv}
              </span>
            </div> */}
                      <div
                        // className="contact-footer w-100"
                        style={{
                          // padding: "50px 25px",
                          backgroundColor: "transparent",
                          marginTop: "26px",
                        }}
                        ref={ErrorSuccessRef}
                      ></div>

                      <div className="contact-footer w-100">
                        <div>
                          <button
                            type="button"
                            className="btn"
                            onClick={handleSubmit}
                          >
                            {loadingsv ? "Loading..." : "Create contact"}
                          </button>
                          {/* <button type="button" className="btn">
                  Create and add another
                </button> */}
                          <button
                            type="button"
                            className="btn btn-deep-orange waves-effect waves-light"
                            onClick={handleCancel}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      </section>
    </>
  );
}
