import {
  STUDY_BATCH_CONTACTS_DELETE_FAIL,
  STUDY_BATCH_CONTACTS_DELETE_REQUEST,
  STUDY_BATCH_CONTACTS_DELETE_RESET,
  STUDY_BATCH_CONTACTS_DELETE_SUCCESS,
  STUDY_BATCH_CONTACTS_INSERT_FAIL,
  STUDY_BATCH_CONTACTS_INSERT_REQUEST,
  STUDY_BATCH_CONTACTS_INSERT_RESET,
  STUDY_BATCH_CONTACTS_INSERT_SUCCESS,
  STUDY_BATCH_CONTACTS_LIST_FAIL,
  STUDY_BATCH_CONTACTS_LIST_REQUEST,
  STUDY_BATCH_CONTACTS_LIST_SUCCESS,
  STUDY_BATCH_CONTACTS_LIST_ALL_FAIL,
  STUDY_BATCH_CONTACTS_LIST_ALL_REQUEST,
  STUDY_BATCH_CONTACTS_LIST_ALL_SUCCESS,
} from "../Constants/studyBatchTokenConstants";

export const studyBatchContactListReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case STUDY_BATCH_CONTACTS_LIST_REQUEST:
      return { loading: true };
    case STUDY_BATCH_CONTACTS_LIST_SUCCESS:
      return { loading: false, studyBatchContactsRecs: payload };
    case STUDY_BATCH_CONTACTS_LIST_FAIL:
      return { loading: false, error: payload };
    default:
      return state;
  }
};

export const studyBatchContactListAllReducer = (
  state = {},
  { type, payload, Total }
) => {
  switch (type) {
    case STUDY_BATCH_CONTACTS_LIST_ALL_REQUEST:
      return { loading: true };
    case STUDY_BATCH_CONTACTS_LIST_ALL_SUCCESS:
      return {
        loading: false,
        studyBatchContactsAllRecs: payload,
        total: Total,
      };
    case STUDY_BATCH_CONTACTS_LIST_ALL_FAIL:
      return { loading: false, error: payload };
    default:
      return state;
  }
};

export const studyBatchContactSaveReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case STUDY_BATCH_CONTACTS_INSERT_REQUEST:
      return { loading: true };
    case STUDY_BATCH_CONTACTS_INSERT_SUCCESS:
      return { loading: false, success: payload };
    case STUDY_BATCH_CONTACTS_INSERT_FAIL:
      return { loading: false, error: payload };
    case STUDY_BATCH_CONTACTS_INSERT_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const studyBatchContactDeleteReducer = (
  state = {},
  { type, payload }
) => {
  switch (type) {
    case STUDY_BATCH_CONTACTS_DELETE_REQUEST:
      return { loading: true };
    case STUDY_BATCH_CONTACTS_DELETE_SUCCESS:
      return { loading: false, success: payload };
    case STUDY_BATCH_CONTACTS_DELETE_FAIL:
      return { loading: false, error: payload };
    case STUDY_BATCH_CONTACTS_DELETE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};
