import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SettingLeftSection from "../Components/SettingLeftSection";
import {
  getUserDetailInternal,
  listUsers,
  saveUserDetails,
  updateUserBlockedStatus,
  updateUserIntDetails,
} from "../../Actions/userActions";
import {
  USERS_CREATE_RESET,
  USERS_UPDATE_RESET,
  USERS_BLOCKED_RESET,
  USERS_ACTIVATE_RESET,
} from "../../Constants/userConstants";
import { userJson } from "../../Models/UserModels";
import TeamDropdown from "../Components/TeamDropdown";
import UserRoleDropdown from "../Components/UserRoleDropdown";
import DepartmentDropdown from "../Components/DepartmentDropdown";
import HierarchyDropdown from "../Components/HierarchyDropdown";
// import BranchDropdown from "../Components/BranchDropdown";
import DeletePopup from "../Components/DeletePoup";
import TopBar from "../../Components/TopBar";
import SidePanel from "../../Components/SidePanel";

export default function Index() {
  const dispatch = useDispatch();
  const [userData, setuserData] = useState(userJson);
  const [listContainer, setlistContainer] = useState(true);
  const [inputContainer, setinputContainer] = useState(false);
  const [updateContainer, setupdateContainer] = useState(false);
  const [UserFilterData, setUserFilterData] = useState({
    page_size: 10,
    page_no: 1,
  });
  const [UserListData, setuserListData] = useState([]);

  //  Delete

  const [OpenNext, setOpenNext] = useState(false);
  const [DeleteAlert, setDeleteAlert] = useState({ msg: "", Delete: () => "" });
  const { error, usersRec } = useSelector((s) => s.usersList);
  const [btnText, setbtnText] = useState("Save");
  
  // const [cancelHide, setcancelHide] = useState(false);

  const {
    loading: loadingsv,
    error: errorsv,
    success: successsv,
  } = useSelector((s) => s.userSave);

  const {
    loading: loadinggd,
    error: errorgd,
    userInternalDets,
  } = useSelector((s) => s.userInternalDetails);

  const {
    loading: loadingud,
    error: errorud,
    success: successud,
  } = useSelector((s) => s.userUpdateIntDetails);

  const {
    loading: loadingac,
    error: errorac,
    success: successac,
  } = useSelector((s) => s.userUpdateActiveStatus);

  const {
    loading: loadingbk,
    error: errorbk,
    success: successbk,
  } = useSelector((s) => s.userUpdateBlockedStatus);

  const handleChange = (e) => {
    e.preventDefault();
    setuserData({ ...userData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (btnText == "Save") {
      dispatch(saveUserDetails(userData));
    } else if (btnText == "Update") {
      //dispatch(updateUserRoleDetails(userRoleData._id, userRoleData));
    } else alert("Error on handleSubmit");
  };

  const getUsers = () => {
    dispatch(listUsers());
  };

  // const handleActivate = (e, id, status) => {
  //   e.preventDefault();
  //   dispatch(updateUserActiveStatus(id, status));
  // };

  const handleBlocked = (e, id) => {
    e.preventDefault();
    dispatch(updateUserBlockedStatus(id));
  };

  const handleEdit = (e, _id) => {
    e.preventDefault();
    setlistContainer(false);
    setupdateContainer(true);
    dispatch(getUserDetailInternal(_id));
  };

  useEffect(() => {
    setTimeout(() => {
      setuserData({
        ...userInternalDets,
        _id: userInternalDets?._id,
        Hierarchy_Id: userInternalDets?.Hierarchy_Id,
        DepartmentId: userInternalDets?.DepartmentId,
        TeamId: userInternalDets?.TeamId,
        // Branch_Id: userInternalDets?.Branch_Id,
        User_Role: userInternalDets?.User_Role,
      });
    }, 1000);
  }, [userInternalDets]);

  const UserRecordSet = useCallback(() => {
    let skip_Pages = (UserFilterData?.page_no - 1) * UserFilterData?.page_size;
    let Count = 1;
    let Doc = [];

    for (let i = skip_Pages; i < usersRec?.length; i++) {
      if (Count > UserFilterData?.page_size) {
        break;
      } else {
        Doc.push(usersRec[i]);
      }
      Count++;
    }

    setuserListData([...Doc]);
  }, [UserFilterData, usersRec]);

  useEffect(() => {
    UserRecordSet();
  }, [UserRecordSet]);

  const handleUpSubmit = (e) => {
    e.preventDefault();
    dispatch(updateUserIntDetails(userData));
  };

  const handleUpCancel = () => {
    setupdateContainer(false);
    setlistContainer(true);
  };

  const handleCancel = () => {
    setuserData({ ...userData });
    setbtnText("Save");
    // setcancelHide(false);
    setlistContainer(true);
    setinputContainer(false);
  };

  const handleOpenCreate = (e) => {
    e.preventDefault();
    setlistContainer(false);
    setinputContainer(true);
  };

  useEffect(() => {
    if (usersRec == undefined) {
      getUsers();
    }
  }, [usersRec]);

  useEffect(() => {
    if (successbk)
      setTimeout(() => {
        dispatch({ type: USERS_BLOCKED_RESET });
      }, 5000);
    if (successac)
      setTimeout(() => {
        dispatch({ type: USERS_ACTIVATE_RESET });
      }, 5000);
    if (successsv)
      setTimeout(() => {
        dispatch({ type: USERS_CREATE_RESET });
        setlistContainer(true);
        setinputContainer(false);
      }, 5000);
    if (successud)
      setTimeout(() => {
        dispatch({ type: USERS_UPDATE_RESET });
        setlistContainer(true);
        setupdateContainer(false);
      }, 5000);

    if (successbk || successsv || successud || successac) {
      getUsers();
      setuserData({ ...userJson });
    }
  }, [successbk, successsv, successud, successac]);

  return (
    <>
      <SidePanel page={"User"} />
      <section className="home-section">
        <TopBar Heading={"Users"} />
        <div
          className="home-content"
          style={{ paddingTop: window.innerWidth <= 480 ? "20px" : "100px" }}
        >
          <div className="content-body">
            <div className="container-fluid">
              <div className="inner-body settings-details">
                <div className="row">
                  <div className="col-md-3">
                    <SettingLeftSection />
                  </div>
                  <div className="col-md-9">
                    <div className="body-content-with-md-9">
                      <div>
                        <div className="container">
                          <div className="row">
                            <div className="col-md-12 text-left body-content-with-md-9-title">
                              <h4>Users </h4>
                            </div>
                          </div>
                          {inputContainer ? (
                            <>
                              <div className="row mt-3 px-5">
                                <div className="col-md-3 pt-1">
                                  <label className="form-group">
                                    Email Address:
                                  </label>
                                </div>
                                <div className="col-md-9">
                                  <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    name="EmailAddress"
                                    placeholder="Enter Email Address"
                                    value={userData.EmailAddress}
                                    onChange={(e) => handleChange(e)}
                                  />
                                </div>
                              </div>

                              <div className="row mt-3 px-5">
                                <div className="col-md-3 pt-1">
                                  <label className="form-group">Team:</label>
                                </div>
                                <div className="col-md-9">
                                  <select
                                    className="form-control form-control-sm"
                                    name="Team_Id"
                                    value={userData.Team_Id}
                                    onChange={(e) => handleChange(e)}
                                  >
                                    <option value="">-- Select Team --</option>
                                    <TeamDropdown />
                                  </select>
                                </div>
                              </div>

                              <div className="row mt-3 px-5">
                                <div className="col-md-3 pt-1">
                                  <label className="form-group">
                                    User Role:
                                  </label>
                                </div>

                                <div className="col-md-9">
                                  <select
                                    className="form-control form-control-sm"
                                    name="User_Role"
                                    value={userData.User_Role}
                                    onChange={(e) => handleChange(e)}
                                  >
                                    <option value="">
                                      --Select User Role--
                                    </option>
                                    <UserRoleDropdown />
                                  </select>
                                </div>
                              </div>

                              <div className="row">
                                <div
                                  className="col-md-12"
                                  style={{ textAlign: "center" }}
                                >
                                  <p>
                                    <a
                                      className="btn btn-deep-orange btn-md"
                                      onClick={(e) => handleSubmit(e)}
                                    >
                                      {loadingsv ? "Laoding..." : btnText}
                                    </a>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <a
                                      className="btn btn-outline-deep-orange btn-md"
                                      onClick={() => handleCancel()}
                                    >
                                      Cancel
                                    </a>
                                  </p>
                                </div>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}

                          {updateContainer ? (
                            <>
                              <div className="row mt-3 px-5">
                                <div className="col-md-3 pt-1">
                                  <label className="form-group">
                                    User Role:
                                  </label>
                                </div>
                                <div className="col-md-9">
                                  <select
                                    className="form-control form-control-sm"
                                    name="User_Role"
                                    value={userData.User_Role}
                                    onChange={(e) => handleChange(e)}
                                  >
                                    <option value="">
                                      --Select User Role--
                                    </option>
                                    <UserRoleDropdown />
                                  </select>
                                </div>
                              </div>

                              <div className="row mt-3 px-5">
                                <div className="col-md-3 pt-1">
                                  <label className="form-group">
                                    Department:
                                  </label>
                                </div>
                                <div className="col-md-9">
                                  <select
                                    className="form-control form-control-sm"
                                    name="DepartmentId"
                                    value={userData.DepartmentId}
                                    onChange={(e) => handleChange(e)}
                                  >
                                    <option value="">
                                      --Select Department--
                                    </option>
                                    <DepartmentDropdown />
                                  </select>
                                </div>
                              </div>

                              <div className="row mt-3 px-5">
                                <div className="col-md-3 pt-1">
                                  <label className="form-group">
                                    Hierarchy:
                                  </label>
                                </div>
                                <div className="col-md-9">
                                  <select
                                    className="form-control form-control-sm"
                                    name="Hierarchy_Id"
                                    value={userData.Hierarchy_Id}
                                    onChange={(e) => handleChange(e)}
                                  >
                                    <option value="">
                                      --Select Hierarchy--
                                    </option>
                                    <HierarchyDropdown />
                                  </select>
                                </div>
                              </div>

                              {/* <div className="row mt-3 px-5">
                                <div className="col-md-3 pt-1">
                                  <label className="form-group">Branch:</label>
                                </div>
                                <div className="col-md-9">
                                  <select
                                    className="form-control form-control-sm"
                                    name="Branch_Id"
                                    value={userData.Branch_Id}
                                    onChange={(e) => handleChange(e)}
                                  >
                                    <option value="">--Select Branch--</option>
                                    <BranchDropdown />
                                  </select>
                                </div>
                              </div> */}

                              <div className="row mt-3 px-5">
                                <div className="col-md-3 pt-1">
                                  <label className="form-group">Team:</label>
                                </div>
                                <div className="col-md-9">
                                  <select
                                    className="form-control form-control-sm"
                                    name="PrimaryTeam"
                                    value={userData.PrimaryTeam}
                                    onChange={(e) => handleChange(e)}
                                  >
                                    <option value="">-- Select Team --</option>
                                    <TeamDropdown />
                                  </select>
                                </div>
                              </div>

                              {/* <div className="row mt-3 px-5">
                                <div className="col-md-3 pt-1">
                                  <label className="form-group">
                                    Secondary Team:
                                  </label>
                                </div>
                                <div className="col-md-9">
                                  <select
                                    className="form-control form-control-sm"
                                    name="SecondaryTeam"
                                    value={userData.SecondaryTeam}
                                    onChange={(e) => handleChange(e)}
                                  >
                                    <option value="">
                                      -- Select Secondary Team --
                                    </option>
                                    <TeamDropdown />
                                  </select>
                                </div>
                              </div>

                              <div className="row mt-3 px-5">
                                <div className="col-md-3 pt-1">
                                  <label className="form-group">
                                    Third Team:
                                  </label>
                                </div>
                                <div className="col-md-9">
                                  <select
                                    className="form-control form-control-sm"
                                    name="ThirdTeam"
                                    value={userData.ThirdTeam}
                                    onChange={(e) => handleChange(e)}
                                  >
                                    <option value="">
                                      -- Select Third Team --
                                    </option>
                                    <TeamDropdown />
                                  </select>
                                </div>
                              </div> */}

                              <div className="row">
                                <div
                                  className="col-md-12"
                                  style={{ textAlign: "center" }}
                                >
                                  <p>
                                    <a
                                      className="btn btn-deep-orange btn-md"
                                      onClick={(e) => handleUpSubmit(e)}
                                    >
                                      Update
                                    </a>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <a
                                      className="btn btn-outline-deep-orange btn-md"
                                      onClick={() => handleUpCancel()}
                                    >
                                      Cancel
                                    </a>
                                  </p>
                                </div>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className="container">
                          <div className="row">
                            <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 ">
                              {errorbk || errorsv || errorud || errorac ? (
                                <label className="alert-danger" id="SpanError">
                                  {errorbk || errorsv || errorud || errorac}
                                </label>
                              ) : (
                                ""
                              )}
                              {successbk ||
                              successud ||
                              successsv ||
                              successac ? (
                                <label
                                  className="alert-success"
                                  id="SpanSuccess"
                                >
                                  {successbk ||
                                    successud ||
                                    successsv ||
                                    successac}
                                </label>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div
                            className="row"
                            style={{ paddingBottom: "20px" }}
                          ></div>
                        </div>

                        {listContainer ? (
                          <div className="container">
                            <div className="row">
                              <div className="col-md-12 ">
                                <div
                                  className={
                                    window.innerWidth <= 480
                                      ? ""
                                      : "float-right"
                                  }
                                >
                                  <a
                                    className="btn btn-outline-deep-orange btn-md"
                                    onClick={handleOpenCreate}
                                  >
                                    Create
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="table-header-md-9 table-responsive">
                                  <table className="table table-striped table-bordered">
                                    <thead>
                                      <tr>
                                        <th> Name</th>
                                        <th>Email Address</th>
                                        <th>Phone Number</th>
                                        <th> Team</th>
                                        <th></th>
                                        <th></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {usersRec ? (
                                        UserListData?.map((dd) => (
                                          <tr key={dd?._id}>
                                            <td>
                                              {dd?.First_Name
                                                ? dd.First_Name
                                                : ""}{" "}
                                              {dd?.Last_Name
                                                ? dd.Last_Name
                                                : ""}
                                            </td>

                                            <td>{dd?.EmailAddress}</td>
                                            <td>{dd?.PhoneNo}</td>
                                            <td>{dd.Team_Id?.Team_Name}</td>
                                            {/* <td>
                                              {dd?.Active
                                                ? "Active"
                                                : "InActive"}
                                            </td> */}

                                            <td align="center">
                                              <input
                                                type="button"
                                                className="btn btn-info btn-sm"
                                                value={
                                                  loadingud
                                                    ? "Loading.."
                                                    : "Update"
                                                }
                                                disabled={loadingud}
                                                onClick={(e) =>
                                                  handleEdit(e, dd._id)
                                                }
                                              />
                                            </td>
                                            {/* <td>
                                              <input
                                                type="button"
                                                className="btn btn-info btn-sm"
                                                value={
                                                  loadingac
                                                    ? "Loading.."
                                                    : dd?.Users_Id?.Active
                                                    ? "de-Activate"
                                                    : "Activate"
                                                }
                                                disabled={loadingac}
                                                onClick={(e) => {
                                                  setDeleteAlert({
                                                    msg: `Do you really want to block this user from crm (${
                                                      dd.Users_Id?.First_Name
                                                        ? dd.Users_Id
                                                            ?.First_Name
                                                          ? dd.Users_Id
                                                              ?.First_Name
                                                          : "" +
                                                            " " +
                                                            dd.Users_Id
                                                              ?.Last_Name
                                                          ? dd.Users_Id
                                                              ?.Last_Name
                                                          : " "
                                                        : "N/A"
                                                    })`,
                                                    Delete: () =>
                                                      handleActivate(
                                                        e,
                                                        dd._id,
                                                        dd.Active
                                                          ? "deactive"
                                                          : "activate"
                                                      ),
                                                  });
                                                  setOpenNext(true);
                                                }}
                                              />
                                            </td> */}

                                            <td>
                                              {dd.Users_Id?.Blocked > 2 ? (
                                                <input
                                                  type="button"
                                                  className="btn btn-info btn-sm"
                                                  value={
                                                    loadingbk
                                                      ? "Loading.."
                                                      : "Un-Blocked"
                                                  }
                                                  disabled={loadingbk}
                                                  onClick={(e) => {
                                                    setDeleteAlert({
                                                      msg: `Do you really want to block this user from crm (${
                                                        dd.Users_Id?.First_Name
                                                          ? dd.Users_Id
                                                              .First_Name
                                                          : "" +
                                                            " " +
                                                            dd.Users_Id
                                                              ?.Last_Name
                                                          ? dd.Users_Id
                                                              .Last_Name
                                                          : ""
                                                      })`,
                                                      Delete: () =>
                                                        handleBlocked(
                                                          e,
                                                          dd.Users_Id._id
                                                        ),
                                                    });
                                                    setOpenNext(true);
                                                  }}
                                                />
                                              ) : (
                                                <></>
                                              )}
                                            </td>
                                          </tr>
                                        ))
                                      ) : (
                                        <tr>
                                          <td colSpan={10}>{error}</td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                                <div className="table-page-details">
                                  <div className="row">
                                    <div className="col-md-9">
                                      <div className="page-number">
                                        {/* <Pagination
                                          setFilterQuery={setUserFilterData}
                                          FilterQuery={UserFilterData}
                                          Total={usersRec?.length}
                                        /> */}
                                      </div>
                                    </div>
                                    <div className="col-md-3 text-center">
                                      <div className="page-counter">
                                        <label>
                                          <select
                                            name="page_size"
                                            value={UserFilterData.page_size}
                                            onChange={(e) =>
                                              setUserFilterData({
                                                ...UserFilterData,
                                                page_size: +e.target.value,
                                              })
                                            }
                                          >
                                            <option value={10}>10</option>
                                            <option value={25}>25</option>
                                            <option value={50}>50</option>
                                            <option value={100}>100</option>
                                          </select>
                                          of{" "}
                                          <span>
                                            {/* {usersRec?.length
                                              ? usersRec?.length
                                              : 0} */}
                                          </span>{" "}
                                          records
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    {/* <!--/ body-content-with-md-9 --> */}
                  </div>
                  {/* <!--/ col-md-9 --> */}
                </div>
              </div>

              <DeletePopup
                OpenNext={OpenNext}
                setOpenNext={setOpenNext}
                DeleteData={DeleteAlert}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
