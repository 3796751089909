import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listCountry } from "../../Actions/countryActions";

// export default function CountryDropdown({ SubAgentLocation }) {
//   const dispatch = useDispatch();
//   const { countries } = useSelector((s) => s.countryList);
//   useEffect(() => {
//     dispatch(listCountry(SubAgentLocation));
//   }, [dispatch, SubAgentLocation]);
//   console.log("Country Dropdown");
//   return countries ? (
//     countries.map((recs) => (
//       <option value={recs._id} key={recs._id}>
//         {recs.Country}
//       </option>
//     ))
//   ) : (
//     <></>
//   );
// }

const CountryDropdown = React.memo(({ SubAgentLocation }) => {
  const dispatch = useDispatch();
  const { countries } = useSelector((s) => s.countryList);
  useEffect(() => {
    dispatch(listCountry(SubAgentLocation));
  }, [dispatch, SubAgentLocation]);


  return countries ? (
    countries.map((recs) => (
      <option value={recs._id} key={recs._id}>
        {recs.Country}
      </option>
    ))
  ) : (
    <></>
  );
});

export default CountryDropdown;
