export const MATERIAL_SUBTITLE_LIST_REQ = "MATERIAL_SUBTITLE_LIST_REQ";
export const MATERIAL_SUBTITLE_LIST_SUCCESS = "MATERIAL_SUBTITLE_LIST_SUCCESS";
export const MATERIAL_SUBTITLE_LIST_FAILED = "MATERIAL_SUBTITLE_LIST_FAILED";
export const MATERIAL_SUBTITLE_LIST_RESET = "MATERIAL_SUBTITLE_LIST_RESET";

export const MATERIAL_SUBTITLE_LIST_ALL_REQ = "MATERIAL_SUBTITLE_LIST_ALL_REQ";
export const MATERIAL_SUBTITLE_LIST_ALL_SUCCESS =
  "MATERIAL_SUBTITLE_LIST_ALL_SUCCESS";
export const MATERIAL_SUBTITLE_LIST_ALL_FAILED =
  "MATERIAL_SUBTITLE_LIST_ALL_FAILED";
export const MATERIAL_SUBTITLE_LIST_ALL_RESET =
  "MATERIAL_SUBTITLE_LIST_ALL_RESET";

export const MATERIAL_SUBTITLE_CREATE_REQ = "MATERIAL_SUBTITLE_CREATE_REQ";
export const MATERIAL_SUBTITLE_CREATE_SUCCESS =
  "MATERIAL_SUBTITLE_CREATE_SUCCESS";
export const MATERIAL_SUBTITLE_CREATE_FAILED =
  "MATERIAL_SUBTITLE_CREATE_FAILED";
export const MATERIAL_SUBTITLE_CREATE_RESET = "MATERIAL_SUBTITLE_CREATE_RESET";

export const MATERIAL_SUBTITLE_UPDATE_REQ = "MATERIAL_SUBTITLE_UPDATE_REQ";
export const MATERIAL_SUBTITLE_UPDATE_SUCCESS =
  "MATERIAL_SUBTITLE_UPDATE_SUCCESS";
export const MATERIAL_SUBTITLE_UPDATE_FAILED =
  "MATERIAL_SUBTITLE_UPDATE_FAILED";
export const MATERIAL_SUBTITLE_UPDATE_RESET = "MATERIAL_SUBTITLE_UPDATE_RESET";

export const MATERIAL_SUBTITLE_REMOVE_REQ = "MATERIAL_SUBTITLE_REMOVE_REQ";
export const MATERIAL_SUBTITLE_REMOVE_SUCCESS =
  "MATERIAL_SUBTITLE_REMOVE_SUCCESS";
export const MATERIAL_SUBTITLE_REMOVE_FAILED =
  "MATERIAL_SUBTITLE_REMOVE_FAILED";
export const MATERIAL_SUBTITLE_REMOVE_RESET = "MATERIAL_SUBTITLE_REMOVE_RESET";

export const MATERIAL_SUBTITLE_DETAILS_REQ = "MATERIAL_SUBTITLE_DETAILS_REQ";
export const MATERIAL_SUBTITLE_DETAILS_SUCCESS =
  "MATERIAL_SUBTITLE_DETAILS_SUCCESS";
export const MATERIAL_SUBTITLE_DETAILS_FAILED =
  "MATERIAL_SUBTITLE_DETAILS_FAILED";
export const MATERIAL_SUBTITLE_DETAILS_RESET =
  "MATERIAL_SUBTITLE_DETAILS_RESET";
