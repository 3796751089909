import {
  EDUCATIONAL_STATUS_DELETE_FAIL,
  EDUCATIONAL_STATUS_DELETE_REQUEST,
  EDUCATIONAL_STATUS_DELETE_SUCCESS,
  EDUCATIONAL_STATUS_GET_FAIL,
  EDUCATIONAL_STATUS_GET_REQUEST,
  EDUCATIONAL_STATUS_GET_SUCCESS,
  EDUCATIONAL_STATUS_INSERT_FAIL,
  EDUCATIONAL_STATUS_INSERT_REQUEST,
  EDUCATIONAL_STATUS_INSERT_SUCCESS,
  EDUCATIONAL_STATUS_LIST_FAIL,
  EDUCATIONAL_STATUS_LIST_REQUEST,
  EDUCATIONAL_STATUS_LIST_SUCCESS,
  EDUCATIONAL_STATUS_UPDATE_FAIL,
  EDUCATIONAL_STATUS_UPDATE_REQUEST,
  EDUCATIONAL_STATUS_UPDATE_SUCCESS,
} from "../Constants/educationalStatusConstants";

export const educationalStatusListReducer = (state = {}, action) => {
  switch (action.type) {
    case EDUCATIONAL_STATUS_LIST_REQUEST:
      return { loading: true };
    case EDUCATIONAL_STATUS_LIST_SUCCESS:
      return { loading: false, educationalStatusRecs: action.payload };
    case EDUCATIONAL_STATUS_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const educationalStatusInsertReducer = (state = {}, action) => {
  switch (action.type) {
    case EDUCATIONAL_STATUS_INSERT_REQUEST:
      return { loading: true };
    case EDUCATIONAL_STATUS_INSERT_SUCCESS:
      return { loading: false, success: action.payload };
    case EDUCATIONAL_STATUS_INSERT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const educationalStatusUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case EDUCATIONAL_STATUS_UPDATE_REQUEST:
      return { loading: true };
    case EDUCATIONAL_STATUS_UPDATE_SUCCESS:
      return { loading: false, success: action.payload };
    case EDUCATIONAL_STATUS_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const educationalStatusDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case EDUCATIONAL_STATUS_DELETE_REQUEST:
      return { loading: true };
    case EDUCATIONAL_STATUS_DELETE_SUCCESS:
      return { loading: false, success: action.payload };
    case EDUCATIONAL_STATUS_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
