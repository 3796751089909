export const EMAIL_TEMPLATE_LIST_REQ = "EMAIL_TEMPLATE_LIST_REQ";
export const EMAIL_TEMPLATE_LIST_SUCCESS = "EMAIL_TEMPLATE_LIST_SUCCESS";
export const EMAIL_TEMPLATE_LIST_FAILED = "EMAIL_TEMPLATE_LIST_FAILED";
export const EMAIL_TEMPLATE_LIST_RESET = "EMAIL_TEMPLATE_LIST_RESET";

export const EMAIL_TEMPLATE_LIST_ALL_REQ = "EMAIL_TEMPLATE_LIST_ALL_REQ";
export const EMAIL_TEMPLATE_LIST_ALL_SUCCESS =
  "EMAIL_TEMPLATE_LIST_ALL_SUCCESS";
export const EMAIL_TEMPLATE_LIST_ALL_FAILED = "EMAIL_TEMPLATE_LIST_ALL_FAILED";
export const EMAIL_TEMPLATE_LIST_ALL_RESET = "EMAIL_TEMPLATE_LIST_ALL_RESET";

export const EMAIL_TEMPLATE_CREATE_REQ = "EMAIL_TEMPLATE_CREATE_REQ";
export const EMAIL_TEMPLATE_CREATE_SUCCESS = "EMAIL_TEMPLATE_CREATE_SUCCESS";
export const EMAIL_TEMPLATE_CREATE_FAILED = "EMAIL_TEMPLATE_CREATE_FAILED";
export const EMAIL_TEMPLATE_CREATE_RESET = "EMAIL_TEMPLATE_CREATE_RESET";

export const EMAIL_TEMPLATE_UPDATE_REQ = "EMAIL_TEMPLATE_UPDATE_REQ";
export const EMAIL_TEMPLATE_UPDATE_SUCCESS = "EMAIL_TEMPLATE_UPDATE_SUCCESS";
export const EMAIL_TEMPLATE_UPDATE_FAILED = "EMAIL_TEMPLATE_UPDATE_FAILED";
export const EMAIL_TEMPLATE_UPDATE_RESET = "EMAIL_TEMPLATE_UPDATE_RESET";

export const EMAIL_TEMPLATE_REMOVE_REQ = "EMAIL_TEMPLATE_REMOVE_REQ";
export const EMAIL_TEMPLATE_REMOVE_SUCCESS = "EMAIL_TEMPLATE_REMOVE_SUCCESS";
export const EMAIL_TEMPLATE_REMOVE_FAILED = "EMAIL_TEMPLATE_REMOVE_FAILED";
export const EMAIL_TEMPLATE_REMOVE_RESET = "EMAIL_TEMPLATE_REMOVE_RESET";

export const EMAIL_TEMPLATE_DETAILS_REQ = "EMAIL_TEMPLATE_DETAILS_REQ";
export const EMAIL_TEMPLATE_DETAILS_SUCCESS = "EMAIL_TEMPLATE_DETAILS_SUCCESS";
export const EMAIL_TEMPLATE_DETAILS_FAILED = "EMAIL_TEMPLATE_DETAILS_FAILED";
export const EMAIL_TEMPLATE_DETAILS_RESET = "EMAIL_TEMPLATE_DETAILS_RESET";
