import {
  CART_DETAIL_CREATE_FAILED,
  CART_DETAIL_CREATE_REQ,
  CART_DETAIL_CREATE_RESET,
  CART_DETAIL_CREATE_SUCCESS,
  CART_DETAIL_REMOVE_FAILED,
  CART_DETAIL_REMOVE_REQ,
  CART_DETAIL_REMOVE_RESET,
  CART_DETAIL_REMOVE_SUCCESS,
  CART_DETAIL_UPDATE_FAILED,
  CART_DETAIL_UPDATE_REQ,
  CART_DETAIL_UPDATE_RESET,
  CART_DETAIL_UPDATE_SUCCESS,
  CART_LIST_FAILED,
  CART_LIST_REQ,
  CART_LIST_SUCCESS,
  CART_PLACE_ORDER_FAILED,
  CART_PLACE_ORDER_REQ,
  CART_PLACE_ORDER_RESET,
  CART_PLACE_ORDER_SUCCESS,
  CART_TOTAL_LIST_FAILED,
  CART_TOTAL_LIST_REQ,
  CART_TOTAL_LIST_SUCCESS,
} from "../Constants/cartConstant";

export const listCartRducer = (state = {}, { type, payload }) => {
  switch (type) {
    case CART_LIST_REQ: {
      return { loading: true };
    }

    case CART_LIST_SUCCESS: {
      return { loading: false, cartRecs: payload };
    }

    case CART_LIST_FAILED: {
      return { loading: false, error: payload };
    }
    default:
      return state;
  }
};

export const cartTotalReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case CART_TOTAL_LIST_REQ: {
      return { loading: true };
    }

    case CART_TOTAL_LIST_SUCCESS: {
      return { loading: false, total: payload };
    }

    case CART_TOTAL_LIST_FAILED: {
      return { loading: false, error: payload };
    }

    default:
      return state;
  }
};

export const createCartDetReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case CART_DETAIL_CREATE_REQ: {
      return { loading: true };
    }
    case CART_DETAIL_CREATE_SUCCESS: {
      return { loading: false, success: payload };
    }

    case CART_DETAIL_CREATE_FAILED: {
      return { loading: false, error: payload };
    }

    case CART_DETAIL_CREATE_RESET: {
      return { loading: false, error: "", success: "" };
    }
    default:
      return state;
  }
};

export const updateCartDetReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case CART_DETAIL_UPDATE_REQ: {
      return { loading: true };
    }
    case CART_DETAIL_UPDATE_SUCCESS: {
      return { loading: false, success: payload };
    }

    case CART_DETAIL_UPDATE_FAILED: {
      return { loading: false, error: payload };
    }

    case CART_DETAIL_UPDATE_RESET: {
      return { loading: false, error: "", success: "" };
    }
    default:
      return state;
  }
};

export const removeCartDetReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case CART_DETAIL_REMOVE_REQ: {
      return { loading: true };
    }
    case CART_DETAIL_REMOVE_SUCCESS: {
      return { loading: false, success: payload };
    }

    case CART_DETAIL_REMOVE_FAILED: {
      return { loading: false, error: payload };
    }

    case CART_DETAIL_REMOVE_RESET: {
      return { loading: false, error: "", success: "" };
    }
    default:
      return state;
  }
};

export const placeOrderReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case CART_PLACE_ORDER_REQ: {
      return { loading: true };
    }

    case CART_PLACE_ORDER_SUCCESS: {
      return { loading: false, success: payload };
    }

    case CART_PLACE_ORDER_FAILED: {
      return { loading: false, error: payload };
    }

    case CART_PLACE_ORDER_RESET: {
      return { loading: false, error: "" };
    }

    default:
      return state;
  }
};
