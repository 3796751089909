export const DOCUMENTS_BULK_LIST_REQUEST = "DOCUMENTS_BULK_LIST_REQUEST";
export const DOCUMENTS_BULK_LIST_SUCCESS = "DOCUMENTS_BULK_LIST_SUCCESS";
export const DOCUMENTS_BULK_LIST_FAIL = "DOCUMENTS_BULK_LIST_FAIL";

export const DOCUMENTS_BULK_GET_REQUEST = "DOCUMENTS_BULK_GET_REQUEST";
export const DOCUMENTS_BULK_GET_SUCCESS = "DOCUMENTS_BULK_GET_SUCCESS";
export const DOCUMENTS_BULK_GET_FAIL = "DOCUMENTS_BULK_GET_FAIL";

export const DOCUMENTS_BULK_INSERT_REQUEST = "DOCUMENTS_BULK_INSERT_REQUEST";
export const DOCUMENTS_BULK_INSERT_SUCCESS = "DOCUMENTS_BULK_INSERT_SUCCESS";
export const DOCUMENTS_BULK_INSERT_FAIL = "DOCUMENTS_BULK_INSERT_FAIL";
export const DOCUMENTS_BULK_INSERT_RESET = "DOCUMENTS_BULK_INSERT_RESET";

export const DOCUMENTS_BULK_UPDATE_REQUEST = "DOCUMENTS_BULK_UPDATE_REQUEST";
export const DOCUMENTS_BULK_UPDATE_SUCCESS = "DOCUMENTS_BULK_UPDATE_SUCCESS";
export const DOCUMENTS_BULK_UPDATE_FAIL = "DOCUMENTS_BULK_UPDATE_FAIL";
export const DOCUMENTS_BULK_UPDATE_RESET = "DOCUMENTS_BULK_UPDATE_RESET";
