export const GET_TIME_ZONE = (time) => {
  var date = new Date(time);

  return (
    date.getDate() +
    " " +
    date.toLocaleString("default", { month: "long" }) +
    " " +
    date.getFullYear() +
    " " +
    "at " +
    date.toLocaleTimeString()
  );
};

export const GET_MONTH_YEAR = (time) => {
  var date = new Date(time);

  return (
    date.getDate() +
    " " +
    date.toLocaleString("default", { month: "long" }) +
    " " +
    date.getFullYear()
  );
};

export const GET_TIME_ZONE2 = (time) => {
  var date = new Date(time);

  return (
    date.getDate() +
    " " +
    date.toLocaleString("default", { month: "short" }) +
    " " +
    date.getFullYear() +
    " " +
    date.toLocaleTimeString()
  );
};

export const GET_TIMESTAMP_TIME = (time) => {
  var date = new Date(time);

  return date.toLocaleString("en-GB").split(",")[1];
};
