import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteEmailLogDetails,
  listEmailLogContact,
  listEmailLogDeal,
  listEmailLogSubagent,
} from "../../../Actions/emailLogActions";
import { TimeStampConverter2 } from "../EmailHide";
import { GET_TIME_ZONE } from "../Time";
import { HiOutlineMail } from "react-icons/hi";

export default function EmailLog({
  setEmailEdit,
  contactId,
  subagentId,
  dealId,
  openEmailTab,
}) {
  const dispatch = useDispatch();
  const [CallData, setCallData] = useState([]);

  const getEmail = () => {
    if (subagentId) {
      dispatch(listEmailLogSubagent(subagentId));
    } else if (dealId) {
      dispatch(listEmailLogDeal(dealId));
    } else {
      dispatch(listEmailLogContact(contactId));
    }
  };

  const {
    loading: loadingntc,
    error: errorntc,
    emailLogContactRec,
  } = useSelector((s) => s.emailLogContactList);

  const {
    loading: loadingntS,
    error: errorntS,
    emailLogSubAgentRec,
  } = useSelector((s) => s.emailLogSubAgentList);

  const {
    loading: loadingntd,
    error: errorntd,
    emailLogDealRec,
  } = useSelector((s) => s.emailLogDealList);

  const { success: successdlt } = useSelector((s) => s.emailLogDelete);

  useEffect(() => {
    if (!emailLogContactRec && !emailLogDealRec && !emailLogSubAgentRec) {
      getEmail();
    }
    if (emailLogContactRec) {
      setCallData(emailLogContactRec);
    } else if (emailLogSubAgentRec) {
      setCallData(emailLogSubAgentRec);
    } else {
      setCallData(emailLogDealRec);
    }
  }, [emailLogContactRec, emailLogDealRec, emailLogSubAgentRec]);

  //  Delete Note

  const handleDelete = (Id) => {
    dispatch(deleteEmailLogDetails(Id));
  };

  useEffect(() => {
    if (successdlt) {
      if (contactId) getEmail();
      else if (dealId) getEmail();
      else if (subagentId) getEmail();
    }
  }, [successdlt]);

  return (
    <div className="create-note">
      <div className="row">
        <div className="col-md-12 text-right">
          <Link className="btn-outer" onClick={openEmailTab}>
            Log Email
          </Link>
          <Link className="btn" onClick={openEmailTab}>
            Create Email
          </Link>
        </div>
        {CallData?.length ? (
          ""
        ) : (
          <div className="col-md-12 text-center mt-3">
            <p>
              Send emails to a contact from this record. Or log emails in SE CRM
              from your email client.
            </p>
          </div>
        )}
      </div>

      {CallData
        ? CallData?.map((el) => (
            <div key={el._id} className="output-task-details show">
              <div className="output-task-header">
                <div className="row">
                  <div className="col-md-6 text-left">
                    <span style={{ fontSize: "20px", marginRight: "7px" }}>
                      <HiOutlineMail />
                    </span>
                    <h5>Logged email</h5>
                  </div>
                  <div className="col-md-6 text-right">
                    <span>{GET_TIME_ZONE(el.createdAt)}</span>
                    <ul className="list-inline right-action-menu">
                      <li className="list-inline-item">
                        <Link
                          onClick={() => {
                            openEmailTab();
                            setEmailEdit({ ...el });
                          }}
                        >
                          Edit
                        </Link>
                      </li>
                      <li className="list-inline-item">
                        <Link onClick={() => handleDelete(el._id)}>Delete</Link>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-12 pl-5 pt-3">
                    <p>
                      Subject:{"  "} {el.EmailSubject}
                    </p>
                    <p>
                      Message:{"  "}
                      <div
                        contentEditable="false"
                        dangerouslySetInnerHTML={{ __html: el.EmailBody }}
                      ></div>
                    </p>
                  </div>
                </div>
                <hr />
              </div>
              <div className="output-task-body pl-4">
                <div className="row">
                  <div className="col-md-4">
                    <span>Contacted</span>
                    <div className="form-group">
                      <p style={{ color: "#0c3eff" }} className=" w-100">
                        {" "}
                        {el?.SubAgent_Id
                          ? el?.SubAgent_Id?.Name
                          : el?.Contact_Id?.full_name
                          ? el?.Contact_Id?.full_name
                          : el?.Contact_Id?.First_Name
                          ? el?.Contact_Id?.First_Name +
                            " " +
                            el?.Contact_Id?.Last_Name
                          : "N/A"}{" "}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <span>Date</span>
                    <input
                      type="date"
                      name="task-date"
                      value={
                        el?.Email_Date ? TimeStampConverter2(el.Email_Date) : ""
                      }
                      disabled={true}
                      className="w-100"
                      style={{ border: "none" }}
                    />
                  </div>
                  <div className="col-md-4">
                    <span>Time</span>
                    <input
                      name="task-time"
                      value={el.Email_Time}
                      disabled={true}
                      className="w-100"
                      style={{ border: "none" }}
                    />
                  </div>
                  <div className="col-md-12">
                    <hr />
                  </div>

                  <div className="col-md-12 pl-5">
                    <h6>
                      <i
                        className="fas fa-user-circle"
                        style={{ color: "#d1d4d4" }}
                      ></i>
                      <b style={{ fontWeight: "500" }}>
                        {el?.Created_By?.First_Name
                          ? el?.Created_By?.First_Name +
                            " " +
                            el?.Created_By?.Last_Name
                          : ""}
                      </b>{" "}
                      logged a Email
                    </h6>
                  </div>
                </div>
                <hr />
              </div>
              <div style={{ padding: "10px 20px", fontWeight: "500" }}>
                {el?.Comments}
              </div>
              <div className="output-task-footer d-flex">
                {/* <p style={{ padding: "10px" }}>{el?.Comments}</p> */}
                <i
                  className="fas fa-comment-dots"
                  style={{ cursor: "pointer" }}
                ></i>
                <p>Add Comment</p>
              </div>
            </div>
          ))
        : ""}
    </div>
  );
}
