export const TASK_LOG_LIST_REQUEST = "TASK_LOG_LIST_REQUEST";
export const TASK_LOG_LIST_SUCCESS = "TASK_LOG_LIST_SUCCESS";
export const TASK_LOG_LIST_FAIL = "TASK_LOG_LIST_FAIL";

export const TASK_LOG_CONTACT_LIST_REQUEST = "TASK_LOG_CONTACT_LIST_REQUEST";
export const TASK_LOG_CONTACT_LIST_SUCCESS = "TASK_LOG_CONTACT_LIST_SUCCESS";
export const TASK_LOG_CONTACT_LIST_FAIL = "TASK_LOG_CONTACT_LIST_FAIL";

export const TASK_LOG_DEAL_LIST_REQUEST = "TASK_LOG_DEAL_LIST_REQUEST";
export const TASK_LOG_DEAL_LIST_SUCCESS = "TASK_LOG_DEAL_LIST_SUCCESS";
export const TASK_LOG_DEAL_LIST_FAIL = "TASK_LOG_DEAL_LIST_FAIL";

export const TASK_LOG_SUBAGENT_LIST_REQUEST = "TASK_LOG_SUBAGENT_LIST_REQUEST";
export const TASK_LOG_SUBAGENT_LIST_SUCCESS = "TASK_LOG_SUBAGENT_LIST_SUCCESS";
export const TASK_LOG_SUBAGENT_LIST_FAIL = "TASK_LOG_SUBAGENT_LIST_FAIL";

export const TASK_LOG_INSERT_REQUEST = "TASK_LOG_INSERT_REQUEST";
export const TASK_LOG_INSERT_SUCCESS = "TASK_LOG_INSERT_SUCCESS";
export const TASK_LOG_INSERT_FAIL = "TASK_LOG_INSERT_FAIL";
export const TASK_LOG_INSERT_RESET = "TASK_LOG_INSERT_RESET";

export const TASK_LOG_UPDATE_REQUEST = "TASK_LOG_UPDATE_REQUEST";
export const TASK_LOG_UPDATE_SUCCESS = "TASK_LOG_UPDATE_SUCCESS";
export const TASK_LOG_UPDATE_FAIL = "TASK_LOG_UPDATE_FAIL";
export const TASK_LOG_UPDATE_RESET = "TASK_LOG_UPDATE_RESET";

export const TASK_LOG_DELETE_REQUEST = "TASK_LOG_DELETE_REQUEST";
export const TASK_LOG_DELETE_SUCCESS = "TASK_LOG_DELETE_SUCCESS";
export const TASK_LOG_DELETE_FAIL = "TASK_LOG_DELETE_FAIL";
export const TASK_LOG_DELETE_RESET = "TASK_LOG_DELETE_RESET";

export const TASK_REMINDER_LIST_DASHBOARD_FAIL =
  "TASK_REMINDER_LIST_DASHBOARD_FAIL";
export const TASK_REMINDER_LIST_DASHBOARD_REQUEST =
  "TASK_REMINDER_LIST_DASHBOARD_REQUEST";
export const TASK_REMINDER_LIST_DASHBOARD_SUCCESS =
  "TASK_REMINDER_LIST_DASHBOARD_SUCCESS";

export const TASK_REMINDER_TOTAL_FAIL = "TASK_REMINDER_TOTAL_FAIL";
export const TASK_REMINDER_TOTAL_REQUEST = "TASK_REMINDER_TOTAL_REQUEST";
export const TASK_REMINDER_TOTAL_SUCCESS = "TASK_REMINDER_TOTAL_SUCCESS";

export const TASK_LOG_GET_ALL_REQ = "TASK_LOG_GET_ALL_REQ";
export const TASK_LOG_GET_ALL_SUCCESS = "TASK_LOG_GET_ALL_SUCCESS";
export const TASK_LOG_GET_ALL_FAIL = "TASK_LOG_GET_ALL_FAIL";
