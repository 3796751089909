export const CREATE_COUPON_REQ = "CREATE_COUPON_REQ";
export const CREATE_COUPON_SUCCESS = "CREATE_COUPON_SUCCESS";
export const CREATE_COUPON_FAILED = "CREATE_COUPON_FAILED";
export const CREATE_COUPON_RESET = "CREATE_COUPON_RESET";

export const UPDATE_COUPON_REQ = "UPDATE_COUPON_REQ";
export const UPDATE_COUPON_SUCCESS = "UPDATE_COUPON_SUCCESS";
export const UPDATE_COUPON_FAILED = "UPDATE_COUPON_FAILED";
export const UPDATE_COUPON_RESET = "UPDATE_COUPON_RESET";

export const COUPON_LIST_REQ = "COUPON_LIST_REQ";
export const COUPON_LIST_SUCCESS = "COUPON_LIST_SUCCESS";
export const COUPON_LIST_FAILED = "COUPON_LIST_FAILED";
export const COUPON_LIST_RESET = "COUPON_LIST_RESET";

export const COUPON_DETAILS_REQ = "COUPON_DETAILS_REQ";
export const COUPON_DETAILS_SUCCESS = "COUPON_DETAILS_SUCCESS";
export const COUPON_DETAILS_FAILED = "COUPON_DETAILS_FAILED";
export const COUPON_DETAILS_RESET = "COUPON_DETAILS_RESET";

export const COUPON_DELETE_REQ = "COUPON_DELETE_REQ";
export const COUPON_DELETE_SUCCESS = "COUPON_DELETE_SUCCESS";
export const COUPON_DELETE_FAILED = "COUPON_DELETE_FAILED";
export const COUPON_DELETE_RESET = "COUPON_DELETE_RESET";
