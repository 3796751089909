import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

export default function PrivateRoute({ children }) {
  const { userRec } = useSelector((state) => state.loginUsers);
  const location = useLocation();

  if (!userRec) {
    return <Navigate to="/login" state={location.pathname} replace />;
  }

  return children;
}
