import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listCalssMode } from "../../Actions/ClassModeAction";

const ClassModeDropDown = React.memo(() => {
  const dispatch = useDispatch();
  const { ClassModeRec } = useSelector((s) => s.listCalssModeReducer);
  useEffect(() => {
    dispatch(listCalssMode());
  }, [dispatch]);
  return ClassModeRec ? (
    ClassModeRec?.map((recs) => (
      <option value={recs.id} key={recs.id}>
        {recs.name}
      </option>
    ))
  ) : (
    <></>
  );
});
export default ClassModeDropDown;
