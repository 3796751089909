export const WORKFLOW_LIST_REQUEST = 'WORKFLOW_LIST_REQUEST'
export const WORKFLOW_LIST_SUCCESS = 'WORKFLOW_LIST_SUCCESS'
export const WORKFLOW_LIST_FAIL = 'WORKFLOW_LIST_FAIL'

export const WORKFLOW_INSERT_REQUEST = 'WORKFLOW_INSERT_REQUEST'
export const WORKFLOW_INSERT_SUCCESS = 'WORKFLOW_INSERT_SUCCESS'
export const WORKFLOW_INSERT_FAIL = 'WORKFLOW_INSERT_FAIL'
export const WORKFLOW_INSERT_RESET = 'WORKFLOW_INSERT_RESET'

export const WORKFLOW_UPDATE_REQUEST = 'WORKFLOW_UPDATE_REQUEST'
export const WORKFLOW_UPDATE_SUCCESS = 'WORKFLOW_UPDATE_SUCCESS'
export const WORKFLOW_UPDATE_FAIL = 'WORKFLOW_UPDATE_FAIL'
export const WORKFLOW_UPDATE_RESET = 'WORKFLOW_UPDATE_RESET'

export const WORKFLOW_DELETE_REQUEST = 'WORKFLOW_DELETE_REQUEST'
export const WORKFLOW_DELETE_SUCCESS = 'WORKFLOW_DELETE_SUCCESS'
export const WORKFLOW_DELETE_FAIL = 'WORKFLOW_DELETE_FAIL'
export const WORKFLOW_DELETE_RESET = 'WORKFLOW_DELETE_RESET'