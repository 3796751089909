import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listUserRole } from "../../Actions/userRoleActions";

// export default function UserRoleDropdown() {
//   const dispatch = useDispatch();
//   const { userRoleRecs } = useSelector((s) => s.userRoleList);
//   const getRecs = () => {
//     dispatch(listUserRole());
//   };
//   useMemo(() => {
//     if (!userRoleRecs) getRecs();
//   }, [dispatch, userRoleRecs]);

//   return userRoleRecs ? (
//     userRoleRecs.map((rec) => (
//       <option value={rec._id} key={rec._id}>
//         {rec.Role_Name}
//       </option>
//     ))
//   ) : (
//     <></>
//   );
// }

const UserRoleDropdown = React.memo(() => {
  const dispatch = useDispatch();
  const { userRoleRecs } = useSelector((s) => s.userRoleList);
  const getRecs = () => {
    dispatch(listUserRole());
  };
  useMemo(() => {
    if (!userRoleRecs) getRecs();
  }, [dispatch, userRoleRecs]);

  return userRoleRecs ? (
    userRoleRecs.map((rec) => (
      <option value={rec._id} key={rec._id}>
        {rec.UserRole_Name}
      </option>
    ))
  ) : (
    <></>
  );
});

export default UserRoleDropdown;
