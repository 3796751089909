import { PropagateLoader } from "react-spinners";

export default function SEDLoading() {
  return (
    <div
      id="CreatContact"
      style={{ width: "100%", zIndex: "928832474677" }}
      className={"overlay-note middle-hand w100"}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20%",
        }}
      >
        <PropagateLoader color="#0c3eff" />
      </div>
    </div>
  );
}
