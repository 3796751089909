import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  TaskLogReminder,
  deleteTaskLogDetails,
  listTaskLogContact,
  listTaskLogDeal,
  listTaskLogSubAgent,
} from "../../../Actions/taskLogActions";
import UserDropdown from "../UserDropdown";
import { TimeStampConverter2 } from "../EmailHide";
import { GET_TIME_ZONE } from "../Time";
import { MdOutlineTaskAlt } from "react-icons/md";
import { BsFillPinFill } from "react-icons/bs";

export default function TaskLog({
  contactId,
  subagentId,
  dealId,
  setEditTask,
  openTaskTab,
}) {
  const dispatch = useDispatch();
  const [TaskData, setTaskData] = useState([]);
  const { success: successdlt } = useSelector((s) => s.taskLogDelete);

  const getTasks = useCallback(() => {
    if (subagentId) {
      dispatch(listTaskLogSubAgent(subagentId));
    } else if (dealId) {
      dispatch(listTaskLogDeal(dealId));
    } else {
      dispatch(listTaskLogContact(contactId));
    }
  }, [dealId, dispatch, contactId, subagentId]);

  const {
    loading: loadingntc,
    error: errorntc,
    taskLogContactRec,
  } = useSelector((s) => s.taskLogContactList);

  const {
    loading: loadingntS,
    error: errorntS,
    taskLogSubAgentRec,
  } = useSelector((s) => s.taskLogSubAgentList);

  const {
    loading: loadingRmnd,
    success: successRmnd,
    error: errorRmnd,
  } = useSelector((s) => s.taskLogReminder);

  const {
    loading: loadingntd,
    error: errorntd,
    taskLogDealRec,
  } = useSelector((s) => s.taskLogDealList);

  useEffect(() => {
    if (!taskLogContactRec && !taskLogDealRec && !taskLogSubAgentRec) {
      getTasks();
    }
    if (taskLogContactRec) {
      setTaskData(taskLogContactRec);
    } else if (taskLogDealRec) {
      setTaskData(taskLogDealRec);
    } else if (taskLogSubAgentRec) {
      setTaskData(taskLogSubAgentRec);
    } else {
      setTaskData([]);
    }
  }, [taskLogContactRec, taskLogDealRec, getTasks, taskLogSubAgentRec]);

  //  Delete Note
  const handleDelete = (Id) => {
    dispatch(deleteTaskLogDetails(Id));
  };

  const Today = new Date();

  useEffect(() => {
    if (successdlt || successRmnd) {
      if (contactId) dispatch(listTaskLogContact(contactId));
      // else if (dealId) dispatch(listTaskLogDeal(dealId));
      // else if (subagentId) dispatch(listTaskLogSubAgent(subagentId));
    }
  }, [successdlt, successRmnd]);

  // console.log(successRmnd);
  return (
    <>
      <div className="create-note">
        <div className="row">
          <div className="col-md-12 text-right">
            <Link onClick={openTaskTab} className="btn">
              Create Task
            </Link>
          </div>
          {TaskData?.length ? (
            ""
          ) : (
            <div className="col-md-12 text-center mt-3">
              <p>
                Create a task for yourself or a teammate. Keep track of all your
                to-dos for this record.
              </p>
            </div>
          )}
        </div>
      </div>

      {TaskData
        ? TaskData?.map((el) => (
            <div key={el._id} className="output-task-details">
              <div className="output-task-header">
                <div className="row">
                  <div className="col-md-7 text-left task-header-left">
                    <sapn
                      style={{
                        fontSize: "20px",
                        marginRight: "5px",
                      }}
                    >
                      <BsFillPinFill />
                    </sapn>

                    <h5>Task</h5>
                    {TimeStampConverter2(el.Due_Date) <
                      TimeStampConverter2(Today) ||
                    TimeStampConverter2(el.Due_Date) <
                      TimeStampConverter2(el.reminder_off_Date) ? (
                      <span>Overdue</span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-md-5 text-right">
                    {el?.ReminderStatus ? (
                      <ul className="list-inline right-action-menu">
                        <li className="list-inline-item">
                          <Link
                            onClick={() => {
                              
                              setEditTask({...el});
                              openTaskTab();
                            }}
                          >
                            Edit
                          </Link>
                        </li>
                        <li className="list-inline-item">
                          <Link onClick={() => handleDelete(el._id)}>
                            Delete
                          </Link>
                        </li>
                      </ul>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-md-12 pl-5 pt-3">
                    <span
                      className={
                        el?.Reminder_Status ? "Active_Task" : "Deactive_Task"
                      }
                      disabled={loadingRmnd}
                      onClick={() => dispatch(TaskLogReminder(el._id))}
                    >
                      <MdOutlineTaskAlt />
                    </span>

                    <h5
                      style={
                        el?.ReminderStatus
                          ? {
                              color: "black",
                            }
                          : { color: "#0c3eff", textDecoration: "line-through" }
                      }
                    >
                      {el?.TaskTitle}
                    </h5>
                  </div>
                </div>
                <hr />
              </div>
              <div className="output-task-inner-details">
                <div className="output-task-body pl-4">
                  <div className="row">
                    <div className="col-md-12 mb-2">
                      <span>Assigned to</span>
                      <br />
                      <div className="form-group d-flex w-50 mt-2">
                        <i className="far fa-user-circle"></i>
                        <select
                          value={el.AssignedTo}
                          className="form-control  Pad"
                          disabled={true}
                        >
                          <option value="">Associated with 1 record</option>
                          <UserDropdown />
                        </select>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <span className="pl-2"> Due Date</span>
                      <br />
                      <input
                        name="Date-time"
                        disabled
                        value={
                          TimeStampConverter2(el.DueDate) + " " + el.Due_Time
                        }
                        style={{ border: "none" }}
                      />
                    </div>
                    <div className="col-md-3">
                      <span className="pl-2">Type</span>
                      <br />
                      <div className="form-group" style={{ width: "65px" }}>
                        <p>{el.TaskType}</p>
                      </div>
                    </div>
                    <div className="col-md-5">
                      <span className="pl-2">Priority</span>
                      <br />
                      <div
                        className="form-group"
                        style={{ width: "65px", padding: "10px 0" }}
                      >
                        <p>
                          {el.Priority === 1
                            ? "High"
                            : el.Priority === 0
                            ? "No"
                            : ""}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <span className="pl-2"> Reminder</span>
                      <br />
                      <input
                        name="Date-time"
                        value={
                          TimeStampConverter2(el.DueDate) + " " + el.Due_Time
                        }
                        disabled
                        style={{ border: "none" }}
                      />
                    </div>
                    <div className="col-md-3">
                      <span className="pl-2">Queue</span>
                      <br />
                      <div className="form-group" style={{ width: "65px" }}>
                        <select disabled className="form-control Pad">
                          <option value="">None</option>
                          <option value="">---</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-5">
                      <span>Created by</span>
                      <br />
                      <h6
                        style={{
                          fontSize: "14px",
                          marginTop: "3px",
                        }}
                      >
                        {el?.Created_By?.First_Name
                          ? el?.Created_By?.First_Name +
                            " " +
                            el?.Created_By?.Last_Name
                          : ""}
                      </h6>
                    </div>
                  </div>
                  <hr />
                  <div>
                    {el?.TaskDescription ? (
                      <>
                        <p>Regarding</p>
                        <div
                          contentEditable="false"
                          dangerouslySetInnerHTML={{
                            __html: el.TaskDescription,
                          }}
                        ></div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="output-task-footer pl-4">
                  <p>Regarding call logged on {GET_TIME_ZONE(el.createdAt)}</p>
                </div>
              </div>
            </div>
          ))
        : ""}
    </>
  );
}
