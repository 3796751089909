import {
  MATERIAL_CREATE_FAILED,
  MATERIAL_CREATE_REQ,
  MATERIAL_CREATE_RESET,
  MATERIAL_CREATE_SUCCESS,
  MATERIAL_DETAILS_FAILED,
  MATERIAL_DETAILS_REQ,
  MATERIAL_DETAILS_RESET,
  MATERIAL_DETAILS_SUCCESS,
  MATERIAL_LIST_FAILED,
  MATERIAL_LIST_REQ,
  MATERIAL_LIST_SUCCESS,
  MATERIAL_REMOVE_FAILED,
  MATERIAL_REMOVE_REQ,
  MATERIAL_REMOVE_RESET,
  MATERIAL_REMOVE_SUCCESS,
  MATERIAL_TOTAL_FAILED,
  MATERIAL_TOTAL_REQ,
  MATERIAL_TOTAL_SUCCESS,
  MATERIAL_UPDATE_FAILED,
  MATERIAL_UPDATE_REQ,
  MATERIAL_UPDATE_RESET,
  MATERIAL_UPDATE_SUCCESS,
} from "../Constants/materialConstants";

export const createMaterialReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case MATERIAL_CREATE_REQ: {
      return { loading: true };
    }

    case MATERIAL_CREATE_SUCCESS: {
      return { loading: false, success: payload };
    }

    case MATERIAL_CREATE_FAILED: {
      return { loading: false, error: payload };
    }

    case MATERIAL_CREATE_RESET: {
      return { success: "", error: "" };
    }

    default:
      return state;
  }
};

export const listMaterialReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case MATERIAL_LIST_REQ: {
      return { loading: true };
    }

    case MATERIAL_LIST_SUCCESS: {
      return { loading: false, materialRecs: payload };
    }

    case MATERIAL_LIST_FAILED: {
      return { loading: false, error: payload };
    }
    default:
      return state;
  }
};

export const updateMaterialReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case MATERIAL_UPDATE_REQ: {
      return { loading: true };
    }

    case MATERIAL_UPDATE_SUCCESS: {
      return { loading: false, success: payload };
    }

    case MATERIAL_UPDATE_FAILED: {
      return { loading: false, error: payload };
    }

    case MATERIAL_UPDATE_RESET: {
      return { loading: false, error: "" };
    }
    default:
      return state;
  }
};

export const removeMaterialReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case MATERIAL_REMOVE_REQ: {
      return { loading: false };
    }

    case MATERIAL_REMOVE_SUCCESS: {
      return { loading: false, success: payload };
    }

    case MATERIAL_REMOVE_FAILED: {
      return { loading: false, error: payload };
    }

    case MATERIAL_REMOVE_RESET: {
      return { loading: false, success: "", error: "" };
    }

    default:
      return state;
  }
};

export const materialDetailsReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case MATERIAL_DETAILS_REQ: {
      return { loading: true };
    }

    case MATERIAL_DETAILS_SUCCESS: {
      return { loading: false, DetMaterial: payload };
    }

    case MATERIAL_DETAILS_FAILED: {
      return { loading: false, error: payload };
    }

    case MATERIAL_DETAILS_RESET: {
      return { loading: false, success: "", error: "" };
    }
    default:
      return state;
  }
};

export const totalMaterialReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case MATERIAL_TOTAL_REQ: {
      return { loading: true };
    }

    case MATERIAL_TOTAL_SUCCESS: {
      return { loading: false, total: payload };
    }

    case MATERIAL_TOTAL_FAILED: {
      return { loading: false, error: payload };
    }

    default:
      return state;
  }
};
