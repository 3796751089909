// export const taskLogJson = {
//   _id: "",
//   Contact_Id: "",
//   Deal_Id: "",
//   Task_Title: "",
//   Task_Description: "",
//   Assigned_to: "",
//   Due_Date: "",
//   Due_Time: "",
//   Task_Type: "",
//   Priority: "",
//   reminder_time: "",
//   reminder_off_Date: "",
//   Comments: "",
// };

export const taskLogJson = {
  ContactId: "",
  TaskTitle: "",
  TaskDescription: "",
  AssignedTo: "",
  Priority: "",
  DueDate: "",
  ReminderDate: "",
  ReminderStatus: "",
  ReminderOffDate: "",
  Comments: "",
};
