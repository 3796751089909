import {
  GURULLY_CONTACT_DELETE_FAIL,
  GURULLY_CONTACT_DELETE_REQUEST,
  GURULLY_CONTACT_DELETE_RESET,
  GURULLY_CONTACT_DELETE_SUCCESS,
  GURULLY_CONTACT_GET_FAIL,
  GURULLY_CONTACT_GET_REQUEST,
  GURULLY_CONTACT_GET_SUCCESS,
  GURULLY_CONTACT_INSERT_FAIL,
  GURULLY_CONTACT_INSERT_REQUEST,
  GURULLY_CONTACT_INSERT_RESET,
  GURULLY_CONTACT_INSERT_SUCCESS,
  GURULLY_CONTACT_LIST_FAIL,
  GURULLY_CONTACT_LIST_REQUEST,
  GURULLY_CONTACT_LIST_SUCCESS,
  GURULLY_CONTACT_UPDATE_FAIL,
  GURULLY_CONTACT_UPDATE_REQUEST,
  GURULLY_CONTACT_UPDATE_RESET,
  GURULLY_CONTACT_UPDATE_SUCCESS,
} from "../Constants/gurullyContactConstant";

export const gurullyContactListReducer = (state = {}, action) => {
  switch (action.type) {
    case GURULLY_CONTACT_LIST_REQUEST:
      return { loading: true };
    case GURULLY_CONTACT_LIST_SUCCESS:
      return { loading: false, gurullyContactRecs: action.payload };
    case GURULLY_CONTACT_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const gurullyContactGetReducer = (state = {}, action) => {
  switch (action.type) {
    case GURULLY_CONTACT_GET_REQUEST:
      return { loading: true };
    case GURULLY_CONTACT_GET_SUCCESS:
      return { loading: false, gurullyContactRec: action.payload };
    case GURULLY_CONTACT_GET_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const gurullyContactInsertReducer = (state = {}, action) => {
  switch (action.type) {
    case GURULLY_CONTACT_INSERT_REQUEST:
      return { loading: true };
    case GURULLY_CONTACT_INSERT_SUCCESS:
      return { loading: false, success: action.payload };
    case GURULLY_CONTACT_INSERT_FAIL:
      return { loading: false, error: action.payload };
    case GURULLY_CONTACT_INSERT_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const gurullyContactUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case GURULLY_CONTACT_UPDATE_REQUEST:
      return { loading: true };
    case GURULLY_CONTACT_UPDATE_SUCCESS:
      return { loading: false, success: action.payload };
    case GURULLY_CONTACT_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case GURULLY_CONTACT_UPDATE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const gurullyContactDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case GURULLY_CONTACT_DELETE_REQUEST:
      return { loading: true };
    case GURULLY_CONTACT_DELETE_SUCCESS:
      return { loading: false, success: action.payload };
    case GURULLY_CONTACT_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case GURULLY_CONTACT_DELETE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};
