import {
  GURULLY_DELETE_FAIL,
  GURULLY_DELETE_REQUEST,
  GURULLY_DELETE_RESET,
  GURULLY_DELETE_SUCCESS,
  GURULLY_GET_FAIL,
  GURULLY_GET_REQUEST,
  GURULLY_GET_SUCCESS,
  GURULLY_INSERT_FAIL,
  GURULLY_INSERT_REQUEST,
  GURULLY_INSERT_RESET,
  GURULLY_INSERT_SUCCESS,
  GURULLY_LIST_FAIL,
  GURULLY_LIST_REQUEST,
  GURULLY_LIST_SUCCESS,
  GURULLY_UPDATE_FAIL,
  GURULLY_UPDATE_REQUEST,
  GURULLY_UPDATE_RESET,
  GURULLY_UPDATE_SUCCESS,
} from "../Constants/gurullyConstant";

export const gurullyListReducer = (state = {}, action) => {
  switch (action.type) {
    case GURULLY_LIST_REQUEST:
      return { loading: true };
    case GURULLY_LIST_SUCCESS:
      return { loading: false, gurullyRecs: action.payload };
    case GURULLY_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const gurullyGetReducer = (state = {}, action) => {
  switch (action.type) {
    case GURULLY_GET_REQUEST:
      return { loading: true };
    case GURULLY_GET_SUCCESS:
      return { loading: false, gurullyRec: action.payload };
    case GURULLY_GET_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const gurullyInsertReducer = (state = {}, action) => {
  switch (action.type) {
    case GURULLY_INSERT_REQUEST:
      return { loading: true };
    case GURULLY_INSERT_SUCCESS:
      return { loading: false, success: action.payload };
    case GURULLY_INSERT_FAIL:
      return { loading: false, error: action.payload };
    case GURULLY_INSERT_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const gurullyUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case GURULLY_UPDATE_REQUEST:
      return { loading: true };
    case GURULLY_UPDATE_SUCCESS:
      return { loading: false, success: action.payload };
    case GURULLY_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case GURULLY_UPDATE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const gurullyDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case GURULLY_DELETE_REQUEST:
      return { loading: true };
    case GURULLY_DELETE_SUCCESS:
      return { loading: false, success: action.payload };
    case GURULLY_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case GURULLY_DELETE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};
