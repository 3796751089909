export const SUBSCRIBED_LEADS_LIST_REQUEST = 'SUBSCRIBED_LEADS_LIST_REQUEST'
export const SUBSCRIBED_LEADS_LIST_SUCCESS = 'SUBSCRIBED_LEADS_LIST_SUCCESS'
export const SUBSCRIBED_LEADS_LIST_FAIL = 'SUBSCRIBED_LEADS_LIST_FAIL'

export const SUBSCRIBED_LEADS_INSERT_REQUEST = 'SUBSCRIBED_LEADS_INSERT_REQUEST'
export const SUBSCRIBED_LEADS_INSERT_SUCCESS = 'SUBSCRIBED_LEADS_INSERT_SUCCESS'
export const SUBSCRIBED_LEADS_INSERT_FAIL = 'SUBSCRIBED_LEADS_INSERT_FAIL'
export const SUBSCRIBED_LEADS_INSERT_RESET = 'SUBSCRIBED_LEADS_INSERT_RESET'

export const SUBSCRIBED_LEADS_UPDATE_REQUEST = 'SUBSCRIBED_LEADS_UPDATE_REQUEST'
export const SUBSCRIBED_LEADS_UPDATE_SUCCESS = 'SUBSCRIBED_LEADS_UPDATE_SUCCESS'
export const SUBSCRIBED_LEADS_UPDATE_FAIL = 'SUBSCRIBED_LEADS_UPDATE_FAIL'
export const SUBSCRIBED_LEADS_UPDATE_RESET = 'SUBSCRIBED_LEADS_UPDATE_RESET'

export const SUBSCRIBED_LEADS_DELETE_REQUEST = 'SUBSCRIBED_LEADS_DELETE_REQUEST'
export const SUBSCRIBED_LEADS_DELETE_SUCCESS = 'SUBSCRIBED_LEADS_DELETE_SUCCESS'
export const SUBSCRIBED_LEADS_DELETE_FAIL = 'SUBSCRIBED_LEADS_DELETE_FAIL'
export const SUBSCRIBED_LEADS_DELETE_RESET = 'SUBSCRIBED_LEADS_DELETE_RESET'