import {
  TASK_LOG_DELETE_FAIL,
  TASK_LOG_DELETE_REQUEST,
  TASK_LOG_DELETE_RESET,
  TASK_LOG_DELETE_SUCCESS,
  TASK_LOG_INSERT_FAIL,
  TASK_LOG_INSERT_REQUEST,
  TASK_LOG_INSERT_RESET,
  TASK_LOG_INSERT_SUCCESS,
  TASK_LOG_CONTACT_LIST_FAIL,
  TASK_LOG_CONTACT_LIST_REQUEST,
  TASK_LOG_CONTACT_LIST_SUCCESS,
  TASK_LOG_DEAL_LIST_FAIL,
  TASK_LOG_DEAL_LIST_REQUEST,
  TASK_LOG_DEAL_LIST_SUCCESS,
  TASK_LOG_UPDATE_FAIL,
  TASK_LOG_UPDATE_REQUEST,
  TASK_LOG_UPDATE_RESET,
  TASK_LOG_UPDATE_SUCCESS,
  TASK_LOG_GET_ALL_REQ,
  TASK_LOG_GET_ALL_SUCCESS,
  TASK_LOG_GET_ALL_FAIL,
  TASK_LOG_SUBAGENT_LIST_REQUEST,
  TASK_LOG_SUBAGENT_LIST_SUCCESS,
  TASK_LOG_SUBAGENT_LIST_FAIL,
} from "../Constants/taskLogConstants";

export const taskLogContactListReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case TASK_LOG_CONTACT_LIST_REQUEST:
      return { loading: true };
    case TASK_LOG_CONTACT_LIST_SUCCESS:
      return { loading: false, taskLogContactRec: payload };
    case TASK_LOG_CONTACT_LIST_FAIL:
      return { loading: false, error: payload };
    default:
      return state;
  }
};

export const taskLogDealListReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case TASK_LOG_DEAL_LIST_REQUEST:
      return { loading: true };
    case TASK_LOG_DEAL_LIST_SUCCESS:
      return { loading: false, taskLogDealRec: payload };
    case TASK_LOG_DEAL_LIST_FAIL:
      return { loading: false, error: payload };
    default:
      return state;
  }
};

export const taskLogSubAgentListReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case TASK_LOG_SUBAGENT_LIST_REQUEST:
      return { loading: true };
    case TASK_LOG_SUBAGENT_LIST_SUCCESS:
      return { loading: false, taskLogSubAgentRec: payload };
    case TASK_LOG_SUBAGENT_LIST_FAIL:
      return { loading: false, error: payload };
    default:
      return state;
  }
};

export const taskLogSaveReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case TASK_LOG_INSERT_REQUEST:
      return { loading: true };
    case TASK_LOG_INSERT_SUCCESS:
      return { loading: false, success: payload };
    case TASK_LOG_INSERT_FAIL:
      return { loading: false, error: payload };
    case TASK_LOG_INSERT_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const taskLogUpdateReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case TASK_LOG_UPDATE_REQUEST:
      return { loading: true };
    case TASK_LOG_UPDATE_SUCCESS:
      return { loading: false, success: payload };
    case TASK_LOG_UPDATE_FAIL:
      return { loading: false, error: payload };
    case TASK_LOG_UPDATE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const taskLogDeleteReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case TASK_LOG_DELETE_REQUEST:
      return { loading: true };
    case TASK_LOG_DELETE_SUCCESS:
      return { loading: false, success: payload };
    case TASK_LOG_DELETE_FAIL:
      return { loading: false, error: payload };
    case TASK_LOG_DELETE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const TaskLogReminderReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case "TASK_REMINDER_REQ": {
      return { loading: true };
    }

    case "TASK_REMINDER_SUCCESS": {
      return { loading: false, success: payload };
    }

    case "TASK_REMINDER_FAIL": {
      return { laoding: false, error: payload };
    }

    default:
      return state;
  }
};

export const TaskLogAllDataReducer = (state = {}, { type, payload, Total }) => {
  switch (type) {
    case TASK_LOG_GET_ALL_REQ: {
      return { loading: true };
    }

    case TASK_LOG_GET_ALL_SUCCESS: {
      return { loading: false, TaskLogRecs: payload, total: Total };
    }

    case TASK_LOG_GET_ALL_FAIL: {
      return { loading: false, error: payload };
    }
    default:
      return state;
  }
};
