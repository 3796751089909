import {
  TLAT_RESULT_FAILED,
  TLAT_RESULT_LIST_FAILED,
  TLAT_RESULT_LIST_REQ,
  TLAT_RESULT_LIST_SUCCESS,
  TLAT_RESULT_REQ,
  TLAT_RESULT_SUCCESS,
} from "../Constants/TLatResultConstant";

export const TLat_ResultReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case TLAT_RESULT_REQ: {
      return { loading: true };
    }

    case TLAT_RESULT_SUCCESS: {
      return { loading: false, AnsweDet: payload };
    }

    case TLAT_RESULT_FAILED: {
      return { loading: false, error: payload };
    }
    default:
      return state;
  }
};

export const TLat_list_ResultReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case TLAT_RESULT_LIST_REQ: {
      return { loading: true };
    }

    case TLAT_RESULT_LIST_SUCCESS: {
      return { loading: false, TLAT_List: payload };
    }

    case TLAT_RESULT_LIST_FAILED: {
      return { loading: false, error: payload };
    }
    default:
      return state;
  }
};
