import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { noteLogJson } from "../../../Models/NoteLogModels";
import {
  listNoteLogContact,
  listNoteLogDeal,
  listNoteLogSubaegnt,
  saveNoteLogDetails,
  updateNoteLogDetails,
} from "../../../Actions/noteLogActions";
import {
  NOTE_LOG_INSERT_RESET,
  NOTE_LOG_UPDATE_RESET,
} from "../../../Constants/noteLogConstants";
import ReactQuill from "react-quill";
import {
  getContactActivity,
  getDealActivity,
  getSubAgentActivity,
} from "../../../Actions/activityActions";
import {
  toastOptions,
  toolbarOptions,
} from "../../../Models/ToastEditiorOption";
import { toast } from "react-toastify";

export default function NoteInsert({
  contactId,
  subagentId,
  dealId,
  editNote,
  closePopup,
}) {
  const dispatch = useDispatch();
  const [noteData, setNoteData] = useState(noteLogJson);
  const { loading, error, success } = useSelector((s) => s.noteLogSave);

  const {
    loading: loadingUpdt,
    success: successUpdt,
    error: errorUpdt,
  } = useSelector((s) => s.noteLogUpdate);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      saveNoteLogDetails({
        ...noteData,
        ContactId: contactId,
        Deal_Id: dealId,
        SubAgent_Id: subagentId,
      })
    );
  };

  const getActivities = useCallback(() => {
    if (subagentId) {
      dispatch(getSubAgentActivity(subagentId));
    } else if (contactId) {
      dispatch(getContactActivity(contactId));
    } else {
      dispatch(getDealActivity(dealId));
    }
  }, [dispatch, contactId, dealId, subagentId]);

  const handleChange = (e) => {
    e.preventDefault();
    setNoteData({
      ...noteData,
      ContactId: contactId,
      // Deal_Id: dealId,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (success) {
      toast.success(success, toastOptions);
      if (contactId) dispatch(listNoteLogContact(contactId));
      else if (dealId) dispatch(listNoteLogDeal(dealId));
      else if (subagentId) dispatch(listNoteLogSubaegnt(subagentId));
      getActivities();
      setNoteData({ ...noteLogJson });
      setTimeout(() => {
        dispatch({ type: NOTE_LOG_INSERT_RESET });
        closePopup();
      }, 3000);
    }
    if (error) {
      toast.error(error, toastOptions);
    }
  }, [success, error, dealId, contactId, subagentId]);

  useEffect(() => {
    if (editNote) {
      setNoteData(editNote);
    } else {
      setNoteData(noteLogJson);
    }
  }, [editNote]);

  // Note Log Update

  const handleNoteUpdate = (e) => {
    e.preventDefault();
    dispatch(updateNoteLogDetails(editNote?._id, { ...noteData }));
  };

  useEffect(() => {
    if (successUpdt) {
      toast.success(successUpdt, toastOptions);
      if (contactId) dispatch(listNoteLogContact(contactId));
      else if (dealId) dispatch(listNoteLogDeal(dealId));
      else if (subagentId) dispatch(listNoteLogSubaegnt(subagentId));
      getActivities();
      setTimeout(() => {
        setNoteData({ ...noteLogJson });
        dispatch({ type: NOTE_LOG_UPDATE_RESET });
        closePopup();
      }, 3000);
    }

    if (errorUpdt) {
      toast.error(errorUpdt, toastOptions);
    }
  }, [successUpdt]);

  return (
    <>
      <div className="overlay-note-content">
        <div className="note-title">
          <h4 className="text-left text-white pl-3">
            {editNote ? "Update Note" : "Create Note"}
          </h4>
          <div className="closebtn">
            {/* <Link
              id="hide-note-mini"
              className="text-right text-white"
              onClick="minimizeNote()"
            >
              <i className="fas fa-minus"></i>
            </Link> */}
            <Link
              id="hide-note-max"
              className="text-right text-white"
              // onClick={closePopup}
            >
              <i className="fas fa-minus"></i>
            </Link>
            <Link className="text-right text-white" onClick={closePopup}>
              <i className="fas fa-times"></i>
            </Link>
          </div>
        </div>
        <div className="note-body" id="noteBody">
          <div className="log-call">
            <div className="row">
              <div className="col-md-12 mt-3 rmv-toolbar">
                <ReactQuill
                  placeholder={"Enter Note description"}
                  name="NoteDescription"
                  modules={{ toolbar: toolbarOptions }}
                  value={noteData.NoteDescription}
                  onChange={(e) =>
                    setNoteData({ ...noteData, NoteDescription: e })
                  }
                  required
                />
              </div>
            </div>

            <div className="add-comment text-right">
              <textarea
                className="add-comment-field"
                placeholder="Add comments"
                rows="1"
                style={{
                  resize: "none",
                  outline: "none",
                  border: "none",
                  width: "90%",
                }}
                name="Comments"
                value={noteData.Comments}
                onChange={handleChange}
              ></textarea>
            </div>
          </div>
          <hr />

          <div className="log-call-footer">
            {editNote ? (
              <button
                className="btn btn-sm btn-deep-orange waves-effect waves-light"
                onClick={handleNoteUpdate}
                disabled={loadingUpdt}
              >
                {loadingUpdt ? "Loading..." : "Update Note"}
              </button>
            ) : (
              <button
                className="btn btn-sm btn-deep-orange waves-effect waves-light"
                onClick={handleSubmit}
                disabled={loading}
              >
                {loading ? "Loading..." : "Save Note"}
              </button>
            )}

            <div className="custom-control custom-checkbox mt-1 ml-1">
              {/* <input
                type="checkbox"
                className="custom-control-input"
                id="noteFollowUp"
              /> */}
              {/* <label className="custom-control-label" htmlFor="noteFollowUp">
                Create a task to follow up
              </label> */}
            </div>
            {/* <div className="form-group mt-3 noteFollow">
              <select className="form-control">
                <option value="">in 3 business days (Friday)</option>
                <option value="">--</option>
              </select>
            </div> */}
          </div>
          <div>
            {/* <p>
              <span className="clrgrn">{success || successUpdt}</span>
              <span className="clrrd">{error || errorUpdt}</span>
            </p> */}
          </div>
        </div>
      </div>
    </>
  );
}
