import {
  OSHC_TYPES_DELETE_FAIL,
  OSHC_TYPES_DELETE_REQUEST,
  OSHC_TYPES_DELETE_RESET,
  OSHC_TYPES_DELETE_SUCCESS,
  OSHC_TYPES_INSERT_FAIL,
  OSHC_TYPES_INSERT_REQUEST,
  OSHC_TYPES_INSERT_RESET,
  OSHC_TYPES_INSERT_SUCCESS,
  OSHC_TYPES_LIST_FAIL,
  OSHC_TYPES_LIST_REQUEST,
  OSHC_TYPES_LIST_SUCCESS,
  OSHC_TYPES_UPDATE_FAIL,
  OSHC_TYPES_UPDATE_REQUEST,
  OSHC_TYPES_UPDATE_RESET,
  OSHC_TYPES_UPDATE_SUCCESS,
} from "../Constants/oshcTypesConstants";

export const oshcTypesListReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case OSHC_TYPES_LIST_REQUEST:
      return { loading: true };
    case OSHC_TYPES_LIST_SUCCESS:
      return { loading: false, oshcTypeRecs: payload };
    case OSHC_TYPES_LIST_FAIL:
      return { loading: false, error: payload };
    default:
      return state;
  }
};

export const oshcTypeSaveReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case OSHC_TYPES_INSERT_REQUEST:
      return { loading: true };
    case OSHC_TYPES_INSERT_SUCCESS:
      return { loading: false, success: payload };
    case OSHC_TYPES_INSERT_FAIL:
      return { loading: false, error: payload };
    case OSHC_TYPES_INSERT_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const oshcTypeUpdateReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case OSHC_TYPES_UPDATE_REQUEST:
      return { loading: true };
    case OSHC_TYPES_UPDATE_SUCCESS:
      return { loading: false, success: payload };
    case OSHC_TYPES_UPDATE_FAIL:
      return { loading: false, error: payload };
    case OSHC_TYPES_UPDATE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const oshcTypeDeleteReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case OSHC_TYPES_DELETE_REQUEST:
      return { loading: true };
    case OSHC_TYPES_DELETE_SUCCESS:
      return { loading: false, success: payload };
    case OSHC_TYPES_DELETE_FAIL:
      return { loading: false, error: payload };
    case OSHC_TYPES_DELETE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};
