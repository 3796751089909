import {
  STUDY_MATERIAL_DELETE_FAIL,
  STUDY_MATERIAL_DELETE_REQUEST,
  STUDY_MATERIAL_DELETE_RESET,
  STUDY_MATERIAL_DELETE_SUCCESS,
  STUDY_MATERIAL_GET_FAIL,
  STUDY_MATERIAL_GET_REQUEST,
  STUDY_MATERIAL_GET_SUCCESS,
  STUDY_MATERIAL_INSERT_FAIL,
  STUDY_MATERIAL_INSERT_REQUEST,
  STUDY_MATERIAL_INSERT_RESET,
  STUDY_MATERIAL_INSERT_SUCCESS,
  STUDY_MATERIAL_LIST_FAIL,
  STUDY_MATERIAL_LIST_REQUEST,
  STUDY_MATERIAL_LIST_SUCCESS,
  STUDY_MATERIAL_UPDATE_FAIL,
  STUDY_MATERIAL_UPDATE_REQUEST,
  STUDY_MATERIAL_UPDATE_RESET,
  STUDY_MATERIAL_UPDATE_SUCCESS,
} from "../Constants/studyMaterialConstant";

export const studyMaterialListReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case STUDY_MATERIAL_LIST_REQUEST:
      return { loading: true };
    case STUDY_MATERIAL_LIST_SUCCESS:
      return { loading: false, studyMaterialRecs: payload };
    case STUDY_MATERIAL_LIST_FAIL:
      return { loading: false, error: payload };
    default:
      return state;
  }
};

export const studyMaterialGetReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case STUDY_MATERIAL_GET_REQUEST:
      return { loading: true };
    case STUDY_MATERIAL_GET_SUCCESS:
      return { loading: false, studyMaterialRec: payload };
    case STUDY_MATERIAL_GET_FAIL:
      return { loading: false, error: payload };
    default:
      return state;
  }
};

export const studyMaterialSaveReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case STUDY_MATERIAL_INSERT_REQUEST:
      return { loading: true };
    case STUDY_MATERIAL_INSERT_SUCCESS:
      return { loading: false, success: payload };
    case STUDY_MATERIAL_INSERT_FAIL:
      return { loading: false, error: payload };
    case STUDY_MATERIAL_INSERT_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const studyMaterialUpdateReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case STUDY_MATERIAL_UPDATE_REQUEST:
      return { loading: true };
    case STUDY_MATERIAL_UPDATE_SUCCESS:
      return { loading: false, success: payload };
    case STUDY_MATERIAL_UPDATE_FAIL:
      return { loading: false, error: payload };
    case STUDY_MATERIAL_UPDATE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const studyMaterialDeleteReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case STUDY_MATERIAL_DELETE_REQUEST:
      return { loading: true };
    case STUDY_MATERIAL_DELETE_SUCCESS:
      return { loading: false, success: payload };
    case STUDY_MATERIAL_DELETE_FAIL:
      return { loading: false, error: payload };
    case STUDY_MATERIAL_DELETE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};
