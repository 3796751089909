export const LIFECYCLE_LIST_REQUEST = 'LIFECYCLE_LIST_REQUEST'
export const LIFECYCLE_LIST_SUCCESS = 'LIFECYCLE_LIST_SUCCESS'
export const LIFECYCLE_LIST_FAIL = 'LIFECYCLE_LIST_FAIL'

export const LIFECYCLE_GET_REQUEST = 'LIFECYCLE_GET_REQUEST'
export const LIFECYCLE_GET_SUCCESS = 'LIFECYCLE_GET_SUCCESS'
export const LIFECYCLE_GET_FAIL = 'LIFECYCLE_GET_FAIL'

export const LIFECYCLE_INSERT_REQUEST = 'LIFECYCLE_INSERT_REQUEST'
export const LIFECYCLE_INSERT_SUCCESS = 'LIFECYCLE_INSERT_SUCCESS'
export const LIFECYCLE_INSERT_FAIL = 'LIFECYCLE_INSERT_FAIL'

export const LIFECYCLE_UPDATE_REQUEST = 'LIFECYCLE_UPDATE_REQUEST'
export const LIFECYCLE_UPDATE_SUCCESS = 'LIFECYCLE_UPDATE_SUCCESS'
export const LIFECYCLE_UPDATE_FAIL = 'LIFECYCLE_UPDATE_FAIL'

export const LIFECYCLE_DELETE_REQUEST = 'LIFECYCLE_DELETE_REQUEST'
export const LIFECYCLE_DELETE_SUCCESS = 'LIFECYCLE_DELETE_SUCCESS'
export const LIFECYCLE_DELETE_FAIL = 'LIFECYCLE_DELETE_FAIL'
