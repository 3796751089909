import React from "react";
import { Helmet } from "react-helmet";

export default function Meta({ MetaDes }) {
  return (
    <Helmet>
      <title>{MetaDes.title}</title>
      <meta name="description" content="Nested component" />
    </Helmet>
  );
}
