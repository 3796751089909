import {
  SEND_EMAIL_BULK_FAILED,
  SEND_EMAIL_BULK_REQ,
  SEND_EMAIL_BULK_RESET,
  SEND_EMAIL_BULK_SUCCESS,
} from "../Constants/SendEmailBulkConstants";

export const sendEMailBulkReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case SEND_EMAIL_BULK_REQ: {
      return { loading: true };
    }

    case SEND_EMAIL_BULK_SUCCESS: {
      return { loading: false, success: payload };
    }

    case SEND_EMAIL_BULK_FAILED: {
      return { loading: false, error: payload };
    }

    case SEND_EMAIL_BULK_RESET: {
      return { loading: false, success: "", error: "" };
    }
    default:
      return state;
  }
};
