import {
  UNIVERISTY_UPLOAD_IMAGE_FAILED,
  UNIVERISTY_UPLOAD_IMAGE_REQ,
  UNIVERISTY_UPLOAD_IMAGE_RESET,
  UNIVERISTY_UPLOAD_IMAGE_SUCCESS,
  UNIVERSITY_DELETE_FAIL,
  UNIVERSITY_DELETE_REQUEST,
  UNIVERSITY_DELETE_RESET,
  UNIVERSITY_DELETE_SUCCESS,
  UNIVERSITY_DETAILS_FAIL,
  UNIVERSITY_DETAILS_REQ,
  UNIVERSITY_DETAILS_SUCCESS,
  UNIVERSITY_INSERT_FAIL,
  UNIVERSITY_INSERT_REQUEST,
  UNIVERSITY_INSERT_RESET,
  UNIVERSITY_INSERT_SUCCESS,
  UNIVERSITY_LIST_FAIL,
  UNIVERSITY_LIST_REQUEST,
  UNIVERSITY_LIST_SUCCESS,
  UNIVERSITY_UPDATE_FAIL,
  UNIVERSITY_UPDATE_REQUEST,
  UNIVERSITY_UPDATE_RESET,
  UNIVERSITY_UPDATE_SUCCESS,
} from "../Constants/universityConstants";

export const universityListReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case UNIVERSITY_LIST_REQUEST:
      return { loading: true };
    case UNIVERSITY_LIST_SUCCESS:
      return { loading: false, universityRecs: payload };
    case UNIVERSITY_LIST_FAIL:
      return { loading: false, error: payload };
    default:
      return state;
  }
};

export const universitySaveReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case UNIVERSITY_INSERT_REQUEST:
      return { loading: true };
    case UNIVERSITY_INSERT_SUCCESS:
      return { loading: false, success: payload };
    case UNIVERSITY_INSERT_FAIL:
      return { loading: false, error: payload };
    case UNIVERSITY_INSERT_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const universityUpdateReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case UNIVERSITY_UPDATE_REQUEST:
      return { loading: true };
    case UNIVERSITY_UPDATE_SUCCESS:
      return { loading: false, success: payload };
    case UNIVERSITY_UPDATE_FAIL:
      return { loading: false, error: payload };
    case UNIVERSITY_UPDATE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const universityDeleteReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case UNIVERSITY_DELETE_REQUEST:
      return { loading: true };
    case UNIVERSITY_DELETE_SUCCESS:
      return { loading: false, success: payload };
    case UNIVERSITY_DELETE_FAIL:
      return { loading: false, error: payload };
    case UNIVERSITY_DELETE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const universityUploadFileReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case UNIVERISTY_UPLOAD_IMAGE_REQ: {
      return { loading: true };
    }

    case UNIVERISTY_UPLOAD_IMAGE_SUCCESS: {
      return { loading: false, success: payload };
    }

    case UNIVERISTY_UPLOAD_IMAGE_FAILED: {
      return { loading: false, error: payload };
    }

    case UNIVERISTY_UPLOAD_IMAGE_RESET: {
      return { loading: false, success: "" };
    }

    default:
      return state;
  }
};

export const universityDetReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case UNIVERSITY_DETAILS_REQ: {
      return { loading: true };
    }

    case UNIVERSITY_DETAILS_SUCCESS: {
      return { loading: false, UniversityRec: payload };
    }

    case UNIVERSITY_DETAILS_FAIL: {
      return { loading: false, error: payload };
    }

    default:
      return state;
  }
};
