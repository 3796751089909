import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  TASK_LOG_INSERT_RESET,
  TASK_LOG_UPDATE_RESET,
} from "../../../Constants/taskLogConstants";
import {
  listTaskLogContact,
  listTaskLogDeal,
  listTaskLogSubAgent,
  saveTaskLogDetails,
  updateTaskLogDetails,
} from "../../../Actions/taskLogActions";
import { taskLogJson } from "../../../Models/TaskLogModels";
import UserDropdown from "../UserDropdown";
import ReactQuill from "react-quill/lib";
import {
  getContactActivity,
  getDealActivity,
  getSubAgentActivity,
} from "../../../Actions/activityActions";
import {
  toastOptions,
  toolbarOptions,
} from "../../../Models/ToastEditiorOption";

import { toast } from "react-toastify";
import DatePicker from "react-datepicker";

export default function TaskInsert({
  editTask,
  contactId,
  subagentId,
  dealId,
  closePopup,
}) {
  const dispatch = useDispatch();
  const [taskData, settaskData] = useState(taskLogJson);
  const [TaskDescription, setTaskDescription] = useState("");
  const [userInfo, setUserInfo] = useState("");
  const { loading, error, success } = useSelector((s) => s.taskLogSave);

  const {
    loading: loadingUpdt,
    success: successUpdt,
    error: errorUpdt,
  } = useSelector((s) => s.taskLogUpdate);

  const getActivities = useCallback(() => {
    if (subagentId) {
      dispatch(getSubAgentActivity(subagentId));
    } else if (contactId) {
      dispatch(getContactActivity(contactId));
    } else {
      dispatch(getDealActivity(dealId));
    }
  }, [dispatch, contactId, dealId, subagentId]);

  const handleSubmit = (e) => {
    e.preventDefault();
    for (let key in taskData) {
      if (taskData[key] === "" || taskData[key] === undefined) {
        taskData[key] = null;
      }
    }
    dispatch(
      saveTaskLogDetails({
        ...taskData,
        TaskDescription,
        ReminderStatus: true,
      })
    );
  };
  
  const handleChange = (e) => {
    e.preventDefault();
    settaskData({
      ...taskData,
      ContactId: contactId,
      // Deal_Id: dealId,
      // SubAgent_Id: subagentId,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (!userInfo) {
      const authToken = JSON.parse(localStorage.getItem("_Tsset"));
      setUserInfo(authToken ? authToken?._id : "");
    }
  }, [userInfo]);

  useEffect(() => {
    if (success) {
      toast.success(success, toastOptions);
      if (contactId) dispatch(listTaskLogContact(contactId));
      // else if (dealId) dispatch(listTaskLogDeal(dealId));
      // else if (subagentId) dispatch(listTaskLogSubAgent(subagentId));
      getActivities();
      settaskData({ ...taskLogJson });
      setTaskDescription("");
      setTimeout(() => {
        dispatch({ type: TASK_LOG_INSERT_RESET });
        closePopup();
      }, 3000);
    }
    if (error) {
      toast.error(error, toastOptions);
    }
  }, [success, error, dispatch,getActivities, contactId]);

  useEffect(() => {
    if (editTask) {
      setTaskDescription(editTask?.TaskDescription);
      settaskData({ ...editTask });
    } else {
      settaskData(taskLogJson);
      setTaskDescription("");
    }
  }, [editTask]);

  // Task Log Update
  const handleTaskUpdate = (e) => {
    e.preventDefault();
    dispatch(
      updateTaskLogDetails(editTask?._id, {
        ...taskData,
        TaskDescription,
        ReminderStatus: true,
      })
    );
  };

  useEffect(() => {
    if (successUpdt) {
      toast.success(successUpdt, toastOptions);
      if (contactId) dispatch(listTaskLogContact(contactId));
      else if (dealId) dispatch(listTaskLogDeal(dealId));
      else if (subagentId) dispatch(listTaskLogSubAgent(subagentId));
      getActivities();
      setTimeout(() => {
        settaskData({ ...taskLogJson });
        setTaskDescription("");
        dispatch({ type: TASK_LOG_UPDATE_RESET });
        closePopup();
      }, 3000);
    }
    if (errorUpdt) {
      toast.error(errorUpdt, toastOptions);
    }
  }, [successUpdt, errorUpdt]);

  useEffect(() => {
    settaskData({
      ...taskData,
      AssignedTo: taskData?.AssignedTo ? taskData?.AssignedTo : userInfo,
    });
  }, [userInfo]);

  return (
    <>
      <div className="overlay-note-content">
        <div className="note-title">
          <h4 className="text-left text-white pl-3">Task</h4>
          <div className="closebtn">
            <Link
              id="hide-note-max"
              className="text-right text-white"
              // onClick={closePopup}
            >
              <i className="fas fa-minus"></i>
            </Link>
            <Link
              className="text-right text-white"
              onClick={(e) => {
                settaskData({ ...taskLogJson });
                closePopup(e);
              }}
            >
              <i className="fas fa-times"></i>
            </Link>
          </div>
        </div>
        <div className="note-body" id="noteBody">
          <div className="log-call">
            <div className="row">
              <div className="col-md-5">
                <span>Task Title</span>
                <input
                  type="text"
                  name="TaskTitle"
                  value={taskData.TaskTitle}
                  onChange={handleChange}
                  className="w-100 ng-pristine ng-valid ng-empty ng-touched"
                  placeholder="Task title..."
                />
              </div>

              {window.innerWidth <= 480 ? (
                <>
                  {" "}
                  <br />
                </>
              ) : (
                ""
              )}

              <div className="col-md-3">
                <span>Due Date</span>
                <div className="myDatePickerContainer">
                  <DatePicker
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    yearDropdownItemNumber={100}
                    scrollableYearDropdown
                    dropdownMode="scroll"
                    dateFormat="dd-MM-yyyy"
                    className="myDatePicker"
                    selected={
                      taskData?.DueDate ? new Date(taskData.DueDate) : ""
                    }
                    onChange={(date) =>
                      settaskData({
                        ...taskData,
                        DueDate: date,
                      })
                    }
                    placeholderText="dd/mm/yy"
                  />
                </div>
              </div>

              <div className="col-md-3">
                <span>Time</span>
                <select
                  name="Due_Time"
                  value={taskData.Due_Time}
                  onChange={handleChange}
                  className="w-100 ng-pristine ng-valid  ng-touched"
                >
                  <option value="">select</option>
                  <option
                    ng-repeat="tlll in TimeList"
                    value="00:00"
                    className="ng-binding ng-scope"
                  >
                    12:00 AM
                  </option>
                  <option
                    ng-repeat="tlll in TimeList"
                    value="00:15"
                    className="ng-binding ng-scope"
                  >
                    12:15 AM
                  </option>
                  <option
                    ng-repeat="tlll in TimeList"
                    value="00:30"
                    className="ng-binding ng-scope"
                  >
                    12:30 AM
                  </option>
                  <option
                    ng-repeat="tlll in TimeList"
                    value="00:45"
                    className="ng-binding ng-scope"
                  >
                    12:45 AM
                  </option>
                  <option
                    ng-repeat="tlll in TimeList"
                    value="01:00"
                    className="ng-binding ng-scope"
                  >
                    01:00 AM
                  </option>
                  <option
                    ng-repeat="tlll in TimeList"
                    value="01:15"
                    className="ng-binding ng-scope"
                  >
                    01:15 AM
                  </option>
                  <option
                    ng-repeat="tlll in TimeList"
                    value="01:30"
                    className="ng-binding ng-scope"
                  >
                    01:30 AM
                  </option>
                  <option
                    ng-repeat="tlll in TimeList"
                    value="01:45"
                    className="ng-binding ng-scope"
                  >
                    01:45 AM
                  </option>
                  <option
                    ng-repeat="tlll in TimeList"
                    value="02:00"
                    className="ng-binding ng-scope"
                  >
                    02:00 AM
                  </option>
                  <option
                    ng-repeat="tlll in TimeList"
                    value="02:15"
                    className="ng-binding ng-scope"
                  >
                    02:15 AM
                  </option>
                  <option
                    ng-repeat="tlll in TimeList"
                    value="02:30"
                    className="ng-binding ng-scope"
                  >
                    02:30 AM
                  </option>
                  <option
                    ng-repeat="tlll in TimeList"
                    value="02:45"
                    className="ng-binding ng-scope"
                  >
                    02:45 AM
                  </option>
                  <option
                    ng-repeat="tlll in TimeList"
                    value="03:00"
                    className="ng-binding ng-scope"
                  >
                    03:00 AM
                  </option>
                  <option
                    ng-repeat="tlll in TimeList"
                    value="03:15"
                    className="ng-binding ng-scope"
                  >
                    03:15 AM
                  </option>
                  <option
                    ng-repeat="tlll in TimeList"
                    value="03:30"
                    className="ng-binding ng-scope"
                  >
                    03:30 AM
                  </option>
                  <option
                    ng-repeat="tlll in TimeList"
                    value="03:45"
                    className="ng-binding ng-scope"
                  >
                    03:45 AM
                  </option>
                  <option
                    ng-repeat="tlll in TimeList"
                    value="04:00"
                    className="ng-binding ng-scope"
                  >
                    04:00 AM
                  </option>
                  <option
                    ng-repeat="tlll in TimeList"
                    value="04:15"
                    className="ng-binding ng-scope"
                  >
                    04:15 AM
                  </option>
                  <option
                    ng-repeat="tlll in TimeList"
                    value="04:30"
                    className="ng-binding ng-scope"
                  >
                    04:30 AM
                  </option>
                  <option
                    ng-repeat="tlll in TimeList"
                    value="04:45"
                    className="ng-binding ng-scope"
                  >
                    04:45 AM
                  </option>
                  <option
                    ng-repeat="tlll in TimeList"
                    value="05:00"
                    className="ng-binding ng-scope"
                  >
                    05:00 AM
                  </option>
                  <option
                    ng-repeat="tlll in TimeList"
                    value="05:15"
                    className="ng-binding ng-scope"
                  >
                    05:15 AM
                  </option>
                  <option
                    ng-repeat="tlll in TimeList"
                    value="05:30"
                    className="ng-binding ng-scope"
                  >
                    05:30 AM
                  </option>
                  <option
                    ng-repeat="tlll in TimeList"
                    value="05:45"
                    className="ng-binding ng-scope"
                  >
                    05:45 AM
                  </option>
                  <option
                    ng-repeat="tlll in TimeList"
                    value="06:00"
                    className="ng-binding ng-scope"
                  >
                    06:00 AM
                  </option>
                  <option
                    ng-repeat="tlll in TimeList"
                    value="06:15"
                    className="ng-binding ng-scope"
                  >
                    06:15 AM
                  </option>
                  <option
                    ng-repeat="tlll in TimeList"
                    value="06:30"
                    className="ng-binding ng-scope"
                  >
                    06:30 AM
                  </option>
                  <option
                    ng-repeat="tlll in TimeList"
                    value="06:45"
                    className="ng-binding ng-scope"
                  >
                    06:45 AM
                  </option>
                  <option
                    ng-repeat="tlll in TimeList"
                    value="07:00"
                    className="ng-binding ng-scope"
                  >
                    07:00 AM
                  </option>
                  <option
                    ng-repeat="tlll in TimeList"
                    value="07:15"
                    className="ng-binding ng-scope"
                  >
                    07:15 AM
                  </option>
                  <option
                    ng-repeat="tlll in TimeList"
                    value="07:30"
                    className="ng-binding ng-scope"
                  >
                    07:30 AM
                  </option>
                  <option
                    ng-repeat="tlll in TimeList"
                    value="07:45"
                    className="ng-binding ng-scope"
                  >
                    07:45 AM
                  </option>
                  <option
                    ng-repeat="tlll in TimeList"
                    value="08:00"
                    className="ng-binding ng-scope"
                  >
                    08:00 AM
                  </option>
                  <option
                    ng-repeat="tlll in TimeList"
                    value="08:15"
                    className="ng-binding ng-scope"
                  >
                    08:15 AM
                  </option>
                  <option
                    ng-repeat="tlll in TimeList"
                    value="08:30"
                    className="ng-binding ng-scope"
                  >
                    08:30 AM
                  </option>
                  <option
                    ng-repeat="tlll in TimeList"
                    value="08:45"
                    className="ng-binding ng-scope"
                  >
                    08:45 AM
                  </option>
                  <option
                    ng-repeat="tlll in TimeList"
                    value="09:00"
                    className="ng-binding ng-scope"
                  >
                    09:00 AM
                  </option>
                  <option
                    ng-repeat="tlll in TimeList"
                    value="09:15"
                    className="ng-binding ng-scope"
                  >
                    09:15 AM
                  </option>
                  <option
                    ng-repeat="tlll in TimeList"
                    value="09:30"
                    className="ng-binding ng-scope"
                  >
                    09:30 AM
                  </option>
                  <option
                    ng-repeat="tlll in TimeList"
                    value="09:45"
                    className="ng-binding ng-scope"
                  >
                    09:45 AM
                  </option>
                  <option
                    ng-repeat="tlll in TimeList"
                    value="10:00"
                    className="ng-binding ng-scope"
                  >
                    10:00 AM
                  </option>
                  <option
                    ng-repeat="tlll in TimeList"
                    value="10:15"
                    className="ng-binding ng-scope"
                  >
                    10:15 AM
                  </option>
                  <option
                    ng-repeat="tlll in TimeList"
                    value="10:30"
                    className="ng-binding ng-scope"
                  >
                    10:30 AM
                  </option>
                  <option
                    ng-repeat="tlll in TimeList"
                    value="10:45"
                    className="ng-binding ng-scope"
                  >
                    10:45 AM
                  </option>
                  <option
                    ng-repeat="tlll in TimeList"
                    value="11:00"
                    className="ng-binding ng-scope"
                  >
                    11:00 AM
                  </option>
                  <option
                    ng-repeat="tlll in TimeList"
                    value="11:15"
                    className="ng-binding ng-scope"
                  >
                    11:15 AM
                  </option>
                  <option
                    ng-repeat="tlll in TimeList"
                    value="11:30"
                    className="ng-binding ng-scope"
                  >
                    11:30 AM
                  </option>
                  <option
                    ng-repeat="tlll in TimeList"
                    value="11:45"
                    className="ng-binding ng-scope"
                  >
                    11:45 AM
                  </option>
                  <option
                    ng-repeat="tlll in TimeList"
                    value="12:00"
                    className="ng-binding ng-scope"
                  >
                    12:00 PM
                  </option>
                  <option
                    ng-repeat="tlll in TimeList"
                    value="12:15"
                    className="ng-binding ng-scope"
                  >
                    12:15 PM
                  </option>
                  <option
                    ng-repeat="tlll in TimeList"
                    value="12:30"
                    className="ng-binding ng-scope"
                  >
                    12:30 PM
                  </option>
                  <option
                    ng-repeat="tlll in TimeList"
                    value="12:45"
                    className="ng-binding ng-scope"
                  >
                    12:45 PM
                  </option>
                  <option
                    ng-repeat="tlll in TimeList"
                    value="13:00"
                    className="ng-binding ng-scope"
                  >
                    01:00 PM
                  </option>
                  <option
                    ng-repeat="tlll in TimeList"
                    value="13:15"
                    className="ng-binding ng-scope"
                  >
                    01:15 PM
                  </option>
                  <option
                    ng-repeat="tlll in TimeList"
                    value="13:30"
                    className="ng-binding ng-scope"
                  >
                    01:30 PM
                  </option>
                  <option
                    ng-repeat="tlll in TimeList"
                    value="13:45"
                    className="ng-binding ng-scope"
                  >
                    01:45 PM
                  </option>
                  <option
                    ng-repeat="tlll in TimeList"
                    value="14:00"
                    className="ng-binding ng-scope"
                  >
                    02:00 PM
                  </option>
                  <option
                    ng-repeat="tlll in TimeList"
                    value="14:15"
                    className="ng-binding ng-scope"
                  >
                    02:15 PM
                  </option>
                  <option
                    ng-repeat="tlll in TimeList"
                    value="14:30"
                    className="ng-binding ng-scope"
                  >
                    02:30 PM
                  </option>
                  <option
                    ng-repeat="tlll in TimeList"
                    value="14:45"
                    className="ng-binding ng-scope"
                  >
                    02:45 PM
                  </option>
                  <option
                    ng-repeat="tlll in TimeList"
                    value="15:00"
                    className="ng-binding ng-scope"
                  >
                    03:00 PM
                  </option>
                  <option
                    ng-repeat="tlll in TimeList"
                    value="15:15"
                    className="ng-binding ng-scope"
                  >
                    03:15 PM
                  </option>
                  <option
                    ng-repeat="tlll in TimeList"
                    value="15:30"
                    className="ng-binding ng-scope"
                  >
                    03:30 PM
                  </option>
                  <option
                    ng-repeat="tlll in TimeList"
                    value="15:45"
                    className="ng-binding ng-scope"
                  >
                    03:45 PM
                  </option>
                  <option
                    ng-repeat="tlll in TimeList"
                    value="16:00"
                    className="ng-binding ng-scope"
                  >
                    04:00 PM
                  </option>
                  <option
                    ng-repeat="tlll in TimeList"
                    value="16:15"
                    className="ng-binding ng-scope"
                  >
                    04:15 PM
                  </option>
                  <option
                    ng-repeat="tlll in TimeList"
                    value="16:30"
                    className="ng-binding ng-scope"
                  >
                    04:30 PM
                  </option>
                  <option
                    ng-repeat="tlll in TimeList"
                    value="16:45"
                    className="ng-binding ng-scope"
                  >
                    04:45 PM
                  </option>
                  <option
                    ng-repeat="tlll in TimeList"
                    value="17:00"
                    className="ng-binding ng-scope"
                  >
                    05:00 PM
                  </option>
                  <option
                    ng-repeat="tlll in TimeList"
                    value="17:15"
                    className="ng-binding ng-scope"
                  >
                    05:15 PM
                  </option>
                  <option
                    ng-repeat="tlll in TimeList"
                    value="17:30"
                    className="ng-binding ng-scope"
                  >
                    05:30 PM
                  </option>
                  <option
                    ng-repeat="tlll in TimeList"
                    value="17:45"
                    className="ng-binding ng-scope"
                  >
                    05:45 PM
                  </option>
                  <option
                    ng-repeat="tlll in TimeList"
                    value="18:00"
                    className="ng-binding ng-scope"
                  >
                    06:00 PM
                  </option>
                  <option
                    ng-repeat="tlll in TimeList"
                    value="18:15"
                    className="ng-binding ng-scope"
                  >
                    06:15 PM
                  </option>

                  <option
                    ng-repeat="tlll in TimeList"
                    value="18:35"
                    className="ng-binding ng-scope"
                  >
                    06:30 PM
                  </option>

                  <option
                    ng-repeat="tlll in TimeList"
                    value="18:45"
                    className="ng-binding ng-scope"
                  >
                    06:45 PM
                  </option>

                  <option
                    ng-repeat="tlll in TimeList"
                    value="19:00"
                    className="ng-binding ng-scope"
                  >
                    7:00 PM
                  </option>
                  <option
                    ng-repeat="tlll in TimeList"
                    value="19:15"
                    className="ng-binding ng-scope"
                  >
                    7:15 PM
                  </option>
                  <option
                    ng-repeat="tlll in TimeList"
                    value="19:30"
                    className="ng-binding ng-scope"
                  >
                    7:30 PM
                  </option>
                  <option
                    ng-repeat="tlll in TimeList"
                    value="19:45"
                    className="ng-binding ng-scope"
                  >
                    7:45 PM
                  </option>
                  <option
                    ng-repeat="tlll in TimeList"
                    value="20:00"
                    className="ng-binding ng-scope"
                  >
                    8:00 PM
                  </option>
                </select>
              </div>
              {window.innerWidth <= 480 ? (
                <>
                  {" "}
                  <br />
                </>
              ) : (
                ""
              )}

              <div className="col-md-12 mt-3 rmv-toolbar">
                <ReactQuill
                  placeholder={"Enter task description"}
                  name="TaskDescription"
                  modules={{ toolbar: toolbarOptions }}
                  value={TaskDescription}
                  onChange={(e) => setTaskDescription(e)}
                  required
                />
              </div>
              {window.innerWidth <= 480 ? (
                <>
                  {" "}
                  <br />
                </>
              ) : (
                ""
              )}
            </div>
            <div className="row">
              <div className="col-md-3">
                <span>Type</span>
                <div className="form-group">
                  <select
                    className="form-control w-100 ng-pristine ng-valid ng-not-empty ng-touched"
                    name="Task_Type"
                    value={taskData.Task_Type}
                    onChange={handleChange}
                  >
                    <option value="">select</option>
                    <option value="Call">Call</option>
                    <option value="Email">Email</option>
                    <option value="To Do">To-do </option>
                  </select>
                </div>
              </div>

              <div className="col-md-5">
                <span>Assigned to</span>
                <div className="form-group">
                  <select
                    className="form-control w-100 ng-pristine ng-untouched ng-valid ng-not-empty"
                    name="AssignedTo"
                    value={taskData?.AssignedTo}
                    onChange={handleChange}
                  >
                    <option value="">select</option>
                    <UserDropdown />
                  </select>
                </div>
              </div>
              <div className="col-md-4">
                <span>Priority</span>
                <div className="form-group">
                  <select
                    className="form-control w-100 ng-pristine ng-untouched ng-valid ng-not-empty"
                    name="Priority"
                    value={taskData?.Priority}
                    onChange={handleChange}
                  >
                    <option value="">select</option>
                    <option value={1}> High</option>
                    <option value={0}>None</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-5">
                <span>Reminder Date</span>

                <div className="myDatePickerContainer">
                  <DatePicker
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    yearDropdownItemNumber={100}
                    scrollableYearDropdown
                    dropdownMode="scroll"
                    dateFormat="dd-MM-yyyy"
                    className="myDatePicker"
                    selected={
                      taskData?.ReminderDate
                        ? new Date(taskData.ReminderDate)
                        : ""
                    }
                    onChange={(date) =>
                      settaskData({
                        ...taskData,
                        ReminderDate: date,
                      })
                    }
                    placeholderText="dd/mm/yy"
                  />
                </div>
              </div>
            </div>

            <div>
              {/* <i className="fas fa-comments" style={{ color: "#0c3eff" }}></i>
              <Link id="comments-add" style={{ color: "#555" }}>
                Add comments
              </Link> */}

              <textarea
                className="add-comment-field"
                placeholder="Add comments"
                rows="1"
                style={{
                  resize: "none",
                  outline: "none",
                  border: "none",
                  width: "90%",
                }}
                name="Comments"
                value={taskData.Comments}
                onChange={handleChange}
              ></textarea>
            </div>
          </div>
          <hr />

          <div className="log-call-footer">
            {editTask ? (
              <button
                className="btn btn-sm btn-deep-orange waves-effect waves-light"
                onClick={handleTaskUpdate}
                disabled={loadingUpdt}
              >
                {loadingUpdt ? "Loading..." : "Update Task"}
              </button>
            ) : (
              <button
                className="btn btn-sm btn-deep-orange waves-effect waves-light"
                onClick={handleSubmit}
                disabled={loading}
              >
                {loading ? "Loading..." : "Save Task"}
              </button>
            )}

            <div className="custom-control custom-checkbox mt-1 ml-1">
              {/* <span className="clrgrn">{success || successUpdt}</span>
              <span className="clrrd">{error || errorUpdt}</span> */}
              {/* <input
                type="checkbox"
                className="custom-control-input"
                id="noteFollowUp"
              /> */}
              {/* <label className="custom-control-label" htmlFor="noteFollowUp">
                Create a task to follow up
              </label> */}
            </div>
            {/* <div className="form-group mt-3 noteFollow">
              <select className="form-control">
                <option value="">in 3 business days (Friday)</option>
                <option value="">--</option>
              </select>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}
