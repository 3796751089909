export const OFFER_ACCEPTED_LIST_REQUEST = 'OFFER_ACCEPTED_LIST_REQUEST'
export const OFFER_ACCEPTED_LIST_SUCCESS = 'OFFER_ACCEPTED_LIST_SUCCESS'
export const OFFER_ACCEPTED_LIST_FAIL = 'OFFER_ACCEPTED_LIST_FAIL'

export const OFFER_ACCEPTED_INSERT_REQUEST = 'OFFER_ACCEPTED_INSERT_REQUEST'
export const OFFER_ACCEPTED_INSERT_SUCCESS = 'OFFER_ACCEPTED_INSERT_SUCCESS'
export const OFFER_ACCEPTED_INSERT_FAIL = 'OFFER_ACCEPTED_INSERT_FAIL'
export const OFFER_ACCEPTED_INSERT_RESET = 'OFFER_ACCEPTED_INSERT_RESET'

export const OFFER_ACCEPTED_UPDATE_REQUEST = 'OFFER_ACCEPTED_UPDATE_REQUEST'
export const OFFER_ACCEPTED_UPDATE_SUCCESS = 'OFFER_ACCEPTED_UPDATE_SUCCESS'
export const OFFER_ACCEPTED_UPDATE_FAIL = 'OFFER_ACCEPTED_UPDATE_FAIL'
export const OFFER_ACCEPTED_UPDATE_RESET = 'OFFER_ACCEPTED_UPDATE_RESET'

export const OFFER_ACCEPTED_DELETE_REQUEST = 'OFFER_ACCEPTED_DELETE_REQUEST'
export const OFFER_ACCEPTED_DELETE_SUCCESS = 'OFFER_ACCEPTED_DELETE_SUCCESS'
export const OFFER_ACCEPTED_DELETE_FAIL = 'OFFER_ACCEPTED_DELETE_FAIL'
export const OFFER_ACCEPTED_DELETE_RESET = 'OFFER_ACCEPTED_DELETE_RESET'
