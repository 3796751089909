import {
  CREATE_PACAKGE_FAILED,
  CREATE_PACAKGE_REQ,
  CREATE_PACAKGE_SUCCESS,
  PACKAGE_DETAILS_FAILED,
  PACKAGE_DETAILS_REQ,
  PACKAGE_DETAILS_SUCCESS,
  PACKAGE_LIST_FAILED,
  PACKAGE_LIST_REQ,
  PACKAGE_LIST_SUCCESS,
  PACKAGE_TYPE_LIST_FAILED,
  PACKAGE_TYPE_LIST_REQ,
  PACKAGE_TYPE_LIST_SUCCESS,
  REMOVE_PACAKGE_FAILED,
  REMOVE_PACAKGE_REQ,
  REMOVE_PACAKGE_SUCCESS,
  UPDATE_PACAKGE_FAILED,
  UPDATE_PACAKGE_REQ,
  UPDATE_PACAKGE_SUCCESS,
} from "../Constants/packageConstant";
import api from "../core/api";

export const Package_Create = (PackageDet, CP) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_PACAKGE_REQ });
    const { data } = await api.post(
      CP ? "/packages/other" : "/packages",
      PackageDet
    );
    dispatch({ type: CREATE_PACAKGE_SUCCESS, payload: data?.Result });
  } catch (error) {
    dispatch({
      type: CREATE_PACAKGE_FAILED,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const Package_List = (PackageFilter) => async (dispatch) => {
  try {
    dispatch({ type: PACKAGE_LIST_REQ });
    const { data } = await api.get(
      `/packages?page_no=${PackageFilter?.page_no}&page_size=${PackageFilter?.page_size}`
    );

    const TotalRec = await api.get("/packages/total");

    dispatch({
      type: PACKAGE_LIST_SUCCESS,
      payload: data?.Result,
      Total: TotalRec?.data?.Result,
    });
  } catch (error) {
    dispatch({
      type: PACKAGE_LIST_FAILED,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const updatePackage = (PackageData, PackageId) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_PACAKGE_REQ });
    const { data } = await api.put(`/packages/${PackageId}`, PackageData);
    dispatch({ type: UPDATE_PACAKGE_SUCCESS, payload: data?.Result });
  } catch (error) {
    dispatch({
      type: UPDATE_PACAKGE_FAILED,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const packageDetails = (PackageId) => async (dispatch) => {
  try {
    dispatch({ type: PACKAGE_DETAILS_REQ });
    const { data } = await api.get(`/packages/${PackageId}`);

    dispatch({ type: PACKAGE_DETAILS_SUCCESS, payload: data?.Result });
  } catch (error) {
    dispatch({
      type: PACKAGE_DETAILS_FAILED,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const Package_Type_Data = () => async (dispatch) => {
  try {
    dispatch({ type: PACKAGE_TYPE_LIST_REQ });
    const { data } = await api.get("/commonEnums/PackageType");
    dispatch({ type: PACKAGE_TYPE_LIST_SUCCESS, payload: data?.Result });
  } catch (error) {
    dispatch({
      type: PACKAGE_TYPE_LIST_FAILED,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const removePackage = (Id) => async (dispatch) => {
  try {
    dispatch({ type: REMOVE_PACAKGE_REQ });
    const { data } = await api.delete(`/packages/${Id}`);
    dispatch({ type: REMOVE_PACAKGE_SUCCESS, payload: data?.Result });
  } catch (error) {
    dispatch({
      type: REMOVE_PACAKGE_FAILED,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};
