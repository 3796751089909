import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_RESET,
  USERS_LIST_REQUEST,
  USERS_LIST_SUCCESS,
  USERS_LIST_FAIL,
  USERS_GET_REQUEST,
  USERS_GET_SUCCESS,
  USERS_GET_FAIL,
  USERS_CREATE_REQUEST,
  USERS_CREATE_SUCCESS,
  USERS_CREATE_FAIL,
  USERS_CREATE_RESET,
  USERS_VERIFY_TKN_REQUEST,
  USERS_VERIFY_TKN_SUCCESS,
  USERS_VERIFY_TKN_FAIL,
  USERS_VERIFY_TKN_RESET,
  USERS_FORGOT_PASSWORD_REQUEST,
  USERS_FORGOT_PASSWORD_SUCCESS,
  USERS_FORGOT_PASSWORD_FAIL,
  USERS_FORGOT_PASSWORD_RESET,
  USERS_RESET_PASSWORD_REQUEST,
  USERS_RESET_PASSWORD_SUCCESS,
  USERS_RESET_PASSWORD_FAIL,
  USERS_RESET_PASSWORD_RESET,
  USERS_CHANGE_PASSWORD_REQUEST,
  USERS_CHANGE_PASSWORD_SUCCESS,
  USERS_CHANGE_PASSWORD_FAIL,
  USERS_CHANGE_PASSWORD_RESET,
  USERS_UPDATE_PROFILE_REQUEST,
  USERS_UPDATE_PROFILE_SUCCESS,
  USERS_UPDATE_PROFILE_FAIL,
  USERS_UPDATE_PROFILE_RESET,
  USERS_GET_INTERNAL_REQUEST,
  USERS_GET_INTERNAL_SUCCESS,
  USERS_GET_INTERNAL_FAIL,
  USERS_UPDATE_SUCCESS,
  USERS_UPDATE_FAIL,
  USERS_UPDATE_RESET,
  USERS_UPDATE_REQUEST,
  USERS_ACTIVATE_REQUEST,
  USERS_ACTIVATE_SUCCESS,
  USERS_ACTIVATE_FAIL,
  USERS_ACTIVATE_RESET,
  USERS_BLOCKED_REQUEST,
  USERS_BLOCKED_SUCCESS,
  USERS_BLOCKED_FAIL,
  USERS_BLOCKED_RESET,
} from "../Constants/userConstants";

export const loginUsersReducers = (state = { userRec: {} }, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return { loading: true };
    case USER_LOGIN_SUCCESS:
      return { loading: false, success: action.payload, userRec: action.rec };
    case USER_LOGIN_FAIL:
      return { loading: false, error: action.payload };

    case "LOGOUT": {
      return {};
    }
    default:
      return state;
  }
};

// User List
export const usersListReducers = (state = {}, action) => {
  switch (action.type) {
    case USERS_LIST_REQUEST:
      return { loading: true };
    case USERS_LIST_SUCCESS:
      return { loading: false, usersRec: action.payload };
    case USERS_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// User Insert
export const userSaveReducers = (state = {}, action) => {
  switch (action.type) {
    case USERS_CREATE_REQUEST:
      return { loading: true };
    case USERS_CREATE_SUCCESS:
      return { loading: false, success: action.payload };
    case USERS_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case USERS_CREATE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

// User Details
export const userDetailsReducers = (state = {}, action) => {
  switch (action.type) {
    case USERS_GET_REQUEST:
      return { loading: true };
    case USERS_GET_SUCCESS:
      return { loading: false, userDets: action.payload };
    case USERS_GET_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// User Details
export const userInternalDetailsReducers = (state = {}, action) => {
  switch (action.type) {
    case USERS_GET_INTERNAL_REQUEST:
      return { loading: true };
    case USERS_GET_INTERNAL_SUCCESS:
      return { loading: false, userInternalDets: action.payload };
    case USERS_GET_INTERNAL_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// Verify the token of User for resetting password
export const userVerifyTknReducers = (state = {}, action) => {
  switch (action.type) {
    case USERS_VERIFY_TKN_REQUEST:
      return { loading: true };
    case USERS_VERIFY_TKN_SUCCESS:
      return { loading: false, success: action.payload };
    case USERS_VERIFY_TKN_FAIL:
      return { loading: false, error: action.payload };
    case USERS_VERIFY_TKN_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

// Forgot Password of the User
export const userForgotPasswordReducers = (state = {}, action) => {
  switch (action.type) {
    case USERS_FORGOT_PASSWORD_REQUEST:
      return { loading: true };
    case USERS_FORGOT_PASSWORD_SUCCESS:
      return { loading: false, success: action.payload };
    case USERS_FORGOT_PASSWORD_FAIL:
      return { loading: false, error: action.payload };
    case USERS_FORGOT_PASSWORD_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

// To reset the password
export const userResetPasswordReducers = (state = {}, action) => {
  switch (action.type) {
    case USERS_RESET_PASSWORD_REQUEST:
      return { loading: true };
    case USERS_RESET_PASSWORD_SUCCESS:
      return { loading: false, success: action.payload };
    case USERS_RESET_PASSWORD_FAIL:
      return { loading: false, error: action.payload };
    case USERS_RESET_PASSWORD_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

// To reset the password
export const userChangePasswordReducers = (state = {}, action) => {
  switch (action.type) {
    case USERS_CHANGE_PASSWORD_REQUEST:
      return { loading: true };
    case USERS_CHANGE_PASSWORD_SUCCESS:
      return { loading: false, success: action.payload };
    case USERS_CHANGE_PASSWORD_FAIL:
      return { loading: false, error: action.payload };
    case USERS_CHANGE_PASSWORD_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

// To reset the password
export const userUpdateProfileReducers = (state = {}, action) => {
  switch (action.type) {
    case USERS_UPDATE_PROFILE_REQUEST:
      return { loading: true };
    case USERS_UPDATE_PROFILE_SUCCESS:
      return { loading: false, success: action.payload };
    case USERS_UPDATE_PROFILE_FAIL:
      return { loading: false, error: action.payload };
    case USERS_UPDATE_PROFILE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

// To update the Internal Details of User
export const userUpdateIntDetailsReducers = (state = {}, action) => {
  switch (action.type) {
    case USERS_UPDATE_REQUEST:
      return { loading: true };
    case USERS_UPDATE_SUCCESS:
      return { loading: false, success: action.payload };
    case USERS_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case USERS_UPDATE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

// To Activate the User.
export const userUpdateActiveStatusReducers = (state = {}, action) => {
  switch (action.type) {
    case USERS_ACTIVATE_REQUEST:
      return { loading: true };
    case USERS_ACTIVATE_SUCCESS:
      return { loading: false, success: action.payload };
    case USERS_ACTIVATE_FAIL:
      return { loading: false, error: action.payload };
    case USERS_ACTIVATE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

// To unblock the User
export const userUpdateBlockedStatusReducers = (state = {}, action) => {
  switch (action.type) {
    case USERS_BLOCKED_REQUEST:
      return { loading: true };
    case USERS_BLOCKED_SUCCESS:
      return { loading: false, success: action.payload };
    case USERS_BLOCKED_FAIL:
      return { loading: false, error: action.payload };
    case USERS_BLOCKED_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};
