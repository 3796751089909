import {
  EMAIL_TEMPLATE_CREATE_FAILED,
  EMAIL_TEMPLATE_CREATE_REQ,
  EMAIL_TEMPLATE_CREATE_RESET,
  EMAIL_TEMPLATE_CREATE_SUCCESS,
  EMAIL_TEMPLATE_DETAILS_FAILED,
  EMAIL_TEMPLATE_DETAILS_REQ,
  EMAIL_TEMPLATE_DETAILS_SUCCESS,
  EMAIL_TEMPLATE_LIST_ALL_FAILED,
  EMAIL_TEMPLATE_LIST_ALL_REQ,
  EMAIL_TEMPLATE_LIST_ALL_SUCCESS,
  EMAIL_TEMPLATE_LIST_FAILED,
  EMAIL_TEMPLATE_LIST_REQ,
  EMAIL_TEMPLATE_LIST_RESET,
  EMAIL_TEMPLATE_LIST_SUCCESS,
  EMAIL_TEMPLATE_REMOVE_FAILED,
  EMAIL_TEMPLATE_REMOVE_REQ,
  EMAIL_TEMPLATE_REMOVE_RESET,
  EMAIL_TEMPLATE_REMOVE_SUCCESS,
  EMAIL_TEMPLATE_UPDATE_FAILED,
  EMAIL_TEMPLATE_UPDATE_REQ,
  EMAIL_TEMPLATE_UPDATE_RESET,
  EMAIL_TEMPLATE_UPDATE_SUCCESS,
} from "../Constants/EmailTemplateConstants";

export const createEmailTemplateReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case EMAIL_TEMPLATE_CREATE_REQ: {
      return { loading: true };
    }

    case EMAIL_TEMPLATE_CREATE_SUCCESS: {
      return { loading: false, success: payload };
    }

    case EMAIL_TEMPLATE_CREATE_FAILED: {
      return { loading: false, error: payload };
    }

    case EMAIL_TEMPLATE_CREATE_RESET: {
      return { laoding: false, success: "", error: "" };
    }
    default:
      return state;
  }
};

export const listEmailTemplateReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case EMAIL_TEMPLATE_LIST_REQ: {
      return { loading: true };
    }

    case EMAIL_TEMPLATE_LIST_SUCCESS: {
      return { loading: false, EmailTemplateRec: payload };
    }

    case EMAIL_TEMPLATE_LIST_FAILED: {
      return { loading: false, error: payload };
    }

    case EMAIL_TEMPLATE_LIST_RESET: {
      return {};
    }

    default:
      return state;
  }
};

export const listEmailTemplateAllReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case EMAIL_TEMPLATE_LIST_ALL_REQ: {
      return { loading: true };
    }

    case EMAIL_TEMPLATE_LIST_ALL_SUCCESS: {
      return { loading: false, EmailTemplateAllRec: payload };
    }

    case EMAIL_TEMPLATE_LIST_ALL_FAILED: {
      return { loading: false, error: payload };
    }

    default:
      return state;
  }
};

export const deleteEmailTemplateReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case EMAIL_TEMPLATE_REMOVE_REQ: {
      return { loading: true };
    }
    case EMAIL_TEMPLATE_REMOVE_SUCCESS: {
      return { loading: false, success: payload };
    }

    case EMAIL_TEMPLATE_REMOVE_FAILED: {
      return { loading: false, error: payload };
    }

    case EMAIL_TEMPLATE_REMOVE_RESET: {
      return { loading: false, error: "", success: "" };
    }
    default:
      return state;
  }
};

export const updateEmailTemplateReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case EMAIL_TEMPLATE_UPDATE_REQ: {
      return { loading: true };
    }

    case EMAIL_TEMPLATE_UPDATE_SUCCESS: {
      return { loading: false, success: payload };
    }

    case EMAIL_TEMPLATE_UPDATE_FAILED: {
      return { laoding: false, error: payload };
    }

    case EMAIL_TEMPLATE_UPDATE_RESET: {
      return { laoding: false, error: "", success: "" };
    }

    default:
      return state;
  }
};

export const singleEmailTemplateDetailsReducer = (
  state = {},
  { type, payload }
) => {
  switch (type) {
    case EMAIL_TEMPLATE_DETAILS_REQ: {
      return { loading: true };
    }

    case EMAIL_TEMPLATE_DETAILS_SUCCESS: {
      return { loading: false, EmailTemplateDetails: payload };
    }

    case EMAIL_TEMPLATE_DETAILS_FAILED: {
      return { laoding: false, error: payload };
    }

    default:
      return state;
  }
};
