export const HIERARCHY_LIST_REQUEST = 'HIERARCHY_LIST_REQUEST'
export const HIERARCHY_LIST_SUCCESS = 'HIERARCHY_LIST_SUCCESS'
export const HIERARCHY_LIST_FAIL = 'HIERARCHY_LIST_FAIL'

export const HIERARCHY_GET_REQUEST = 'HIERARCHY_GET_REQUEST'
export const HIERARCHY_GET_SUCCESS = 'HIERARCHY_GET_SUCCESS'
export const HIERARCHY_GET_FAIL = 'HIERARCHY_GET_FAIL'

export const HIERARCHY_INSERT_REQUEST = 'HIERARCHY_INSERT_REQUEST'
export const HIERARCHY_INSERT_SUCCESS = 'HIERARCHY_INSERT_SUCCESS'
export const HIERARCHY_INSERT_FAIL = 'HIERARCHY_INSERT_FAIL'
export const HIERARCHY_INSERT_RESET = 'HIERARCHY_INSERT_RESET'

export const HIERARCHY_UPDATE_REQUEST = 'HIERARCHY_UPDATE_REQUEST'
export const HIERARCHY_UPDATE_SUCCESS = 'HIERARCHY_UPDATE_SUCCESS'
export const HIERARCHY_UPDATE_FAIL = 'HIERARCHY_UPDATE_FAIL'
export const HIERARCHY_UPDATE_RESET = 'HIERARCHY_UPDATE_RESET'

export const HIERARCHY_DELETE_REQUEST = 'HIERARCHY_DELETE_REQUEST'
export const HIERARCHY_DELETE_SUCCESS = 'HIERARCHY_DELETE_SUCCESS'
export const HIERARCHY_DELETE_FAIL = 'HIERARCHY_DELETE_FAIL'
export const HIERARCHY_DELETE_RESET = 'HIERARCHY_DELETE_RESET'