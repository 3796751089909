import {
  TASK_REMINDER_LIST_DASHBOARD_FAIL,
  TASK_REMINDER_LIST_DASHBOARD_REQUEST,
  TASK_REMINDER_LIST_DASHBOARD_SUCCESS,
  TASK_REMINDER_TOTAL_FAIL,
  TASK_REMINDER_TOTAL_REQUEST,
  TASK_REMINDER_TOTAL_SUCCESS,
} from "../Constants/taskLogConstants";

export const taskReminderTotalReducer = (state = {}, action) => {
  switch (action.type) {
    case TASK_REMINDER_TOTAL_REQUEST:
      return { loading: true };
    case TASK_REMINDER_TOTAL_SUCCESS:
      return { loading: false, reminderTotalValue: action.payload };
    case TASK_REMINDER_TOTAL_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const dashboardReminderListReducer = (state = {}, action) => {
  switch (action.type) {
    case TASK_REMINDER_LIST_DASHBOARD_REQUEST:
      return { loading: true };
    case TASK_REMINDER_LIST_DASHBOARD_SUCCESS:
      return { loading: false, reminderList: action.payload };
    case TASK_REMINDER_LIST_DASHBOARD_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
