import { useDispatch, useSelector } from "react-redux";
import { Sidebar_Handle_Open } from "../Actions/SideBarAction";
import { HiUserCircle } from "react-icons/hi";
import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from "@coreui/react";

export default function TopBar({ Heading }) {
  const dispatch = useDispatch();
  const { sidebar } = useSelector((state) => state.Sidebar_Reducer);
  const { userRec } = useSelector((state) => state.loginUsers);
  const handleSideBar = (e) => {
    e.preventDefault();
    dispatch(Sidebar_Handle_Open(!sidebar));
  };

  return (
    <>
      <nav>
        <div className="sidebar-button">
          <i onClick={handleSideBar} className="bx bx-menu sidebarBtn"></i>
          <span className="dashboard">{Heading ? Heading : "Dashboard"}</span>
        </div>
        {/*<div className="search-box">
          <input type="text" placeholder="Search..." />
          <i className="bx bx-search"></i>
  </div>*/}
        <div className="profile-details">
          <CDropdown style={{ background: "none" }}>
            <CDropdownToggle
              color="none"
              style={{ border: "none", background: "none", boxShadow: "none" }}
            >
              <span style={{ marginRight: "10px", fontWeight: "600" }}>
                {userRec?.First_Name}
              </span>
              <span style={{ fontSize: "35px", marginTop: "10px" }}>
                <HiUserCircle />
              </span>
            </CDropdownToggle>
            <CDropdownMenu>
              <CDropdownItem href="/Account">Account</CDropdownItem>
              <CDropdownItem href="/setting">Settings</CDropdownItem>
              {/* <CDropdownItem href="/resetpassword">
                Change Password
              </CDropdownItem> */}
            </CDropdownMenu>
          </CDropdown>
        </div>
      </nav>
    </>
  );
}
