export const contactJson = {
  Contact_Id: "",
  First_Name: "",
  Last_Name: "",
  Phone_No: "",
  Email_Address: "",
  Country_Id: "",
  full_name: "",
  pageId: "",
  createdtime: "",
  leadgen_id: "",
  ad_id: "",
  adset_id: "",
  campaign_id: "",
  form_id: "",
  Assigned_User_Id: "",
  Qualifies: "",
  Educational_Status: "",
  Year_Of_Completion: "",
  Category_Of_Study: "",
  Interested_Country: "",
  Interested_Field_Of_Study: "",
  Interested_Level_Of_Study: "",
  English_Test_Attempted: "",
  English_Test_Score: "",
  Team_Id: "",
  Contact_Platform: "",
  Reason_not_Qualify: "",
  Preferred_Intake: "",
  Profile_Status: "",
  Month_Id: "",
  Academic_Percentage: "",
  Intake_Year: "",
  Email_Address1: "",
  Email_Address2: "",
  Phone_No1: "",
  Phone_No2: "",
  City: "",
  Comments: "",
  Contact_priority: "",
  Lead_Source: "",
  visa_expiry_date: "",
  referral_type: "",
  Referral_User_Id: "",
  Referral_SubAgent_Id: "",
  Referral_Contact_Id: "",
  Gender: "",
  Date_Of_Birth: "",
  Marital_Status: "",
  Contact_Rating: "",
  How_you_heard_About_Us: "",
  Branch: "",
  Enquiry_For: "",
  Interested_Country2: "",
  Financial_Source: "",
  Counsellor_Meeting: "",
  Meeting_Date: "",
  Ielts_Paid: "",
  Contact_Owner_II: "",
  State: "",
  Grading_System: "",
  Passport_number: "",
  EmRelation: "",
  EmName: "",
  EmPhone_Number: "",
  EmEmail_Address: "",
  OSHC_Type: "",
  Previous_Visa_Refusal: "",
  Visa_Refusal_Reason: "",
  Vaccination_Status: "",
  Enquired_Times: "",
  Subscribed_Leads: "",
  page_size: 25,
  page_no: 1,
  searchtxt: "",
  contactnote: "",
};

export const contactJson2 = {
  First_Name: "",
  Last_Name: "",
  EmailAddress: "",
  PhoneNo: "",
  Qualifies: "",
  City: "",
  State: "",
  Country: "",
};

export const contactFilterJson = {
  Contact_Id: "",
  First_Name: "",
  Last_Name: "",
  Phone_No: "",
  Email_Address: "",
  Country_Id: "",
  full_name: "",
  pageId: "",
  createdtime: "",
  leadgen_id: "",
  ad_id: "",
  adset_id: "",
  campaign_id: "",
  form_id: "",
  Assigned_User_Id: "",
  Qualifies: "",
  Educational_Status: "",
  Year_Of_Completion: "",
  Category_Of_Study: "",
  Interested_Country: "",
  Interested_Field_Of_Study: "",
  Interested_Level_Of_Study: "",
  English_Test_Attempted: "",
  English_Test_Score: "",
  Team_Id: "",
  Contact_Platform: "",
  Reason_not_Qualify: "",
  Preferred_Intake: "",
  Profile_Status: "",
  Month_Id: "",
  AcademicPercentage_From: "",
  AcademicPercentage_To: "",
  Intake_Year: "",
  Email_Address1: "",
  Email_Address2: "",
  Phone_No1: "",
  Phone_No2: "",
  City: "",
  Comments: "",
  Contact_priority: "",
  Lead_Source: "",
  visa_expiry_date: "",
  referral_type: "",
  Referral_User_Id: "",
  Referral_SubAgent_Id: "",
  Referral_Contact_Id: "",
  Gender: "",
  Date_Of_Birth: "",
  Marital_Status: "",
  Contact_Rating: "",
  How_you_heard_About_Us: "",
  Branch: "",
  Enquiry_For: "",
  Interested_Country2: "",
  Financial_Source: "",
  Counsellor_Meeting: "",
  Meeting_Date: "",
  Ielts_Paid: "",
  Contact_Owner_II: "",
  State: "",
  Grading_System: "",
  Passport_number: "",
  EmRelation: "",
  EmName: "",
  EmPhone_Number: "",
  EmEmail_Address: "",
  OSHC_Type: "",
  Previous_Visa_Refusal: "",
  Visa_Refusal_Reason: "",
  Vaccination_Status: "",
  Enquired_Times: "",
  Subscribed_Leads: "",
  page_size: 25,
  page_no: 1,
  contactnote: "",
  Meeting_Date_From: "",
  Meeting_Date_To: "",
  First_Activity_Date_To: "",
  First_Activity_Date_From: "",
  Last_Activity_Date_From: "",
  Last_Activity_Date_To: "",
  Create_Date_From: "",
  Create_Date_To: "",
};

export const ContactCreateValidator = (data) => {
  let Error = "";
  if (
    data?.First_Name === "" ||
    data?.First_Name === undefined ||
    data?.First_Name === null
  ) {
    Error += "First Name required";
  }

  if (
    data?.Last_Name === "" ||
    data?.Last_Name === undefined ||
    data?.Last_Name === null
  ) {
    Error += "Last Name required ";
  }

  if (
    data?.EmailAddress === "" ||
    data?.EmailAddress === undefined ||
    data?.EmEmail_Address === null
  ) {
    Error += "Email Address required ";
  }

  if (
    data?.PhoneNo === "" ||
    data?.PhoneNo === undefined ||
    data?.PhoneNo === null
  ) {
    Error += "PhoneNo required ";
  }

  if (Error) {
    return "Error - " + Error;
  } else {
    return "success";
  }
};

export const ContactValidatorForm = (data) => {
  let error = [];
  if (
    data?.Enquiry_For === "" ||
    data?.Enquiry_For === undefined ||
    data?.Enquiry_For === null
  ) {
    error.push("Enquiry For");
  }

  if (
    data?.Lead_Source === "" ||
    data?.Lead_Source === undefined ||
    data?.Lead_Source === null
  ) {
    error.push("Lead Source");
  }
  if (
    data?.First_Name === "" ||
    data?.First_Name === undefined ||
    data?.First_Name === null
  ) {
    error.push("First Name");
  }

  if (
    data?.Last_Name === "" ||
    data?.Last_Name === undefined ||
    data?.Last_Name === null
  ) {
    error.push("Last Name");
  }

  if (
    data?.Email_Address === "" ||
    data?.Email_Address === undefined ||
    data?.Email_Address === null
  ) {
    error.push("Email Address");
  }

  if (
    data?.Phone_No === "" ||
    data?.Phone_No === undefined ||
    data?.Phone_No === null
  ) {
    error.push("Phone No");
  }

  if (error.length > 0) {
    return "Error -" + error.join(",") + " required !";
  } else {
    return "success";
  }
};
