import {
  MEDIUM_OF_EVENT_DELETE_FAIL,
  MEDIUM_OF_EVENT_DELETE_REQUEST,
  MEDIUM_OF_EVENT_DELETE_RESET,
  MEDIUM_OF_EVENT_DELETE_SUCCESS,
  MEDIUM_OF_EVENT_INSERT_FAIL,
  MEDIUM_OF_EVENT_INSERT_REQUEST,
  MEDIUM_OF_EVENT_INSERT_RESET,
  MEDIUM_OF_EVENT_INSERT_SUCCESS,
  MEDIUM_OF_EVENT_LIST_FAIL,
  MEDIUM_OF_EVENT_LIST_REQUEST,
  MEDIUM_OF_EVENT_LIST_SUCCESS,
  MEDIUM_OF_EVENT_UPDATE_FAIL,
  MEDIUM_OF_EVENT_UPDATE_REQUEST,
  MEDIUM_OF_EVENT_UPDATE_RESET,
  MEDIUM_OF_EVENT_UPDATE_SUCCESS,
} from "../Constants/mediumOfEventConstants";

export const mediumOfEventListReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case MEDIUM_OF_EVENT_LIST_REQUEST:
      return { loading: true };
    case MEDIUM_OF_EVENT_LIST_SUCCESS:
      return { loading: false, mediumOfEventRecs: payload };
    case MEDIUM_OF_EVENT_LIST_FAIL:
      return { loading: false, error: payload };
    default:
      return state;
  }
};

export const mediumOfEventSaveReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case MEDIUM_OF_EVENT_INSERT_REQUEST:
      return { loading: true };
    case MEDIUM_OF_EVENT_INSERT_SUCCESS:
      return { loading: false, success: payload };
    case MEDIUM_OF_EVENT_INSERT_FAIL:
      return { loading: false, error: payload };
    case MEDIUM_OF_EVENT_INSERT_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const mediumOfEventUpdateReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case MEDIUM_OF_EVENT_UPDATE_REQUEST:
      return { loading: true };
    case MEDIUM_OF_EVENT_UPDATE_SUCCESS:
      return { loading: false, success: payload };
    case MEDIUM_OF_EVENT_UPDATE_FAIL:
      return { loading: false, error: payload };
    case MEDIUM_OF_EVENT_UPDATE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const mediumOfEventDeleteReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case MEDIUM_OF_EVENT_DELETE_REQUEST:
      return { loading: true };
    case MEDIUM_OF_EVENT_DELETE_SUCCESS:
      return { loading: false, success: payload };
    case MEDIUM_OF_EVENT_DELETE_FAIL:
      return { loading: false, error: payload };
    case MEDIUM_OF_EVENT_DELETE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};
