export const ORDER_LIST_REQ = "ORDER_LIST_REQ";
export const ORDER_LIST_SUCCESS = "ORDER_LIST_SUCCESS";
export const ORDER_LIST_FAILED = "ORDER_LIST_FAILED";
export const ORDER_LIST_RESET = "ORDER_LIST_RESET";

export const ORDER_LIST_TOTAL_REQ = "ORDER_LIST_TOTAL_REQ";
export const ORDER_LIST_TOTAL_SUCCESS = "ORDER_LIST_TOTAL_SUCCESS";
export const ORDER_LIST_TOTAL_FAILED = "ORDER_LIST_TOTAL_FAILED";
export const ORDER_LIST_TOTAL_RESET = "ORDER_LIST_TOTAL_RESET";

export const ORDER_DETAIL_CREATE_REQ = "ORDER_DETAIL_CREATE_REQ";
export const ORDER_DETAIL_CREATE_SUCCESS = "ORDER_DETAIL_CREATE_SUCCESS";
export const ORDER_DETAIL_CREATE_FAILED = "ORDER_DETAIL_CREATE_FAILED";
export const ORDER_DETAIL_CREATE_RESET = "ORDER_DETAIL_CREATE_RESET";

export const ORDER_DETAIL_UPDATE_REQ = "ORDER_DETAIL_UPDATE_REQ";
export const ORDER_DETAIL_UPDATE_SUCCESS = "ORDER_DETAIL_UPDATE_SUCCESS";
export const ORDER_DETAIL_UPDATE_FAILED = "ORDER_DETAIL_UPDATE_FAILED";
export const ORDER_DETAIL_UPDATE_RESET = "ORDER_DETAIL_UPDATE_RESET";

export const ORDER_DETAIL_REMOVE_REQ = "ORDER_DETAIL_REMOVE_REQ";
export const ORDER_DETAIL_REMOVE_SUCCESS = "ORDER_DETAIL_REMOVE_SUCCESS";
export const ORDER_DETAIL_REMOVE_FAILED = "ORDER_DETAIL_REMOVE_FAILED";
export const ORDER_DETAIL_REMOVE_RESET = "ORDER_DETAIL_REMOVE_RESET";

export const ORDER_DETAILS_REQ = "ORDER_DETAILS_REQ";
export const ORDER_DETAILS_SUCCESS = "ORDER_DETAILS_SUCCESS";
export const ORDER_DETAILS_FAILED = "ORDER_DETAILS_FAILED";
