import {
  REFERRAL_DELETE_FAIL,
  REFERRAL_DELETE_REQUEST,
  REFERRAL_DELETE_RESET,
  REFERRAL_DELETE_SUCCESS,
  REFERRAL_INSERT_FAIL,
  REFERRAL_INSERT_REQUEST,
  REFERRAL_INSERT_RESET,
  REFERRAL_INSERT_SUCCESS,
  REFERRAL_LIST_FAIL,
  REFERRAL_LIST_REQUEST,
  REFERRAL_LIST_SUCCESS,
  REFERRAL_UPDATE_FAIL,
  REFERRAL_UPDATE_REQUEST,
  REFERRAL_UPDATE_RESET,
  REFERRAL_UPDATE_SUCCESS,
} from "../Constants/referralConstants";

export const referralTypeListReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case REFERRAL_LIST_REQUEST:
      return { loading: true };
    case REFERRAL_LIST_SUCCESS:
      return { loading: false, referralTypeRecs: payload };
    case REFERRAL_LIST_FAIL:
      return { loading: false, error: payload };
    default:
      return state;
  }
};

export const referralTypeSaveReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case REFERRAL_INSERT_REQUEST:
      return { loading: true };
    case REFERRAL_INSERT_SUCCESS:
      return { loading: false, success: payload };
    case REFERRAL_INSERT_FAIL:
      return { loading: false, error: payload };
    case REFERRAL_INSERT_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const referralTypeUpdateReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case REFERRAL_UPDATE_REQUEST:
      return { loading: true };
    case REFERRAL_UPDATE_SUCCESS:
      return { loading: false, success: payload };
    case REFERRAL_UPDATE_FAIL:
      return { loading: false, error: payload };
    case REFERRAL_UPDATE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const referralTypeDeleteReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case REFERRAL_DELETE_REQUEST:
      return { loading: true };
    case REFERRAL_DELETE_SUCCESS:
      return { loading: false, success: payload };
    case REFERRAL_DELETE_FAIL:
      return { loading: false, error: payload };
    case REFERRAL_DELETE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};
