import {
  QUALIFIES_DELETE_FAIL,
  QUALIFIES_DELETE_REQUEST,
  QUALIFIES_DELETE_RESET,
  QUALIFIES_DELETE_SUCCESS,
  QUALIFIES_INSERT_FAIL,
  QUALIFIES_INSERT_REQUEST,
  QUALIFIES_INSERT_RESET,
  QUALIFIES_INSERT_SUCCESS,
  QUALIFIES_LIST_FAIL,
  QUALIFIES_LIST_REQUEST,
  QUALIFIES_LIST_SUCCESS,
  QUALIFIES_UPDATE_FAIL,
  QUALIFIES_UPDATE_REQUEST,
  QUALIFIES_UPDATE_RESET,
  QUALIFIES_UPDATE_SUCCESS,
} from "../Constants/qualifiesConstants";

export const qualifiesListReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case QUALIFIES_LIST_REQUEST:
      return { loading: true };
    case QUALIFIES_LIST_SUCCESS:
      return { loading: false, qualifiesRec: payload };
    case QUALIFIES_LIST_FAIL:
      return { loading: false, error: payload };
    default:
      return state;
  }
};

export const qualifiesSaveReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case QUALIFIES_INSERT_REQUEST:
      return { loading: true };
    case QUALIFIES_INSERT_SUCCESS:
      return { loading: false, success: payload };
    case QUALIFIES_INSERT_FAIL:
      return { loading: false, error: payload };
    case QUALIFIES_INSERT_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const qualifiesUpdateReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case QUALIFIES_UPDATE_REQUEST:
      return { loading: true };
    case QUALIFIES_UPDATE_SUCCESS:
      return { loading: false, success: payload };
    case QUALIFIES_UPDATE_FAIL:
      return { loading: false, error: payload };
    case QUALIFIES_UPDATE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const qualifiesDeleteReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case QUALIFIES_DELETE_REQUEST:
      return { loading: true };
    case QUALIFIES_DELETE_SUCCESS:
      return { loading: false, success: payload };
    case QUALIFIES_DELETE_FAIL:
      return { loading: false, error: payload };
    case QUALIFIES_DELETE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};
