export const ENGLISH_TEST_ATTEMPTED_LIST_REQUEST = 'ENGLISH_TEST_ATTEMPTED_LIST_REQUEST'
export const ENGLISH_TEST_ATTEMPTED_LIST_SUCCESS = 'ENGLISH_TEST_ATTEMPTED_LIST_SUCCESS'
export const ENGLISH_TEST_ATTEMPTED_LIST_FAIL = 'ENGLISH_TEST_ATTEMPTED_LIST_FAIL'

export const ENGLISH_TEST_ATTEMPTED_GET_REQUEST = 'ENGLISH_TEST_ATTEMPTED_GET_REQUEST'
export const ENGLISH_TEST_ATTEMPTED_GET_SUCCESS = 'ENGLISH_TEST_ATTEMPTED_GET_SUCCESS'
export const ENGLISH_TEST_ATTEMPTED_GET_FAIL = 'ENGLISH_TEST_ATTEMPTED_GET_FAIL'

export const ENGLISH_TEST_ATTEMPTED_INSERT_REQUEST = 'ENGLISH_TEST_ATTEMPTED_INSERT_REQUEST'
export const ENGLISH_TEST_ATTEMPTED_INSERT_SUCCESS = 'ENGLISH_TEST_ATTEMPTED_INSERT_SUCCESS'
export const ENGLISH_TEST_ATTEMPTED_INSERT_FAIL = 'ENGLISH_TEST_ATTEMPTED_INSERT_FAIL'
export const ENGLISH_TEST_ATTEMPTED_INSERT_RESET = 'ENGLISH_TEST_ATTEMPTED_INSERT_RESET'

export const ENGLISH_TEST_ATTEMPTED_UPDATE_REQUEST = 'ENGLISH_TEST_ATTEMPTED_UPDATE_REQUEST'
export const ENGLISH_TEST_ATTEMPTED_UPDATE_SUCCESS = 'ENGLISH_TEST_ATTEMPTED_UPDATE_SUCCESS'
export const ENGLISH_TEST_ATTEMPTED_UPDATE_FAIL = 'ENGLISH_TEST_ATTEMPTED_UPDATE_FAIL'
export const ENGLISH_TEST_ATTEMPTED_UPDATE_RESET = 'ENGLISH_TEST_ATTEMPTED_UPDATE_RESET'

export const ENGLISH_TEST_ATTEMPTED_DELETE_REQUEST = 'ENGLISH_TEST_ATTEMPTED_DELETE_REQUEST'
export const ENGLISH_TEST_ATTEMPTED_DELETE_SUCCESS = 'ENGLISH_TEST_ATTEMPTED_DELETE_SUCCESS'
export const ENGLISH_TEST_ATTEMPTED_DELETE_FAIL = 'ENGLISH_TEST_ATTEMPTED_DELETE_FAIL'
export const ENGLISH_TEST_ATTEMPTED_DELETE_RESET = 'ENGLISH_TEST_ATTEMPTED_DELETE_RESET'
