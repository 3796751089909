import {
  PARTNER_AGENT_LIST_FAIL,
  PARTNER_AGENT_LIST_REQUEST,
  PARTNER_AGENT_LIST_SUCCESS,
} from "../Constants/partnerAgentConstants";

export const partnerAgentReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case PARTNER_AGENT_LIST_REQUEST: {
      return { loading: true };
    }

    case PARTNER_AGENT_LIST_SUCCESS: {
      return { loading: false, partnerAgentRecs: payload };
    }

    case PARTNER_AGENT_LIST_FAIL: {
      return { loading: false, error: payload };
    }

    default:
      return state;
  }
};
