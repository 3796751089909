export const NOTIFICATIONS_LIST_REQUEST = "NOTIFICATIONS_LIST_REQUEST";
export const NOTIFICATIONS_LIST_SUCCESS = "NOTIFICATIONS_LIST_SUCCESS";
export const NOTIFICATIONS_LIST_FAIL = "NOTIFICATIONS_LIST_FAIL";

export const NOTIFICATIONS_GET_REQUEST = "NOTIFICATIONS_GET_REQUEST";
export const NOTIFICATIONS_GET_SUCCESS = "NOTIFICATIONS_GET_SUCCESS";
export const NOTIFICATIONS_GET_FAIL = "NOTIFICATIONS_GET_FAIL";

export const NOTIFICATIONS_INSERT_REQUEST = "NOTIFICATIONS_INSERT_REQUEST";
export const NOTIFICATIONS_INSERT_SUCCESS = "NOTIFICATIONS_INSERT_SUCCESS";
export const NOTIFICATIONS_INSERT_FAIL = "NOTIFICATIONS_INSERT_FAIL";
export const NOTIFICATIONS_INSERT_RESET = "NOTIFICATIONS_INSERT_RESET";

export const NOTIFICATIONS_UPDATE_REQUEST = "NOTIFICATIONS_UPDATE_REQUEST";
export const NOTIFICATIONS_UPDATE_SUCCESS = "NOTIFICATIONS_UPDATE_SUCCESS";
export const NOTIFICATIONS_UPDATE_FAIL = "NOTIFICATIONS_UPDATE_FAIL";

export const NOTIFICATIONS_DELETE_REQUEST = "NOTIFICATIONS_DELETE_REQUEST";
export const NOTIFICATIONS_DELETE_SUCCESS = "NOTIFICATIONS_DELETE_SUCCESS";
export const NOTIFICATIONS_DELETE_FAIL = "NOTIFICATIONS_DELETE_FAIL";
