export const STUDY_PACKAGE_LIST_REQUEST = "STUDY_PACKAGE_LIST_REQUEST";
export const STUDY_PACKAGE_LIST_SUCCESS = "STUDY_PACKAGE_LIST_SUCCESS";
export const STUDY_PACKAGE_LIST_FAIL = "STUDY_PACKAGE_LIST_FAIL";

export const STUDY_PACKAGE_GET_REQUEST = "STUDY_PACKAGE_GET_REQUEST";
export const STUDY_PACKAGE_GET_SUCCESS = "STUDY_PACKAGE_GET_SUCCESS";
export const STUDY_PACKAGE_GET_FAIL = "STUDY_PACKAGE_GET_FAIL";

export const STUDY_PACKAGE_INSERT_REQUEST = "STUDY_PACKAGE_INSERT_REQUEST";
export const STUDY_PACKAGE_INSERT_SUCCESS = "STUDY_PACKAGE_INSERT_SUCCESS";
export const STUDY_PACKAGE_INSERT_FAIL = "STUDY_PACKAGE_INSERT_FAIL";
export const STUDY_PACKAGE_INSERT_RESET = "STUDY_PACKAGE_INSERT_RESET";

export const STUDY_PACKAGE_UPDATE_REQUEST = "STUDY_PACKAGE_UPDATE_REQUEST";
export const STUDY_PACKAGE_UPDATE_SUCCESS = "STUDY_PACKAGE_UPDATE_SUCCESS";
export const STUDY_PACKAGE_UPDATE_FAIL = "STUDY_PACKAGE_UPDATE_FAIL";
export const STUDY_PACKAGE_UPDATE_RESET = "STUDY_PACKAGE_UPDATE_RESET";

export const STUDY_PACKAGE_DELETE_REQUEST = "STUDY_PACKAGE_DELETE_REQUEST";
export const STUDY_PACKAGE_DELETE_SUCCESS = "STUDY_PACKAGE_DELETE_SUCCESS";
export const STUDY_PACKAGE_DELETE_FAIL = "STUDY_PACKAGE_DELETE_FAIL";
export const STUDY_PACKAGE_DELETE_RESET = "STUDY_PACKAGE_DELETE_RESET";
