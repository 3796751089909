import {
  PIPELINE_DELETE_FAIL,
  PIPELINE_DELETE_REQUEST,
  PIPELINE_DELETE_RESET,
  PIPELINE_DELETE_SUCCESS,
  PIPELINE_GET_FAIL,
  PIPELINE_GET_REQUEST,
  PIPELINE_GET_SUCCESS,
  PIPELINE_INSERT_FAIL,
  PIPELINE_INSERT_REQUEST,
  PIPELINE_INSERT_RESET,
  PIPELINE_INSERT_SUCCESS,
  PIPELINE_LIST_FAIL,
  PIPELINE_LIST_REQUEST,
  PIPELINE_LIST_SUCCESS,
  PIPELINE_UPDATE_FAIL,
  PIPELINE_UPDATE_REQUEST,
  PIPELINE_UPDATE_RESET,
  PIPELINE_UPDATE_SUCCESS,
} from "../Constants/pipelineConstants";

export const pipelineListReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case PIPELINE_LIST_REQUEST:
      return { loading: true };
    case PIPELINE_LIST_SUCCESS:
      return { loading: false, pipelineRec: payload };
    case PIPELINE_LIST_FAIL:
      return { loading: false, error: payload };
    default:
      return state;
  }
};

export const pipelineDetailReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case PIPELINE_GET_REQUEST:
      return { loading: true };
    case PIPELINE_GET_SUCCESS:
      return { loading: false, pipelinerec: payload };
    case PIPELINE_GET_FAIL:
      return { loading: false, error: payload };
    default:
      return state;
  }
};

export const pipelineSaveReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case PIPELINE_INSERT_REQUEST:
      return { loading: true };
    case PIPELINE_INSERT_SUCCESS:
      return { loading: false, success: payload };
    case PIPELINE_INSERT_FAIL:
      return { loading: false, error: payload };
    case PIPELINE_INSERT_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const pipelineUpdateReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case PIPELINE_UPDATE_REQUEST:
      return { loading: true };
    case PIPELINE_UPDATE_SUCCESS:
      return { loading: false, success: payload };
    case PIPELINE_UPDATE_FAIL:
      return { loading: false, error: payload };
    case PIPELINE_UPDATE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const pipelineDeleteReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case PIPELINE_DELETE_REQUEST:
      return { loading: true };
    case PIPELINE_DELETE_SUCCESS:
      return { loading: false, success: payload };
    case PIPELINE_DELETE_FAIL:
      return { loading: false, error: payload };
    case PIPELINE_DELETE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};
