import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listGurully } from "../../Actions/GurullyAction";

const GurullyDropDown = React.memo(() => {
  const dispatch = useDispatch();
  const { gurullyRecs } = useSelector((s) => s.gurullyList);
  useEffect(() => {
    dispatch(listGurully());
  }, [dispatch]);
  return gurullyRecs ? (
    gurullyRecs?.map((recs) => (
      <option value={recs._id} key={recs._id}>
        {recs?.UserId}
      </option>
    ))
  ) : (
    <></>
  );
});
export default GurullyDropDown;
