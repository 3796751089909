import {
  DEAL_STAGE_DELETE_FAIL,
  DEAL_STAGE_DELETE_REQUEST,
  DEAL_STAGE_DELETE_RESET,
  DEAL_STAGE_DELETE_SUCCESS,
  DEAL_STAGE_INSERT_FAIL,
  DEAL_STAGE_INSERT_REQUEST,
  DEAL_STAGE_INSERT_RESET,
  DEAL_STAGE_INSERT_SUCCESS,
  DEAL_STAGE_LIST_FAIL,
  DEAL_STAGE_LIST_REQUEST,
  DEAL_STAGE_LIST_SUCCESS,
  DEAL_STAGE_UPDATE_FAIL,
  DEAL_STAGE_UPDATE_REQUEST,
  DEAL_STAGE_UPDATE_RESET,
  DEAL_STAGE_UPDATE_SUCCESS,
} from "../Constants/dealStageConstants";

export const dealStageListReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case DEAL_STAGE_LIST_REQUEST:
      return { loading: true };
    case DEAL_STAGE_LIST_SUCCESS:
      return { loading: false, dealStageRecs: payload };
    case DEAL_STAGE_LIST_FAIL:
      return { loading: false, error: payload };
    default:
      return state;
  }
};

export const dealStageSaveReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case DEAL_STAGE_INSERT_REQUEST:
      return { loading: true };
    case DEAL_STAGE_INSERT_SUCCESS:
      return { loading: false, success: payload };
    case DEAL_STAGE_INSERT_FAIL:
      return { loading: false, error: payload };
    case DEAL_STAGE_INSERT_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const dealStageUpdateReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case DEAL_STAGE_UPDATE_REQUEST:
      return { loading: true };
    case DEAL_STAGE_UPDATE_SUCCESS:
      return { loading: false, success: payload };
    case DEAL_STAGE_UPDATE_FAIL:
      return { loading: false, error: payload };
    case DEAL_STAGE_UPDATE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const dealStageDeleteReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case DEAL_STAGE_DELETE_REQUEST:
      return { loading: true };
    case DEAL_STAGE_DELETE_SUCCESS:
      return { loading: false, success: payload };
    case DEAL_STAGE_DELETE_FAIL:
      return { loading: false, error: payload };
    case DEAL_STAGE_DELETE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};
