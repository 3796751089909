import {
  PROFILE_DELETE_FAIL,
  PROFILE_DELETE_REQUEST,
  PROFILE_DELETE_RESET,
  PROFILE_DELETE_SUCCESS,
  PROFILE_INSERT_FAIL,
  PROFILE_INSERT_REQUEST,
  PROFILE_INSERT_RESET,
  PROFILE_INSERT_SUCCESS,
  PROFILE_LIST_FAIL,
  PROFILE_LIST_REQUEST,
  PROFILE_LIST_SUCCESS,
  PROFILE_UPDATE_FAIL,
  PROFILE_UPDATE_REQUEST,
  PROFILE_UPDATE_RESET,
  PROFILE_UPDATE_SUCCESS,
} from "../Constants/profileConstants";

export const profileListReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case PROFILE_LIST_REQUEST:
      return { loading: true };
    case PROFILE_LIST_SUCCESS:
      return { loading: false, profileRecs: payload };
    case PROFILE_LIST_FAIL:
      return { loading: false, error: payload };
    default:
      return state;
  }
};

export const profileSaveReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case PROFILE_INSERT_REQUEST:
      return { loading: true };
    case PROFILE_INSERT_SUCCESS:
      return { loading: false, success: payload };
    case PROFILE_INSERT_FAIL:
      return { loading: false, error: payload };
    case PROFILE_INSERT_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const profileUpdateReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case PROFILE_UPDATE_REQUEST:
      return { loading: true };
    case PROFILE_UPDATE_SUCCESS:
      return { loading: false, success: payload };
    case PROFILE_UPDATE_FAIL:
      return { loading: false, error: payload };
    case PROFILE_UPDATE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const profileDeleteReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case PROFILE_DELETE_REQUEST:
      return { loading: true };
    case PROFILE_DELETE_SUCCESS:
      return { loading: false, success: payload };
    case PROFILE_DELETE_FAIL:
      return { loading: false, error: payload };
    case PROFILE_DELETE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};
