export const config = {
  "content-type": "application/json",
};

export function getLocalTime(date) {
  // console.log(date);
  var utc = new Date(date);
  // console.log(utc);
  // var offset = utc.getTimezoneOffset();
  // console.log(offset);
  // var local = new Date(utc.getTime() - offset * 60000);
  // console.log(local);
  return utc;
}
