export const PROFILE_LIST_REQUEST = 'PROFILE_LIST_REQUEST'
export const PROFILE_LIST_SUCCESS = 'PROFILE_LIST_SUCCESS'
export const PROFILE_LIST_FAIL = 'PROFILE_LIST_FAIL'

export const PROFILE_INSERT_REQUEST = 'PROFILE_INSERT_REQUEST'
export const PROFILE_INSERT_SUCCESS = 'PROFILE_INSERT_SUCCESS'
export const PROFILE_INSERT_FAIL = 'PROFILE_INSERT_FAIL'
export const PROFILE_INSERT_RESET = 'PROFILE_INSERT_RESET'

export const PROFILE_UPDATE_REQUEST = 'PROFILE_UPDATE_REQUEST'
export const PROFILE_UPDATE_SUCCESS = 'PROFILE_UPDATE_SUCCESS'
export const PROFILE_UPDATE_FAIL = 'PROFILE_UPDATE_FAIL'
export const PROFILE_UPDATE_RESET = 'PROFILE_UPDATE_RESET'

export const PROFILE_DELETE_REQUEST = 'PROFILE_DELETE_REQUEST'
export const PROFILE_DELETE_SUCCESS = 'PROFILE_DELETE_SUCCESS'
export const PROFILE_DELETE_FAIL = 'PROFILE_DELETE_FAIL'
export const PROFILE_DELETE_RESET = 'PROFILE_DELETE_RESET'
