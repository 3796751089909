export const DEAL_GET_ALL_REQUEST = 'DEAL_GET_ALL_REQUEST'
export const DEAL_GET_ALL_SUCCESS = 'DEAL_GET_ALL_SUCCESS'
export const DEAL_GET_ALL_FAIL = 'DEAL_GET_ALL_FAIL'
export const DEAL_GET_ALL_RESET = 'DEAL_GET_ALL_RESET'

export const DEAL_LIST_REQUEST = 'DEAL_LIST_REQUEST'
export const DEAL_LIST_SUCCESS = 'DEAL_LIST_SUCCESS'
export const DEAL_LIST_FAIL = 'DEAL_LIST_FAIL'
export const DEAL_LIST_RESET = 'DEAL_LIST_RESET'

export const DEAL_LIST_TOTAL_REQUEST = 'DEAL_LIST_TOTAL_REQUEST'
export const DEAL_LIST_TOTAL_SUCCESS = 'DEAL_LIST_TOTAL_SUCCESS'
export const DEAL_LIST_TOTAL_FAIL = 'DEAL_LIST_TOTAL_FAIL'
export const DEAL_LIST_TOTAL_RESET = 'DEAL_LIST_TOTAL_RESET'

export const DEAL_CREATE_REQUEST = 'DEAL_CREATE_REQUEST'
export const DEAL_CREATE_SUCCESS = 'DEAL_CREATE_SUCCESS'
export const DEAL_CREATE_FAIL = 'DEAL_CREATE_FAIL'
export const DEAL_CREATE_RESET = 'DEAL_CREATE_RESET'

export const DEAL_UPDATE_REQUEST = 'DEAL_UPDATE_REQUEST'
export const DEAL_UPDATE_SUCCESS = 'DEAL_UPDATE_SUCCESS'
export const DEAL_UPDATE_FAIL = 'DEAL_UPDATE_FAIL'
export const DEAL_UPDATE_RESET = 'DEAL_UPDATE_RESET'

export const DEAL_GET_REQUEST = 'DEAL_GET_REQUEST'
export const DEAL_GET_SUCCESS = 'DEAL_GET_SUCCESS'
export const DEAL_GET_FAIL = 'DEAL_GET_FAIL'
export const DEAL_GET_RESET = 'DEAL_GET_RESET'