export const TEAM_LIST_REQUEST = 'TEAM_LIST_REQUEST'
export const TEAM_LIST_SUCCESS = 'TEAM_LIST_SUCCESS'
export const TEAM_LIST_FAIL = 'TEAM_LIST_FAIL'

export const TEAM_GET_REQUEST = 'TEAM_GET_REQUEST'
export const TEAM_GET_SUCCESS = 'TEAM_GET_SUCCESS'
export const TEAM_GET_FAIL = 'TEAM_GET_FAIL'

export const TEAM_INSERT_REQUEST = 'TEAM_INSERT_REQUEST'
export const TEAM_INSERT_SUCCESS = 'TEAM_INSERT_SUCCESS'
export const TEAM_INSERT_FAIL = 'TEAM_INSERT_FAIL'
export const TEAM_INSERT_RESET = 'TEAM_INSERT_RESET'

export const TEAM_UPDATE_REQUEST = 'TEAM_UPDATE_REQUEST'
export const TEAM_UPDATE_SUCCESS = 'TEAM_UPDATE_SUCCESS'
export const TEAM_UPDATE_FAIL = 'TEAM_UPDATE_FAIL'
export const TEAM_UPDATE_RESET = 'TEAM_UPDATE_RESET'

export const TEAM_DELETE_REQUEST = 'TEAM_DELETE_REQUEST'
export const TEAM_DELETE_SUCCESS = 'TEAM_DELETE_SUCCESS'
export const TEAM_DELETE_FAIL = 'TEAM_DELETE_FAIL'
export const TEAM_DELETE_RESET = 'TEAM_DELETE_RESET'