// export const CourseJson = {
//   CourseName: "",
//   UniversityId: "",
//   Campus_Id: "",
//   Country_Id: "",
//   Campus: [],
//   Course_Url: "",
//   Intakes: "",
//   Duration: "",
//   Yearly_Tuition_Fees: "",
//   IELTS_Required: "",
//   PTE_Required: "",
//   TOEFL_Required: "",
//   GRE_Required: "",
//   GMAT_Required: "",
//   Duolingo_Required: "",
//   Entry_Requirement: "",
//   Application_Fees: "",
//   Application_Deadline: "",
//   Course_Type: "",
//   IELTS_Score: "",
//   Popular_Courses: "",
//   Global_Courses: "",
//   Au_NZ_Popular_Courses: "",
//   UK_IR_Popular_Courses: "",
//   DurationInMonths: "",
// };

export const CourseJson = {
  CourseName: "",
};

export const CourseFilterJson = {
  page_size: 25,
  page_no: 1,
  searchtxt: "",
  Country_Id: "",
  UniversityId: "",
  Course_Type: "",
  Prefered_Intake: "",
  Course_Duration: "",
  IELTS_Score: "",
  Course_Fees_From: "",
  Course_Fees_To: "",
};

export const Course_Create_Validation = (data) => {
  let error = [];

  if (
    data?.CourseName === "" ||
    data?.CourseName === undefined ||
    data?.CourseName === null
  ) {
    error.push("Course Name");
  }

  if (
    data?.UniversityId === "" ||
    data?.UniversityId === undefined ||
    data?.UniversityId == null
  ) {
    error.push("University");
  }

  if (error.length > 0) {
    return "Error " + error.join(",") + " required !";
  } else {
    return "success";
  }
};

export const Course_Table_Model = [
  "Delete",
  "Edit",
  "Course Name",
  // "Campus Name",
  "University Name",
  "Country",
  "Course Link",
  "Duration",
  "Yearly Fees",
  "Application Fee",
  "Application Deadline",
  // "IELTS Required",
  // "PTE Required",
  // "TOEFL Required",
  // "GRE Required",
  // "GMAT Required",
  // "Duolingo Required",
  // "IELTS Score",
  // "Course Type",
];

export const IELTS_SCORE_SELECT_MODEL = [
  4.0, 4.5, 5.0, 5.5, 6.0, 6.5, 7.0, 7.5, 8.0, 8.5, 9.0,
];

export const Month_Model = [
  { label: "Jan", value: "Jan" },
  { label: "Feb", value: "Feb" },
  { label: "March", value: "March" },
  { label: "Apr", value: "Apr" },
  { label: "May", value: "May" },
  { label: "Jun", value: "Jun" },
  { label: "Jul", value: "Jul" },
  { label: "Aug", value: "Aug" },
  { label: "Sept", value: "Sept" },
  { label: "Oct", value: "Oct" },
  { label: "Nov", value: "Nov" },
  { label: "Dec", value: "Dec" },
];

export const Duration_model = [
  { value: "1", name: "1 Year" },
  { value: "2", name: "2 Year" },
  { value: "3", name: "3 Year" },
  { value: "4", name: "4 Year" },
  { value: "4+", name: "4+ Year" },
];
