import SidePanel from "../../Components/SidePanel";
import TopBar from "../../Components/TopBar";
import { useDispatch, useSelector } from "react-redux";
import Meta from "../../Components/Meta";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GET_TIME_ZONE } from "../Components/Time";
import { contactJson } from "../../Models/ContactModels";
import { toastOptions } from "../../Models/ToastEditiorOption";
import { toast, ToastContainer } from "react-toastify";
import { MATERIAL_CREATE_RESET } from "../../Constants/materialConstants";
import { Package_Model } from "../../Models/PackageModel";
import { Package_List } from "../../Actions/PacakgeAction";
import Pagination from "../Components/Pagination";
import { FaRegEdit } from "react-icons/fa";
import SEDLoading from "../../Components/SEDLoading";
import { REMOVE_PACAKGE_RESET } from "../../Constants/packageConstant";
import DeletePopup from "../Components/DeletePoup";

export default function Package() {
  const [PacakgeEdit, setPacakgeEdit] = useState(false);
  const [PackageCreateData, setPackageCreateData] = useState(Package_Model);
  const [userInfo, setUserInfo] = useState("");
  const [OpenNext, setOpenNext] = useState(false);
  const [DeleteAlert, setDeleteAlert] = useState({ msg: "", Delete: () => "" });
  const [MobileFilterActive, setMobileFilterActive] = useState(false);
  const [createSec, setCreateSec] = useState(false);
  // const [showActiveSec, setShowActiveSec] = useState("primary");

  const [PackageFilter, setPackageFilter] = useState({
    page_size: 10,
    page_no: 1,
  });

  const Navigate = useNavigate();
  const mobile_View = window.innerWidth;
  const { loading, error, PackageRec, total } = useSelector(
    (s) => s.Package_List_Reducer
  );

  const {
    loading: loadingRmv,
    success: successRmv,
    error: errorRmv,
  } = useSelector((s) => s.removePackageReducer);

  const handleCancel = () => {
    Navigate("/Pacakges");
  };

  const {
    loading: loadingsv,
    error: errorsv,
    success: successsv,
  } = useSelector((s) => s.createMaterialReducer);

  const dispatch = useDispatch();
  const MetaDes = { title: "Testfify - Packages" };

  const Get_Packages = useCallback(() => {
    dispatch(Package_List(PackageFilter));
  }, [dispatch, PackageFilter]);

  // const handleRemove = (e, Id) => {
  //   e.preventDefault();
  //   dispatch(removePackage(Id));
  // };

  useEffect(() => {
    Get_Packages();
  }, [Get_Packages]);

  useEffect(() => {
    if (successsv) {
      setCreateSec(false);
      setPackageCreateData(contactJson);
      toast.success(successsv, toastOptions);
      Get_Packages();
      setTimeout(() => {
        dispatch({ type: MATERIAL_CREATE_RESET });
      }, 2000);
    }
    if (errorsv) {
      toast.success(errorsv, toastOptions);
    }
  }, [successsv, errorsv,dispatch, Get_Packages]);

  useEffect(() => {
    if (successRmv) {
      toast.success(successRmv, toastOptions);
      Get_Packages();
      setTimeout(() => {
        dispatch({ type: REMOVE_PACAKGE_RESET });
      }, 2000);
    }

    if (errorRmv) {
      toast.error(errorRmv, toastOptions);
    }
  }, [successRmv,dispatch, Get_Packages,errorRmv]);

  return (
    <>
      <Meta MetaDes={MetaDes} />
      <SidePanel page={"Packages"} />
      <section className="home-section">
        <TopBar Heading="Package" />
        {loadingRmv || loading ? <SEDLoading /> : ""}
        <div className="home-content" style={{ paddingTop: "70px" }}>
          <div className="Contact-Middle">
            <div className="Contact_Top_Header">
              <div className="home-title float-left"></div>
              <div style={{ display: "flex", gap: "20px" }} className="">
                <div className="input-group search-contacts">
                  <input
                    type="Search"
                    className="form-control"
                    name="searchtxt"
                    style={{ display: "block" }}
                    // value={Search}
                    // onChange={(e) => {
                    //   if (e.target.value === "") {
                    //     setFilterData({ ...FilterData, searchtxt: "" });
                    //     setPackageFilter({
                    //       ...PackageFilter,
                    //       searchtxt: "",
                    //     });
                    //   }
                    //   setSearch(e.target.value);
                    // }}
                    // onKeyDown={(e) => {
                    //   if (
                    //     e.key === "Enter" &&
                    //     Search !== "" &&
                    //     Search !== undefined
                    //   ) {
                    //     setFilterData({ ...FilterData, searchtxt: Search });
                    //     setPackageFilter({
                    //       ...PackageFilter,
                    //       searchtxt: Search,
                    //     });
                    //   }
                    // }}
                    placeholder="search for packages"
                  />
                  <i
                    className="fa fa-search"
                    // onClick={() => {
                    //   if (Search !== "" && Search !== undefined) {
                    //     setFilterData({ ...FilterData, searchtxt: Search });
                    //     setPackageFilter({
                    //       ...PackageFilter,
                    //       searchtxt: Search,
                    //     });
                    //   }
                    // }}
                    aria-hidden="true"
                  ></i>
                </div>{" "}
                <div className="right-click Create-btn">
                  <a
                    style={{ width: "180px" }}
                    className="btn create-contact"
                    onClick={(e) => {
                      setPackageCreateData({
                        ...PackageCreateData,
                        Assigned_User_Id: userInfo,
                      });
                      Navigate("/Package/create", "_blank");
                    }}
                  >
                    Create Package
                  </a>
                </div>
                <div className="right-click Create-btn">
                  <a
                    style={{ width: "180px" }}
                    className="btn create-contact"
                    onClick={(e) => {
                      Navigate("/Package/cp", "_blank");
                    }}
                  >
                    CP
                  </a>
                </div>
              </div>
            </div>

            {window.innerWidth <= 480 ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginLeft: "-10px",
                }}
              >
                <div className=" Create-btn">
                  <a
                    style={{ width: "180px" }}
                    className="btn create-contact"
                    onClick={(e) => {
                      setPackageCreateData({
                        ...PackageCreateData,
                        Assigned_User_Id: userInfo,
                      });
                      Navigate("/Package/create", "_blank");
                    }}
                  >
                    Create Package
                  </a>
                </div>
                <div className=" Create-btn">
                  <a
                    style={{ width: "180px" }}
                    className="btn create-contact"
                    onClick={(e) => {
                      Navigate("/Package/cp", "_blank");
                    }}
                  >
                    CP
                  </a>
                </div>
              </div>
            ) : (
              ""
            )}
            <hr />
            <div className="row">
              <div className="col-md-4 col-lg-3">
                <div
                  style={
                    mobile_View <= 480
                      ? { marginBottom: "20px" }
                      : { cursor: "auto" }
                  }
                  className="add-filter"
                >
                  <div
                    className={
                      MobileFilterActive
                        ? "filter-title  filter-title-mob filter-body-active"
                        : "filter-title  filter-title-mob"
                    }
                  >
                    <h5>Filter Package</h5>
                    <button
                      onClick={() => setMobileFilterActive(false)}
                      className="mob-nav-open"
                    >
                      ☰
                    </button>
                  </div>

                  <div
                    className={
                      MobileFilterActive
                        ? "filter-body filter-body-mob  filter-body-active custom-scroll-right pt-0"
                        : "filter-body filter-body-mob custom-scroll-right pt-0"
                    }
                  >
                    <div className="sticky pt-2">
                      <p style={{ marginBottom: "-20px" }}>
                        showing {total ? total : 0} Packages
                      </p>

                      <br />

                      <button
                        style={{ color: "white" }}
                        className="btn btn-sm btn-deep-orange"
                        onClick={() => {
                          setPackageFilter({
                            ...PackageFilter,
                          });
                        }}
                      >
                        Clear All
                      </button>
                    </div>
                    <ul className="modify-filter-title">
                      {/*                       
                      <li className="chose">Qualifies</li>
                      <li>
                        <QualifiesMultiSelectDropdown
                          values={
                            PackageFilter?.Qualifies
                              ? PackageFilter?.Qualifies
                              : []
                          }
                          handleChange={(e) => {
                            setPackageFilter({
                              ...PackageFilter,
                              Qualifies: e,
                            });
                          }}
                        />
                      </li>
                      <li className="chose">Contact Rating</li>
                      <li>
                        <ContactRatingMultiSelectDropdown
                          values={
                            PackageFilter?.Contact_Rating
                              ? PackageFilter?.Contact_Rating
                              : []
                          }
                          handleChange={(e) => {
                            setPackageFilter({
                              ...PackageFilter,
                              Contact_Rating: e,
                            });
                          }}
                        />
                      </li>
                      <li className="chose">Team</li>
                      <li>
                        <TeamMultiSelctDropdown
                          values={
                            PackageFilter?.Team_Id
                              ? PackageFilter?.Team_Id
                              : []
                          }
                          handleChange={(e) => {
                            setPackageFilter({
                              ...PackageFilter,
                              Team_Id: e,
                            });
                          }}
                        />
                      </li>
                      <li className="chose">Educational Status</li>
                      <li>
                        <EducationalStatusMultiSelectDropdown
                          values={
                            PackageFilter?.Educational_Status
                              ? PackageFilter?.Educational_Status
                              : []
                          }
                          handleChange={(e) => {
                            setPackageFilter({
                              ...PackageFilter,
                              Educational_Status: e,
                            });
                          }}
                        />
                      </li>
                      <li className="chose">Create Date</li>
                      <li>
                        <div className="myDatePickerContainer">
                          <ReactDatePicker
                            onKeyDown={(e) => {
                              e.preventDefault();
                            }}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            yearDropdownItemNumber={100}
                            scrollableYearDropdown
                            dropdownMode="scroll"
                            dateFormat="dd-MM-yyyy"
                            className="myDatePicker"
                            style={{ width: "100%" }}
                            selected={PackageFilter.Create_Date_From}
                            onChange={(date) => {
                              //   setFilterData({
                              //     ...FilterData,
                              //     Create_Date_From: date,
                              //   });

                              setPackageFilter({
                                ...PackageFilter,
                                Create_Date_From: date,
                              });
                            }}
                            placeholderText="dd/mm/yy"
                          />
                        </div>
                      </li>
                      <li style={{ textAlign: "center" }}>to</li>
                      <li>
                        <div className="myDatePickerContainer">
                          <ReactDatePicker
                            onKeyDown={(e) => {
                              e.preventDefault();
                            }}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            yearDropdownItemNumber={100}
                            scrollableYearDropdown
                            dropdownMode="scroll"
                            dateFormat="dd-MM-yyyy"
                            className="myDatePicker"
                            selected={PackageFilter.Create_Date_To}
                            onChange={(date) => {
                              //   setFilterData({
                              //     ...FilterData,
                              //     Create_Date_To: date,
                              //   });
                              setPackageFilter({
                                ...PackageFilter,
                                Create_Date_To: date,
                              });
                            }}
                            placeholderText="dd/mm/yy"
                          />
                        </div>
                      </li>
                      <li className="chose">Academic Percentage</li>
                      <li>
                        <div className="row">
                          <div className="col-md-6">
                            <select
                              name="AcademicPercentage_From"
                              value={PackageFilter.AcademicPercentage_From}
                              onChange={handleFilterChange}
                              className="form-control"
                            >
                              <option value="">--Select--</option>
                              <PercentageDropdown start="40" end="95" />
                            </select>
                          </div>
                          <div className="col-md-6">
                            <select
                              name="AcademicPercentage_To"
                              onChange={handleFilterChange}
                              value={PackageFilter.AcademicPercentage_To}
                              className="form-control"
                            >
                              <option value="">--Select--</option>
                              <PercentageDropdown start="45" end="100" />
                            </select>
                          </div>
                        </div>
                      </li>
                      <li className="chose">Preferred Intake</li>
                      <li>
                        <PreferredIntakeMultiSelectDropdown
                          values={
                            PackageFilter?.Preferred_Intake
                              ? PackageFilter?.Preferred_Intake
                              : []
                          }
                          handleChange={(e) =>
                            setPackageFilter({
                              ...PackageFilter,
                              Preferred_Intake: e,
                            })
                          }
                        />
                      </li>
                      <li className="chose">Intake Year</li>
                      <li>
                        <YearIntakeMultiSelectDropdown
                          values={
                            PackageFilter?.Intake_Year
                              ? PackageFilter?.Intake_Year
                              : []
                          }
                          handleChange={(e) =>
                            setPackageFilter({
                              ...PackageFilter,
                              Intake_Year: e,
                            })
                          }
                        />
                      </li>
                      <li className="chose">First Activity Date</li>
                      <li>
                        <div className="myDatePickerContainer">
                          <ReactDatePicker
                            onKeyDown={(e) => {
                              e.preventDefault();
                            }}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            yearDropdownItemNumber={100}
                            scrollableYearDropdown
                            dropdownMode="scroll"
                            dateFormat="dd-MM-yyyy"
                            className="myDatePicker"
                            selected={
                              PackageFilter.First_Activity_Date_From
                            }
                            onChange={(date) => {
                              //   setFilterData({
                              //     ...FilterData,
                              //     First_Activity_Date_From: date,
                              //   });
                              setPackageFilter({
                                ...PackageFilter,
                                First_Activity_Date_From: date,
                              });
                            }}
                            placeholderText="dd/mm/yy"
                          />
                        </div>
                      </li>
                      <li style={{ textAlign: "center" }}>to</li>
                      <li>
                        <div className="myDatePickerContainer">
                          <ReactDatePicker
                            onKeyDown={(e) => {
                              e.preventDefault();
                            }}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            yearDropdownItemNumber={100}
                            scrollableYearDropdown
                            dropdownMode="scroll"
                            dateFormat="dd-MM-yyyy"
                            className="myDatePicker"
                            selected={PackageFilter.First_Activity_Date_To}
                            onChange={(date) => {
                              //   setFilterData({
                              //     ...FilterData,
                              //     First_Activity_Date_To: date,
                              //   });
                              setPackageFilter({
                                ...PackageFilter,
                                First_Activity_Date_To: date,
                              });
                            }}
                            placeholderText="dd/mm/yy"
                          />
                        </div>
                      </li>
                      <li className="chose">Last Activity Date</li>
                      <li>
                        <div className="myDatePickerContainer">
                          <ReactDatePicker
                            onKeyDown={(e) => {
                              e.preventDefault();
                            }}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            yearDropdownItemNumber={100}
                            scrollableYearDropdown
                            dropdownMode="scroll"
                            dateFormat="dd-MM-yyyy"
                            className="myDatePicker"
                            selected={PackageFilter.Last_Activity_Date_From}
                            onChange={(date) => {
                              //   setFilterData({
                              //     ...FilterData,
                              //     Last_Activity_Date_From: date,
                              //   });

                              setPackageFilter({
                                ...PackageFilter,
                                Last_Activity_Date_From: date,
                              });
                            }}
                            placeholderText="dd/mm/yy"
                          />
                        </div>
                      </li>
                      <li style={{ textAlign: "center" }}>to</li>
                      <li>
                        <div className="myDatePickerContainer">
                          <ReactDatePicker
                            onKeyDown={(e) => {
                              e.preventDefault();
                            }}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            yearDropdownItemNumber={100}
                            scrollableYearDropdown
                            dropdownMode="scroll"
                            dateFormat="dd-MM-yyyy"
                            className="myDatePicker"
                            selected={PackageFilter.Last_Activity_Date_To}
                            onChange={(date) => {
                              //   setFilterData({
                              //     ...FilterData,
                              //     Last_Activity_Date_To: date,
                              //   });

                              setPackageFilter({
                                ...PackageFilter,
                                Last_Activity_Date_To: date,
                              });
                            }}
                            placeholderText="dd/mm/yy"
                          />
                        </div>
                      </li> */}
                      {/* <li className="chose">Profile</li>
                      <li>
                        <ProfileMultiSelectDropdown
                          values={
                            PackageFilter?.Profile_Status
                              ? PackageFilter?.Profile_Status
                              : []
                          }
                          handleChange={(e) =>
                            setPackageFilter({
                              ...PackageFilter,
                              Profile_Status: e,
                            })
                          }
                        />
                      </li>
                      <li className="chose">Enquiry For</li> */}
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-md-8 col-lg-9">
                <div
                  style={{ maxHeight: "60vh" }}
                  className="Contact-table-wrapper"
                >
                  <table>
                    <thead>
                      <tr className="sticky">
                        <th>Edit</th>
                        {/* <th>Delete</th> */}
                        <th>Package Name</th>
                        <th>Package Detail</th>
                        <th>Package Description</th>
                        {/* <th>Course</th> */}
                        <th>Actual Cost</th>
                        <th>Discounted Price</th>
                        <th>Timings</th>
                        <th>Duration</th>
                        <th>Sub Category</th>
                        <th>Created </th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <tr>
                          <td colSpan={10}>Laoding... </td>
                        </tr>
                      ) : error ? (
                        <tr>
                          <td colSpan={10}>{error}</td>
                        </tr>
                      ) : (
                        PackageRec &&
                        PackageRec?.map((ev) => (
                          <tr key={ev._id}>
                            <td
                              onClick={() =>
                                Navigate(`/package/create?PackageId=${ev?._id}`)
                              }
                              style={{ cursor: "pointer", color: "blue" }}
                            >
                              <FaRegEdit />
                            </td>

                            <td> {ev?.Name} </td>
                            <td>{ev?.Detail}</td>
                            <td>
                              <div
                                contentEditable="false"
                                dangerouslySetInnerHTML={{
                                  __html: ev?.Description,
                                }}
                              ></div>
                            </td>
                            <td>{ev?.ActualCost}</td>
                            <td>{ev?.DiscountedPrice}</td>
                            <td>{ev?.Timings}</td>
                            <td>{ev?.Duration}</td>
                            <td>{ev?.SubCategory}</td>
                            <td>{GET_TIME_ZONE(ev?.createdAt)}</td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
                <div>
                  <Pagination
                    setFilterQuery={setPackageFilter}
                    FilterQuery={PackageFilter}
                    Total={total}
                  />
                </div>

                {/* Package Create section */}

                <div
                  id="CreatContact"
                  className={createSec ? "overlay-note w-100" : "overlay-note"}
                >
                  <div className="overlay-note-content mblVwe">
                    <div className="note-title">
                      <h4 className="text-left text-white pl-3">
                        {PacakgeEdit
                          ? "Update Package Details"
                          : "Create Package "}
                      </h4>
                      <a
                        className="closebtn text-right text-white"
                        onClick={(e) => handleCancel()}
                      >
                        ×
                      </a>
                    </div>
                  </div>
                </div>
                <DeletePopup
                  OpenNext={OpenNext}
                  setOpenNext={setOpenNext}
                  DeleteData={DeleteAlert}
                />
                <ToastContainer />
                {/* Package Create section end */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
