export const toolbarOptions = {
  container: [[{ header: [2, false] }], ["bold", "italic", "underline"]],
  handlers: {},
};

export const toolbarOptions2 = {
  container: [[{ header: [2, false] }], ["bold", "italic", "underline"]],
  handlers: {},
};

export const toolbarOptions3 = {
  container: [],
  handlers: {},
};

export const toastOptions = {
  position: "bottom-right",
  autoClose: 4000,
  pauseOnHover: true,
  draggable: true,
  theme: "dark",
};
