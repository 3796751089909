import {
  CREATE_PACAKGE_FAILED,
  CREATE_PACAKGE_REQ,
  CREATE_PACAKGE_RESET,
  CREATE_PACAKGE_SUCCESS,
  PACKAGE_DETAILS_FAILED,
  PACKAGE_DETAILS_REQ,
  PACKAGE_DETAILS_RESET,
  PACKAGE_DETAILS_SUCCESS,
  PACKAGE_LIST_FAILED,
  PACKAGE_LIST_REQ,
  PACKAGE_LIST_RESET,
  PACKAGE_LIST_SUCCESS,
  PACKAGE_TYPE_LIST_FAILED,
  PACKAGE_TYPE_LIST_REQ,
  PACKAGE_TYPE_LIST_SUCCESS,
  REMOVE_PACAKGE_FAILED,
  REMOVE_PACAKGE_REQ,
  REMOVE_PACAKGE_RESET,
  REMOVE_PACAKGE_SUCCESS,
  UPDATE_PACAKGE_FAILED,
  UPDATE_PACAKGE_REQ,
  UPDATE_PACAKGE_RESET,
  UPDATE_PACAKGE_SUCCESS,
} from "../Constants/packageConstant";

export const Package_Create_Reducer = (state = {}, { type, payload }) => {
  switch (type) {
    case CREATE_PACAKGE_REQ: {
      return { loading: true };
    }

    case CREATE_PACAKGE_SUCCESS: {
      return { loading: false, success: payload };
    }

    case CREATE_PACAKGE_FAILED: {
      return { loading: false, error: payload };
    }

    case CREATE_PACAKGE_RESET: {
      return { loading: false, error: "", success: "" };
    }

    default:
      return state;
  }
};

export const Package_List_Reducer = (state = {}, { type, payload, Total }) => {
  switch (type) {
    case PACKAGE_LIST_REQ: {
      return { loading: true };
    }

    case PACKAGE_LIST_SUCCESS: {
      return { loading: false, PackageRec: payload, total: Total };
    }
    case PACKAGE_LIST_FAILED: {
      return { loading: false, error: payload };
    }

    case PACKAGE_LIST_RESET: {
      return {};
    }

    default:
      return state;
  }
};

export const updatePackageReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case UPDATE_PACAKGE_REQ: {
      return { loading: true };
    }

    case UPDATE_PACAKGE_SUCCESS: {
      return { loading: false, success: payload };
    }

    case UPDATE_PACAKGE_FAILED: {
      return { loading: false, error: payload };
    }

    case UPDATE_PACAKGE_RESET: {
      return { loading: false, error: "", success: "" };
    }

    default:
      return state;
  }
};

export const packageDetailsReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case PACKAGE_DETAILS_REQ: {
      return { loading: true };
    }

    case PACKAGE_DETAILS_SUCCESS: {
      return { loading: false, DetailsPack: payload };
    }

    case PACKAGE_DETAILS_FAILED: {
      return { loading: false, error: payload };
    }

    case PACKAGE_DETAILS_RESET: {
      return { loading: false, success: "", error: "" };
    }

    default:
      return state;
  }
};

export const Package_Type_Data_Reducer = (state = {}, { type, payload }) => {
  switch (type) {
    case PACKAGE_TYPE_LIST_REQ: {
      return { loading: true };
    }

    case PACKAGE_TYPE_LIST_SUCCESS: {
      return { loading: false, PackageTypeRec: payload };
    }

    case PACKAGE_TYPE_LIST_FAILED: {
      return { loading: false, error: payload };
    }

    default:
      return state;
  }
};

export const removePackageReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case REMOVE_PACAKGE_REQ: {
      return { loading: true };
    }

    case REMOVE_PACAKGE_SUCCESS: {
      return { loading: false, success: payload };
    }

    case REMOVE_PACAKGE_FAILED: {
      return { loading: false, error: payload };
    }

    case REMOVE_PACAKGE_RESET: {
      return { loading: false, success: "", error: "" };
    }
    default:
      return state;
  }
};
