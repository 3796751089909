import {
  EMAIL_LOG_DELETE_FAIL,
  EMAIL_LOG_DELETE_REQUEST,
  EMAIL_LOG_DELETE_SUCCESS,
  EMAIL_LOG_INSERT_FAIL,
  EMAIL_LOG_INSERT_REQUEST,
  EMAIL_LOG_INSERT_SUCCESS,
  EMAIL_LOG_CONTACT_LIST_FAIL,
  EMAIL_LOG_CONTACT_LIST_REQUEST,
  EMAIL_LOG_CONTACT_LIST_SUCCESS,
  EMAIL_LOG_DEAL_LIST_FAIL,
  EMAIL_LOG_DEAL_LIST_REQUEST,
  EMAIL_LOG_DEAL_LIST_SUCCESS,
  EMAIL_LOG_UPDATE_FAIL,
  EMAIL_LOG_UPDATE_REQUEST,
  EMAIL_LOG_UPDATE_SUCCESS,
  EMAIL_LOG_SUBAGENT_LIST_SUCCESS,
  EMAIL_LOG_SUBAGENT_LIST_REQUEST,
  EMAIL_LOG_SUBAGENT_LIST_FAIL,
} from "../Constants/emailLogConstants";
import api from "../core/api";

export const listEmailLogContact = (id) => async (dispatch) => {
  try {
    dispatch({ type: EMAIL_LOG_CONTACT_LIST_REQUEST });
    const { data } = await api.get(`/contact/emaillog/${id}`);
    dispatch({
      type: EMAIL_LOG_CONTACT_LIST_SUCCESS,
      payload: data?.Result,
    });
  } catch (error) {
    dispatch({
      type: EMAIL_LOG_CONTACT_LIST_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const listEmailLogDeal = (id) => async (dispatch) => {
  try {
    dispatch({ type: EMAIL_LOG_DEAL_LIST_REQUEST });
    const { data } = await api.get(`/emaillog/getDealRecords/${id}`);
    dispatch({
      type: EMAIL_LOG_DEAL_LIST_SUCCESS,
      payload: data.Result,
    });
  } catch (error) {
    dispatch({
      type: EMAIL_LOG_DEAL_LIST_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const listEmailLogSubagent = (id) => async (dispatch) => {
  try {
    dispatch({ type: EMAIL_LOG_SUBAGENT_LIST_REQUEST });
    const { data } = await api.get(`/emaillog/getSubAgentRecords/${id}`);
    dispatch({
      type: EMAIL_LOG_SUBAGENT_LIST_SUCCESS,
      payload: data.Result,
    });
  } catch (error) {
    dispatch({
      type: EMAIL_LOG_SUBAGENT_LIST_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const saveEmailLogDetails = (EmailLogJson) => async (dispatch) => {
  try {
    dispatch({ type: EMAIL_LOG_INSERT_REQUEST });
    const { data } = await api.post(`/contact/emaillog`, EmailLogJson);
    dispatch({
      type: EMAIL_LOG_INSERT_SUCCESS,
      payload: data.Result,
    });
  } catch (error) {
    dispatch({
      type: EMAIL_LOG_INSERT_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const updateEmailLogDetails = (id, EmailLogjson) => async (dispatch) => {
  try {
    dispatch({ type: EMAIL_LOG_UPDATE_REQUEST });
    const { data } = await api.put(`/contact/emaillog/${id}`, EmailLogjson);
    dispatch({
      type: EMAIL_LOG_UPDATE_SUCCESS,
      payload: data.Result,
    });
  } catch (error) {
    dispatch({
      type: EMAIL_LOG_UPDATE_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const deleteEmailLogDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: EMAIL_LOG_DELETE_REQUEST });
    const { data } = await api.delete(`/contact/emaillog/${id}`);
    dispatch({
      type: EMAIL_LOG_DELETE_SUCCESS,
      payload: data.Result,
    });
  } catch (error) {
    dispatch({
      type: EMAIL_LOG_DELETE_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};
