import {
  HIERARCHY_DELETE_FAIL,
  HIERARCHY_DELETE_REQUEST,
  HIERARCHY_DELETE_RESET,
  HIERARCHY_DELETE_SUCCESS,
  HIERARCHY_INSERT_FAIL,
  HIERARCHY_INSERT_REQUEST,
  HIERARCHY_INSERT_RESET,
  HIERARCHY_INSERT_SUCCESS,
  HIERARCHY_LIST_FAIL,
  HIERARCHY_LIST_REQUEST,
  HIERARCHY_LIST_SUCCESS,
  HIERARCHY_UPDATE_FAIL,
  HIERARCHY_UPDATE_REQUEST,
  HIERARCHY_UPDATE_RESET,
  HIERARCHY_UPDATE_SUCCESS,
} from "../Constants/HierarchyConstants";

export const hierarchyListReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case HIERARCHY_LIST_REQUEST:
      return { loading: true };
    case HIERARCHY_LIST_SUCCESS:
      return { loading: false, hierarchyRecs: payload };
    case HIERARCHY_LIST_FAIL:
      return { loading: false, error: payload };
    default:
      return state;
  }
};

export const hierarchySaveReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case HIERARCHY_INSERT_REQUEST:
      return { loading: true };
    case HIERARCHY_INSERT_SUCCESS:
      return { loading: false, success: payload };
    case HIERARCHY_INSERT_FAIL:
      return { loading: false, error: payload };
    case HIERARCHY_INSERT_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const hierarchyUpdateReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case HIERARCHY_UPDATE_REQUEST:
      return { loading: true };
    case HIERARCHY_UPDATE_SUCCESS:
      return { loading: false, success: payload };
    case HIERARCHY_UPDATE_FAIL:
      return { loading: false, error: payload };
    case HIERARCHY_UPDATE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const hierarchyDeleteReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case HIERARCHY_DELETE_REQUEST:
      return { loading: true };
    case HIERARCHY_DELETE_SUCCESS:
      return { loading: false, success: payload };
    case HIERARCHY_DELETE_FAIL:
      return { loading: false, error: payload };
    case HIERARCHY_DELETE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};
