import {
  applyMiddleware,
  combineReducers,
  compose,
  legacy_createStore,
} from "redux";
import thunk from "redux-thunk";
import { Sidebar_Reducer } from "./Reducers/SidebarReducer";

import { GET_LOCAL } from "./Models/LocalData";

import {
  loginUsersReducers,
  userChangePasswordReducers,
  userDetailsReducers,
  userForgotPasswordReducers,
  userInternalDetailsReducers,
  userResetPasswordReducers,
  userSaveReducers,
  userUpdateActiveStatusReducers,
  userUpdateBlockedStatusReducers,
  userUpdateIntDetailsReducers,
  userUpdateProfileReducers,
  userVerifyTknReducers,
  usersListReducers,
} from "./Reducers/userReducers";

import {
  commonHeaderReducer,
  getOffsetReducer,
} from "./Reducers/commonReducers";

import {
  monthDeleteReducer,
  monthInsertReducer,
  monthListReducer,
  monthUpdateReducer,
} from "./Reducers/monthReducers";

import {
  notificationCreateReducer,
  notificationGetReducer,
} from "./Reducers/notificationReducers";

import {
  dashboardReminderListReducer,
  taskReminderTotalReducer,
} from "./Reducers/taskReducers";

import {
  qualifiesDeleteReducer,
  qualifiesListReducer,
  qualifiesSaveReducer,
  qualifiesUpdateReducer,
} from "./Reducers/qualifiesReducers";

import {
  countryDeleteReducer,
  countryListReducer,
  countrySaveReducer,
  countryUpdateReducer,
} from "./Reducers/countryReducers";

import {
  applicationStatusDeleteReducer,
  applicationStatusDetailReducer,
  applicationStatusListReducer,
  applicationStatusSaveReducer,
  applicationStatusUpdateReducer,
} from "./Reducers/applicationStatusReducers";

import {
  contactPlatformDeleteReducer,
  contactPlatformDetailReducer,
  contactPlatformListReducer,
  contactPlatformSaveReducer,
  contactPlatformUpdateReducer,
} from "./Reducers/platformReducers";

import {
  campaignDetailReducer,
  campaignListReducer,
  campaignSaveReducer,
  campaignUpdateReducer,
  campaignUpdateStatusReducer,
} from "./Reducers/campaignReducers";

import {
  pipelineDeleteReducer,
  pipelineDetailReducer,
  pipelineListReducer,
  pipelineSaveReducer,
  pipelineUpdateReducer,
} from "./Reducers/pipelineReducers";

import {
  callOutcomeDeleteReducer,
  callOutcomeListReducer,
  callOutcomeSaveReducer,
  callOutcomeUpdateReducer,
} from "./Reducers/callOutcomeReducers";

import {
  categoryOfStudyDeleteReducer,
  categoryOfStudyListReducer,
  categoryOfStudySaveReducer,
  categoryOfStudyUpdateReducer,
} from "./Reducers/categoryOfStudyReducers";

import {
  confirmationOfAcceptanceDeleteReducer,
  confirmationOfAcceptanceListReducer,
  confirmationOfAcceptanceSaveReducer,
  confirmationOfAcceptanceUpdateReducer,
} from "./Reducers/ConfirmationOfAcceptance";

import {
  crmUpdatesCreateReducers,
  crmUpdatesDeleteReducers,
  crmUpdatesListReducers,
  crmUpdatesUpdateReducers,
} from "./Reducers/crmUpdates";

import {
  departmentDeleteReducer,
  departmentListReducer,
  departmentSaveReducer,
  departmentUpdateReducer,
} from "./Reducers/departmentReducers";

import {
  hierarchyDeleteReducer,
  hierarchyListReducer,
  hierarchySaveReducer,
  hierarchyUpdateReducer,
} from "./Reducers/HierarchyReducer";

import {
  fbTokenGetReducer,
  fbTokenSaveReducer,
} from "./Reducers/fbTokensReducer";

import {
  financialSourceDeleteReducer,
  financialSourceListReducer,
  financialSourceSaveReducer,
  financialSourceUpdateReducer,
} from "./Reducers/financialSourceReducers";

import {
  genderDeleteReducer,
  genderListReducer,
  genderSaveReducer,
  genderUpdateReducer,
} from "./Reducers/genderReducers";

import {
  howyouheardaboutusDeleteReducer,
  howyouheardaboutusListReducer,
  howyouheardaboutusSaveReducer,
  howyouheardaboutusUpdateReducer,
} from "./Reducers/Howyouheardaboutus";

import {
  interestedFieldOfStudyDeleteReducer,
  interestedFieldOfStudyListReducer,
  interestedFieldOfStudySaveReducer,
  interestedFieldOfStudyUpdateReducer,
} from "./Reducers/interestedFieldOfStudyReducers";

import {
  interestedLevelOfStudyDeleteReducer,
  interestedLevelOfStudyListReducer,
  interestedLevelOfStudySaveReducer,
  interestedLevelOfStudyUpdateReducer,
} from "./Reducers/interestedLevelOfStudyReducers";

import {
  leadSourceDeleteReducer,
  leadSourceListReducer,
  leadSourceSaveReducer,
  leadSourceUpdateReducer,
} from "./Reducers/leadSourceReducers";

import {
  mediumOfEventDeleteReducer,
  mediumOfEventListReducer,
  mediumOfEventSaveReducer,
  mediumOfEventUpdateReducer,
} from "./Reducers/mediumOfEventReducers";

import {
  meetingInterfaceDeleteReducer,
  meetingInterfaceListReducer,
  meetingInterfaceSaveReducer,
  meetingInterfaceUpdateReducer,
} from "./Reducers/meetingInterfaceReducers";

import {
  modeOfPaymentDeleteReducer,
  modeOfPaymentListReducer,
  modeOfPaymentSaveReducer,
  modeOfPaymentUpdateReducer,
} from "./Reducers/modeOfPaymentReducers";

import {
  offerAcceptedDeleteReducer,
  offerAcceptedListReducer,
  offerAcceptedSaveReducer,
  offerAcceptedUpdateReducer,
} from "./Reducers/offerAcceptedReducers";

import {
  preferredIntakeDeleteReducer,
  preferredIntakeListReducer,
  preferredIntakeSaveReducer,
  preferredIntakeUpdateReducer,
} from "./Reducers/preferredIntakeReducers";

import {
  priorityDeleteReducer,
  priorityListReducer,
  prioritySaveReducer,
  priorityUpdateReducer,
} from "./Reducers/priorityReducers";

import {
  profileDeleteReducer,
  profileListReducer,
  profileSaveReducer,
  profileUpdateReducer,
} from "./Reducers/profileReducers";

import {
  reasonDidNotQualifyDeleteReducer,
  reasonDidNotQualifyListReducer,
  reasonDidNotQualifySaveReducer,
  reasonDidNotQualifyUpdateReducer,
} from "./Reducers/reasonDidNotQualifyReducers";

import {
  resumeDeleteReducer,
  resumeListReducer,
  resumeSaveReducer,
  resumeUpdateReducer,
} from "./Reducers/resumeReducers";

import {
  referralTypeDeleteReducer,
  referralTypeListReducer,
  referralTypeSaveReducer,
  referralTypeUpdateReducer,
} from "./Reducers/referralReducers";

import {
  studyLevelDeleteReducer,
  studyLevelListReducer,
  studyLevelSaveReducer,
  studyLevelUpdateReducer,
} from "./Reducers/studyLevelReducers";

import {
  subscribeDeleteReducer,
  subscribeListReducer,
  subscribeSaveReducer,
  subscribeUpdateReducer,
} from "./Reducers/SubscribeReducers";

import {
  subscribedLeadsDeleteReducer,
  subscribedLeadsListReducer,
  subscribedLeadsSaveReducer,
  subscribedLeadsUpdateReducer,
} from "./Reducers/subscribedLeadsReducers";

import {
  userRoleDeleteReducer,
  userRoleListReducer,
  userRoleSaveReducer,
  userRoleUpdateReducer,
} from "./Reducers/userRoleReducers";

import {
  visaStatusDeleteReducer,
  visaStatusListReducer,
  visaStatusSaveReducer,
  visaStatusUpdateReducer,
} from "./Reducers/visaStatusReducers";

import {
  websitePagesDeleteReducer,
  websitePagesListReducer,
  websitePagesSaveReducer,
  websitePagesUpdateReducer,
} from "./Reducers/websitePagesReducers";
import {
  contactRatingCreateReducers,
  contactRatingDeleteReducers,
  contactRatingListReducers,
  contactRatingUpdateReducers,
} from "./Reducers/contactRatingReducers";
import {
  CheckedDocumetListReducer,
  createCheckedDocumetListReducer,
  documentDeleteReducer,
  documentListReducer,
  documentSaveReducer,
  documentUpdateReducer,
} from "./Reducers/documentListReducer";
import {
  educationalStatusDeleteReducer,
  educationalStatusInsertReducer,
  educationalStatusListReducer,
  educationalStatusUpdateReducer,
} from "./Reducers/educationalStatusReducers";
import {
  englishTestAttemptedDeleteReducer,
  englishTestAttemptedListReducer,
  englishTestAttemptedSaveReducer,
  englishTestAttemptedUpdateReducer,
} from "./Reducers/englishTestReducers";
import {
  enquiryForDeleteReducer,
  enquiryForReducer,
  enquiryForSaveReducer,
  enquiryForUpdateReducer,
} from "./Reducers/enquiryForReducer";
import {
  maritalStatusDeleteReducer,
  maritalStatusListReducer,
  maritalStatusSaveReducer,
  maritalStatusUpdateReducer,
} from "./Reducers/maritalStatusReducers";
import {
  teamDeleteReducer,
  teamInsertReducer,
  teamListReducer,
  teamUpdateReducer,
} from "./Reducers/teamReducers";
import {
  branchDeleteReducer,
  branchListReducer,
  branchSaveReducer,
  branchUpdateReducer,
} from "./Reducers/branchReducers";
import {
  universityDeleteReducer,
  universityDetReducer,
  universityListReducer,
  universitySaveReducer,
  universityUpdateReducer,
  universityUploadFileReducer,
} from "./Reducers/universityReducers";
import {
  workflowDeleteReducer,
  workflowListReducer,
  workflowSaveReducer,
  workflowUpdateReducer,
} from "./Reducers/workflowReducers";
import {
  campusByuniversityReducer,
  campusDeleteReducer,
  campusListReducer,
  campusSaveReducer,
  campusUpdateReducer,
} from "./Reducers/campusReducers";
import {
  dealStageDeleteReducer,
  dealStageListReducer,
  dealStageSaveReducer,
  dealStageUpdateReducer,
} from "./Reducers/dealStageReducers";
import {
  dealCreateReducer,
  dealListReducer,
  dealUpdateReducer,
  dealDetailReducer,
  listDealByContactReducer,
} from "./Reducers/dealReducers";
import {
  contactCreateReducer,
  contactDetByIdsReducer,
  contactDetailReducer,
  contactListReducer,
  contactUpdateReducer,
  totalContactsReducer,
} from "./Reducers/contactReducers";
import { formListReducer } from "./Reducers/formReducers";
import { pageListReducer } from "./Reducers/pageReducers";
import {
  oshcTypeDeleteReducer,
  oshcTypeSaveReducer,
  oshcTypeUpdateReducer,
  oshcTypesListReducer,
} from "./Reducers/oshcTypesReducers";
import {
  currencyDeleteReducer,
  currencyListReducer,
  currencySaveReducer,
  currencyUpdateReducer,
} from "./Reducers/currencyReducers";

import {
  subjectDeleteReducer,
  subjectListReducer,
  subjectSaveReducer,
  subjectUpdateReducer,
} from "./Reducers/subjectReducers";
import {
  studyBatchDeleteReducer,
  studyBatchListReducer,
  studyBatchSaveReducer,
  studyBatchUpdateReducer,
} from "./Reducers/studyBatchReducers";
import {
  studyBatchContactDeleteReducer,
  studyBatchContactListReducer,
  studyBatchContactListAllReducer,
  studyBatchContactSaveReducer,
} from "./Reducers/studyBatchContactsReducers";
import {
  callLogContactListReducer,
  callLogDealListReducer,
  callLogDeleteReducer,
  callLogSaveReducer,
  callLogSubAgentListReducer,
  callLogUpdateReducer,
} from "./Reducers/callLogReducers";
import {
  emailLogContactListReducer,
  emailLogDealListReducer,
  emailLogDeleteReducer,
  emailLogSaveReducer,
  emailLogSubAgentListReducer,
  emailLogUpdateReducer,
} from "./Reducers/emailLogReducers";
import {
  noteLogContactListReducer,
  noteLogDealListReducer,
  noteLogDeleteReducer,
  noteLogSaveReducer,
  noteLogSubAgentListReducer,
  noteLogUpdateReducer,
} from "./Reducers/noteLogReducers";
import {
  TaskLogAllDataReducer,
  TaskLogReminderReducer,
  taskLogContactListReducer,
  taskLogDealListReducer,
  taskLogDeleteReducer,
  taskLogSaveReducer,
  taskLogSubAgentListReducer,
  taskLogUpdateReducer,
} from "./Reducers/taskLogReducers";
import { partnerAgentReducer } from "./Reducers/partnerAgentReducer";
import { affiliatePartnerListReducer } from "./Reducers/affiliatePartnerReducer";
import {
  applicationCreateReducer,
  applicationDetByIdsReducer,
  applicationDetReducer,
  applicationListReducer,
  updateApplicationReducer,
} from "./Reducers/applicationReducer";
import {
  courseCreateReducer,
  courseDeleteReducer,
  courseListAllRecReducer,
  courseListReducer,
  courseUpdateReducer,
  getCourseDetailsReducer,
} from "./Reducers/courseReducer";
import { InvoiceListReducer } from "./Reducers/InvoiceReducer";
import { applicationTagReducer } from "./Reducers/ApplicationTagReducer";
import { lifeCycleListReducer } from "./Reducers/lifeCycleReducer";
import {
  shortListCourseCreateReducer,
  shortListCourseListReducer,
} from "./Reducers/shortListCourseReducer";
import {
  eventCreateReducer,
  eventGetReducer,
  eventListReducer,
  eventUpdateReducer,
  eventUploadMobileImageReducer,
} from "./Reducers/eventReducer";

import {
  activityContactListReducer,
  activityDealListReducer,
  activitySubAgentListReducer,
} from "./Reducers/activityReducer";

import {
  documentBulkListReducer,
  documentBulkSaveReducer,
  documentBulkUpdateReducer,
} from "./Reducers/documentBulkReducer";
import {
  studyPacakgeDeleteReducer,
  studyPacakgeListReducer,
  studyPacakgeSaveReducer,
  studyPacakgeUpdateReducer,
} from "./Reducers/studyPacakgeReducer";
import {
  studyMaterialDeleteReducer,
  studyMaterialGetReducer,
  studyMaterialListReducer,
  studyMaterialSaveReducer,
  studyMaterialUpdateReducer,
} from "./Reducers/studyMaterialReducer";
import {
  ContactBatchCreateReducer,
  ContactBatchListReducer,
  ContactBatchUpdateReducer,
  SendEmailContactBatchReducer,
  getContactBatchDetailsReducer,
} from "./Reducers/contactBatchReducer";

import {
  ListMessageEmailReducer,
  SendEmailReducer,
  SendMessageEmailReducer,
} from "./Reducers/SendEmailReducer";
import {
  SubagentNotificationCreateReducer,
  SubagentNotificationGetReducer,
} from "./Reducers/subagentNotificationReducer";
import {
  SubAgent_InsertAssignedUserReducer,
  subagent_ListAssignedUserReducer,
} from "./Reducers/SubAgentUserAssignedReducer";
import {
  defaultUserInsertReducer,
  defaultUserListReducer,
} from "./Reducers/defaultUserReducers";
import {
  defaultBranchUserInsertReducer,
  defaultBranchUserListReducer,
} from "./Reducers/defaultBranchUserReducers";
import {
  ApplicationSubmissionStatus_Reducer,
  ContactApplicationList_Reducer,
  Contact_Work_Details_list_Reducer,
  Create_Contact_Work_Details_list_Reducer,
  contactAdditionalDetReducer,
  createApplicationTempReducer,
  newApplicationCreateReducer,
  newApplicationListReducer,
} from "./Reducers/NewApplicationReducer";

import {
  Create_Deal_Task_Reducer,
  Step_Deal_Reducer,
} from "./Reducers/DealTaskReducer";
import {
  createMaterialReducer,
  listMaterialReducer,
  materialDetailsReducer,
  removeMaterialReducer,
  totalMaterialReducer,
  updateMaterialReducer,
} from "./Reducers/materialReducer";

import {
  Package_Create_Reducer,
  Package_List_Reducer,
  Package_Type_Data_Reducer,
  packageDetailsReducer,
  removePackageReducer,
  updatePackageReducer,
} from "./Reducers/PacakgeReducer";
import { listTestTypeReducer } from "./Reducers/TestTypeReducer";
import { listCalssModeReducer } from "./Reducers/ClassModeReducer";
import {
  CreatePsychometricQuestionReducer,
  DeletePsychometricQuestionReducer,
  listPsychometricQuestionReducer,
  PsychometricResultReducer,
  UpdatePsychometricQuestionReducer,
} from "./Reducers/PsychometricQuestionReducer";
import {
  classCenterDeleteReducer,
  classCenterInsertReducer,
  classCenterListReducer,
  classCenterUpdateReducer,
} from "./Reducers/classCenterReducer";
import {
  timingsDeleteReducer,
  timingsInsertReducer,
  timingsListReducer,
  timingsUpdateReducer,
} from "./Reducers/timingsReducer";
import {
  createCartDetReducer,
  listCartRducer,
  removeCartDetReducer,
  cartTotalReducer,
  updateCartDetReducer,
  placeOrderReducer,
} from "./Reducers/cartReducer";
import {
  createOrderDetReducer,
  listOrderRducer,
  orderDetailsDataReducer,
  removeOrderDetReducer,
  totalListOrderReducer,
  updateOrderDetReducer,
} from "./Reducers/orderReducer";
import {
  Coupon_Create_Reducer,
  Coupon_Delete_Reducer,
  Coupon_List_Reducer,
  couponDetailsReducer,
  updateCouponReducer,
} from "./Reducers/CouponReducer";
import { updatePaymentReducer } from "./Reducers/PaymentReducer";
import {
  createMaterialTitleReducer,
  deleteMaterialTitleReducer,
  listMaterialTitleAllReducer,
  listMaterialTitleReducer,
  updateMaterialTitleReducer,
} from "./Reducers/materialTitleReducer";
import {
  gurullyDeleteReducer,
  gurullyInsertReducer,
  gurullyListReducer,
  gurullyUpdateReducer,
} from "./Reducers/GurullyReducer";
import {
  gurullyContactDeleteReducer,
  gurullyContactInsertReducer,
  gurullyContactListReducer,
  gurullyContactUpdateReducer,
} from "./Reducers/GurullyContactReducer";
import {
  createMockTestReducer,
  listMockTestReducer,
  mockTestDetailsReducer,
  removeMockTestReducer,
  totalMockTestReducer,
  updateMockTestReducer,
} from "./Reducers/MockTestReducer";
import {
  TLat_list_ResultReducer,
  TLat_ResultReducer,
} from "./Reducers/TLatResultReducer";
import {
  createMaterialSubTitleReducer,
  deleteMaterialSubTitleReducer,
  listMaterialSubTitleAllReducer,
  listMaterialSubTitleReducer,
  singleMaterialSubTitleDetailsReducer,
  updateMaterialSubTitleReducer,
} from "./Reducers/materialSubTitleReducer";
import {
  createEmailTemplateReducer,
  deleteEmailTemplateReducer,
  listEmailTemplateAllReducer,
  listEmailTemplateReducer,
  singleEmailTemplateDetailsReducer,
  updateEmailTemplateReducer,
} from "./Reducers/emailTemplateReducer";
import { sendEMailBulkReducer } from "./Reducers/sendEmailBulkReducer";
const rootReducers = combineReducers({
  // Common Header
  commonHeader: commonHeaderReducer,
  getOffset: getOffsetReducer,
  // Month
  monthList: monthListReducer,
  monthInsert: monthInsertReducer,
  monthUpdate: monthUpdateReducer,
  monthDelete: monthDeleteReducer,
  // Other
  notificationGet: notificationGetReducer,
  notificationCreate: notificationCreateReducer,
  taskReminderTotal: taskReminderTotalReducer,
  dashboardReminderList: dashboardReminderListReducer,
  // Qualifies
  qualifiesList: qualifiesListReducer,
  qualifiesSave: qualifiesSaveReducer,
  qualifiesUpdate: qualifiesUpdateReducer,
  qualifiesDelete: qualifiesDeleteReducer,
  // Country
  countryList: countryListReducer,
  countrySave: countrySaveReducer,
  countryUpdate: countryUpdateReducer,
  countryDelete: countryDeleteReducer,
  // application

  applicationList: applicationListReducer,
  applicationCreate: applicationCreateReducer,
  applicationDets: applicationDetReducer,
  applicationUpdate: updateApplicationReducer,
  applicationRecByIds: applicationDetByIdsReducer,

  // new appliation
  contactApplicationList: ContactApplicationList_Reducer,
  newapplicationList: newApplicationListReducer,
  newapplicationCreate: newApplicationCreateReducer,
  newTempApplicationCreate: createApplicationTempReducer,
  contactAdditionlDet: contactAdditionalDetReducer,
  contactWorkDet: Contact_Work_Details_list_Reducer,
  contactCreateWorkDet: Create_Contact_Work_Details_list_Reducer,

  // application Status
  applicationStatusList: applicationStatusListReducer,
  applicationStatusDetail: applicationStatusDetailReducer,
  applicationStatusSave: applicationStatusSaveReducer,
  applicationStatusUpdate: applicationStatusUpdateReducer,
  applicationStatusDelete: applicationStatusDeleteReducer,
  applicationSubmissionStatusDet: ApplicationSubmissionStatus_Reducer,
  // Contact Platform
  contactPlatformList: contactPlatformListReducer,
  contactPlatformDetail: contactPlatformDetailReducer,
  contactPlatformSave: contactPlatformSaveReducer,
  contactPlatformUpdate: contactPlatformUpdateReducer,
  contactPlatformDelete: contactPlatformDeleteReducer,
  // Campaign
  campaignList: campaignListReducer,
  campaignDetail: campaignDetailReducer,
  campaignSave: campaignSaveReducer,
  campaignUpdate: campaignUpdateReducer,
  campaignUpdateStatus: campaignUpdateStatusReducer,
  // Pipeline
  pipelineList: pipelineListReducer,
  pipelineDetail: pipelineDetailReducer,
  pipelineSave: pipelineSaveReducer,
  pipelineUpdate: pipelineUpdateReducer,
  pipelineDelete: pipelineDeleteReducer,
  // Call Outcome
  callOutcomeList: callOutcomeListReducer,
  callOutcomeSave: callOutcomeSaveReducer,
  callOutcomeUpdate: callOutcomeUpdateReducer,
  callOutcomeDelete: callOutcomeDeleteReducer,
  // Category Of Study
  categoryOfStudyList: categoryOfStudyListReducer,
  categoryOfStudySave: categoryOfStudySaveReducer,
  categoryOfStudyUpdate: categoryOfStudyUpdateReducer,
  categoryOfStudyDelete: categoryOfStudyDeleteReducer,
  // Confirmation of Acceptance
  confirmationOfAcceptanceList: confirmationOfAcceptanceListReducer,
  confirmationOfAcceptanceSave: confirmationOfAcceptanceSaveReducer,
  confirmationOfAcceptanceUpdate: confirmationOfAcceptanceUpdateReducer,
  confirmationOfAcceptanceDelete: confirmationOfAcceptanceDeleteReducer,

  // Invoice
  InvoiceList: InvoiceListReducer,

  // application Tag
  applicationTagList: applicationTagReducer,

  // CRM Updates
  crmUpdatesList: crmUpdatesListReducers,
  crmUpdatesCreate: crmUpdatesCreateReducers,
  crmUpdatesUpdate: crmUpdatesUpdateReducers,
  crmUpdatesDelete: crmUpdatesDeleteReducers,
  // Departments
  departmentList: departmentListReducer,
  departmentSave: departmentSaveReducer,
  departmentUpdate: departmentUpdateReducer,
  departmentDelete: departmentDeleteReducer,
  // Hierarchy
  hierarchyList: hierarchyListReducer,
  hierarchySave: hierarchySaveReducer,
  hierarchyUpdate: hierarchyUpdateReducer,
  hierarchyDelete: hierarchyDeleteReducer,
  // fb Tokens
  fbTokenGet: fbTokenGetReducer,
  fbTokenSave: fbTokenSaveReducer,
  // Financial Source
  financialSourceList: financialSourceListReducer,
  financialSourceSave: financialSourceSaveReducer,
  financialSourceUpdate: financialSourceUpdateReducer,
  financialSourceDelete: financialSourceDeleteReducer,
  // Gender
  genderList: genderListReducer,
  genderSave: genderSaveReducer,
  genderUpdate: genderUpdateReducer,
  genderDelete: genderDeleteReducer,
  // How you heard about Us
  howyouheardaboutusList: howyouheardaboutusListReducer,
  howyouheardaboutusSave: howyouheardaboutusSaveReducer,
  howyouheardaboutusUpdate: howyouheardaboutusUpdateReducer,
  howyouheardaboutusDelete: howyouheardaboutusDeleteReducer,
  // Interested Field Of Study
  interestedFieldOfStudyList: interestedFieldOfStudyListReducer,
  interestedFieldOfStudySave: interestedFieldOfStudySaveReducer,
  interestedFieldOfStudyUpdate: interestedFieldOfStudyUpdateReducer,
  interestedFieldOfStudyDelete: interestedFieldOfStudyDeleteReducer,
  // Interested Level of Study
  interestedLevelOfStudyList: interestedLevelOfStudyListReducer,
  interestedLevelOfStudySave: interestedLevelOfStudySaveReducer,
  interestedLevelOfStudyUpdate: interestedLevelOfStudyUpdateReducer,
  interestedLevelOfStudyDelete: interestedLevelOfStudyDeleteReducer,
  // Lead Source
  leadSourceList: leadSourceListReducer,
  leadSourceSave: leadSourceSaveReducer,
  leadSourceUpdate: leadSourceUpdateReducer,
  leadSourceDelete: leadSourceDeleteReducer,
  // Medium of Event
  mediumOfEventList: mediumOfEventListReducer,
  mediumOfEventSave: mediumOfEventSaveReducer,
  mediumOfEventUpdate: mediumOfEventUpdateReducer,
  mediumOfEventDelete: mediumOfEventDeleteReducer,
  // Meeting Type
  meetingInterfaceList: meetingInterfaceListReducer,
  meetingInterfaceSave: meetingInterfaceSaveReducer,
  meetingInterfaceUpdate: meetingInterfaceUpdateReducer,
  meetingInterfaceDelete: meetingInterfaceDeleteReducer,
  // Mode Of Payment
  modeOfPaymentList: modeOfPaymentListReducer,
  modeOfPaymentSave: modeOfPaymentSaveReducer,
  modeOfPaymentUpdate: modeOfPaymentUpdateReducer,
  modeOfPaymentDelete: modeOfPaymentDeleteReducer,
  // Offer Accepted
  offerAcceptedList: offerAcceptedListReducer,
  offerAcceptedSave: offerAcceptedSaveReducer,
  offerAcceptedUpdate: offerAcceptedUpdateReducer,
  offerAcceptedDelete: offerAcceptedDeleteReducer,
  // Preferred Intake
  preferredIntakeList: preferredIntakeListReducer,
  preferredIntakeSave: preferredIntakeSaveReducer,
  preferredIntakeUpdate: preferredIntakeUpdateReducer,
  preferredIntakeDelete: preferredIntakeDeleteReducer,
  // Priority
  priorityList: priorityListReducer,
  prioritySave: prioritySaveReducer,
  priorityUpdate: priorityUpdateReducer,
  priorityDelete: priorityDeleteReducer,
  // Profile
  profileList: profileListReducer,
  profileSave: profileSaveReducer,
  profileUpdate: profileUpdateReducer,
  profileDelete: profileDeleteReducer,
  // Reason Did Not Qualify
  reasonDidNotQualifyList: reasonDidNotQualifyListReducer,
  reasonDidNotQualifySave: reasonDidNotQualifySaveReducer,
  reasonDidNotQualifyUpdate: reasonDidNotQualifyUpdateReducer,
  reasonDidNotQualifyDelete: reasonDidNotQualifyDeleteReducer,
  // Resume
  resumeList: resumeListReducer,
  resumeSave: resumeSaveReducer,
  resumeUpdate: resumeUpdateReducer,
  resumeDelete: resumeDeleteReducer,
  // Referral Type
  referralTypeList: referralTypeListReducer,
  referralTypeSave: referralTypeSaveReducer,
  referralTypeUpdate: referralTypeUpdateReducer,
  referralTypeDelete: referralTypeDeleteReducer,
  // Study Level
  studyLevelList: studyLevelListReducer,
  studyLevelSave: studyLevelSaveReducer,
  studyLevelUpdate: studyLevelUpdateReducer,
  studyLevelDelete: studyLevelDeleteReducer,
  // Subscribe
  subscribeList: subscribeListReducer,
  subscribeSave: subscribeSaveReducer,
  subscribeUpdate: subscribeUpdateReducer,
  subscribeDelete: subscribeDeleteReducer,
  // Subscribed Leads
  subscribedLeadsList: subscribedLeadsListReducer,
  subscribedLeadsSave: subscribedLeadsSaveReducer,
  subscribedLeadsUpdate: subscribedLeadsUpdateReducer,
  subscribedLeadsDelete: subscribedLeadsDeleteReducer,
  // User Role
  userRoleList: userRoleListReducer,
  userRoleSave: userRoleSaveReducer,
  userRoleUpdate: userRoleUpdateReducer,
  userRoleDelete: userRoleDeleteReducer,

  // Visa flow process

  StepDealsTasks: Step_Deal_Reducer,
  DealTaskCreate: Create_Deal_Task_Reducer,

  // Visa Status
  visaStatusList: visaStatusListReducer,
  visaStatusSave: visaStatusSaveReducer,
  visaStatusUpdate: visaStatusUpdateReducer,
  visaStatusDelete: visaStatusDeleteReducer,
  // Website Page
  websitePagesList: websitePagesListReducer,
  websitePagesSave: websitePagesSaveReducer,
  websitePagesUpdate: websitePagesUpdateReducer,
  websitePagesDelete: websitePagesDeleteReducer,
  // Course
  courseListRecs: courseListReducer,
  CourseListAllRec: courseListAllRecReducer,
  CourseCreate: courseCreateReducer,
  CourseDetailsRec: getCourseDetailsReducer,
  CourseUpdate: courseUpdateReducer,
  CourseRemove: courseDeleteReducer,

  // contact Batch
  contactBatchListRecs: ContactBatchListReducer,
  contactBatchCreate: ContactBatchCreateReducer,
  contactBatchDetailsRec: getContactBatchDetailsReducer,
  contactBatchUpdate: ContactBatchUpdateReducer,
  contactBatchSendEmail: SendEmailContactBatchReducer,

  // Contact Rating
  contactRatingList: contactRatingListReducers,
  contactRatingCreate: contactRatingCreateReducers,
  contactRatingUpdate: contactRatingUpdateReducers,
  contactRatingDelete: contactRatingDeleteReducers,
  // Document List
  documentList: documentListReducer,
  documentSave: documentSaveReducer,
  documentUpdate: documentUpdateReducer,
  documentDelete: documentDeleteReducer,
  CheckedDocumetListReducer,
  createCheckedDocumetListReducer,
  //document Bulk list

  documentBulkList: documentBulkListReducer,
  documentBulkSave: documentBulkSaveReducer,
  documentBulkUpdate: documentBulkUpdateReducer,

  // Educational Status
  educationalStatusList: educationalStatusListReducer,
  educationalStatusInsert: educationalStatusInsertReducer,
  educationalStatusUpdate: educationalStatusUpdateReducer,
  educationalStatusDelete: educationalStatusDeleteReducer,
  // English Test Attempted
  englishTestAttemptedList: englishTestAttemptedListReducer,
  englishTestAttemptedSave: englishTestAttemptedSaveReducer,
  englishTestAttemptedUpdate: englishTestAttemptedUpdateReducer,
  englishTestAttemptedDelete: englishTestAttemptedDeleteReducer,
  // Enquiry For
  enquiryForList: enquiryForReducer,
  enquiryForSave: enquiryForSaveReducer,
  enquiryForUpdate: enquiryForUpdateReducer,
  enquiryForDelete: enquiryForDeleteReducer,
  // Marital Status
  maritalStatusList: maritalStatusListReducer,
  maritalStatusSave: maritalStatusSaveReducer,
  maritalStatusUpdate: maritalStatusUpdateReducer,
  maritalStatusDelete: maritalStatusDeleteReducer,

  // Branch
  branchList: branchListReducer,
  branchSave: branchSaveReducer,
  branchUpdate: branchUpdateReducer,
  branchDelete: branchDeleteReducer,
  // University
  universityList: universityListReducer,
  universitySave: universitySaveReducer,
  universityUpdate: universityUpdateReducer,
  universityDelete: universityDeleteReducer,
  universityUploadImages: universityUploadFileReducer,
  univeristySingleRec: universityDetReducer,
  // Workflow
  workflowList: workflowListReducer,
  workflowSave: workflowSaveReducer,
  workflowUpdate: workflowUpdateReducer,
  workflowDelete: workflowDeleteReducer,
  // Campus
  campusList: campusListReducer,
  campusListByUniversity: campusByuniversityReducer,
  campusSave: campusSaveReducer,
  campusUpdate: campusUpdateReducer,
  campusDelete: campusDeleteReducer,
  //Deals
  createDeal: dealCreateReducer,
  dealListRec: dealListReducer,
  dealListContactRec: listDealByContactReducer,
  dealUpdate: dealUpdateReducer,
  dealDet: dealDetailReducer,
  // Deal Stage
  dealStageDelete: dealStageDeleteReducer,
  dealStageList: dealStageListReducer,
  dealStageSave: dealStageSaveReducer,
  dealStageUpdate: dealStageUpdateReducer,

  // Affiliate PArtner
  affiliatePartnerList: affiliatePartnerListReducer,
  // Form
  formList: formListReducer,
  // Page
  pageList: pageListReducer,
  // partner agent
  partnerAgentList: partnerAgentReducer,
  // OSHC TYPES
  oshcTypesList: oshcTypesListReducer,
  oshcTypeDelete: oshcTypeDeleteReducer,
  oshcTypeSave: oshcTypeSaveReducer,
  oshcTypeUpdate: oshcTypeUpdateReducer,
  // Currency
  currencyList: currencyListReducer,
  currencySave: currencySaveReducer,
  currencyUpdate: currencyUpdateReducer,
  currencyDelete: currencyDeleteReducer,

  // send email

  sendEmailData: SendEmailReducer,

  SendMessageEmailReducer,
  ListMessageEmailReducer,

  // study Pacakge
  studyPackList: studyPacakgeListReducer,
  studyPackDelete: studyPacakgeDeleteReducer,
  studyPackSave: studyPacakgeSaveReducer,
  studyPackUpdate: studyPacakgeUpdateReducer,
  // study Pacakge
  studyMaterialDelete: studyMaterialDeleteReducer,
  studyMaterialList: studyMaterialListReducer,
  studyMaterialGet: studyMaterialGetReducer,
  studyMaterialSave: studyMaterialSaveReducer,
  studyMaterialUpdate: studyMaterialUpdateReducer,
  // Subject
  subjectList: subjectListReducer,
  subjectSave: subjectSaveReducer,
  subjectUpdate: subjectUpdateReducer,
  subjectDelete: subjectDeleteReducer,
  // Study Batch
  studyBatchList: studyBatchListReducer,
  studyBatchDelete: studyBatchDeleteReducer,
  studyBatchSave: studyBatchSaveReducer,
  studyBatchUpdate: studyBatchUpdateReducer,
  // Study Batch Contacts
  studyBatchContactList: studyBatchContactListReducer,
  studyBatchContactListAll: studyBatchContactListAllReducer,
  studyBatchContactDelete: studyBatchContactDeleteReducer,
  studyBatchContactSave: studyBatchContactSaveReducer,
  // Call Log
  callLogContactList: callLogContactListReducer,
  callLogDealList: callLogDealListReducer,
  callLogSubAgentList: callLogSubAgentListReducer,
  callLogSave: callLogSaveReducer,
  callLogUpdate: callLogUpdateReducer,
  callLogDelete: callLogDeleteReducer,
  // Default Users
  defaultUserList: defaultUserListReducer,
  defaultUserInsert: defaultUserInsertReducer,
  // Default Branch Users
  defaultBranchUserList: defaultBranchUserListReducer,
  defaultBranchUserInsert: defaultBranchUserInsertReducer,
  // Email Log
  emailLogContactList: emailLogContactListReducer,
  emailLogDealList: emailLogDealListReducer,
  emailLogSubAgentList: emailLogSubAgentListReducer,
  emailLogSave: emailLogSaveReducer,
  emailLogUpdate: emailLogUpdateReducer,
  emailLogDelete: emailLogDeleteReducer,
  // Note Log
  noteLogContactList: noteLogContactListReducer,
  noteLogDealList: noteLogDealListReducer,
  noteLogSubAgentList: noteLogSubAgentListReducer,
  noteLogSave: noteLogSaveReducer,
  noteLogUpdate: noteLogUpdateReducer,
  noteLogDelete: noteLogDeleteReducer,
  // Task Log
  taskLogContactList: taskLogContactListReducer,
  taskLogDealList: taskLogDealListReducer,
  taskLogSubAgentList: taskLogSubAgentListReducer,
  taskLogSave: taskLogSaveReducer,
  taskLogUpdate: taskLogUpdateReducer,
  taskLogDelete: taskLogDeleteReducer,
  taskLogReminder: TaskLogReminderReducer,
  taskListAll: TaskLogAllDataReducer,

  //  Activity
  activtyContactList: activityContactListReducer,
  activityDealList: activityDealListReducer,
  activitySubAgentList: activitySubAgentListReducer,

  // subagent notification

  subagentNotificationListDet: SubagentNotificationGetReducer,
  subagentNotificationInsert: SubagentNotificationCreateReducer,

  // subagent user assigned

  subagentListAssignedUserDet: subagent_ListAssignedUserReducer,
  subagentAssignedUserInsert: SubAgent_InsertAssignedUserReducer,

  // Lifecycle
  lifeCycleList: lifeCycleListReducer,
  // ShortList Course
  shortListCourseCreate: shortListCourseCreateReducer,
  shortListCourseList: shortListCourseListReducer,
  // Event
  eventList: eventListReducer,
  evetCreate: eventCreateReducer,
  eventUpadte: eventUpdateReducer,
  evetSingle: eventGetReducer,
  eventMobileImageUplaod: eventUploadMobileImageReducer,

  // Added For Testifyi

  Sidebar_Reducer,

  // Users
  loginUsers: loginUsersReducers,
  usersList: usersListReducers,
  userSave: userSaveReducers,
  userDetails: userDetailsReducers,
  userVerifyTkn: userVerifyTknReducers,
  userForgotPassword: userForgotPasswordReducers,
  userResetPassword: userResetPasswordReducers,
  userChangePassword: userChangePasswordReducers,
  userUpdateProfile: userUpdateProfileReducers,
  userInternalDetails: userInternalDetailsReducers,
  userUpdateIntDetails: userUpdateIntDetailsReducers,
  userUpdateActiveStatus: userUpdateActiveStatusReducers,
  userUpdateBlockedStatus: userUpdateBlockedStatusReducers,

  // Contacts
  contactList: contactListReducer,
  contactCreate: contactCreateReducer,
  contactUpdate: contactUpdateReducer,
  contactDetail: contactDetailReducer,
  contactDetByIdData: contactDetByIdsReducer,
  totalContactsReducer,
  // Material
  createMaterialReducer,
  listMaterialReducer,
  updateMaterialReducer,
  removeMaterialReducer,
  totalMaterialReducer,

  // --Materials
  createMaterialTitleReducer,
  listMaterialTitleReducer,
  listMaterialTitleAllReducer,
  materialDetailsReducer,
  deleteMaterialTitleReducer,
  updateMaterialTitleReducer,

  // --Materials Sub title
  createMaterialSubTitleReducer,
  listMaterialSubTitleReducer,
  listMaterialSubTitleAllReducer,
  deleteMaterialSubTitleReducer,
  updateMaterialSubTitleReducer,
  singleMaterialSubTitleDetailsReducer,

  // Email Template

  createEmailTemplateReducer,
  listEmailTemplateReducer,
  listEmailTemplateAllReducer,
  deleteEmailTemplateReducer,
  updateEmailTemplateReducer,
  singleEmailTemplateDetailsReducer,

  //--Mock Test
  createMockTestReducer,
  listMockTestReducer,
  updateMockTestReducer,
  removeMockTestReducer,
  totalMockTestReducer,
  mockTestDetailsReducer,
  // Pacakge

  Package_List_Reducer,
  Package_Create_Reducer,
  updatePackageReducer,
  packageDetailsReducer,
  Package_Type_Data_Reducer,
  removePackageReducer,
  //---class

  listTestTypeReducer,
  listCalssModeReducer,
  CreatePsychometricQuestionReducer,
  listPsychometricQuestionReducer,
  UpdatePsychometricQuestionReducer,
  DeletePsychometricQuestionReducer,

  // Team
  teamList: teamListReducer,
  teamInsert: teamInsertReducer,
  teamUpdate: teamUpdateReducer,
  teamDelete: teamDeleteReducer,

  //Gurully

  gurullyList: gurullyListReducer,
  gurullyInsert: gurullyInsertReducer,
  gurullyUpdate: gurullyUpdateReducer,
  gurullyDelete: gurullyDeleteReducer,

  //Gurully contact

  gurullyContactList: gurullyContactListReducer,
  gurullyContactInsert: gurullyContactInsertReducer,
  gurullyContactUpdate: gurullyContactUpdateReducer,
  gurullyContactDelete: gurullyContactDeleteReducer,

  //Class Center

  classCenterList: classCenterListReducer,
  classCenterInsert: classCenterInsertReducer,
  classCenterUpdate: classCenterUpdateReducer,
  classCenterDelete: classCenterDeleteReducer,

  //Timings Center

  timingsList: timingsListReducer,
  timingsInsert: timingsInsertReducer,
  timingsUpdate: timingsUpdateReducer,
  timingsDelete: timingsDeleteReducer,

  // Cart
  listCartRducer,
  createCartDetReducer,
  updateCartDetReducer,
  removeCartDetReducer,
  cartTotalReducer,

  // Order
  listOrderRducer,
  createOrderDetReducer,
  updateOrderDetReducer,
  removeOrderDetReducer,
  totalListOrderReducer,
  placeOrderReducer,
  PsychometricResultReducer,
  updatePaymentReducer,
  orderDetailsDataReducer,

  // Coupon

  Coupon_Create_Reducer,
  Coupon_List_Reducer,
  updateCouponReducer,
  couponDetailsReducer,
  Coupon_Delete_Reducer,

  // T LAT result
  TLat_ResultReducer,
  TLat_list_ResultReducer,

  sendEMailBulkReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const InitialState = {
  loginUsers: {
    userRec: GET_LOCAL("_Tsset"),
  },
};

export const store = legacy_createStore(
  rootReducers,
  InitialState,
  composeEnhancers(applyMiddleware(thunk))
);
