import SidePanel from "../../Components/SidePanel";
import TopBar from "../../Components/TopBar";
import { useDispatch, useSelector } from "react-redux";
import Meta from "../../Components/Meta";
import { useCallback, useEffect, useRef, useState } from "react";
import { contactJson } from "../../Models/ContactModels";
import { toastOptions } from "../../Models/ToastEditiorOption";
import { toast } from "react-toastify";
import CourseDropDown from "../Components/CourseDropDown";
import {
  createCartDet,
  listCartRec,
  totalCart,
} from "../../Actions/cartAction";
import { CART_DETAIL_CREATE_RESET } from "../../Constants/cartConstant";
import PackageTypeDropDown from "../Components/PackageTypeDropDown";
import { SubCategory_Model } from "../../Models/PackageModel";
import Pagination from "../Components/Pagination";

export default function Cart() {
  const [cartCreateData, setcartCreateData] = useState(contactJson);
  const [MobileFilterActive, setMobileFilterActive] = useState(false);
  const [createSec, setCreateSec] = useState(false);
  const [cartFilterData, setcartFilterData] = useState({
    page_size: 10,
    page_no: 1,
  });
  const mobile_View = window.innerWidth;
  const { loading, error, cartRecs } = useSelector((s) => s.listCartRducer);
  const { total } = useSelector((s) => s.cartTotalReducer);
  const ErrorSuccessRef = useRef();

  const handleCancel = () => {
    setCreateSec(false);
  };

  const {
    loading: loadingsv,
    error: errorsv,
    success: successsv,
  } = useSelector((s) => s.createCartDetReducer);

  const dispatch = useDispatch();
  const MetaDes = { title: "Testfify - Cart" };

  const Get_Cart = useCallback(() => {
    dispatch(listCartRec(cartFilterData));
    dispatch(totalCart());
  }, [dispatch, cartFilterData]);

  const handleChange = (e) => {
    setcartCreateData({
      ...cartCreateData,
      [e.target.name]: e.target.value,
    });
  };
  const handleFilterChange = () => {};

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(createCartDet(cartCreateData));
  };

  useEffect(() => {
    Get_Cart();
  }, [Get_Cart]);

  useEffect(() => {
    if (successsv) {
      setCreateSec(false);
      setcartCreateData(contactJson);
      toast.success(successsv, toastOptions);
      Get_Cart();
      setTimeout(() => {
        dispatch({ type: CART_DETAIL_CREATE_RESET });
      }, 2000);
    }

    if (errorsv) {
      toast.success(errorsv, toastOptions);
    }
  }, [successsv, errorsv, dispatch, Get_Cart]);

  return (
    <>
      <Meta MetaDes={MetaDes} />
      <SidePanel page={"Cart"} />
      <section className="home-section">
        <TopBar Heading="Cart" />
        <div className="home-content" style={{ paddingTop: "70px" }}>
          <div className="Contact-Middle">
            <div className="Contact_Top_Header">
              <div className="home-title float-left"></div>
              <div style={{ display: "flex", gap: "20px" }} className="">
                <div className="input-group search-contacts">
                  <input
                    type="Search"
                    className="form-control"
                    name="searchtxt"
                    style={{ display: "block" }}
                    // value={Search}
                    // onChange={(e) => {
                    //   if (e.target.value === "") {
                    //     setFilterData({ ...FilterData, searchtxt: "" });
                    //     setcartFilterData({
                    //       ...cartFilterData,
                    //       searchtxt: "",
                    //     });
                    //   }
                    //   setSearch(e.target.value);
                    // }}
                    // onKeyDown={(e) => {
                    //   if (
                    //     e.key === "Enter" &&
                    //     Search !== "" &&
                    //     Search !== undefined
                    //   ) {
                    //     setFilterData({ ...FilterData, searchtxt: Search });
                    //     setcartFilterData({
                    //       ...cartFilterData,
                    //       searchtxt: Search,
                    //     });
                    //   }
                    // }}
                    placeholder="search for package"
                  />
                  <i
                    className="fa fa-search"
                    // onClick={() => {
                    //   if (Search !== "" && Search !== undefined) {
                    //     setFilterData({ ...FilterData, searchtxt: Search });
                    //     setcartFilterData({
                    //       ...cartFilterData,
                    //       searchtxt: Search,
                    //     });
                    //   }
                    // }}
                    aria-hidden="true"
                  ></i>
                </div>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-md-4 col-lg-3">
                <div
                  style={
                    mobile_View <= 480
                      ? { marginBottom: "20px" }
                      : { cursor: "auto" }
                  }
                  className="add-filter"
                >
                  <div
                    className={
                      MobileFilterActive
                        ? "filter-title  filter-title-mob filter-body-active"
                        : "filter-title  filter-title-mob"
                    }
                    style={{ cursor: "auto" }}
                  >
                    <h5>Filter Cart</h5>
                    <button
                      onClick={() => setMobileFilterActive(false)}
                      className="mob-nav-open"
                    >
                      ☰
                    </button>
                  </div>

                  <div
                    className={
                      MobileFilterActive
                        ? "filter-body filter-body-mob  filter-body-active custom-scroll-right pt-0"
                        : "filter-body filter-body-mob custom-scroll-right pt-0"
                    }
                    style={{ cursor: "auto" }}
                  >
                    <div className="sticky pt-2">
                      <p style={{ marginBottom: "0px" }}>
                        showing {total ? total : 0} Cart
                      </p>

                      <button
                        style={{ color: "white" }}
                        className="btn btn-sm btn-deep-orange"
                        onClick={() => {
                          setcartFilterData({
                            page_size: 10,
                            page_no: 1,
                          });
                        }}
                      >
                        Clear All
                      </button>
                    </div>
                    <ul className="modify-filter-title">
                      <li className="chose">Package</li>
                      <li>
                        <select
                          name="PackageId"
                          value={cartFilterData.PackageId}
                          onChange={handleFilterChange}
                          className="form-control"
                        >
                          <option value="">--Select--</option>
                        </select>
                      </li>

                      <li className="chose">Package Type</li>
                      <li>
                        <select
                          name="PackageId"
                          value={cartFilterData.PackageId}
                          onChange={handleFilterChange}
                          className="form-control"
                        >
                          <option value="">--Select--</option>
                          <PackageTypeDropDown />
                        </select>
                      </li>

                      <li className="chose">Sub Category</li>
                      <li>
                        <select
                          name="SubCategory"
                          value={cartFilterData.SubCategory}
                          onChange={handleFilterChange}
                          className="form-control"
                        >
                          <option value="">--Select--</option>
                          {SubCategory_Model?.map((ev, i) => (
                            <option key={i + 67}>{ev}</option>
                          ))}
                        </select>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-md-8 col-lg-9">
                <div className="Contact-table-wrapper">
                  <table>
                    <thead>
                      <tr className="sticky">
                        <th>Package Name</th>
                        <th>Contact Name</th>
                        <th>Email Address</th>
                        <th>Phone No</th>
                        <th>Actual Cost</th>
                        <th>Discounted Cost</th>
                        <th>Timings</th>
                        <th>Custom Timings</th>
                        <th>Test Type</th>
                        <th>SubCategory</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <tr>
                          <td colSpan={10}>Laoding... </td>
                        </tr>
                      ) : error ? (
                        <tr>
                          <td colSpan={10}>{error}</td>
                        </tr>
                      ) : (
                        cartRecs &&
                        cartRecs?.map((ev) => (
                          <tr key={ev._id}>
                            <td> {ev?.packageName}</td>
                            <td>{ev?.First_Name + " " + ev?.Last_Name}</td>
                            <td>{ev?.EmailAddress}</td>
                            <td>{ev?.PhoneNo}</td>
                            <td>{ev?.packageACost}</td>
                            <td>{ev?.packageDCost}</td>
                            <td>{ev?.Timings}</td>
                            <td>{ev?.CustomTimings}</td>
                            <td>
                              {ev?.TestType === 1
                                ? "Paper Based"
                                : ev?.TestType == 2
                                ? "Computer Based"
                                : ""}
                            </td>
                            <td>{ev?.SubCategory}</td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
                <div>
                  {/* <Pagination
                    Records={total}
                    setFilterQuery={setcartFilterData}
                    FilterQuery={cartFilterData}
                    CurrentPage={1}
                    NextPage={""}
                    PreviousPage={""}
                    handlePagination=""
                    setCurrentPage
                  /> */}

                  <Pagination
                    setFilterQuery={setcartFilterData}
                    FilterQuery={cartFilterData}
                    Total={total}
                  />
                </div>

                <div
                  id="CreatContact"
                  className={createSec ? "overlay-note w-100" : "overlay-note"}
                >
                  <div className="overlay-add-deals contact-right-side">
                    <div className="note-title">
                      <h4 className="text-left text-white pl-3">
                        Create Material
                      </h4>
                      <a
                        className="closebtn text-right text-white"
                        onClick={(e) => setCreateSec(false)}
                      >
                        ×
                      </a>
                    </div>

                    <div className="contact-body modify-create-contact">
                      {/* <div className="form-group">
                        <label>
                          Enquiry for:<b>*</b>
                        </label>
                        <select
                          className="form-control"
                          name="Enquiry_For"
                          value={cartCreateData.Enquiry_For}
                          onChange={(e) => handleChange(e)}
                        >
                          <option>-- Select Enquiry For --</option>
                          <EnquiryForDropdown />
                        </select>
                      </div>

                      <div className="form-group">
                        <label>
                          Lead source:<b>*</b>
                        </label>
                        <select
                          className="form-control"
                          name="Lead_Source"
                          value={cartCreateData.Lead_Source}
                          onChange={(e) => handleChange(e)}
                        >
                          <option>-- Select Lead Source --</option>
                          <LeadSourceDropdown />
                        </select>
                      </div> */}
                      <div className="form-group">
                        <label>
                          Material Title:<b>*</b>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Materia lTitle"
                          name="MaterialTitle"
                          value={cartCreateData.MaterialTitle}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>

                      <div className="form-group">
                        <label>
                          Material Detail:<b>*</b>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Material Detail"
                          name="MaterialDetail"
                          value={cartCreateData.MaterialDetail}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>

                      <div className="form-group first-input">
                        <label>
                          Course Description:<b>*</b>
                        </label>
                        {/* <button className="first-btn-plus" value="+">
                          +{" "}
                        </button> */}
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Material Description"
                          name="MaterialDescription"
                          value={cartCreateData.MaterialDescription}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>

                      <div
                        // className="contact-footer w-100"
                        style={{
                          // padding: "50px 25px",
                          backgroundColor: "transparent",
                          marginTop: "26px",
                        }}
                        ref={ErrorSuccessRef}
                      ></div>

                      <div className="form-group first-input">
                        <label>
                          Course Id:<b>*</b>
                        </label>

                        <select
                          type="text"
                          className="form-control"
                          name="Course_Id"
                          value={cartCreateData.Course_Id}
                          onChange={(e) => handleChange(e)}
                        >
                          <option>Select Course</option>
                          <CourseDropDown />
                        </select>
                      </div>
                      <div style={{ height: "215px" }}></div>

                      <div className="contact-footer w-100">
                        <div>
                          <button
                            type="button"
                            className="btn"
                            onClick={handleSubmit}
                          >
                            {loadingsv ? "Loading..." : "Create Material"}
                          </button>
                          {/* <button type="button" className="btn">
                  Create and add another
                </button> */}
                          <button
                            type="button"
                            className="btn btn-deep-orange waves-effect waves-light"
                            onClick={handleCancel}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
