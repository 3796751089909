import {
  NOTE_LOG_DELETE_FAIL,
  NOTE_LOG_DELETE_REQUEST,
  NOTE_LOG_DELETE_SUCCESS,
  NOTE_LOG_INSERT_FAIL,
  NOTE_LOG_INSERT_REQUEST,
  NOTE_LOG_INSERT_SUCCESS,
  NOTE_LOG_CONTACT_LIST_FAIL,
  NOTE_LOG_CONTACT_LIST_REQUEST,
  NOTE_LOG_CONTACT_LIST_SUCCESS,
  NOTE_LOG_DEAL_LIST_FAIL,
  NOTE_LOG_DEAL_LIST_REQUEST,
  NOTE_LOG_DEAL_LIST_SUCCESS,
  NOTE_LOG_UPDATE_FAIL,
  NOTE_LOG_UPDATE_REQUEST,
  NOTE_LOG_UPDATE_SUCCESS,
  NOTE_LOG_SUBAGENT_LIST_REQUEST,
  NOTE_LOG_SUBAGENT_LIST_SUCCESS,
  NOTE_LOG_SUBAGENT_LIST_FAIL,
} from "../Constants/noteLogConstants";
import api from "../core/api";

export const listNoteLogContact = (id) => async (dispatch) => {
  try {
    dispatch({ type: NOTE_LOG_CONTACT_LIST_REQUEST });
    const { data } = await api.get(`/contact/notelog/${id}`);
  
    dispatch({
      type: NOTE_LOG_CONTACT_LIST_SUCCESS,
      payload: data.Result,
    });
  } catch (error) {
    dispatch({
      type: NOTE_LOG_CONTACT_LIST_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const listNoteLogDeal = (id) => async (dispatch) => {
  try {
    dispatch({ type: NOTE_LOG_DEAL_LIST_REQUEST });
    const { data } = await api.get(`/notelog/getDealRecords/${id}`);

    dispatch({
      type: NOTE_LOG_DEAL_LIST_SUCCESS,
      payload: data.Result,
    });
  } catch (error) {
    dispatch({
      type: NOTE_LOG_DEAL_LIST_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const listNoteLogSubaegnt = (id) => async (dispatch) => {
  try {
    dispatch({ type: NOTE_LOG_SUBAGENT_LIST_REQUEST });
    const { data } = await api.get(`/notelog/getSubAgentRecords/${id}`);
    dispatch({
      type: NOTE_LOG_SUBAGENT_LIST_SUCCESS,
      payload: data.Result,
    });
  } catch (error) {
    dispatch({
      type: NOTE_LOG_SUBAGENT_LIST_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const saveNoteLogDetails = (NoteLogJson) => async (dispatch) => {
  try {
    dispatch({ type: NOTE_LOG_INSERT_REQUEST });
    const { data } = await api.post(`/contact/notelog`, NoteLogJson);
    dispatch({
      type: NOTE_LOG_INSERT_SUCCESS,
      payload: data.Result,
    });
  } catch (error) {
    dispatch({
      type: NOTE_LOG_INSERT_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const updateNoteLogDetails = (id, NoteLogjson) => async (dispatch) => {
  try {
    dispatch({ type: NOTE_LOG_UPDATE_REQUEST });
    const { data } = await api.put(`/contact/notelog/${id}`, NoteLogjson);
    dispatch({
      type: NOTE_LOG_UPDATE_SUCCESS,
      payload: data.Result,
    });
  } catch (error) {
    dispatch({
      type: NOTE_LOG_UPDATE_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const deleteNoteLogDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: NOTE_LOG_DELETE_REQUEST });
    const { data } = await api.delete(`/contact/notelog/${id}`);
    dispatch({
      type: NOTE_LOG_DELETE_SUCCESS,
      payload: data.Result,
    });
  } catch (error) {
    dispatch({
      type: NOTE_LOG_DELETE_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};
