export const CURRENCY_LIST_REQUEST = 'CURRENCY_LIST_REQUEST'
export const CURRENCY_LIST_SUCCESS = 'CURRENCY_LIST_SUCCESS'
export const CURRENCY_LIST_FAIL = 'CURRENCY_LIST_FAIL'

export const CURRENCY_GET_REQUEST = 'CURRENCY_GET_REQUEST'
export const CURRENCY_GET_SUCCESS = 'CURRENCY_GET_SUCCESS'
export const CURRENCY_GET_FAIL = 'CURRENCY_GET_FAIL'

export const CURRENCY_INSERT_REQUEST = 'CURRENCY_INSERT_REQUEST'
export const CURRENCY_INSERT_SUCCESS = 'CURRENCY_INSERT_SUCCESS'
export const CURRENCY_INSERT_FAIL = 'CURRENCY_INSERT_FAIL'
export const CURRENCY_INSERT_RESET = 'CURRENCY_INSERT_RESET'

export const CURRENCY_UPDATE_REQUEST = 'CURRENCY_UPDATE_REQUEST'
export const CURRENCY_UPDATE_SUCCESS = 'CURRENCY_UPDATE_SUCCESS'
export const CURRENCY_UPDATE_FAIL = 'CURRENCY_UPDATE_FAIL'
export const CURRENCY_UPDATE_RESET = 'CURRENCY_UPDATE_RESET'

export const CURRENCY_DELETE_REQUEST = 'CURRENCY_DELETE_REQUEST'
export const CURRENCY_DELETE_SUCCESS = 'CURRENCY_DELETE_SUCCESS'
export const CURRENCY_DELETE_FAIL = 'CURRENCY_DELETE_FAIL'
export const CURRENCY_DELETE_RESET = 'CURRENCY_DELETE_RESET'