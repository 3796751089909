import { useEffect, useState } from "react";
import ClassCenterDropDown from "./ClassCenterDropDown";
import ClassModeDropDown from "./ClassModeDropDown";
import { useDispatch, useSelector } from "react-redux";
import { Package_List, packageDetails } from "../../Actions/PacakgeAction";
import TimingsDropDown from "./TimingsDropDown";
import { createCartDet } from "../../Actions/cartAction";
import { toast } from "react-toastify";
import { toastOptions } from "../../Models/ToastEditiorOption";
import { CART_DETAIL_CREATE_RESET } from "../../Constants/cartConstant";

const CartModel = {
  PackageId: "",
  CourseId: "",
  classLocation: "",
  ClassMode: "",
  Timings: "",
  CustomTimings: "",
};

export default function Cart({
  createSec,
  setcreateSec,
  ContactId,
  Get_Order_Cart_Det,
}) {
  const [CartData, setCartData] = useState(CartModel);

  const handleCancelCart = () => {
    setcreateSec("");
    dispatch({ type: CART_DETAIL_CREATE_RESET });
  };

  const dispatch = useDispatch();
  const { loading, success, error } = useSelector(
    (s) => s.createCartDetReducer
  );
  
  const { PackageRec } = useSelector((s) => s.Package_List_Reducer);
  const { DetailsPack } = useSelector((s) => s.packageDetailsReducer);

  const handleSubmit = (e) => {
    e.preventDefault();
    let Error = "";
    if (CartData?.PackageId === "") {
      Error += "Package Required !";
    }

    if (CartData?.ClassMode === "") {
      Error += "Class Mode Required !";
    }

    if (CartData?.Timings === "") {
      Error += "Timings Required !";
    }

    if (Error == "") {
      dispatch(createCartDet({ ...CartData, ContactId }));
    } else {
      toast.error("Error - " + Error, toastOptions);
    }
  };

  const handleChange = (e) => {
    if (e.target.name === "ClassMode" && e.target.value == 1) {
      setCartData({
        ...CartData,
        [e.target.name]: e.target.value,
        classLocation: null,
      });
    } else {
      setCartData({ ...CartData, [e.target.name]: e.target.value });
    }
  };

  useEffect(() => {
    dispatch(Package_List({ page_size: 200, page_no: 1 }));
  }, [dispatch]);

  useEffect(() => {
    if (CartData?.PackageId) {
      dispatch(packageDetails(CartData?.PackageId));
    }
  }, [CartData?.PackageId]);

  useEffect(() => {
    if (DetailsPack && CartData?.PackageId) {
      setCartData({ ...CartData, CourseId: DetailsPack?.PackageId?.CourseId });
    } else {
      setCartData({ ...CartData, CourseId: "" });
    }
  }, [DetailsPack]);

  useEffect(() => {
    if (success) {
      toast.success(success, toastOptions);
      Get_Order_Cart_Det();
      setcreateSec("");
      setTimeout(() => {
        handleCancelCart();
      }, 3000);
    }

    if (error) {
      toast.error(error, toastOptions);
    }
  }, [success,Get_Order_Cart_Det, error]);

  return (
    <>
      <div
        id="CreatContact"
        className={createSec === "Cart" ? "overlay-note w-100" : "overlay-note"}
      >
        <div className="overlay-add-deals contact-right-side">
          <div className="note-title">
            <h4 className="text-left text-white pl-3">Cart</h4>
            <a
              className="closebtn text-right text-white"
              onClick={() => handleCancelCart()}
            >
              ×
            </a>
          </div>

          <div
            className="contact-body modify-create-contact"
            style={{ height: "92vh" }}
          >
            <div className="form-group ">
              <label for="package">
                Package:<b>*</b>
              </label>
              <select
                id="package"
                name="PackageId"
                value={CartData?.PackageId}
                onChange={handleChange}
                className="form-control"
              >
                <option value="">--Select Package --</option>
                {PackageRec?.map((ev) => (
                  <option value={ev?._id} key={ev?._id}>
                    {ev?.Name}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-group ">
              <label>
                Class Mode:<b>*</b>
              </label>
              <select
                name="ClassMode"
                value={CartData?.ClassMode}
                onChange={handleChange}
                className="form-control"
              >
                <option value="">--Select Class Mode --</option>
                <ClassModeDropDown />
              </select>
            </div>

            {CartData?.ClassMode == 1 || !CartData?.ClassMode ? (
              ""
            ) : (
              <div className="form-group ">
                <label>
                  Class Location:<b>*</b>
                </label>
                <select
                  name="classLocation"
                  value={CartData?.classLocation}
                  onChange={handleChange}
                  className="form-control"
                >
                  <option value="">--Select Class Mode --</option>
                  <ClassCenterDropDown />
                </select>
              </div>
            )}

            <div className="form-group first-input">
              <label>
                Timings:<b>*</b>
              </label>
              <select
                name="Timings"
                value={CartData?.Timings}
                onChange={handleChange}
                className="form-control"
              >
                <option value="">--Select Timings --</option>
                <TimingsDropDown
                  centerlocation={CartData?.classLocation}
                  classmodeid={CartData?.ClassMode}
                  courseid={CartData?.CourseId}
                />
                <option value="Custom">Custom</option>
              </select>
            </div>

            {CartData?.Timings === "Custom" ? (
              <div className="form-group first-input">
                <label>
                  Please Share Your Preferred Slot:<b>*</b>
                </label>
                <input
                  type="text"
                  placeholder="Enter Your preferred slot"
                  name="CustomTimings"
                  value={CartData?.CustomTimings}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
            ) : (
              ""
            )}

            {/* <div
              className="contact-footer w-100"
              style={{
                padding: "50px 25px",
                backgroundColor: "transparent",
              }}
            >
              <div>
                <span className="" style={{ color: "green", fontSize: "13px" }}>
                  {success}
                </span>
                <span className="" style={{ color: "red", fontSize: "13px" }}>
                  {error}
                </span>
              </div>
            </div> */}

            <div className="contact-footer w-100">
              <button type="button" className="btn" onClick={handleSubmit}>
                {loading ? "Loading..." : "Add"}
              </button>

              {!loading ? (
                <button
                  type="button"
                  className="btn btn-deep-orange waves-effect waves-light"
                  onClick={() => handleCancelCart()}
                >
                  Cancel
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
