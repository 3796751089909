export const gurullyJson = {
  UserId: "",
  Password: "",
  ValidityFrom: "",
  ValidityTill: "",
};

export const gurullyContactJson = {
  GurullyId: "",
  PackageId: "",
  ContactId: "",
};
