import {
  STUDY_LEVEL_DELETE_FAIL,
  STUDY_LEVEL_DELETE_REQUEST,
  STUDY_LEVEL_DELETE_RESET,
  STUDY_LEVEL_DELETE_SUCCESS,
  STUDY_LEVEL_INSERT_FAIL,
  STUDY_LEVEL_INSERT_REQUEST,
  STUDY_LEVEL_INSERT_RESET,
  STUDY_LEVEL_INSERT_SUCCESS,
  STUDY_LEVEL_LIST_FAIL,
  STUDY_LEVEL_LIST_REQUEST,
  STUDY_LEVEL_LIST_SUCCESS,
  STUDY_LEVEL_UPDATE_FAIL,
  STUDY_LEVEL_UPDATE_REQUEST,
  STUDY_LEVEL_UPDATE_RESET,
  STUDY_LEVEL_UPDATE_SUCCESS,
} from "../Constants/studyLevelConstants";

export const studyLevelListReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case STUDY_LEVEL_LIST_REQUEST:
      return { loading: true };
    case STUDY_LEVEL_LIST_SUCCESS:
      return { loading: false, studyLevelRecs: payload };
    case STUDY_LEVEL_LIST_FAIL:
      return { loading: false, error: payload };
    default:
      return state;
  }
};

export const studyLevelSaveReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case STUDY_LEVEL_INSERT_REQUEST:
      return { loading: true };
    case STUDY_LEVEL_INSERT_SUCCESS:
      return { loading: false, success: payload };
    case STUDY_LEVEL_INSERT_FAIL:
      return { loading: false, error: payload };
    case STUDY_LEVEL_INSERT_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const studyLevelUpdateReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case STUDY_LEVEL_UPDATE_REQUEST:
      return { loading: true };
    case STUDY_LEVEL_UPDATE_SUCCESS:
      return { loading: false, success: payload };
    case STUDY_LEVEL_UPDATE_FAIL:
      return { loading: false, error: payload };
    case STUDY_LEVEL_UPDATE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const studyLevelDeleteReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case STUDY_LEVEL_DELETE_REQUEST:
      return { loading: true };
    case STUDY_LEVEL_DELETE_SUCCESS:
      return { loading: false, success: payload };
    case STUDY_LEVEL_DELETE_FAIL:
      return { loading: false, error: payload };
    case STUDY_LEVEL_DELETE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};
