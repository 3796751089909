export const RESUME_LIST_REQUEST = 'RESUME_LIST_REQUEST'
export const RESUME_LIST_SUCCESS = 'RESUME_LIST_SUCCESS'
export const RESUME_LIST_FAIL = 'RESUME_LIST_FAIL'

export const RESUME_INSERT_REQUEST = 'RESUME_INSERT_REQUEST'
export const RESUME_INSERT_SUCCESS = 'RESUME_INSERT_SUCCESS'
export const RESUME_INSERT_FAIL = 'RESUME_INSERT_FAIL'
export const RESUME_INSERT_RESET = 'RESUME_INSERT_RESET'

export const RESUME_UPDATE_REQUEST = 'RESUME_UPDATE_REQUEST'
export const RESUME_UPDATE_SUCCESS = 'RESUME_UPDATE_SUCCESS'
export const RESUME_UPDATE_FAIL = 'RESUME_UPDATE_FAIL'
export const RESUME_UPDATE_RESET = 'RESUME_UPDATE_RESET'

export const RESUME_DELETE_REQUEST = 'RESUME_DELETE_REQUEST'
export const RESUME_DELETE_SUCCESS = 'RESUME_DELETE_SUCCESS'
export const RESUME_DELETE_FAIL = 'RESUME_DELETE_FAIL'
export const RESUME_DELETE_RESET = 'RESUME_DELETE_RESET'
