import {
  GENDER_DELETE_FAIL,
  GENDER_DELETE_REQUEST,
  GENDER_DELETE_SUCCESS,
  GENDER_INSERT_FAIL,
  GENDER_INSERT_REQUEST,
  GENDER_INSERT_SUCCESS,
  GENDER_LIST_FAIL,
  GENDER_LIST_REQUEST,
  GENDER_LIST_SUCCESS,
  GENDER_UPDATE_FAIL,
  GENDER_UPDATE_REQUEST,
  GENDER_UPDATE_SUCCESS,
} from "../Constants/genderConstants";
import api from "../core/api";

export const listGender = () => async (dispatch) => {
  try {
    dispatch({ type: GENDER_LIST_REQUEST });
    const { data } = await api.get(`/gender`);
    dispatch({
      type: GENDER_LIST_SUCCESS,
      payload: data.Result,
    });
  } catch (error) {
    dispatch({
      type: GENDER_LIST_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const saveGenderDetails =
  (inputJson) => async (dispatch) => {
    try {
      dispatch({ type: GENDER_INSERT_REQUEST });
      const { data } = await api.post(`/gender`, inputJson);
      dispatch({
        type: GENDER_INSERT_SUCCESS,
        payload: data.Result,
      });
    } catch (error) {
      dispatch({
        type: GENDER_INSERT_FAIL,
        payload:
          error.response && error.response.data.Result
            ? error.response.data.Result
            : error.message,
      });
    }
  };

export const updateGenderDetails =
  (id, inputJson) => async (dispatch) => {
    try {
      dispatch({ type: GENDER_UPDATE_REQUEST });
      const { data } = await api.put(`/gender/${id}`, inputJson);
      dispatch({
        type: GENDER_UPDATE_SUCCESS,
        payload: data.Result,
      });
    } catch (error) {
      dispatch({
        type: GENDER_UPDATE_FAIL,
        payload:
          error.response && error.response.data.Result
            ? error.response.data.Result
            : error.message,
      });
    }
  };

export const deleteGenderDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: GENDER_DELETE_REQUEST });
    const { data } = await api.delete(`/gender/${id}`);
    dispatch({
      type: GENDER_DELETE_SUCCESS,
      payload: data.Result,
    });
  } catch (error) {
    dispatch({
      type: GENDER_DELETE_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};
