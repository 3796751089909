export const MATERIAL_CREATE_REQ = "MATERIAL_CREATE_REQ";
export const MATERIAL_CREATE_FAILED = "MATERIAL_CREATE_FAILED";
export const MATERIAL_CREATE_SUCCESS = "MATERIAL_CREATE_SUCCESS";
export const MATERIAL_CREATE_RESET = "MATERIAL_CREATE_RESET";

export const MATERIAL_LIST_REQ = "MATERIAL_LIST_REQ";
export const MATERIAL_LIST_SUCCESS = "MATERIAL_LIST_SUCCESS";
export const MATERIAL_LIST_FAILED = "MATERIAL_LIST_FAILED";


export const MATERIAL_TOTAL_REQ = "MATERIAL_TOTAL_REQ";
export const MATERIAL_TOTAL_SUCCESS = "MATERIAL_TOTAL_SUCCESS";
export const MATERIAL_TOTAL_FAILED = "MATERIAL_TOTAL_FAILED";

export const MATERIAL_DETAILS_REQ = "MATERIAL_DETAILS_REQ";
export const MATERIAL_DETAILS_SUCCESS = "MATERIAL_DETAILS_SUCCESS";
export const MATERIAL_DETAILS_FAILED = "MATERIAL_DETAILS_FAILED";
export const MATERIAL_DETAILS_RESET = "MATERIAL_DETAILS_RESET";

export const MATERIAL_UPDATE_REQ = "MATERIAL_UPDATE_REQ";
export const MATERIAL_UPDATE_SUCCESS = "MATERIAL_UPDATE_SUCCESS";
export const MATERIAL_UPDATE_FAILED = "MATERIAL_UPDATE_FAILED";
export const MATERIAL_UPDATE_RESET = "MATERIAL_UPDATE_RESET";

export const MATERIAL_REMOVE_REQ = "MATERIAL_REMOVE_REQ";
export const MATERIAL_REMOVE_SUCCESS = "MATERIAL_REMOVE_SUCCESS";
export const MATERIAL_REMOVE_FAILED = "MATERIAL_REMOVE_FAILED";
export const MATERIAL_REMOVE_RESET = "MATERIAL_REMOVE_RESET";
