import { ContactApplicationList } from "../Actions/NewApplicationAction";
import {
  APPLICATION_SUBMISSION_STATUS_FAILED,
  APPLICATION_SUBMISSION_STATUS_REQ,
  APPLICATION_SUBMISSION_STATUS_RESET,
  APPLICATION_SUBMISSION_STATUS_SUCCESS,
  CONTACT_ADDITIONAL_DET_ERROR,
  CONTACT_ADDITIONAL_DET_REQ,
  CONTACT_ADDITIONAL_DET_RESET,
  CONTACT_ADDITIONAL_DET_SUCCESS,
  CONTACT_WORK_DETAILS_CREATE_FAIL,
  CONTACT_WORK_DETAILS_CREATE_REQ,
  CONTACT_WORK_DETAILS_CREATE_RESET,
  CONTACT_WORK_DETAILS_CREATE_SUCCESS,
  CONTACT_WORK_DETAILS_LIST_FAIL,
  CONTACT_WORK_DETAILS_LIST_REQ,
  CONTACT_WORK_DETAILS_LIST_SUCCESS,
  NEW_APPLICATION_CREATE_FAILED,
  NEW_APPLICATION_CREATE_REQ,
  NEW_APPLICATION_CREATE_RESET,
  NEW_APPLICATION_CREATE_SUCCESS,
  NEW_APPLICATION_LIST_FAILED,
  NEW_APPLICATION_LIST_REQ,
  NEW_APPLICATION_LIST_SUCCESS,
  NEW_APPLICATION_TEMP_CREATE_FAILED,
  NEW_APPLICATION_TEMP_CREATE_REQ,
  NEW_APPLICATION_TEMP_CREATE_RESET,
  NEW_APPLICATION_TEMP_CREATE_SUCCESS,
  NEW_CONTACT_APPLICATION_LIST_FAILED,
  NEW_CONTACT_APPLICATION_LIST_REQ,
  NEW_CONTACT_APPLICATION_LIST_SUCCESS,
} from "../Constants/NewApplicationConstant";

export const newApplicationCreateReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case NEW_APPLICATION_CREATE_REQ: {
      return { loading: true };
    }
    case NEW_APPLICATION_CREATE_SUCCESS: {
      return { loading: false, success: payload };
    }

    case NEW_APPLICATION_CREATE_FAILED: {
      return { loading: false, error: payload };
    }

    case NEW_APPLICATION_CREATE_RESET: {
      return { laoding: false, success: "" };
    }

    default:
      return state;
  }
};

export const newApplicationListReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case NEW_APPLICATION_LIST_REQ: {
      return { loading: true };
    }

    case NEW_APPLICATION_LIST_SUCCESS: {
      return { loading: false, newApplicationRec: payload };
    }

    case NEW_APPLICATION_LIST_FAILED: {
      return { loading: false, error: payload };
    }
    default:
      return state;
  }
};

export const createApplicationTempReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case NEW_APPLICATION_TEMP_CREATE_REQ: {
      return { loading: true };
    }

    case NEW_APPLICATION_TEMP_CREATE_SUCCESS: {
      return { loading: false, ApplicationTempId: payload };
    }

    case NEW_APPLICATION_TEMP_CREATE_FAILED: {
      return { loading: false, error: payload };
    }

    case NEW_APPLICATION_TEMP_CREATE_RESET: {
      return { loading: false, ApplicationTempId: "" };
    }

    default:
      return state;
  }
};

export const contactAdditionalDetReducer = (
  state = {},
  { type, payload, ContactFamilyDet, ContactDeclarationDet }
) => {
  switch (type) {
    case CONTACT_ADDITIONAL_DET_REQ: {
      return { loading: true };
    }

    case CONTACT_ADDITIONAL_DET_SUCCESS: {
      return {
        loading: false,
        ContactAdditionalRec: payload,
        ContactFamilyDet,
        ContactDeclarationDet,
      };
    }

    case CONTACT_ADDITIONAL_DET_ERROR: {
      return { loading: false, error: payload };
    }

    case CONTACT_ADDITIONAL_DET_RESET: {
      return { loading: false, ContactAdditionalRec: "" };
    }

    default:
      return state;
  }
};

export const Contact_Work_Details_list_Reducer = (
  state = {},
  { type, payload }
) => {
  switch (type) {
    case CONTACT_WORK_DETAILS_LIST_REQ: {
      return { loading: true };
    }

    case CONTACT_WORK_DETAILS_LIST_SUCCESS: {
      return { loading: false, Contact_Work_Rec: payload };
    }

    case CONTACT_WORK_DETAILS_LIST_FAIL: {
      return { loading: false, error: payload };
    }

    default:
      return state;
  }
};

export const Create_Contact_Work_Details_list_Reducer = (
  state = {},
  { type, payload }
) => {
  switch (type) {
    case CONTACT_WORK_DETAILS_CREATE_REQ: {
      return { loading: true };
    }

    case CONTACT_WORK_DETAILS_CREATE_SUCCESS: {
      return { loading: false, success: payload };
    }

    case CONTACT_WORK_DETAILS_CREATE_FAIL: {
      return { loading: false, error: payload };
    }

    case CONTACT_WORK_DETAILS_CREATE_RESET: {
      return { loading: false, success: "" };
    }

    default:
      return state;
  }
};

export const ContactApplicationList_Reducer = (
  state = {},
  { type, payload, Total }
) => {
  switch (type) {
    case NEW_CONTACT_APPLICATION_LIST_REQ: {
      return { loading: true };
    }

    case NEW_CONTACT_APPLICATION_LIST_SUCCESS: {
      return { loading: false, ContactApplicationListDet: payload, Total };
    }

    case NEW_CONTACT_APPLICATION_LIST_FAILED: {
      return { loading: false, error: payload };
    }

    default:
      return state;
  }
};

export const ApplicationSubmissionStatus_Reducer = (
  state = {},
  { type, payload }
) => {
  switch (type) {
    case APPLICATION_SUBMISSION_STATUS_REQ: {
      return { loading: true };
    }

    case APPLICATION_SUBMISSION_STATUS_SUCCESS: {
      return { loading: false, SubmissionStatus: payload };
    }

    case APPLICATION_SUBMISSION_STATUS_FAILED: {
      return { loading: false, error: payload };
    }

    case APPLICATION_SUBMISSION_STATUS_RESET: {
      return { SubmissionStatus: "", laoding: false };
    }

    default:
      return state;
  }
};
