import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Package_List } from "../../Actions/PacakgeAction";

const PackageDropDown = React.memo(() => {
  const dispatch = useDispatch();
  const { PackageRec } = useSelector((s) => s.Package_List_Reducer);
  useEffect(() => {
    dispatch(Package_List({ page_no: 1, page_size: 100 }));
  }, [dispatch]);
  return PackageRec ? (
    PackageRec?.map((recs) => (
      <option value={recs._id} key={recs._id}>
        {recs?.Name}
      </option>
    ))
  ) : (
    <></>
  );
});
export default PackageDropDown;
