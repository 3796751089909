import {
  MEETING_INTERFACE_DELETE_FAIL,
  MEETING_INTERFACE_DELETE_REQUEST,
  MEETING_INTERFACE_DELETE_RESET,
  MEETING_INTERFACE_DELETE_SUCCESS,
  MEETING_INTERFACE_INSERT_FAIL,
  MEETING_INTERFACE_INSERT_REQUEST,
  MEETING_INTERFACE_INSERT_RESET,
  MEETING_INTERFACE_INSERT_SUCCESS,
  MEETING_INTERFACE_LIST_FAIL,
  MEETING_INTERFACE_LIST_REQUEST,
  MEETING_INTERFACE_LIST_SUCCESS,
  MEETING_INTERFACE_UPDATE_FAIL,
  MEETING_INTERFACE_UPDATE_REQUEST,
  MEETING_INTERFACE_UPDATE_RESET,
  MEETING_INTERFACE_UPDATE_SUCCESS,
} from "../Constants/meetingInterfaceConstants";

export const meetingInterfaceListReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case MEETING_INTERFACE_LIST_REQUEST:
      return { loading: true };
    case MEETING_INTERFACE_LIST_SUCCESS:
      return { loading: false, meetingInterfaceRecs: payload };
    case MEETING_INTERFACE_LIST_FAIL:
      return { loading: false, error: payload };
    default:
      return state;
  }
};

export const meetingInterfaceSaveReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case MEETING_INTERFACE_INSERT_REQUEST:
      return { loading: true };
    case MEETING_INTERFACE_INSERT_SUCCESS:
      return { loading: false, success: payload };
    case MEETING_INTERFACE_INSERT_FAIL:
      return { loading: false, error: payload };
    case MEETING_INTERFACE_INSERT_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const meetingInterfaceUpdateReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case MEETING_INTERFACE_UPDATE_REQUEST:
      return { loading: true };
    case MEETING_INTERFACE_UPDATE_SUCCESS:
      return { loading: false, success: payload };
    case MEETING_INTERFACE_UPDATE_FAIL:
      return { loading: false, error: payload };
    case MEETING_INTERFACE_UPDATE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const meetingInterfaceDeleteReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case MEETING_INTERFACE_DELETE_REQUEST:
      return { loading: true };
    case MEETING_INTERFACE_DELETE_SUCCESS:
      return { loading: false, success: payload };
    case MEETING_INTERFACE_DELETE_FAIL:
      return { loading: false, error: payload };
    case MEETING_INTERFACE_DELETE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};
