import {
  CONTACT_RATING_INSERT_FAIL,
  CONTACT_RATING_INSERT_REQUEST,
  CONTACT_RATING_INSERT_SUCCESS,
  CONTACT_RATING_DELETE_FAIL,
  CONTACT_RATING_DELETE_REQUEST,
  CONTACT_RATING_DELETE_SUCCESS,
  CONTACT_RATING_LIST_FAIL,
  CONTACT_RATING_LIST_REQUEST,
  CONTACT_RATING_LIST_SUCCESS,
  CONTACT_RATING_UPDATE_FAIL,
  CONTACT_RATING_UPDATE_REQUEST,
  CONTACT_RATING_UPDATE_SUCCESS,
} from "../Constants/contactRatingConstants";

// Contact Rating List
export const contactRatingListReducers = (state = {}, action) => {
  switch (action.type) {
    case CONTACT_RATING_LIST_REQUEST:
      return { loading: true };
    case CONTACT_RATING_LIST_SUCCESS:
      return { loading: false, contactRatingRecs: action.payload };
    case CONTACT_RATING_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// Contact Rating Create
export const contactRatingCreateReducers = (state = {}, action) => {
  switch (action.type) {
    case CONTACT_RATING_INSERT_REQUEST:
      return { loading: true };
    case CONTACT_RATING_INSERT_SUCCESS:
      return { loading: false, success: action.payload };
    case CONTACT_RATING_INSERT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// Contact Rating Update
export const contactRatingUpdateReducers = (state = {}, action) => {
  switch (action.type) {
    case CONTACT_RATING_UPDATE_REQUEST:
      return { loading: true };
    case CONTACT_RATING_UPDATE_SUCCESS:
      return { loading: false, success: action.payload };
    case CONTACT_RATING_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// Contact Rating Delete
export const contactRatingDeleteReducers = (state = {}, action) => {
  switch (action.type) {
    case CONTACT_RATING_DELETE_REQUEST:
      return { loading: true };
    case CONTACT_RATING_DELETE_SUCCESS:
      return { loading: false, success: action.payload };
    case CONTACT_RATING_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
