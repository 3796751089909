import { Link } from "react-router-dom";

export default function DeletePopup({ OpenNext, setOpenNext, DeleteData }) {
  const handleCancel1 = () => {
    setOpenNext(false);
  };

  const handleDelete = () => {
    DeleteData?.Delete();
    setOpenNext(false);
  };

  return (
    <div
      id="CreatContact"
      className={OpenNext ? "overlay-note w-100" : "overlay-note"}
      style={{ top: "10%" }}
    >
      <div className="overlay-note-content mblVwe">
        <div className="note-title">
          <h4 className="text-left text-white pl-3">Confirmation</h4>
          <div className="closebtn">
            <Link id="hide-note-max" className="text-right text-white">
              <i className="fas fa-minus"></i>
            </Link>
            <Link
              className="text-right text-white"
              onClick={() => setOpenNext(false)}
            >
              <i className="fas fa-times"></i>
            </Link>
          </div>
        </div>
        <div
          style={{ padding: "20px" }}
          className="Send_Email_Form"
          id="noteBody"
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <div>
              {" "}
              <p>{DeleteData?.msg} </p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <button className="cst_btn" onClick={() => handleDelete()}>
                Yes
              </button>{" "}
              <button className="cst_btn" onClick={() => handleCancel1()}>
                No
              </button>{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
