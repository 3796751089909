import SidePanel from "../../Components/SidePanel";
import TopBar from "../../Components/TopBar";
import { useDispatch, useSelector } from "react-redux";
import Meta from "../../Components/Meta";
import { useCallback, useEffect, useState } from "react";
import { contactJson } from "../../Models/ContactModels";
import { toastOptions } from "../../Models/ToastEditiorOption";
import { toast } from "react-toastify";
import { MATERIAL_CREATE_RESET } from "../../Constants/materialConstants";
import { orderDetailsData } from "../../Actions/orderAction";
import { useParams } from "react-router-dom";
import SEDLoading from "../../Components/SEDLoading";

export default function OrderDetails() {
  const [orderCreateData, setorderCreateData] = useState(contactJson);
  const [createSec, setCreateSec] = useState(false);
  const [OtherDet, setOtherDet] = useState({});

  const { loading, error, OrderDet } = useSelector(
    (s) => s.orderDetailsDataReducer
  );

  const { id } = useParams();

  const {
    loading: loadingsv,
    error: errorsv,
    success: successsv,
  } = useSelector((s) => s.createMaterialReducer);

  const dispatch = useDispatch();

  const MetaDes = { title: "Testfify - Orders" };

  const Get_Details = useCallback(() => {
    dispatch(orderDetailsData(id));
  }, [dispatch]);

  useEffect(() => {
    Get_Details();
  }, [Get_Details]);

  useEffect(() => {
    if (successsv) {
      setCreateSec(false);
      setorderCreateData(contactJson);
      toast.success(successsv, toastOptions);
      Get_Details();
      setTimeout(() => {
        dispatch({ type: MATERIAL_CREATE_RESET });
      }, 2000);
    }
    if (errorsv) {
      toast.success(errorsv, toastOptions);
    }
  }, [successsv, errorsv, dispatch]);

  const Get_All_Nested_Values = (obj) => {
    let result = {};

    const Getvalue=(obj)=>{
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          if (
            typeof obj[key] === "object" &&
            obj[key] !== null &&
            obj[key] !== undefined
          ) {
            // If the value is an object, keep its keys at the next level
            for (const subKey in obj[key]) {
              // if(obj[key][subKey]){}
              if (obj[key].hasOwnProperty(subKey)) {
                result[`${subKey}`] = obj[key][subKey];
              }
            }
          } else {
            // Otherwise, keep it at the top level
            result[key] = obj[key];
          }
        }
      }
    }
    Getvalue(obj)

    setOtherDet(result);
  };

  useEffect(() => {
    if (OrderDet?.transactionDetails) {
      Get_All_Nested_Values(OrderDet?.transactionDetails);
    }
  }, [OrderDet]);
  

  return (
    <>
      <Meta MetaDes={MetaDes} />
      {loading ? <SEDLoading /> : ""}
      <SidePanel page={"Orders"} />
      <section className="home-section">
        <TopBar Heading="Order Details" />
        <div className="home-content" style={{ paddingTop: "70px" }}>
          <div className="Contact-Middle">
            <div className="Contact_Top_Header">
              {/* <div className="home-title float-left"></div> */}
              {/* <div style={{ display: "flex", gap: "20px" }} className="">
                <div className="input-group search-contacts">
                  <input
                    type="Search"
                    className="form-control"
                    name="searchtxt"
                    style={{ display: "block" }}
                    // value={Search}
                    // onChange={(e) => {
                    //   if (e.target.value === "") {
                    //     setFilterData({ ...FilterData, searchtxt: "" });
                    //     setorderFilterData({
                    //       ...orderFilterData,
                    //       searchtxt: "",
                    //     });
                    //   }
                    //   setSearch(e.target.value);
                    // }}
                    // onKeyDown={(e) => {
                    //   if (
                    //     e.key === "Enter" &&
                    //     Search !== "" &&
                    //     Search !== undefined
                    //   ) {
                    //     setFilterData({ ...FilterData, searchtxt: Search });
                    //     setorderFilterData({
                    //       ...orderFilterData,
                    //       searchtxt: Search,
                    //     });
                    //   }
                    // }}
                    placeholder="search for order"
                  />
                  <i
                    className="fa fa-search"
                    // onClick={() => {
                    //   if (Search !== "" && Search !== undefined) {
                    //     setFilterData({ ...FilterData, searchtxt: Search });
                    //     setorderFilterData({
                    //       ...orderFilterData,
                    //       searchtxt: Search,
                    //     });
                    //   }
                    // }}
                    aria-hidden="true"
                  ></i>
                </div>{" "}
              </div> */}
            </div>
            {/* <hr /> */}
            <div className="row" style={{ padding:window.innerWidth<=480 ? "0 10px" : "0 30px" }}>
              {/*  */}
              <div>{error}</div>

              <div className="Order_Details_card">
                <div>
                  <h4> Order Details</h4>
                </div>

                <div className="Order_Top_Details">
                 
                    <div>
                      <span>First Name:</span>
                      <span>{OrderDet?.ContactId?.First_Name}</span>
                    </div>
                    <div>
                      <span>Last Name:</span>
                      <span>{OrderDet?.ContactId?.Last_Name}</span>
                    </div>{" "}
                    <div>
                      <span>Email Address:</span>
                      <span>{OrderDet?.ContactId?.EmailAddress}</span>
                    </div>
                 

                 
                    <div>
                      <span>Phone No:</span>
                      <span>{OrderDet?.ContactId?.PhoneNo}</span>
                    </div>
                    <div>
                      <span>Package Name:</span>
                      <span>
                        {OrderDet?.PackageId?.Name
                          ? OrderDet?.PackageId?.Name
                          : "N/A"}
                      </span>
                    </div>
                    <div>
                      <span>Package Amount:</span>
                      <span>
                        {OrderDet?.PackageId?.ActualCost
                          ? `₹${OrderDet?.PackageId?.ActualCost}`
                          : "N/A"}
                      </span>
                    </div>
                  

                  
                    <div>
                      <span>Transaction ID:</span>
                      <span>{OrderDet?.transactionId}</span>
                    </div>

                    <div>
                      <span>Payment Status:</span>
                      <span>{OrderDet?.transactionStatus}</span>
                    </div>

                    <div>
                      <span>Amount:</span>
                      <span>₹{OrderDet?.Amount}</span>
                    </div>
                  
                  {/* {OrderDet?.transactionDetails ? (
                    <>
                      <div>
                        <span>Payment Method</span>
                        <span>
                          {OrderDet?.transactionDetails?.paymentMethod}
                        </span>
                      </div>
                      <div>
                        <span>Comments</span>
                        <span>{OrderDet?.transactionDetails?.Comments}</span>
                      </div>
                    </>
                  ) : (
                    ""
                  )} */}
                </div>
              </div>
              

              { Object.keys(OtherDet).length != 0 ? (
                <div className="Order_Details_card2">
                  <div>
                    <h4>Transaction Details</h4>
                  </div>

                  <div className="Other_Tr_Det">
                    {OtherDet
                      ? Object.keys(OtherDet)?.map((key, index) =>
                          typeof OtherDet[key] == "object" &&
                          OtherDet[key] !== null ? (
                            Object.keys(OtherDet[key])?.map((key2, index) => (
                              <div>
                                <span>{key2}: </span>
                                <span>
                                  {OtherDet[key][key2] || OtherDet[key][key2] ==0
                                    ? OtherDet[key][key2]
                                    : "N/A"}
                                </span>
                              </div>
                            ))
                          ) : (
                            <div>
                              <span>{key}: </span>
                              {typeof OtherDet[key] == "boolean" ? (
                                <span>
                                  {OtherDet[key] ? "True" : "false"}
                                </span>
                              ) : (
                                <span>
                                  {OtherDet[key] || OtherDet[key]==0 ? OtherDet[key] : "N/A"}
                                </span>
                              )}
                            </div>
                          )
                        )
                      : ""}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
