import {
  HOW_YOU_HEARD_ABOUT_US_DELETE_FAIL,
  HOW_YOU_HEARD_ABOUT_US_DELETE_REQUEST,
  HOW_YOU_HEARD_ABOUT_US_DELETE_RESET,
  HOW_YOU_HEARD_ABOUT_US_DELETE_SUCCESS,
  HOW_YOU_HEARD_ABOUT_US_INSERT_FAIL,
  HOW_YOU_HEARD_ABOUT_US_INSERT_REQUEST,
  HOW_YOU_HEARD_ABOUT_US_INSERT_RESET,
  HOW_YOU_HEARD_ABOUT_US_INSERT_SUCCESS,
  HOW_YOU_HEARD_ABOUT_US_LIST_FAIL,
  HOW_YOU_HEARD_ABOUT_US_LIST_REQUEST,
  HOW_YOU_HEARD_ABOUT_US_LIST_SUCCESS,
  HOW_YOU_HEARD_ABOUT_US_UPDATE_FAIL,
  HOW_YOU_HEARD_ABOUT_US_UPDATE_REQUEST,
  HOW_YOU_HEARD_ABOUT_US_UPDATE_RESET,
  HOW_YOU_HEARD_ABOUT_US_UPDATE_SUCCESS,
} from "../Constants/howYouHeardAboutUsConstants";

export const howyouheardaboutusListReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case HOW_YOU_HEARD_ABOUT_US_LIST_REQUEST:
      return { loading: true };
    case HOW_YOU_HEARD_ABOUT_US_LIST_SUCCESS:
      return { loading: false, HowYouHeardAboutUsRecs: payload };
    case HOW_YOU_HEARD_ABOUT_US_LIST_FAIL:
      return { loading: false, error: payload };
    default:
      return state;
  }
};

export const howyouheardaboutusSaveReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case HOW_YOU_HEARD_ABOUT_US_INSERT_REQUEST:
      return { loading: true };
    case HOW_YOU_HEARD_ABOUT_US_INSERT_SUCCESS:
      return { loading: false, success: payload };
    case HOW_YOU_HEARD_ABOUT_US_INSERT_FAIL:
      return { loading: false, error: payload };
    case HOW_YOU_HEARD_ABOUT_US_INSERT_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const howyouheardaboutusUpdateReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case HOW_YOU_HEARD_ABOUT_US_UPDATE_REQUEST:
      return { loading: true };
    case HOW_YOU_HEARD_ABOUT_US_UPDATE_SUCCESS:
      return { loading: false, success: payload };
    case HOW_YOU_HEARD_ABOUT_US_UPDATE_FAIL:
      return { loading: false, error: payload };
    case HOW_YOU_HEARD_ABOUT_US_UPDATE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const howyouheardaboutusDeleteReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case HOW_YOU_HEARD_ABOUT_US_DELETE_REQUEST:
      return { loading: true };
    case HOW_YOU_HEARD_ABOUT_US_DELETE_SUCCESS:
      return { loading: false, success: payload };
    case HOW_YOU_HEARD_ABOUT_US_DELETE_FAIL:
      return { loading: false, error: payload };
    case HOW_YOU_HEARD_ABOUT_US_DELETE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};
