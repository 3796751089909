import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SettingLeftSection from "../Components/SettingLeftSection";
import {
  listCallOutcome,
  updateCallOutcomeDetails,
  deleteCallOutcomeDetails,
  saveCallOutcomeDetails,
} from "../../Actions/callOutcomeActions";
import {
  CALL_OUTCOME_INSERT_RESET,
  CALL_OUTCOME_UPDATE_RESET,
  CALL_OUTCOME_DELETE_RESET,
} from "../../Constants/callOutcomeConstants";
import { callOutcomeJson } from "../../Models/CallOutcomerModels";
import DeletePopup from "../Components/DeletePoup";
import { toast, ToastContainer } from "react-toastify";
import { toastOptions } from "../../Models/ToastEditiorOption";
import TopBar from "../../Components/TopBar";
import SidePanel from "../../Components/SidePanel";

export default function CallOutCome() {
  const dispatch = useDispatch();
  const [callOutcomeData, setcallOutcomeData] = useState(callOutcomeJson);
  const { loading, error, calloutcomeRec } = useSelector(
    (s) => s.callOutcomeList
  );
  const [OpenNext, setOpenNext] = useState(false);
  const [DeleteAlert, setDeleteAlert] = useState({ msg: "", Delete: () => "" });
  const [btnText, setbtnText] = useState("Save");
  const [cancelHide, setcancelHide] = useState(false);

  const {
    loading: loadingsv,
    error: errorsv,
    success: successsv,
  } = useSelector((s) => s.callOutcomeSave);

  const {
    loading: loadingud,
    error: errorud,
    success: successud,
  } = useSelector((s) => s.callOutcomeUpdate);

  const {
    loading: loadingdet,
    error: errordet,
    success: successdet,
  } = useSelector((s) => s.callOutcomeDelete);

  const handleChange = (e) => {
    e.preventDefault();
    setcallOutcomeData({ ...callOutcomeData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    toast.dismiss();
    if (btnText === "Save") {
      dispatch(saveCallOutcomeDetails(callOutcomeData));
    } else if (btnText === "Update") {
      dispatch(updateCallOutcomeDetails(callOutcomeData._id, callOutcomeData));
      setbtnText("Save");
      setcancelHide(false);
    } else alert("Error on handleSubmit");
  };

  const getcallOutcomes = () => {
    dispatch(listCallOutcome());
  };

  const handleEdit = (e, _id, CallOutcome) => {
    toast.dismiss();
    e.preventDefault();
    setbtnText("Update");
    setcancelHide(true);
    setcallOutcomeData({ ...callOutcomeData, CallOutcome, _id });
  };

  const handleDelete = (e, _id) => {
    toast.dismiss();
    e.preventDefault();
    if (_id) {
      dispatch(deleteCallOutcomeDetails(_id));
    }
  };

  const handleCancel = (e) => {
    e.preventDefault();
    setcallOutcomeData({ ...callOutcomeJson });
    setbtnText("Save");
    setcancelHide(false);
  };

  useEffect(() => {
    if (calloutcomeRec === undefined) {
      getcallOutcomes();
    }
  }, [calloutcomeRec]);

  useEffect(() => {
    if (successdet)
      // setTimeout(() => {
      dispatch({ type: CALL_OUTCOME_DELETE_RESET });
    getcallOutcomes();
    // }, 1000);
    if (successud)
      // setTimeout(() => {
      dispatch({ type: CALL_OUTCOME_UPDATE_RESET });
    getcallOutcomes();
    // }, 1000);
    if (successsv)
      // setTimeout(() => {
      dispatch({ type: CALL_OUTCOME_INSERT_RESET });
    getcallOutcomes();
    // }, 1000);
    setcallOutcomeData({ ...callOutcomeJson });

    if (successdet || successsv || successud) {
      toast.success(successdet || successsv || successud, toastOptions);
    }
  }, [successdet, successsv, dispatch, successud]);

  useEffect(() => {
    if (errordet) {
      toast.error(errordet, toastOptions);
      dispatch({ type: CALL_OUTCOME_DELETE_RESET });
    }

    if (errorsv) {
      toast.error(errorsv, toastOptions);
      dispatch({ type: CALL_OUTCOME_INSERT_RESET });
    }
    if (errorud) {
      toast.error(errorud, toastOptions);
      dispatch({ type: CALL_OUTCOME_UPDATE_RESET });
    }
  }, [errorsv, errordet, dispatch, errorud]);

  return (
    <>
      <SidePanel page={"Call Outcome"} />
      <section className="home-section">
        <TopBar Heading={"Call Outcome"} />
        <div className="home-content" style={{ paddingTop: "100px" }}>
          <div className="content-body">
            {/* <!--Body-content--> */}
            <div className="container-fluid">
              <div className="heading-title">
                <div className="row">
                  <div className="col-md-12">
                    <div className="home-title float-left">
                      {/* <nav className="sub-nav-bcrumb" aria-label="breadcrumb">
                        <ol className="breadcrumb">
                          <li className="breadcrumb-item">
                            <Link to="/settings">Settings</Link>
                          </li>
                          <li
                            className="breadcrumb-item active"
                            aria-current="page"
                          >
                            Call Outcome
                          </li>
                        </ol>
                      </nav> */}
                    </div>
                    <div className="home-table-search float-right d-flex">
                      {/* <!--    <button>right side</button> --> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="inner-body settings-details">
                <div className="row">
                  <div className="col-md-3">
                    <SettingLeftSection />
                  </div>

                  <div className="col-md-9">
                    <div className="body-content-with-md-9">
                      <div>
                        <div className="container">
                          <div className="row">
                            <div className="col-md-12 text-left body-content-with-md-9-title">
                              <h4>Call Outcome </h4>
                            </div>
                          </div>
                          <div className="row mt-3 px-5">
                            <div className="col-md-3 pt-1">
                              <label className="form-group">
                                Outcome Message:
                              </label>
                            </div>
                            <div className="col-md-9">
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                name="CallOutcome"
                                placeholder="Enter Outcome Message"
                                value={callOutcomeData.CallOutcome}
                                onChange={(e) => handleChange(e)}
                              />
                            </div>
                          </div>

                          <div className="row">
                            <div
                              className="col-md-12"
                              style={{ textAlign: "center" }}
                            >
                              <p>
                                <a
                                  className="btn btn-deep-orange btn-md"
                                  onClick={(e) => handleSubmit(e)}
                                >
                                  {loadingsv || loadingud
                                    ? "Loading..."
                                    : btnText}
                                </a>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                {cancelHide ? (
                                  <a
                                    className="btn btn-outline-deep-orange btn-md"
                                    onClick={(e) => handleCancel(e)}
                                  >
                                    Cancel
                                  </a>
                                ) : (
                                  ""
                                )}
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="container">
                          {/* <div className="row">
                        <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 ">
                          {errordet || errorsv || errorud ? (
                            <label className="alert-danger" id="SpanError">
                              {errordet || errorsv || errorud}
                            </label>
                          ) : (
                            ""
                          )}
                          {successdet || successud || successsv ? (
                            <label className="alert-success" id="SpanSuccess">
                              {successdet || successud || successsv}
                            </label>
                          ) : (
                            ""
                          )}
                        </div>
                      </div> */}
                        </div>

                        <div className="container">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="table-header-md-9 table-responsive">
                                <table className="table table-striped table-bordered">
                                  <thead>
                                    <tr>
                                      <th>Outcome Message</th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {loading ? (
                                      <tr>
                                        <td colSpan={3}>Loaidng...</td>
                                      </tr>
                                    ) : calloutcomeRec ? (
                                      calloutcomeRec.map((dd) => (
                                        <tr key={dd._id}>
                                          <td>{dd.CallOutcome}</td>
                                          <td align="center">
                                            <input
                                              type="button"
                                              className="btn btn-info btn-sm"
                                              value={
                                                loadingud
                                                  ? "Loading.."
                                                  : "Update"
                                              }
                                              disabled={loadingud}
                                              onClick={(e) =>
                                                handleEdit(
                                                  e,
                                                  dd._id,
                                                  dd.CallOutcome
                                                )
                                              }
                                            />
                                            &nbsp;&nbsp;&nbsp;
                                            <input
                                              type="button"
                                              className="btn btn-info btn-sm"
                                              value={
                                                loadingdet
                                                  ? "Loading.."
                                                  : "Deactivate"
                                              }
                                              disabled={loadingud}
                                              onClick={(e) => {
                                                setDeleteAlert({
                                                  msg: `Do you really want to deactivate this call outcome (${dd?.CallOutcome}) ?`,
                                                  Delete: () =>
                                                    handleDelete(e, dd._id),
                                                });
                                                setOpenNext(true);
                                              }}
                                            />
                                          </td>
                                        </tr>
                                      ))
                                    ) : (
                                      <tr>
                                        <td colSpan={3}>{error}</td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!--/ body-content-with-md-9 --> */}
                  </div>
                  {/* <!--/ col-md-9 --> */}
                </div>
              </div>
            </div>
            <DeletePopup
              OpenNext={OpenNext}
              setOpenNext={setOpenNext}
              DeleteData={DeleteAlert}
            />
            <ToastContainer />
          </div>
        </div>
      </section>
    </>
  );
}
