export const LoginForm_Model = { EmailAddress: "", Password: "" };
export const ForgotPasswordModel = { EmailAddress: "" };
export const Change_Password_Model = { Password: "", ConfirmPassword: "" };
export const User_Create_Model = {
  EmailAddress: "",
  Department: "",
  Hierarchy: "",
};

export const Login_Form_Validator = (data) => {
  if (data.MID === "" || data.MID === null) {
    return "Please Enter Email";
  } else if (data.Password === "" || data.Password === null) {
    return "Please Enter Password";
    // } else if (!data.Email.includes("@") || !data.Email.includes(".")) {
    //   return "Please Enter a valid Email";
  } else {
    return "success";
  }
};

export const User_Json = [
  {
    id: 1,
    email: "merchant1@astrasolutions.com.np",
    username: "merchant1",
    password: "Astra#121",
    mid: "10000011",
  },
  {
    id: 2,
    email: "merchant2@astrasolutions.com.np",
    username: "merchant2",
    password: "Astra#122",
    mid: "10000012",
  },
  {
    id: 3,
    email: "merchant3@astrasolutions.com.np",
    username: "merchant3",
    password: "Astra#123",
    mid: "10000013",
  },
  {
    id: 4,
    email: "merchant4@astrasolutions.com.np",
    username: "merchant4",
    password: "Astra#124",
    mid: "10000014",
  },
  {
    id: 5,
    email: "merchant5@astrasolutions.com.np",
    username: "merchant5",
    password: "Astra#125",
    mid: "10000015",
  },
];

export const Find_User = (data) => {
  for (let i = 0; i < User_Json.length; i++) {
    if (
      User_Json[i].email === data.MID ||
      User_Json[i].username === data.MID ||
      User_Json[i].mid === data.MID
    ) {
      if (User_Json[i].password === data.Password) {
        return User_Json[i];
      }
    }
  }
  return undefined;
};
