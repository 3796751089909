export const CALL_LOG_CONTACT_LIST_REQUEST = "CALL_LOG_CONTACT_LIST_REQUEST";
export const CALL_LOG_CONTACT_LIST_SUCCESS = "CALL_LOG_CONTACT_LIST_SUCCESS";
export const CALL_LOG_CONTACT_LIST_FAIL = "CALL_LOG_CONTACT_LIST_FAIL";

export const CALL_LOG_DEAL_LIST_REQUEST = "CALL_LOG_DEAL_LIST_REQUEST";
export const CALL_LOG_DEAL_LIST_SUCCESS = "CALL_LOG_DEAL_LIST_SUCCESS";
export const CALL_LOG_DEAL_LIST_FAIL = "CALL_LOG_DEAL_LIST_FAIL";

export const CALL_LOG_SUBAGENT_LIST_REQUEST = "CALL_LOG_SUBAGENT_LIST_REQUEST";
export const CALL_LOG_SUBAGENT_LIST_SUCCESS = "CALL_LOG_SUBAGENT_LIST_SUCCESS";
export const CALL_LOG_SUBAGENT_LIST_FAIL = "CALL_LOG_SUBAGENT_LIST_FAIL";

export const CALL_LOG_INSERT_REQUEST = "CALL_LOG_INSERT_REQUEST";
export const CALL_LOG_INSERT_SUCCESS = "CALL_LOG_INSERT_SUCCESS";
export const CALL_LOG_INSERT_FAIL = "CALL_LOG_INSERT_FAIL";
export const CALL_LOG_INSERT_RESET = "CALL_LOG_INSERT_RESET";

export const CALL_LOG_UPDATE_REQUEST = "CALL_LOG_UPDATE_REQUEST";
export const CALL_LOG_UPDATE_SUCCESS = "CALL_LOG_UPDATE_SUCCESS";
export const CALL_LOG_UPDATE_FAIL = "CALL_LOG_UPDATE_FAIL";
export const CALL_LOG_UPDATE_RESET = "CALL_LOG_UPDATE_RESET";

export const CALL_LOG_DELETE_REQUEST = "CALL_LOG_DELETE_REQUEST";
export const CALL_LOG_DELETE_SUCCESS = "CALL_LOG_DELETE_SUCCESS";
export const CALL_LOG_DELETE_FAIL = "CALL_LOG_DELETE_FAIL";
export const CALL_LOG_DELETE_RESET = "CALL_LOG_DELETE_RESET";
