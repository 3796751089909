import {
  SHORTLISTCOURSE_INSERT_FAIL,
  SHORTLISTCOURSE_INSERT_REQUEST,
  SHORTLISTCOURSE_INSERT_RESET,
  SHORTLISTCOURSE_INSERT_SUCCESS,
  SHORTLISTCOURSE_LIST_FAIL,
  SHORTLISTCOURSE_LIST_REQUEST,
  SHORTLISTCOURSE_LIST_SUCCESS,
} from "../Constants/shortListCourseConstant";

export const shortListCourseCreateReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case SHORTLISTCOURSE_INSERT_REQUEST: {
      return { loading: true };
    }

    case SHORTLISTCOURSE_INSERT_SUCCESS: {
      return { loading: false, success: payload };
    }

    case SHORTLISTCOURSE_INSERT_FAIL: {
      return { loading: false, error: payload };
    }

    case SHORTLISTCOURSE_INSERT_RESET: {
      return { loading: false, success: "" };
    }

    default:
      return state;
  }
};

export const shortListCourseListReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case SHORTLISTCOURSE_LIST_REQUEST: {
      return { loading: true };
    }

    case SHORTLISTCOURSE_LIST_SUCCESS: {
      return { loading: false, shortListCourseRec: payload };
    }

    case SHORTLISTCOURSE_LIST_FAIL: {
      return { loading: false, error: payload };
    }

    default:
      return state;
  }
};
