import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listCourses } from "../../Actions/courseAction";

const CourseDropDown = React.memo(() => {
  const dispatch = useDispatch();
  const {  CouresesRec } = useSelector(
    (s) => s.CourseListAllRec
  );
  useEffect(() => {
    dispatch(listCourses());
  }, [dispatch]);

  return CouresesRec ? (
    CouresesRec.map((recs) => (
      <option value={recs._id} key={recs._id}>
        {recs.CourseName}
      </option>
    ))
  ) : (
    <></>
  );
});

export default CourseDropDown;
