import React, { useEffect, useState } from "react";
import SettingLeftSection from "../Components/SettingLeftSection";
import { useDispatch, useSelector } from "react-redux";
import { Gender_Model, userJson } from "../../Models/UserModels";
import {
  getUserDetail,
  updateUserProfileDetails,
} from "../../Actions/userActions";
import { USERS_UPDATE_PROFILE_RESET } from "../../Constants/userConstants";
import TopBar from "../../Components/TopBar";
import SidePanel from "../../Components/SidePanel";
let UserData = { First_Name: "", Last_Name: "", EmailAddress: "", PhoneNo: "" };

export default function Account() {
  const dispatch = useDispatch();
  const [userRecs, setuserRecs] = useState(userJson);

  const [errMessage, setErrMessage] = useState("");
  const [succMessage, setSuccMessage] = useState("");

  const { userDets, loading, error } = useSelector((s) => s.userDetails);

  const {
    success: successup,
    loading: loadingup,
    error: errorup,
  } = useSelector((s) => s.userUpdateProfile);

  const handleChange = (e) => {
    e.preventDefault();
    setuserRecs({ ...userRecs, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let validate = "";

    for (let key in UserData) {
      if (userRecs[key] == "") {
        validate = "All Fields are Cumpulsory!";
      }
    }
    if (validate == "") {
      dispatch(updateUserProfileDetails(userRecs));
    } else {
      setErrMessage(validate);
    }
  };

  const getuserDetails = () => {
    dispatch(getUserDetail());
  };

  useEffect(() => {
    if (!userDets) {
      getuserDetails();
    }
    if (userRecs.First_Name == "" && userDets != undefined) {
      setuserRecs({ ...userDets });
    }
  }, [userDets]);

  useEffect(() => {
    if (successup) {
      getuserDetails();
      setSuccMessage(successup);
      setTimeout(() => {
        dispatch({ type: USERS_UPDATE_PROFILE_RESET });
      }, 4000);
    }
  }, [successup, dispatch]);

  return (
    <>
      <SidePanel page={"User"} />
      <section className="home-section">
        <TopBar Heading={"Account"} />
        <div
          className="home-content"
          style={{ paddingTop: window.innerWidth <= 550 ? "20px" : "100px" }}
        >
          <div className="content-body">
            {/* <!--Body-content--> */}
            <div className="container-fluid">
              <div className="heading-title">
                <div className="row">
                  <div className="col-md-12">
                    {/* <div className="home-title float-left">
                      <nav className="sub-nav-bcrumb" aria-label="breadcrumb">
                        <ol className="breadcrumb">
                          <li className="breadcrumb-item">
                            <Link to="/settings">Settings</Link>
                          </li>
                          <li
                            className="breadcrumb-item active"
                            aria-current="page"
                          >
                            Account
                          </li>
                        </ol>
                      </nav>
                    </div> */}
                    <div className="home-table-search float-right d-flex"></div>
                  </div>
                </div>
              </div>

              <div className="inner-body settings-details">
                <div className="row">
                  <div className="col-md-3">
                    <SettingLeftSection />
                  </div>

                  <div className="col-md-9">
                    <div className="body-content-with-md-9">
                      <div
                        className="container"
                        ng-app="UserApp"
                        ng-controller="UserCtrl"
                      >
                        <div className="row">
                          <div className="col-md-12 text-left body-content-with-md-9-title">
                            <h4>Account Information</h4>
                          </div>
                          <input type="hidden" value="11" id="User_Id" />
                        </div>

                        <form onSubmit={handleSubmit}>
                          <div className="row mt-5 px-5">
                            <div className="col-md-3 pt-1">
                              <p>First Name :</p>
                            </div>
                            <div className="col-md-9">
                              <p>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="First Name"
                                  name="First_Name"
                                  value={userRecs?.First_Name}
                                  onChange={(e) => handleChange(e)}
                                />
                              </p>
                            </div>

                            <div className="col-md-3 pt-1">
                              <p>Last Name :</p>
                            </div>
                            <div className="col-md-9">
                              <p>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Last Name"
                                  name="Last_Name"
                                  value={userRecs?.Last_Name}
                                  onChange={(e) => handleChange(e)}
                                />
                              </p>
                            </div>

                            {/* <div className="col-md-3 pt-1">
                              <p>Username :</p>
                            </div>
                            <div className="col-md-9">
                              <p>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Username"
                                  name="Username"
                                  value={userRecs?.Username}
                                  onChange={(e) => handleChange(e)}
                                />
                              </p>
                            </div> */}

                            <div className="col-md-3 pt-1">
                              <p>Email Address :</p>
                            </div>
                            <div className="col-md-9">
                              <p>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="EmailAddress"
                                  value={userRecs?.EmailAddress}
                                  disabled
                                />
                              </p>
                            </div>

                            <div className="col-md-3 pt-1">
                              <p>Phone No :</p>
                            </div>

                            <div className="col-md-9">
                              <p>
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="Phone No"
                                  name="PhoneNo"
                                  value={userRecs?.PhoneNo}
                                  onChange={(e) => handleChange(e)}
                                />
                              </p>
                            </div>

                            <div className="col-md-3 pt-1">
                              <p>Gender:</p>
                            </div>

                            <div className="col-md-9">
                              <p>
                                <select
                                  type="number"
                                  className="form-control"
                                  placeholder="Gender"
                                  name="Gender"
                                  value={userRecs?.Gender}
                                  onChange={(e) => handleChange(e)}
                                >
                                  <option value="">--Select Gender--</option>
                                  {Gender_Model?.map((ev) => (
                                    <option key={ev?.Id} value={ev?.Id}>
                                      {ev?.Gender}
                                    </option>
                                  ))}
                                  {/* <GenderDropdown /> */}
                                </select>
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div
                              className="col-md-12"
                              style={{ textAlign: "center" }}
                            >
                              <button
                                type="button"
                                className="btn btn-deep-orange"
                                disabled={loadingup}
                                onClick={handleSubmit}
                              >
                                {loadingup ? "Loading..." : "Update"}
                              </button>
                            </div>
                          </div>
                        </form>
                        <div className="row">
                          <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                            <span className="alert-danger">
                              {errorup ? errorup : ""}
                              {errMessage ? errMessage : ""}
                            </span>
                            <span className="alert-success">
                              {successup ? successup : ""}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!--/ body-content-with-md-9 --> */}
                  </div>
                  {/* <!--/ col-md-9 --> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
