import SidePanel from "../../Components/SidePanel";
import TopBar from "../../Components/TopBar";
import { useDispatch, useSelector } from "react-redux";
import Meta from "../../Components/Meta";
import { useCallback, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  createMaterial,
  listMaterial,
  materialDetails,
  removeMaterial,
  totalMaterial,
  updateMaterial,
} from "../../Actions/materialAction";
import { toastOptions } from "../../Models/ToastEditiorOption";
import { toast, ToastContainer } from "react-toastify";
import {
  MATERIAL_CREATE_RESET,
  MATERIAL_REMOVE_RESET,
  MATERIAL_UPDATE_RESET,
} from "../../Constants/materialConstants";
import CourseDropDown from "../Components/CourseDropDown";
import Pagination from "../Components/Pagination";
import { FaRegEdit } from "react-icons/fa";
import {
  Material_Validator_Model,
  MaterialJson,
} from "../../Models/MaterialModel";
import SEDLoading from "../../Components/SEDLoading";
import { MdDelete } from "react-icons/md";
import DeletePopup from "../Components/DeletePoup";
import MaterialTitleDropDown from "../Components/MaterialTitleDropDown";
import ReactQuill from "react-quill";
import { listCourses } from "../../Actions/courseAction";
import { listMaterialTitle } from "../../Actions/MaterialTitleAction";

export default function Material() {
  const [materialCreateData, setmaterialCreateData] = useState(MaterialJson);
  const [MaterialDescription, setMaterialDescription] = useState("");
  const [MaterialEditData, setMaterialEditData] = useState("");
  const [MobileFilterActive, setMobileFilterActive] = useState(false);
  const [createSec, setCreateSec] = useState(false);
  const [materialFilterData, setmaterialFilterData] = useState({
    page_size: 10,
    page_no: 1,
  });

  const { total } = useSelector((s) => s.totalMaterialReducer);
  const [OpenNext, setOpenNext] = useState(false);
  const [DeleteAlert, setDeleteAlert] = useState({ msg: "", Delete: () => "" });
  const mobile_View = window.innerWidth;
  const { loading, error, materialRecs } = useSelector(
    (s) => s.listMaterialReducer
  );
  const { DetMaterial, loading: loadingDet } = useSelector(
    (s) => s.materialDetailsReducer
  );
  const Navigate = useNavigate();

  const {
    loaidng: loadingUpt,
    success: successUpt,
    error: errorUpt,
  } = useSelector((s) => s.updateMaterialReducer);

  const { MaterialTitleRec } = useSelector((s) => s.listMaterialTitleReducer);

  const ErrorSuccessRef = useRef();
  
  const handleCancel = () => {
    setCreateSec(false);
    setMaterialEditData(false);
    setmaterialCreateData(MaterialJson);
    setMaterialDescription("");
  };

  const {
    loading: loadingsv,
    error: errorsv,
    success: successsv,
  } = useSelector((s) => s.createMaterialReducer);

  const {
    loading: loadingRmv,
    success: successRmv,
    error: errorRmv,
  } = useSelector((s) => s.removeMaterialReducer);

  const dispatch = useDispatch();
  const MetaDes = { title: "Testfify - Material" };

  const Get_Materials = useCallback(() => {
    dispatch(listMaterial());
    dispatch(totalMaterial());
  }, [dispatch]);

  const handleChange = (e) => {
    setmaterialCreateData({
      ...materialCreateData,
      [e.target.name]: e.target.value,
    });
  };

  const handleFilterChange = () => {};

  const handleSubmit = (e) => {
    e.preventDefault();
    const Validator = Material_Validator_Model({
      ...materialCreateData,
      MaterialDescription,
    });

    if (Validator === "success") {
      dispatch(createMaterial({ ...materialCreateData, MaterialDescription }));
    } else {
      toast.error(Validator, toastOptions);
    }
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    const Validator = Material_Validator_Model({
      ...materialCreateData,
      MaterialDescription,
    });
    if (Validator === "success") {
      dispatch(
        updateMaterial(
          { ...materialCreateData, MaterialDescription },
          materialCreateData?._id
        )
      );
    } else {
      toast.error(Validator, toastOptions);
    }
  };

  const handleRemove = (e, Id) => {
    e.preventDefault();
    dispatch(removeMaterial(Id));
  };

  useEffect(() => {
    Get_Materials();
  }, [Get_Materials]);

  useEffect(() => {
    if (successsv) {
      toast.success(successsv, toastOptions);
      handleCancel();
      Get_Materials();
      setTimeout(() => {
        dispatch({ type: MATERIAL_CREATE_RESET });
      }, 2000);
    }

    if (errorsv) {
      toast.success(errorsv, toastOptions);
    }
  }, [successsv, errorsv, dispatch, Get_Materials]);

  useEffect(() => {
    if (successUpt) {
      toast.success(successUpt, toastOptions);
      handleCancel();
      Get_Materials();
      setTimeout(() => {
        dispatch({ type: MATERIAL_UPDATE_RESET });
      }, 2000);
    }
    if (errorUpt) {
      toast.success(errorUpt, toastOptions);
    }
  }, [successUpt, errorUpt, dispatch, Get_Materials]);

  useEffect(() => {
    if (successRmv) {
      toast.success(successRmv, toastOptions);
      handleCancel();
      Get_Materials();
      setTimeout(() => {
        dispatch({ type: MATERIAL_REMOVE_RESET });
      }, 2000);
    }
    if (errorRmv) {
      toast.success(errorRmv, toastOptions);
    }
  }, [successRmv, errorRmv, dispatch, Get_Materials]);

  useEffect(() => {
    if (DetMaterial && MaterialEditData) {
      setmaterialCreateData({
        ...DetMaterial,
      });
      setMaterialDescription(DetMaterial?.MaterialDescription);
    } else {
      setmaterialCreateData(MaterialJson);
      setMaterialDescription("");
    }
  }, [DetMaterial, MaterialEditData]);

  useEffect(() => {
    dispatch(listMaterialTitle(materialCreateData?.CourseId));
  }, [materialCreateData?.CourseId, dispatch]);

  useEffect(() => {
    dispatch(listCourses());
  }, [dispatch]);

  return (
    <>
      <Meta MetaDes={MetaDes} />
      <SidePanel page={"Material"} />
      <section className="home-section">
        <TopBar Heading="Material" />
        {loadingUpt || loadingRmv || loadingDet ? <SEDLoading /> : ""}
        <div className="home-content" style={{ paddingTop: "70px" }}>
          <div className="Contact-Middle">
            <div className="Contact_Top_Header">
              <div className="home-title float-left"></div>
              <div style={{ display: "flex", gap: "20px" }} className="">
                <div className="input-group search-contacts">
                  <input
                    type="Search"
                    className="form-control"
                    name="searchtxt"
                    style={{ display: "block" }}
                    // value={Search}
                    // onChange={(e) => {
                    //   if (e.target.value === "") {
                    //     setFilterData({ ...FilterData, searchtxt: "" });
                    //     setmaterialFilterData({
                    //       ...materialFilterData,
                    //       searchtxt: "",
                    //     });
                    //   }
                    //   setSearch(e.target.value);
                    // }}
                    // onKeyDown={(e) => {
                    //   if (
                    //     e.key === "Enter" &&
                    //     Search !== "" &&
                    //     Search !== undefined
                    //   ) {
                    //     setFilterData({ ...FilterData, searchtxt: Search });
                    //     setmaterialFilterData({
                    //       ...materialFilterData,
                    //       searchtxt: Search,
                    //     });
                    //   }
                    // }}
                    placeholder="search for Materials"
                  />
                  <i className="fa fa-search" aria-hidden="true"></i>
                </div>
                <div className="right-click Create-btn">
                  <a
                    style={{ width: "180px" }}
                    className="btn create-contact"
                    onClick={(e) => {
                      setmaterialCreateData({
                        ...materialCreateData,
                      });
                      setCreateSec(true);
                    }}
                  >
                    Create Material
                  </a>
                </div>
              </div>
            </div>

            {window.innerWidth <= 550 ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginRight: "17px",
                }}
                className=" Create-btn"
              >
                <a
                  style={{ width: "180px" }}
                  className="btn create-contact"
                  onClick={(e) => {
                    setmaterialCreateData({
                      ...materialCreateData,
                    });
                    setCreateSec(true);
                  }}
                >
                  Create Material
                </a>
              </div>
            ) : (
              ""
            )}
            <hr />
            <div className="row">
              <div className="col-md-4 col-lg-3">
                <div
                  style={
                    mobile_View <= 480
                      ? { marginBottom: "20px" }
                      : { cursor: "auto" }
                  }
                  className="add-filter"
                >
                  <div
                    className={
                      MobileFilterActive
                        ? "filter-title  filter-title-mob filter-body-active"
                        : "filter-title  filter-title-mob"
                    }
                    style={{ cursor: "auto" }}
                  >
                    <h5>Filter Materials</h5>
                    <button
                      onClick={() => setMobileFilterActive(false)}
                      className="mob-nav-open"
                    >
                      ☰
                    </button>
                  </div>

                  <div
                    className={
                      MobileFilterActive
                        ? "filter-body filter-body-mob  filter-body-active custom-scroll-right pt-0"
                        : "filter-body filter-body-mob custom-scroll-right pt-0"
                    }
                    style={{ cursor: "auto" }}
                  >
                    <div className="sticky pt-2">
                      <p style={{ marginBottom: "-20px" }}>
                        showing {total ? total : 0} Materials
                      </p>
                      <br />
                      <button
                        style={{ color: "white" }}
                        className="btn btn-sm btn-deep-orange"
                        onClick={() => {
                          setmaterialFilterData({
                            ...materialFilterData,
                          });
                        }}
                      >
                        Clear All
                      </button>
                    </div>
                    <ul className="modify-filter-title">
                      <li className="chose">Course</li>
                      <li>
                        <select
                          name="AcademicPercentage_From"
                          value={materialFilterData.AcademicPercentage_From}
                          onChange={handleFilterChange}
                          className="form-control"
                        >
                          <option value="">--Select--</option>
                          <CourseDropDown />
                        </select>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-md-8 col-lg-9">
                <div className="Contact-table-wrapper">
                  <table>
                    <thead>
                      <tr className="sticky">
                        <th>Edit</th>
                        <th>Delete</th>
                        <th>Material Title</th>
                        <th>Material Sub Title</th>
                        <th>Course</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <tr>
                          <td colSpan={10}>Laoding... </td>
                        </tr>
                      ) : error ? (
                        <tr>
                          <td colSpan={10}>{error}</td>
                        </tr>
                      ) : (
                        materialRecs &&
                        materialRecs?.map((ev) => (
                          <tr key={ev._id}>
                            <td
                              onClick={() => {
                                setmaterialCreateData({
                                  CourseId: ev?.MaterialTitleId?.CourseId?._id,
                                  MaterialTitleId: ev?.MaterialTitleId?._id,
                                });
                                dispatch(materialDetails(ev._id));
                                setTimeout(() => {
                                  setMaterialEditData(true);
                                  setCreateSec(true);
                                }, 1000);
                              }}
                              style={{ cursor: "pointer", color: "blue" }}
                            >
                              <FaRegEdit />
                            </td>

                            <td
                              onClick={(e) => {
                                setDeleteAlert({
                                  msg: `Do you really want to delete this Material (${ev?.MaterialTitleId?.MaterialTitle}) ?`,
                                  Delete: () => handleRemove(e, ev._id),
                                });
                                setOpenNext(true);
                              }}
                              style={{ cursor: "pointer", color: "red" }}
                            >
                              <MdDelete />
                            </td>
                            <td>
                              <Link
                                target="_blank"
                                style={{
                                  textDecoration: "none",
                                  color: "#0c3eff",
                                }}
                              >
                                {ev?.MaterialTitleId?.MaterialTitle}
                              </Link>
                            </td>
                            <td>{ev?.MaterialSubTitle}</td>
                            <td>{ev?.MaterialTitleId?.CourseId?.CourseName}</td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
                <div>
                  <Pagination
                    setFilterQuery={setmaterialFilterData}
                    FilterQuery={materialFilterData}
                    Total={total || 0}
                  />
                </div>

                <div
                  id="CreatContact"
                  className={createSec ? "overlay-note w-100" : "overlay-note"}
                >
                  <div className="overlay-add-deals contact-right-side">
                    <div className="note-title">
                      <h4 className="text-left text-white pl-3">
                        {MaterialEditData
                          ? "Update Material"
                          : "Create Material"}
                      </h4>
                      <a
                        className="closebtn text-right text-white"
                        onClick={(e) => handleCancel()}
                      >
                        ×
                      </a>
                    </div>

                    <div
                      style={{ height: "95vh" }}
                      className="contact-body modify-create-contact"
                    >
                      <div className="form-group first-input">
                        <label>
                          Course :<b>*</b>
                        </label>

                        <select
                          className="form-control"
                          name="CourseId"
                          value={materialCreateData.CourseId}
                          onChange={(e) => handleChange(e)}
                        >
                          <option>Select Course</option>
                          <CourseDropDown />
                        </select>
                      </div>

                      <div className="form-group first-input-email">
                        <label>
                          Material Title:<b>*</b>
                        </label>

                        <button
                          onClick={() => Navigate("/Material-Title")}
                          className="first-btn-plus-email"
                          value="+"
                        >
                          {" "}
                          +{" "}
                        </button>

                        <select
                          className="form-control"
                          name="MaterialTitleId"
                          value={materialCreateData.MaterialTitleId}
                          onChange={(e) => handleChange(e)}
                        >
                          <option>select material Title</option>
                          {MaterialTitleRec?.map((el) => (
                            <option key={el?._id} value={el?._id}>
                              {el?.MaterialTitle}
                            </option>
                          ))}
                          <div style={{ display: "none" }}>
                            <MaterialTitleDropDown
                              Course={materialCreateData?.CourseId}
                            />
                          </div>
                        </select>
                      </div>

                      <div className="form-group">
                        <label>
                          Material Sub-Title:<b>*</b>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Sub Title"
                          name="MaterialSubTitle"
                          value={materialCreateData.MaterialSubTitle}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>

                      <div className="form-group first-input">
                        <label>
                          Material Description:<b>*</b>
                        </label>

                        <ReactQuill
                          placeholder={"Enter Material description"}
                          name="MaterialDescription"
                          value={MaterialDescription}
                          onChange={(e) => setMaterialDescription(e)}
                          required
                        />

                        {/* <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Material Description"
                          name="MaterialDescription"
                          value={materialCreateData.MaterialDescription}
                          onChange={(e) => handleChange(e)}
                        /> */}
                      </div>

                      <div
                        // className="contact-footer w-100"
                        style={{
                          // padding: "50px 25px",
                          backgroundColor: "transparent",
                          marginTop: "26px",
                        }}
                        ref={ErrorSuccessRef}
                      ></div>

                      <div className="contact-footer w-100">
                        <div>
                          {MaterialEditData ? (
                            <button
                              type="button"
                              className="btn"
                              onClick={handleUpdate}
                              disabled={loadingUpt}
                            >
                              {loadingUpt ? "Loading..." : "Update Material"}
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="btn"
                              onClick={handleSubmit}
                              disabled={loadingsv}
                            >
                              {loadingsv ? "Loading..." : "Create Material"}
                            </button>
                          )}

                          <button
                            type="button"
                            className="btn btn-deep-orange waves-effect waves-light"
                            onClick={() => handleCancel()}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <DeletePopup
            OpenNext={OpenNext}
            setOpenNext={setOpenNext}
            DeleteData={DeleteAlert}
          />
        </div>
        <ToastContainer />
      </section>
    </>
  );
}
