import {
  CALL_OUTCOME_DELETE_FAIL,
  CALL_OUTCOME_DELETE_REQUEST,
  CALL_OUTCOME_DELETE_RESET,
  CALL_OUTCOME_DELETE_SUCCESS,
  CALL_OUTCOME_GET_FAIL,
  CALL_OUTCOME_GET_REQUEST,
  CALL_OUTCOME_GET_SUCCESS,
  CALL_OUTCOME_INSERT_FAIL,
  CALL_OUTCOME_INSERT_REQUEST,
  CALL_OUTCOME_INSERT_RESET,
  CALL_OUTCOME_INSERT_SUCCESS,
  CALL_OUTCOME_LIST_FAIL,
  CALL_OUTCOME_LIST_REQUEST,
  CALL_OUTCOME_LIST_SUCCESS,
  CALL_OUTCOME_UPDATE_FAIL,
  CALL_OUTCOME_UPDATE_REQUEST,
  CALL_OUTCOME_UPDATE_RESET,
  CALL_OUTCOME_UPDATE_SUCCESS,
} from "../Constants/callOutcomeConstants";

export const callOutcomeListReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case CALL_OUTCOME_LIST_REQUEST:
      return { loading: true };
    case CALL_OUTCOME_LIST_SUCCESS:
      return { loading: false, calloutcomeRec: payload };
    case CALL_OUTCOME_LIST_FAIL:
      return { loading: false, error: payload };
    default:
      return state;
  }
};

export const callOutcomeSaveReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case CALL_OUTCOME_INSERT_REQUEST:
      return { loading: true };
    case CALL_OUTCOME_INSERT_SUCCESS:
      return { loading: false, success: payload };
    case CALL_OUTCOME_INSERT_FAIL:
      return { loading: false, error: payload };
    case CALL_OUTCOME_INSERT_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const callOutcomeUpdateReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case CALL_OUTCOME_UPDATE_REQUEST:
      return { loading: true };
    case CALL_OUTCOME_UPDATE_SUCCESS:
      return { loading: false, success: payload };
    case CALL_OUTCOME_UPDATE_FAIL:
      return { loading: false, error: payload };
    case CALL_OUTCOME_UPDATE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const callOutcomeDeleteReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case CALL_OUTCOME_DELETE_REQUEST:
      return { loading: true };
    case CALL_OUTCOME_DELETE_SUCCESS:
      return { loading: false, success: payload };
    case CALL_OUTCOME_DELETE_FAIL:
      return { loading: false, error: payload };
    case CALL_OUTCOME_DELETE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};
