import {
  TEST_TYPE_LIST_FAILED,
  TEST_TYPE_LIST_REQ,
  TEST_TYPE_LIST_SUCCESS,
} from "../Constants/TestTypeConstnat";

export const listTestTypeReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case TEST_TYPE_LIST_REQ: {
      return { loading: true };
    }

    case TEST_TYPE_LIST_SUCCESS: {
      return { loading: false, TestTypeRec: payload };
    }

    case TEST_TYPE_LIST_FAILED: {
      return { laoding: false, error: payload };
    }
    default:
      return state;
  }
};
