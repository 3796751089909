export const NOTE_LOG_CONTACT_LIST_REQUEST = "NOTE_LOG_CONTACT_LIST_REQUEST";
export const NOTE_LOG_CONTACT_LIST_SUCCESS = "NOTE_LOG_CONTACT_LIST_SUCCESS";
export const NOTE_LOG_CONTACT_LIST_FAIL = "NOTE_LOG_CONTACT_LIST_FAIL";

export const NOTE_LOG_DEAL_LIST_REQUEST = "NOTE_LOG_DEAL_LIST_REQUEST";
export const NOTE_LOG_DEAL_LIST_SUCCESS = "NOTE_LOG_DEAL_LIST_SUCCESS";
export const NOTE_LOG_DEAL_LIST_FAIL = "NOTE_LOG_DEAL_LIST_FAIL";

export const NOTE_LOG_SUBAGENT_LIST_REQUEST = "NOTE_LOG_SUBAGENT_LIST_REQUEST";
export const NOTE_LOG_SUBAGENT_LIST_SUCCESS = "NOTE_LOG_SUBAGENT_LIST_SUCCESS";
export const NOTE_LOG_SUBAGENT_LIST_FAIL = "NOTE_LOG_SUBAGENT_LIST_FAIL";

export const NOTE_LOG_INSERT_REQUEST = "NOTE_LOG_INSERT_REQUEST";
export const NOTE_LOG_INSERT_SUCCESS = "NOTE_LOG_INSERT_SUCCESS";
export const NOTE_LOG_INSERT_FAIL = "NOTE_LOG_INSERT_FAIL";
export const NOTE_LOG_INSERT_RESET = "NOTE_LOG_INSERT_RESET";

export const NOTE_LOG_UPDATE_REQUEST = "NOTE_LOG_UPDATE_REQUEST";
export const NOTE_LOG_UPDATE_SUCCESS = "NOTE_LOG_UPDATE_SUCCESS";
export const NOTE_LOG_UPDATE_FAIL = "NOTE_LOG_UPDATE_FAIL";
export const NOTE_LOG_UPDATE_RESET = "NOTE_LOG_UPDATE_RESET";

export const NOTE_LOG_DELETE_REQUEST = "NOTE_LOG_DELETE_REQUEST";
export const NOTE_LOG_DELETE_SUCCESS = "NOTE_LOG_DELETE_SUCCESS";
export const NOTE_LOG_DELETE_FAIL = "NOTE_LOG_DELETE_FAIL";
export const NOTE_LOG_DELETE_RESET = "NOTE_LOG_DELETE_RESET";
