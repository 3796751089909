import {
  EMAIL_INSERT_FAIL,
  EMAIL_INSERT_REQUEST,
  EMAIL_INSERT_RESET,
  EMAIL_INSERT_SUCCESS,
  EMAIL_MESSAGE_INSERT_FAILED,
  EMAIL_MESSAGE_INSERT_REQUEST,
  EMAIL_MESSAGE_INSERT_RESET,
  EMAIL_MESSAGE_INSERT_SUCCESS,
  EMAIL_MESSAGE_LIST_FAILED,
  EMAIL_MESSAGE_LIST_REQUEST,
  EMAIL_MESSAGE_LIST_SUCCESS,
} from "../Constants/SendEmailConstant";

export const SendEmailReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case EMAIL_INSERT_REQUEST: {
      return { loading: true };
    }

    case EMAIL_INSERT_SUCCESS: {
      return { loading: false, success: payload };
    }
    case EMAIL_INSERT_FAIL: {
      return { loading: false, error: payload };
    }

    case EMAIL_INSERT_RESET: {
      return { loading: false, success: "" };
    }

    default:
      return state;
  }
};

export const SendMessageEmailReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case EMAIL_MESSAGE_INSERT_REQUEST: {
      return { loading: true };
    }

    case EMAIL_MESSAGE_INSERT_SUCCESS: {
      return { loading: false, success: payload };
    }

    case EMAIL_MESSAGE_INSERT_FAILED: {
      return { loading: false, error: payload };
    }

    case EMAIL_MESSAGE_INSERT_RESET: {
      return { loading: false, success: "" };
    }
    default:
      return state;
  }
};

export const ListMessageEmailReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case EMAIL_MESSAGE_LIST_REQUEST: {
      return { loading: true };
    }

    case EMAIL_MESSAGE_LIST_SUCCESS: {
      return { loading: false, Message_Email_List: payload };
    }

    case EMAIL_MESSAGE_LIST_FAILED: {
      return { loadng: false, error: payload };
    }

    default:
      return state;
  }
};
