import {
  PREFERRED_INTAKE_DELETE_FAIL,
  PREFERRED_INTAKE_DELETE_REQUEST,
  PREFERRED_INTAKE_DELETE_RESET,
  PREFERRED_INTAKE_DELETE_SUCCESS,
  PREFERRED_INTAKE_INSERT_FAIL,
  PREFERRED_INTAKE_INSERT_REQUEST,
  PREFERRED_INTAKE_INSERT_RESET,
  PREFERRED_INTAKE_INSERT_SUCCESS,
  PREFERRED_INTAKE_LIST_FAIL,
  PREFERRED_INTAKE_LIST_REQUEST,
  PREFERRED_INTAKE_LIST_SUCCESS,
  PREFERRED_INTAKE_UPDATE_FAIL,
  PREFERRED_INTAKE_UPDATE_REQUEST,
  PREFERRED_INTAKE_UPDATE_RESET,
  PREFERRED_INTAKE_UPDATE_SUCCESS,
} from "../Constants/preferredIntakeConstants";

export const preferredIntakeListReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case PREFERRED_INTAKE_LIST_REQUEST:
      return { loading: true };
    case PREFERRED_INTAKE_LIST_SUCCESS:
      return { loading: false, preferredIntakeRecs: payload };
    case PREFERRED_INTAKE_LIST_FAIL:
      return { loading: false, error: payload };
    default:
      return state;
  }
};

export const preferredIntakeSaveReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case PREFERRED_INTAKE_INSERT_REQUEST:
      return { loading: true };
    case PREFERRED_INTAKE_INSERT_SUCCESS:
      return { loading: false, success: payload };
    case PREFERRED_INTAKE_INSERT_FAIL:
      return { loading: false, error: payload };
    case PREFERRED_INTAKE_INSERT_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const preferredIntakeUpdateReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case PREFERRED_INTAKE_UPDATE_REQUEST:
      return { loading: true };
    case PREFERRED_INTAKE_UPDATE_SUCCESS:
      return { loading: false, success: payload };
    case PREFERRED_INTAKE_UPDATE_FAIL:
      return { loading: false, error: payload };
    case PREFERRED_INTAKE_UPDATE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const preferredIntakeDeleteReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case PREFERRED_INTAKE_DELETE_REQUEST:
      return { loading: true };
    case PREFERRED_INTAKE_DELETE_SUCCESS:
      return { loading: false, success: payload };
    case PREFERRED_INTAKE_DELETE_FAIL:
      return { loading: false, error: payload };
    case PREFERRED_INTAKE_DELETE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};
