import { useEffect, useState } from "react";
import {
  ForgotPasswordModel,
  Login_Form_Validator,
} from "../../Models/LoginModel";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { toastOptions } from "../../Models/LocalData";
import { useNavigate } from "react-router-dom";
import { forgotPasswordUserDetails } from "../../Actions/userActions";

export default function ForgotPassword() {
  const [LoginForm, setLoginForm] = useState(ForgotPasswordModel);
  // const [PasswordVisible, setPasswordVissible] = useState(false);
  const { loading, success, error } = useSelector(
    (state) => state.userForgotPassword
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleFormChange = (e) => {
    setLoginForm({ ...LoginForm, [e.target.name]: e.target.value });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const Login_Form_Check = Login_Form_Validator(LoginForm);
    if (Login_Form_Check === "success") {
      dispatch(forgotPasswordUserDetails(LoginForm));
    } else {
      toast.error(Login_Form_Check, toastOptions);
    }
  };

  // useEffect(() => {
  //   if (success) {
  //     navigate("/");
  //   }
  // });

  useEffect(() => {
    // if (success) {
    //   toast.success(success, toastOptions);
    //   // setTimeout(() => {
    //   //   navigate("/");
    //   // }, 1500);
    // }
    if (error) {
      toast.error(error, toastOptions);
    }
  }, [error, success]);

  return (
    <div>
      <div className="Login">
        <div id="particles-js"></div>
        <div className="">
          <div className="container_new">
            <form name="form1" className="box" onSubmit={handleFormSubmit}>
              <img
                style={{ width: "40%", marginBottom: "30px" }}
                src="./assets/images/Testifyi_logo1.jpg"
                alt="logo"
              />
              <h5>Forgot Password in to your account.</h5>

              {success ? (
                <h4
                  style={{
                    width: "300px",
                    textAlign: "center",
                    color: "green",
                  }}
                >
                  {success}
                </h4>
              ) : (
                <>
                  <input
                    type="text"
                    name="EmailAddress"
                    value={LoginForm.EmailAddress}
                    onChange={handleFormChange}
                    placeholder="EmailAddress"
                    autoComplete="off"
                    required
                  />

                  {/*<label className="remember">
              <input type="checkbox" />
              <span></span>
              <small className="rmb">Remember me</small>
            </label>
            <a href="#" className="forgetpass">
              Forget Password?
  </a>*/}
                  <input
                    type="submit"
                    disabled={loading}
                    value={loading ? "Loading..." : "Forgot Password"}
                    className="btn1"
                  />
                </>
              )}
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
