export const CATEGORY_OF_STUDY_LIST_REQUEST = 'CATEGORY_OF_STUDY_LIST_REQUEST'
export const CATEGORY_OF_STUDY_LIST_SUCCESS = 'CATEGORY_OF_STUDY_LIST_SUCCESS'
export const CATEGORY_OF_STUDY_LIST_FAIL = 'CATEGORY_OF_STUDY_LIST_FAIL'

export const CATEGORY_OF_STUDY_GET_REQUEST = 'CATEGORY_OF_STUDY_GET_REQUEST'
export const CATEGORY_OF_STUDY_GET_SUCCESS = 'CATEGORY_OF_STUDY_GET_SUCCESS'
export const CATEGORY_OF_STUDY_GET_FAIL = 'CATEGORY_OF_STUDY_GET_FAIL'

export const CATEGORY_OF_STUDY_INSERT_REQUEST = 'CATEGORY_OF_STUDY_INSERT_REQUEST'
export const CATEGORY_OF_STUDY_INSERT_SUCCESS = 'CATEGORY_OF_STUDY_INSERT_SUCCESS'
export const CATEGORY_OF_STUDY_INSERT_FAIL = 'CATEGORY_OF_STUDY_INSERT_FAIL'
export const CATEGORY_OF_STUDY_INSERT_RESET = 'CATEGORY_OF_STUDY_INSERT_RESET'

export const CATEGORY_OF_STUDY_UPDATE_REQUEST = 'CATEGORY_OF_STUDY_UPDATE_REQUEST'
export const CATEGORY_OF_STUDY_UPDATE_SUCCESS = 'CATEGORY_OF_STUDY_UPDATE_SUCCESS'
export const CATEGORY_OF_STUDY_UPDATE_FAIL = 'CATEGORY_OF_STUDY_UPDATE_FAIL'
export const CATEGORY_OF_STUDY_UPDATE_RESET = 'CATEGORY_OF_STUDY_UPDATE_RESET'

export const CATEGORY_OF_STUDY_DELETE_REQUEST = 'CATEGORY_OF_STUDY_DELETE_REQUEST'
export const CATEGORY_OF_STUDY_DELETE_SUCCESS = 'CATEGORY_OF_STUDY_DELETE_SUCCESS'
export const CATEGORY_OF_STUDY_DELETE_FAIL = 'CATEGORY_OF_STUDY_DELETE_FAIL'
export const CATEGORY_OF_STUDY_DELETE_RESET = 'CATEGORY_OF_STUDY_DELETE_RESET'
