import {
  LEAD_SOURCE_DELETE_FAIL,
  LEAD_SOURCE_DELETE_REQUEST,
  LEAD_SOURCE_DELETE_RESET,
  LEAD_SOURCE_DELETE_SUCCESS,
  LEAD_SOURCE_INSERT_FAIL,
  LEAD_SOURCE_INSERT_REQUEST,
  LEAD_SOURCE_INSERT_RESET,
  LEAD_SOURCE_INSERT_SUCCESS,
  LEAD_SOURCE_LIST_FAIL,
  LEAD_SOURCE_LIST_REQUEST,
  LEAD_SOURCE_LIST_SUCCESS,
  LEAD_SOURCE_UPDATE_FAIL,
  LEAD_SOURCE_UPDATE_REQUEST,
  LEAD_SOURCE_UPDATE_RESET,
  LEAD_SOURCE_UPDATE_SUCCESS,
} from "../Constants/leadSourceConstants";

export const leadSourceListReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case LEAD_SOURCE_LIST_REQUEST:
      return { loading: true };
    case LEAD_SOURCE_LIST_SUCCESS:
      return { loading: false, leadSourceRecs: payload };
    case LEAD_SOURCE_LIST_FAIL:
      return { loading: false, error: payload };
    default:
      return state;
  }
};

export const leadSourceSaveReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case LEAD_SOURCE_INSERT_REQUEST:
      return { loading: true };
    case LEAD_SOURCE_INSERT_SUCCESS:
      return { loading: false, success: payload };
    case LEAD_SOURCE_INSERT_FAIL:
      return { loading: false, error: payload };
    case LEAD_SOURCE_INSERT_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const leadSourceUpdateReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case LEAD_SOURCE_UPDATE_REQUEST:
      return { loading: true };
    case LEAD_SOURCE_UPDATE_SUCCESS:
      return { loading: false, success: payload };
    case LEAD_SOURCE_UPDATE_FAIL:
      return { loading: false, error: payload };
    case LEAD_SOURCE_UPDATE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const leadSourceDeleteReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case LEAD_SOURCE_DELETE_REQUEST:
      return { loading: true };
    case LEAD_SOURCE_DELETE_SUCCESS:
      return { loading: false, success: payload };
    case LEAD_SOURCE_DELETE_FAIL:
      return { loading: false, error: payload };
    case LEAD_SOURCE_DELETE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};
