import {
  NOTIFICATIONS_GET_FAIL,
  NOTIFICATIONS_GET_REQUEST,
  NOTIFICATIONS_GET_SUCCESS,
  NOTIFICATIONS_INSERT_FAIL,
  NOTIFICATIONS_INSERT_REQUEST,
  NOTIFICATIONS_INSERT_SUCCESS,
} from "../Constants/notificationsConstants";
import api from "../core/api";

export const getNotifications = (id) => async (dispatch) => {
  try {
    dispatch({
      type: NOTIFICATIONS_GET_REQUEST,
    });
    const { data } = await api.get(`/notifications/${id}`);
    var dt = data.Result;

    dispatch({
      type: NOTIFICATIONS_GET_SUCCESS,
      payload: dt,
    });
  } catch (error) {
    dispatch({
      type: NOTIFICATIONS_GET_FAIL,
      payload:
        error.response.data && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const createNotification = (NotificationData) => async (dispatch) => {
  try {
    dispatch({
      type: NOTIFICATIONS_INSERT_REQUEST,
    });

    const { data } = await api.post(`/notifications/`, NotificationData);
    var dt = data.Result;
    dispatch({
      type: NOTIFICATIONS_INSERT_SUCCESS,
      payload: dt,
    });
  } catch (error) {
    dispatch({
      type: NOTIFICATIONS_INSERT_FAIL,
      payload:
        error.response.data && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};
