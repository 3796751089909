import {
  TIMINGS_DELETE_FAIL,
  TIMINGS_DELETE_REQUEST,
  TIMINGS_DELETE_RESET,
  TIMINGS_DELETE_SUCCESS,
  TIMINGS_GET_FAIL,
  TIMINGS_GET_REQUEST,
  TIMINGS_GET_SUCCESS,
  TIMINGS_INSERT_FAIL,
  TIMINGS_INSERT_REQUEST,
  TIMINGS_INSERT_RESET,
  TIMINGS_INSERT_SUCCESS,
  TIMINGS_LIST_FAIL,
  TIMINGS_LIST_REQUEST,
  TIMINGS_LIST_SUCCESS,
  TIMINGS_UPDATE_FAIL,
  TIMINGS_UPDATE_REQUEST,
  TIMINGS_UPDATE_RESET,
  TIMINGS_UPDATE_SUCCESS,
} from "../Constants/timingsConstant";

export const timingsListReducer = (state = {}, action) => {
  switch (action.type) {
    case TIMINGS_LIST_REQUEST:
      return { loading: true };
    case TIMINGS_LIST_SUCCESS:
      return { loading: false, timingsRecs: action.payload };
    case TIMINGS_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const timingsGetReducer = (state = {}, action) => {
  switch (action.type) {
    case TIMINGS_GET_REQUEST:
      return { loading: true };
    case TIMINGS_GET_SUCCESS:
      return { loading: false, timingsRec: action.payload };
    case TIMINGS_GET_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const timingsInsertReducer = (state = {}, action) => {
  switch (action.type) {
    case TIMINGS_INSERT_REQUEST:
      return { loading: true };
    case TIMINGS_INSERT_SUCCESS:
      return { loading: false, success: action.payload };
    case TIMINGS_INSERT_FAIL:
      return { loading: false, error: action.payload };
    case TIMINGS_INSERT_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const timingsUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case TIMINGS_UPDATE_REQUEST:
      return { loading: true };
    case TIMINGS_UPDATE_SUCCESS:
      return { loading: false, success: action.payload };
    case TIMINGS_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case TIMINGS_UPDATE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const timingsDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case TIMINGS_DELETE_REQUEST:
      return { loading: true };
    case TIMINGS_DELETE_SUCCESS:
      return { loading: false, success: action.payload };
    case TIMINGS_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case TIMINGS_DELETE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};
