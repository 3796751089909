export const PRIORITY_LIST_REQUEST = 'PRIORITY_LIST_REQUEST'
export const PRIORITY_LIST_SUCCESS = 'PRIORITY_LIST_SUCCESS'
export const PRIORITY_LIST_FAIL = 'PRIORITY_LIST_FAIL'

export const PRIORITY_INSERT_REQUEST = 'PRIORITY_INSERT_REQUEST'
export const PRIORITY_INSERT_SUCCESS = 'PRIORITY_INSERT_SUCCESS'
export const PRIORITY_INSERT_FAIL = 'PRIORITY_INSERT_FAIL'
export const PRIORITY_INSERT_RESET = 'PRIORITY_INSERT_RESET'

export const PRIORITY_UPDATE_REQUEST = 'PRIORITY_UPDATE_REQUEST'
export const PRIORITY_UPDATE_SUCCESS = 'PRIORITY_UPDATE_SUCCESS'
export const PRIORITY_UPDATE_FAIL = 'PRIORITY_UPDATE_FAIL'
export const PRIORITY_UPDATE_RESET = 'PRIORITY_UPDATE_RESET'

export const PRIORITY_DELETE_REQUEST = 'PRIORITY_DELETE_REQUEST'
export const PRIORITY_DELETE_SUCCESS = 'PRIORITY_DELETE_SUCCESS'
export const PRIORITY_DELETE_FAIL = 'PRIORITY_DELETE_FAIL'
export const PRIORITY_DELETE_RESET = 'PRIORITY_DELETE_RESET'