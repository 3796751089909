import {
  ORDER_DETAIL_CREATE_FAILED,
  ORDER_DETAIL_CREATE_REQ,
  ORDER_DETAIL_CREATE_RESET,
  ORDER_DETAIL_CREATE_SUCCESS,
  ORDER_DETAIL_REMOVE_FAILED,
  ORDER_DETAIL_REMOVE_REQ,
  ORDER_DETAIL_REMOVE_RESET,
  ORDER_DETAIL_REMOVE_SUCCESS,
  ORDER_DETAIL_UPDATE_FAILED,
  ORDER_DETAIL_UPDATE_REQ,
  ORDER_DETAIL_UPDATE_RESET,
  ORDER_DETAIL_UPDATE_SUCCESS,
  ORDER_DETAILS_FAILED,
  ORDER_DETAILS_REQ,
  ORDER_DETAILS_SUCCESS,
  ORDER_LIST_FAILED,
  ORDER_LIST_REQ,
  ORDER_LIST_SUCCESS,
  ORDER_LIST_TOTAL_FAILED,
  ORDER_LIST_TOTAL_REQ,
  ORDER_LIST_TOTAL_SUCCESS,
} from "../Constants/orderConstant";

export const listOrderRducer = (state = {}, { type, payload }) => {
  switch (type) {
    case ORDER_LIST_REQ: {
      return { loading: true };
    }

    case ORDER_LIST_SUCCESS: {
      return { loading: false, orderRecs: payload };
    }

    case ORDER_LIST_FAILED: {
      return { loading: false, error: payload };
    }
    default:
      return state;
  }
};

export const totalListOrderReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case ORDER_LIST_TOTAL_REQ: {
      return { loading: true };
    }

    case ORDER_LIST_TOTAL_SUCCESS: {
      return { loading: false, total: payload };
    }

    case ORDER_LIST_TOTAL_FAILED: {
      return { loading: false, error: payload };
    }

    default:
      return state;
  }
};

export const orderDetailsDataReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case ORDER_DETAILS_REQ: {
      return { loaidng: true };
    }

    case ORDER_DETAILS_SUCCESS: {
      return { loading: false, OrderDet: payload };
    }

    case ORDER_DETAILS_FAILED: {
      return { loading: false, error: payload };
    }

    default:
      return state;
  }
};

export const createOrderDetReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case ORDER_DETAIL_CREATE_REQ: {
      return { loading: true };
    }
    case ORDER_DETAIL_CREATE_SUCCESS: {
      return { loading: false, success: payload };
    }

    case ORDER_DETAIL_CREATE_FAILED: {
      return { loading: false, error: payload };
    }

    case ORDER_DETAIL_CREATE_RESET: {
      return { loading: false, error: "", success: "" };
    }
    default:
      return state;
  }
};

export const updateOrderDetReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case ORDER_DETAIL_UPDATE_REQ: {
      return { loading: true };
    }
    case ORDER_DETAIL_UPDATE_SUCCESS: {
      return { loading: false, success: payload };
    }

    case ORDER_DETAIL_UPDATE_FAILED: {
      return { loading: false, error: payload };
    }

    case ORDER_DETAIL_UPDATE_RESET: {
      return { loading: false, error: "", success: "" };
    }
    default:
      return state;
  }
};

export const removeOrderDetReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case ORDER_DETAIL_REMOVE_REQ: {
      return { loading: true };
    }
    case ORDER_DETAIL_REMOVE_SUCCESS: {
      return { loading: false, success: payload };
    }

    case ORDER_DETAIL_REMOVE_FAILED: {
      return { loading: false, error: payload };
    }

    case ORDER_DETAIL_REMOVE_RESET: {
      return { loading: false, error: "", success: "" };
    }
    default:
      return state;
  }
};
