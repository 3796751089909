export const MaterialJson = {
  MaterialTitleId: "",
  MaterialSubTitle: "",
  MaterialDescription: "",
  CourseId: "",
};

export const MaterialTitleJson = {
  MaterialTitle: "",
  CourseId: "",
  SubCategoryId:""
};

export const MaterialSubTitleJson = {
  MaterialTitleId: "",
  MaterialSubTitle: "",
  MaterialDescription: "",
};

export const Material_Validator_Model = (Data) => {
  let Error = "";

  if (
    Data?.MaterialTitleId === "" ||
    Data?.MaterialTitleId === undefined ||
    Data?.MaterialTitleId === null
  ) {
    Error += "Material Title is required, ";
  }

  if (
    Data?.MaterialSubTitle === "" ||
    Data?.MaterialSubTitle === undefined ||
    Data?.MaterialSubTitle === null
  ) {
    Error += "Material Sub Title is required, ";
  }

  if (
    Data?.MaterialDescription === "" ||
    Data?.MaterialDescription === undefined ||
    Data?.MaterialDescription === null
  ) {
    Error += "Material Description is required, ";
  }

  if (
    Data?.CourseId === "" ||
    Data?.CourseId === undefined ||
    Data?.CourseId === null
  ) {
    Error += "Course is required, ";
  }

  if (Error) {
    return Error;
  } else {
    return "success";
  }
};

export const MaterialSubTitle_Validator_Model = (
  Data,
  MaterialDesc,
  UploadedDesc
) => {
  let Error = "";
  if (
    Data?.MaterialTitleId === "" ||
    Data?.MaterialTitleId === undefined ||
    Data?.MaterialTitleId === null
  ) {
    Error += "Course Id is required, ";
  }
  if (
    Data?.MaterialSubTitle === "" ||
    Data?.MaterialSubTitle === undefined ||
    Data?.MaterialSubTitle === null
  ) {
    Error += "Mock No is required, ";
  }

  if (MaterialDesc?.length == 0) {
    Error += "Mock Description is required, ";
  }

  for (let i = 0; i < MaterialDesc?.length; i++) {
    if (
      MaterialDesc[i] == "InputText" &&
      !UploadedDesc[i]?.desc?.replace(/(<([^>]+)>)/gi, "").trim()?.length
    ) {
      Error += "Input Text Should Not Empty !";
    } else if (MaterialDesc[i] == "Image") {
      if (!UploadedDesc[i]?.image && !UploadedDesc[i]?.url) {
        Error += "Image  Should Not Empty !";
      }
    } else if (MaterialDesc[i] == "Mp3") {
      if (!UploadedDesc[i]?.audio && !UploadedDesc[i]?.url) {  //
        Error += "Mp3 file  Should Not Empty !";
      }
    }
  }

  if (Error) {
    return Error;
  } else {
    return "success";
  }
};
