import {
  CREATE_COUPON_FAILED,
  CREATE_COUPON_REQ,
  CREATE_COUPON_SUCCESS,
  COUPON_DETAILS_FAILED,
  COUPON_DETAILS_REQ,
  COUPON_DETAILS_SUCCESS,
  COUPON_LIST_FAILED,
  COUPON_LIST_REQ,
  COUPON_LIST_SUCCESS,
  COUPON_DELETE_FAILED,
  COUPON_DELETE_REQ,
  COUPON_DELETE_SUCCESS,
  UPDATE_COUPON_FAILED,
  UPDATE_COUPON_REQ,
  UPDATE_COUPON_SUCCESS,
} from "../Constants/CouponConstant";
import api from "../core/api";

export const Coupon_Create = (CouponDet) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_COUPON_REQ });
    const { data } = await api.post("/Coupon", CouponDet);
    dispatch({ type: CREATE_COUPON_SUCCESS, payload: data?.Result });
  } catch (error) {
    dispatch({
      type: CREATE_COUPON_FAILED,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const Coupon_List = (CouponFilter) => async (dispatch) => {
  try {
    dispatch({ type: COUPON_LIST_REQ });
    const { data } = await api.get(
      `/Coupon?page_no=${CouponFilter?.page_no}&page_size=${CouponFilter?.page_size}`
    );

    const TotalRec = await api.get("/Coupon/total");

    dispatch({
      type: COUPON_LIST_SUCCESS,
      payload: data?.Result,
      Total: TotalRec?.data?.Result,
    });
  } catch (error) {
    dispatch({
      type: COUPON_LIST_FAILED,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const updateCoupon = (CouponData, CouponId) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_COUPON_REQ });
    const { data } = await api.put(`/Coupon/${CouponId}`, CouponData);
    dispatch({ type: UPDATE_COUPON_SUCCESS, payload: data?.Result });
  } catch (error) {
    dispatch({
      type: UPDATE_COUPON_FAILED,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const couponDetails = (CouponId) => async (dispatch) => {
  try {
    dispatch({ type: COUPON_DETAILS_REQ });
    const { data } = await api.get(`/Coupon/${CouponId}`);

    dispatch({ type: COUPON_DETAILS_SUCCESS, payload: data?.Result });
  } catch (error) {
    dispatch({
      type: COUPON_DETAILS_FAILED,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const CouponDelete = (Id) => async (dispatch) => {
  try {
    dispatch({ type: COUPON_DELETE_REQ });
    const { data } = await api.delete("/coupon/" + Id);
    dispatch({ type: COUPON_DELETE_SUCCESS, payload: data?.Result });
  } catch (error) {
    dispatch({
      type: COUPON_DELETE_FAILED,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};
