import {
  CONTACT_LIST_REQUEST,
  CONTACT_LIST_SUCCESS,
  CONTACT_LIST_FAIL,
  CONTACT_CREATE_REQUEST,
  CONTACT_CREATE_SUCCESS,
  CONTACT_CREATE_FAIL,
  CONTACT_CREATE_RESET,
  CONTACT_UPDATE_REQUEST,
  CONTACT_UPDATE_SUCCESS,
  CONTACT_UPDATE_FAIL,
  CONTACT_UPDATE_RESET,
  CONTACT_GET_REQUEST,
  CONTACT_GET_SUCCESS,
  CONTACT_GET_FAIL,
  CONTACT_DET_BY_IDS_REQUEST,
  CONTACT_DET_BY_IDS_SUCCESS,
  CONTACT_DET_BY_IDS_FAIL,
  CONTACT_TOTAL_LIST_REQUEST,
  CONTACT_TOTAL_LIST_SUCCESS,
  CONTACT_TOTAL_LIST_FAIL,
} from "../Constants/contactConstants";

export const contactListReducer = (state = {}, { type, payload, total }) => {
  switch (type) {
    case CONTACT_LIST_REQUEST:
      return { loading: true };
    case CONTACT_LIST_SUCCESS:
      return { loading: false, contactRecs: payload, total: total };
    case CONTACT_LIST_FAIL:
      return { loading: false, error: payload };
    default:
      return state;
  }
};

export const totalContactsReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case CONTACT_TOTAL_LIST_REQUEST: {
      return { loading: true };
    }

    case CONTACT_TOTAL_LIST_SUCCESS: {
      return { loading: false, total: payload };
    }
    case CONTACT_TOTAL_LIST_FAIL: {
      return { loading: false, error: payload };
    }

    default:
      return state;
  }
};

export const contactCreateReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case CONTACT_CREATE_REQUEST:
      return { loading: true };
    case CONTACT_CREATE_SUCCESS:
      return { loading: false, success: payload };
    case CONTACT_CREATE_FAIL:
      return { loading: false, error: payload };
    case CONTACT_CREATE_RESET:
      return { loading: false, success: "" };
    default:
      return state;
  }
};

export const contactUpdateReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case CONTACT_UPDATE_REQUEST:
      return { loading: true };
    case CONTACT_UPDATE_SUCCESS:
      return { loading: false, success: payload };
    case CONTACT_UPDATE_FAIL:
      return { loading: false, error: payload };
    case CONTACT_UPDATE_RESET:
      return { loading: false, success: "" };
    default:
      return state;
  }
};

export const contactDetailReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case CONTACT_GET_REQUEST:
      return { loading: true };
    case CONTACT_GET_SUCCESS:
      return { loading: false, contactDet: payload };
    case CONTACT_GET_FAIL:
      return { loading: false, error: payload };
    default:
      return state;
  }
};

export const contactDetByIdsReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case CONTACT_DET_BY_IDS_REQUEST: {
      return { loading: true };
    }

    case CONTACT_DET_BY_IDS_SUCCESS: {
      return { laoding: false, contactDetById: payload };
    }

    case CONTACT_DET_BY_IDS_FAIL: {
      return { laoding: false, error: payload };
    }

    default:
      return state;
  }
};
