export const CONTACT_BATCH_LIST_REQUEST = "CONTACT_BATCH_LIST_REQUEST";
export const CONTACT_BATCH_LIST_SUCCESS = "CONTACT_BATCH_LIST_SUCCESS";
export const CONTACT_BATCH_LIST_FAIL = "CONTACT_BATCH_LIST_FAIL";
export const CONTACT_BATCH_LIST_RESET = "CONTACT_BATCH_LIST_RESET";

export const CONTACT_BATCH_CREATE_REQUEST = "CONTACT_BATCH_CREATE_REQUEST";
export const CONTACT_BATCH_CREATE_SUCCESS = "CONTACT_BATCH_CREATE_SUCCESS";
export const CONTACT_BATCH_CREATE_FAIL = "CONTACT_BATCH_CREATE_FAIL";
export const CONTACT_BATCH_CREATE_RESET = "CONTACT_BATCH_CREATE_RESET";

export const CONTACT_BATCH_GET_REQUEST = "CONTACT_BATCH_GET_REQUEST";
export const CONTACT_BATCH_GET_SUCCESS = "CONTACT_BATCH_GET_SUCCESS";
export const CONTACT_BATCH_GET_FAIL = "CONTACT_BATCH_GET_FAIL";
export const CONTACT_BATCH_GET_RESET = "CONTACT_BATCH_GET_RESET";

export const CONTACT_BATCH_UPDATE_REQUEST = "CONTACT_BATCH_UPDATE_REQUEST";
export const CONTACT_BATCH_UPDATE_SUCCESS = "CONTACT_BATCH_UPDATE_SUCCESS";
export const CONTACT_BATCH_UPDATE_FAIL = "CONTACT_BATCH_UPDATE_FAIL";
export const CONTACT_BATCH_UPDATE_RESET = "CONTACT_BATCH_UPDATE_RESET";

export const CONTACT_BATCH_SEND_EMAIL_REQUEST =
  "CONTACT_BATCH_SEND_EMAIL_REQUEST";
export const CONTACT_BATCH_SEND_EMAIL_SUCCESS =
  "CONTACT_BATCH_SEND_EMAIL_SUCCESS";
export const CONTACT_BATCH_SEND_EMAIL_FAIL = "CONTACT_BATCH_SEND_EMAIL_FAIL";
export const CONTACT_BATCH_SEND_EMAIL_RESET = "CONTACT_BATCH_SEND_EMAIL_RESET";

export const CONTACT_BATCH_DET_BY_IDS_REQUEST =
  "CONTACT_BATCH_DET_BY_IDS_REQUEST";
export const CONTACT_BATCH_DET_BY_IDS_SUCCESS =
  "CONTACT_BATCH_DET_BY_IDS_SUCCESS";
export const CONTACT_BATCH_DET_BY_IDS_FAIL = "CONTACT_BATCH_DET_BY_IDS_FAIL";
