import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getNotifications } from "../../Actions/notificationActions";
import moment from "moment/moment";
import { getLocalTime } from "../../core/common";

export default function Notifications() {
  const dispatch = useDispatch();
  const [UserToken, setUserToken] = useState("");
  // const { userInfo, exdt } = useSelector((s) => s.loginUsers);
  const { notifications, loading, error } = useSelector(
    (s) => s.notificationGet
  );

  const getNotification = useCallback(() => {
    const authToken = JSON.parse(localStorage.getItem("_Tsset"));
    setUserToken(authToken._id);
    dispatch(getNotifications(authToken ? authToken?._id : ""));
  }, [dispatch]);

  useEffect(() => {
    getNotification();
  }, [getNotification]);

  return (
    <div className="notification">
      {notifications ? (
        notifications.length > 0 ? (
          notifications?.map((rec, index) => (
            <div
              className="output-task-header output-task-details"
              key={index + 1}
            >
              <div className="notification-header">
                <div className="row">
                  <div className="col-md-8 text-left">
                    <i className="fas fa-user-circle"></i>{" "}
                    <h5>Notification - {rec.Notification_Code}</h5>
                  </div>
                  <div className="col-md-4 text-right">
                    <span>
                      {moment(getLocalTime(rec.createdAt)).format(
                        "DD MMM YYYY h:mm a"
                      )}
                    </span>
                  </div>
                  <div className="col-md-12 pl-3 pt-3 assign">
                    {rec.Contact_Id != "" ? (
                      <>
                        <i className="fas fa-user-circle"></i>
                        <div
                          contentEditable="false"
                          dangerouslySetInnerHTML={{
                            __html: rec?.Notification_Details,
                          }}
                        ></div>
                      </>
                    ) : rec.Deal_Id != "" ? (
                      <>
                        <i className="fas fa-user-circle"></i>
                        <div
                          contentEditable="false"
                          dangerouslySetInnerHTML={{
                            __html: rec?.Notification_Details,
                          }}
                        ></div>
                      </>
                    ) : (
                      <div
                        contentEditable="false"
                        dangerouslySetInnerHTML={{
                          __html: rec?.Notification_Details,
                        }}
                      ></div>
                    )}
                  </div>
                  <div className="col-md-12 pl-5 assign">
                    {rec.Contact_Id ? (
                      <>
                        <i
                          className="fas fa-user-circle"
                          style={{ fontSize: "24px" }}
                        ></i>
                        <Link
                          to={`/contact/edit/${rec.Contact_Id?._id}`}
                          target="_blank"
                        >
                          {rec.Contact_Id?.First_Name}{" "}
                          {rec.Contact_Id?.Last_Name}
                        </Link>
                      </>
                    ) : rec.Deal_Id ? (
                      <>
                        <i
                          className="fas fa-user-circle"
                          style={{ fontSize: "24px" }}
                        ></i>
                        <Link
                          to={`/deal/edit/${rec.Deal_Id?._id}`}
                          target="_blank"
                        >
                          {" "}
                          {rec.Deal_Id?.DealName}
                        </Link>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-md-12 text-right">
                    <i className="fa fa-ellipsis-h assign"></i>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="output-task-header output-task-details">
            <div className="notification-header">
              <div className="row">
                <div className="col-md-12 pl-3 pt-3 assign">
                  <p>There are no notifications in your inbox.3 {error}</p>
                </div>
              </div>
            </div>
          </div>
        )
      ) : (
        <div className="output-task-header output-task-details">
          <div className="notification-header">
            <div className="row">
              <div className="col-md-12 pl-3 pt-3 assign">
                {/* <p>{error}</p> */}
                <p>No Record Exist !</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
