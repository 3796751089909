import {
  DEPARTMENT_DELETE_FAIL,
  DEPARTMENT_DELETE_REQUEST,
  DEPARTMENT_DELETE_SUCCESS,
  DEPARTMENT_INSERT_FAIL,
  DEPARTMENT_INSERT_REQUEST,
  DEPARTMENT_INSERT_SUCCESS,
  DEPARTMENT_LIST_FAIL,
  DEPARTMENT_LIST_REQUEST,
  DEPARTMENT_LIST_SUCCESS,
  DEPARTMENT_UPDATE_FAIL,
  DEPARTMENT_UPDATE_REQUEST,
  DEPARTMENT_UPDATE_SUCCESS,
} from "../Constants/departmentConstants";
import api from "../core/api";

export const listDepartment = () => async (dispatch) => {
  try {
    dispatch({ type: DEPARTMENT_LIST_REQUEST });
    const { data } = await api.get(`/departments`);
    dispatch({
      type: DEPARTMENT_LIST_SUCCESS,
      payload: data.Result,
    });
  } catch (error) {
    dispatch({
      type: DEPARTMENT_LIST_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const saveDepartmentDetails = (inputJson) => async (dispatch) => {
  try {
    dispatch({ type: DEPARTMENT_INSERT_REQUEST });
    const { data } = await api.post(`/departments`, inputJson);
    dispatch({
      type: DEPARTMENT_INSERT_SUCCESS,
      payload: data.Result,
    });
  } catch (error) {
    dispatch({
      type: DEPARTMENT_INSERT_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const updateDepartmentDetails = (id, inputJson) => async (dispatch) => {
  try {
    dispatch({ type: DEPARTMENT_UPDATE_REQUEST });
    const { data } = await api.put(`/departments/${id}`, inputJson);
    dispatch({
      type: DEPARTMENT_UPDATE_SUCCESS,
      payload: data.Result,
    });
  } catch (error) {
    dispatch({
      type: DEPARTMENT_UPDATE_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const deleteDepartmentDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: DEPARTMENT_DELETE_REQUEST });
    const { data } = await api.delete(`/departments/${id}`);
    dispatch({
      type: DEPARTMENT_DELETE_SUCCESS,
      payload: data.Result,
    });
  } catch (error) {
    dispatch({
      type: DEPARTMENT_DELETE_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};
