import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listUsers } from "../../Actions/userActions";

const UserDropdown = React.memo(() => {
  const dispatch = useDispatch();
  const { usersRec } = useSelector((s) => s.usersList);
  const getRecs = useCallback(() => {
    dispatch(listUsers());
  }, [dispatch]);

  useMemo(() => {
    if (!usersRec) getRecs();
  }, [getRecs, usersRec]);

  return usersRec?.length ? (
    usersRec?.map((rec) => (
      <option value={rec?._id} key={rec?._id}>
        {rec?.First_Name
          ? rec?.First_Name + " " + rec?.Last_Name
          : rec?.EmailAddress}
      </option>
    ))
  ) : (
    <></>
  );
});

export default UserDropdown;
