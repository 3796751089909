import {
  CAMPUS_DELETE_FAIL,
  CAMPUS_DELETE_REQUEST,
  CAMPUS_DELETE_RESET,
  CAMPUS_DELETE_SUCCESS,
  CAMPUS_INSERT_FAIL,
  CAMPUS_INSERT_REQUEST,
  CAMPUS_INSERT_RESET,
  CAMPUS_INSERT_SUCCESS,
  CAMPUS_LIST_FAIL,
  CAMPUS_LIST_REQUEST,
  CAMPUS_LIST_SUCCESS,
  CAMPUS_UPDATE_FAIL,
  CAMPUS_UPDATE_REQUEST,
  CAMPUS_UPDATE_RESET,
  CAMPUS_UPDATE_SUCCESS,
  CAMPUS_USING_UNOVERSITY_LIST_REQ,
  CAMPUS_USING_UNOVERSITY_LIST_RFAIL,
  CAMPUS_USING_UNOVERSITY_LIST_SUUCESS,
} from "../Constants/campusConstants";

export const campusListReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case CAMPUS_LIST_REQUEST:
      return { loading: true };
    case CAMPUS_LIST_SUCCESS:
      return { loading: false, campusRecs: payload };
    case CAMPUS_LIST_FAIL:
      return { loading: false, error: payload };
    default:
      return state;
  }
};

export const campusSaveReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case CAMPUS_INSERT_REQUEST:
      return { loading: true };
    case CAMPUS_INSERT_SUCCESS:
      return { loading: false, success: payload };
    case CAMPUS_INSERT_FAIL:
      return { loading: false, error: payload };
    case CAMPUS_INSERT_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const campusUpdateReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case CAMPUS_UPDATE_REQUEST:
      return { loading: true };
    case CAMPUS_UPDATE_SUCCESS:
      return { loading: false, success: payload };
    case CAMPUS_UPDATE_FAIL:
      return { loading: false, error: payload };
    case CAMPUS_UPDATE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const campusDeleteReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case CAMPUS_DELETE_REQUEST:
      return { loading: true };
    case CAMPUS_DELETE_SUCCESS:
      return { loading: false, success: payload };
    case CAMPUS_DELETE_FAIL:
      return { loading: false, error: payload };
    case CAMPUS_DELETE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const campusByuniversityReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case CAMPUS_USING_UNOVERSITY_LIST_REQ: {
      return { laoding: true };
    }

    case CAMPUS_USING_UNOVERSITY_LIST_SUUCESS: {
      return { laoding: false, campusByUniversityRec: payload };
    }

    case CAMPUS_USING_UNOVERSITY_LIST_RFAIL: {
      return { laoding: false, error: payload };
    }

    default:
      return state;
  }
};
