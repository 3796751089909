export const FINANCIAL_SOURCE_LIST_REQUEST = 'FINANCIAL_SOURCE_LIST_REQUEST'
export const FINANCIAL_SOURCE_LIST_SUCCESS = 'FINANCIAL_SOURCE_LIST_SUCCESS'
export const FINANCIAL_SOURCE_LIST_FAIL = 'FINANCIAL_SOURCE_LIST_FAIL'

export const FINANCIAL_SOURCE_INSERT_REQUEST = 'FINANCIAL_SOURCE_INSERT_REQUEST'
export const FINANCIAL_SOURCE_INSERT_SUCCESS = 'FINANCIAL_SOURCE_INSERT_SUCCESS'
export const FINANCIAL_SOURCE_INSERT_FAIL = 'FINANCIAL_SOURCE_INSERT_FAIL'
export const FINANCIAL_SOURCE_INSERT_RESET = 'FINANCIAL_SOURCE_INSERT_RESET'

export const FINANCIAL_SOURCE_UPDATE_REQUEST = 'FINANCIAL_SOURCE_UPDATE_REQUEST'
export const FINANCIAL_SOURCE_UPDATE_SUCCESS = 'FINANCIAL_SOURCE_UPDATE_SUCCESS'
export const FINANCIAL_SOURCE_UPDATE_FAIL = 'FINANCIAL_SOURCE_UPDATE_FAIL'
export const FINANCIAL_SOURCE_UPDATE_RESET = 'FINANCIAL_SOURCE_UPDATE_RESET'

export const FINANCIAL_SOURCE_DELETE_REQUEST = 'FINANCIAL_SOURCE_DELETE_REQUEST'
export const FINANCIAL_SOURCE_DELETE_SUCCESS = 'FINANCIAL_SOURCE_DELETE_SUCCESS'
export const FINANCIAL_SOURCE_DELETE_FAIL = 'FINANCIAL_SOURCE_DELETE_FAIL'
export const FINANCIAL_SOURCE_DELETE_RESET = 'FINANCIAL_SOURCE_DELETE_RESET'
