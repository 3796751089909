import {
  CLASS_MODE_LIST_FAILED,
  CLASS_MODE_LIST_REQ,
  CLASS_MODE_LIST_SUCCESS,
} from "../Constants/ClassModeConstant";
import api from "../core/api";

export const listCalssMode = () => async (dispatch) => {
  try {
    dispatch({ type: CLASS_MODE_LIST_REQ });
    const { data } = await api.get(`/commonEnums/ClassMode`);
    dispatch({
      type: CLASS_MODE_LIST_SUCCESS,
      payload: data.Result,
    });
  } catch (error) {
    dispatch({
      type: CLASS_MODE_LIST_FAILED,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};
