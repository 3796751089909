import {
  SUBAGENT_USER_ASSIGNED_INSERT_FAILED,
  SUBAGENT_USER_ASSIGNED_INSERT_REQ,
  SUBAGENT_USER_ASSIGNED_INSERT_RESET,
  SUBAGENT_USER_ASSIGNED_INSERT_SUCCESS,
  SUBAGENT_USER_ASSIGNED_LIST_FAILED,
  SUBAGENT_USER_ASSIGNED_LIST_REQ,
  SUBAGENT_USER_ASSIGNED_LIST_SUCCESS,
} from "../Constants/SubAgentUserAssignedConstant";

export const subagent_ListAssignedUserReducer = (
  state = {},
  { type, payload }
) => {
  switch (type) {
    case SUBAGENT_USER_ASSIGNED_LIST_REQ: {
      return { loading: true };
    }

    case SUBAGENT_USER_ASSIGNED_LIST_SUCCESS: {
      return { loading: false, Subagent_Assigned_User_Rec: payload };
    }

    case SUBAGENT_USER_ASSIGNED_LIST_FAILED: {
      return { loading: false, error: payload };
    }
    default:
      return state;
  }
};

export const SubAgent_InsertAssignedUserReducer = (
  state = {},
  { type, payload }
) => {
  switch (type) {
    case SUBAGENT_USER_ASSIGNED_INSERT_REQ: {
      return { loading: true };
    }
    case SUBAGENT_USER_ASSIGNED_INSERT_SUCCESS: {
      return { loading: false, success: payload };
    }

    case SUBAGENT_USER_ASSIGNED_INSERT_FAILED: {
      return { loading: false, error: payload };
    }

    case SUBAGENT_USER_ASSIGNED_INSERT_RESET: {
      return { loading: false, success: "" };
    }
    default:
      return state;
  }
};
