import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { changeHeader } from "../../Actions/commonActions";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import SidePanel from "../../Components/SidePanel";
import TopBar from "../../Components/TopBar";

export default function Settings() {
  const dispatch = useDispatch();
  const [collapseOne, setCollapseOne] = useState("show");
  const [collapseTwo, setCollapseTwo] = useState("");
  const [collapseThree, setCollapseThree] = useState("");
  const [collapseFour, setCollapseFour] = useState("");

  const handleShow = (sec) => {
    setCollapseOne("");
    setCollapseTwo("");
    setCollapseThree("");
    setCollapseFour("");

    if (sec === "One") setCollapseOne("show");
    if (sec === "Two") setCollapseTwo("show");
    if (sec === "Three") setCollapseThree("show");
    if (sec === "Four") setCollapseFour("show");
  };

  useEffect(() => {
    dispatch(changeHeader("Settings"));
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title>Settings</title>
      </Helmet>
      <SidePanel page={"User"} />
      <section className="home-section">
        <TopBar Heading={"Setting"} />
        <div className="home-content" style={{ paddingTop: "100px" }}>
          <div className="settings-pro">
            <div className="container">
              <div className="accordion" id="accordionSE">
                <div className="card">
                  <div
                    className="card-header note note-primary"
                    id="headingOne"
                  >
                    <button
                      type="button"
                      className="btn btn-link"
                      data-toggle="collapse"
                      data-target="#collapseOne"
                      onClick={() => handleShow("One")}
                    >
                      <i
                        className={
                          collapseOne
                            ? "fas fa-chevron-down"
                            : "fas fa-chevron-right"
                        }
                      ></i>
                      <span className="accordion-title"> Basic Info :</span>
                    </button>
                  </div>
                  <div
                    id="collapseOne"
                    className={`collapse ${collapseOne}`}
                    aria-labelledby="headingOne"
                    data-parent="#accordionSE"
                  >
                    <div className="card-body">
                      <div className="show-settings-icons">
                        <div className="row" style={{ padding: "10px" }}>
                          <div className="col-3 col-md-1">
                            <div className="settings-icons text-center">
                              <Link to="/account">
                                <img
                                  src="../assets/img/basic_info.png"
                                  className="img-icon"
                                  alt="basic info"
                                />
                                <p>Basic Info</p>
                              </Link>
                            </div>
                          </div>
                          <div className="col-3 col-md-1">
                            <div className="settings-icons text-center">
                              <Link to="/">
                                <img
                                  src="../assets/img/basic_info.png"
                                  className="img-icon"
                                  alt="icons_notofication"
                                />
                                <p>Notifications</p>
                              </Link>
                            </div>
                          </div>
                          <div className="col-3 col-md-1">
                            <div className="settings-icons text-center">
                              <Link to="/security">
                                <img
                                  src="../assets/img/basic_info.png"
                                  className="img-icon"
                                  alt="icons_security"
                                />
                                <p>Security</p>
                              </Link>
                            </div>
                          </div>
                          <div className="col-3 col-md-1">
                            <div className="settings-icons text-center">
                              <Link to="/account">
                                <img
                                  src="../assets/img/basic_info.png"
                                  className="img-icon"
                                  alt="account_defaults"
                                />
                                <p>Account Defaults</p>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div
                    className="card-header note note-primary"
                    id="headingTwo"
                  >
                    <button
                      type="button"
                      className="btn btn-link collapsed"
                      data-toggle="collapse"
                      data-target="#collapseTwo"
                      onClick={() => handleShow("Two")}
                    >
                      <i
                        className={
                          collapseTwo
                            ? "fas fa-chevron-down"
                            : "fas fa-chevron-right"
                        }
                      ></i>
                      <span className="accordion-title"> User Control :</span>
                    </button>
                  </div>
                  <div
                    id="collapseTwo"
                    className={`collapse ${collapseTwo}`}
                    aria-labelledby="headingTwo"
                    data-parent="#accordionSE"
                  >
                    <div className="card-body">
                      <div className="show-settings-icons">
                        <div className="row" style={{ padding: "10px" }}>
                          <div className="col-3 col-md-1">
                            <div className="settings-icons text-center">
                              <Link to="/department">
                                <img
                                  src="../assets/img/icons_companies.svg"
                                  className="img-icon"
                                  alt="icons_companies"
                                />
                                <p>Departments</p>
                              </Link>
                            </div>
                          </div>

                          <div className="col-3 col-md-1">
                            <div className="settings-icons text-center">
                              <Link to="/hierarchy">
                                <img
                                  src="../assets/img/icons_domains.svg"
                                  className="img-icon"
                                  alt="icons_domains"
                                />
                                <p>Hierarchy</p>
                              </Link>
                            </div>
                          </div>

                          <div className="col-3 col-md-1">
                            <div className="settings-icons text-center">
                              <Link to="/PsychometricQuestion">
                                <img
                                  src="../assets/img/icons_domains.svg"
                                  className="img-icon"
                                  alt="icons_domains"
                                />
                                <p>Psychometric</p>
                              </Link>
                            </div>
                          </div>

                          <div className="col-3 col-md-1">
                            <div className="settings-icons text-center">
                              <Link to="/calloutcome">
                                <img
                                  src="../assets/img/Properties.svg"
                                  className="img-icon"
                                  alt="Properties"
                                />
                                <p>Call Outcome</p>
                              </Link>
                            </div>
                          </div>

                          <div className="col-3 col-md-1">
                            <div className="settings-icons text-center">
                              <Link to="/users">
                                <img
                                  src="../assets/img/icons_integrations.svg"
                                  className="img-icon"
                                  alt="icons_integrations"
                                />
                                <p>Users</p>
                              </Link>
                            </div>
                          </div>

                          <div className="col-3 col-md-1">
                            <div className="settings-icons text-center">
                              <Link to="/user-role">
                                <img
                                  src="../assets/img/icons_marketting.svg"
                                  className="img-icon"
                                  alt="icons_marketting"
                                />
                                <p>User Role</p>
                              </Link>
                            </div>
                          </div>

                          <div className="col-3 col-md-1">
                            <div className="settings-icons text-center">
                              <Link to="/team">
                                <img
                                  src="../assets/img/Properties.svg"
                                  className="img-icon"
                                  alt="Properties"
                                />
                                <p>Team</p>
                              </Link>
                            </div>
                          </div>

                          <div className="col-3 col-md-1">
                            <div className="settings-icons text-center">
                              <Link to="/class-center">
                                <img
                                  src="../assets/img/Properties.svg"
                                  className="img-icon"
                                  alt="Properties"
                                />
                                <p>Class Center</p>
                              </Link>
                            </div>
                          </div>

                          <div className="col-3 col-md-1">
                            <div className="settings-icons text-center">
                              <Link to="/timings">
                                <img
                                  src="../assets/img/icons_qualifies.svg"
                                  className="img-icon"
                                  alt="icons_qualifies"
                                />
                                <p>Timings</p>
                              </Link>
                            </div>
                          </div>

                          <div className="col-3 col-md-1">
                            <div className="settings-icons text-center">
                              <Link to="/Material-Title">
                                <img
                                  src="../assets/img/icons_qualifies.svg"
                                  className="img-icon"
                                  alt="icons_qualifies"
                                />
                                <p>Material Title</p>
                              </Link>
                            </div>
                          </div>

                          <div className="col-3 col-md-1">
                            <div className="settings-icons text-center">
                              <Link to="/Material-Sub-Title">
                                <img
                                  src="../assets/img/icons_qualifies.svg"
                                  className="img-icon"
                                  alt="icons_qualifies"
                                />
                                <p>Material Sub Title</p>
                              </Link>
                            </div>
                          </div>

                          <div className="col-3 col-md-1">
                            <div className="settings-icons text-center">
                              <Link to="/gurully">
                                <img
                                  src="../assets/img/icons_qualifies.svg"
                                  className="img-icon"
                                  alt="icons_qualifies"
                                />
                                <p>Gurully</p>
                              </Link>
                            </div>
                          </div>

                          <div className="col-3 col-md-1">
                            <div className="settings-icons text-center">
                              <Link to="/gurully-contact-link">
                                <img
                                  src="../assets/img/icons_qualifies.svg"
                                  className="img-icon"
                                  alt="icons_qualifies"
                                />
                                <p>Gurully Contact Link</p>
                              </Link>
                            </div>
                          </div>

                          <div className="col-3 col-md-1">
                            <div className="settings-icons text-center">
                              <Link to="/T-LAT-Result">
                                <img
                                  src="../assets/img/icons_qualifies.svg"
                                  className="img-icon"
                                  alt="icons_qualifies"
                                />
                                <p>T-LAT Result</p>
                              </Link>
                            </div>
                          </div>

                          <div className="col-3 col-md-1">
                            <div className="settings-icons text-center">
                              <Link to="/email-template">
                                <img
                                  src="../assets/img/icons_qualifies.svg"
                                  className="img-icon"
                                  alt="icons_qualifies"
                                />
                                <p>Email Template</p>
                              </Link>
                            </div>
                          </div>

                          <div className="col-3 col-md-1">
                            <div className="settings-icons text-center">
                              <Link to="/email-bulk/send">
                                <img
                                  src="../assets/img/icons_qualifies.svg"
                                  className="img-icon"
                                  alt="icons_qualifies"
                                />
                                <p>Email Bulk Send</p>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
