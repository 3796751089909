import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SettingLeftSection from "../Components/SettingLeftSection";
import DeletePopup from "../Components/DeletePoup";
import TopBar from "../../Components/TopBar";
import SidePanel from "../../Components/SidePanel";
import {
  CreatePsychometricQuestion,
  DeletePsychometricQuestion,
  listPsychometricQuestion,
  UpdatePsychometricQuestion,
} from "../../Actions/PsychometricQuestionAction";
import { PsychometricJson } from "../../Models/PsychometricModel";
import { MdOutlineDelete } from "react-icons/md";
import {
  PSYCHOMETRIC_QUESTION_CREATE_FAILED,
  PSYCHOMETRIC_QUESTION_CREATE_RESET,
  PSYCHOMETRIC_QUESTION_DELETE_RESET,
  PSYCHOMETRIC_QUESTION_UPDATE_RESET,
} from "../../Constants/PsychometricQuestionConstant";

export default function PsychometricQuestion() {
  const dispatch = useDispatch();
  const [PsychometricData, setPsychometricData] = useState(PsychometricJson);
  const { error, QuestionList } = useSelector(
    (s) => s.listPsychometricQuestionReducer
  );
  const [Options, setOptions] = useState([{ option: "" }]);
  const [btnText, setbtnText] = useState("Save");
  const [cancelHide, setcancelHide] = useState(false);
  const [OpenNext, setOpenNext] = useState(false);
  const [DeleteAlert, setDeleteAlert] = useState({ msg: "", Delete: () => "" });

  const {
    loading: loadingsv,
    error: errorsv,
    success: successsv,
  } = useSelector((s) => s.CreatePsychometricQuestionReducer);

  const {
    loading: loadingud,
    error: errorud,
    success: successud,
  } = useSelector((s) => s.UpdatePsychometricQuestionReducer);
  
  const {
    loading: loadingdet,
    error: errordet,
    success: successdet,
  } = useSelector((s) => s.DeletePsychometricQuestionReducer);

  const handleAddMoreOption = () => {
    setOptions([...Options, { option: "" }]);
  };

  const handleRemove = (i) => {
    const DelOption = [...Options];
    if (DelOption?.length > 1) {
      DelOption.splice(i, 1);
      setOptions([...DelOption]);
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    setPsychometricData({
      ...PsychometricData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (btnText == "Save") {
      let NOption = [];
      for (let i = 0; i < Options?.length; i++) {
        if (Options[i]?.option) {
          NOption.push(Options[i]?.option);
        }
      }
      if (NOption?.length >= 2) {
        dispatch(
          CreatePsychometricQuestion({
            ...PsychometricData,
            Options: [...NOption],
          })
        );
      } else {
        dispatch({
          type: PSYCHOMETRIC_QUESTION_CREATE_FAILED,
          payload: "Error - Minimum 2 option is required !",
        });
      }
    } else if (btnText == "Update") {
      let NOption = [];
      for (let i = 0; i < Options?.length; i++) {
        if (Options[i]?.option) {
          NOption.push(Options[i]?.option);
        }
      }

      if (NOption?.length >= 2) {
        dispatch(
          UpdatePsychometricQuestion(PsychometricData?._id, {
            ...PsychometricData,
            Options: [...NOption],
          })
        );
        // setbtnText("Save");
        // setcancelHide(false);
      } else {
        dispatch({
          type: PSYCHOMETRIC_QUESTION_CREATE_FAILED,
          payload: "Error - Minimum 2 option is required !",
        });
      }
    } else alert("Error on handleSubmit");
  };
  const getPsychometricQ = () => {
    dispatch(listPsychometricQuestion());
  };
  const handleDelete = (e, _id) => {
    e.preventDefault();
    dispatch(DeletePsychometricQuestion(_id));
  };

  const handleEdit = (e, _id, Question, QuestionType, Options, Answer) => {
    e.preventDefault();
    setbtnText("Update");
    setcancelHide(true);
    setPsychometricData({
      ...PsychometricData,
      Question,
      QuestionType,
      _id,
      Answer,
    });
    let arr = [];
    for (let i = 0; i < Options?.length; i++) {
      arr.push({ option: Options[i] });
    }
    setOptions([...arr]);
  };

  const handleCancel = () => {
    setPsychometricData({ ...PsychometricJson });
    setOptions([{ option: "" }]);
    setbtnText("Save");
    setcancelHide(false);
  };

  useEffect(() => {
    if (QuestionList == undefined) {
      getPsychometricQ();
    }
  }, [QuestionList]);

  useEffect(() => {
    if (successdet)
      setTimeout(() => {
        dispatch({ type: PSYCHOMETRIC_QUESTION_DELETE_RESET });
      }, 4000);
    if (successud)
      setTimeout(() => {
        dispatch({ type: PSYCHOMETRIC_QUESTION_UPDATE_RESET });
      }, 4000);
    if (successsv)
      setTimeout(() => {
        dispatch({ type: PSYCHOMETRIC_QUESTION_CREATE_RESET });
      }, 4000);
    if (successdet || successsv || successud) {
      getPsychometricQ();
      setPsychometricData({ ...PsychometricJson });
      setOptions([{ option: "" }]);
      handleCancel();
    }
  }, [successdet, successsv, successud, dispatch]);

  return (
    <>
      <SidePanel page={"Psychometric"} />
      <section className="home-section">
        <TopBar Heading={"Psychometric"} />
        <div
          className="home-content"
          style={{ paddingTop: window.innerWidth <= 550 ? "20px" : "100px" }}
        >
          <div className="content-body">
            {/* <!--Body-content--> */}
            <div className="container-fluid">
              <div className="heading-title">
                <div className="row">
                  <div className="col-md-12">
                    <div className="home-table-search float-right d-flex">
                      {/* <!--    <button>right side</button> --> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="inner-body settings-details">
                <div className="row">
                  <div className="col-md-3">
                    <SettingLeftSection />
                  </div>

                  <div className="col-md-9">
                    <div className="body-content-with-md-9">
                      <div>
                        <div className="container">
                          <div className="row">
                            <div className="col-md-12 text-left body-content-with-md-9-title">
                              <h4>Psychometric Question </h4>
                            </div>
                          </div>
                          <div className="row mt-3 px-5">
                            <div className="col-md-3 pt-1">
                              <label className="form-group">Question:</label>
                            </div>
                            <div className="col-md-9">
                              <textarea
                                style={{ resize: "none" }}
                                className="form-control form-control-sm"
                                name="Question"
                                placeholder="Enter Psychometric Question"
                                value={PsychometricData.Question}
                                onChange={(e) => handleChange(e)}
                              ></textarea>
                            </div>
                          </div>

                          <div className="row mt-3 px-5">
                            <div className="col-md-3 pt-1">
                              <label className="form-group">
                                Question Type:
                              </label>
                            </div>
                            <div className="col-md-9">
                              <select
                                className="form-control form-control-sm"
                                name="QuestionType"
                                value={PsychometricData.QuestionType}
                                onChange={(e) => handleChange(e)}
                              >
                                <option value="">select</option>
                                <option value={1}>Easy</option>
                                <option value={2}>Medium</option>
                                <option value={3}>Hard</option>
                              </select>
                            </div>
                          </div>

                          {Options?.map((ev, i) => (
                            <div className="row mt-3 px-5">
                              <div className="col-md-3 pt-1">
                                <label className="form-group">
                                  Options {i + 1}:
                                </label>
                              </div>

                              <div
                                style={{ display: "flex", gap: "8px" }}
                                className="col-md-9"
                              >
                                <input
                                  className="form-control form-control-sm"
                                  value={Options[i]?.option}
                                  onChange={(e) => {
                                    let Opt = [...Options];
                                    Opt[i]["option"] = e.target.value;
                                    setOptions([...Opt]);
                                  }}
                                />
                                {Options?.length > 1 ? (
                                  <div>
                                    <button
                                      style={{
                                        fontSize: "10px",
                                        padding: "3px 10px",
                                        border: "1px solid blue",
                                        borderRadius: "4px",
                                      }}
                                      onClick={() => handleRemove(i)}
                                    >
                                      <MdOutlineDelete />
                                    </button>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          ))}
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              marginRight: "40px",
                            }}
                          >
                            <button
                              style={{
                                fontSize: "10px",
                                padding: "3px 10px",
                                border: "1px solid blue",
                                borderRadius: "4px",
                              }}
                              onClick={() => handleAddMoreOption()}
                            >
                              Add More
                            </button>
                          </div>

                          <div className="row mt-3 px-5">
                            <div className="col-md-3 pt-1">
                              <label className="form-group">Answer:</label>
                            </div>
                            <div className="col-md-9">
                              <select
                                className="form-control form-control-sm"
                                name="Answer"
                                value={PsychometricData.Answer}
                                onChange={(e) => handleChange(e)}
                              >
                                <option value="">select Answer</option>
                                {Options?.map((ek, i) =>
                                  ek.option ? (
                                    <option value={ek.option} key={i + 243}>
                                      {ek?.option}
                                    </option>
                                  ) : (
                                    ""
                                  )
                                )}
                              </select>
                            </div>
                          </div>

                          <div className="row">
                            <div
                              className="col-md-12"
                              style={{ textAlign: "center" }}
                            >
                              <p>
                                <a
                                  className="btn btn-deep-orange btn-md"
                                  disabled={
                                    loadingsv || loadingud || loadingdet
                                  }
                                  onClick={(e) => {
                                    if (loadingsv || loadingud || loadingdet) {
                                    } else {
                                      handleSubmit(e);
                                    }
                                  }}
                                >
                                  {loadingsv || loadingud || loadingdet
                                    ? "Loading..."
                                    : btnText}
                                </a>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                {cancelHide ? (
                                  <a
                                    className="btn btn-outline-deep-orange btn-md"
                                    onClick={() => handleCancel()}
                                  >
                                    Cancel
                                  </a>
                                ) : (
                                  ""
                                )}
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="container">
                          <div className="row">
                            <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 ">
                              {errordet || errorsv || errorud ? (
                                <label className="alert-danger" id="SpanError">
                                  {errordet || errorsv || errorud}
                                </label>
                              ) : (
                                ""
                              )}
                              {successdet || successud || successsv ? (
                                <label
                                  className="alert-success"
                                  id="SpanSuccess"
                                >
                                  {successdet || successud || successsv}
                                </label>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="container">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="table-header-md-9 table-responsive">
                                <table className="table table-striped table-bordered">
                                  <thead>
                                    <tr>
                                      <th>Psychometric Question</th>
                                      <th>Question Type</th>
                                      <th>Answer</th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {QuestionList ? (
                                      QuestionList.map((dd) => (
                                        <tr key={dd._id}>
                                          <td>{dd.Question}</td>
                                          <td>{dd.QuestionType}</td>
                                          <td>{dd.Answer}</td>
                                          <td align="center">
                                            <input
                                              type="button"
                                              className="btn btn-info btn-sm"
                                              value={
                                                loadingud
                                                  ? "Loading.."
                                                  : "Update"
                                              }
                                              disabled={loadingud}
                                              onClick={(e) =>
                                                handleEdit(
                                                  e,
                                                  dd._id,
                                                  dd.Question,
                                                  dd?.QuestionType,
                                                  dd?.Options,
                                                  dd?.Answer
                                                )
                                              }
                                            />
                                            &nbsp;&nbsp;&nbsp;
                                            <input
                                              type="button"
                                              className="btn btn-info btn-sm"
                                              value={
                                                loadingud
                                                  ? "Loading.."
                                                  : "Delete"
                                              }
                                              disabled={loadingud}
                                              onClick={(e) => {
                                                setDeleteAlert({
                                                  msg: `Do you really want to delete this Question (${dd.Question}) ?`,
                                                  Delete: () =>
                                                    handleDelete(e, dd._id),
                                                });
                                                setOpenNext(true);
                                              }}
                                            />
                                          </td>
                                        </tr>
                                      ))
                                    ) : (
                                      <tr>
                                        <td colSpan={2}>{error}</td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!--/ body-content-with-md-9 --> */}
                  </div>
                  {/* <!--/ col-md-9 --> */}
                </div>
              </div>
            </div>
            <DeletePopup
              OpenNext={OpenNext}
              setOpenNext={setOpenNext}
              DeleteData={DeleteAlert}
            />
          </div>
        </div>
      </section>
    </>
  );
}
