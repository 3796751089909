import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listHierarchy } from "../../Actions/hierarchyActions";

// export default function HierarchyDropdown() {
//   const dispatch = useDispatch();
//   const { hierarchyRecs } = useSelector((s) => s.hierarchyList);
//   useEffect(() => {
//     dispatch(listHierarchy());
//   }, []);
//   return hierarchyRecs ? (
//     hierarchyRecs.map((recs) => (
//       <option value={recs._id} key={recs._id}>
//         {recs.HierarchyName}
//       </option>
//     ))
//   ) : (
//     <></>
//   );
// }

const HierarchyDropdown = React.memo(() => {
  const dispatch = useDispatch();
  const { hierarchyRecs } = useSelector((s) => s.hierarchyList);
  useEffect(() => {
    dispatch(listHierarchy());
  }, []);
  return hierarchyRecs ? (
    hierarchyRecs.map((recs) => (
      <option value={recs._id} key={recs._id}>
        {recs.HierarchyName}
      </option>
    ))
  ) : (
    <></>
  );
});

export default HierarchyDropdown;
