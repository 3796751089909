import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  deleteNoteLogDetails,
  listNoteLogContact,
  listNoteLogDeal,
  listNoteLogSubaegnt,
} from "../../../Actions/noteLogActions";
import { GET_TIME_ZONE } from "../Time";
import { FaRegEdit } from "react-icons/fa";

export default function NoteLogs({
  contactId,
  dealId,
  subagentId,
  setEditNote,
  openNoteTab,
}) {
  const dispatch = useDispatch();
  const [noteData, setNoteData] = useState([]);
  const { success: successdlt } = useSelector((s) => s.noteLogDelete);
  const getNotes = useCallback(() => {
    dispatch(listNoteLogSubaegnt(subagentId));
    if (contactId) {
      dispatch(listNoteLogContact(contactId));
    } else if (subagentId) {
      dispatch(listNoteLogSubaegnt(subagentId));
    } else {
      dispatch(listNoteLogDeal(dealId));
    }
  }, [dispatch, dealId, contactId, subagentId]);

  const {
    loading: loadingntc,
    error: errorntc,
    noteLogContactRec,
  } = useSelector((s) => s.noteLogContactList);

  const {
    loading: loadingntS,
    error: errorntS,
    noteLogSubAgentRec,
  } = useSelector((s) => s.noteLogSubAgentList);
  const {
    loading: loadingntd,
    error: errorntd,
    noteLogDealRec,
  } = useSelector((s) => s.noteLogDealList);

  useEffect(() => {
    if (noteLogDealRec) {
      setNoteData(noteLogDealRec);
    } else if (noteLogContactRec) {
      setNoteData(noteLogContactRec);
    } else if (noteLogSubAgentRec) {
      setNoteData(noteLogSubAgentRec);
    } else {
      setNoteData([]);
    }
  }, [noteLogContactRec, noteLogDealRec, noteLogSubAgentRec]);

  useEffect(() => {
    getNotes();
  }, [getNotes]);

  //  Delete Note

  const handleDelete = (Id) => {
    dispatch(deleteNoteLogDetails(Id));
  };

  useEffect(() => {
    if (successdlt) {
      if (contactId) dispatch(listNoteLogContact(contactId));
      else if (dealId) dispatch(listNoteLogDeal(dealId));
      else if (subagentId) dispatch(listNoteLogSubaegnt(subagentId));
    }
  }, [successdlt]);

  return (
    <>
      <div className="create-note">
        <div className="row">
          <div className="col-md-12 text-right">
            <Link className="btn" onClick={openNoteTab}>
              Create Note
            </Link>
          </div>
          {errorntc || errorntd ? (
            <div className="col-md-12 text-center mt-3">
              <p>
                Take notes about this record to keep track of important info.
                You can even @mention a teammate if you need to
              </p>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>

      {noteData ? (
        noteData?.map((dd) => (
          <div key={dd._id} className="output-task-details">
            <div className="output-task-header">
              <div className="row">
                <div className="col-md-6 text-left">
                  <span style={{ fontSize: "20px", marginRight: "7px" }}>
                    <FaRegEdit />
                  </span>
                  <h5>Note</h5>
                </div>
                <div className="col-md-6 text-right">
                  <span>{GET_TIME_ZONE(dd?.createdAt)}</span>
                  <ul className="list-inline right-action-menu">
                    <li className="list-inline-item">
                      <Link
                        onClick={() => {
                          openNoteTab();
                          setEditNote(dd);
                        }}
                      >
                        Edit
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link onClick={() => handleDelete(dd._id)}>Delete</Link>
                    </li>
                  </ul>
                </div>
                <div className="col-md-12 pl-5 pt-3">
                  <div
                    contentEditable="false"
                    dangerouslySetInnerHTML={{ __html: dd?.NoteDescription }}
                  ></div>
                </div>
              </div>
            </div>
            <div className="output-task-body pl-4">
              <div className="row">
                <div className="col-md-12 pl-5">
                  <h6>
                    <i
                      className="fas fa-user-circle"
                      style={{ color: "#d1d4d4" }}
                    ></i>
                    <b style={{ fontWeight: "500" }}>
                      {dd?.Created_By?.First_Name ? dd?.Created_By?.First_Name :"" +
                        " " +
                        dd?.Created_By?.Last_Name ? dd?.Created_By?.Last_Name : ""}
                    </b>
                    &nbsp; left a note
                  </h6>
                </div>
              </div>
            </div>
            <hr />
            <div className="output-task-footer d-flex">
              {/* <i
                className="fas fa-comment-dots"
                style={{ cursor: "pointer" }}
              ></i>
              <p>Add Comment</p> */}
            </div>
          </div>
        ))
      ) : (
        <></>
      )}
    </>
  );
}
