import SidePanel from "../../Components/SidePanel";
import TopBar from "../../Components/TopBar";
import Meta from "../../Components/Meta";

export default function Deal() {
  // const { sidebar } = useSelector((state) => state.Sidebar_Reducer);
  // const { userRec } = useSelector((state) => state.loginUsers);

  // const dispatch = useDispatch();
  const MetaDes = { title: "Testfify - Deals" };

  return (
    <>
      <Meta MetaDes={MetaDes} />
      <SidePanel page={"Deals"} />
      <section className="home-section">
        <TopBar Heading="Deal" />
        <div className="home-content" style={{ paddingTop: "100px" }}></div>
      </section>
    </>
  );
}
