export const REFERRAL_LIST_REQUEST = 'REFERRAL_LIST_REQUEST'
export const REFERRAL_LIST_SUCCESS = 'REFERRAL_LIST_SUCCESS'
export const REFERRAL_LIST_FAIL = 'REFERRAL_LIST_FAIL'

export const REFERRAL_INSERT_REQUEST = 'REFERRAL_INSERT_REQUEST'
export const REFERRAL_INSERT_SUCCESS = 'REFERRAL_INSERT_SUCCESS'
export const REFERRAL_INSERT_FAIL = 'REFERRAL_INSERT_FAIL'
export const REFERRAL_INSERT_RESET = 'REFERRAL_INSERT_RESET'

export const REFERRAL_UPDATE_REQUEST = 'REFERRAL_UPDATE_REQUEST'
export const REFERRAL_UPDATE_SUCCESS = 'REFERRAL_UPDATE_SUCCESS'
export const REFERRAL_UPDATE_FAIL = 'REFERRAL_UPDATE_FAIL'
export const REFERRAL_UPDATE_RESET = 'REFERRAL_UPDATE_RESET'

export const REFERRAL_DELETE_REQUEST = 'REFERRAL_DELETE_REQUEST'
export const REFERRAL_DELETE_SUCCESS = 'REFERRAL_DELETE_SUCCESS'
export const REFERRAL_DELETE_FAIL = 'REFERRAL_DELETE_FAIL'
export const REFERRAL_DELETE_RESET = 'REFERRAL_DELETE_RESET'
