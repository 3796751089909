export const SUBAGENT_NOTIFICATIONS_LIST_REQUEST =
  "SUBAGENT_NOTIFICATIONS_LIST_REQUEST";
export const SUBAGENT_NOTIFICATIONS_LIST_SUCCESS =
  "SUBAGENT_NOTIFICATIONS_LIST_SUCCESS";
export const SUBAGENT_NOTIFICATIONS_LIST_FAIL =
  "SUBAGENT_NOTIFICATIONS_LIST_FAIL";

export const SUBAGENT_NOTIFICATIONS_GET_REQUEST =
  "SUBAGENT_NOTIFICATIONS_GET_REQUEST";
export const SUBAGENT_NOTIFICATIONS_GET_SUCCESS =
  "SUBAGENT_NOTIFICATIONS_GET_SUCCESS";
export const SUBAGENT_NOTIFICATIONS_GET_FAIL =
  "SUBAGENT_NOTIFICATIONS_GET_FAIL";

export const SUBAGENT_NOTIFICATIONS_INSERT_REQUEST =
  "SUBAGENT_NOTIFICATIONS_INSERT_REQUEST";
export const SUBAGENT_NOTIFICATIONS_INSERT_SUCCESS =
  "SUBAGENT_NOTIFICATIONS_INSERT_SUCCESS";
export const SUBAGENT_NOTIFICATIONS_INSERT_FAIL =
  "SUBAGENT_NOTIFICATIONS_INSERT_FAIL";
export const SUBAGENT_NOTIFICATIONS_INSERT_RESET =
  "SUBAGENT_NOTIFICATIONS_INSERT_RESET";
