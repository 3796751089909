import SidePanel from "../../Components/SidePanel";
import TopBar from "../../Components/TopBar";
import { useDispatch, useSelector } from "react-redux";
import Meta from "../../Components/Meta";
import { useCallback, useEffect, useState } from "react";
import { toastOptions } from "../../Models/ToastEditiorOption";
import { toast, ToastContainer } from "react-toastify";
import { Coupon_Model } from "../../Models/Coupon_Model";
import {
  Coupon_Create,
  Coupon_List,
  CouponDelete,
  couponDetails,
  updateCoupon,
} from "../../Actions/CouponAction";
import Pagination from "../Components/Pagination";
import { FaRegEdit } from "react-icons/fa";
import {
  COUPON_DELETE_RESET,
  CREATE_COUPON_RESET,
  UPDATE_COUPON_RESET,
} from "../../Constants/CouponConstant";
import { MdDelete } from "react-icons/md";
import DeletePopup from "../Components/DeletePoup";

export default function Coupon() {
  const [CouponEdit, setCouponEdit] = useState(false);
  const [CouponCreateData, setCouponCreateData] = useState(Coupon_Model);
  const [MobileFilterActive, setMobileFilterActive] = useState(false);
  const [createSec, setCreateSec] = useState(false);
  const [DeleteAlert, setDeleteAlert] = useState({ msg: "", Delete: () => "" });
  const [OpenNext, setOpenNext] = useState(false);
  const [showActiveSec, setShowActiveSec] = useState("primary");

  const [CouponFilterData, setCouponFilterData] = useState({
    page_size: 10,
    page_no: 1,
  });

  const {
    loading: loadingUpt,
    success: successUpt,
    error: errorUpt,
  } = useSelector((s) => s.updateCouponReducer);

  const mobile_View = window.innerWidth;

  const { loading, error, CouponRec, total } = useSelector(
    (s) => s.Coupon_List_Reducer
  );

  const handleChange = (e) => {
    setCouponCreateData({
      ...CouponCreateData,
      [e.target.name]: e.target.value,
    });
  };

  const handleCancel = () => {
    setCreateSec(false);
  };

  const {
    loading: loadingsv,
    error: errorsv,
    success: successsv,
  } = useSelector((s) => s.Coupon_Create_Reducer);

  const {
    loading: loadingDel,
    success: successDel,
    error: errorDel,
  } = useSelector((s) => s.Coupon_Delete_Reducer);

  const dispatch = useDispatch();

  const MetaDes = { title: "Testfify - Contact" };

  const Get_Coupons = useCallback(() => {
    dispatch(Coupon_List(CouponFilterData));
  }, [dispatch, CouponFilterData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(Coupon_Create(CouponCreateData));
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    dispatch(updateCoupon(CouponCreateData, CouponCreateData?._id));
  };

  const handleDelete = (e, _id) => {
    e.preventDefault();
    dispatch(CouponDelete(_id));
  };

  useEffect(() => {
    Get_Coupons();
  }, [Get_Coupons]);

  useEffect(() => {
    if (successsv) {
      setCreateSec(false);
      setCouponCreateData(Coupon_Model);
      toast.success(successsv, toastOptions);
      Get_Coupons();
      setTimeout(() => {
        dispatch({ type: CREATE_COUPON_RESET });
      }, 2000);
    }
    if (errorsv) {
      toast.success(errorsv, toastOptions);
    }
  }, [successsv, dispatch, errorsv, Get_Coupons]);

  useEffect(() => {
    if (successUpt) {
      setCreateSec(false);
      setCouponCreateData(Coupon_Model);
      toast.success(successUpt, toastOptions);
      Get_Coupons();
      setTimeout(() => {
        dispatch({ type: UPDATE_COUPON_RESET });
      }, 2000);
    }
    if (errorUpt) {
      toast.success(errorUpt, toastOptions);
    }
  }, [successUpt, dispatch, errorUpt, Get_Coupons]);

  useEffect(() => {
    if (successDel) {
      toast.success(successDel, toastOptions);
      Get_Coupons();
      setTimeout(() => {
        dispatch({ type: COUPON_DELETE_RESET });
      }, 2000);
    }
    if (errorDel) {
      toast.success(errorDel, toastOptions);
    }
  }, [successDel, dispatch, errorDel, Get_Coupons]);

  useEffect(() => {
    if (!CouponEdit) {
      setCouponCreateData(Coupon_Model);
    }
  }, [CouponEdit]);

  return (
    <>
      <Meta MetaDes={MetaDes} />
      <SidePanel page={"Coupons"} />
      <section className="home-section">
        <TopBar Heading="Coupons" />
        <div className="home-content" style={{ paddingTop: "70px" }}>
          <div className="Contact-Middle">
            <div className="Contact_Top_Header">
              <div className="home-title float-left"></div>
              <div style={{ display: "flex", gap: "20px" }} className="">
                <div className="input-group search-contacts">
                  <input
                    type="Search"
                    className="form-control"
                    name="searchtxt"
                    style={{ display: "block" }}
                    // value={Search}
                    // onChange={(e) => {
                    //   if (e.target.value === "") {
                    //     setFilterData({ ...FilterData, searchtxt: "" });
                    //     setCouponFilterData({
                    //       ...CouponFilterData,
                    //       searchtxt: "",
                    //     });
                    //   }
                    //   setSearch(e.target.value);
                    // }}
                    // onKeyDown={(e) => {
                    //   if (
                    //     e.key === "Enter" &&
                    //     Search !== "" &&
                    //     Search !== undefined
                    //   ) {
                    //     setFilterData({ ...FilterData, searchtxt: Search });
                    //     setCouponFilterData({
                    //       ...CouponFilterData,
                    //       searchtxt: Search,
                    //     });
                    //   }
                    // }}
                    placeholder="search for Coupons"
                  />
                  <i
                    className="fa fa-search"
                    // onClick={() => {
                    //   if (Search !== "" && Search !== undefined) {
                    //     setFilterData({ ...FilterData, searchtxt: Search });
                    //     setCouponFilterData({
                    //       ...CouponFilterData,
                    //       searchtxt: Search,
                    //     });
                    //   }
                    // }}
                    aria-hidden="true"
                  ></i>
                </div>
                <div className="right-click Create-btn">
                  <a
                    style={{ width: "180px" }}
                    className="btn create-contact"
                    onClick={(e) => {
                      setCreateSec(true);
                    }}
                  >
                    Create Coupon
                  </a>
                </div>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-md-4 col-lg-3">
                <div
                  style={
                    mobile_View <= 480
                      ? { marginBottom: "20px" }
                      : { cursor: "auto" }
                  }
                  className="add-filter"
                >
                  <div
                    className={
                      MobileFilterActive
                        ? "filter-title  filter-title-mob filter-body-active"
                        : "filter-title  filter-title-mob"
                    }
                  >
                    <h5>Filter Coupon</h5>
                    <button
                      onClick={() => setMobileFilterActive(false)}
                      className="mob-nav-open"
                    >
                      ☰
                    </button>
                  </div>

                  <div
                    className={
                      MobileFilterActive
                        ? "filter-body filter-body-mob  filter-body-active custom-scroll-right pt-0"
                        : "filter-body filter-body-mob custom-scroll-right pt-0"
                    }
                  >
                    <div className="sticky pt-2">
                      <p style={{ marginBottom: "-20px" }}>
                        showing {total ? total : 0} Coupons
                      </p>

                      <br />

                      <button
                        style={{ color: "white" }}
                        className="btn btn-sm btn-deep-orange"
                        onClick={() => {
                          //   setFilterData({
                          //     ...contactFilterJson,
                          //     Assigned_User_Id: userId,
                          //   });
                          setCouponFilterData({
                            // ...contactFilterJson,
                            // Assigned_User_Id: userId,
                          });
                          setShowActiveSec("primary");
                        }}
                      >
                        Clear All
                      </button>
                    </div>
                    <ul className="modify-filter-title">
                      {/*                       
                      <li className="chose">Qualifies</li>
                      <li>
                        <QualifiesMultiSelectDropdown
                          values={
                            CouponFilterData?.Qualifies
                              ? CouponFilterData?.Qualifies
                              : []
                          }
                          handleChange={(e) => {
                            setCouponFilterData({
                              ...CouponFilterData,
                              Qualifies: e,
                            });
                          }}
                        />
                      </li>
                      <li className="chose">Contact Rating</li>
                      <li>
                        <ContactRatingMultiSelectDropdown
                          values={
                            CouponFilterData?.Contact_Rating
                              ? CouponFilterData?.Contact_Rating
                              : []
                          }
                          handleChange={(e) => {
                            setCouponFilterData({
                              ...CouponFilterData,
                              Contact_Rating: e,
                            });
                          }}
                        />
                      </li>
                      <li className="chose">Team</li>
                      <li>
                        <TeamMultiSelctDropdown
                          values={
                            CouponFilterData?.Team_Id
                              ? CouponFilterData?.Team_Id
                              : []
                          }
                          handleChange={(e) => {
                            setCouponFilterData({
                              ...CouponFilterData,
                              Team_Id: e,
                            });
                          }}
                        />
                      </li>
                      <li className="chose">Educational Status</li>
                      <li>
                        <EducationalStatusMultiSelectDropdown
                          values={
                            CouponFilterData?.Educational_Status
                              ? CouponFilterData?.Educational_Status
                              : []
                          }
                          handleChange={(e) => {
                            setCouponFilterData({
                              ...CouponFilterData,
                              Educational_Status: e,
                            });
                          }}
                        />
                      </li>
                      <li className="chose">Create Date</li>
                      <li>
                        <div className="myDatePickerContainer">
                          <ReactDatePicker
                            onKeyDown={(e) => {
                              e.preventDefault();
                            }}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            yearDropdownItemNumber={100}
                            scrollableYearDropdown
                            dropdownMode="scroll"
                            dateFormat="dd-MM-yyyy"
                            className="myDatePicker"
                            style={{ width: "100%" }}
                            selected={CouponFilterData.Create_Date_From}
                            onChange={(date) => {
                              //   setFilterData({
                              //     ...FilterData,
                              //     Create_Date_From: date,
                              //   });

                              setCouponFilterData({
                                ...CouponFilterData,
                                Create_Date_From: date,
                              });
                            }}
                            placeholderText="dd/mm/yy"
                          />
                        </div>
                      </li>
                      <li style={{ textAlign: "center" }}>to</li>
                      <li>
                        <div className="myDatePickerContainer">
                          <ReactDatePicker
                            onKeyDown={(e) => {
                              e.preventDefault();
                            }}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            yearDropdownItemNumber={100}
                            scrollableYearDropdown
                            dropdownMode="scroll"
                            dateFormat="dd-MM-yyyy"
                            className="myDatePicker"
                            selected={CouponFilterData.Create_Date_To}
                            onChange={(date) => {
                              //   setFilterData({
                              //     ...FilterData,
                              //     Create_Date_To: date,
                              //   });
                              setCouponFilterData({
                                ...CouponFilterData,
                                Create_Date_To: date,
                              });
                            }}
                            placeholderText="dd/mm/yy"
                          />
                        </div>
                      </li>
                      <li className="chose">Academic Percentage</li>
                      <li>
                        <div className="row">
                          <div className="col-md-6">
                            <select
                              name="AcademicPercentage_From"
                              value={CouponFilterData.AcademicPercentage_From}
                              onChange={handleFilterChange}
                              className="form-control"
                            >
                              <option value="">--Select--</option>
                              <PercentageDropdown start="40" end="95" />
                            </select>
                          </div>
                          <div className="col-md-6">
                            <select
                              name="AcademicPercentage_To"
                              onChange={handleFilterChange}
                              value={CouponFilterData.AcademicPercentage_To}
                              className="form-control"
                            >
                              <option value="">--Select--</option>
                              <PercentageDropdown start="45" end="100" />
                            </select>
                          </div>
                        </div>
                      </li>
                      <li className="chose">Preferred Intake</li>
                      <li>
                        <PreferredIntakeMultiSelectDropdown
                          values={
                            CouponFilterData?.Preferred_Intake
                              ? CouponFilterData?.Preferred_Intake
                              : []
                          }
                          handleChange={(e) =>
                            setCouponFilterData({
                              ...CouponFilterData,
                              Preferred_Intake: e,
                            })
                          }
                        />
                      </li>
                      <li className="chose">Intake Year</li>
                      <li>
                        <YearIntakeMultiSelectDropdown
                          values={
                            CouponFilterData?.Intake_Year
                              ? CouponFilterData?.Intake_Year
                              : []
                          }
                          handleChange={(e) =>
                            setCouponFilterData({
                              ...CouponFilterData,
                              Intake_Year: e,
                            })
                          }
                        />
                      </li>
                      <li className="chose">First Activity Date</li>
                      <li>
                        <div className="myDatePickerContainer">
                          <ReactDatePicker
                            onKeyDown={(e) => {
                              e.preventDefault();
                            }}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            yearDropdownItemNumber={100}
                            scrollableYearDropdown
                            dropdownMode="scroll"
                            dateFormat="dd-MM-yyyy"
                            className="myDatePicker"
                            selected={
                              CouponFilterData.First_Activity_Date_From
                            }
                            onChange={(date) => {
                              //   setFilterData({
                              //     ...FilterData,
                              //     First_Activity_Date_From: date,
                              //   });
                              setCouponFilterData({
                                ...CouponFilterData,
                                First_Activity_Date_From: date,
                              });
                            }}
                            placeholderText="dd/mm/yy"
                          />
                        </div>
                      </li>
                      <li style={{ textAlign: "center" }}>to</li>
                      <li>
                        <div className="myDatePickerContainer">
                          <ReactDatePicker
                            onKeyDown={(e) => {
                              e.preventDefault();
                            }}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            yearDropdownItemNumber={100}
                            scrollableYearDropdown
                            dropdownMode="scroll"
                            dateFormat="dd-MM-yyyy"
                            className="myDatePicker"
                            selected={CouponFilterData.First_Activity_Date_To}
                            onChange={(date) => {
                              //   setFilterData({
                              //     ...FilterData,
                              //     First_Activity_Date_To: date,
                              //   });
                              setCouponFilterData({
                                ...CouponFilterData,
                                First_Activity_Date_To: date,
                              });
                            }}
                            placeholderText="dd/mm/yy"
                          />
                        </div>
                      </li>
                      <li className="chose">Last Activity Date</li>
                      <li>
                        <div className="myDatePickerContainer">
                          <ReactDatePicker
                            onKeyDown={(e) => {
                              e.preventDefault();
                            }}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            yearDropdownItemNumber={100}
                            scrollableYearDropdown
                            dropdownMode="scroll"
                            dateFormat="dd-MM-yyyy"
                            className="myDatePicker"
                            selected={CouponFilterData.Last_Activity_Date_From}
                            onChange={(date) => {
                              //   setFilterData({
                              //     ...FilterData,
                              //     Last_Activity_Date_From: date,
                              //   });

                              setCouponFilterData({
                                ...CouponFilterData,
                                Last_Activity_Date_From: date,
                              });
                            }}
                            placeholderText="dd/mm/yy"
                          />
                        </div>
                      </li>
                      <li style={{ textAlign: "center" }}>to</li>
                      <li>
                        <div className="myDatePickerContainer">
                          <ReactDatePicker
                            onKeyDown={(e) => {
                              e.preventDefault();
                            }}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            yearDropdownItemNumber={100}
                            scrollableYearDropdown
                            dropdownMode="scroll"
                            dateFormat="dd-MM-yyyy"
                            className="myDatePicker"
                            selected={CouponFilterData.Last_Activity_Date_To}
                            onChange={(date) => {
                              //   setFilterData({
                              //     ...FilterData,
                              //     Last_Activity_Date_To: date,
                              //   });

                              setCouponFilterData({
                                ...CouponFilterData,
                                Last_Activity_Date_To: date,
                              });
                            }}
                            placeholderText="dd/mm/yy"
                          />
                        </div>
                      </li> */}
                      {/* <li className="chose">Profile</li>
                      <li>
                        <ProfileMultiSelectDropdown
                          values={
                            CouponFilterData?.Profile_Status
                              ? CouponFilterData?.Profile_Status
                              : []
                          }
                          handleChange={(e) =>
                            setCouponFilterData({
                              ...CouponFilterData,
                              Profile_Status: e,
                            })
                          }
                        />
                      </li>
                      <li className="chose">Enquiry For</li> */}
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-md-8 col-lg-9">
                <div
                  style={{ maxHeight: "60vh" }}
                  className="Contact-table-wrapper"
                >
                  <table>
                    <thead>
                      <tr className="sticky">
                        <th>Edit</th>
                        <th>Delete</th>
                        <th>Coupon Code</th>
                        <th>No Of Coupons</th>
                        <th>Percentage</th>
                        <th>Contact Name</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading || loadingDel ? (
                        <tr>
                          <td colSpan={10}>Laoding... </td>
                        </tr>
                      ) : error ? (
                        <tr>
                          <td colSpan={10}>{error}</td>
                        </tr>
                      ) : (
                        CouponRec &&
                        CouponRec?.map((ev) => (
                          <tr key={ev._id}>
                            <td
                              onClick={() => {
                                dispatch(couponDetails(ev._id));
                                setCouponEdit(true);
                                setCouponCreateData({ ...ev });
                                setCreateSec(true);
                              }}
                              style={{ cursor: "pointer", color: "blue" }}
                            >
                              <FaRegEdit />
                            </td>
                            <td
                              onClick={(e) => {
                                setDeleteAlert({
                                  msg: `Do you really want to delete this Hierarchy (${ev.CouponCode}) ?`,
                                  Delete: () => handleDelete(e, ev._id),
                                });
                                setOpenNext(true);
                              }}
                              style={{ cursor: "pointer", color: "red" }}
                            >
                              <MdDelete />
                            </td>
                            <td>{ev?.CouponCode}</td>
                            <td>{ev?.NoOfCoupons}</td>
                            <td>{ev?.Percentage}</td>
                            <td>{ev?.ContactName}</td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
                <div>
                  <Pagination
                    setFilterQuery={setCouponFilterData}
                    FilterQuery={CouponFilterData}
                    Total={total}
                  />
                </div>

                {/* start create */}

                <div
                  id="CreatContact"
                  className={createSec ? "overlay-note w-100" : "overlay-note"}
                >
                  <div className="overlay-add-deals contact-right-side">
                    <div className="note-title">
                      <h4 className="text-left text-white pl-3">
                        {CouponEdit ? "Update Coupon" : "Create Coupon"}
                      </h4>
                      <a
                        className="closebtn text-right text-white"
                        onClick={(e) => setCreateSec(false)}
                      >
                        ×
                      </a>
                    </div>

                    <div
                      className="contact-body modify-create-contact"
                      style={{ height: "95vh" }}
                    >
                      <div className="form-group">
                        <label>
                          Coupon Code:<b>*</b>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Coupon Code"
                          name="CouponCode"
                          value={CouponCreateData.CouponCode}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>

                      <div className="form-group">
                        <label>
                          No Of Coupons:<b>*</b>
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter No Of Coupons"
                          name="NoOfCoupons"
                          value={CouponCreateData.NoOfCoupons}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>

                      <div className="form-group first-input">
                        <label>
                          Percentage:<b>*</b>
                        </label>

                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Percentage"
                          name="Percentage"
                          value={CouponCreateData.Percentage}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>

                      <div
                        // className="contact-footer w-100"
                        style={{
                          // padding: "50px 25px",
                          backgroundColor: "transparent",
                          marginTop: "26px",
                        }}
                        // ref={ErrorSuccessRef}
                      ></div>

                      <div className="contact-footer w-100">
                        <div>
                          {CouponEdit ? (
                            <button
                              type="button"
                              className="btn"
                              disabled={loadingUpt}
                              onClick={handleUpdate}
                            >
                              {loadingUpt ? "Loading..." : "Update Coupon"}
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="btn"
                              disabled={loadingsv}
                              onClick={handleSubmit}
                            >
                              {loadingsv ? "Loading..." : "Create Coupon"}
                            </button>
                          )}

                          <button
                            type="button"
                            className="btn btn-deep-orange waves-effect waves-light"
                            onClick={handleCancel}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Coupon Create section end */}
              </div>
            </div>
            <DeletePopup
              OpenNext={OpenNext}
              setOpenNext={setOpenNext}
              DeleteData={DeleteAlert}
            />
          </div>
          <ToastContainer />
        </div>
      </section>
    </>
  );
}
