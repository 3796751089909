import {
  AFFILIATE_PARTNER_LIST_FAIL,
  AFFILIATE_PARTNER_LIST_REQUEST,
  AFFILIATE_PARTNER_LIST_SUCCESS,
} from "../Constants/affiliatePartnerConstants";

export const affiliatePartnerListReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case AFFILIATE_PARTNER_LIST_REQUEST: {
      return { laoding: true };
    }

    case AFFILIATE_PARTNER_LIST_SUCCESS: {
      return { loading: false, affiliatePartnerRecs: payload };
    }

    case AFFILIATE_PARTNER_LIST_FAIL: {
      return { laoding: false, error: payload };
    }
    default:
      return state;
  }
};
