import {
  CART_DETAIL_CREATE_FAILED,
  CART_DETAIL_CREATE_REQ,
  CART_DETAIL_CREATE_SUCCESS,
  CART_DETAIL_REMOVE_FAILED,
  CART_DETAIL_REMOVE_REQ,
  CART_DETAIL_REMOVE_SUCCESS,
  CART_DETAIL_UPDATE_FAILED,
  CART_DETAIL_UPDATE_REQ,
  CART_DETAIL_UPDATE_SUCCESS,
  CART_LIST_FAILED,
  CART_LIST_REQ,
  CART_LIST_SUCCESS,
  CART_PLACE_ORDER_FAILED,
  CART_PLACE_ORDER_REQ,
  CART_PLACE_ORDER_SUCCESS,
  CART_TOTAL_LIST_REQ,
  CART_TOTAL_LIST_SUCCESS,
} from "../Constants/cartConstant";

import api from "../core/api";

export const listCartRec = (cartFilter) => async (dispatch) => {
  try {
    dispatch({ type: CART_LIST_REQ });
    const { data } = await api.get(
      `/cart?page_size=${cartFilter?.page_size}&page_no=${cartFilter?.page_no}`
    );

    dispatch({ type: CART_LIST_SUCCESS, payload: data?.Result });
  } catch (error) {
    dispatch({
      type: CART_LIST_FAILED,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const listCartRecByContact = (ContactId) => async (dispatch) => {
  try {
    dispatch({ type: CART_LIST_REQ });
    const { data } = await api.get(`/cart/contact/${ContactId}`);

    dispatch({ type: CART_LIST_SUCCESS, payload: data?.Result });
  } catch (error) {
    dispatch({
      type: CART_LIST_FAILED,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const totalCart = () => async (dispatch) => {
  try {
    dispatch({ type: CART_TOTAL_LIST_REQ });
    const { data } = await api.get("/cart/total");

    dispatch({ type: CART_TOTAL_LIST_SUCCESS, payload: data?.Result });
  } catch (error) {
    dispatch({
      type: CART_TOTAL_LIST_SUCCESS,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const createCartDet = (inputJson) => async (dispatch) => {
  try {
    dispatch({ type: CART_DETAIL_CREATE_REQ });
    const { data } = await api.post("/cart", inputJson);
    dispatch({ type: CART_DETAIL_CREATE_SUCCESS, payload: data?.Result });
  } catch (error) {
    dispatch({
      type: CART_DETAIL_CREATE_FAILED,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const updateCartDet = (inputJson, Id) => async (dispatch) => {
  try {
    dispatch({ type: CART_DETAIL_UPDATE_REQ });
    const { data } = await api.put(`/cart/${Id}`, inputJson);
    dispatch({ type: CART_DETAIL_UPDATE_SUCCESS, payload: data?.Result });
  } catch (error) {
    dispatch({
      type: CART_DETAIL_UPDATE_FAILED,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const removeCartDet = (Id) => async (dispatch) => {
  try {
    dispatch({ type: CART_DETAIL_REMOVE_REQ });
    const { data } = await api.delete(`/cart/${Id}`);
    dispatch({ type: CART_DETAIL_REMOVE_SUCCESS, payload: data?.Result });
  } catch (error) {
    dispatch({
      type: CART_DETAIL_REMOVE_FAILED,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const placeOrder = (Id) => async (dispatch) => {
  // console.log(Id)
  try {
    dispatch({ type: CART_PLACE_ORDER_REQ });

    const { data } = await api.post(`/cart/placeByorder/${Id}`);
    dispatch({ type: CART_PLACE_ORDER_SUCCESS, payload: data?.Result });
  } catch (error) {
    dispatch({
      type: CART_PLACE_ORDER_FAILED,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};
