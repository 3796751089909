export const MockTestJson = {
  MockNo: "",
  MockDescription: "",
  CourseId: "",
  SubCategoryId: "",
};

export const MockTest_Validator_Model = (Data, MockDesc, UploadedDesc) => {
  let Error = "";
  if (
    Data?.CourseId === "" ||
    Data?.CourseId === undefined ||
    Data?.CourseId === null
  ) {
    Error += "Course Id is required, ";
  }
  if (
    Data?.MockNo === "" ||
    Data?.MockNo === undefined ||
    Data?.MockNo === null
  ) {
    Error += "Mock No is required, ";
  }

  if (MockDesc?.length == 0) {
    Error += "Mock Description is required, ";
  }

  for (let i = 0; i < MockDesc?.length; i++) {
    if (
      MockDesc[i] == "InputText" &&
      !UploadedDesc[i]?.desc?.replace(/(<([^>]+)>)/gi, "").trim()?.length
    ) {
      Error += " Input Text Should Not Empty !";
    } else if (MockDesc[i] == "Image") {
      if (!UploadedDesc[i]?.image && !UploadedDesc[i]?.url)
        Error += " Image  Should Not Empty !";
    } else if (MockDesc[i] == "Mp3") {
      if (!UploadedDesc[i]?.audio && !UploadedDesc[i]?.url) {
        Error += " Mp3 file  Should Not Empty !";
      }
    }
  }

  if (Error) {
    return Error;
  } else {
    return "success";
  }
};

export const checkDomain = (url, targetDomain) => {
  try {
    const parsedUrl = new URL(url);
    return parsedUrl.hostname === new URL(targetDomain).hostname;
  } catch (error) {
    console.error("Invalid URL:", error);
    return false;
  }
};
