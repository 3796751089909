import {
  DEAL_TASK_CREATE_FAILED,
  DEAL_TASK_CREATE_REQUEST,
  DEAL_TASK_CREATE_RESET,
  DEAL_TASK_CREATE_SUCCESS,
  DEAL_TASK_STEP_FAILED,
  DEAL_TASK_STEP_REQUEST,
  DEAL_TASK_STEP_SUCCESS,
} from "../Constants/DealTaskConstant";

export const Create_Deal_Task_Reducer = (state = {}, { type, payload }) => {
  switch (type) {
    case DEAL_TASK_CREATE_REQUEST: {
      return { loading: true };
    }

    case DEAL_TASK_CREATE_SUCCESS: {
      return { loading: false, success: payload };
    }

    case DEAL_TASK_CREATE_FAILED: {
      return { loading: false, error: payload };
    }

    case DEAL_TASK_CREATE_RESET: {
      return { loading: false, success: "" };
    }
    default: {
      return state;
    }
  }
};

export const Step_Deal_Reducer = (state = {}, { type, payload }) => {
  switch (type) {
    case DEAL_TASK_STEP_REQUEST: {
      return { loading: true };
    }

    case DEAL_TASK_STEP_SUCCESS: {
      return { loading: false, DealVisaFlowStep: payload };
    }

    case DEAL_TASK_STEP_FAILED: {
      return { loading: false, error: payload };
    }

    default:
      return state;
  }
};
