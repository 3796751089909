import SidePanel from "../../Components/SidePanel";
import TopBar from "../../Components/TopBar";
import { useDispatch, useSelector } from "react-redux";
import Meta from "../../Components/Meta";
import { useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { toastOptions } from "../../Models/ToastEditiorOption";
import { toast, ToastContainer } from "react-toastify";
import CourseDropDown from "../Components/CourseDropDown";
import Pagination from "../Components/Pagination";
import { FaRegEdit } from "react-icons/fa";
import SEDLoading from "../../Components/SEDLoading";
import { MdDelete } from "react-icons/md";
import DeletePopup from "../Components/DeletePoup";
import { listCourses } from "../../Actions/courseAction";
import { listMaterialTitle } from "../../Actions/MaterialTitleAction";
import { AiFillDelete } from "react-icons/ai";

import {
  createMockTest,
  listMockTest,
  mockDetails,
  removeMockTest,
  totalMockTest,
  updateMockTest,
} from "../../Actions/MockTestAction";

import {
  MockTestJson,
  MockTest_Validator_Model,
  checkDomain,
} from "../../Models/mockTestModel";

import {
  MOCKTEST_CREATE_RESET,
  MOCKTEST_REMOVE_RESET,
  MOCKTEST_UPDATE_RESET,
} from "../../Constants/MockTestConstant";

import ReactQuill from "react-quill";
import api from "../../core/api";
const Img = { image: "", preview: "", name: "" };
const audio = { audio: "", preview: "", name: "" };

export default function MockTest() {
  const [MockTestCreateData, setMockTestCreateData] = useState(MockTestJson);
  const [MockDescription, setMockDescription] = useState([]);
  const [MaterialEditData, setMaterialEditData] = useState("");
  const [MobileFilterActive, setMobileFilterActive] = useState(false);
  const [isSubCate, setisSubCate] = useState(false);
  const [MockDesc, setMockDesc] = useState([]);
  const [UploadedDesc, setUploadedDesc] = useState([]);
  const [Load, setLoad] = useState(false);

  const [createSec, setCreateSec] = useState(false);
  const [materialFilterData, setmaterialFilterData] = useState({
    page_size: 10,
    page_no: 1,
  });
  const { total } = useSelector((s) => s.totalMockTestReducer);
  const [OpenNext, setOpenNext] = useState(false);
  const [DeleteAlert, setDeleteAlert] = useState({ msg: "", Delete: () => "" });
  const mobile_View = window.innerWidth;

  const { CouresesRec } = useSelector((s) => s.CourseListAllRec);

  const { loading, error, mockTestRecs } = useSelector(
    (s) => s.listMockTestReducer
  );

  const { DetMockTest, loading: loadingDet } = useSelector(
    (s) => s.mockTestDetailsReducer
  );

  const {
    loaidng: loadingUpt,
    success: successUpt,
    error: errorUpt,
  } = useSelector((s) => s.updateMockTestReducer);

  const ErrorSuccessRef = useRef();

  const handleDescRemove = (index) => {
    setLoad(true);
    let Details1 = [...MockDesc];
    let UploadDetail1 = [...UploadedDesc];
    Details1.splice(index, 1);
    UploadDetail1.splice(index, 1);
    setTimeout(() => {
      setMockDesc([...Details1]);
      setUploadedDesc([...UploadDetail1]);
      setLoad(false);
    }, 1500);
  };
  const handleCancel = () => {
    setCreateSec(false);
    setMaterialEditData(false);
    setMockTestCreateData(MockTestJson);
    setMockDescription("");
    setMockDesc([]);
    setUploadedDesc([]);
  };

  const {
    loading: loadingsv,
    error: errorsv,
    success: successsv,
  } = useSelector((s) => s.createMockTestReducer);

  const {
    loading: loadingRmv,
    success: successRmv,
    error: errorRmv,
  } = useSelector((s) => s.removeMockTestReducer);

  const dispatch = useDispatch();
  const MetaDes = { title: "Testfify - Mock Test" };

  const Get_MockTests = useCallback(() => {
    dispatch(listMockTest());
    dispatch(totalMockTest());
  }, [dispatch]);

  const handleChange = (e) => {
    setMockTestCreateData({
      ...MockTestCreateData,
      [e.target.name]: e.target.value,
    });
  };

  const handleFilterChange = () => {};

  const SetFiles = async (FileData, url, index) => {
    const { data } = await api.post(`/uploaddoc?url=${url}`, FileData);
    let details = [...UploadedDesc];
    details[index].url = data?.url;
    setUploadedDesc([...details]);
  };

  const extractElementsInSequence = (htmlString) => {
    // Create a temporary DOM element to parse the HTML string
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlString;

    const result = [];
    const TypeOfResult = [];

    // Iterate over child nodes of the parent container

    const childNode = tempDiv.childNodes[0];

    childNode.childNodes.forEach((node, i) => {
      if (node.nodeType === Node.ELEMENT_NODE) {
        if (node.tagName.toLowerCase() === "div") {
          // Check if the div contains an img
          const img = node.querySelector("img");
          if (img) {
            const Name = img.src?.split("/");
            result.push({
              image: "",
              preview: "",
              name: Name[Name?.length - 1],
              url: img.src,
            }); // Push image URL
            TypeOfResult.push("Image");
          }

          // Check if the div contains an audio
          const audio = node.querySelector("audio");
          if (audio && audio.src) {
            const Name = audio.src?.split("/");
            result.push({
              audio: "",
              preview: "",
              name: Name[Name?.length - 1],
              url: audio.src,
            }); // Push MP3 URL
            TypeOfResult.push("Mp3");
          }

          // If the div contains text and no other elements, extract it as HTML
          if (!img && !audio) {
            const innerHTML = node.innerHTML.trim();

            if (innerHTML) {
              result.push({ desc: innerHTML });
              TypeOfResult.push("InputText"); // Push raw HTML content
            }
          }
        }
      }
    });

    setMockDesc([...TypeOfResult]);
    setUploadedDesc([...result]);
    // return result;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const Validator = MockTest_Validator_Model(
      {
        ...MockTestCreateData,
      },
      MockDesc,
      UploadedDesc
    );

    if (Validator === "success") {
      setLoad(true);
      for (let i = 0; i < MockDesc?.length; i++) {
        if (MockDesc[i] === "Image") {
          const formData = new FormData();
          formData.append("UploadFile", UploadedDesc[i]?.image);
          SetFiles(formData, "/MockTest/Image", i);
        } else if (MockDesc[i] === "Mp3") {
          const formData = new FormData();
          formData.append("UploadFile", UploadedDesc[i]?.audio);
          SetFiles(formData, "/MockTest/Mp3", i);
        }
      }

      setTimeout(async () => {
        const MockArray = await MockDesc?.map((en, i) => {
          if (en === "InputText") {
            return UploadedDesc[i]?.desc;
          } else if (en === "Image") {
            return `<img src="${
              process.env.REACT_APP_DOC_URL + UploadedDesc[i]?.url
            }" alt={mock_image}/>`;
          } else if (en === "Mp3") {
            return `<audio controls  style={{ width: "100%",marginTop:"20px", outline: "none" }} src="${
              process.env.REACT_APP_DOC_URL + UploadedDesc[i]?.url
            }">
              </audio>`;
          } else {
            return "";
          }
        });

        const MockDescriptions =
          await `<div style={{maxWidth: "600px",margin: "20px auto",textAlign: "center",border: "1px solid #ccc",padding: "20px",borderRadius: "8px",boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",fontFamily: "Arial, sans-serif",}}>
          ${MockArray?.map(
            (en) => `<div >
            ${en}
            </div>`
          ).join("")}
        </div>`;

        await dispatch(
          createMockTest({
            ...MockTestCreateData,
            MockDescription: MockDescriptions,
          })
        );

        setLoad(false);
      }, 5000);
    } else {
      toast.error(Validator, toastOptions);
    }
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    const Validator = MockTest_Validator_Model(
      {
        ...MockTestCreateData,
      },
      MockDesc,
      UploadedDesc
    );

    if (Validator === "success") {
      setLoad(true);
      for (let i = 0; i < MockDesc?.length; i++) {
        if (MockDesc[i] === "Image" && !UploadedDesc[i]?.url) {
          const formData = new FormData();
          formData.append("UploadFile", UploadedDesc[i]?.image);
          SetFiles(formData, "/MockTest/Image", i);
        } else if (MockDesc[i] === "Mp3" && !UploadedDesc[i]?.url) {
          const formData = new FormData();
          formData.append("UploadFile", UploadedDesc[i]?.audio);
          SetFiles(formData, "/MockTest/Mp3", i);
        }
      }

      setTimeout(async () => {
        const MockArray = await MockDesc?.map((en, i) => {
          if (en === "InputText") {
            return UploadedDesc[i]?.desc;
          } else if (en === "Image") {
            const urlData = checkDomain(
              UploadedDesc[i]?.url,
              process.env.REACT_APP_DOC_URL
            )
              ? UploadedDesc[i]?.url
              : process.env.REACT_APP_DOC_URL + UploadedDesc[i]?.url;
            return `<img src="${urlData}" alt={mock_image}/>`;
          } else if (en == "Mp3") {
            const urlData1 = checkDomain(
              UploadedDesc[i]?.url,
              process.env.REACT_APP_DOC_URL
            )
              ? UploadedDesc[i]?.url
              : process.env.REACT_APP_DOC_URL + UploadedDesc[i]?.url;
            return `
          <audio controls  style={{ width: "100%",marginTop:"20px", outline: "none" }} src="${urlData1}">
            </audio>
            `;
          } else {
            return "";
          }
        });
        const MockDescriptions =
          await `<div style={{maxWidth: "600px",margin: "20px auto",textAlign: "center",border: "1px solid #ccc",padding: "20px",borderRadius: "8px",boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",fontFamily: "Arial, sans-serif",}}>
        ${MockArray?.map(
          (en, i) =>
            `<div>
            ${en}
          </div>`
        ).join("")}
        </div>`;
        await dispatch(
          updateMockTest(
            { ...MockTestCreateData, MockDescription: MockDescriptions },
            MockTestCreateData?._id
          )
        );
        setLoad(false);
      }, 4000);
    } else {
      toast.error(Validator, toastOptions);
    }
  };

  const handleRemove = (e, Id) => {
    e.preventDefault();
    dispatch(removeMockTest(Id));
  };

  const handlePreview = () => {
    const newTab = window.open("", "_blank");
    newTab.document.write(
      `<html><head><title>Preview</title></head><body><divstyle={{maxWidth: "600px",margin: "20px auto",textAlign: "center",border: "1px solid #ccc",padding: "20px",borderRadius: "8px",boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",fontFamily: "Arial, sans-serif",}}>`
    );

    MockDesc.map((ev, index) => {
      // Image Preview
      if (
        ev == "Image" &&
        (UploadedDesc[index]?.preview || UploadedDesc[index]?.url)
      ) {
        newTab.document.write(
          `<div>  <img src="${
            UploadedDesc[index]?.preview || UploadedDesc[index]?.url
          }"   style={{ width:"100%", maxWidth: "100%", borderRadius: "8px" ,display: "block"}}></div>`
        );
      }

      // Audio Preview
      if (
        ev == "Mp3" &&
        (UploadedDesc[index]?.preview || UploadedDesc[index]?.url)
      ) {
        newTab.document.write(
          `<audio controls  style={{ width: "100%",marginTop:"20px", outline: "none" }} src="${
            UploadedDesc[index]?.preview || UploadedDesc[index]?.url
          }"></audio>`
        );
      }
      // Mock Description Preview
      if (ev == "InputText" && UploadedDesc[index]?.desc) {
        newTab.document.write(`<div style={{
          marginBottom: "20px",
          fontSize: "18px",
          lineHeight: "1.6",
          color: "#333",
        }}> ${UploadedDesc[index]?.desc}</div>`);
      }
    });

    newTab.document.write("</div></body></html>");
  };

  useEffect(() => {
    Get_MockTests();
  }, [Get_MockTests]);

  useEffect(() => {
    if (successsv) {
      toast.success(successsv, toastOptions);
      setMockDesc([]);
      setUploadedDesc([]);
      handleCancel();
      Get_MockTests();
      setTimeout(() => {
        dispatch({ type: MOCKTEST_CREATE_RESET });
      }, 2000);
    }

    if (errorsv) {
      toast.success(errorsv, toastOptions);
    }
  }, [successsv, errorsv, dispatch, Get_MockTests]);

  useEffect(() => {
    if (successUpt) {
      toast.success(successUpt, toastOptions);
      handleCancel();
      Get_MockTests();
      setMockDesc([]);
      setUploadedDesc([]);
      setTimeout(() => {
        dispatch({ type: MOCKTEST_UPDATE_RESET });
      }, 2000);
    }

    if (errorUpt) {
      toast.success(errorUpt, toastOptions);
    }
  }, [successUpt, errorUpt, dispatch, Get_MockTests]);

  useEffect(() => {
    if (successRmv) {
      toast.success(successRmv, toastOptions);
      handleCancel();
      Get_MockTests();
      setTimeout(() => {
        dispatch({ type: MOCKTEST_REMOVE_RESET });
      }, 2000);
    }

    if (errorRmv) {
      toast.success(errorRmv, toastOptions);
    }
  }, [successRmv, errorRmv, dispatch, Get_MockTests]);

  useEffect(() => {
    if (DetMockTest && MaterialEditData) {
      setLoad(true);
      setMockTestCreateData({
        ...DetMockTest,
      });
      extractElementsInSequence(DetMockTest?.MockDescription);
      setLoad(false);
    } else {
      setMockTestCreateData(MockTestJson);
      setUploadedDesc([]);
      setMockDesc([]);
    }
  }, [DetMockTest, MaterialEditData]);

  useEffect(() => {
    dispatch(listMaterialTitle(MockTestCreateData?.CourseId));
  }, [MockTestCreateData?.CourseId, dispatch]);

  useEffect(() => {
    const CourseName = CouresesRec?.find(
      (ev) => ev?._id == MockTestCreateData?.CourseId
    )?.CourseName;

    if (CourseName?.toLowerCase() == "ielts") {
      setisSubCate("ielts");
    } else if (CourseName?.toLowerCase() == "pte") {
      setisSubCate("pte");
    } else {
      setisSubCate(false);
      setMockTestCreateData({ ...MockTestCreateData, SubCategoryId: "" });
    }
  }, [CouresesRec, MockTestCreateData?.CourseId]);

  useEffect(() => {
    dispatch(listCourses());
  }, [dispatch]);

  useEffect(() => {}, []);

  return (
    <>
      <Meta MetaDes={MetaDes} />
      <SidePanel page={"MockTest"} />
      <section className="home-section">
        <TopBar Heading="Mock Test" />
        {Load || loadingUpt || loadingRmv || loadingDet ? <SEDLoading /> : ""}
        <div className="home-content" style={{ paddingTop: "70px" }}>
          <div className="Contact-Middle">
            <div className="Contact_Top_Header">
              <div className="home-title float-left"></div>
              <div style={{ display: "flex", gap: "20px" }} className="">
                <div className="input-group search-contacts">
                  <input
                    type="Search"
                    className="form-control"
                    name="searchtxt"
                    style={{ display: "block" }}
                    // value={Search}
                    // onChange={(e) => {
                    //   if (e.target.value === "") {
                    //     setFilterData({ ...FilterData, searchtxt: "" });
                    //     setmaterialFilterData({
                    //       ...materialFilterData,
                    //       searchtxt: "",
                    //     });
                    //   }
                    //   setSearch(e.target.value);
                    // }}
                    // onKeyDown={(e) => {
                    //   if (
                    //     e.key === "Enter" &&
                    //     Search !== "" &&
                    //     Search !== undefined
                    //   ) {
                    //     setFilterData({ ...FilterData, searchtxt: Search });
                    //     setmaterialFilterData({
                    //       ...materialFilterData,
                    //       searchtxt: Search,
                    //     });
                    //   }
                    // }}
                    placeholder="search for Mock Tests"
                  />
                  <i className="fa fa-search" aria-hidden="true"></i>
                </div>
                <div className="right-click Create-btn">
                  <a
                    style={{ width: "180px" }}
                    className="btn create-contact"
                    onClick={(e) => {
                      setMockTestCreateData({
                        ...MockTestCreateData,
                      });
                      setCreateSec(true);
                    }}
                  >
                    Create Mocktest
                  </a>
                </div>
              </div>
            </div>

            {window.innerWidth <= 550 ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginRight: "17px",
                }}
                className=" Create-btn"
              >
                <a
                  style={{ width: "180px" }}
                  className="btn create-contact"
                  onClick={(e) => {
                    setMockTestCreateData({
                      ...MockTestCreateData,
                    });
                    setCreateSec(true);
                  }}
                >
                  Create Mock Test
                </a>
              </div>
            ) : (
              ""
            )}

            <hr />
            <div className="row">
              <div className="col-md-4 col-lg-3">
                <div
                  style={
                    mobile_View <= 480
                      ? { marginBottom: "20px" }
                      : { cursor: "auto" }
                  }
                  className="add-filter"
                >
                  <div
                    className={
                      MobileFilterActive
                        ? "filter-title  filter-title-mob filter-body-active"
                        : "filter-title  filter-title-mob"
                    }
                    style={{ cursor: "auto" }}
                  >
                    <h5>Filter Mock-Test</h5>
                    <button
                      onClick={() => setMobileFilterActive(false)}
                      className="mob-nav-open"
                    >
                      ☰
                    </button>
                  </div>

                  <div
                    className={
                      MobileFilterActive
                        ? "filter-body filter-body-mob  filter-body-active custom-scroll-right pt-0"
                        : "filter-body filter-body-mob custom-scroll-right pt-0"
                    }
                    style={{ cursor: "auto" }}
                  >
                    <div className="sticky pt-2">
                      <p style={{ marginBottom: "-20px" }}>
                        showing {total ? total : 0} Mock Tests
                      </p>
                      <br />
                      <button
                        style={{ color: "white" }}
                        className="btn btn-sm btn-deep-orange"
                        onClick={() => {
                          setmaterialFilterData({
                            ...materialFilterData,
                          });
                        }}
                      >
                        Clear All
                      </button>
                    </div>
                    <ul className="modify-filter-title">
                      <li className="chose">Course</li>
                      <li>
                        <select
                          name="AcademicPercentage_From"
                          value={materialFilterData.AcademicPercentage_From}
                          onChange={handleFilterChange}
                          className="form-control"
                        >
                          <option value="">--Select--</option>
                          <CourseDropDown />
                        </select>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-md-8 col-lg-9">
                <div
                  style={{ display: "flex", justifyContent: "center" }}
                  className="Contact-table-wrapper"
                >
                  <table>
                    <thead>
                      <tr className="sticky">
                        <th>Edit</th>
                        <th>Delete</th>
                        <th>Mock No</th>
                        <th>Course</th>
                        <th>Sub Category</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <tr>
                          <td colSpan={10}>Laoding... </td>
                        </tr>
                      ) : error ? (
                        <tr>
                          <td colSpan={10}>{error}</td>
                        </tr>
                      ) : (
                        mockTestRecs &&
                        mockTestRecs?.map((ev) => (
                          <tr key={ev._id}>
                            <td
                              onClick={() => {
                                // setMockTestCreateData({
                                //   CourseId: ev?.MaterialTitleId?.CourseId?._id,
                                //   MaterialTitleId: ev?.MaterialTitleId?._id,
                                // });
                                dispatch(mockDetails(ev._id));
                                setTimeout(() => {
                                  setMaterialEditData(true);
                                  setCreateSec(true);
                                }, 1000);
                              }}
                              style={{ cursor: "pointer", color: "blue" }}
                            >
                              <FaRegEdit />
                            </td>

                            <td
                              onClick={(e) => {
                                setDeleteAlert({
                                  msg: `Do you really want to delete this mock (${ev?.MockNo}) ?`,
                                  Delete: () => handleRemove(e, ev._id),
                                });
                                setOpenNext(true);
                              }}
                              style={{ cursor: "pointer", color: "red" }}
                            >
                              <MdDelete />
                            </td>
                            <td>
                              <Link
                                target="_blank"
                                style={{
                                  textDecoration: "none",
                                  color: "#0c3eff",
                                }}
                              >
                                {ev?.MockNo}
                              </Link>
                            </td>

                            <td>{ev?.CourseId?.CourseName}</td>
                            <td>{ev?.SubCategoryId}</td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
                <div>
                  <Pagination
                    setFilterQuery={setmaterialFilterData}
                    FilterQuery={materialFilterData}
                    Total={total || 0}
                  />
                </div>

                <div
                  id="CreatContact"
                  className={createSec ? "overlay-note w-100" : "overlay-note"}
                >
                  <div className="overlay-add-deals contact-right-side">
                    <div className="note-title">
                      <h4 className="text-left text-white pl-3">
                        {MaterialEditData
                          ? "Update Mock Test"
                          : "Create Mock Test"}
                      </h4>
                      <a
                        className="closebtn text-right text-white"
                        onClick={(e) => handleCancel()}
                      >
                        ×
                      </a>
                    </div>

                    <div
                      style={{ height: "95vh" }}
                      className="contact-body modify-create-contact"
                    >
                      <div className="form-group first-input">
                        <label>
                          Course :<b>*</b>
                        </label>

                        <select
                          className="form-control"
                          name="CourseId"
                          value={MockTestCreateData?.CourseId}
                          onChange={(e) => handleChange(e)}
                        >
                          <option value="">Select Course</option>
                          <CourseDropDown />
                        </select>
                      </div>

                      {isSubCate == "ielts" ? (
                        <div className="form-group first-input">
                          <label>
                            SubCategory :<b>*</b>
                          </label>

                          <select
                            className="form-control"
                            name="SubCategoryId"
                            value={MockTestCreateData?.SubCategoryId}
                            onChange={(e) => handleChange(e)}
                          >
                            <option value="">--Select--</option>
                            <option value="Academic">Academic</option>
                            <option value="General">General</option>
                          </select>
                        </div>
                      ) : isSubCate == "pte" ? (
                        <div className="form-group first-input">
                          <label>
                            SubCategory :<b>*</b>
                          </label>
                          <select
                            className="form-control"
                            name="SubCategoryId"
                            value={MockTestCreateData?.SubCategoryId}
                            onChange={(e) => handleChange(e)}
                          >
                            <option value="">--Select--</option>
                            <option value="PTE Academic">PTE Academic</option>
                            <option value="PTE CORE">PTE CORE</option>
                          </select>
                        </div>
                      ) : (
                        ""
                      )}

                      <div className="form-group">
                        <label>
                          Mock No:<b>*</b>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Mock No"
                          name="MockNo"
                          value={MockTestCreateData.MockNo}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>

                      <div className="form-group first-input">
                        <label>
                          Select Mock Description:<b>*</b>
                        </label>
                        <div
                          style={{
                            display: "flex",
                            gap: "5px",
                            marginTop: "6px",
                          }}
                        >
                          <button
                            onClick={() => {
                              setMockDesc([...MockDesc, "InputText"]);
                              setUploadedDesc([...UploadedDesc, { desc: "" }]);
                            }}
                            className="cst_btn"
                          >
                            Input Box
                          </button>
                          <button
                            onClick={() => {
                              setMockDesc([...MockDesc, "Image"]);
                              setUploadedDesc([...UploadedDesc, { ...Img }]);
                            }}
                            className="cst_btn"
                          >
                            Image
                          </button>
                          <button
                            onClick={() => {
                              setMockDesc([...MockDesc, "Mp3"]);
                              setUploadedDesc([...UploadedDesc, { ...audio }]);
                            }}
                            className="cst_btn"
                          >
                            Mp3
                          </button>
                        </div>
                      </div>

                      {MockDesc?.map((ek, i) => (
                        <div key={i + 674}>
                          {ek == "InputText" ? (
                            <div className="form-group ">
                              <label>
                                Description :<b>*</b>
                              </label>
                              <ReactQuill
                                placeholder={"Enter Mock description"}
                                name="MockDescription"
                                value={UploadedDesc[i]?.desc}
                                onChange={(e) => {
                                  let uploadDesc = [...UploadedDesc];
                                  uploadDesc[i].desc = e;
                                  setUploadedDesc([...uploadDesc]);
                                }}
                                required
                              />
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <span
                                  onClick={() => handleDescRemove(i)}
                                  style={{ color: "red" }}
                                >
                                  <AiFillDelete />
                                </span>
                              </div>
                            </div>
                          ) : ek == "Image" ? (
                            <div className="form-group">
                              <label>
                                Upload image :<b>*</b>
                              </label>
                              <div className="Input_With_Del">
                                <input
                                  type="file"
                                  accept="image/*"
                                  filename={UploadedDesc[i]?.name}
                                  name={i + 7676}
                                  className="form-control"
                                  onChange={(e) => {
                                    let uploadDesc1 = [...UploadedDesc];
                                    uploadDesc1[i].image = e.target.files[0];
                                    uploadDesc1[i].name =
                                      e.target.files[0].name;
                                    uploadDesc1[i].url = "";
                                    const reader = new FileReader();

                                    reader.onload = (e) =>
                                      (uploadDesc1[i].preview =
                                        e.target.result);
                                    reader.readAsDataURL(e.target.files[0]);

                                    setUploadedDesc([...uploadDesc1]);
                                  }}
                                />
                                <span
                                  onClick={() => handleDescRemove(i)}
                                  style={{ color: "red" }}
                                >
                                  <AiFillDelete />
                                </span>
                              </div>
                            </div>
                          ) : ek == "Mp3" ? (
                            <div className="form-group">
                              <label>
                                Upload Mp3 :<b>*</b>
                              </label>
                              <div className="Input_With_Del">
                                <input
                                  type="file"
                                  accept="audio/mp3"
                                  className="form-control"
                                  filename={UploadedDesc[i]?.name}
                                  name={i + 6174}
                                  onChange={(e) => {
                                    let uploadDesc = [...UploadedDesc];
                                    uploadDesc[i].audio = e.target.files[0];
                                    uploadDesc[i].name = e.target.files[0].name;
                                    uploadDesc[i].url = "";
                                    const reader = new FileReader();
                                    reader.onload = (e) =>
                                      (uploadDesc[i].preview = e.target.result);
                                    reader.readAsDataURL(e.target.files[0]);
                                    setUploadedDesc([...uploadDesc]);
                                  }}
                                />
                                <span
                                  onClick={() => handleDescRemove(i)}
                                  style={{ color: "red" }}
                                >
                                  <AiFillDelete />
                                </span>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      ))}

                      <div
                        // className="contact-footer w-100"
                        style={{
                          // padding: "50px 25px",
                          backgroundColor: "transparent",
                          marginTop: "26px",
                        }}
                        ref={ErrorSuccessRef}
                      ></div>

                      <div className="contact-footer w-100">
                        <div>
                          {MaterialEditData ? (
                            <button
                              type="button"
                              className="btn"
                              onClick={handleUpdate}
                              disabled={loadingUpt}
                            >
                              {loadingUpt ? "Loading..." : "Update Mock Test"}
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="btn"
                              onClick={handleSubmit}
                              disabled={loadingsv}
                            >
                              {loadingsv ? "Loading..." : "Create Mock Test"}
                            </button>
                          )}

                          <button
                            type="button"
                            className="btn btn-deep-orange waves-effect waves-light"
                            onClick={() => handlePreview()}
                          >
                            Preview
                          </button>

                          <button
                            type="button"
                            className="btn btn-deep-orange waves-effect waves-light"
                            onClick={() => handleCancel()}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <DeletePopup
            OpenNext={OpenNext}
            setOpenNext={setOpenNext}
            DeleteData={DeleteAlert}
          />
        </div>
        <ToastContainer />
      </section>
    </>
  );
}
