import {
  USER_ROLE_DELETE_FAIL,
  USER_ROLE_DELETE_REQUEST,
  USER_ROLE_DELETE_SUCCESS,
  USER_ROLE_INSERT_FAIL,
  USER_ROLE_INSERT_REQUEST,
  USER_ROLE_INSERT_SUCCESS,
  USER_ROLE_LIST_FAIL,
  USER_ROLE_LIST_REQUEST,
  USER_ROLE_LIST_SUCCESS,
  USER_ROLE_UPDATE_FAIL,
  USER_ROLE_UPDATE_REQUEST,
  USER_ROLE_UPDATE_SUCCESS,
} from "../Constants/userRoleConstants";
import api from "../core/api";

export const listUserRole = () => async (dispatch) => {
  try {
    dispatch({ type: USER_ROLE_LIST_REQUEST });
    const { data } = await api.get(`/userRole`);
    dispatch({
      type: USER_ROLE_LIST_SUCCESS,
      payload: data.Result,
    });
  } catch (error) {
    dispatch({
      type: USER_ROLE_LIST_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const saveUserRoleDetails =
  (InputJson) => async (dispatch) => {
    try {
      dispatch({ type: USER_ROLE_INSERT_REQUEST });
      const { data } = await api.post(`/userRole`, InputJson);
      dispatch({
        type: USER_ROLE_INSERT_SUCCESS,
        payload: data.Result,
      });
    } catch (error) {
      dispatch({
        type: USER_ROLE_INSERT_FAIL,
        payload:
          error.response && error.response.data.Result
            ? error.response.data.Result
            : error.message,
      });
    }
  };

export const updateUserRoleDetails =
  (id, InputJson) => async (dispatch) => {
    try {
      dispatch({ type: USER_ROLE_UPDATE_REQUEST });
      const { data } = await api.put(`/userRole/${id}`, InputJson);
      dispatch({
        type: USER_ROLE_UPDATE_SUCCESS,
        payload: data.Result,
      });
    } catch (error) {
      dispatch({
        type: USER_ROLE_UPDATE_FAIL,
        payload:
          error.response && error.response.data.Result
            ? error.response.data.Result
            : error.message,
      });
    }
  };

export const deleteUserRoleDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: USER_ROLE_DELETE_REQUEST });
    const { data } = await api.delete(`/userRole/${id}`);
    dispatch({
      type: USER_ROLE_DELETE_SUCCESS,
      payload: data.Result,
    });
  } catch (error) {
    dispatch({
      type: USER_ROLE_DELETE_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};
