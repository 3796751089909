import {
  SUBJECT_DELETE_FAIL,
  SUBJECT_DELETE_REQUEST,
  SUBJECT_DELETE_RESET,
  SUBJECT_DELETE_SUCCESS,
  SUBJECT_INSERT_FAIL,
  SUBJECT_INSERT_REQUEST,
  SUBJECT_INSERT_RESET,
  SUBJECT_INSERT_SUCCESS,
  SUBJECT_LIST_FAIL,
  SUBJECT_LIST_REQUEST,
  SUBJECT_LIST_SUCCESS,
  SUBJECT_UPDATE_FAIL,
  SUBJECT_UPDATE_REQUEST,
  SUBJECT_UPDATE_RESET,
  SUBJECT_UPDATE_SUCCESS,
} from "../Constants/subjectConstants";

export const subjectListReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case SUBJECT_LIST_REQUEST:
      return { loading: true };
    case SUBJECT_LIST_SUCCESS:
      return { loading: false, subjectRecs: payload };
    case SUBJECT_LIST_FAIL:
      return { loading: false, error: payload };
    default:
      return state;
  }
};

export const subjectSaveReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case SUBJECT_INSERT_REQUEST:
      return { loading: true };
    case SUBJECT_INSERT_SUCCESS:
      return { loading: false, success: payload };
    case SUBJECT_INSERT_FAIL:
      return { loading: false, error: payload };
    case SUBJECT_INSERT_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const subjectUpdateReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case SUBJECT_UPDATE_REQUEST:
      return { loading: true };
    case SUBJECT_UPDATE_SUCCESS:
      return { loading: false, success: payload };
    case SUBJECT_UPDATE_FAIL:
      return { loading: false, error: payload };
    case SUBJECT_UPDATE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const subjectDeleteReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case SUBJECT_DELETE_REQUEST:
      return { loading: true };
    case SUBJECT_DELETE_SUCCESS:
      return { loading: false, success: payload };
    case SUBJECT_DELETE_FAIL:
      return { loading: false, error: payload };
    case SUBJECT_DELETE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};
