import {
  ENQUIRY_FOR_DELETE_FAIL,
  ENQUIRY_FOR_DELETE_REQUEST,
  ENQUIRY_FOR_DELETE_RESET,
  ENQUIRY_FOR_DELETE_SUCCESS,
  ENQUIRY_FOR_INSERT_FAIL,
  ENQUIRY_FOR_INSERT_REQUEST,
  ENQUIRY_FOR_INSERT_RESET,
  ENQUIRY_FOR_INSERT_SUCCESS,
  ENQUIRY_FOR_LIST_FAIL,
  ENQUIRY_FOR_LIST_REQUEST,
  ENQUIRY_FOR_LIST_SUCCESS,
  ENQUIRY_FOR_UPDATE_FAIL,
  ENQUIRY_FOR_UPDATE_REQUEST,
  ENQUIRY_FOR_UPDATE_RESET,
  ENQUIRY_FOR_UPDATE_SUCCESS,
} from "../Constants/enquiryConstants";

export const enquiryForReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case ENQUIRY_FOR_LIST_REQUEST:
      return { loading: true };
    case ENQUIRY_FOR_LIST_SUCCESS:
      return { loading: false, enquiryForRecs: payload };
    case ENQUIRY_FOR_LIST_FAIL:
      return { loading: false, error: payload };
    default:
      return state;
  }
};

export const enquiryForSaveReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case ENQUIRY_FOR_INSERT_REQUEST:
      return { loading: true };
    case ENQUIRY_FOR_INSERT_SUCCESS:
      return { loading: false, success: payload };
    case ENQUIRY_FOR_INSERT_FAIL:
      return { loading: false, error: payload };
    case ENQUIRY_FOR_INSERT_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const enquiryForUpdateReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case ENQUIRY_FOR_UPDATE_REQUEST:
      return { loading: true };
    case ENQUIRY_FOR_UPDATE_SUCCESS:
      return { loading: false, success: payload };
    case ENQUIRY_FOR_UPDATE_FAIL:
      return { loading: false, error: payload };
    case ENQUIRY_FOR_UPDATE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const enquiryForDeleteReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case ENQUIRY_FOR_DELETE_REQUEST:
      return { loading: true };
    case ENQUIRY_FOR_DELETE_SUCCESS:
      return { loading: false, success: payload };
    case ENQUIRY_FOR_DELETE_FAIL:
      return { loading: false, error: payload };
    case ENQUIRY_FOR_DELETE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};
