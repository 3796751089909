import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listDepartment } from "../../Actions/departmentActions";

const DepartmentDropdown = React.memo(() => {
  const dispatch = useDispatch();
  const { departmentRec } = useSelector((s) => s.departmentList);
  useEffect(() => {
    dispatch(listDepartment());
  }, []);
  return departmentRec ? (
    departmentRec.map((recs) => (
      <option value={recs._id} key={recs._id}>
        {recs.DepartmentName}
      </option>
    ))
  ) : (
    <></>
  );
});

export default DepartmentDropdown;
