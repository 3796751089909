export const CONFIRMATION_OF_ACCEPTANCE_LIST_REQUEST = 'CONFIRMATION_OF_ACCEPTANCE_LIST_REQUEST'
export const CONFIRMATION_OF_ACCEPTANCE_LIST_SUCCESS = 'CONFIRMATION_OF_ACCEPTANCE_LIST_SUCCESS'
export const CONFIRMATION_OF_ACCEPTANCE_LIST_FAIL = 'CONFIRMATION_OF_ACCEPTANCE_LIST_FAIL'

export const CONFIRMATION_OF_ACCEPTANCE_GET_REQUEST = 'CONFIRMATION_OF_ACCEPTANCE_GET_REQUEST'
export const CONFIRMATION_OF_ACCEPTANCE_GET_SUCCESS = 'CONFIRMATION_OF_ACCEPTANCE_GET_SUCCESS'
export const CONFIRMATION_OF_ACCEPTANCE_GET_FAIL = 'CONFIRMATION_OF_ACCEPTANCE_GET_FAIL'

export const CONFIRMATION_OF_ACCEPTANCE_INSERT_REQUEST = 'CONFIRMATION_OF_ACCEPTANCE_INSERT_REQUEST'
export const CONFIRMATION_OF_ACCEPTANCE_INSERT_SUCCESS = 'CONFIRMATION_OF_ACCEPTANCE_INSERT_SUCCESS'
export const CONFIRMATION_OF_ACCEPTANCE_INSERT_FAIL = 'CONFIRMATION_OF_ACCEPTANCE_INSERT_FAIL'
export const CONFIRMATION_OF_ACCEPTANCE_INSERT_RESET = 'CONFIRMATION_OF_ACCEPTANCE_INSERT_RESET'

export const CONFIRMATION_OF_ACCEPTANCE_UPDATE_REQUEST = 'CONFIRMATION_OF_ACCEPTANCE_UPDATE_REQUEST'
export const CONFIRMATION_OF_ACCEPTANCE_UPDATE_SUCCESS = 'CONFIRMATION_OF_ACCEPTANCE_UPDATE_SUCCESS'
export const CONFIRMATION_OF_ACCEPTANCE_UPDATE_FAIL = 'CONFIRMATION_OF_ACCEPTANCE_UPDATE_FAIL'
export const CONFIRMATION_OF_ACCEPTANCE_UPDATE_RESET = 'CONFIRMATION_OF_ACCEPTANCE_UPDATE_RESET'

export const CONFIRMATION_OF_ACCEPTANCE_DELETE_REQUEST = 'CONFIRMATION_OF_ACCEPTANCE_DELETE_REQUEST'
export const CONFIRMATION_OF_ACCEPTANCE_DELETE_SUCCESS = 'CONFIRMATION_OF_ACCEPTANCE_DELETE_SUCCESS'
export const CONFIRMATION_OF_ACCEPTANCE_DELETE_FAIL = 'CONFIRMATION_OF_ACCEPTANCE_DELETE_FAIL'
export const CONFIRMATION_OF_ACCEPTANCE_DELETE_RESET = 'CONFIRMATION_OF_ACCEPTANCE_DELETE_RESET'
