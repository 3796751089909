import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { remindersListDashboard } from "../../Actions/taskActions";
import moment from "moment/moment";

export default function Tasks() {
  const dispatch = useDispatch();
  // const navigate = useNavigate();

  const { offset } = useSelector((s) => s.getOffset);
  const { reminderList } = useSelector((s) => s.dashboardReminderList);
  const [userdet, setUserDet] = useState([]);
  useEffect(() => {
    const userRec = JSON.parse(localStorage.getItem("_Tsset"));
    setUserDet(userRec);
    if (reminderList == undefined) {
      dispatch(remindersListDashboard(userRec?._id));
    }
  }, [dispatch, reminderList]);

  return (
    <div className="table-responsive">
      <table className="table table-condensed">
        <thead
          style={{ position: "sticky", top: "0" }}
          className="t-head-th-bg"
        >
          <tr>
            <th className="t-head-fixed" scope="col">
              Status
            </th>
            <th className="t-head-fixed" scope="col">
              Title
            </th>
            <th className="t-head-fixed" scope="col">
              Priority
            </th>
            <th className="t-head-fixed" scope="col">
              Due Date (GMT {offset})
            </th>
            <th className="t-head-fixed" scope="col">
              Associated with
            </th>
          </tr>
        </thead>
        <tbody className="table-bordered">
          {reminderList ? (
            reminderList?.map((rrec) => (
              <tr key={rrec._id} className="inline-task-rows">
                <td>
                  <i className={"fas fa-check-circle mark-icon"}></i>
                </td>
                <td>
                  <span className="underLine-title">{rrec.Task_Title}</span>
                </td>
                <td>{rrec.Priority === 1 ? "High" : "Low"}</td>
                <td>{moment(rrec.Due_Date).format("DD MMM YYYY hh:mm a")}</td>
                <td>
                  {!rrec.Contact_Id ? (
                    !rrec.Deal_Id ? (
                      "---"
                    ) : (
                      <Link
                        to={`/deal/edit/${rrec.Deal_Id?._id}`}
                        target="_blank"
                      >
                        {rrec.Deal_Id?.DealName} - Deal
                      </Link>
                    )
                  ) : (
                    <Link
                      to={`/contact/edit/${rrec.Contact_Id?._id}`}
                      target="_blank"
                    >
                      {rrec.Contact_Id?.First_Name} {rrec.Contact_Id?.Last_Name}
                      -{rrec.Contact_Id?.full_name}
                    </Link>
                  )}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={5}>No Record Exist!</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}
