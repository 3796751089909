import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listTeam } from "../../Actions/teamActions";

// export default function TeamDropdown() {
//   const dispatch = useDispatch();
//   const { teamRecs } = useSelector((s) => s.teamList);
//   useMemo(() => {
//     dispatch(listTeam());
//   }, [dispatch]);
//   return teamRecs ? (
//     teamRecs.map((recs) => (
//       <option value={recs._id} key={recs._id}>
//         {recs.Team_Name}
//       </option>
//     ))
//   ) : (
//     <></>
//   );
// }

const TeamDropdown = React.memo(() => {
  const dispatch = useDispatch();
  const { teamRecs } = useSelector((s) => s.teamList);
  useMemo(() => {
    dispatch(listTeam());
  }, [dispatch]);
  return teamRecs ? (
    teamRecs.map((recs) => (
      <option value={recs._id} key={recs._id}>
        {recs.Team_Name}
      </option>
    ))
  ) : (
    <></>
  );
});

export default TeamDropdown;
