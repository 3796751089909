import {
  INTERESTED_FIELD_OF_STUDY_DELETE_FAIL,
  INTERESTED_FIELD_OF_STUDY_DELETE_REQUEST,
  INTERESTED_FIELD_OF_STUDY_DELETE_RESET,
  INTERESTED_FIELD_OF_STUDY_DELETE_SUCCESS,
  INTERESTED_FIELD_OF_STUDY_INSERT_FAIL,
  INTERESTED_FIELD_OF_STUDY_INSERT_REQUEST,
  INTERESTED_FIELD_OF_STUDY_INSERT_RESET,
  INTERESTED_FIELD_OF_STUDY_INSERT_SUCCESS,
  INTERESTED_FIELD_OF_STUDY_LIST_FAIL,
  INTERESTED_FIELD_OF_STUDY_LIST_REQUEST,
  INTERESTED_FIELD_OF_STUDY_LIST_SUCCESS,
  INTERESTED_FIELD_OF_STUDY_UPDATE_FAIL,
  INTERESTED_FIELD_OF_STUDY_UPDATE_REQUEST,
  INTERESTED_FIELD_OF_STUDY_UPDATE_RESET,
  INTERESTED_FIELD_OF_STUDY_UPDATE_SUCCESS,
} from "../Constants/interestedFieldOfStudyConstants";

export const interestedFieldOfStudyListReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case INTERESTED_FIELD_OF_STUDY_LIST_REQUEST:
      return { loading: true };
    case INTERESTED_FIELD_OF_STUDY_LIST_SUCCESS:
      return { loading: false, interestedFieldOfStudyRecs: payload };
    case INTERESTED_FIELD_OF_STUDY_LIST_FAIL:
      return { loading: false, error: payload };
    default:
      return state;
  }
};

export const interestedFieldOfStudySaveReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case INTERESTED_FIELD_OF_STUDY_INSERT_REQUEST:
      return { loading: true };
    case INTERESTED_FIELD_OF_STUDY_INSERT_SUCCESS:
      return { loading: false, success: payload };
    case INTERESTED_FIELD_OF_STUDY_INSERT_FAIL:
      return { loading: false, error: payload };
    case INTERESTED_FIELD_OF_STUDY_INSERT_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const interestedFieldOfStudyUpdateReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case INTERESTED_FIELD_OF_STUDY_UPDATE_REQUEST:
      return { loading: true };
    case INTERESTED_FIELD_OF_STUDY_UPDATE_SUCCESS:
      return { loading: false, success: payload };
    case INTERESTED_FIELD_OF_STUDY_UPDATE_FAIL:
      return { loading: false, error: payload };
    case INTERESTED_FIELD_OF_STUDY_UPDATE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const interestedFieldOfStudyDeleteReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case INTERESTED_FIELD_OF_STUDY_DELETE_REQUEST:
      return { loading: true };
    case INTERESTED_FIELD_OF_STUDY_DELETE_SUCCESS:
      return { loading: false, success: payload };
    case INTERESTED_FIELD_OF_STUDY_DELETE_FAIL:
      return { loading: false, error: payload };
    case INTERESTED_FIELD_OF_STUDY_DELETE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};
