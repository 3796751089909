import {
  CAMPAIGN_UPDATE_STATUS_FAIL,
  CAMPAIGN_UPDATE_STATUS_REQUEST,
  CAMPAIGN_UPDATE_STATUS_RESET,
  CAMPAIGN_UPDATE_STATUS_SUCCESS,
  CAMPAIGN_GET_FAIL,
  CAMPAIGN_GET_REQUEST,
  CAMPAIGN_GET_SUCCESS,
  CAMPAIGN_INSERT_FAIL,
  CAMPAIGN_INSERT_REQUEST,
  CAMPAIGN_INSERT_RESET,
  CAMPAIGN_INSERT_SUCCESS,
  CAMPAIGN_LIST_FAIL,
  CAMPAIGN_LIST_REQUEST,
  CAMPAIGN_LIST_SUCCESS,
  CAMPAIGN_UPDATE_FAIL,
  CAMPAIGN_UPDATE_REQUEST,
  CAMPAIGN_UPDATE_RESET,
  CAMPAIGN_UPDATE_SUCCESS,
} from "../Constants/campaignConstants";

export const campaignListReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case CAMPAIGN_LIST_REQUEST:
      return { loading: true };
    case CAMPAIGN_LIST_SUCCESS:
      return { loading: false, campaigns: payload };
    case CAMPAIGN_LIST_FAIL:
      return { loading: false, error: payload };
    default:
      return state;
  }
};

export const campaignDetailReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case CAMPAIGN_GET_REQUEST:
      return { loading: true };
    case CAMPAIGN_GET_SUCCESS:
      return { loading: false, campaignrec: payload };
    case CAMPAIGN_GET_FAIL:
      return { loading: false, error: payload };
    default:
      return state;
  }
};

export const campaignSaveReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case CAMPAIGN_INSERT_REQUEST:
      return { loading: true };
    case CAMPAIGN_INSERT_SUCCESS:
      return { loading: false, success: payload };
    case CAMPAIGN_INSERT_FAIL:
      return { loading: false, error: payload };
    case CAMPAIGN_INSERT_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const campaignUpdateReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case CAMPAIGN_UPDATE_REQUEST:
      return { loading: true };
    case CAMPAIGN_UPDATE_SUCCESS:
      return { loading: false, success: payload };
    case CAMPAIGN_UPDATE_FAIL:
      return { loading: false, error: payload };
    case CAMPAIGN_UPDATE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const campaignUpdateStatusReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case CAMPAIGN_UPDATE_STATUS_REQUEST:
      return { loading: true };
    case CAMPAIGN_UPDATE_STATUS_SUCCESS:
      return { loading: false, success: payload };
    case CAMPAIGN_UPDATE_STATUS_FAIL:
      return { loading: false, error: payload };
    case CAMPAIGN_UPDATE_STATUS_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};
