export const WEBSITE_PAGE_LIST_REQUEST = 'WEBSITE_PAGE_LIST_REQUEST'
export const WEBSITE_PAGE_LIST_SUCCESS = 'WEBSITE_PAGE_LIST_SUCCESS'
export const WEBSITE_PAGE_LIST_FAIL = 'WEBSITE_PAGE_LIST_FAIL'

export const WEBSITE_PAGE_INSERT_REQUEST = 'WEBSITE_PAGE_INSERT_REQUEST'
export const WEBSITE_PAGE_INSERT_SUCCESS = 'WEBSITE_PAGE_INSERT_SUCCESS'
export const WEBSITE_PAGE_INSERT_FAIL = 'WEBSITE_PAGE_INSERT_FAIL'
export const WEBSITE_PAGE_INSERT_RESET = 'WEBSITE_PAGE_INSERT_RESET'

export const WEBSITE_PAGE_UPDATE_REQUEST = 'WEBSITE_PAGE_UPDATE_REQUEST'
export const WEBSITE_PAGE_UPDATE_SUCCESS = 'WEBSITE_PAGE_UPDATE_SUCCESS'
export const WEBSITE_PAGE_UPDATE_FAIL = 'WEBSITE_PAGE_UPDATE_FAIL'
export const WEBSITE_PAGE_UPDATE_RESET = 'WEBSITE_PAGE_UPDATE_RESET'

export const WEBSITE_PAGE_DELETE_REQUEST = 'WEBSITE_PAGE_DELETE_REQUEST'
export const WEBSITE_PAGE_DELETE_SUCCESS = 'WEBSITE_PAGE_DELETE_SUCCESS'
export const WEBSITE_PAGE_DELETE_FAIL = 'WEBSITE_PAGE_DELETE_FAIL'
export const WEBSITE_PAGE_DELETE_RESET = 'WEBSITE_PAGE_DELETE_RESET'
