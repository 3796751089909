import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listQualifies } from "../../Actions/qualifiesActions";
import { MultiSelect } from "react-multi-select-component";

export default function QualifiesMultiSelectDropdown({ values, handleChange }) {
  const dispatch = useDispatch();

  const { loading, qualifiesRec, error } = useSelector((s) => s.qualifiesList);

  const getRecs = useCallback(() => {
    dispatch(listQualifies());
  }, [dispatch]);

  useMemo(() => {
    if (!qualifiesRec) getRecs();
  }, [getRecs, qualifiesRec]);

  return (
    <>
      <MultiSelect
        options={
          qualifiesRec
            ? qualifiesRec?.map((el) => {
                return {
                  label: el.QualifiesData,
                  value: el._id,
                };
              })
            : []
        }
        className="Multi_Selector"
        value={values}
        onChange={(e) => {
          // setSelected2(e);
          handleChange(e);
        }}
        labelledBy="Select"
      />
    </>
  );
}
