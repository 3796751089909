import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SettingLeftSection from "../Components/SettingLeftSection";
import DeletePopup from "../Components/DeletePoup";
import SidePanel from "../../Components/SidePanel";
import TopBar from "../../Components/TopBar";
import SEDLoading from "../../Components/SEDLoading";

import {
  deleteUserRoleDetails,
  listUserRole,
  saveUserRoleDetails,
  updateUserRoleDetails,
} from "../../Actions/userRoleActions";

import {
  USER_ROLE_DELETE_RESET,
  USER_ROLE_UPDATE_RESET,
} from "../../Constants/userRoleConstants";

import { userRoleJson } from "../../Models/UserRoleModels";

export default function UserRole() {
  const dispatch = useDispatch();
  const [UserRole, setUserRole] = useState(userRoleJson);
  const { loading, error, userRoleRecs } = useSelector((s) => s.userRoleList);
  const [btnText, setbtnText] = useState("Save");
  const [cancelHide, setcancelHide] = useState(false);
  const [OpenNext, setOpenNext] = useState(false);
  const [DeleteAlert, setDeleteAlert] = useState({ msg: "", Delete: () => "" });

  const {
    loading: loadingsv,
    error: errorsv,
    success: successsv,
  } = useSelector((s) => s.userRoleSave);

  const {
    loading: loadingud,
    error: errorud,
    success: successud,
  } = useSelector((s) => s.userRoleUpdate);

  const {
    loading: loadingdet,
    error: errordet,
    success: successdet,
  } = useSelector((s) => s.userRoleDelete);

  const handleChange = (e) => {
    e.preventDefault();
    setUserRole({ ...UserRole, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (btnText == "Save") {
      dispatch(saveUserRoleDetails(UserRole));
    } else if (btnText == "Update") {
      dispatch(updateUserRoleDetails(UserRole._id, UserRole));
    } else alert("Error on handleSubmit");
  };

  const getDepartments = useCallback(() => {
    dispatch(listUserRole());
  }, [dispatch]);

  const handleDelete = (e, _id) => {
    e.preventDefault();
    dispatch(deleteUserRoleDetails(_id));
  };

  const handleEdit = (e, _id, UserRole_Name) => {
    e.preventDefault();
    setbtnText("Update");
    setcancelHide(true);
    setUserRole({ ...UserRole, UserRole_Name, _id });
  };

  const handleCancel = () => {
    setUserRole({ ...userRoleJson });
    setbtnText("Save");
    setcancelHide(false);
  };

  useEffect(() => {
    getDepartments();
  }, [getDepartments]);

  useEffect(() => {
    if (successdet)
      setTimeout(() => {
        dispatch({ type: USER_ROLE_DELETE_RESET });
      }, 5000);
    if (successud)
      setTimeout(() => {
        dispatch({ type: USER_ROLE_UPDATE_RESET });
      }, 5000);
    if (successsv) setTimeout(() => {}, 5000);
    if (successdet || successsv || successud) {
      getDepartments();
      setUserRole({ ...userRoleJson });
      handleCancel();
    }
  }, [successdet, successsv, getDepartments, successud, dispatch]);

  return (
    <>
      <SidePanel page={"User Role"} />
      <section className="home-section">
        {loading ? <SEDLoading /> : ""}
        <TopBar Heading={"User Role"} />
        <div
          className="home-content"
          style={{ paddingTop: window.innerWidth <= 480 ? "20px" : "100px" }}
        >
          <div className="content-body">
            {/* <!--Body-content--> */}
            <div className="container-fluid">
              <div className="heading-title">
                <div className="row">
                  <div className="col-md-12">
                    <div className="home-table-search float-right d-flex">
                      {/* <!--    <button>right side</button> --> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="inner-body settings-details">
                <div className="row">
                  <div className="col-md-3">
                    <SettingLeftSection />
                  </div>

                  <div className="col-md-9">
                    <div className="body-content-with-md-9">
                      <div>
                        <div className="container">
                          <div className="row">
                            <div className="col-md-12 text-left body-content-with-md-9-title">
                              <h4>User Role </h4>
                            </div>
                          </div>
                          <div className="row mt-3 px-5">
                            <div className="col-md-3 pt-1">
                              <label className="form-group">
                                Userrole Name:
                              </label>
                            </div>
                            <div className="col-md-9">
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                name="UserRole_Name"
                                placeholder="Enter User Role Name"
                                value={UserRole.UserRole_Name}
                                onChange={(e) => handleChange(e)}
                              />
                            </div>
                          </div>

                          <div className="row">
                            <div
                              className="col-md-12"
                              style={{ textAlign: "center" }}
                            >
                              <p>
                                <a
                                  className="btn btn-deep-orange btn-md"
                                  onClick={(e) => handleSubmit(e)}
                                >
                                  {loadingsv || loadingud || loadingdet
                                    ? "Loading..."
                                    : btnText}
                                </a>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                {cancelHide ? (
                                  <a
                                    className="btn btn-outline-deep-orange btn-md"
                                    onClick={() => handleCancel()}
                                  >
                                    Cancel
                                  </a>
                                ) : (
                                  ""
                                )}
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="container">
                          <div className="row">
                            <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 ">
                              {errordet || errorsv || errorud ? (
                                <label className="alert-danger" id="SpanError">
                                  {errordet || errorsv || errorud}
                                </label>
                              ) : (
                                ""
                              )}
                              {successdet || successud || successsv ? (
                                <label
                                  className="alert-success"
                                  id="SpanSuccess"
                                >
                                  {successdet || successud || successsv}
                                </label>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="container">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="table-header-md-9 table-responsive">
                                <table className="table table-striped table-bordered">
                                  <thead>
                                    <tr>
                                      <th>User Role Name</th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {userRoleRecs ? (
                                      userRoleRecs.map((dd) => (
                                        <tr key={dd._id}>
                                          <td>{dd.UserRole_Name}</td>
                                          <td align="center">
                                            <input
                                              type="button"
                                              className="btn btn-info btn-sm"
                                              value={
                                                loadingud
                                                  ? "Loading.."
                                                  : "Update"
                                              }
                                              disabled={loadingud}
                                              onClick={(e) =>
                                                handleEdit(
                                                  e,
                                                  dd._id,
                                                  dd.UserRole_Name
                                                )
                                              }
                                            />
                                            &nbsp;&nbsp;&nbsp;
                                            <input
                                              type="button"
                                              className="btn btn-info btn-sm"
                                              value={
                                                loadingdet
                                                  ? "Loading.."
                                                  : "Delete"
                                              }
                                              disabled={loadingdet}
                                              onClick={(e) => {
                                                setDeleteAlert({
                                                  msg: `Do you really want to delete this department (${dd.UserRole_Name}) ?`,
                                                  Delete: () =>
                                                    handleDelete(e, dd._id),
                                                });
                                                setOpenNext(true);
                                              }}
                                            />
                                          </td>
                                        </tr>
                                      ))
                                    ) : (
                                      <tr>
                                        <td colSpan={2}>{error}</td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!--/ body-content-with-md-9 --> */}
                  </div>
                  {/* <!--/ col-md-9 --> */}
                </div>
              </div>
            </div>
            <DeletePopup
              OpenNext={OpenNext}
              setOpenNext={setOpenNext}
              DeleteData={DeleteAlert}
            />
          </div>
        </div>
      </section>
    </>
  );
}
