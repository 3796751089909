export const STUDY_BATCH_LIST_REQUEST = 'STUDY_BATCH_LIST_REQUEST'
export const STUDY_BATCH_LIST_SUCCESS = 'STUDY_BATCH_LIST_SUCCESS'
export const STUDY_BATCH_LIST_FAIL = 'STUDY_BATCH_LIST_FAIL'

export const STUDY_BATCH_INSERT_REQUEST = 'STUDY_BATCH_INSERT_REQUEST'
export const STUDY_BATCH_INSERT_SUCCESS = 'STUDY_BATCH_INSERT_SUCCESS'
export const STUDY_BATCH_INSERT_FAIL = 'STUDY_BATCH_INSERT_FAIL'
export const STUDY_BATCH_INSERT_RESET = 'STUDY_BATCH_INSERT_RESET'

export const STUDY_BATCH_UPDATE_REQUEST = 'STUDY_BATCH_UPDATE_REQUEST'
export const STUDY_BATCH_UPDATE_SUCCESS = 'STUDY_BATCH_UPDATE_SUCCESS'
export const STUDY_BATCH_UPDATE_FAIL = 'STUDY_BATCH_UPDATE_FAIL'
export const STUDY_BATCH_UPDATE_RESET = 'STUDY_BATCH_UPDATE_RESET'

export const STUDY_BATCH_DELETE_REQUEST = 'STUDY_BATCH_DELETE_REQUEST'
export const STUDY_BATCH_DELETE_SUCCESS = 'STUDY_BATCH_DELETE_SUCCESS'
export const STUDY_BATCH_DELETE_FAIL = 'STUDY_BATCH_DELETE_FAIL'
export const STUDY_BATCH_DELETE_RESET = 'STUDY_BATCH_DELETE_RESET'
