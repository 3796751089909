import {
  COUNTRY_DELETE_FAIL,
  COUNTRY_DELETE_REQUEST,
  COUNTRY_DELETE_RESET,
  COUNTRY_DELETE_SUCCESS,
  COUNTRY_INSERT_FAIL,
  COUNTRY_INSERT_REQUEST,
  COUNTRY_INSERT_RESET,
  COUNTRY_INSERT_SUCCESS,
  COUNTRY_LIST_FAIL,
  COUNTRY_LIST_REQUEST,
  COUNTRY_LIST_SUCCESS,
  COUNTRY_UPDATE_FAIL,
  COUNTRY_UPDATE_REQUEST,
  COUNTRY_UPDATE_RESET,
  COUNTRY_UPDATE_SUCCESS,
} from "../Constants/countryConstants";

export const countryListReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case COUNTRY_LIST_REQUEST:
      return { loading: true };
    case COUNTRY_LIST_SUCCESS:
      return { loading: false, countries: payload };
    case COUNTRY_LIST_FAIL:
      return { loading: false, error: payload };
    default:
      return state;
  }
};

export const countrySaveReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case COUNTRY_INSERT_REQUEST:
      return { loading: true };
    case COUNTRY_INSERT_SUCCESS:
      return { loading: false, success: payload };
    case COUNTRY_INSERT_FAIL:
      return { loading: false, error: payload };
    case COUNTRY_INSERT_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const countryUpdateReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case COUNTRY_UPDATE_REQUEST:
      return { loading: true };
    case COUNTRY_UPDATE_SUCCESS:
      return { loading: false, success: payload };
    case COUNTRY_UPDATE_FAIL:
      return { loading: false, error: payload };
    case COUNTRY_UPDATE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const countryDeleteReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case COUNTRY_DELETE_REQUEST:
      return { loading: true };
    case COUNTRY_DELETE_SUCCESS:
      return { loading: false, success: payload };
    case COUNTRY_DELETE_FAIL:
      return { loading: false, error: payload };
    case COUNTRY_DELETE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};
