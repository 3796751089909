import {
  SUBAGENT_NOTIFICATIONS_INSERT_FAIL,
  SUBAGENT_NOTIFICATIONS_INSERT_REQUEST,
  SUBAGENT_NOTIFICATIONS_INSERT_RESET,
  SUBAGENT_NOTIFICATIONS_INSERT_SUCCESS,
  SUBAGENT_NOTIFICATIONS_LIST_FAIL,
  SUBAGENT_NOTIFICATIONS_LIST_REQUEST,
  SUBAGENT_NOTIFICATIONS_LIST_SUCCESS,
} from "../Constants/subagentNotification";

export const SubagentNotificationGetReducer = (
  state = {},
  { type, payload }
) => {
  switch (type) {
    case SUBAGENT_NOTIFICATIONS_LIST_REQUEST: {
      return { loading: true };
    }

    case SUBAGENT_NOTIFICATIONS_LIST_SUCCESS: {
      return { loading: false, subagentNotificationRec: payload };
    }

    case SUBAGENT_NOTIFICATIONS_LIST_FAIL: {
      return { loading: false, error: payload };
    }

    default:
      return state;
  }
};

export const SubagentNotificationCreateReducer = (
  state = {},
  { type, payload }
) => {
  switch (type) {
    case SUBAGENT_NOTIFICATIONS_INSERT_REQUEST: {
      return { loading: true };
    }

    case SUBAGENT_NOTIFICATIONS_INSERT_SUCCESS: {
      return { loading: false, success: payload };
    }

    case SUBAGENT_NOTIFICATIONS_INSERT_FAIL: {
      return { loading: false, error: payload };
    }

    case SUBAGENT_NOTIFICATIONS_INSERT_RESET: {
      return { loading: false, success: "" };
    }
    default:
      return state;
  }
};
