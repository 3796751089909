export const GURULLY_CONTACT_LIST_REQUEST = "GURULLY_CONTACT_LIST_REQUEST";
export const GURULLY_CONTACT_LIST_SUCCESS = "GURULLY_CONTACT_LIST_SUCCESS";
export const GURULLY_CONTACT_LIST_FAIL = "GURULLY_CONTACT_LIST_FAIL";

export const GURULLY_CONTACT_GET_REQUEST = "GURULLY_CONTACT_GET_REQUEST";
export const GURULLY_CONTACT_GET_SUCCESS = "GURULLY_CONTACT_GET_SUCCESS";
export const GURULLY_CONTACT_GET_FAIL = "GURULLY_CONTACT_GET_FAIL";

export const GURULLY_CONTACT_INSERT_REQUEST = "GURULLY_CONTACT_INSERT_REQUEST";
export const GURULLY_CONTACT_INSERT_SUCCESS = "GURULLY_CONTACT_INSERT_SUCCESS";
export const GURULLY_CONTACT_INSERT_FAIL = "GURULLY_CONTACT_INSERT_FAIL";
export const GURULLY_CONTACT_INSERT_RESET = "GURULLY_CONTACT_INSERT_RESET";

export const GURULLY_CONTACT_UPDATE_REQUEST = "GURULLY_CONTACT_UPDATE_REQUEST";
export const GURULLY_CONTACT_UPDATE_SUCCESS = "GURULLY_CONTACT_UPDATE_SUCCESS";
export const GURULLY_CONTACT_UPDATE_FAIL = "GURULLY_CONTACT_UPDATE_FAIL";
export const GURULLY_CONTACT_UPDATE_RESET = "GURULLY_CONTACT_UPDATE_RESET";

export const GURULLY_CONTACT_DELETE_REQUEST = "GURULLY_CONTACT_DELETE_REQUEST";
export const GURULLY_CONTACT_DELETE_SUCCESS = "GURULLY_CONTACT_DELETE_SUCCESS";
export const GURULLY_CONTACT_DELETE_FAIL = "GURULLY_CONTACT_DELETE_FAIL";
export const GURULLY_CONTACT_DELETE_RESET = "GURULLY_CONTACT_DELETE_RESET";
