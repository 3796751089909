import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listMaterialTitleAll } from "../../Actions/MaterialTitleAction";

const MaterialTitleDropDown = React.memo(({ Course }) => {
  const dispatch = useDispatch();
  const { MaterialTitleAllRec } = useSelector(
    (s) => s.listMaterialTitleAllReducer
  );

  useEffect(() => {
    dispatch(listMaterialTitleAll());
  }, [Course, dispatch]);
  return MaterialTitleAllRec ? (
    MaterialTitleAllRec?.map((recs) => (
      <option value={recs._id} key={recs._id}>
        {recs.MaterialTitle}
      </option>
    ))
  ) : (
    <></>
  );
});

export default MaterialTitleDropDown;
