import {
  COMMON_HEADER_REQUEST,
  COMMON_HEADER_SUCCESS,
  COMMON_HEADER_RESET,
} from "../Constants/commonConstants";

export const changeHeader = (headerVal) => async (dispatch) => {
  //  console.log("Header action call")
  try {
    dispatch({
      type: COMMON_HEADER_REQUEST,
    });

    dispatch({
      type: COMMON_HEADER_SUCCESS,
      payload: headerVal,
    });
  } catch (error) {
    dispatch({
      type: COMMON_HEADER_RESET,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};
