export const GET_LOCAL = (key) => {
  return JSON.parse(localStorage.getItem(key)) || undefined;
};

export const SET_LOCAL = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const REMOVE_LOCAL = (key) => {
  localStorage.clear(key);
  localStorage.removeItem(key);
};

export const toastOptions = {
  position: "bottom-right",
  autoClose: 4000,
  pauseOnHover: true,
  draggable: true,
  theme: "dark",
};

export const Months = {
  Jan: "01",
  Feb: "02",
  Mar: "03",
  Apr: "04",
  May: "05",
  Jun: "06",
  July: "07",
  Aug: "08",
  Sep: "09",
  Oct: "10",
  Nov: "11",
};

export const handleVoid = (date, time) => {
  // if()
  const Time = new Date().getTime();
  const PastTime =
    new Date(`${date}T${time}`).getTime() + 23 * (60 * 60 * 1000);

  if (Time > PastTime) {
    return true;
  } else {
    return false;
  }
};
