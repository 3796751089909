import {
  VISA_STATUS_DELETE_FAIL,
  VISA_STATUS_DELETE_REQUEST,
  VISA_STATUS_DELETE_RESET,
  VISA_STATUS_DELETE_SUCCESS,
  VISA_STATUS_INSERT_FAIL,
  VISA_STATUS_INSERT_REQUEST,
  VISA_STATUS_INSERT_RESET,
  VISA_STATUS_INSERT_SUCCESS,
  VISA_STATUS_LIST_FAIL,
  VISA_STATUS_LIST_REQUEST,
  VISA_STATUS_LIST_SUCCESS,
  VISA_STATUS_UPDATE_FAIL,
  VISA_STATUS_UPDATE_REQUEST,
  VISA_STATUS_UPDATE_RESET,
  VISA_STATUS_UPDATE_SUCCESS,
} from "../Constants/visaStatusConstants";

export const visaStatusListReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case VISA_STATUS_LIST_REQUEST:
      return { loading: true };
    case VISA_STATUS_LIST_SUCCESS:
      return { loading: false, visaStatusRecs: payload };
    case VISA_STATUS_LIST_FAIL:
      return { loading: false, error: payload };
    default:
      return state;
  }
};

export const visaStatusSaveReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case VISA_STATUS_INSERT_REQUEST:
      return { loading: true };
    case VISA_STATUS_INSERT_SUCCESS:
      return { loading: false, success: payload };
    case VISA_STATUS_INSERT_FAIL:
      return { loading: false, error: payload };
    case VISA_STATUS_INSERT_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const visaStatusUpdateReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case VISA_STATUS_UPDATE_REQUEST:
      return { loading: true };
    case VISA_STATUS_UPDATE_SUCCESS:
      return { loading: false, success: payload };
    case VISA_STATUS_UPDATE_FAIL:
      return { loading: false, error: payload };
    case VISA_STATUS_UPDATE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const visaStatusDeleteReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case VISA_STATUS_DELETE_REQUEST:
      return { loading: true };
    case VISA_STATUS_DELETE_SUCCESS:
      return { loading: false, success: payload };
    case VISA_STATUS_DELETE_FAIL:
      return { loading: false, error: payload };
    case VISA_STATUS_DELETE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};
