export const QUALIFIES_LIST_REQUEST = 'QUALIFIES_LIST_REQUEST'
export const QUALIFIES_LIST_SUCCESS = 'QUALIFIES_LIST_SUCCESS'
export const QUALIFIES_LIST_FAIL = 'QUALIFIES_LIST_FAIL'

export const QUALIFIES_INSERT_REQUEST = 'QUALIFIES_INSERT_REQUEST'
export const QUALIFIES_INSERT_SUCCESS = 'QUALIFIES_INSERT_SUCCESS'
export const QUALIFIES_INSERT_FAIL = 'QUALIFIES_INSERT_FAIL'
export const QUALIFIES_INSERT_RESET = 'QUALIFIES_INSERT_RESET'

export const QUALIFIES_UPDATE_REQUEST = 'QUALIFIES_UPDATE_REQUEST'
export const QUALIFIES_UPDATE_SUCCESS = 'QUALIFIES_UPDATE_SUCCESS'
export const QUALIFIES_UPDATE_FAIL = 'QUALIFIES_UPDATE_FAIL'
export const QUALIFIES_UPDATE_RESET = 'QUALIFIES_UPDATE_RESET'

export const QUALIFIES_DELETE_REQUEST = 'QUALIFIES_DELETE_REQUEST'
export const QUALIFIES_DELETE_SUCCESS = 'QUALIFIES_DELETE_SUCCESS'
export const QUALIFIES_DELETE_FAIL = 'QUALIFIES_DELETE_FAIL'
export const QUALIFIES_DELETE_RESET = 'QUALIFIES_DELETE_RESET'

