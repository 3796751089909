import {
  DEPARTMENT_DELETE_FAIL,
  DEPARTMENT_DELETE_REQUEST,
  DEPARTMENT_DELETE_RESET,
  DEPARTMENT_DELETE_SUCCESS,
  DEPARTMENT_INSERT_FAIL,
  DEPARTMENT_INSERT_REQUEST,
  DEPARTMENT_INSERT_RESET,
  DEPARTMENT_INSERT_SUCCESS,
  DEPARTMENT_LIST_FAIL,
  DEPARTMENT_LIST_REQUEST,
  DEPARTMENT_LIST_SUCCESS,
  DEPARTMENT_UPDATE_FAIL,
  DEPARTMENT_UPDATE_REQUEST,
  DEPARTMENT_UPDATE_RESET,
  DEPARTMENT_UPDATE_SUCCESS,
} from "../Constants/departmentConstants";

export const departmentListReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case DEPARTMENT_LIST_REQUEST:
      return { loading: true };
    case DEPARTMENT_LIST_SUCCESS:
      return { loading: false, departmentRec: payload };
    case DEPARTMENT_LIST_FAIL:
      return { loading: false, error: payload };
    default:
      return state;
  }
};

export const departmentSaveReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case DEPARTMENT_INSERT_REQUEST:
      return { loading: true };
    case DEPARTMENT_INSERT_SUCCESS:
      return { loading: false, success: payload };
    case DEPARTMENT_INSERT_FAIL:
      return { loading: false, error: payload };
    case DEPARTMENT_INSERT_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const departmentUpdateReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case DEPARTMENT_UPDATE_REQUEST:
      return { loading: true };
    case DEPARTMENT_UPDATE_SUCCESS:
      return { loading: false, success: payload };
    case DEPARTMENT_UPDATE_FAIL:
      return { loading: false, error: payload };
    case DEPARTMENT_UPDATE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const departmentDeleteReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case DEPARTMENT_DELETE_REQUEST:
      return { loading: true };
    case DEPARTMENT_DELETE_SUCCESS:
      return { loading: false, success: payload };
    case DEPARTMENT_DELETE_FAIL:
      return { loading: false, error: payload };
    case DEPARTMENT_DELETE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};
