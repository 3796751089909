import {
  ENGLISH_TEST_ATTEMPTED_DELETE_FAIL,
  ENGLISH_TEST_ATTEMPTED_DELETE_REQUEST,
  ENGLISH_TEST_ATTEMPTED_DELETE_RESET,
  ENGLISH_TEST_ATTEMPTED_DELETE_SUCCESS,
  ENGLISH_TEST_ATTEMPTED_INSERT_FAIL,
  ENGLISH_TEST_ATTEMPTED_INSERT_REQUEST,
  ENGLISH_TEST_ATTEMPTED_INSERT_RESET,
  ENGLISH_TEST_ATTEMPTED_INSERT_SUCCESS,
  ENGLISH_TEST_ATTEMPTED_LIST_FAIL,
  ENGLISH_TEST_ATTEMPTED_LIST_REQUEST,
  ENGLISH_TEST_ATTEMPTED_LIST_SUCCESS,
  ENGLISH_TEST_ATTEMPTED_UPDATE_FAIL,
  ENGLISH_TEST_ATTEMPTED_UPDATE_REQUEST,
  ENGLISH_TEST_ATTEMPTED_UPDATE_RESET,
  ENGLISH_TEST_ATTEMPTED_UPDATE_SUCCESS,
} from "../Constants/englishTestAttemptedConstants";

export const englishTestAttemptedListReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case ENGLISH_TEST_ATTEMPTED_LIST_REQUEST:
      return { loading: true };
    case ENGLISH_TEST_ATTEMPTED_LIST_SUCCESS:
      return { loading: false, englishTestAttemptedRecs: payload };
    case ENGLISH_TEST_ATTEMPTED_LIST_FAIL:
      return { loading: false, error: payload };
    default:
      return state;
  }
};

export const englishTestAttemptedSaveReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case ENGLISH_TEST_ATTEMPTED_INSERT_REQUEST:
      return { loading: true };
    case ENGLISH_TEST_ATTEMPTED_INSERT_SUCCESS:
      return { loading: false, success: payload };
    case ENGLISH_TEST_ATTEMPTED_INSERT_FAIL:
      return { loading: false, error: payload };
    case ENGLISH_TEST_ATTEMPTED_INSERT_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const englishTestAttemptedUpdateReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case ENGLISH_TEST_ATTEMPTED_UPDATE_REQUEST:
      return { loading: true };
    case ENGLISH_TEST_ATTEMPTED_UPDATE_SUCCESS:
      return { loading: false, success: payload };
    case ENGLISH_TEST_ATTEMPTED_UPDATE_FAIL:
      return { loading: false, error: payload };
    case ENGLISH_TEST_ATTEMPTED_UPDATE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const englishTestAttemptedDeleteReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case ENGLISH_TEST_ATTEMPTED_DELETE_REQUEST:
      return { loading: true };
    case ENGLISH_TEST_ATTEMPTED_DELETE_SUCCESS:
      return { loading: false, success: payload };
    case ENGLISH_TEST_ATTEMPTED_DELETE_FAIL:
      return { loading: false, error: payload };
    case ENGLISH_TEST_ATTEMPTED_DELETE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};
