export const CONTACT_ACTIVITY_LIST_REQUEST='CONTACT_ACTIVITY_LIST_REQUEST'
export const CONTACT_ACTIVITY_LIST_SUCCESS='CONTACT_ACTIVITY_LIST_SUCCESS'
export const CONTACT_ACTIVITY_LIST_FAIL='CONTACT_ACTIVITY_LIST_FAIL'
export const CONTACT_ACTIVITY_LIST_RESET='CONTACT_ACTIVITY_LIST_RESET'


export const SUBAGENT_ACTIVITY_LIST_REQUEST='SUBAGENT_ACTIVITY_LIST_REQUEST'
export const SUBAGENT_ACTIVITY_LIST_SUCCESS='SUBAGENT_ACTIVITY_LIST_SUCCESS'
export const SUBAGENT_ACTIVITY_LIST_FAIL='SUBAGENT_ACTIVITY_LIST_FAIL'
export const SUBAGENT_ACTIVITY_LIST_RESET='SUBAGENT_ACTIVITY_LIST_RESET'

export const DEAL_ACTIVITY_LIST_REQUEST='DEAL_ACTIVITY_LIST_REQUEST'
export const DEAL_ACTIVITY_LIST_SUCCESS='DEAL_ACTIVITY_LIST_SUCCESS'
export const DEAL_ACTIVITY_LIST_FAIL='DEAL_ACTIVITY_LIST_FAIL'
export const DEAL_ACTIVITY_LIST_RESET='DEAL_ACTIVITY_LIST_RESET'

