import {
  CLASS_CENTER_DELETE_FAIL,
  CLASS_CENTER_DELETE_REQUEST,
  CLASS_CENTER_DELETE_RESET,
  CLASS_CENTER_DELETE_SUCCESS,
  CLASS_CENTER_GET_FAIL,
  CLASS_CENTER_GET_REQUEST,
  CLASS_CENTER_GET_SUCCESS,
  CLASS_CENTER_INSERT_FAIL,
  CLASS_CENTER_INSERT_REQUEST,
  CLASS_CENTER_INSERT_RESET,
  CLASS_CENTER_INSERT_SUCCESS,
  CLASS_CENTER_LIST_FAIL,
  CLASS_CENTER_LIST_REQUEST,
  CLASS_CENTER_LIST_SUCCESS,
  CLASS_CENTER_UPDATE_FAIL,
  CLASS_CENTER_UPDATE_REQUEST,
  CLASS_CENTER_UPDATE_RESET,
  CLASS_CENTER_UPDATE_SUCCESS,
} from "../Constants/classCenterConstant";

export const classCenterListReducer = (state = {}, action) => {
  switch (action.type) {
    case CLASS_CENTER_LIST_REQUEST:
      return { loading: true };
    case CLASS_CENTER_LIST_SUCCESS:
      return { loading: false, classCenterRecs: action.payload };
    case CLASS_CENTER_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const classCenterGetReducer = (state = {}, action) => {
  switch (action.type) {
    case CLASS_CENTER_GET_REQUEST:
      return { loading: true };
    case CLASS_CENTER_GET_SUCCESS:
      return { loading: false, classCenterRec: action.payload };
    case CLASS_CENTER_GET_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const classCenterInsertReducer = (state = {}, action) => {
  switch (action.type) {
    case CLASS_CENTER_INSERT_REQUEST:
      return { loading: true };
    case CLASS_CENTER_INSERT_SUCCESS:
      return { loading: false, success: action.payload };
    case CLASS_CENTER_INSERT_FAIL:
      return { loading: false, error: action.payload };
    case CLASS_CENTER_INSERT_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const classCenterUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case CLASS_CENTER_UPDATE_REQUEST:
      return { loading: true };
    case CLASS_CENTER_UPDATE_SUCCESS:
      return { loading: false, success: action.payload };
    case CLASS_CENTER_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case CLASS_CENTER_UPDATE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const classCenterDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case CLASS_CENTER_DELETE_REQUEST:
      return { loading: true };
    case CLASS_CENTER_DELETE_SUCCESS:
      return { loading: false, success: action.payload };
    case CLASS_CENTER_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case CLASS_CENTER_DELETE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};
