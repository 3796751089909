import { useEffect, useState } from "react";
import {
  Package_Det_Validator,
  Package_Image_Model,
  Package_Model,
  SubCategory_Model,
} from "../../Models/PackageModel";
import SidePanel from "../../Components/SidePanel";
import TopBar from "../../Components/TopBar";
import CourseDropDown from "../Components/CourseDropDown";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { toastOptions } from "../../Models/ToastEditiorOption";
import {
  Package_Create,
  packageDetails,
  updatePackage,
} from "../../Actions/PacakgeAction";
import {
  CREATE_PACAKGE_RESET,
  UPDATE_PACAKGE_RESET,
} from "../../Constants/packageConstant";
import { useNavigate, useSearchParams } from "react-router-dom";
import SEDLoading from "../../Components/SEDLoading";
import TestTypeDropDown from "../Components/TestTypeDropDown";
import PackageTypeDropDown from "../Components/PackageTypeDropDown";
import ReactQuill from "react-quill";

export default function CreatePacakge() {
  const [PackageCreateData, setPackageCreateData] = useState(Package_Model);
  const [Description, setDescription] = useState("");
  const {
    loading: loadingsv,
    success: successsv,
    error: errorsv,
  } = useSelector((s) => s.Package_Create_Reducer);
  const [EditPackage, setEditPackage] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const {
    loading: loadingupt,
    success: successupt,
    error: errorupt,
  } = useSelector((s) => s.updatePackageReducer);

  const {
    loading: loadingDet,
    DetailsPack,
    error: errorDet,
  } = useSelector((s) => s.packageDetailsReducer);
  
  const Navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    const Validate = Package_Det_Validator(PackageCreateData);
    if (Validate === "success") {
      dispatch(Package_Create({ ...PackageCreateData, Description }));
    } else {
      toast.error(Validate, toastOptions);
    }
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    const Validate = Package_Det_Validator(PackageCreateData);
    if (Validate === "success") {
      dispatch(
        updatePackage(
          { ...PackageCreateData, Description },
          searchParams.get("PackageId")
        )
      );
    } else {
      toast.error(Validate, toastOptions);
    }
  };

  const handleCancel = () => {
    Navigate("/packages");
  };

  const handleChange = (e) => {
    setPackageCreateData({
      ...PackageCreateData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (successsv) {
      toast.success(successsv, toastOptions);
      setTimeout(() => {
        dispatch({ type: CREATE_PACAKGE_RESET });
        setPackageCreateData(Package_Model);
      }, 3000);
    }

    if (errorsv) {
      toast.error(errorsv, toastOptions);
    }
  }, [successsv,dispatch, errorsv]);

  useEffect(() => {
    if (successupt) {
      toast.success(successupt, toastOptions);
      setTimeout(() => {
        dispatch({ type: UPDATE_PACAKGE_RESET });
      }, 3000);
    }

    if (errorupt) {
      toast.error(errorupt, toastOptions);
    }
  }, [successupt,dispatch, errorupt]);

  useEffect(() => {
    if (searchParams.get("PackageId")) {
      setEditPackage(true);
      dispatch(packageDetails(searchParams.get("PackageId")));
    }
  }, [searchParams, dispatch]);

  useEffect(() => {
    if (DetailsPack && searchParams.get("PackageId")) {
      setPackageCreateData({ ...DetailsPack, ...DetailsPack?.PackageId });
      setDescription(DetailsPack?.PackageId?.Description);
    } else {
      setPackageCreateData({ ...Package_Model });
    }
  }, [DetailsPack]);

  return (
    <>
      <SidePanel page={"Packages"} />
      <section className="home-section">
        <TopBar Heading={EditPackage ? "Update Package" : "Package"} />
        {loadingsv || loadingupt || loadingDet ? <SEDLoading /> : ""}
        <div className="home-content" style={{ paddingTop: "70px" }}>
          <div className="Pacakge_Create_Body">
            <div className="note-body" id="noteBody">
              <div className="log-call">
                <div>
                  <div className="frm_grp2">
                    <div className="form-group frm-grp">
                      <label>
                        {"Package Name"}:<b>*</b>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Package Name"
                        name={"Name"}
                        value={PackageCreateData.Name}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>

                    <div className="form-group frm-grp">
                      <label>
                        Course:<b>*</b>
                      </label>
                      <select
                        name="CourseId"
                        value={PackageCreateData.CourseId}
                        onChange={(e) => handleChange(e)}
                        className="form-control"
                      >
                        <option value="">--Select--</option>
                        <CourseDropDown />
                      </select>
                    </div>

                    <div className="form-group frm-grp">
                      <label>
                        {"Image"}:<b>*</b>
                      </label>

                      <select
                        type="text"
                        className="form-control"
                        placeholder="Image url"
                        name={"Image"}
                        value={PackageCreateData.Image}
                        onChange={(e) => handleChange(e)}
                      >
                        <option>select</option>
                        {Package_Image_Model?.map((ev, i) => (
                          <option key={i + 670} value={ev?.Image}>
                            {ev?.Name}
                          </option>
                        ))}
                      </select>
                      {/* <input
                        type="text"
                        className="form-control"
                        placeholder="Image url"
                        name={"Image"}
                        value={PackageCreateData.Image}
                        onChange={(e) => handleChange(e)}
                      /> */}
                    </div>
                  </div>
                  <div className="frm_grp2">
                    <div className="form-group frm-grp">
                      <label>
                        {"Pacakge Detail"}:<b>*</b>
                      </label>

                      <input
                        type="text"
                        className="form-control"
                        placeholder="Pacakge Detail"
                        name={"Detail"}
                        value={PackageCreateData.Detail}
                        onChange={(e) => handleChange(e)}
                      />
                      {/* <div className="myDatePickerContainer">
                                <ReactDatePicker
                                  onKeyDown={(e) => {
                                    e.preventDefault();
                                  }}
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  yearDropdownItemNumber={100}
                                  scrollableYearDropdown
                                  dropdownMode="scroll"
                                  dateFormat="dd-MM-yyyy"
                                  className="myDatePicker"
                                  selected={
                                    PackageCreateData?.DueAmountToBePaidBy
                                      ? new Date(
                                          PackageCreateData?.DueAmountToBePaidBy
                                        )
                                      : ""
                                  }
                                  onChange={(date) =>
                                    setPackageCreateData({
                                      ...PackageCreateData,
                                      DueAmountToBePaidBy: date,
                                    })
                                  }
                                  placeholderText="dd/mm/yy"
                                />
                              </div> */}
                    </div>
                    <div className="form-group frm-grp">
                      <label>
                        {"Package Description"}:<b>*</b>
                      </label>

                      <ReactQuill
                        placeholder={"Enter Package description"}
                        name="Description"
                        value={Description}
                        onChange={(e) => setDescription(e)}
                        required
                      />
                      {/* <textarea
                        className="form-control"
                        placeholder="Description"
                        name={"Description"}
                        style={{ resize: "none" }}
                        value={PackageCreateData.Description}
                        onChange={(e) => handleChange(e)}
                      ></textarea> */}
                    </div>

                    <div className="form-group frm-grp">
                      <label>
                        {"ActualCost"}:<b>*</b>
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Actual Cost"
                        name={"ActualCost"}
                        value={PackageCreateData.ActualCost}
                        onChange={(e) => {
                          let Price = +e.target.value;
                          setPackageCreateData({
                            ...PackageCreateData,
                            ActualCost: Price ? Price.toFixed(2) : "",
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="frm_grp2">
                    <div className="form-group frm-grp">
                      <label>
                        {"Discounted Price"}:<b>*</b>
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Discounted Price"
                        name={"DiscountedPrice"}
                        value={PackageCreateData.DiscountedPrice}
                        onChange={(e) => {
                          let Price = +e.target.value;
                          setPackageCreateData({
                            ...PackageCreateData,
                            DiscountedPrice: Price ? Price.toFixed(2) : "",
                          });
                        }}
                      />
                    </div>{" "}
                    {/* <div className="form-group frm-grp">
                      <label>
                        {"Timings"}:<b>*</b>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Timings"
                        name={"Timings"}
                        value={PackageCreateData.Timings}
                        onChange={(e) => handleChange(e)}
                      />
                    </div> */}
                    <div className="form-group frm-grp">
                      <label>
                        {"Duration"}:<b>*</b>
                      </label>

                      <input
                        type="number"
                        className="form-control"
                        placeholder="Duration"
                        name={"Duration"}
                        value={PackageCreateData.Duration}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                  </div>

                  <div className="frm_grp2">
                    <div className="form-group frm-grp">
                      <label>
                        {"Sub Category"}:<b>*</b>
                      </label>
                      <select
                        type="text"
                        className="form-control"
                        placeholder="Sub Category"
                        name={"SubCategory"}
                        value={PackageCreateData.SubCategory}
                        onChange={(e) => handleChange(e)}
                      >
                        <option>select</option>
                        {SubCategory_Model?.map((ev, i) => (
                          <option key={i + 67}>{ev}</option>
                        ))}
                      </select>
                    </div>{" "}
                    {/* <div className="form-group frm-grp">
                      <label>
                        {"Class Mode"}:<b>*</b>
                      </label>
                      <select
                        className="form-control"
                        name={"ClassMode"}
                        value={PackageCreateData.ClassMode}
                        onChange={(e) => handleChange(e)}
                      >
                        <option value="">Select</option>
                        <ClassModeDropDown />
                      </select>
                    </div> */}
                    <div className="form-group frm-grp">
                      <label>
                        {"TestType"}:<b>*</b>
                      </label>
                      <select
                        className="form-control"
                        name={"TestType"}
                        value={PackageCreateData.TestType}
                        onChange={(e) => handleChange(e)}
                      >
                        <option value="">Select</option>
                        <TestTypeDropDown />
                      </select>
                    </div>
                  </div>

                  <div className="frm_grp2">
                    <div className="form-group frm-grp">
                      <label>
                        {"Package Type"}:<b>*</b>
                      </label>
                      <select
                        className="form-control"
                        name={"PackageType"}
                        value={PackageCreateData.PackageType}
                        onChange={(e) => handleChange(e)}
                      >
                        <option value="">Select</option>
                        <PackageTypeDropDown />
                      </select>
                    </div>
                  </div>
                  <h5 style={{ color: "black", padding: "4px 0" }}>
                    Packages :
                  </h5>

                  <div className="frm_grp2">
                    <div className="form-group frm-grp">
                      <label>
                        {"LIVE CLASSES"}:<b>*</b>
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="LIVE CLASSES"
                        name={"LIVE_CLASSES"}
                        value={PackageCreateData.LIVE_CLASSES}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>{" "}
                    <div className="form-group frm-grp">
                      <label>
                        {"1:1 DOUBT SESSIONS"}:<b>*</b>
                      </label>
                      <div className="Radio_Btn_Container">
                        <div>
                          <input
                            type="radio"
                            checked={
                              PackageCreateData.One_On_One_DOUBT_SESSIONS ==
                              "Yes"
                            }
                            onChange={(e) =>
                              setPackageCreateData({
                                ...PackageCreateData,
                                One_On_One_DOUBT_SESSIONS: "Yes",
                              })
                            }
                          />
                          <span> Yes</span>
                        </div>
                        <div>
                          <input
                            type="radio"
                            checked={
                              PackageCreateData.One_On_One_DOUBT_SESSIONS ==
                              "No"
                            }
                            onChange={(e) =>
                              setPackageCreateData({
                                ...PackageCreateData,
                                One_On_One_DOUBT_SESSIONS: "No",
                              })
                            }
                          />{" "}
                          <span> No</span>
                        </div>
                      </div>

                      {/* <select
                        className="form-control"
                        placeholder="1:1 DOUBT SESSIONS"
                        name={"One_On_One_DOUBT_SESSIONS"}
                        value={PackageCreateData.One_On_One_DOUBT_SESSIONS}
                        onChange={(e) => handleChange(e)}
                      >
                        <option value="">Select</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select> */}
                    </div>
                    <div className="form-group frm-grp">
                      <label>
                        {"DOUBT SESSIONS"}:<b>*</b>
                      </label>

                      <div className="Radio_Btn_Container">
                        <div>
                          <input
                            type="radio"
                            checked={PackageCreateData.DOUBT_SESSIONS == "Yes"}
                            onChange={(e) =>
                              setPackageCreateData({
                                ...PackageCreateData,
                                DOUBT_SESSIONS: "Yes",
                              })
                            }
                          />
                          <span> Yes</span>
                        </div>
                        <div>
                          <input
                            type="radio"
                            checked={PackageCreateData.DOUBT_SESSIONS == "No"}
                            onChange={(e) =>
                              setPackageCreateData({
                                ...PackageCreateData,
                                DOUBT_SESSIONS: "No",
                              })
                            }
                          />{" "}
                          <span> No</span>
                        </div>
                      </div>
                      {/* <select
                        className="form-control"
                        placeholder="DOUBT_SESSIONS"
                        name={"DOUBT_SESSIONS"}
                        value={PackageCreateData.DOUBT_SESSIONS}
                        onChange={(e) => handleChange(e)}
                      >
                        <option value="">Select</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select> */}
                    </div>
                  </div>

                  <div className="frm_grp2">
                    <div className="form-group frm-grp">
                      <label>
                        {"SESSION TESTS"}:<b>*</b>
                      </label>

                      <div className="Radio_Btn_Container">
                        <div>
                          <input
                            type="radio"
                            checked={PackageCreateData.SESSION_TESTS == "Yes"}
                            onChange={(e) =>
                              setPackageCreateData({
                                ...PackageCreateData,
                                SESSION_TESTS: "Yes",
                              })
                            }
                          />
                          <span> Yes</span>
                        </div>
                        <div>
                          <input
                            type="radio"
                            checked={PackageCreateData.SESSION_TESTS == "No"}
                            onChange={(e) =>
                              setPackageCreateData({
                                ...PackageCreateData,
                                SESSION_TESTS: "No",
                              })
                            }
                          />{" "}
                          <span> No</span>
                        </div>
                      </div>
                      {/* <select
                        className="form-control"
                        placeholder="SESSION TESTS"
                        name={"SESSION_TESTS"}
                        value={PackageCreateData.SESSION_TESTS}
                        onChange={(e) => handleChange(e)}
                      >
                        <option value="">Select</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select> */}
                    </div>{" "}
                    <div className="form-group frm-grp">
                      <label>
                        {"STUDY MATERIAL ACCESS"}:<b>*</b>
                      </label>
                      <div className="Radio_Btn_Container">
                        <div>
                          <input
                            type="radio"
                            checked={
                              PackageCreateData.STUDY_MATERIAL_ACCESS == "Yes"
                            }
                            onChange={(e) =>
                              setPackageCreateData({
                                ...PackageCreateData,
                                STUDY_MATERIAL_ACCESS: "Yes",
                              })
                            }
                          />
                          <span> Yes</span>
                        </div>
                        <div>
                          <input
                            type="radio"
                            checked={
                              PackageCreateData.STUDY_MATERIAL_ACCESS == "No"
                            }
                            onChange={(e) =>
                              setPackageCreateData({
                                ...PackageCreateData,
                                STUDY_MATERIAL_ACCESS: "No",
                              })
                            }
                          />{" "}
                          <span> No</span>
                        </div>
                      </div>
                      {/* <select
                        className="form-control"
                        name={"STUDY_MATERIAL_ACCESS"}
                        value={PackageCreateData.STUDY_MATERIAL_ACCESS}
                        onChange={(e) => handleChange(e)}
                      >
                        <option value="">Select</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select> */}
                    </div>
                    <div className="form-group frm-grp">
                      <label>
                        {"COMPLETE MOCK TEST"}:<b>*</b>
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        name={"COMPLETE_MOCK_TEST"}
                        value={PackageCreateData.COMPLETE_MOCK_TEST}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                  </div>

                  <div className="frm_grp2">
                    <div className="form-group frm-grp">
                      <label>
                        {"COMPLETE MOCK TEST AI"}:<b>*</b>
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        name={"COMPLETE_MOCK_TEST_AI"}
                        value={PackageCreateData.COMPLETE_MOCK_TEST_AI}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>{" "}
                    <div className="form-group frm-grp">
                      <label>
                        {"TESTIFYI REALITY TEST"}:<b>*</b>
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        name={"TESTIFYI_REALITY_TEST"}
                        value={PackageCreateData.TESTIFYI_REALITY_TEST}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                    <div className="form-group frm-grp">
                      <label>
                        {"MASTER CLASS"}:<b>*</b>
                      </label>
                      <div className="Radio_Btn_Container">
                        <div>
                          <input
                            type="radio"
                            checked={PackageCreateData.MASTER_CLASS == "Yes"}
                            onChange={(e) =>
                              setPackageCreateData({
                                ...PackageCreateData,
                                MASTER_CLASS: "Yes",
                              })
                            }
                          />
                          <span> Yes</span>
                        </div>
                        <div>
                          <input
                            type="radio"
                            checked={PackageCreateData.MASTER_CLASS == "No"}
                            onChange={(e) =>
                              setPackageCreateData({
                                ...PackageCreateData,
                                MASTER_CLASS: "No",
                              })
                            }
                          />{" "}
                          <span> No</span>
                        </div>
                      </div>
                      {/* <select
                        className="form-control"
                        name={"MASTER_CLASS"}
                        value={PackageCreateData.MASTER_CLASS}
                        onChange={(e) => handleChange(e)}
                      >
                        <option style={{ color: "gray" }}>Select</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select> */}
                    </div>
                  </div>

                  <div className="frm_grp2">
                    <div className="form-group frm-grp">
                      <label>
                        {"1:1 SPEAKING SESSIONS"}:<b>*</b>
                      </label>
                      <div className="Radio_Btn_Container">
                        <div>
                          <input
                            type="radio"
                            checked={
                              PackageCreateData.One_On_One_SPEAKING_SESSIONS ==
                              "Yes"
                            }
                            onChange={(e) =>
                              setPackageCreateData({
                                ...PackageCreateData,
                                One_On_One_SPEAKING_SESSIONS: "Yes",
                              })
                            }
                          />
                          <span> Yes</span>
                        </div>
                        <div>
                          <input
                            type="radio"
                            checked={
                              PackageCreateData.One_On_One_SPEAKING_SESSIONS ==
                              "No"
                            }
                            onChange={(e) =>
                              setPackageCreateData({
                                ...PackageCreateData,
                                One_On_One_SPEAKING_SESSIONS: "No",
                              })
                            }
                          />{" "}
                          <span> No</span>
                        </div>
                      </div>
                      {/* <select
                        className="form-control"
                        name={"One_On_One_SPEAKING_SESSIONS"}
                        value={PackageCreateData.One_On_One_SPEAKING_SESSIONS}
                        onChange={(e) => handleChange(e)}
                      >
                        <option value="">Select</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select> */}
                    </div>{" "}
                    <div className="form-group frm-grp">
                      <label>
                        {"TESTIFYI PRACTICE TEST"}:<b>*</b>
                      </label>
                      <input
                        className="form-control"
                        name={"LANGUAGE_PRACTICE_TEST"}
                        value={PackageCreateData.LANGUAGE_PRACTICE_TEST}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                    <div className="form-group frm-grp">
                      <label>
                        {"GRAMMAR TEST"}:<b>*</b>
                      </label>
                      <div className="Radio_Btn_Container">
                        <div>
                          <input
                            type="radio"
                            checked={PackageCreateData.GRAMMAR_TEST == "Yes"}
                            onChange={(e) =>
                              setPackageCreateData({
                                ...PackageCreateData,
                                GRAMMAR_TEST: "Yes",
                              })
                            }
                          />
                          <span> Yes</span>
                        </div>
                        <div>
                          <input
                            type="radio"
                            checked={PackageCreateData.GRAMMAR_TEST == "No"}
                            onChange={(e) =>
                              setPackageCreateData({
                                ...PackageCreateData,
                                GRAMMAR_TEST: "No",
                              })
                            }
                          />{" "}
                          <span> No</span>
                        </div>
                      </div>
                      {/* <select
                        className="form-control"
                        name={"GRAMMAR_TEST"}
                        value={PackageCreateData.GRAMMAR_TEST}
                        onChange={(e) => handleChange(e)}
                      >
                        <option value="">Select</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select> */}
                    </div>
                  </div>

                  <div className="frm_grp2">
                    <div className="form-group frm-grp">
                      <label>
                        {"MOCK INTERVIEW"}:<b>*</b>
                      </label>
                      <div className="Radio_Btn_Container">
                        <div>
                          <input
                            type="radio"
                            checked={PackageCreateData.MOCK_INTERVIEW == "Yes"}
                            onChange={(e) =>
                              setPackageCreateData({
                                ...PackageCreateData,
                                MOCK_INTERVIEW: "Yes",
                              })
                            }
                          />
                          <span> Yes</span>
                        </div>
                        <div>
                          <input
                            type="radio"
                            checked={PackageCreateData.MOCK_INTERVIEW == "No"}
                            onChange={(e) =>
                              setPackageCreateData({
                                ...PackageCreateData,
                                MOCK_INTERVIEW: "No",
                              })
                            }
                          />{" "}
                          <span> No</span>
                        </div>
                      </div>
                      {/* <select
                        className="form-control"
                        name={"MOCK_INTERVIEW"}
                        value={PackageCreateData.MOCK_INTERVIEW}
                        onChange={(e) => handleChange(e)}
                      >
                        <option value="">Select</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select> */}
                    </div>{" "}
                    <div className="form-group frm-grp">
                      <label>
                        {"GROUP DISCUSSIONS"}:<b>*</b>
                      </label>
                      <div className="Radio_Btn_Container">
                        <div>
                          <input
                            type="radio"
                            checked={
                              PackageCreateData.GROUP_DISCUSSIONS == "Yes"
                            }
                            onChange={(e) =>
                              setPackageCreateData({
                                ...PackageCreateData,
                                GROUP_DISCUSSIONS: "Yes",
                              })
                            }
                          />
                          <span> Yes</span>
                        </div>
                        <div>
                          <input
                            type="radio"
                            checked={
                              PackageCreateData.GROUP_DISCUSSIONS == "No"
                            }
                            onChange={(e) =>
                              setPackageCreateData({
                                ...PackageCreateData,
                                GROUP_DISCUSSIONS: "No",
                              })
                            }
                          />{" "}
                          <span> No</span>
                        </div>
                      </div>
                      {/* <select
                        className="form-control"
                        name={"GROUP_DISCUSSIONS"}
                        value={PackageCreateData.GROUP_DISCUSSIONS}
                        onChange={(e) => handleChange(e)}
                      >
                        <option value="">Select</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select> */}
                    </div>
                    <div className="form-group frm-grp">
                      <label>
                        {"BUSINESS ENGLISH SESSIONS"}:<b>*</b>
                      </label>
                      <div className="Radio_Btn_Container">
                        <div>
                          <input
                            type="radio"
                            checked={
                              PackageCreateData.BUSINESS_ENGLISH_SESSIONS ==
                              "Yes"
                            }
                            onChange={(e) =>
                              setPackageCreateData({
                                ...PackageCreateData,
                                BUSINESS_ENGLISH_SESSIONS: "Yes",
                              })
                            }
                          />
                          <span> Yes</span>
                        </div>
                        <div>
                          <input
                            type="radio"
                            checked={
                              PackageCreateData.BUSINESS_ENGLISH_SESSIONS ==
                              "No"
                            }
                            onChange={(e) =>
                              setPackageCreateData({
                                ...PackageCreateData,
                                BUSINESS_ENGLISH_SESSIONS: "No",
                              })
                            }
                          />{" "}
                          <span> No</span>
                        </div>
                      </div>
                      {/* <select
                        className="form-control"
                        name={"BUSINESS_ENGLISH_SESSIONS"}
                        value={PackageCreateData.BUSINESS_ENGLISH_SESSIONS}
                        onChange={(e) => handleChange(e)}
                      >
                        <option value="">Select</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select> */}
                    </div>
                  </div>
                  <div className="frm_grp2">
                    <div className="form-group frm-grp">
                      <label>
                        {"SOFT SKILLS SESSIONS"}:<b>*</b>
                      </label>
                      <div className="Radio_Btn_Container">
                        <div>
                          <input
                            type="radio"
                            checked={
                              PackageCreateData.SOFT_SKILLS_SESSION == "Yes"
                            }
                            onChange={(e) =>
                              setPackageCreateData({
                                ...PackageCreateData,
                                SOFT_SKILLS_SESSION: "Yes",
                              })
                            }
                          />
                          <span> Yes</span>
                        </div>
                        <div>
                          <input
                            type="radio"
                            checked={
                              PackageCreateData.SOFT_SKILLS_SESSION == "No"
                            }
                            onChange={(e) =>
                              setPackageCreateData({
                                ...PackageCreateData,
                                SOFT_SKILLS_SESSION: "No",
                              })
                            }
                          />{" "}
                          <span> No</span>
                        </div>
                      </div>
                      {/* <select
                        className="form-control"
                        name={"SOFT_SKILLS_SESSION"}
                        value={PackageCreateData.SOFT_SKILLS_SESSION}
                        onChange={(e) => handleChange(e)}
                      >
                        <option value="">Select</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select> */}
                    </div>{" "}
                  </div>
                </div>
                <hr />
                <div>
                  {/* <p>
                    <span className="clrgrn">{successsv}</span>
                    <span className="clrrd">{errorsv}</span>
                  </p> */}
                </div>
                <div className="log-call-footer">
                  {EditPackage ? (
                    <button
                      className="btn btn-sm btn-deep-orange waves-effect waves-light"
                      onClick={handleUpdate}
                      disabled={loadingupt}
                    >
                      {loadingupt ? "Loading..." : "Update Record"}
                    </button>
                  ) : (
                    <button
                      className="btn btn-sm btn-deep-orange waves-effect waves-light"
                      onClick={handleSubmit}
                      disabled={loadingsv}
                    >
                      {loadingsv ? "Loading..." : "Save Record"}
                    </button>
                  )}

                  <button
                    type="button"
                    disabled={loadingsv}
                    className="btn  waves-effect waves-light"
                    onClick={() => handleCancel()}
                  >
                    Cancel
                  </button>
                  <div className="custom-control custom-checkbox mt-1 ml-1"></div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      </section>
    </>
  );
}
