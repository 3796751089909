import {
  TLAT_RESULT_FAILED,
  TLAT_RESULT_LIST_FAILED,
  TLAT_RESULT_LIST_REQ,
  TLAT_RESULT_LIST_SUCCESS,
  TLAT_RESULT_REQ,
  TLAT_RESULT_SUCCESS,
} from "../Constants/TLatResultConstant";
import api from "../core/api";

export const TLat_Result = (contactId) => async (dispatch) => {
  try {
    dispatch({ type: TLAT_RESULT_REQ });
    const { data } = await api.get(`/psychometric/${contactId}`);
    dispatch({ type: TLAT_RESULT_SUCCESS, payload: data?.Result });
  } catch (ex) {
    dispatch({
      type: TLAT_RESULT_FAILED,
      payload:
        ex.response && ex.response.data.Result
          ? ex.response.data.Result
          : ex.message,
    });
  }
};

export const TLat_list_Result = () => async (dispatch) => {
  try {
    dispatch({ type: TLAT_RESULT_LIST_REQ });
    const { data } = await api.get("/psychometricQuestion/all-results");
    dispatch({ type: TLAT_RESULT_LIST_SUCCESS, payload: data?.Result });
  } catch (ex) {
    dispatch({
      type: TLAT_RESULT_LIST_FAILED,
      payload:
        ex.response && ex.response.data.Result
          ? ex.response.data.Result
          : ex.message,
    });
  }
};
