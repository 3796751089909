export const Hideemail = (target) => {
  var email = target;
  var hiddenEmail = "";
  for (let i = 0; i < email.length; i++) {
    if (i > 2 && i < email.indexOf("@")) {
      hiddenEmail += "*";
    } else {
      hiddenEmail += email[i];
    }
  }

  return hiddenEmail;
};

export const PhoneHide = (target) => {
  var phone = target;
  var hiddenPhone = "";
  for (let i = 0; i < phone.length; i++) {
    if (i > 2) {
      hiddenPhone += "*";
    } else {
      hiddenPhone += phone[i];
    }
  }

  return hiddenPhone;
};

export const TimeStampConverter = (date) => {
  var d = new Date(date);

  return (
    d.getFullYear() +
    "-" +
    ("0" + (d.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + d.getDate()).slice(-2)
  );
};

export const TimeStampConverter2 = (date) => {
  var d = new Date(date);

  return (
    d.getFullYear() +
    "-" +
    ("0" + (d.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + d.getDate()).slice(-2)
  );
};

export const TimeStampConverter3 = (date) => {
  var d = new Date(date);
  var today = new Date();

  let Dates =
    d.getFullYear() +
    "-" +
    ("0" + (d.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + d.getDate()).slice(-2);

  today =
    today.getFullYear() +
    "-" +
    ("0" + (today.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + today.getDate()).slice(-2);

  if (today === Dates) {
    return "Today";
  } else {
    return Dates;
  }
};
