export const GURULLY_LIST_REQUEST = "GURULLY_LIST_REQUEST";
export const GURULLY_LIST_SUCCESS = "GURULLY_LIST_SUCCESS";
export const GURULLY_LIST_FAIL = "GURULLY_LIST_FAIL";

export const GURULLY_GET_REQUEST = "GURULLY_GET_REQUEST";
export const GURULLY_GET_SUCCESS = "GURULLY_GET_SUCCESS";
export const GURULLY_GET_FAIL = "GURULLY_GET_FAIL";

export const GURULLY_INSERT_REQUEST = "GURULLY_INSERT_REQUEST";
export const GURULLY_INSERT_SUCCESS = "GURULLY_INSERT_SUCCESS";
export const GURULLY_INSERT_FAIL = "GURULLY_INSERT_FAIL";
export const GURULLY_INSERT_RESET = "GURULLY_INSERT_RESET";

export const GURULLY_UPDATE_REQUEST = "GURULLY_UPDATE_REQUEST";
export const GURULLY_UPDATE_SUCCESS = "GURULLY_UPDATE_SUCCESS";
export const GURULLY_UPDATE_FAIL = "GURULLY_UPDATE_FAIL";
export const GURULLY_UPDATE_RESET = "GURULLY_UPDATE_RESET";

export const GURULLY_DELETE_REQUEST = "GURULLY_DELETE_REQUEST";
export const GURULLY_DELETE_SUCCESS = "GURULLY_DELETE_SUCCESS";
export const GURULLY_DELETE_FAIL = "GURULLY_DELETE_FAIL";
export const GURULLY_DELETE_RESET = "GURULLY_DELETE_RESET";
