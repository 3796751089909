import {
  MATERIAL_TITLE_CREATE_FAILED,
  MATERIAL_TITLE_CREATE_REQ,
  MATERIAL_TITLE_CREATE_RESET,
  MATERIAL_TITLE_CREATE_SUCCESS,
  MATERIAL_TITLE_LIST_ALL_FAILED,
  MATERIAL_TITLE_LIST_ALL_REQ,
  MATERIAL_TITLE_LIST_ALL_SUCCESS,
  MATERIAL_TITLE_LIST_FAILED,
  MATERIAL_TITLE_LIST_REQ,
  MATERIAL_TITLE_LIST_RESET,
  MATERIAL_TITLE_LIST_SUCCESS,
  MATERIAL_TITLE_REMOVE_FAILED,
  MATERIAL_TITLE_REMOVE_REQ,
  MATERIAL_TITLE_REMOVE_RESET,
  MATERIAL_TITLE_REMOVE_SUCCESS,
  MATERIAL_TITLE_UPDATE_FAILED,
  MATERIAL_TITLE_UPDATE_REQ,
  MATERIAL_TITLE_UPDATE_RESET,
  MATERIAL_TITLE_UPDATE_SUCCESS,
} from "../Constants/MaterialTitleConstant";

export const createMaterialTitleReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case MATERIAL_TITLE_CREATE_REQ: {
      return { loading: true };
    }

    case MATERIAL_TITLE_CREATE_SUCCESS: {
      return { loading: false, success: payload };
    }

    case MATERIAL_TITLE_CREATE_FAILED: {
      return { loading: false, error: payload };
    }

    case MATERIAL_TITLE_CREATE_RESET: {
      return { laoding: false, success: "", error: "" };
    }
    default:
      return state;
  }
};

export const listMaterialTitleReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case MATERIAL_TITLE_LIST_REQ: {
      return { loading: true };
    }

    case MATERIAL_TITLE_LIST_SUCCESS: {
      return { loading: false, MaterialTitleRec: payload };
    }

    case MATERIAL_TITLE_LIST_FAILED: {
      return { loading: false, error: payload };
    }

    case MATERIAL_TITLE_LIST_RESET: {
      return {};
    }

    default:
      return state;
  }
};

export const listMaterialTitleAllReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case MATERIAL_TITLE_LIST_ALL_REQ: {
      return { loading: true };
    }

    case MATERIAL_TITLE_LIST_ALL_SUCCESS: {
      return { loading: false, MaterialTitleAllRec: payload };
    }

    case MATERIAL_TITLE_LIST_ALL_FAILED: {
      return { loading: false, error: payload };
    }

    default:
      return state;
  }
};

export const deleteMaterialTitleReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case MATERIAL_TITLE_REMOVE_REQ: {
      return { loading: true };
    }
    case MATERIAL_TITLE_REMOVE_SUCCESS: {
      return { loading: false, success: payload };
    }

    case MATERIAL_TITLE_REMOVE_FAILED: {
      return { loading: false, error: payload };
    }

    case MATERIAL_TITLE_REMOVE_RESET: {
      return { loading: false, error: "", success: "" };
    }
    default:
      return state;
  }
};

export const updateMaterialTitleReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case MATERIAL_TITLE_UPDATE_REQ: {
      return { loading: true };
    }

    case MATERIAL_TITLE_UPDATE_SUCCESS: {
      return { loading: false, success: payload };
    }

    case MATERIAL_TITLE_UPDATE_FAILED: {
      return { laoding: false, error: payload };
    }

    case MATERIAL_TITLE_UPDATE_RESET: {
      return { laoding: false, error: "", success: "" };
    }

    default:
      return state;
  }
};
