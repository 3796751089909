import {
  CRM_UPDATES_INSERT_FAIL,
  CRM_UPDATES_INSERT_REQUEST,
  CRM_UPDATES_INSERT_SUCCESS,
  CRM_UPDATES_DELETE_FAIL,
  CRM_UPDATES_DELETE_REQUEST,
  CRM_UPDATES_DELETE_SUCCESS,
  CRM_UPDATES_LIST_FAIL,
  CRM_UPDATES_LIST_REQUEST,
  CRM_UPDATES_LIST_SUCCESS,
  CRM_UPDATES_UPDATE_FAIL,
  CRM_UPDATES_UPDATE_REQUEST,
  CRM_UPDATES_UPDATE_SUCCESS,
} from "../Constants/crmUpdatesConstants";

// Contact Rating List
export const crmUpdatesListReducers = (state = {}, action) => {
  switch (action.type) {
    case CRM_UPDATES_LIST_REQUEST:
      return { loading: true };
    case CRM_UPDATES_LIST_SUCCESS:
      return { loading: false, crmUpdatesRecs: action.payload };
    case CRM_UPDATES_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// Contact Rating Create
export const crmUpdatesCreateReducers = (state = {}, action) => {
  switch (action.type) {
    case CRM_UPDATES_INSERT_REQUEST:
      return { loading: true };
    case CRM_UPDATES_INSERT_SUCCESS:
      return { loading: false, success: action.payload };
    case CRM_UPDATES_INSERT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// Contact Rating Update
export const crmUpdatesUpdateReducers = (state = {}, action) => {
  switch (action.type) {
    case CRM_UPDATES_UPDATE_REQUEST:
      return { loading: true };
    case CRM_UPDATES_UPDATE_SUCCESS:
      return { loading: false, success: action.payload };
    case CRM_UPDATES_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// Contact Rating Delete
export const crmUpdatesDeleteReducers = (state = {}, action) => {
  switch (action.type) {
    case CRM_UPDATES_DELETE_REQUEST:
      return { loading: true };
    case CRM_UPDATES_DELETE_SUCCESS:
      return { loading: false, success: action.payload };
    case CRM_UPDATES_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
