import {
  INVOICED_LIST_FAIL,
  INVOICED_LIST_REQUEST,
  INVOICED_LIST_SUCCESS,
} from "../Constants/invoiceConstant";

export const InvoiceListReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case INVOICED_LIST_REQUEST: {
      return { loading: true };
    }

    case INVOICED_LIST_SUCCESS: {
      return { loading: false, invoiceRec: payload };
    }

    case INVOICED_LIST_FAIL: {
      return { loading: false, invoiceRec: "" };
    }

    default:
      return state;
  }
};
