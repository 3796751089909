import {
  CREATE_COUPON_FAILED,
  CREATE_COUPON_REQ,
  CREATE_COUPON_RESET,
  CREATE_COUPON_SUCCESS,
  COUPON_DETAILS_FAILED,
  COUPON_DETAILS_REQ,
  COUPON_DETAILS_RESET,
  COUPON_DETAILS_SUCCESS,
  COUPON_LIST_FAILED,
  COUPON_LIST_REQ,
  COUPON_LIST_RESET,
  COUPON_LIST_SUCCESS,
  COUPON_DELETE_FAILED,
  COUPON_DELETE_REQ,
  COUPON_DELETE_SUCCESS,
  UPDATE_COUPON_FAILED,
  UPDATE_COUPON_REQ,
  UPDATE_COUPON_RESET,
  UPDATE_COUPON_SUCCESS,
  COUPON_DELETE_RESET,
} from "../Constants/CouponConstant";

export const Coupon_Create_Reducer = (state = {}, { type, payload }) => {
  switch (type) {
    case CREATE_COUPON_REQ: {
      return { loading: true };
    }

    case CREATE_COUPON_SUCCESS: {
      return { loading: false, success: payload };
    }

    case CREATE_COUPON_FAILED: {
      return { loading: false, error: payload };
    }

    case CREATE_COUPON_RESET: {
      return { loading: false, error: "", success: "" };
    }

    default:
      return state;
  }
};

export const Coupon_List_Reducer = (state = {}, { type, payload, Total }) => {
  switch (type) {
    case COUPON_LIST_REQ: {
      return { loading: true };
    }

    case COUPON_LIST_SUCCESS: {
      return { loading: false, CouponRec: payload, total: Total };
    }
    case COUPON_LIST_FAILED: {
      return { loading: false, error: payload };
    }

    case COUPON_LIST_RESET: {
      return {};
    }

    default:
      return state;
  }
};

export const updateCouponReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case UPDATE_COUPON_REQ: {
      return { loading: true };
    }

    case UPDATE_COUPON_SUCCESS: {
      return { loading: false, success: payload };
    }

    case UPDATE_COUPON_FAILED: {
      return { loading: false, error: payload };
    }

    case UPDATE_COUPON_RESET: {
      return { loading: false, error: "", success: "" };
    }

    default:
      return state;
  }
};

export const couponDetailsReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case COUPON_DETAILS_REQ: {
      return { loading: true };
    }

    case COUPON_DETAILS_SUCCESS: {
      return { loading: false, DetailsCoupon: payload };
    }

    case COUPON_DETAILS_FAILED: {
      return { loading: false, error: payload };
    }

    case COUPON_DETAILS_RESET: {
      return { loading: false, success: "", error: "" };
    }

    default:
      return state;
  }
};

export const Coupon_Delete_Reducer = (state = {}, { type, payload }) => {
  switch (type) {
    case COUPON_DELETE_REQ: {
      return { loading: true };
    }

    case COUPON_DELETE_SUCCESS: {
      return { loading: false, success: payload };
    }

    case COUPON_DELETE_FAILED: {
      return { loading: false, error: payload };
    }

    case COUPON_DELETE_RESET: {
      return { loading: false, error: "" };
    }

    default:
      return state;
  }
};
