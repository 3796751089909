export const MEETING_INTERFACE_LIST_REQUEST = 'MEETING_INTERFACE_LIST_REQUEST'
export const MEETING_INTERFACE_LIST_SUCCESS = 'MEETING_INTERFACE_LIST_SUCCESS'
export const MEETING_INTERFACE_LIST_FAIL = 'MEETING_INTERFACE_LIST_FAIL'

export const MEETING_INTERFACE_INSERT_REQUEST = 'MEETING_INTERFACE_INSERT_REQUEST'
export const MEETING_INTERFACE_INSERT_SUCCESS = 'MEETING_INTERFACE_INSERT_SUCCESS'
export const MEETING_INTERFACE_INSERT_FAIL = 'MEETING_INTERFACE_INSERT_FAIL'
export const MEETING_INTERFACE_INSERT_RESET = 'MEETING_INTERFACE_INSERT_RESET'

export const MEETING_INTERFACE_UPDATE_REQUEST = 'MEETING_INTERFACE_UPDATE_REQUEST'
export const MEETING_INTERFACE_UPDATE_SUCCESS = 'MEETING_INTERFACE_UPDATE_SUCCESS'
export const MEETING_INTERFACE_UPDATE_FAIL = 'MEETING_INTERFACE_UPDATE_FAIL'
export const MEETING_INTERFACE_UPDATE_RESET = 'MEETING_INTERFACE_UPDATE_RESET'

export const MEETING_INTERFACE_DELETE_REQUEST = 'MEETING_INTERFACE_DELETE_REQUEST'
export const MEETING_INTERFACE_DELETE_SUCCESS = 'MEETING_INTERFACE_DELETE_SUCCESS'
export const MEETING_INTERFACE_DELETE_FAIL = 'MEETING_INTERFACE_DELETE_FAIL'
export const MEETING_INTERFACE_DELETE_RESET = 'MEETING_INTERFACE_DELETE_RESET'
