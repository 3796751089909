export default function ErrorPage() {
  return (
    <>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <img
          style={{ width: "auto", height: "100vh", margin: "auto" }}
          src={"assets/img/404Page_Not_Found.jpg"}
          alt="404 Page Not Found"
        />
        <div style={{ position: "fixed", bottom: "20%", right: "30%" }}>
          <button
            style={{
              padding: "4px 10px",
              borderRadius: "3px",
              boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
              border: "none",
            }}
            onClick={() => (window.location.href = "/")}
          >
            GO BACK HOME
          </button>
        </div>
      </div>
    </>
  );
}
