
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { updatePayment } from "../../../Actions/PaymentAction";
import { toastOptions } from "../../../Models/ToastEditiorOption";
import { UPDATE_PAYMENT_DETAILS_RESET } from "../../../Constants/PaymentUpdate";

const CartModel = {
  orderId: "",
  paymentMethod: "",
  Comments: "",
};

export default function UpdatePayment({
  createSec,
  setcreateSec,
  Get_Order_Cart_Det,
  orderId,
  setOrderId,
}) {
  const [CartData, setCartData] = useState(CartModel);

  const handleCancelCart = () => {
    setcreateSec("");
    setCartData(CartModel);
    dispatch({ type: UPDATE_PAYMENT_DETAILS_RESET });
    setOrderId("");
  };

  const dispatch = useDispatch();
  const { loading, success, error } = useSelector(
    (s) => s.updatePaymentReducer
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    let Error = "";
    if (CartData?.paymentMethod === "") {
      Error += "Payment Methode Required";
    }

    if (CartData?.Comments === "") {
      Error += " Comments required !";
    }

    if (Error == "") {
      dispatch(updatePayment({ ...CartData, orderId }));
    } else {
      toast.error("Error - " + Error, toastOptions);
    }
  };

  const handleChange = (e) => {
    if (e.target.name === "ClassMode" && e.target.value == 1) {
      setCartData({
        ...CartData,
        [e.target.name]: e.target.value,
        classLocation: null,
      });
    } else {
      setCartData({ ...CartData, [e.target.name]: e.target.value });
    }
  };

  useEffect(() => {
    if (success) {
      toast.success(success, toastOptions);
      Get_Order_Cart_Det();
      setcreateSec("");
      setTimeout(() => {
        handleCancelCart();
      }, 3000);
    }

    if (error) {
      toast.error(error, toastOptions);
    }
  }, [success, error]);

  return (
    <>
      <div
        id="CreatContact"
        className={
          createSec === "Update Payment" ? "overlay-note w-100" : "overlay-note"
        }
      >
        <div className="overlay-add-deals contact-right-side">
          <div className="note-title">
            <h4 className="text-left text-white pl-3">Update Payment</h4>
            <a
              className="closebtn text-right text-white"
              onClick={() => handleCancelCart()}
            >
              ×
            </a>
          </div>

          <div
            className="contact-body modify-create-contact"
            style={{ height: "92vh" }}
          >
            <div className="form-group ">
              <label for="paymentMethod">
                Payment Methode:<b>*</b>
              </label>
              <select
                id="paymentMethod"
                name="paymentMethod"
                value={CartData?.paymentMethod}
                onChange={handleChange}
                className="form-control"
              >
                <option value="">--Select Package --</option>
                <option value="Cash">Cash</option>
                <option value="UPI QR">UPI QR</option>
                <option value="Bank Transfer">Bank Transfer</option>
              </select>
            </div>

            <div className="form-group ">
              <label>
                Comments:<b>*</b>
              </label>
              <input
                name="Comments"
                value={CartData?.Comments}
                onChange={handleChange}
                className="form-control"
              />
            </div>

            {/* <div
              className="contact-footer w-100"
              style={{
                padding: "50px 25px",
                backgroundColor: "transparent",
              }}
            >
              <div>
                <span className="" style={{ color: "green", fontSize: "13px" }}>
                  {success}
                </span>
                <span className="" style={{ color: "red", fontSize: "13px" }}>
                  {error}
                </span>
              </div>
            </div> */}

            <div className="contact-footer w-100">
              <button type="button" className="btn" onClick={handleSubmit}>
                {loading ? "Loading..." : "Add"}
              </button>

              {!loading ? (
                <button
                  type="button"
                  className="btn btn-deep-orange waves-effect waves-light"
                  onClick={() => handleCancelCart()}
                >
                  Cancel
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
