import {
  CALL_OUTCOME_DELETE_FAIL,
  CALL_OUTCOME_DELETE_REQUEST,
  CALL_OUTCOME_DELETE_SUCCESS,
  CALL_OUTCOME_INSERT_FAIL,
  CALL_OUTCOME_INSERT_REQUEST,
  CALL_OUTCOME_INSERT_SUCCESS,
  CALL_OUTCOME_LIST_FAIL,
  CALL_OUTCOME_LIST_REQUEST,
  CALL_OUTCOME_LIST_SUCCESS,
  CALL_OUTCOME_UPDATE_FAIL,
  CALL_OUTCOME_UPDATE_REQUEST,
  CALL_OUTCOME_UPDATE_SUCCESS,
} from "../Constants/callOutcomeConstants";
import api from "../core/api";

export const listCallOutcome = () => async (dispatch) => {
  try {
    dispatch({ type: CALL_OUTCOME_LIST_REQUEST });
    const { data } = await api.get(`/calloutcome`);
    dispatch({
      type: CALL_OUTCOME_LIST_SUCCESS,
      payload: data.Result,
    });
  } catch (error) {
    dispatch({
      type: CALL_OUTCOME_LIST_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const saveCallOutcomeDetails =
  (calloutcomeJson) => async (dispatch) => {
    try {
      dispatch({ type: CALL_OUTCOME_INSERT_REQUEST });
      const { data } = await api.post(`/calloutcome`, calloutcomeJson);
      dispatch({
        type: CALL_OUTCOME_INSERT_SUCCESS,
        payload: data.Result,
      });
    } catch (error) {
      dispatch({
        type: CALL_OUTCOME_INSERT_FAIL,
        payload:
          error.response && error.response.data.Result
            ? error.response.data.Result
            : error.message,
      });
    }
  };

export const updateCallOutcomeDetails =
  (id, calloutcomejson) => async (dispatch) => {
    try {
      dispatch({ type: CALL_OUTCOME_UPDATE_REQUEST });
      const { data } = await api.put(`/calloutcome/${id}`, calloutcomejson);
      dispatch({
        type: CALL_OUTCOME_UPDATE_SUCCESS,
        payload: data.Result,
      });
    } catch (error) {
      dispatch({
        type: CALL_OUTCOME_UPDATE_FAIL,
        payload:
          error.response && error.response.data.Result
            ? error.response.data.Result
            : error.message,
      });
    }
  };

export const deleteCallOutcomeDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: CALL_OUTCOME_DELETE_REQUEST });
    const { data } = await api.delete(`/calloutcome/${id}`);
    dispatch({
      type: CALL_OUTCOME_DELETE_SUCCESS,
      payload: data.Result,
    });
  } catch (error) {
    dispatch({
      type: CALL_OUTCOME_DELETE_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};
