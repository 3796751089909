import React, { useCallback, useEffect, useState } from "react";
import UserDropdown from "../Components/UserDropdown";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { contactJson } from "../../Models/ContactModels";
import { getContactDetails, updateContact } from "../../Actions/contactActions";
import Helmet from "react-helmet";
import GenderDropdown from "../Components/GenderDropdown";
import { CONTACT_UPDATE_RESET } from "../../Constants/contactConstants";
import NoteLogs from "../Components/SubSections/NoteLogs";
import NoteInsert from "../Components/InsertPopup/NoteInsert";
import CallLog from "../Components/SubSections/CallLog";
import EmailLog from "../Components/SubSections/EmailLog";
import TaskInsert from "../Components/InsertPopup/TaskInsert";
import EmailInsert from "../Components/InsertPopup/EmailInsert";
import CallInsert from "../Components/InsertPopup/CallInsert";
import TaskLog from "../Components/SubSections/TaskLog";
import ActivityList from "../Components/ActivityList";
import { toast, ToastContainer } from "react-toastify";
import { toastOptions } from "../../Models/ToastEditiorOption";
import SidePanel from "../../Components/SidePanel";
import TopBar from "../../Components/TopBar";
import Cart from "../Components/Cart";

import {
  listCartRecByContact,
  placeOrder,
  removeCartDet,
} from "../../Actions/cartAction";

import { Get_Package_Type } from "../../Models/PackageModel";
import { listOrderRecByContact } from "../../Actions/orderAction";
import { MdDeleteOutline } from "react-icons/md";
import DeletePopup from "../Components/DeletePoup";
import { CART_PLACE_ORDER_RESET } from "../../Constants/cartConstant";
import { PsychometricResult } from "../../Actions/PsychometricQuestionAction";
import UpdatePayment from "../Components/InsertPopup/UpdatePayment";
import SEDLoading from "../../Components/SEDLoading";

export default function Edit() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [editNote, setEditNote] = useState(false);
  const [emailEdit, setEmailEdit] = useState(false);
  const [CallEdit, setCallEdit] = useState(false);
  const [editTask, setEditTask] = useState(false);
  // const [userInfo, setUserInfo] = useState("");
  const [orderId, setOrderId] = useState("");
  const [contactData, setContactData] = useState(contactJson);
  const [contactSec, setContactSec] = useState("");
  const [tabsSec, settabsSec] = useState("activity");
  const [activityPopups, setactivityPopups] = useState("");
  const [createSec, setcreateSec] = useState("f");
  const [drpdown, setdrpdown] = useState("");
  const [RightSideOption1, setRightSideOption1] = useState("");
  const { loading, contactDet } = useSelector((s) => s.contactDetail);
  const [OpenNext, setOpenNext] = useState(false);
  const [DeleteAlert, setDeleteAlert] = useState({ msg: "", Delete: () => "" });

  const {
    loading: loadingOrderS,
    success: successOrderS,
    error: errorOrderS,
  } = useSelector((s) => s.placeOrderReducer);
  const Navigate = useNavigate();
  const {
    // loading: loadingCdel,
    success: successCdel,
    error: errorCdel,
  } = useSelector((s) => s.removeCartDetReducer);

  const { TestResult, error: errorResult } = useSelector(
    (s) => s.PsychometricResultReducer
  );

  const {
    loading: loadingsv,
    error: errorsv,
    success: successsv,
  } = useSelector((s) => s.applicationCreate);

  const {
    // loading: loadingup,
    error: errorup,
    success: successup,
  } = useSelector((s) => s.contactUpdate);

  const { cartRecs, error: errorCartRec } = useSelector(
    (state) => state.listCartRducer
  );

  const { orderRecs, error: errorOrderRecs } = useSelector(
    (s) => s.listOrderRducer
  );

  // useEffect(() => {
  //   const authToken = JSON.parse(localStorage.getItem("_Tsset"));
  //   setUserInfo(authToken ? authToken?._id : "");
  // }, []);

  const Get_Order_Cart_Det = useCallback(() => {
    if (id) {
      dispatch(listCartRecByContact(id));
      dispatch(listOrderRecByContact(id));
    }
  }, [dispatch, id, successOrderS]);

  useEffect(() => {
    Get_Order_Cart_Det();
  }, [Get_Order_Cart_Det, successCdel]);

  const getContactDet = useCallback(() => {
    if (id) {
      dispatch(getContactDetails(id));
      dispatch(PsychometricResult(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (!contactDet) {
      getContactDet();
    }
    setContactData(contactDet);
  }, [contactDet, getContactDet]);

  const handleChange = (e) => {
    e.preventDefault();
    setContactData({ ...contactData, [e.target.name]: e.target.value });
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    dispatch(updateContact(id, contactData));
  };

  const handleCancel = (e) => {
    e.preventDefault();
    setTimeout(() => {
      dispatch(getContactDet());
    }, 1000);
  };

  useEffect(() => {
    if (successup) {
      setTimeout(() => {
        dispatch({ type: CONTACT_UPDATE_RESET });
      }, 2000);
    }
  }, [successup, dispatch]);

  // shortlist course

  useEffect(() => {
    if (successOrderS) {
      toast.success(successOrderS, toastOptions);
      dispatch({ type: CART_PLACE_ORDER_RESET });
    }
    if (errorOrderS) {
      toast.error(errorOrderS, toastOptions);
    }
  }, [errorOrderS, dispatch, successOrderS]);

  useEffect(() => {
    toast.dismiss();
    if (successsv) {
      toast.success(successsv, toastOptions);
      setTimeout(() => {
        setcreateSec("");
      }, 1000);
    }
    if (errorsv) {
      toast.error(errorsv, toastOptions);
    }
  }, [successsv, errorsv, dispatch]);

  // Deal create

  const Mobile_Screen = window.innerWidth;

  const handleRemoveCart = (e, id) => {
    e.preventDefault();
    dispatch(removeCartDet(id));
  };

  useEffect(() => {
    if (successCdel) {
      toast.success(successCdel, toastOptions);
    }

    if (errorCdel) {
      toast.error(errorCdel, toastOptions);
    }
  }, [successCdel, errorCdel]);

  return (
    <>
      <Helmet>
        <title>
          {`
          ${
            contactData?.First_Name
              ? contactData?.First_Name + " " + contactData?.Last_Name
              : contactData?.full_name
          }
            `}
        </title>
      </Helmet>
      <SidePanel page={"Contact"} />
      <section className="home-section">
        <TopBar Heading="Contact Details" />
        {loadingsv ? <SEDLoading /> : ""}
        <div className="home-content" style={{ paddingTop: "100px" }}>
          <div className="bs-offset-main bs-canvas-anim">
            <div className="content-body" style={{ padding: "0px" }}>
              <div className="contact-details">
                <div className="container-fluid">
                  <div className="row">
                    <div className={Mobile_Screen <= 480 ? "mblo" : "col-md-3"}>
                      <div className="left-details">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="user-icon text-center">
                              <i className="fas fa-user-circle"></i>
                            </div>
                            <div className="sticky">
                              <div className="left-user text-center py-2">
                                <h4 className="user-title-fixed">
                                  {contactData?.First_Name
                                    ? contactData?.First_Name +
                                      " " +
                                      contactData?.Last_Name
                                    : contactData?.full_name}
                                </h4>
                                {/* <p
                                  style={{
                                    marginBottom: "5px",
                                    fontSize: "14px",
                                  }}
                                >
                                  ({" "}
                                  {contactData?.QualifiesData
                                    ? contactData?.QualifiesData
                                    : "--"}
                                  ,{" "}
                                  {contactData?.EnquiryFor
                                    ? contactData?.EnquiryFor
                                    : "--"}{" "}
                                  ,
                                  {contactData?.ContactRating == "Hot" ? (
                                    <i
                                      className="fab fa-bitbucket fa-lg"
                                      style={{ color: "red" }}
                                    ></i>
                                  ) : contactData?.ContactRating == "Cold" ? (
                                    <i
                                      className="fab fa-bitbucket fa-lg"
                                      style={{ color: "orange" }}
                                    ></i>
                                  ) : contactData?.ContactRating == "Warm" ? (
                                    <i
                                      className="fab fa-bitbucket fa-lg"
                                      style={{ color: "skyblue" }}
                                    ></i>
                                  ) : (
                                    "NA"
                                  )}
                                  )
                                </p> */}
                              </div>
                            </div>

                            <div className="user-control">
                              <ul>
                                <li data-toggle="tooltip" title="Create a note">
                                  <Link
                                    onClick={(e) => setactivityPopups("note")}
                                    // onClick="toggle_visibility('note-popup') , maximizeNote();"
                                  >
                                    <i className="fas fa-edit"></i> Note
                                  </Link>
                                </li>

                                <li
                                  data-toggle="tooltip"
                                  title="Create an email"
                                >
                                  <Link
                                    // onClick="toggle_visibility('email-popup') , maximizeEmail();"
                                    onClick={(e) => setactivityPopups("Email")}
                                  >
                                    <i className="far fa-envelope"></i> Email
                                  </Link>
                                </li>

                                <li
                                  className="nav-item"
                                  data-toggle="tooltip"
                                  title="Make a phone call"
                                >
                                  <Link
                                    className="nav-link"
                                    data-toggle="dropdown"
                                    style={{ padding: "0px" }}
                                    onClick={(e) =>
                                      setdrpdown(
                                        drpdown === "Call" ? "" : "Call"
                                      )
                                    }
                                  >
                                    <i className="fas fa-phone"></i> Call
                                  </Link>
                                  <div
                                    className={
                                      drpdown === "Call"
                                        ? "dropdown-menu show showdrpdown"
                                        : "dropdown-menu"
                                    }
                                  >
                                    <Link className="dropdown-item btn-deep-orange text-white">
                                      Add your phone number
                                    </Link>
                                  </div>
                                </li>

                                <li
                                  className="nav-item"
                                  data-toggle="tooltip"
                                  title="Log a call, email, or meeting"
                                >
                                  <Link
                                    className="nav-link"
                                    data-toggle="dropdown"
                                    style={{ padding: "0px" }}
                                    onClick={(e) => setdrpdown("Log")}
                                  >
                                    <i className="fas fa-plus"></i> Log
                                  </Link>
                                  <div
                                    className={
                                      drpdown === "Log"
                                        ? "dropdown-menu show showdrpdown"
                                        : "dropdown-menu"
                                    }
                                  >
                                    <Link
                                      className="dropdown-item"
                                      onClick={(e) => {
                                        setactivityPopups("Call");
                                        setdrpdown("");
                                      }}
                                    >
                                      Log a call
                                    </Link>
                                    <Link
                                      className="dropdown-item"
                                      onClick={(e) => {
                                        setactivityPopups("Email");
                                        setdrpdown("");
                                      }}
                                    >
                                      Log an email
                                    </Link>
                                    <Link
                                      className="dropdown-item"
                                      onClick={(e) => {
                                        setactivityPopups("Meeting");
                                        setdrpdown("");
                                      }}
                                    >
                                      Log a meeting
                                    </Link>
                                  </div>
                                </li>

                                <li data-toggle="tooltip" title="Create a task">
                                  <Link
                                    onClick={(e) => setactivityPopups("Task")}
                                  >
                                    <i className="fas fa-thumbtack"></i> Task
                                  </Link>
                                </li>

                                <li
                                  data-toggle="tooltip"
                                  title="Schedule a meeting"
                                >
                                  <Link
                                    onClick={(e) =>
                                      setactivityPopups("Meeting")
                                    }
                                  >
                                    <i className="far fa-handshake"></i> Meet
                                  </Link>
                                </li>
                              </ul>
                            </div>

                            <hr />

                            <div className="about-contact">
                              <div className="modify-contact-filter about-contact-body">
                                <div className="accordion" id="QueriesSection">
                                  {/* <div className="card1">
                                    <div
                                      className="card-header"
                                      id="headingOne"
                                    >
                                      <h5 className="mb-0">
                                        <i className="fas fa-ellipsis-v more-dot"></i>
                                        <button
                                          type="button"
                                          className={
                                            contactSec === "Classification"
                                              ? "btn btn-link"
                                              : "btn btn-link collapsed"
                                          }
                                          style={{ color: "#0c3eff" }}
                                          onClick={(e) =>
                                            setContactSec("Classification")
                                          }
                                        >
                                          <i
                                            className={
                                              contactSec === "Classification"
                                                ? "fa fa-angle-down"
                                                : "fa fa-angle-right"
                                            }
                                          ></i>
                                          Classification
                                        </button>
                                      </h5>
                                    </div>
                                    <div
                                      id="collapseOne"
                                      className={
                                        contactSec === "Classification"
                                          ? "collapse show"
                                          : "collapse"
                                      }
                                      aria-labelledby="headingOne"
                                      data-parent="#QueriesSection"
                                    >
                                      <div className="card-body">
                                        <div className="form-group">
                                          <label>Enquiry for:</label>
                                        
                                          <select
                                            className="form-control"
                                            name="Enquiry_For"
                                            value={
                                              contactData?.Enquiry_For
                                                ? contactData?.Enquiry_For
                                                : ""
                                            }
                                            onChange={handleChange}
                                          >
                                            <option value="">--Select--</option>
                                            <EnquiryForDropdown />
                                          </select>
                                        </div>

                                        <div className="form-group">
                                          <label>Lead source:</label>
                                          <i className="fas fa-pencil-alt"></i>
                                          <select
                                            className="form-control"
                                            name="Lead_Source"
                                            value={
                                              contactData?.Lead_Source
                                                ? contactData?.Lead_Source
                                                : ""
                                            }
                                            onChange={handleChange}
                                          >
                                            <option value="">--Select--</option>
                                            <LeadSourceDropdown />
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                  </div> */}

                                  <div className="card1">
                                    <div
                                      className="card-header"
                                      id="headingTwo"
                                    >
                                      <h5 className="mb-0">
                                        <i className="fas fa-ellipsis-v more-dot"></i>
                                        <button
                                          type="button"
                                          className={
                                            contactSec == "BasicInfo"
                                              ? "btn btn-link"
                                              : "btn btn-link "
                                          }
                                          onClick={(e) =>
                                            setContactSec("BasicInfo")
                                          }
                                          style={{ color: "#0c3eff" }}
                                        >
                                          <i
                                            className={
                                              contactSec == "BasicInfo"
                                                ? "fa fa-angle-right"
                                                : "fa fa-angle-right"
                                            }
                                          ></i>{" "}
                                          Contact Details
                                        </button>
                                      </h5>
                                    </div>
                                    <div
                                      id="collapseTwo"
                                      className={
                                        contactSec == "BasicInfo"
                                          ? "collapse show"
                                          : "collapse show"
                                      }
                                      aria-labelledby="headingTwo"
                                      data-parent="#QueriesSection"
                                    >
                                      <div className="card-body modify-create-contact">
                                        <div className="form-group">
                                          <label>First Name:</label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter First Name"
                                            name="First_Name"
                                            value={contactData?.First_Name}
                                            onChange={handleChange}
                                          />
                                          <i className="fas fa-pencil-alt"></i>
                                        </div>

                                        <div className="form-group">
                                          <label>Last Name:</label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Last Name"
                                            name="Last_Name"
                                            value={contactData?.Last_Name}
                                            onChange={handleChange}
                                          />
                                          <i className="fas fa-pencil-alt"></i>
                                        </div>

                                        <div className="form-group first-input">
                                          <label>
                                            Telephone:<b>*</b>
                                          </label>

                                          <input
                                            type="text"
                                            name="PhoneNo"
                                            value={contactData?.PhoneNo}
                                            onChange={handleChange}
                                            className="form-control"
                                            placeholder="Enter Phone number"
                                          />
                                        </div>

                                        {/* <div className="form-group third-input">
                                          <button
                                            className="third-btn-minus"
                                            value="-"
                                          >
                                            -
                                          </button>
                                          <input
                                            type="text"
                                            name="Phone_No2"
                                            value={contactData?.Phone_No2}
                                            onChange={handleChange}
                                            className="form-control"
                                            placeholder="Enter Phone number"
                                          />
                                        </div> */}

                                        <div className="form-group first-input-email">
                                          <label>
                                            Email:<b>*</b>
                                          </label>

                                          <input
                                            type="email"
                                            name="Email_Address"
                                            value={contactData?.EmailAddress}
                                            onChange={handleChange}
                                            className="form-control"
                                            placeholder="Enter email"
                                          />
                                        </div>
                                        {/* <div className="form-group Second-input-email">
                                          <button
                                            className="second-btn-plus-email"
                                            value="+"
                                          >
                                            +
                                          </button>

                                          <button
                                            className="second-btn-minus-email"
                                            value="-"
                                          >
                                            -
                                          </button>
                                          <input
                                            type="email"
                                            name="Email_Address1"
                                            value={contactData?.Email_Address1}
                                            onChange={handleChange}
                                            className="form-control"
                                            placeholder="Enter email"
                                          />
                                        </div>
                                        <div className="form-group third-input-email">
                                          <button
                                            className="third-btn-minus-email"
                                            value="-"
                                          >
                                            -
                                          </button>
                                          <input
                                            type="email"
                                            name="Email_Address2"
                                            value={contactData?.Email_Address2}
                                            onChange={handleChange}
                                            className="form-control"
                                            placeholder="Enter email"
                                          />
                                        </div> */}

                                        {/* <div className="form-group">
                                          <label>Date Of Birth:</label>

                                          <div className="myDatePickerContainer">
                                            <ReactDatePicker
                                              onKeyDown={(e) => {
                                                e.preventDefault();
                                              }}
                                              peekNextMonth
                                              showMonthDropdown
                                              showYearDropdown
                                              yearDropdownItemNumber={100}
                                              scrollableYearDropdown
                                              dropdownMode="scroll"
                                              dateFormat="dd-MM-yyyy"
                                              className="myDatePicker2"
                                              selected={
                                                contactData?.Date_Of_Birth
                                                  ? new Date(
                                                      contactData?.Date_Of_Birth
                                                    )
                                                  : contactData?.Date_Of_Birth
                                              }
                                              onChange={(date) =>
                                                setContactData({
                                                  ...contactData,
                                                  Date_Of_Birth: date,
                                                })
                                              }
                                              placeholderText="dd/mm/yy"
                                            />
                                          </div>
                                        </div> */}

                                        <div className="form-group">
                                          <label>Gender:</label>
                                          <i className="fas fa-pencil-alt"></i>
                                          <select
                                            className="form-control"
                                            name="Gender"
                                            value={
                                              contactData?.Gender
                                                ? contactData?.Gender
                                                : ""
                                            }
                                            onChange={handleChange}
                                          >
                                            <option value="">--Select--</option>
                                            <GenderDropdown />
                                          </select>
                                        </div>

                                        <div className="form-group">
                                          <label>Contact Owner:</label>
                                          <i className="fas fa-pencil-alt"></i>
                                          <select
                                            className="form-control"
                                            name="ContactOwner"
                                            value={
                                              contactData?.ContactOwner
                                                ? contactData?.ContactOwner
                                                : ""
                                            }
                                            onChange={handleChange}
                                          >
                                            <option value="">--Select--</option>
                                            <UserDropdown />
                                          </select>
                                        </div>

                                        <div className="form-group">
                                          <label>City:</label>
                                          <i className="fas fa-pencil-alt"></i>
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter City"
                                            name="City"
                                            value={contactData?.City}
                                            onChange={handleChange}
                                          />
                                        </div>

                                        <div className="form-group">
                                          <label>State:</label>
                                          <i className="fas fa-pencil-alt"></i>
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter State"
                                            name="State"
                                            value={contactData?.State}
                                            onChange={handleChange}
                                          />
                                        </div>

                                        {/* <div className="form-group">
                                          <label>Country:</label>
                                          <i className="fas fa-pencil-alt"></i>
                                          <select
                                            className="form-control"
                                            name="Country_Id"
                                            value={
                                              contactData?.Country_Id
                                                ? contactData?.Country_Id
                                                : ""
                                            }
                                            onChange={handleChange}
                                          >
                                            <option value="">--Select--</option>
                                            <CountryDropdown />
                                          </select>
                                        </div> */}

                                        {/* <div className="form-group">
                                          <label>
                                            How did you hear about us:
                                          </label>
                                          <i className="fas fa-pencil-alt"></i>
                                          <select
                                            className="form-control"
                                            name="How_you_heard_About_Us"
                                            value={
                                              contactData?.How_you_heard_About_Us
                                                ? contactData?.How_you_heard_About_Us
                                                : ""
                                            }
                                            onChange={handleChange}
                                          >
                                            <option value="">--Select--</option>
                                            <HowYouHeardAboutUsDropdown />
                                          </select>
                                        </div> */}
                                      </div>
                                    </div>
                                  </div>

                                  {/* <div className="card1">
                                    <div
                                      className="card-header"
                                      id="headingThree"
                                    >
                                      <h5 className="mb-0">
                                        <i className="fas fa-ellipsis-v more-dot"></i>
                                        <button
                                          type="button"
                                          className={
                                            contactSec === "Identification"
                                              ? "btn btn-link"
                                              : "btn btn-link collapsed"
                                          }
                                          onClick={(e) =>
                                            setContactSec("Identification")
                                          }
                                          style={{ color: "#0c3eff" }}
                                        >
                                          <i
                                            className={
                                              contactSec == "Identification"
                                                ? "fa fa-angle-down"
                                                : "fa fa-angle-right"
                                            }
                                          ></i>{" "}
                                          Identification
                                        </button>
                                      </h5>
                                    </div>
                                    <div
                                      id="collapseThree"
                                      className={
                                        contactSec === "Identification"
                                          ? "collapse show"
                                          : "collapse"
                                      }
                                      aria-labelledby="headingThree"
                                      data-parent="#QueriesSection"
                                    >
                                      <div className="card-body">
                                        <strong>Educational history</strong>

                                        <div className="form-group">
                                          <label>Educational status:</label>
                                          <i className="fas fa-pencil-alt"></i>
                                          <select
                                            className="form-control"
                                            name="Educational_Status"
                                            value={
                                              contactData?.Educational_Status
                                                ? contactData?.Educational_Status
                                                : ""
                                            }
                                            onChange={handleChange}
                                          >
                                            <option value="">--Select--</option>
                                            <EducationalStatusDropdown />
                                          </select>
                                        </div>
                                        <div className="form-group">
                                          <label>Month of completion:</label>
                                          <i className="fas fa-pencil-alt"></i>
                                          <select
                                            className="form-control"
                                            name="Month_Id"
                                            value={
                                              contactData?.Month_Id
                                                ? contactData?.Month_Id
                                                : ""
                                            }
                                            onChange={handleChange}
                                          >
                                            <option value="">--Select--</option>
                                            <MonthDropdown />
                                          </select>
                                        </div>
                                        {/* <div className="form-group">
                                          <label>Year of Completion:</label>
                                          <i className="fas fa-pencil-alt"></i>
                                          <select
                                            className="form-control"
                                            name="Year_Of_Completion"
                                            value={
                                              contactData?.Year_Of_Completion
                                                ? contactData?.Year_Of_Completion
                                                : ""
                                            }
                                            onChange={handleChange}
                                          >
                                            <option value="">--Select--</option>
                                            <YearOfCompletionDropdown />
                                          </select>
                                        </div> 

                                     

                                  <div className="card1">
                                    <div
                                      className="card-header"
                                      id="headingFour"
                                    >
                                      <h5 className="mb-0">
                                        <i className="fas fa-ellipsis-v more-dot"></i>
                                        <button
                                          type="button"
                                          className={
                                            contactSec === "Status"
                                              ? "btn btn-link"
                                              : "btn btn-link collapsed"
                                          }
                                          onClick={(e) =>
                                            setContactSec("Status")
                                          }
                                          style={{ color: "#0c3eff" }}
                                        >
                                          <i
                                            className={
                                              contactSec == "Status"
                                                ? "fa fa-angle-down"
                                                : "fa fa-angle-right"
                                            }
                                          ></i>{" "}
                                          Status
                                        </button>
                                      </h5>
                                    </div>
                                    <div
                                      id="collapseFour"
                                      className={
                                        contactSec == "Status"
                                          ? "collapse show"
                                          : "collapse"
                                      }
                                      aria-labelledby="headingTwo"
                                      data-parent="#QueriesSection"
                                    >
                                      <div className="card-body">
                                        <div className="form-group">
                                          <label> Meeting interface :</label>
                                          <i className="fas fa-pencil-alt"></i>
                                          <select
                                            className="form-control"
                                            value={
                                              contactData?.Counsellor_Meeting
                                                ? contactData?.Counsellor_Meeting
                                                : ""
                                            }
                                            name="Counsellor_Meeting"
                                            onChange={handleChange}
                                          >
                                            <option value="">--Select--</option>
                                            <MeetingInterfaceDropdown />
                                          </select>
                                        </div>

                                        <div className="form-group">
                                          <label>Meeting date:</label>

                                          <div className="myDatePickerContainer">
                                            <ReactDatePicker
                                              onKeyDown={(e) => {
                                                e.preventDefault();
                                              }}
                                              peekNextMonth
                                              showMonthDropdown
                                              showYearDropdown
                                              yearDropdownItemNumber={100}
                                              scrollableYearDropdown
                                              dropdownMode="scroll"
                                              dateFormat="dd-MM-yyyy"
                                              className="myDatePicker2"
                                              selected={
                                                contactData?.Meeting_Date
                                                  ? new Date(
                                                      contactData?.Meeting_Date
                                                    )
                                                  : contactData?.Meeting_Date
                                              }
                                              onChange={(date) =>
                                                setContactData({
                                                  ...contactData,
                                                  Meeting_Date: date,
                                                })
                                              }
                                              placeholderText="dd/mm/yy"
                                            />
                                          </div>
                                        </div>

                                        <div className="form-group">
                                          <label> Profile:</label>
                                          <i className="fas fa-pencil-alt"></i>
                                          <select className="form-control">
                                            <option value="">--Select--</option>
                                            <ProfileDropdown />
                                          </select>
                                        </div>

                                        <div className="form-group">
                                          <label> Qualify:</label>
                                          <i className="fas fa-pencil-alt"></i>
                                          <select
                                            className="form-control"
                                            value={
                                              contactData?.Qualifies
                                                ? contactData?.Qualifies
                                                : ""
                                            }
                                            name="Qualifies"
                                            onChange={handleChange}
                                          >
                                            <option value="">--Select--</option>
                                            <QualifiesDropdown />
                                          </select>
                                        </div>

                                        

                                        <div className="form-group">
                                          <label> Rating:</label>
                                          <i className="fas fa-pencil-alt"></i>
                                          <select
                                            className="form-control"
                                            value={
                                              contactData?.Contact_Rating
                                                ? contactData?.Contact_Rating
                                                : ""
                                            }
                                            name="Contact_Rating"
                                            onChange={handleChange}
                                          >
                                            <option value="">---</option>
                                            <ContactRatingDropdown />
                                          </select>
                                        </div>

                                        <div className="form-group">
                                          <label> Primary contact owner:</label>
                                          <i className="fas fa-pencil-alt"></i>
                                          <select
                                            className="form-control"
                                            value={
                                              contactData?.Assigned_User_Id
                                                ? contactData?.Assigned_User_Id
                                                : ""
                                            }
                                            name="Assigned_User_Id"
                                            onChange={handleChange}
                                          >
                                            <option value="">---</option>
                                            <UserDropdown />
                                          </select>
                                        </div>

                                        <div className="form-group">
                                          <label>
                                            {" "}
                                            Secondary Contact owner:
                                          </label>
                                          <i className="fas fa-pencil-alt"></i>
                                          <select
                                            className="form-control"
                                            value={
                                              contactData?.Contact_Owner_II
                                                ? contactData?.Contact_Owner_II
                                                : ""
                                            }
                                            name="Contact_Owner_II"
                                            onChange={handleChange}
                                          >
                                            <option value="">---</option>
                                            <UserDropdown />
                                          </select>
                                        </div>

                                        <div className="form-group">
                                          <label> Branch:</label>
                                          <i className="fas fa-pencil-alt"></i>
                                          <select
                                            className="form-control"
                                            value={
                                              contactData?.Branch
                                                ? contactData?.Branch
                                                : ""
                                            }
                                            name="Branch"
                                            onChange={handleChange}
                                          >
                                            <option value="">--Select--</option>
                                            <BranchDropdown />
                                          </select>
                                        </div>

                                        <div className="form-group">
                                          <label>Comments</label>
                                          <i className="fas fa-pencil-alt"></i>
                                          <textarea
                                            type="text"
                                            className="form-control"
                                            rows={3}
                                            placeholder="type something...."
                                            style={{ resize: "none" }}
                                            value={contactData?.Comments}
                                            name="Comments"
                                            onChange={handleChange}
                                          ></textarea>
                                        </div>
                                      </div>
                                    </div>
                                  </div> */}
                                </div>
                              </div>

                              <div className="sticky-footer pl-3">
                                <div className="edit-contact-footer">
                                  <button
                                    type="button"
                                    className="btn btn-sm"
                                    onClick={handleUpdate}
                                    disabled={loading}
                                  >
                                    {loading ? "Loading..." : "Update"}
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-deep-orange waves-effect waves-light"
                                    onClick={handleCancel}
                                  >
                                    Cancel
                                  </button>
                                  <span style={{ color: "green" }}>
                                    {successup}
                                  </span>
                                  <span style={{ color: "red" }}>
                                    {errorup}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Middle Section Note, activity */}
                        <div className="profile-activity">
                          <div
                            id="note-popup"
                            className={
                              activityPopups === "note"
                                ? "overlay-note-mini-max dispPopup"
                                : "overlay-note-mini-max"
                            }
                          >
                            <NoteInsert
                              contactId={id}
                              dealId={""}
                              editNote={editNote}
                              closePopup={(e) => {
                                setactivityPopups("");
                                setEditNote(false);
                              }}
                            />
                          </div>

                          <div
                            id="task-popup"
                            className={
                              activityPopups === "Task"
                                ? "overlay-note-mini-max dispPopup"
                                : "overlay-note-mini-max"
                            }
                          >
                            <TaskInsert
                              contactId={id}
                              dealId={""}
                              editTask={editTask}
                              closePopup={(e) => {
                                setactivityPopups("");
                                setEditTask(false);
                              }}
                            />
                          </div>

                          <div
                            id="email-popup"
                            className="overlay-note-mini-max"
                          >
                            {/* <EmailInsert
                          contactId={id}
                          dealId={""}
                          emailEdit={emailEdit}
                          closePopup={(e) => {
                            setactivityPopups("");
                            setEmailEdit(false);
                          }}
                        /> */}
                          </div>

                          <div
                            id="meet-popup"
                            className="overlay-note-mini-max"
                          >
                            <div className="overlay-note-content">
                              <div className="note-title">
                                <h4 className="text-left text-white pl-3">
                                  Meeting
                                </h4>
                                <div className="closebtn">
                                  <Link
                                    id="hide-meet-mini"
                                    className="text-right text-white"
                                    // onClick="minimizeMeet()"
                                  >
                                    <i className="fas fa-minus"></i>
                                  </Link>
                                  <Link
                                    id="hide-meet-max"
                                    className="text-right text-white"
                                    // onClick="maximizeMeet()"
                                  >
                                    <i className="fas fa-minus"></i>
                                  </Link>
                                  <Link
                                    className="text-right text-white"
                                    // onClick="closeMeet()"
                                  >
                                    <i className="fas fa-times"></i>
                                  </Link>
                                </div>
                              </div>
                              <div className="note-body" id="meetBody">
                                <div className="log-call">
                                  <div className="row mb-3">
                                    <div className="col-md-12">
                                      <input
                                        type="text"
                                        name="Subject"
                                        className="w-100"
                                        placeholder="What are you meeting about?"
                                      />
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-12">
                                      <textarea
                                        className="w-100"
                                        rows="5"
                                        placeholder="Describe your meeting...."
                                        style={{
                                          resize: "none",
                                          outline: "none",
                                          border: "none",
                                        }}
                                      ></textarea>
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-md-12 mt-1">
                                      <h5
                                        style={{
                                          fontSize: "12px",
                                          color: "#707070",
                                          lineHeight: "14px",
                                        }}
                                      >
                                        Schedule Meeting
                                      </h5>
                                    </div>
                                    <div className="col-md-3">
                                      <input
                                        type="text"
                                        name="task-time"
                                        className="w-100"
                                        placeholder="08:00 AM"
                                      />
                                    </div>
                                    <div className="col-md-5">
                                      <input
                                        type="text"
                                        className="datepicker W-100"
                                        name="date1"
                                        placeholder="DD/MM/YYYY"
                                      />
                                    </div>
                                    <div className="col-md-4"></div>
                                  </div>
                                </div>
                                <hr />

                                <button className="btn btn-sm btn-deep-orange waves-effect waves-light">
                                  Save
                                </button>
                              </div>
                            </div>
                          </div>

                          <div
                            id="LogCall-popup"
                            className={
                              activityPopups === "Call"
                                ? "overlay-note-mini-max dispPopup"
                                : "overlay-note-mini-max"
                            }
                          >
                            <CallInsert
                              contactId={id}
                              dealId={""}
                              callEdit={CallEdit}
                              closePopup={(e) => {
                                setactivityPopups("");
                                setCallEdit(false);
                              }}
                            />
                          </div>

                          <div
                            id="LogEmail-popup"
                            className={
                              activityPopups === "Email"
                                ? "overlay-note-mini-max dispPopup"
                                : "overlay-note-mini-max"
                            }
                          >
                            <EmailInsert
                              contactId={id}
                              dealId={""}
                              emailEdit={emailEdit}
                              closePopup={(e) => {
                                setactivityPopups("");
                                setEmailEdit(false);
                              }}
                            />
                          </div>

                          <div
                            id="LogMeeting-popup"
                            className={"overlay-note-mini-max"}
                            // className={
                            //   activityPopups == "Meeting"
                            //     ? "overlay-note-mini-max dispPopup"
                            //     : "overlay-note-mini-max"
                            // }
                          >
                            <div className="overlay-note-content">
                              <div className="note-title">
                                <h4 className="text-left text-white pl-3">
                                  Log Meeting
                                </h4>
                                <div className="closebtn">
                                  <Link
                                    id="hide-LogMeeting-mini"
                                    className="text-right text-white"
                                  >
                                    {/* <i className="fas fa-minus"></i> */}
                                  </Link>
                                  <Link
                                    id="hide-LogMeeting-max"
                                    className="text-right text-white"
                                  >
                                    {/* <i className="fas fa-minus"></i> */}
                                  </Link>
                                  <Link
                                    className="text-right text-white"
                                    onClick={(e) => setactivityPopups("")}
                                  >
                                    <i className="fas fa-times"></i>
                                  </Link>
                                </div>
                              </div>

                              <div className="note-body" id="LogMeetingBody">
                                <div className="log-call">
                                  <div className="row">
                                    <div
                                      className="col-md-3"
                                      style={{ padding: "2px" }}
                                    >
                                      <span>Attendees</span>
                                      <div className="form-group">
                                        <select className="form-control w-100">
                                          <option value="">Adnan Ad</option>
                                          <option value="">--</option>
                                        </select>
                                      </div>
                                    </div>
                                    <div
                                      className="col-md-4"
                                      style={{ padding: "2px" }}
                                    >
                                      <span>Outcome</span>
                                      <div className="form-group">
                                        <select className="form-control w-100">
                                          <option value="">None</option>
                                          <option value="">Scheduled</option>
                                          <option value="">Completed</option>
                                          <option value="">Rescheduled</option>
                                          <option value="">No Show</option>
                                          <option value="">Canceled</option>
                                        </select>
                                      </div>
                                    </div>
                                    <div
                                      className="col-md-3"
                                      style={{ padding: "2px" }}
                                    >
                                      <span>Date</span>
                                      <input
                                        type="text"
                                        className="datepicker w-100"
                                        name="date5"
                                        placeholder="DD/MM/YYYY"
                                      />
                                    </div>
                                    <div
                                      className="col-md-2"
                                      style={{ padding: "2px" }}
                                    >
                                      <span>Time</span>
                                      <input
                                        type="text"
                                        className="w-100"
                                        name="task-time"
                                        placeholder="08:00 AM"
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="log-call">
                                  <div className="row mt-2">
                                    <div
                                      className="col-md-12"
                                      style={{ padding: "2px" }}
                                    >
                                      <textarea
                                        className="w-100"
                                        rows="7"
                                        placeholder="Describe the meeting...."
                                        style={{
                                          resize: "none",
                                          outline: "none",
                                          border: "none",
                                        }}
                                      ></textarea>
                                    </div>
                                  </div>
                                </div>

                                <hr />
                                <div className="log-call-footer">
                                  <button className="btn btn-sm btn-deep-orange waves-effect waves-light">
                                    Log activity
                                  </button>
                                  <div className="custom-control custom-checkbox mt-1 ml-1">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      id="logmeetingFollowUp"
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor="logmeetingFollowUp"
                                    >
                                      Create a task to follow up
                                    </label>
                                  </div>
                                  <div className="form-group mt-3 logmeetingFollow">
                                    <select className="form-control">
                                      <option value="">
                                        in 3 business days (Friday)
                                      </option>
                                      <option value="">--</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* <div id="Add-deals-popup" className="overlay-note">
                            <div className="overlay-add-deals">
                              <div className="note-title">
                                <h4 className="text-left text-white pl-3">
                                  Add deals to this contact
                                </h4>
                                <Link
                                  className="closebtn text-right text-white"
                                  // onClick="closeAddDeals()"
                                >
                                  &times;
                                </Link>
                              </div>
                              <div className="deals-input">
                                <p>
                                  Track revenue opportunities for your contact
                                  by associating a deal.
                                </p>
                                <div className="row">
                                  <div className="col-md-12">
                                    <nav>
                                      <div className="nav nav-tabs">
                                        <Link
                                          className="nav-item nav-link active"
                                          data-toggle="tab"
                                          to="#create-deal"
                                        >
                                          Create a new deal
                                        </Link>
                                        <Link
                                          className="nav-item nav-link"
                                          data-toggle="tab"
                                          to="#add-exist-deal"
                                        >
                                          Add existing deal
                                        </Link>
                                      </div>
                                    </nav>
                                    <div className="tab-content">
                                      <div
                                        className="tab-pane fade show active pt-4"
                                        id="create-deal"
                                      >
                                        <form>
                                          <div className="form-group">
                                            <label>Deal name *</label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder="Enter Deal name"
                                            />
                                          </div>

                                          <div className="form-group">
                                            <label>Pipeline *</label>
                                            <select className="form-control">
                                              <option value="">
                                                Student Visa Pipeline
                                              </option>
                                              <option value="">
                                                Change Coll/Uni Pipeline
                                              </option>
                                              <option value="">
                                                IELTS {">"} Student Visa
                                                Pipeline
                                              </option>
                                              <option value="">
                                                Permanent Residency Pipeline
                                              </option>
                                              <option value="">
                                                Visa Estudiante
                                              </option>
                                              <option value="">
                                                Student Visa Extension
                                              </option>
                                              <option value="">
                                                Tourist Visa / Bridging Visa
                                              </option>
                                              <option value="">485 Visa</option>
                                              <option value="">
                                                Bridging Visa
                                              </option>
                                            </select>
                                          </div>

                                          <div className="form-group">
                                            <label>Deal stage *</label>
                                            <select className="form-control">
                                              <option value="">
                                                First Consultation
                                              </option>
                                              <option value="">---</option>
                                            </select>
                                          </div>
                                          <div className="form-group">
                                            <label>Amount</label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder="   "
                                            />
                                          </div>
                                          <div className="form-group">
                                            <label>IELTS Class Time</label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder="   "
                                            />
                                          </div>
                                          <div className="form-group">
                                            <label>IELTS Amount Paid</label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder="   "
                                            />
                                          </div>
                                          <div className="form-group">
                                            <label>IELTS Enrolled Date</label>
                                            <input
                                              type="text"
                                              className="datepicker form-control"
                                              name="date6"
                                              placeholder="DD/MM/YYYY"
                                            />
                                          </div>
                                          <div className="form-group">
                                            <label>IELTS Finish Date</label>
                                            <input
                                              type="text"
                                              className="datepicker form-control"
                                              name="date7"
                                              placeholder="DD/MM/YYYY"
                                            />
                                          </div>

                                          <hr />
                                          <h5>Associate deal with</h5>
                                          <div className="form-group">
                                            <label>Company</label>
                                            <select className="form-control">
                                              <option value="">
                                                Search companies
                                              </option>
                                              <option value="">----</option>
                                            </select>
                                          </div>
                                          <div className="form-group">
                                            <label>Contact</label>
                                            <select className="form-control">
                                              <option value="">email</option>
                                              <option value="">----</option>
                                            </select>
                                          </div>
                                          <div className="checkDeal d-flex pb-5">
                                            <div className="custom-control custom-checkbox mt-3 ml-3">
                                              <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id="timelineDeals"
                                              />
                                              <label
                                                className="custom-control-label"
                                                htmlFor="timelineDeals"
                                              >
                                                Add timeline activity from adnan
                                                ad to this deal
                                              </label>
                                            </div>
                                            <div className="form-group mt-2 timelineActiveDeals">
                                              <select
                                                className="form-control"
                                                style={{
                                                  border: "none",
                                                  color: "#0c3eff",
                                                  fontWeight: "600",
                                                }}
                                              >
                                                <option value="">
                                                  Last 30 days
                                                </option>
                                                <option value="">
                                                  Last 60 days
                                                </option>
                                                <option value="">
                                                  Last 90 days
                                                </option>
                                                <option value="">
                                                  All time
                                                </option>
                                                <option value="">
                                                  Custom date
                                                </option>
                                              </select>
                                            </div>
                                          </div>

                                          <div className="contact-footer w-100">
                                            <button
                                              type="button"
                                              className="btn"
                                            >
                                              Create
                                            </button>
                                            <button
                                              type="button"
                                              className="btn"
                                            >
                                              Create and add another
                                            </button>
                                            <button
                                              type="button"
                                              // onClick="closeAddDeals()"
                                              className="btn btn-outline-deep-orange waves-effect waves-light"
                                            >
                                              Cancel
                                            </button>
                                          </div>
                                        </form>
                                      </div>
                                      <div
                                        className="tab-pane fade pt-5"
                                        id="add-exist-deal"
                                      >
                                        <div className="input-group">
                                          <input
                                            type="Search"
                                            className="form-control"
                                            placeholder="Search"
                                            name="search"
                                          />
                                          <i
                                            className="fas fa-search"
                                            style={{
                                              backgroundColor: "#0c3eff",
                                              color: "#fff",
                                              lineHeight: "30px",
                                              padding: "0px 20px",
                                              cursor: "pointer",
                                            }}
                                          ></i>
                                        </div>
                                        <div className="contact-footer">
                                          <button type="button" className="btn">
                                            Save
                                          </button>
                                          <button
                                            type="button"
                                            // onClick="closeAddDeals()"
                                            className="btn btn-outline-deep-orange waves-effect waves-light"
                                          >
                                            Cancel
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>

                    <div className={Mobile_Screen <= 480 ? "mblo" : "col-md-6"}>
                      <div className="center-details icons-title">
                        {/* Top Header Section note,call,email,task */}
                        <div className="center-details-nav">
                          <ul
                            className="nav nav-tabs"
                            id="myTab"
                            role="tablist"
                          >
                            <li className="nav-item">
                              <Link
                                className={
                                  tabsSec === "activity"
                                    ? "nav-link active"
                                    : "nav-link"
                                }
                                id="activity-tab"
                                data-toggle="tab"
                                role="tab"
                                aria-controls="activity"
                                aria-selected="true"
                                title="Activity"
                                onClick={(e) => settabsSec("activity")}
                              >
                                <i
                                  className="fa fa-desktop"
                                  aria-hidden="true"
                                ></i>
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                className={
                                  tabsSec === "note"
                                    ? "nav-link active"
                                    : "nav-link"
                                }
                                id="notes-tab"
                                data-toggle="tab"
                                role="tab"
                                aria-controls="notes"
                                aria-selected="false"
                                title=" Notes"
                                onClick={(e) => settabsSec("note")}
                              >
                                <i
                                  className="fa fa-sticky-note"
                                  aria-hidden="true"
                                ></i>
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                className={
                                  tabsSec === "email"
                                    ? "nav-link active"
                                    : "nav-link"
                                }
                                id="contact-tab"
                                data-toggle="tab"
                                role="tab"
                                aria-controls="contact"
                                aria-selected="false"
                                title="Emails"
                                onClick={(e) => settabsSec("email")}
                              >
                                <i
                                  className="fa fa-envelope"
                                  aria-hidden="true"
                                ></i>
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                className={
                                  tabsSec === "call"
                                    ? "nav-link active"
                                    : "nav-link"
                                }
                                id="calls-tab"
                                data-toggle="tab"
                                role="tab"
                                aria-controls="calls"
                                aria-selected="false"
                                title="Calls"
                                onClick={(e) => settabsSec("call")}
                              >
                                <i
                                  className="fa fa-phone-square"
                                  aria-hidden="true"
                                ></i>
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                className={
                                  tabsSec === "task"
                                    ? "nav-link active"
                                    : "nav-link"
                                }
                                id="tasks-tab"
                                data-toggle="tab"
                                role="tab"
                                aria-controls="tasks"
                                aria-selected="false"
                                title="Tasks"
                                onClick={(e) => settabsSec("task")}
                              >
                                <i
                                  className="fa fa-tasks"
                                  aria-hidden="true"
                                ></i>
                              </Link>
                            </li>

                            {/* <li className="nav-item">
                              <Link
                                className={
                                  tabsSec === "other"
                                    ? "nav-link active"
                                    : "nav-link"
                                }
                                id="tasks-tab"
                                data-toggle="tab"
                                role="tab"
                                aria-controls="tasks"
                                aria-selected="false"
                                title="Tasks"
                                onClick={(e) => settabsSec("other")}
                              >
                                Others
                              </Link>
                            </li> */}
                          </ul>
                        </div>

                        {/* List of note,call,email,task */}

                        <div className="center-details-body center-body">
                          <div className="tab-content" id="myTabContent">
                            <div
                              className={
                                tabsSec === "activity"
                                  ? "tab-pane fade show active"
                                  : "tab-pane fade"
                              }
                              id="activity"
                              role="tabpanel"
                              aria-labelledby="activity-tab"
                            >
                              <ActivityList
                                contactId={id}
                                dealId={0}
                                setEditNote={setEditNote}
                                openNoteTab={(e) => setactivityPopups("note")}
                                setEmailEdit={setEmailEdit}
                                openEmailTab={() => setactivityPopups("Email")}
                                setCallEdit={setCallEdit}
                                openCallTab={() => setactivityPopups("Call")}
                                setEditTask={setEditTask}
                                openTaskTab={() => setactivityPopups("Task")}
                              />
                            </div>

                            <div
                              className={
                                tabsSec === "note"
                                  ? "tab-pane fade show active"
                                  : "tab-pane fade"
                              }
                              id="notes"
                              role="tabpanel"
                              aria-labelledby="notes-tab"
                            >
                              <NoteLogs
                                contactId={id}
                                dealId={0}
                                setEditNote={setEditNote}
                                openNoteTab={(e) => setactivityPopups("note")}
                              />
                            </div>

                            <div
                              className={
                                tabsSec === "email"
                                  ? "tab-pane fade show active"
                                  : "tab-pane fade"
                              }
                              id="email"
                              role="tabpanel"
                              aria-labelledby="contact-tab"
                            >
                              <EmailLog
                                contactId={id}
                                dealId={0}
                                setEmailEdit={setEmailEdit}
                                openEmailTab={() => setactivityPopups("Email")}
                              />
                            </div>

                            <div
                              className={
                                tabsSec === "call"
                                  ? "tab-pane fade show active"
                                  : "tab-pane fade"
                              }
                              id="calls"
                              role="tabpanel"
                              aria-labelledby="calls-tab"
                            >
                              <CallLog
                                contactId={id}
                                dealId={0}
                                setCallEdit={setCallEdit}
                                openCallTab={() => setactivityPopups("Call")}
                              />
                            </div>

                            <div
                              className={
                                tabsSec === "task"
                                  ? "tab-pane fade show active"
                                  : "tab-pane fade"
                              }
                              id="tasks"
                              role="tabpanel"
                              aria-labelledby="tasks-tab"
                            >
                              <TaskLog
                                contactId={id}
                                dealId={0}
                                setEditTask={setEditTask}
                                openTaskTab={() => setactivityPopups("Task")}
                              />
                            </div>

                            <div
                              className={
                                tabsSec === "whatsapp"
                                  ? "tab-pane fade show active"
                                  : "tab-pane fade"
                              }
                              id="whatsapp"
                              role="tabpanel"
                              aria-labelledby="whatsapp-tab"
                            >
                              <div className="create-note whatsapp-chat">
                                <div className="row">
                                  <div className="col-md-12 text-center mt-3">
                                    <div className="chat-msg-shared">
                                      <div className="mesgs">
                                        <div className="msg_history">
                                          <div className="incoming_msg">
                                            <div className="received_msg">
                                              <div className="received_withd_msg">
                                                <p>
                                                  I need to know about the
                                                  application status.
                                                </p>
                                                <span className="time_date">
                                                  11:01 AM | Aug 2022
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="outgoing_msg">
                                            <div className="sent_msg">
                                              <p>
                                                We are working on it please
                                                shared docs. I need to know
                                                about the application status. We
                                                are working on it please shared
                                                docs. We are working on it
                                                please shared docs.
                                              </p>
                                              <span className="time_date">
                                                11:01 AM | Aug 2022
                                              </span>
                                            </div>
                                          </div>

                                          <div className="incoming_msg">
                                            <div className="received_msg">
                                              <div className="received_withd_msg">
                                                <p>
                                                  I need to know about the
                                                  application.
                                                </p>
                                                <span className="time_date">
                                                  11:01 AM | Aug 2022
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="outgoing_msg">
                                            <div className="sent_msg">
                                              <p>We are working on it</p>
                                              <span className="time_date">
                                                11:01 AM | Aug 2022
                                              </span>
                                            </div>
                                          </div>

                                          <div className="incoming_msg">
                                            <div className="received_msg">
                                              <div className="received_withd_msg">
                                                <p>
                                                  I need to know about the
                                                  application status.
                                                </p>
                                                <span className="time_date">
                                                  11:01 AM | Aug 2022
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="outgoing_msg">
                                            <div className="sent_msg">
                                              <p>
                                                We are working on it please
                                                shared docs.
                                              </p>
                                              <span className="time_date">
                                                11:01 AM | Aug 2022
                                              </span>
                                            </div>
                                          </div>

                                          <div className="incoming_msg">
                                            <div className="received_msg">
                                              <div className="received_withd_msg">
                                                <p>
                                                  I need to know about the
                                                  application status.
                                                </p>
                                                <span className="time_date">
                                                  11:01 AM | Aug 2022
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="outgoing_msg">
                                            <div className="sent_msg">
                                              <p>
                                                We are working on it please
                                                shared docs.
                                              </p>
                                              <span className="time_date">
                                                11:01 AM | Aug 2022
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="type_msg">
                                        <div className="input_msg_write">
                                          <input
                                            type="text"
                                            className="write_msg"
                                            placeholder="Type a message"
                                          />
                                          <button
                                            className="msg_send_btn"
                                            type="button"
                                          >
                                            <i
                                              className="fa fa-paper-plane"
                                              aria-hidden="true"
                                            ></i>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              className={
                                tabsSec === "meeting"
                                  ? "tab-pane fade show active"
                                  : "tab-pane fade"
                              }
                              id="meeting"
                              role="tabpanel"
                              aria-labelledby="meeting-tab"
                            >
                              <div className="create-note">
                                <div className="row">
                                  <div className="col-md-12 text-right">
                                    <Link className="btn">Create Task</Link>
                                  </div>
                                  <div className="col-md-12 text-center mt-3">
                                    <h4>
                                      Create a task for yourself or a teammate.
                                      Keep track of all your to-dos for this
                                      record.
                                    </h4>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* <div
                              className={
                                tabsSec === "other"
                                  ? "tab-pane fade show active"
                                  : "tab-pane fade"
                              }
                              id="activity"
                              role="tabpanel"
                              aria-labelledby="activity-tab"
                            >
                              <OtherActivity contactId={id} />
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Right Side section deal , attachment, applications,message */}

                    <div
                      className={Mobile_Screen <= 480 ? "mblVw" : "col-md-3"}
                    >
                      <div className="right-details">
                        {/* <div className="right-body notification-send">
                          <div className="row">
                            <div className="col-md-7 text-left">
                              <h6
                                onClick={() => {
                                  if (RightSideOption1 === "Deal") {
                                    setRightSideOption1("");
                                  } else {
                                    setRightSideOption1("Deal");
                                  }
                                }}
                                className="notification-send-toggle"
                              >
                                <i
                                  className={
                                    RightSideOption1 == "Deal"
                                      ? "fas fa-chevron-down"
                                      : "fas fa-chevron-right"
                                  }
                                  style={{
                                    fontSize: "12px",
                                    padding: "0px",
                                    color: "#0c3eff",
                                  }}
                                ></i>
                                &nbsp;&nbsp;
                                <strong
                                  style={{
                                    color: "#4a4a4a",
                                    cursor: "pointer",
                                  }}
                                >
                                  Deal
                                </strong>
                              </h6>
                            </div>
                            <div className="col-md-5 text-right">
                              <h6>
                                <Link
                                  onClick={() => {
                                    setdealCreateData({
                                      ...dealCreateData,
                                      DealName: contactData?.First_Name
                                        ? contactData?.First_Name +
                                          "-" +
                                          contactData?.Last_Name
                                        : contactData?.full_name,
                                    });
                                    setDealOpen(true);
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  <i
                                    className="fas fa-plus"
                                    style={{
                                      fontSize: "12px",
                                      padding: "0px",
                                      color: "#0c3eff",
                                    }}
                                  ></i>
                                  Add
                                </Link>
                              </h6>
                            </div>

                            <div
                              style={{
                                display:
                                  RightSideOption1 == "Deal" ? "block" : "none",
                              }}
                              className="col-md-12 notification-send-show attached-files pl-4 pt-3"
                            >
                              {dealData?.length === 0 ? (
                                <span>No-record exist !</span>
                              ) : (
                                ""
                              )}
                              {dealData?.map((el) => (
                                <div
                                  key={el._id}
                                  className="alert alert-info alert-dismissible"
                                >
                                  <h5
                                    style={{
                                      fontSize: "13px",
                                      lineHeight: "16px",
                                      marginBottom: "2px",
                                      fontWeight: "500",
                                      color: "#0c3eff",
                                      padding: "0",
                                      margin: "0",
                                    }}
                                  >
                                    <Link
                                      to={`/deal/edit/${el._id}`}
                                      target="_blank"
                                    >
                                      {" "}
                                      <u> {el.DealName}</u>
                                    </Link>
                                  </h5>
                                  <p
                                    style={{
                                      padding: "0",
                                      margin: "0",
                                      display: "flex",
                                      gap: "5px",
                                      alignItems: "center",
                                    }}
                                  >
                                    <span> Pipeline: </span>
                                    <select
                                      style={{
                                        fontSize: "10px",
                                        ouline: "none",
                                        border: "none",
                                        background: "transparent",
                                        color: "#0c3eff",
                                        width: "auto",
                                        padding: "0",
                                        margin: "0",
                                      }}
                                      value={el.Pipeline_Id}
                                      disabled={true}
                                      className="form-control"
                                    >
                                      <PipelineDropdown />
                                    </select>
                                  </p>

                                  <p
                                    style={{
                                      padding: "0",
                                      margin: "0",
                                      display: "flex",
                                      gap: "5px",
                                      alignItems: "center",
                                    }}
                                  >
                                    <span> Deal stage: </span>
                                    <select
                                      className="form-control"
                                      style={{
                                        fontSize: "10px",
                                        ouline: "none",
                                        width: "auto",
                                        border: "none",
                                        background: "transparent",
                                        color: "#0c3eff",
                                        padding: "0",
                                        margin: "0",
                                      }}
                                      disabled={true}
                                      value={el.Deal_Stage_Id}
                                    >
                                      <DealStageDropDown />
                                    </select>
                                  </p>

                                  <h6>
                                    close date:
                                    <span>
                                      {el.Close_Date
                                        ? TimeStampConverter(el.Close_Date)
                                        : ""}
                                    </span>
                                  </h6>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>

                        <div className="right-body Attach-file">
                          <div className="row">
                            <div className="col-md-12 text-left">
                              <h6 className="attach-file-text-toggle">
                                <i
                                  className="fas fa-chevron-right"
                                  style={{
                                    fontSize: "12px",
                                    padding: "0px",
                                    color: "#0c3eff",
                                  }}
                                ></i>

                                <strong
                                  style={{
                                    color: "#4a4a4a",
                                    cursor: "pointer",
                                  }}
                                  onClick={(e) =>
                                    window.open(
                                      `/lifecycle/${id}/cnt`,
                                      "_blank"
                                    )
                                  }
                                >
                                  Lifecycle
                                </strong>
                              </h6>
                            </div>
                          </div>
                        </div>

                        <NotificationComponent
                          RightSideOption={RightSideOption1}
                          setRightSideOption={setRightSideOption1}
                          createSec={createSec}
                          setcreateSec={setcreateSec}
                          Contact_Id={contactData?._id}
                        />

                        <div className="right-body application-status">
                          <div className="row">
                            <div className="col-md-7 text-left">
                              <h6
                                onClick={() => {
                                  if (RightSideOption1 === "Application") {
                                    setRightSideOption1("");
                                  } else setRightSideOption1("Application");
                                }}
                                className="application-send-toggle"
                              >
                                <i
                                  className={
                                    RightSideOption1 == "Application"
                                      ? "fas fa-chevron-down"
                                      : "fas fa-chevron-right"
                                  }
                                  style={{
                                    fontSize: "12px",
                                    padding: "0px",
                                    color: "#0c3eff",
                                  }}
                                ></i>

                                <strong
                                  style={{
                                    color: "#4a4a4a",
                                    cursor: "pointer",
                                  }}
                                >
                                  Application
                                </strong>
                              </h6>
                            </div>

                            <div className="col-md-5 text-right">
                              <h6>
                                <Link
                                  onClick={() =>
                                    dispatch(
                                      createApplicationTemp({ Contact_Id: id })
                                    )
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  <i
                                    className="fas fa-plus"
                                    style={{
                                      fontSize: "12px",
                                      padding: "0px",
                                      color: "#0c3eff",
                                    }}
                                  ></i>
                                  Add
                                </Link>
                              </h6>
                            </div>

                            <div
                              style={{
                                display:
                                  RightSideOption1 == "Application"
                                    ? "block"
                                    : "none",
                              }}
                              className="col-md-12 application-send-show attached-files pl-4 pt-3"
                            >
                              {loadingApplicationRec ? (
                                <span>Loading...</span>
                              ) : (
                                ""
                              )}
                              <span>{errorApplicationRec}</span>
                              {applicationDetByIds?.map((el) =>
                                el?.ApplicationTemp_Id ? (
                                  <div
                                    key={el._id}
                                    className="alert alert-info alert-dismissible"
                                  >
                                    {el?.ApplicationTemp_Id?.isSubmitted ? (
                                      <div>
                                        <p style={{ color: "#0c3eff" }}>
                                          {`SED${
                                            el.Application_Id
                                              ? el.Application_Id
                                              : ""
                                          } - ${
                                            el.Course_Id?.CourseName
                                              ? el.Course_Id?.CourseName
                                              : "N/A"
                                          } -${
                                            el.University_Id?.University_Name
                                              ? el.University_Id
                                                  ?.University_Name
                                              : "N/A"
                                          } - ${
                                            el.PAYMENT_MADE
                                              ? el.PAYMENT_MADE
                                              : "N/A"
                                          }`}
                                        </p>
                                        <p>
                                          Status:{" "}
                                          {el?.ApplicationTemp_Id?.isSubmitted
                                            ? "Pre-assessment"
                                            : "In-progress"}
                                        </p>
                                      </div>
                                    ) : (
                                      <Link
                                        target="_blank"
                                        to={`/application/create/${el?.ApplicationTemp_Id?._id}/1`}
                                      >
                                        <p style={{ color: "#0c3eff" }}>
                                          {`SED${
                                            el.Application_Id
                                              ? el.Application_Id
                                              : ""
                                          } - ${
                                            el.Course_Id?.CourseName
                                              ? el.Course_Id?.CourseName
                                              : "N/A"
                                          } -${
                                            el.University_Id?.University_Name
                                              ? el.University_Id
                                                  ?.University_Name
                                              : "N/A"
                                          } - ${
                                            el.PAYMENT_MADE
                                              ? el.PAYMENT_MADE
                                              : "N/A"
                                          }`}
                                        </p>
                                        <p>
                                          Status:{" "}
                                          {el?.ApplicationTemp_Id?.isSubmitted
                                            ? "Pre-assessment"
                                            : "In-progress"}
                                        </p>
                                      </Link>
                                    )}
                                  </div>
                                ) : (
                                  <div
                                    key={el._id}
                                    className="alert alert-info alert-dismissible"
                                  >
                                    <Link
                                      to={`/application/details/${el._id}`}
                                      target="_blank"
                                    >
                                      <p style={{ color: "#0c3eff" }}>
                                        {`SED${
                                          el.Application_Id
                                            ? el.Application_Id
                                            : ""
                                        } - ${
                                          el.Course_Id?.CourseName
                                            ? el.Course_Id?.CourseName
                                            : "N/A"
                                        } -${
                                          el.University_Id?.University_Name
                                            ? el.University_Id?.University_Name
                                            : "N/A"
                                        } - ${
                                          el.PAYMENT_MADE
                                            ? el.PAYMENT_MADE
                                            : "N/A"
                                        }`}
                                      </p>
                                    </Link>
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        </div>

                        <MessageComponent
                          RightSideOption={RightSideOption1}
                          setRightSideOption={setRightSideOption1}
                          createSec={createSec}
                          setcreateSec={setcreateSec}
                          Contact_Id={contactData?._id}
                        /> */}

                        {/* Shortlist course */}

                        <div className="right-body application-status">
                          <div className="row">
                            <div className="col-md-7 text-left">
                              <h6
                                onClick={() => {
                                  if (RightSideOption1 === "Cart") {
                                    setRightSideOption1("");
                                  } else setRightSideOption1("Cart");
                                }}
                                className="application-send-toggle"
                              >
                                <i
                                  className={
                                    RightSideOption1 === "Cart"
                                      ? "fas fa-chevron-down"
                                      : "fas fa-chevron-right"
                                  }
                                  style={{
                                    fontSize: "12px",
                                    padding: "0px",
                                    color: "#0c3eff",
                                  }}
                                ></i>

                                <strong
                                  style={{
                                    color: "#4a4a4a",
                                    cursor: "pointer",
                                  }}
                                >
                                  Cart
                                </strong>
                              </h6>
                            </div>
                            <div className="col-md-5 text-right">
                              <h6>
                                <Link
                                  onClick={() => setcreateSec("Cart")}
                                  style={{ cursor: "pointer" }}
                                >
                                  <i
                                    className="fas fa-plus"
                                    style={{
                                      fontSize: "12px",
                                      padding: "0px",
                                      color: "#0c3eff",
                                    }}
                                  ></i>
                                  Add
                                </Link>
                              </h6>
                            </div>

                            <div
                              style={{
                                display:
                                  RightSideOption1 === "Cart"
                                    ? "block"
                                    : "none",
                              }}
                              className="col-md-12 application-send-show attached-files pl-4 pt-3"
                            >
                              <span>{errorCartRec}</span>
                              {cartRecs?.length === 0 ? (
                                <span>No - record exist !</span>
                              ) : (
                                ""
                              )}

                              {cartRecs?.map((el) => (
                                <div
                                  key={el._id}
                                  className="alert alert-info alert-dismissible"
                                >
                                  <h6
                                    style={{
                                      fontSize: "14px",
                                      marginBottom: "0",
                                      paddingBottom: "2px",
                                      color: "#f36525",
                                      fontWeight: "bold",
                                      cursor: "pointer",
                                    }}
                                    // onClick={() =>
                                    //   window.open(
                                    //     `/package/create?PackageId=${el?._id}`,
                                    //     "_blank"
                                    //   )
                                    // }
                                  >
                                    {el?.PackageId?.Name}
                                  </h6>
                                  <p
                                    style={{
                                      fontSize: "10px",
                                      color: "black",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Package Type:
                                    {
                                      Get_Package_Type(
                                        el?.PackageId?.PackageType
                                      )?.name
                                    }
                                  </p>
                                  <p
                                    style={{
                                      fontSize: "10px",
                                      color: "black",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Actual Cost: {el?.PackageId?.ActualCost}
                                  </p>
                                  <p
                                    style={{
                                      fontSize: "10px",
                                      color: "black",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Discounted Price :
                                    {el?.PackageId?.DiscountedPrice}
                                  </p>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <span
                                      style={{
                                        background: "blue",
                                        color: "white",
                                        padding: "4px 6px",
                                        borderRadius: "2px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        dispatch(placeOrder(el?._id))
                                      }
                                    >
                                      {loadingOrderS
                                        ? "Loading.."
                                        : "Place Order"}
                                    </span>
                                    <span
                                      style={{
                                        color: "red",
                                        cursor: "pointer",
                                        fontSize: "14px",
                                      }}
                                      onClick={(e) => {
                                        setDeleteAlert({
                                          msg: `Do you really want to remove Package from Cart (${el?.PackageId?.Name}) ?`,
                                          Delete: () =>
                                            handleRemoveCart(e, el._id),
                                        });
                                        setOpenNext(true);
                                      }}
                                    >
                                      <MdDeleteOutline />
                                    </span>
                                  </div>
                                </div>
                              ))}
                            </div>

                            {createSec === "Cart" ? (
                              <Cart
                                createSec={createSec}
                                setcreateSec={setcreateSec}
                                ContactId={id}
                                Get_Order_Cart_Det={Get_Order_Cart_Det}
                              />
                            ) : (
                              ""
                            )}

                            {/* <div
                              className={
                                createSec === "Cart"
                                  ? "overlay-note w-100  modal fade show"
                                  : "modal fade"
                              }
                              id="shortlistSend"
                              role="dialog"
                              aria-labelledby="shortlistTitle"
                              style={
                                createSec === "ShortListCourse"
                                  ? { display: "block", paddingRight: "10px " }
                                  : { display: "none" }
                              }
                              aria-hidden="true"
                              aria-modal={createSec === "Cart" ? true : false}
                            >
                              <p>dd</p>
                            </div> */}
                          </div>
                        </div>

                        <div className="right-body application-status">
                          <div className="row">
                            <div className="col-md-7 text-left">
                              <h6
                                onClick={() => {
                                  if (RightSideOption1 === "Order") {
                                    setRightSideOption1("");
                                  } else setRightSideOption1("Order");
                                }}
                                className="application-send-toggle"
                              >
                                <i
                                  className={
                                    RightSideOption1 === "Order"
                                      ? "fas fa-chevron-down"
                                      : "fas fa-chevron-right"
                                  }
                                  style={{
                                    fontSize: "12px",
                                    padding: "0px",
                                    color: "#0c3eff",
                                  }}
                                ></i>

                                <strong
                                  style={{
                                    color: "#4a4a4a",
                                    cursor: "pointer",
                                  }}
                                >
                                  Orders
                                </strong>
                              </h6>
                            </div>
                            <div className="col-md-5 text-right">
                              <h6>
                                {/* <Link
                                  onClick={() => setcreateSec("Order")}
                                  style={{ cursor: "pointer" }}
                                >
                                  <i
                                    className="fas fa-plus"
                                    style={{
                                      fontSize: "12px",
                                      padding: "0px",
                                      color: "#0c3eff",
                                    }}
                                  ></i>
                                  Add
                                </Link> */}
                              </h6>
                            </div>

                            <div
                              style={{
                                display:
                                  RightSideOption1 === "Order"
                                    ? "block"
                                    : "none",
                              }}
                              className="col-md-12 application-send-show attached-files pl-4 pt-3"
                            >
                              <span>{errorOrderRecs}</span>
                              {orderRecs?.length === 0 ? (
                                <span>No - record exist !</span>
                              ) : (
                                ""
                              )}

                              {orderRecs?.map((el) => (
                                <div
                                  key={el._id}
                                  className="alert alert-info alert-dismissible"
                                >
                                  <h6
                                    style={{
                                      fontSize: "14px",
                                      marginBottom: "0",
                                      paddingBottom: "2px",
                                      color: "#f36525",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {el?.PackageId?.Name}
                                  </h6>
                                  <p
                                    style={{
                                      fontSize: "10px",
                                      color: "black",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Package Type:{" "}
                                    {
                                      Get_Package_Type(
                                        el?.PackageId?.PackageType
                                      )?.name
                                    }
                                  </p>
                                  <p
                                    style={{
                                      fontSize: "10px",
                                      color: "black",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Transaction Status: {el?.transactionStatus}
                                  </p>

                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    {el?.isPaid ? (
                                      ""
                                    ) : (
                                      <span
                                        style={{
                                          background: "blue",
                                          color: "white",
                                          padding: "4px 6px",
                                          borderRadius: "2px",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          setOrderId(el?._id);
                                          setcreateSec("Update Payment");
                                        }}
                                      >
                                        {loadingOrderS
                                          ? "Loading.."
                                          : "Update Payment"}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              ))}
                            </div>

                            {/* 
                            <div
                              className={
                                createSec === "Cart"
                                  ? "overlay-note w-100  modal fade show"
                                  : "modal fade"
                              }
                              id="shortlistSend"
                              role="dialog"
                              aria-labelledby="shortlistTitle"
                              style={
                                createSec === "ShortListCourse"
                                  ? { display: "block", paddingRight: "10px " }
                                  : { display: "none" }
                              }
                              aria-hidden="true"
                              aria-modal={createSec === "Cart" ? true : false}
                            >
                              <p>dd</p>
                            </div> */}
                          </div>

                          {createSec === "Update Payment" ? (
                            <UpdatePayment
                              setcreateSec={setcreateSec}
                              createSec={createSec}
                              Get_Order_Cart_Det={Get_Order_Cart_Det}
                              orderId={orderId}
                              setOrderId={setOrderId}
                            />
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="right-body application-status">
                          <div className="row">
                            <div className="col-md-7 text-left">
                              <h6
                                onClick={() => {
                                  if (RightSideOption1 === "TLAT") {
                                    setRightSideOption1("");
                                  } else setRightSideOption1("TLAT");
                                }}
                                className="application-send-toggle"
                              >
                                <i
                                  className={
                                    RightSideOption1 === "TLAT"
                                      ? "fas fa-chevron-down"
                                      : "fas fa-chevron-right"
                                  }
                                  style={{
                                    fontSize: "12px",
                                    padding: "0px",
                                    color: "#0c3eff",
                                  }}
                                ></i>

                                <strong
                                  style={{
                                    color: "#4a4a4a",
                                    cursor: "pointer",
                                  }}
                                >
                                  T-LAT Result
                                </strong>
                              </h6>
                            </div>
                            <div className="col-md-5 text-right">
                              <h6>
                                {/* <Link
                                  onClick={() => setcreateSec("Order")}
                                  style={{ cursor: "pointer" }}
                                >
                                  <i
                                    className="fas fa-plus"
                                    style={{
                                      fontSize: "12px",
                                      padding: "0px",
                                      color: "#0c3eff",
                                    }}
                                  ></i>
                                  Add
                                </Link> */}
                              </h6>
                            </div>

                            <div
                              style={{
                                display:
                                  RightSideOption1 === "TLAT"
                                    ? "block"
                                    : "none",
                              }}
                              className="col-md-12 application-send-show attached-files pl-4 pt-3"
                            >
                              <span>{errorOrderRecs}</span>
                              {!TestResult || errorResult ? (
                                <span>No Test Attempted !</span>
                              ) : (
                                ""
                              )}

                              {TestResult ? (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                  className="alert alert-info alert-dismissible"
                                >
                                  <h6
                                    style={{
                                      fontSize: "14px",
                                      marginBottom: "0",
                                      paddingBottom: "2px",
                                      color: "#f36525",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Scored: {TestResult?.Result}
                                  </h6>
                                  <p
                                    onClick={() =>
                                      Navigate(`/T-LAT-Result/${id}`, "_blank")
                                    }
                                    style={{
                                      background: "blue",
                                      color: "white",
                                      padding: "3px 4px",
                                      borderRadius: "1px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    Check Result
                                  </p>
                                  {/* <p
                                    style={{
                                      fontSize: "10px",
                                      color: "black",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Package Type:{" "}
                                    {
                                      Get_Package_Type(
                                        el?.PackageId?.PackageType
                                      )?.name
                                    }
                                  </p>
                                  <p
                                    style={{
                                      fontSize: "10px",
                                      color: "black",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Transaction Status: {el?.transactionStatus}
                                  </p>

                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <span
                                      style={{
                                        background: "blue",
                                        color: "white",
                                        padding: "4px 6px",
                                        borderRadius: "2px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        dispatch(placeOrder(el?._id))
                                      }
                                    >
                                      {loadingOrderS
                                        ? "Loading.."
                                        : "Place Order"}
                                    </span>
                                  </div> */}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* ------ */}
            <DeletePopup
              OpenNext={OpenNext}
              setOpenNext={setOpenNext}
              DeleteData={DeleteAlert}
            />
            <ToastContainer />
          </div>
        </div>
      </section>
    </>
  );
}
