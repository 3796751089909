import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SettingLeftSection from "../Components/SettingLeftSection";
import DeletePopup from "../Components/DeletePoup";
import SidePanel from "../../Components/SidePanel";
import TopBar from "../../Components/TopBar";
import SEDLoading from "../../Components/SEDLoading";
import {
  MaterialSubTitle_Validator_Model,
  MaterialSubTitleJson,
} from "../../Models/MaterialModel";
import MaterialTitleDropDown from "../Components/MaterialTitleDropDown";
import {
  createMaterialSubTitle,
  deleteMaterialSubTitle,
  listMaterialSubTitleAll,
  singleMaterialSubTitleDetails,
  updateMaterialSubTitle,
} from "../../Actions/materialSubTitleAction";
import ReactQuill from "react-quill";
import {
  MATERIAL_SUBTITLE_CREATE_RESET,
  MATERIAL_SUBTITLE_REMOVE_RESET,
  MATERIAL_SUBTITLE_UPDATE_RESET,
} from "../../Constants/materialSubTitleConstant";
import { AiFillDelete } from "react-icons/ai";
import api from "../../core/api";
import { toast, ToastContainer } from "react-toastify";
import { toastOptions } from "../../Models/ToastEditiorOption";
import { checkDomain } from "../../Models/mockTestModel";

const Img = { image: "", preview: "", name: "" };
const audio = { audio: "", preview: "", name: "" };

export default function MaterialSubTitle() {
  const dispatch = useDispatch();
  const [MaterialSubTitleData, setMaterialSubTitleData] =
    useState(MaterialSubTitleJson);
  const [createSec, setcreateSec] = useState(false);
  const [MaterialDesc, setMaterialDesc] = useState([]);
  const [UploadedDesc, setUploadedDesc] = useState([]);
  const [Load, setLoad] = useState(false);
  const sectionRef = useRef(null);
  const [MaterialDescription, setMaterialDescription] = useState("");
  const { loading, error, MaterialSubTitleAllRec } = useSelector(
    (s) => s.listMaterialSubTitleAllReducer
  );
  const [btnText, setbtnText] = useState("Save");
  const [cancelHide, setcancelHide] = useState(false);
  const [OpenNext, setOpenNext] = useState(false);
  const [DeleteAlert, setDeleteAlert] = useState({ msg: "", Delete: () => "" });

  const {
    loading: loadingsv,
    error: errorsv,
    success: successsv,
  } = useSelector((s) => s.createMaterialSubTitleReducer);

  const {
    loading: loadingud,
    error: errorud,
    success: successud,
  } = useSelector((s) => s.updateMaterialSubTitleReducer);
  const { MaterialSubTitleDetails } = useSelector(
    (s) => s.singleMaterialSubTitleDetailsReducer
  );

  const {
    loading: loadingdet,
    error: errordet,
    success: successdet,
  } = useSelector((s) => s.deleteMaterialSubTitleReducer);

  const handleChange = (e) => {
    e.preventDefault();
    setMaterialSubTitleData({
      ...MaterialSubTitleData,
      [e.target.name]: e.target.value,
    });
  };

  const SetFiles = async (FileData, url, index) => {
    const { data } = await api.post(`/uploaddoc?url=${url}`, FileData);
    let details = [...UploadedDesc];
    details[index].url = data?.url;
    setUploadedDesc([...details]);
  };

  const extractElementsInSequence = (htmlString) => {
    // Create a temporary DOM element to parse the HTML string
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlString;

    const result = [];
    const TypeOfResult = [];

    // Iterate over child nodes of the parent container

    const childNode = tempDiv.childNodes[0];

    childNode.childNodes.forEach((node, i) => {
      if (node.nodeType === Node.ELEMENT_NODE) {
        if (node.tagName.toLowerCase() === "div") {
          // Check if the div contains an img
          const img = node.querySelector("img");
          if (img) {
            const Name = img.src?.split("/");
            result.push({
              image: "",
              preview: "",
              name: Name[Name?.length - 1],
              url: img.src,
            }); // Push image URL
            TypeOfResult.push("Image");
          }

          // Check if the div contains an audio

          const audio = node.querySelector("audio");
          if (audio && audio.src) {
            const Name = audio.src?.split("/");
            result.push({
              audio: "",
              preview: "",
              name: Name[Name?.length - 1],
              url: audio.src,
            }); // Push MP3 URL
            TypeOfResult.push("Mp3");
          }

          // If the div contains text and no other elements, extract it as HTML

          if (!img && !audio) {
            const innerHTML = node.innerHTML.trim();

            if (innerHTML) {
              result.push({ desc: innerHTML });
              TypeOfResult.push("InputText"); // Push raw HTML content
            }
          }
        }
      }
    });

    setMaterialDesc([...TypeOfResult]);
    setUploadedDesc([...result]);
    // return result;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (btnText === "Save") {
      const Validator = MaterialSubTitle_Validator_Model(
        {
          ...MaterialSubTitleData,
        },
        MaterialDesc,
        UploadedDesc
      );
      if (Validator === "success") {
        setLoad(true);
        for (let i = 0; i < MaterialDesc?.length; i++) {
          if (MaterialDesc[i] === "Image") {
            const formData = new FormData();
            formData.append("UploadFile", UploadedDesc[i]?.image);
            SetFiles(formData, "/MockTest/Image", i);
          } else if (MaterialDesc[i] === "Mp3") {
            const formData = new FormData();
            formData.append("UploadFile", UploadedDesc[i]?.audio);
            SetFiles(formData, "/MockTest/Mp3", i);
          }
        }

        setTimeout(async () => {
          const MaterialArray = await MaterialDesc?.map((en, i) => {
            if (en === "InputText") {
              return UploadedDesc[i]?.desc;
            } else if (en === "Image") {
              return `<img src="${
                process.env.REACT_APP_DOC_URL + UploadedDesc[i]?.url
              }" alt={mock_image}/>`;
            } else if (en === "Mp3") {
              return `<audio controls  style={{ width: "100%",marginTop:"20px", outline: "none" }} src="${
                process.env.REACT_APP_DOC_URL + UploadedDesc[i]?.url
              }">
                </audio>`;
            } else {
              return "";
            }
          });

          const Descriptions =
            await `<div style={{maxWidth: "600px",margin: "20px auto",textAlign: "center",border: "1px solid #ccc",padding: "20px",borderRadius: "8px",boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",fontFamily: "Arial, sans-serif",}}>
            ${MaterialArray?.map(
              (en) => `<div >
              ${en}
              </div>`
            ).join("")}
          </div>`;

          await dispatch(
            createMaterialSubTitle({
              ...MaterialSubTitleData,
              MaterialDescription: Descriptions,
            })
          );

          setLoad(false);
        }, 5000);
      } else {
        toast.error(Validator, toastOptions);
      }
    } else if (btnText === "Update") {
      const Validator = MaterialSubTitle_Validator_Model(
        {
          ...MaterialSubTitleData,
        },
        MaterialDesc,
        UploadedDesc
      );

      if (Validator === "success") {
        setLoad(true);
        for (let i = 0; i < MaterialDesc?.length; i++) {
          if (MaterialDesc[i] === "Image" && !UploadedDesc[i]?.url) {
            const formData = new FormData();
            formData.append("UploadFile", UploadedDesc[i]?.image);
            SetFiles(formData, "/MockTest/Image", i);
          } else if (MaterialDesc[i] === "Mp3" && !UploadedDesc[i]?.url) {
            const formData = new FormData();
            formData.append("UploadFile", UploadedDesc[i]?.audio);
            SetFiles(formData, "/MockTest/Mp3", i);
          }
        }

        setTimeout(async () => {
          const MaterialArray = await MaterialDesc?.map((en, i) => {
            if (en === "InputText") {
              return UploadedDesc[i]?.desc;
            } else if (en === "Image") {
              const urlData = checkDomain(
                UploadedDesc[i]?.url,
                process.env.REACT_APP_DOC_URL
              )
                ? UploadedDesc[i]?.url
                : process.env.REACT_APP_DOC_URL + UploadedDesc[i]?.url;
              return `<img src="${urlData}" alt={mock_image}/>
                      `;
            } else if (en === "Mp3") {
              const urlData = checkDomain(
                UploadedDesc[i]?.url,
                process.env.REACT_APP_DOC_URL
              )
                ? UploadedDesc[i]?.url
                : process.env.REACT_APP_DOC_URL + UploadedDesc[i]?.url;
              return `
                    <audio controls  style={{ width: "100%",marginTop:"20px", outline: "none" }} src="${urlData}">
                      </audio>
                      `;
            } else {
              return "";
            }
          });

          const Descriptions =
            await `<div style={{maxWidth: "600px",margin: "20px auto",textAlign: "center",border: "1px solid #ccc",padding: "20px",borderRadius: "8px",boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",fontFamily: "Arial, sans-serif",}}>
            ${MaterialArray?.map(
              (en) => `<div >
              ${en}
              </div>`
            ).join("")}
          </div>`;

          await dispatch(
            updateMaterialSubTitle(MaterialSubTitleData._id, {
              ...MaterialSubTitleData,
              MaterialDescription: Descriptions,
            })
          );

          setLoad(false);
        }, 5000);
      } else {
        toast.error(Validator, toastOptions);
      }
    } else alert("Error on handleSubmit");
  };

  const handleDescRemove = (index) => {
    let Details1 = [...MaterialDesc];
    let UploadDetails1 = [...UploadedDesc];
    UploadDetails1.splice(index, 1);
    Details1.splice(index, 1);
    setMaterialDesc([...Details1]);
    setUploadedDesc([...UploadDetails1]);
  };

  const handlePreview = () => {
    const newTab = window.open("", "_blank");
    newTab.document.write(
      `<html><head><title>Preview</title></head><body><divstyle={{maxWidth: "600px",margin: "20px auto",textAlign: "center",border: "1px solid #ccc",padding: "20px",borderRadius: "8px",boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",fontFamily: "Arial, sans-serif",}}>`
    );

    MaterialDesc.map((ev, index) => {
      // Image Preview
      if (
        ev == "Image" &&
        (UploadedDesc[index]?.preview || UploadedDesc[index]?.url)
      ) {
        newTab.document.write(
          `<div>  <img src="${
            UploadedDesc[index]?.preview || UploadedDesc[index]?.url
          }"   style={{ width:"100%", maxWidth: "100%", borderRadius: "8px" ,display: "block"}}></div>`
        );
      }

      // Audio Preview
      if (
        ev == "Mp3" &&
        (UploadedDesc[index]?.preview || UploadedDesc[index]?.url)
      ) {
        newTab.document.write(
          `<audio controls  style={{ width: "100%",marginTop:"20px", outline: "none" }} src="${
            UploadedDesc[index]?.preview || UploadedDesc[index]?.url
          }"></audio>`
        );
      }
      // Mock Description Preview
      if (ev == "InputText" && UploadedDesc[index]?.desc) {
        newTab.document.write(`<div style={{
          marginBottom: "20px",
          fontSize: "18px",
          lineHeight: "1.6",
          color: "#333",
        }}> ${UploadedDesc[index]?.desc}</div>`);
      }
    });

    newTab.document.write("</div></body></html>");
  };

  const getMaterials = useCallback(() => {
    dispatch(listMaterialSubTitleAll());
  }, [dispatch]);

  const handleDelete = (e, _id) => {
    e.preventDefault();
    dispatch(deleteMaterialSubTitle(_id));
  };

  const handleEdit = (e, _id) => {
    e.preventDefault();
    dispatch(singleMaterialSubTitleDetails(_id));
    setbtnText("Update");
    setcancelHide(true);
  };

  useEffect(() => {
    if (MaterialSubTitleDetails) {
      setLoad(true);
      setMaterialSubTitleData({
        ...MaterialSubTitleDetails,
        MaterialTitleId: MaterialSubTitleDetails?.MaterialId,
      });
      setMaterialDescription(MaterialSubTitleDetails?.MaterialDescription);
      extractElementsInSequence(MaterialSubTitleDetails?.MaterialDescription);
      setLoad(false);
      setbtnText("Update");
    }
  }, [MaterialSubTitleDetails]);

  const handleCancel = () => {
    setMaterialSubTitleData(MaterialSubTitleJson);
    setMaterialDescription("");
    setbtnText("Save");
    setMaterialDesc([]);
    setUploadedDesc([]);
    setcancelHide(false);
    setcreateSec(false);
  };

  useEffect(() => {
    getMaterials();
  }, [getMaterials]);

  useEffect(() => {
    if (successdet)
      setTimeout(() => {
        dispatch({ type: MATERIAL_SUBTITLE_REMOVE_RESET });
      }, 2000);

    if (successud)
      setTimeout(() => {
        dispatch({ type: MATERIAL_SUBTITLE_UPDATE_RESET });
      }, 2000);

    if (successsv)
      setTimeout(() => {
        dispatch({ type: MATERIAL_SUBTITLE_CREATE_RESET });
      }, 2000);

    if (successdet || successsv || successud) {
      handleCancel();
      getMaterials();
    }

    if (errordet || errorsv || errorud) {
      toast.error(errordet || errorsv || errorud, toastOptions);
    }
  }, [
    successdet,
    getMaterials,
    successsv,
    dispatch,
    successud,
    errordet,
    errorsv,
    errorud,
  ]);

  return (
    <>
      <SidePanel page={"Material Sub Title"} />
      <section className="home-section">
        {Load || loading || loadingsv || loadingud || loadingdet ? (
          <SEDLoading />
        ) : (
          ""
        )}
        <TopBar Heading={"Material Sub Title"} />
        <div
          className="home-content"
          style={{ paddingTop: window.innerWidth <= 480 ? "20px" : "100px" }}
        >
          <div className="content-body">
            {/* <!--Body-content--> */}
            <div className="container-fluid">
              <div className="heading-title">
                <div className="row">
                  <div className="col-md-12">
                    <div className="home-table-search float-right d-flex">
                      {/* <!-- <button>right side</button> --> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="inner-body settings-details">
                <div className="row">
                  <div className="col-md-3">
                    <SettingLeftSection />
                  </div>
                  <div className="col-md-9">
                    <div className="body-content-with-md-9">
                      <div>
                        <div ref={sectionRef} className="container">
                          <div className="row">
                            <div className="col-md-12 text-left body-content-with-md-9-title">
                              <h4>Material Sub Title </h4>
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                            className=" Create-btn"
                          >
                            <a
                              style={{ width: "180px" }}
                              className="btn create-contact"
                              onClick={() => setcreateSec(true)}
                            >
                              Create Sub title
                            </a>
                          </div>
                        </div>

                        {/* <div className="row mt-3 px-5">
                          <div className="col-md-3 pt-1">
                            <label className="form-group">
                              Material Title:
                            </label>
                          </div>
                          <div className="col-md-9">
                            <select
                              className="form-control form-control-sm"
                              name="MaterialTitleId"
                              value={MaterialSubTitleData.MaterialTitleId}
                              onChange={(e) => handleChange(e)}
                            >
                              <option>select Material Title</option>
                              <MaterialTitleDropDown />
                            </select>
                          </div>
                        </div>
                        <div className="row mt-3 px-5">
                          <div className="col-md-3 pt-1">
                            <label className="form-group">
                              Material Sub Title:
                            </label>
                          </div>
                          <div className="col-md-9">
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              name="MaterialSubTitle"
                              placeholder="Enter Sub Title"
                              value={MaterialSubTitleData.MaterialSubTitle}
                              onChange={(e) => handleChange(e)}
                            />
                          </div>
                        </div>
                        <div className="row mt-3 px-5 mb-3">
                          <div className="col-md-3 pt-1">
                            <label className="form-group">
                              Material Description:
                            </label>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              gap: "5px",
                            }}
                            className="col-md-9"
                          >
                            <button
                              onClick={() => {
                                setMaterialDesc([...MaterialDesc, "InputText"]);
                                setUploadedDesc([
                                  ...UploadedDesc,
                                  { desc: "" },
                                ]);
                              }}
                              className="cst_btn"
                            >
                              Input Box
                            </button>
                            <button
                              onClick={() => {
                                setMaterialDesc([...MaterialDesc, "Image"]);
                                setUploadedDesc([...UploadedDesc, { ...Img }]);
                              }}
                              className="cst_btn"
                            >
                              Image
                            </button>
                            <button
                              onClick={() => {
                                setMaterialDesc([...MaterialDesc, "Mp3"]);
                                setUploadedDesc([
                                  ...UploadedDesc,
                                  { ...audio },
                                ]);
                              }}
                              className="cst_btn"
                            >
                              Mp3
                            </button>
                          </div>
                        </div> */}

                        {/* {MaterialDesc?.map((ek, i) => (
                          <div key={i + 474} style={{ marginBottom: "20px" }}>
                            {" "}
                            {ek == "InputText" ? (
                              <div className="row mt-3 px-5">
                                <div className="col-md-3 pt-1">
                                  <label>Description :</label>
                                </div>
                                <div className="col-md-9">
                                  <ReactQuill
                                    placeholder={"Enter Mock description"}
                                    name="MockDescription"
                                    value={UploadedDesc[i]?.desc}
                                    onChange={(e) => {
                                      let uploadDesc = [...UploadedDesc];
                                      uploadDesc[i].desc = e;
                                      setUploadedDesc([...uploadDesc]);
                                    }}
                                    required
                                  />
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-end",
                                    }}
                                  >
                                    <span
                                      onClick={() => handleDescRemove(i)}
                                      style={{
                                        color: "red",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <AiFillDelete />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ) : ek == "Image" ? (
                              <div className="row mt-3 px-5">
                                <div className="col-md-3 pt-1">
                                  <label>Upload image :</label>
                                </div>
                                <div className="Input_With_Del col-md-9">
                                  <input
                                    type="file"
                                    accept="image/*"
                                    filename={UploadedDesc[i]?.name}
                                    className="form-control"
                                    name="imageData"
                                    onChange={(e) => {
                                      let uploadDesc = [...UploadedDesc];
                                      uploadDesc[i].image = e.target.files[0];
                                      uploadDesc[i].name =
                                        e.target.files[0].name;
                                      uploadDesc[i].url = "";
                                      const reader = new FileReader();
                                      reader.onload = (e) =>
                                        (uploadDesc[i].preview =
                                          e.target.result);
                                      reader.readAsDataURL(e.target.files[0]);
                                      setUploadedDesc([...uploadDesc]);
                                    }}
                                  />
                                  <span
                                    onClick={() => handleDescRemove(i)}
                                    style={{ color: "red" }}
                                  >
                                    <AiFillDelete />
                                  </span>
                                </div>
                              </div>
                            ) : ek == "Mp3" ? (
                              <div className="row mt-3 px-5">
                                <div className="col-md-3 pt-1">
                                  <label>Upload Mp3 :</label>
                                </div>
                                <div className="Input_With_Del col-md-9">
                                  <input
                                    type="file"
                                    accept="audio/mp3"
                                    className="form-control"
                                    filename="jjkll.mp3"
                                    placeholder="Enter Mock No"
                                    name="audioData"
                                    onChange={(e) => {
                                      let uploadDesc = [...UploadedDesc];
                                      uploadDesc[i].audio = e.target.files[0];
                                      uploadDesc[i].name =
                                        e.target.files[0].name;
                                      uploadDesc[i].url = "";
                                      const reader = new FileReader();
                                      reader.onload = (e) =>
                                        (uploadDesc[i].preview =
                                          e.target.result);
                                      reader.readAsDataURL(e.target.files[0]);
                                      setUploadedDesc([...uploadDesc]);
                                    }}
                                  />
                                  <span
                                    onClick={() => handleDescRemove(i)}
                                    style={{ color: "red" }}
                                  >
                                    <AiFillDelete />
                                  </span>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}{" "}
                          </div>
                        ))}
                        <div className="row">
                          <div
                            className="col-md-12"
                            style={{ textAlign: "center" }}
                          >
                            <p>
                              <a
                                className="btn btn-deep-orange btn-md"
                                onClick={(e) => handleSubmit(e)}
                              >
                                {loadingsv || loadingud || loadingdet
                                  ? "Loading..."
                                  : btnText}
                              </a>
                              <button
                                type="button"
                                className="btn btn-deep-orange waves-effect waves-light"
                                onClick={() => handlePreview()}
                              >
                                Preview
                              </button>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                             
                              <a
                                className="btn btn-outline-deep-orange btn-md"
                                onClick={() => handleCancel()}
                              >
                                Cancel
                              </a>
                            </p>
                          </div>
                        </div> */}

                        <div
                          id="CreatContact"
                          className={
                            createSec ? "overlay-note w-100" : "overlay-note"
                          }
                        >
                          <div className="overlay-add-deals contact-right-side">
                            <div className="note-title">
                              <h4 className="text-left text-white pl-3">
                                {btnText == "Update"
                                  ? "Update Material Sub Title"
                                  : "Create Material Sub Title"}
                              </h4>
                              <a
                                className="closebtn text-right text-white"
                                onClick={(e) => handleCancel()}
                              >
                                ×
                              </a>
                            </div>

                            <div
                              style={{ height: "95vh" }}
                              className="contact-body modify-create-contact"
                            >
                              <div className="form-group first-input">
                                <label>
                                  Material Title :<b>*</b>
                                </label>
                                <select
                                  className="form-control form-control-sm"
                                  name="MaterialTitleId"
                                  value={MaterialSubTitleData.MaterialTitleId}
                                  onChange={(e) => handleChange(e)}
                                >
                                  <option>select Material Title</option>
                                  <MaterialTitleDropDown />
                                </select>
                              </div>

                              <div className="form-group first-input">
                                <label>
                                  Material Sub Title :<b>*</b>
                                </label>
                                <input
                                  type="text"
                                  className="form-control "
                                  name="MaterialSubTitle"
                                  placeholder="Enter Sub Title"
                                  value={MaterialSubTitleData.MaterialSubTitle}
                                  onChange={(e) => handleChange(e)}
                                />
                              </div>

                              <div className="form-group first-input">
                                <label>
                                  Select Material Description:
                                  <b>*</b>
                                </label>
                                <div
                                  style={{
                                    display: "flex",
                                    gap: "5px",
                                    marginTop: "6px",
                                  }}
                                >
                                  <button
                                    onClick={() => {
                                      setMaterialDesc([
                                        ...MaterialDesc,
                                        "InputText",
                                      ]);
                                      setUploadedDesc([
                                        ...UploadedDesc,
                                        { desc: "" },
                                      ]);
                                    }}
                                    className="cst_btn"
                                  >
                                    Input Box
                                  </button>
                                  <button
                                    onClick={() => {
                                      setMaterialDesc([
                                        ...MaterialDesc,
                                        "Image",
                                      ]);
                                      setUploadedDesc([
                                        ...UploadedDesc,
                                        { ...Img },
                                      ]);
                                    }}
                                    className="cst_btn"
                                  >
                                    Image
                                  </button>
                                  <button
                                    onClick={() => {
                                      setMaterialDesc([...MaterialDesc, "Mp3"]);
                                      setUploadedDesc([
                                        ...UploadedDesc,
                                        { ...audio },
                                      ]);
                                    }}
                                    className="cst_btn"
                                  >
                                    Mp3
                                  </button>
                                </div>
                              </div>

                              {MaterialDesc?.map((ek, i) => (
                                <div key={i + 674}>
                                  {ek == "InputText" ? (
                                    <div className="form-group ">
                                      <label>
                                        Description :<b>*</b>
                                      </label>
                                      <div>
                                        <ReactQuill
                                          placeholder={
                                            "Enter Material description"
                                          }
                                          value={UploadedDesc[i]?.desc}
                                          onChange={(e) => {
                                            let uploadDesc = [...UploadedDesc];
                                            uploadDesc[i].desc = e;
                                            setUploadedDesc([...uploadDesc]);
                                          }}
                                          required
                                        />
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "flex-end",
                                        }}
                                      >
                                        <span
                                          onClick={() => handleDescRemove(i)}
                                          style={{ color: "red" }}
                                        >
                                          <AiFillDelete />
                                        </span>
                                      </div>
                                    </div>
                                  ) : ek == "Image" ? (
                                    <div className="form-group">
                                      <label>
                                        Upload image :<b>*</b>
                                      </label>
                                      <div className="Input_With_Del">
                                        <input
                                          type="file"
                                          accept="image/*"
                                          filename={UploadedDesc[i]?.name}
                                          name={i + 7676}
                                          className="form-control"
                                          onChange={(e) => {
                                            let uploadDesc1 = [...UploadedDesc];
                                            uploadDesc1[i].image =
                                              e.target.files[0];
                                            uploadDesc1[i].name =
                                              e.target.files[0].name;
                                            uploadDesc1[i].url = "";
                                            const reader = new FileReader();

                                            reader.onload = (e) =>
                                              (uploadDesc1[i].preview =
                                                e.target.result);
                                            reader.readAsDataURL(
                                              e.target.files[0]
                                            );

                                            setUploadedDesc([...uploadDesc1]);
                                          }}
                                        />
                                        <span
                                          onClick={() => handleDescRemove(i)}
                                          style={{ color: "red" }}
                                        >
                                          <AiFillDelete />
                                        </span>
                                      </div>
                                    </div>
                                  ) : ek == "Mp3" ? (
                                    <div className="form-group">
                                      <label>
                                        Upload Mp3 :<b>*</b>
                                      </label>
                                      <div className="Input_With_Del">
                                        <input
                                          type="file"
                                          accept="audio/mp3"
                                          className="form-control"
                                          filename={UploadedDesc[i]?.name}
                                          name={i + 6174}
                                          onChange={(e) => {
                                            let uploadDesc = [...UploadedDesc];
                                            uploadDesc[i].audio =
                                              e.target.files[0];
                                            uploadDesc[i].name =
                                              e.target.files[0].name;
                                            uploadDesc[i].url = "";
                                            const reader = new FileReader();
                                            reader.onload = (e) =>
                                              (uploadDesc[i].preview =
                                                e.target.result);
                                            reader.readAsDataURL(
                                              e.target.files[0]
                                            );
                                            setUploadedDesc([...uploadDesc]);
                                          }}
                                        />
                                        <span
                                          onClick={() => handleDescRemove(i)}
                                          style={{ color: "red" }}
                                        >
                                          <AiFillDelete />
                                        </span>
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ))}

                              <div
                                // className="contact-footer w-100"
                                style={{
                                  // padding: "50px 25px",
                                  backgroundColor: "transparent",
                                  marginTop: "26px",
                                }}
                              ></div>

                              <div className="contact-footer w-100">
                                <p>
                                  <a
                                    className="btn btn-deep-orange btn-md"
                                    onClick={(e) => handleSubmit(e)}
                                  >
                                    {loadingsv || loadingud || loadingdet
                                      ? "Loading..."
                                      : btnText}
                                  </a>
                                  <button
                                    type="button"
                                    className="btn btn-deep-orange btn-md"
                                    onClick={() => handlePreview()}
                                  >
                                    Preview
                                  </button>
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                  <a
                                    className="btn btn-outline-deep-orange btn-md"
                                    onClick={() => handleCancel()}
                                  >
                                    Cancel
                                  </a>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="container">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="table-header-md-9 table-responsive">
                                <table className="table table-striped table-bordered">
                                  <thead>
                                    <tr>
                                      <th>Material Title</th>
                                      <th>Material Sub Title</th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {MaterialSubTitleAllRec ? (
                                      MaterialSubTitleAllRec.map((dd) => (
                                        <tr key={dd._id}>
                                          <td>
                                            {dd?.MaterialId?.MaterialTitle}
                                          </td>
                                          <td>{dd.MaterialSubTitle}</td>
                                          <td align="center">
                                            <input
                                              type="button"
                                              className="btn btn-info btn-sm"
                                              value={
                                                loadingud
                                                  ? "Loading.."
                                                  : "Update"
                                              }
                                              disabled={loadingud}
                                              onClick={(e) => {
                                                handleEdit(
                                                  e,
                                                  dd._id,
                                                  dd?.MaterialId?._id,
                                                  dd.MaterialSubTitle,
                                                  dd.MaterialDescription
                                                );
                                                setcreateSec(true);
                                                setbtnText("Update");
                                              }}
                                            />
                                            &nbsp;&nbsp;&nbsp;
                                            <input
                                              type="button"
                                              className="btn btn-info btn-sm"
                                              value={
                                                loadingdet
                                                  ? "Loading.."
                                                  : "Delete"
                                              }
                                              disabled={loadingdet}
                                              onClick={(e) => {
                                                setDeleteAlert({
                                                  msg: `Do you really want to delete this sub title (${dd.MaterialSubTitle}) ?`,
                                                  Delete: () =>
                                                    handleDelete(e, dd._id),
                                                });
                                                setOpenNext(true);
                                              }}
                                            />
                                          </td>
                                        </tr>
                                      ))
                                    ) : (
                                      <tr>
                                        <td colSpan={2}>{error}</td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!--/ body-content-with-md-9 --> */}
                  </div>
                  {/* <!--/ col-md-9 --> */}
                </div>
              </div>
            </div>
            <DeletePopup
              OpenNext={OpenNext}
              setOpenNext={setOpenNext}
              DeleteData={DeleteAlert}
            />
          </div>
          <ToastContainer />
        </div>
      </section>
    </>
  );
}
