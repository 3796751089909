import {
  COURSE_DELETE_FAIL,
  COURSE_DELETE_REQUEST,
  COURSE_DELETE_RESET,
  COURSE_DELETE_SUCCESS,
  COURSE_INSERT_FAIL,
  COURSE_INSERT_REQUEST,
  COURSE_INSERT_RESET,
  COURSE_INSERT_SUCCESS,
  COURSE_LIST_ALL_FAIL,
  COURSE_LIST_ALL_REQUEST,
  COURSE_LIST_ALL_SUCCESS,
  COURSE_LIST_FAIL,
  COURSE_LIST_REQUEST,
  COURSE_LIST_SUCCESS,
  COURSE_SINGLE_FAIL,
  COURSE_SINGLE_REQUEST,
  COURSE_SINGLE_SUCCESS,
  COURSE_UPDATE_FAIL,
  COURSE_UPDATE_REQUEST,
  COURSE_UPDATE_RESET,
  COURSE_UPDATE_SUCCESS,
} from "../Constants/courseConstant";

export const courseListReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case COURSE_LIST_REQUEST: {
      
      return { loading: true };
    }

    case COURSE_LIST_SUCCESS: {
      return { loading: true, courseRec: payload };
    }

    case COURSE_LIST_FAIL: {
      return { loading: false, error: payload };
    }
    default:
      return state;
  }
};

export const courseListAllRecReducer = (
  state = {},
  { type, payload, total }
) => {
  switch (type) {
    case COURSE_LIST_ALL_REQUEST: {
      return { loading: true };
    }

    case COURSE_LIST_ALL_SUCCESS: {
      return { loading: false, CouresesRec: payload, total };
    }

    case COURSE_LIST_ALL_FAIL: {
      return { loading: false, error: payload };
    }
    default:
      return state;
  }
};

export const courseCreateReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case COURSE_INSERT_REQUEST: {
      return { loading: true };
    }

    case COURSE_INSERT_SUCCESS: {
      return { loading: false, success: payload };
    }

    case COURSE_INSERT_FAIL: {
      return { loading: false, error: payload };
    }

    case COURSE_INSERT_RESET: {
      return { loading: false, success: "" };
    }

    default:
      return state;
  }
};

export const getCourseDetailsReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case COURSE_SINGLE_REQUEST: {
      return { loading: true };
    }

    case COURSE_SINGLE_SUCCESS: {
      return { loading: false, CourseSingleRec: payload };
    }

    case COURSE_SINGLE_FAIL: {
      return { loading: false, error: payload };
    }

    default:
      return state;
  }
};

export const courseUpdateReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case COURSE_UPDATE_REQUEST: {
      return { loading: true };
    }

    case COURSE_UPDATE_SUCCESS: {
      return { loading: false, success: payload };
    }

    case COURSE_UPDATE_FAIL: {
      return { loading: false, error: payload };
    }

    case COURSE_UPDATE_RESET: {
      return { loading: false, success: "" };
    }
    default:
      return state;
  }
};

export const courseDeleteReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case COURSE_DELETE_REQUEST: {
      return { loading: true };
    }

    case COURSE_DELETE_SUCCESS: {
      return { loading: false, success: payload };
    }

    case COURSE_DELETE_FAIL: {
      return { loading: false, error: payload };
    }

    case COURSE_DELETE_RESET: {
      return { loading: false, success: "" };
    }
    default:
      return state;
  }
};
