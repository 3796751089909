import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SettingLeftSection from "../Components/SettingLeftSection";
import DeletePopup from "../Components/DeletePoup";
import SidePanel from "../../Components/SidePanel";
import TopBar from "../../Components/TopBar";
import SEDLoading from "../../Components/SEDLoading";
import { deleteTimings } from "../../Actions/timingsAction";

import { TLat_list_Result } from "../../Actions/TLatResultAction";
import { Link } from "react-router-dom";

const TimingJson = {
  Timings: "",
  centerLocation: "",
  classModeId: "",
  courseId: "",
};

export default function PsychometricResult() {
  const dispatch = useDispatch();
  const { loading, error, TLAT_List } = useSelector(
    (s) => s.TLat_list_ResultReducer
  );

  const getTimings = useCallback(() => {
    dispatch(TLat_list_Result());
  }, [dispatch]);

  useEffect(() => {
    if (TLAT_List == undefined) {
      getTimings();
    }
  }, [TLAT_List, getTimings]);

  return (
    <>
      <SidePanel page={"T-LAT Results"} />
      <section className="home-section">
        {loading ? <SEDLoading /> : ""}
        <TopBar Heading={"T-LAT Results"} />
        <div
          className="home-content"
          style={{ paddingTop: window.innerWidth <= 480 ? "20px" : "100px" }}
        >
          <div className="content-body">
            {/* <!--Body-content--> */}
            <div className="container-fluid">
              <div className="inner-body settings-details">
                <div className="row">
                  <div className="col-md-3">
                    <SettingLeftSection />
                  </div>

                  <div className="col-md-9">
                    <div className="body-content-with-md-9">
                      <div>
                        <div className="container">
                          <div className="row">
                            <div className="col-md-12 text-left body-content-with-md-9-title">
                              <h4>T-LAT Result </h4>
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            marginTop: "20px",
                            overflow: "scroll",
                            height: "50vh",
                          }}
                          className="container"
                        >
                          <div className="row">
                            <div className="col-md-12">
                              <div className="table-header-md-9 table-responsive">
                                <table className="table table-striped table-bordered">
                                  <thead>
                                    <tr>
                                      <th>Contact Name</th>
                                      <th>Score</th>
                                      <th>Attendee Code</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {TLAT_List ? (
                                      TLAT_List.map((dd) => (
                                        <tr key={dd._id}>
                                          <td>
                                            <Link
                                              to={`/contact/details/${dd?.ContactId?._id}`}
                                              target="_blank"
                                              style={{
                                                textDecoration: "none",
                                                color: "blue",
                                              }}
                                            >
                                              {dd?.ContactId?.First_Name
                                                ? dd?.ContactId?.First_Name +
                                                  " " +
                                                  (dd?.ContactId?.Last_Name
                                                    ? dd?.ContactId?.Last_Name
                                                    : "")
                                                : "--"}
                                            </Link>
                                          </td>
                                          <td
                                            style={{
                                              display: "flex",
                                              gap: "4px",
                                            }}
                                          >
                                            <span>{dd?.Result} </span>

                                            <Link
                                              style={{ color: "blue" }}
                                              to={`/T-LAT-Result/${dd?.ContactId?._id}`}
                                              target="_blank"
                                            >
                                              Check Result
                                            </Link>
                                          </td>
                                          <td>{dd?.AttendeeCode}</td>
                                        </tr>
                                      ))
                                    ) : (
                                      <tr>
                                        <td colSpan={2}>{error}</td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!--/ body-content-with-md-9 --> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
