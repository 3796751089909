import { useEffect, useState } from "react";
import SEDLoading from "../../Components/SEDLoading";
import SidePanel from "../../Components/SidePanel";
import TopBar from "../../Components/TopBar";
import QualifiesMultiSelectDropdown from "../Components/QualifiesMultiDropDown";
import ReactDatePicker from "react-datepicker";
import { BulkEmailJson } from "../../Models/EmailTemplateModel";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { toastOptions } from "../../Models/ToastEditiorOption";
import { SEND_EMAIL_BULK_RESET } from "../../Constants/SendEmailBulkConstants";
import { sendEMailBulk } from "../../Actions/sendEmailBulkAction";

export default function SendBulkEmail() {
  const [BulkEmailData, setBulkEmailData] = useState(BulkEmailJson);
  const dispatch = useDispatch();
  const { loading, success, error } = useSelector(
    (s) => s.sendEMailBulkReducer
  );
  const handleChange = (e) => {
    setBulkEmailData({ ...BulkEmailData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // dispatch(sendEMailBulk(BulkEmailData));
  };

  const handleCancel = () => {
    setBulkEmailData({ ...BulkEmailJson });
    dispatch({ type: SEND_EMAIL_BULK_RESET });
  };

  const handlePreview = () => {};

  useEffect(() => {
    if (success) {
      toast.success(success, toastOptions);
      setTimeout(() => {
        dispatch({ type: SEND_EMAIL_BULK_RESET });
      }, 3000);
    }
    if (error) {
      toast.error(error, toastOptions);
    }
  }, [success, dispatch, error]);

  return (
    <>
      <SidePanel page={"Send Bulk Email"} />

      <section className="home-section">
        {loading ? <SEDLoading /> : ""}
        <TopBar Heading={"Send Bulk Email"} />
        <div
          className=""
          style={{ paddingTop: window.innerWidth <= 480 ? "20px" : "100px" }}
        >
          <div className="Main_Email_Container">
            <div className="note-title">
              <h4 className="text-left text-white pl-3">Send Bulk Email</h4>
            </div>
            <div className="EmailSend">
              <div className="row">
                <div className="form-group">
                  <label>
                    Branch:<b>*</b>
                  </label>
                  <select
                    className="form-control"
                    name="TemplateContent"
                    onChange={handleChange}
                  >
                    <option>Select</option>
                  </select>
                </div>

                <div className="form-group">
                  <label>
                    Qualifies:
                    <b>*</b>
                  </label>
                  <div>
                    <QualifiesMultiSelectDropdown
                      values={[]}
                      handleChange={(e) =>
                        setBulkEmailData({
                          ...BulkEmailData,
                          QualifiesData: e,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="form-group">
                  <label>
                    Date From:<b>*</b>
                  </label>
                  <div className="myDatePickerContainer">
                    <ReactDatePicker
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      yearDropdownItemNumber={100}
                      scrollableYearDropdown
                      dropdownMode="scroll"
                      dateFormat="dd-MM-yyyy"
                      className=" form-control"
                      style={{ width: "100%" }}
                      //   selected={contactFilterData.Create_Date_From}
                      onChange={(date) => {
                        //   setFilterData({
                        //     ...FilterData,
                        //     Create_Date_From: date,
                        //   });
                        // setContactFilterData({
                        //   ...contactFilterData,
                        //   Create_Date_From: date,
                        // });
                      }}
                      placeholderText="dd/mm/yy"
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label>
                    Date Till:<b>*</b>
                  </label>
                  <div className="myDatePickerContainer">
                    <ReactDatePicker
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      yearDropdownItemNumber={100}
                      scrollableYearDropdown
                      dropdownMode="scroll"
                      dateFormat="dd-MM-yyyy"
                      className=" form-control"
                      style={{ width: "100%" }}
                      //   selected={contactFilterData.Create_Date_From}
                      onChange={(date) => {
                        //   setFilterData({
                        //     ...FilterData,
                        //     Create_Date_From: date,
                        //   });
                        // setContactFilterData({
                        //   ...contactFilterData,
                        //   Create_Date_From: date,
                        // });
                      }}
                      placeholderText="dd/mm/yy"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="form-group sbj  ">
                  <label>
                    Email Subject:<b>*</b>
                  </label>
                  <input
                    type="text"
                    className="form-control "
                    name="EmailSubject"
                    placeholder="Enter Subject"
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <label>
                    Email Body:
                    <b>*</b>
                  </label>
                  <div>
                    <select
                      className="form-control"
                      name="EmailBody"
                      onChange={handleChange}
                    >
                      <option>Select</option>
                    </select>
                  </div>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "15px",
                }}
              >
                <button
                  onClick={handleSubmit}
                  className="btn btn-deep-orange btn-md"
                >
                  SEND
                </button>
                <button
                  onClick={() => handlePreview()}
                  className="btn btn-deep-orange btn-md"
                >
                  Preview
                </button>
                <button
                  onClick={() => handleCancel()}
                  className="btn btn-outline-deep-orange btn-md"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
