export const PREFERRED_INTAKE_LIST_REQUEST = 'PREFERRED_INTAKE_LIST_REQUEST'
export const PREFERRED_INTAKE_LIST_SUCCESS = 'PREFERRED_INTAKE_LIST_SUCCESS'
export const PREFERRED_INTAKE_LIST_FAIL = 'PREFERRED_INTAKE_LIST_FAIL'

export const PREFERRED_INTAKE_INSERT_REQUEST = 'PREFERRED_INTAKE_INSERT_REQUEST'
export const PREFERRED_INTAKE_INSERT_SUCCESS = 'PREFERRED_INTAKE_INSERT_SUCCESS'
export const PREFERRED_INTAKE_INSERT_FAIL = 'PREFERRED_INTAKE_INSERT_FAIL'
export const PREFERRED_INTAKE_INSERT_RESET = 'PREFERRED_INTAKE_INSERT_RESET'

export const PREFERRED_INTAKE_UPDATE_REQUEST = 'PREFERRED_INTAKE_UPDATE_REQUEST'
export const PREFERRED_INTAKE_UPDATE_SUCCESS = 'PREFERRED_INTAKE_UPDATE_SUCCESS'
export const PREFERRED_INTAKE_UPDATE_FAIL = 'PREFERRED_INTAKE_UPDATE_FAIL'
export const PREFERRED_INTAKE_UPDATE_RESET = 'PREFERRED_INTAKE_UPDATE_RESET'

export const PREFERRED_INTAKE_DELETE_REQUEST = 'PREFERRED_INTAKE_DELETE_REQUEST'
export const PREFERRED_INTAKE_DELETE_SUCCESS = 'PREFERRED_INTAKE_DELETE_SUCCESS'
export const PREFERRED_INTAKE_DELETE_FAIL = 'PREFERRED_INTAKE_DELETE_FAIL'
export const PREFERRED_INTAKE_DELETE_RESET = 'PREFERRED_INTAKE_DELETE_RESET'
