export const MATERIAL_TITLE_LIST_REQ = "MATERIAL_TITLE_LIST_REQ";
export const MATERIAL_TITLE_LIST_SUCCESS = "MATERIAL_TITLE_LIST_SUCCESS";
export const MATERIAL_TITLE_LIST_FAILED = "MATERIAL_TITLE_LIST_FAILED";
export const MATERIAL_TITLE_LIST_RESET = "MATERIAL_TITLE_LIST_RESET";

export const MATERIAL_TITLE_LIST_ALL_REQ = "MATERIAL_TITLE_LIST_ALL_REQ";
export const MATERIAL_TITLE_LIST_ALL_SUCCESS =
  "MATERIAL_TITLE_LIST_ALL_SUCCESS";
export const MATERIAL_TITLE_LIST_ALL_FAILED = "MATERIAL_TITLE_LIST_ALL_FAILED";
export const MATERIAL_TITLE_LIST_ALL_RESET = "MATERIAL_TITLE_LIST_ALL_RESET";

export const MATERIAL_TITLE_CREATE_REQ = "MATERIAL_TITLE_CREATE_REQ";
export const MATERIAL_TITLE_CREATE_SUCCESS = "MATERIAL_TITLE_CREATE_SUCCESS";
export const MATERIAL_TITLE_CREATE_FAILED = "MATERIAL_TITLE_CREATE_FAILED";
export const MATERIAL_TITLE_CREATE_RESET = "MATERIAL_TITLE_CREATE_RESET";

export const MATERIAL_TITLE_UPDATE_REQ = "MATERIAL_TITLE_UPDATE_REQ";
export const MATERIAL_TITLE_UPDATE_SUCCESS = "MATERIAL_TITLE_UPDATE_SUCCESS";
export const MATERIAL_TITLE_UPDATE_FAILED = "MATERIAL_TITLE_UPDATE_FAILED";
export const MATERIAL_TITLE_UPDATE_RESET = "MATERIAL_TITLE_UPDATE_RESET";

export const MATERIAL_TITLE_REMOVE_REQ = "MATERIAL_TITLE_REMOVE_REQ";
export const MATERIAL_TITLE_REMOVE_SUCCESS = "MATERIAL_TITLE_REMOVE_SUCCESS";
export const MATERIAL_TITLE_REMOVE_FAILED = "MATERIAL_TITLE_REMOVE_FAILED";
export const MATERIAL_TITLE_REMOVE_RESET = "MATERIAL_TITLE_REMOVE_RESET";
