export const userJson = {
  _id: "",
  First_Name: "",
  Last_Name: "",
  Username: "",
  EmailAddress: "",
  Password: "",
  PhoneNo: "",
  User_Role: "",
  Team_Id: "",
  Code: "",
  Verified: "",
  Country_Id: "",
  Gender: "",
  Branch_Id: "",
  Hierarchy_Id: "",
  DepartmentId: "",
  Deleted: "",
  Blocked: "",
  otp: "",
  PrimaryTeam: "",
  SecondaryTeam: "",
  ThirdTeam: "",
};

export const Gender_Model = [
  { Id: "1", Gender: "Male" },
  { Id: "2", Gender: "Female" },
  { Id: "3", Gender: "Others" },
];
