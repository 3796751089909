export const EMAIL_LOG_CONTACT_LIST_REQUEST = "EMAIL_LOG_CONTACT_LIST_REQUEST";
export const EMAIL_LOG_CONTACT_LIST_SUCCESS = "EMAIL_LOG_CONTACT_LIST_SUCCESS";
export const EMAIL_LOG_CONTACT_LIST_FAIL = "EMAIL_LOG_CONTACT_LIST_FAIL";

export const EMAIL_LOG_DEAL_LIST_REQUEST = "EMAIL_LOG_DEAL_LIST_REQUEST";
export const EMAIL_LOG_DEAL_LIST_SUCCESS = "EMAIL_LOG_DEAL_LIST_SUCCESS";
export const EMAIL_LOG_DEAL_LIST_FAIL = "EMAIL_LOG_DEAL_LIST_FAIL";

export const EMAIL_LOG_SUBAGENT_LIST_REQUEST =
  "EMAIL_LOG_SUBAGENT_LIST_REQUEST";
export const EMAIL_LOG_SUBAGENT_LIST_SUCCESS =
  "EMAIL_LOG_SUBAGENT_LIST_SUCCESS";
export const EMAIL_LOG_SUBAGENT_LIST_FAIL = "EMAIL_LOG_SUBAGENT_LIST_FAIL";

export const EMAIL_LOG_INSERT_REQUEST = "EMAIL_LOG_INSERT_REQUEST";
export const EMAIL_LOG_INSERT_SUCCESS = "EMAIL_LOG_INSERT_SUCCESS";
export const EMAIL_LOG_INSERT_FAIL = "EMAIL_LOG_INSERT_FAIL";
export const EMAIL_LOG_INSERT_RESET = "EMAIL_LOG_INSERT_RESET";

export const EMAIL_LOG_UPDATE_REQUEST = "EMAIL_LOG_UPDATE_REQUEST";
export const EMAIL_LOG_UPDATE_SUCCESS = "EMAIL_LOG_UPDATE_SUCCESS";
export const EMAIL_LOG_UPDATE_FAIL = "EMAIL_LOG_UPDATE_FAIL";
export const EMAIL_LOG_UPDATE_RESET = "EMAIL_LOG_UPDATE_RESET";

export const EMAIL_LOG_DELETE_REQUEST = "EMAIL_LOG_DELETE_REQUEST";
export const EMAIL_LOG_DELETE_SUCCESS = "EMAIL_LOG_DELETE_SUCCESS";
export const EMAIL_LOG_DELETE_FAIL = "EMAIL_LOG_DELETE_FAIL";
export const EMAIL_LOG_DELETE_RESET = "EMAIL_LOG_DELETE_RESET";
