// import { useDispatch } from "react-redux";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Sidebar_Handle_Open } from "../Actions/SideBarAction";
import { userLogout } from "../Actions/userActions";
import { useEffect } from "react";
export default function SidePanel({ page }) {
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const { sidebar } = useSelector((state) => state.Sidebar_Reducer);
  const MobileScreen = window.innerWidth;
  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(userLogout());
    Navigate("/login");
  };
  const handleSidebar = (e) => {
    e.preventDefault();
    if (MobileScreen <= 780) {
      dispatch(Sidebar_Handle_Open(false));
    }
  };

  useEffect(() => {
    if (window.innerWidth <= 480) {
      dispatch(Sidebar_Handle_Open(false));
    }
  }, []);

  return (
    <div className={sidebar ? "sidebar active" : "sidebar"}>
      <div className="logo-details">
        <img
          src={
            MobileScreen <= 780
              ? sidebar
                ? "/assets/images/Testifyi_logo1.jpg"
                : "/assets/images/Testifyi_Color_Icon.jpg"
              : sidebar
              ? "/assets/images/Testifyi_Color_Icon.png"
              : "/assets/images/Testifyi_logo1.jpg"
          }
          alt="logo"
        />
      </div>
      <ul className="nav-links">
        <li onClick={handleSidebar}>
          <Link to="/" className={page === "Home" ? "active" : ""}>
            <i className="bx bxs-dashboard"></i>
            <span className="links_name">Dashboard</span>
          </Link>
        </li>
        <li onClick={handleSidebar}>
          <Link to="/Contact" className={page === "Contact" ? "active" : ""}>
            <i className="bx bxs-contact"></i>
            <span className="links_name">Contact</span>
          </Link>
        </li>
        {/* <li onClick={handleSidebar}>
          <Link to="/Material" className={page === "Material" ? "active" : ""}>
            <i className="bx bx-paperclip"></i>
            <span className="links_name">Material</span>
          </Link>
        </li> */}

        <li onClick={handleSidebar}>
          <Link to="/Packages" className={page === "Packages" ? "active" : ""}>
            <i className="bx bxs-package"></i>
            <span className="links_name">Packages</span>
          </Link>
        </li>

        <li onClick={handleSidebar}>
          <Link to="/course" className={page === "Course" ? "active" : ""}>
            <i className="bx bx-book-open"></i>
            <span className="links_name">Course</span>
          </Link>
        </li>

        <li onClick={handleSidebar}>
          <Link to="/cart" className={page === "Cart" ? "active" : ""}>
            <i className="bx bxs-cart-alt"></i>
            <span className="links_name">Cart</span>
          </Link>
        </li>

        <li onClick={handleSidebar}>
          <Link to="/orders" className={page === "Orders" ? "active" : ""}>
            <i className="bx bxs-wallet"></i>
            <span className="links_name">Orders</span>
          </Link>
        </li>

        <li onClick={handleSidebar}>
          <Link to="/Coupons" className={page === "Coupons" ? "active" : ""}>
            <i className="bx bxs-coupon"></i>
            <span className="links_name">Coupons</span>
          </Link>
        </li>


        <li onClick={handleSidebar}>
          <Link to="/mock-test" className={page === "MockTest" ? "active" : ""}>
            <i className="bx bxs-coupon"></i>
            <span className="links_name">Mock Test</span>
          </Link>
        </li>

        <li className="log_out" onClick={handleLogout}>
          <Link to="#">
            <i className="bx bx-log-out"></i>
            <span className="links_name">Log out</span>
          </Link>
        </li>
      </ul>
    </div>
  );
}
