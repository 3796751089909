import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  deleteCallLogDetails,
  listCallLogContact,
  listCallLogDeal,
  listCallLogSubagent,
} from "../../../Actions/callLogActions";
import CallOutcomeDropdown from "../CallOutcomeDropdown";
import { TimeStampConverter2 } from "../EmailHide";
import { GET_TIME_ZONE } from "../Time";
import { MdCall } from "react-icons/md";

export default function CallLog({
  setCallEdit,
  subagentId,
  contactId,
  dealId,
  openCallTab,
}) {
  const dispatch = useDispatch();
  const [CallData, setCallData] = useState([]);
  const getCalls = useCallback(() => {
    if (subagentId) {
      dispatch(listCallLogSubagent(subagentId));
    } else if (dealId) {
      dispatch(listCallLogDeal(dealId));
    } else {
      dispatch(listCallLogContact(contactId));
    }
  }, [subagentId, contactId, dealId, dispatch]);

  const {
    loading: loadingntc,
    error: errorntc,
    callLogContactRec,
  } = useSelector((s) => s.callLogContactList);

  const {
    loading: loadingntd,
    error: errorntd,
    callLogDealRec,
  } = useSelector((s) => s.callLogDealList);

  const {
    loading: loadingntS,
    error: errorntS,
    callLogSubAgentRec,
  } = useSelector((S) => S.callLogSubAgentList);

  const { success: successdlt } = useSelector((s) => s.callLogDelete);

  useEffect(() => {
    if (!callLogContactRec && !callLogDealRec && !callLogSubAgentRec) {
      getCalls();
    }
    if (callLogContactRec) {
      setCallData(callLogContactRec);
    } else if (callLogSubAgentRec) {
      setCallData(callLogSubAgentRec);
    } else {
      setCallData(callLogDealRec);
    }
  }, [callLogContactRec, callLogDealRec, callLogSubAgentRec, getCalls]);

  //  Delete Note

  const handleDelete = (Id) => {
    dispatch(deleteCallLogDetails(Id));
  };

  useEffect(() => {
    if (successdlt) {
      if (contactId) getCalls();
      else if (dealId) getCalls();
      else if (subagentId) getCalls(subagentId);
    }
  }, [successdlt]);

  return (
    <div>
      <div className="create-note">
        <div className="row">
          <div className="col-md-12 text-right">
            <Link className="btn-outer" onClick={() => openCallTab()}>
              Log Call
            </Link>
            <Link className="btn">Make a phone call</Link>
          </div>
          {CallData?.length ? (
            ""
          ) : (
            <div className="col-md-12 text-center mt-3">
              <p>
                Call a contact from this record. Or log a call activity to keep
                track of your discussion and notes.
                <Link>Learn more</Link>
              </p>
            </div>
          )}
        </div>
      </div>
      {CallData
        ? CallData?.map((el) => (
            <div key={el._id} className="output-task-details">
              <div className="output-task-header">
                <div className="row">
                  <div className="col-md-6 text-left">
                    <span style={{ fontSize: "20px", marginRight: "7px" }}>
                      <MdCall />
                    </span>
                    <h5>Logged call</h5>
                  </div>
                  <div className="col-md-6 text-right">
                    <span>{GET_TIME_ZONE(el.createdAt)}</span>
                    <ul className="list-inline right-action-menu">
                      <li className="list-inline-item">
                        <Link
                          onClick={() => {
                            setCallEdit(el);
                            openCallTab();
                          }}
                        >
                          Edit
                        </Link>
                      </li>
                      <li className="list-inline-item">
                        <Link onClick={() => handleDelete(el._id)}>Delete</Link>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-12 pl-5 pt-3">
                    <div
                      contentEditable="false"
                      dangerouslySetInnerHTML={{ __html: el?.CallDetail }}
                    ></div>
                  </div>
                </div>
                <hr />
              </div>

              <div>
                <div className="output-task-body pl-4">
                  <div className="row">
                    <div className="col-md-3">
                      <span>Contacted</span>
                      <div className="form-group">
                        <p style={{ color: "#0c3eff" }} className=" w-100">
                          {" "}
                          {el?.SubAgent_Id
                          ? el?.SubAgent_Id?.Name
                          : el?.Contact_Id?.full_name
                          ? el?.Contact_Id?.full_name
                          : el?.Contact_Id?.First_Name
                          ? el?.Contact_Id?.First_Name +
                            " " +
                            el?.Contact_Id?.Last_Name
                          : "N/A"}{" "}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <span>Call outcome</span>
                      <div className="form-group">
                        <select
                          value={el?.Call_Outcome}
                          className="form-control w-100"
                          style={{ padding: "5px 0" }}
                          disabled={true}
                        >
                          <option value="">N/A</option>
                          <CallOutcomeDropdown />
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <span>Date</span>
                      <input
                        type="date"
                        name="task-date"
                        value={
                          el?.Call_Date ? TimeStampConverter2(el.Call_Date) : ""
                        }
                        className="w-100"
                        disabled={true}
                        style={{ border: "none" }}
                      />
                    </div>
                    <div className="col-md-3">
                      <span>Time</span>
                      <input
                        name="task-time"
                        className="w-100"
                        value={el.Call_Time}
                        disabled={true}
                        style={{ border: "none" }}
                      />
                    </div>
                    <div className="col-md-12">
                      <hr />
                    </div>
                    <div className="col-md-12 pl-5">
                      <h6>
                        <i
                          className="fas fa-user-circle"
                          style={{ color: "#d1d4d4" }}
                        ></i>
                        <b style={{ fontWeight: "500" }}>
                          {" "}
                          {el?.Created_By?.First_Name
                            ? el?.Created_By?.First_Name +
                              " " +
                              el?.Created_By?.Last_Name
                            : ""}
                        </b>{" "}
                        logged a call
                      </h6>
                    </div>
                  </div>
                  <hr />
                </div>
                <div className="output-task-footer d-flex">
                  <i
                    className="fas fa-comment-dots"
                    style={{ cursor: "pointer" }}
                  ></i>
                  <p>Add Comment</p>
                </div>
              </div>
            </div>
          ))
        : ""}
    </div>
  );
}
