export const CART_LIST_REQ = "CART_LIST_REQ";
export const CART_LIST_SUCCESS = "CART_LIST_SUCCESS";
export const CART_LIST_FAILED = "CART_LIST_FAILED";
export const CART_LIST_RESET = "CART_LIST_RESET";

export const CART_TOTAL_LIST_REQ = "CART_TOTAL_LIST_REQ";
export const CART_TOTAL_LIST_SUCCESS = "CART_TOTAL_LIST_SUCCESS";
export const CART_TOTAL_LIST_FAILED = "CART_TOTAL_LIST_FAILED";
export const CART_TOTAL_LIST_RESET = "CART_TOTAL_LIST_RESET";

export const CART_DETAIL_CREATE_REQ = "CART_DETAIL_CREATE_REQ";
export const CART_DETAIL_CREATE_SUCCESS = "CART_DETAIL_CREATE_SUCCESS";
export const CART_DETAIL_CREATE_FAILED = "CART_DETAIL_CREATE_FAILED";
export const CART_DETAIL_CREATE_RESET = "CART_DETAIL_CREATE_RESET";

export const CART_DETAIL_UPDATE_REQ = "CART_DETAIL_UPDATE_REQ";
export const CART_DETAIL_UPDATE_SUCCESS = "CART_DETAIL_UPDATE_SUCCESS";
export const CART_DETAIL_UPDATE_FAILED = "CART_DETAIL_UPDATE_FAILED";
export const CART_DETAIL_UPDATE_RESET = "CART_DETAIL_UPDATE_RESET";

export const CART_DETAIL_REMOVE_REQ = "CART_DETAIL_REMOVE_REQ";
export const CART_DETAIL_REMOVE_SUCCESS = "CART_DETAIL_REMOVE_SUCCESS";
export const CART_DETAIL_REMOVE_FAILED = "CART_DETAIL_REMOVE_FAILED";
export const CART_DETAIL_REMOVE_RESET = "CART_DETAIL_REMOVE_RESET";

export const CART_PLACE_ORDER_REQ = "CART_PLACE_ORDER_REQ";
export const CART_PLACE_ORDER_SUCCESS = "CART_PLACE_ORDER_SUCCESS";
export const CART_PLACE_ORDER_FAILED = "CART_PLACE_ORDER_FAILED";
export const CART_PLACE_ORDER_RESET = "CART_PLACE_ORDER_RESET";
