import {
  TEAM_DELETE_FAIL,
  TEAM_DELETE_REQUEST,
  TEAM_DELETE_SUCCESS,
  TEAM_INSERT_FAIL,
  TEAM_INSERT_REQUEST,
  TEAM_INSERT_SUCCESS,
  TEAM_LIST_FAIL,
  TEAM_LIST_REQUEST,
  TEAM_LIST_SUCCESS,
  TEAM_UPDATE_FAIL,
  TEAM_UPDATE_REQUEST,
  TEAM_UPDATE_SUCCESS,
} from "../Constants/teamConstants";
import api from "../core/api";

export const listTeam = () => async (dispatch) => {
  try {
    dispatch({
      type: TEAM_LIST_REQUEST,
    });
    const { data } = await api.get(`/team`);

    var dt = data.Result;

    dispatch({
      type: TEAM_LIST_SUCCESS,
      payload: dt,
    });
  } catch (error) {
    dispatch({
      type: TEAM_LIST_FAIL,
      payload:
        error.response && error?.response?.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const createTeam = (inputJson) => async (dispatch) => {
  try {
    dispatch({
      type: TEAM_INSERT_REQUEST,
    });
    const { data } = await api.post(`/team`, inputJson);
    var dt = data.Result;

    dispatch({
      type: TEAM_INSERT_SUCCESS,
      payload: dt,
    });
  } catch (error) {
    dispatch({
      type: TEAM_INSERT_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const updateTeam = (id, inputJson) => async (dispatch) => {
  try {
    dispatch({
      type: TEAM_UPDATE_REQUEST,
    });
    const { data } = await api.put(`/team/${id}`, inputJson);
    var dt = data.Result;

    dispatch({
      type: TEAM_UPDATE_SUCCESS,
      payload: dt,
    });
  } catch (error) {
    dispatch({
      type: TEAM_UPDATE_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const deleteTeam = (id) => async (dispatch) => {
  try {
    dispatch({
      type: TEAM_DELETE_REQUEST,
    });
    const { data } = await api.delete(`/team/${id}`);
    var dt = data.Result;

    dispatch({
      type: TEAM_DELETE_SUCCESS,
      payload: dt,
    });
  } catch (error) {
    dispatch({
      type: TEAM_DELETE_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};
