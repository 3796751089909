import { useEffect, useState } from "react";

export default function Pagination({ setFilterQuery, FilterQuery, Total }) {
  const [activePage, setActivePage] = useState(1);
  const records = Math.ceil(Total / FilterQuery.page_size);
  const arr = Array.from({ length: records }, (_, index) => index + 1);

  useEffect(() => {
    setActivePage(FilterQuery.page_no);
  }, [FilterQuery]);

  return (
    <>
      <div className="Pagination">
        <div>
          <button
            disabled={activePage === 1 || Total === 0 || Total === undefined}
            onClick={() =>
              setFilterQuery({
                ...FilterQuery,
                page_no: FilterQuery.page_no - 1,
              })
            }
            className={
              activePage === 1 ? "btn-pagination deactive" : "btn-pagination"
            }
          >
            Previous
          </button>
        </div>
        {arr?.map((el, i) => (
          <li
            onClick={() => setFilterQuery({ ...FilterQuery, page_no: el })}
            key={i + 789}
            className={
              i <= activePage + 2 && i >= activePage - 3
                ? "page-item"
                : "page-item2"
            }
          >
            <button
              disabled={activePage === el}
              style={{
                background: "transparent",
                color: "black",
                padding: "5px 10px",
                border: "0.1px solid grey",
              }}
              className={
                i === activePage - 1
                  ? "pagination:number pageActive "
                  : "pagination:number"
              }
            >
              {el}
            </button>
          </li>
        ))}
        <div className="page-item">
          <button
            disabled={
              activePage === arr.length || Total === 0 || Total === undefined
            }
            onClick={() =>
              setFilterQuery({
                ...FilterQuery,
                page_no: FilterQuery.page_no + 1,
              })
            }
            className={
              activePage === arr.length || Total === 0 || Total === undefined
                ? "btn-pagination deactive"
                : "btn-pagination button_color"
            }
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
}
