export const SUBJECT_LIST_REQUEST = 'SUBJECT_LIST_REQUEST'
export const SUBJECT_LIST_SUCCESS = 'SUBJECT_LIST_SUCCESS'
export const SUBJECT_LIST_FAIL = 'SUBJECT_LIST_FAIL'

export const SUBJECT_INSERT_REQUEST = 'SUBJECT_INSERT_REQUEST'
export const SUBJECT_INSERT_SUCCESS = 'SUBJECT_INSERT_SUCCESS'
export const SUBJECT_INSERT_FAIL = 'SUBJECT_INSERT_FAIL'
export const SUBJECT_INSERT_RESET = 'SUBJECT_INSERT_RESET'

export const SUBJECT_UPDATE_REQUEST = 'SUBJECT_UPDATE_REQUEST'
export const SUBJECT_UPDATE_SUCCESS = 'SUBJECT_UPDATE_SUCCESS'
export const SUBJECT_UPDATE_FAIL = 'SUBJECT_UPDATE_FAIL'
export const SUBJECT_UPDATE_RESET = 'SUBJECT_UPDATE_RESET'

export const SUBJECT_DELETE_REQUEST = 'SUBJECT_DELETE_REQUEST'
export const SUBJECT_DELETE_SUCCESS = 'SUBJECT_DELETE_SUCCESS'
export const SUBJECT_DELETE_FAIL = 'SUBJECT_DELETE_FAIL'
export const SUBJECT_DELETE_RESET = 'SUBJECT_DELETE_RESET'
