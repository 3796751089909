import {
  TEST_TYPE_LIST_FAILED,
  TEST_TYPE_LIST_REQ,
  TEST_TYPE_LIST_SUCCESS,
} from "../Constants/TestTypeConstnat";
import api from "../core/api";

export const listTestType = () => async (dispatch) => {
  try {
    dispatch({ type: TEST_TYPE_LIST_REQ });
    const { data } = await api.get(`/commonEnums/testType`);
    dispatch({
      type: TEST_TYPE_LIST_SUCCESS,
      payload: data.Result,
    });
  } catch (error) {
    dispatch({
      type: TEST_TYPE_LIST_FAILED,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};
