import {
  COURSE_DELETE_FAIL,
  COURSE_DELETE_REQUEST,
  COURSE_DELETE_SUCCESS,
  COURSE_INSERT_FAIL,
  COURSE_INSERT_REQUEST,
  COURSE_INSERT_SUCCESS,
  COURSE_LIST_ALL_FAIL,
  COURSE_LIST_ALL_REQUEST,
  COURSE_LIST_ALL_SUCCESS,
  COURSE_LIST_FAIL,
  COURSE_LIST_REQUEST,
  COURSE_LIST_SUCCESS,
  COURSE_SINGLE_FAIL,
  COURSE_SINGLE_REQUEST,
  COURSE_SINGLE_SUCCESS,
  COURSE_UPDATE_FAIL,
  COURSE_UPDATE_REQUEST,
  COURSE_UPDATE_SUCCESS,
} from "../Constants/courseConstant";
import api from "../core/api";

export const listCourseByUniversityId = (universityId) => async (dispatch) => {
  try {
    dispatch({ type: COURSE_LIST_REQUEST });
    const { data } = await api.get(`/courses/usinguniversity/${universityId}`);
    const res = data.Result;
    dispatch({ type: COURSE_LIST_SUCCESS, payload: res });
  } catch (error) {
    dispatch({
      type: COURSE_LIST_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const listCourses = (CourseJson) => async (dispatch) => {
  try {
    dispatch({
      type: COURSE_LIST_ALL_REQUEST,
    });
    const { data } = await api.get(`/courses`, CourseJson);
    const res = data.Result;
    dispatch({
      type: COURSE_LIST_ALL_SUCCESS,
      payload: res,
      total: data?.Total_Courses,
    });
  } catch (error) {
    dispatch({
      type: COURSE_LIST_ALL_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const createCourse = (inputJson) => async (dispatch) => {
  try {
    dispatch({
      type: COURSE_INSERT_REQUEST,
    });
    const { data } = await api.post(`/courses`, inputJson);
    const res = data.Result;
    dispatch({
      type: COURSE_INSERT_SUCCESS,
      payload: res,
    });
  } catch (error) {
    dispatch({
      type: COURSE_INSERT_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const getCourseDetails = (Id) => async (dispatch) => {
  try {
    dispatch({
      type: COURSE_SINGLE_REQUEST,
    });
    const { data } = await api.get(`/courses/${Id}`);
    const res = data.Result;
    dispatch({
      type: COURSE_SINGLE_SUCCESS,
      payload: res,
    });
  } catch (error) {
    dispatch({
      type: COURSE_SINGLE_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const updateCourse = (Id, CourseData) => async (dispatch) => {
  try {
    dispatch({
      type: COURSE_UPDATE_REQUEST,
    });
   
    const { data } = await api.put(`/courses/${Id}`, CourseData);
    const res = data.Result;
    dispatch({
      type: COURSE_UPDATE_SUCCESS,
      payload: res,
    });
   
  } catch (error) {
    
    dispatch({
      type: COURSE_UPDATE_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const deleteCourse = (Id) => async (dispatch) => {
  try {
    dispatch({
      type: COURSE_DELETE_REQUEST,
    });
    const { data } = await api.delete(`/courses/${Id}`);
    const res = data.Result;
    dispatch({
      type: COURSE_DELETE_SUCCESS,
      payload: res,
    });
  } catch (error) {
    dispatch({
      type: COURSE_DELETE_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};
