export const DEAL_STAGE_LIST_REQUEST = 'DEAL_STAGE_LIST_REQUEST'
export const DEAL_STAGE_LIST_SUCCESS = 'DEAL_STAGE_LIST_SUCCESS'
export const DEAL_STAGE_LIST_FAIL = 'DEAL_STAGE_LIST_FAIL'

export const DEAL_STAGE_GET_REQUEST = 'DEAL_STAGE_GET_REQUEST'
export const DEAL_STAGE_GET_SUCCESS = 'DEAL_STAGE_GET_SUCCESS'
export const DEAL_STAGE_GET_FAIL = 'DEAL_STAGE_GET_FAIL'

export const DEAL_STAGE_INSERT_REQUEST = 'DEAL_STAGE_INSERT_REQUEST'
export const DEAL_STAGE_INSERT_SUCCESS = 'DEAL_STAGE_INSERT_SUCCESS'
export const DEAL_STAGE_INSERT_FAIL = 'DEAL_STAGE_INSERT_FAIL'
export const DEAL_STAGE_INSERT_RESET = 'DEAL_STAGE_INSERT_RESET'

export const DEAL_STAGE_UPDATE_REQUEST = 'DEAL_STAGE_UPDATE_REQUEST'
export const DEAL_STAGE_UPDATE_SUCCESS = 'DEAL_STAGE_UPDATE_SUCCESS'
export const DEAL_STAGE_UPDATE_FAIL = 'DEAL_STAGE_UPDATE_FAIL'
export const DEAL_STAGE_UPDATE_RESET = 'DEAL_STAGE_UPDATE_RESET'

export const DEAL_STAGE_DELETE_REQUEST = 'DEAL_STAGE_DELETE_REQUEST'
export const DEAL_STAGE_DELETE_SUCCESS = 'DEAL_STAGE_DELETE_SUCCESS'
export const DEAL_STAGE_DELETE_FAIL = 'DEAL_STAGE_DELETE_FAIL'
export const DEAL_STAGE_DELETE_RESET = 'DEAL_STAGE_DELETE_RESET'
