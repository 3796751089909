import {
  CURRENCY_DELETE_FAIL,
  CURRENCY_DELETE_REQUEST,
  CURRENCY_DELETE_RESET,
  CURRENCY_DELETE_SUCCESS,
  CURRENCY_INSERT_FAIL,
  CURRENCY_INSERT_REQUEST,
  CURRENCY_INSERT_RESET,
  CURRENCY_INSERT_SUCCESS,
  CURRENCY_LIST_FAIL,
  CURRENCY_LIST_REQUEST,
  CURRENCY_LIST_SUCCESS,
  CURRENCY_UPDATE_FAIL,
  CURRENCY_UPDATE_REQUEST,
  CURRENCY_UPDATE_RESET,
  CURRENCY_UPDATE_SUCCESS,
} from "../Constants/currencyConstants";

export const currencyListReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case CURRENCY_LIST_REQUEST:
      return { loading: true };
    case CURRENCY_LIST_SUCCESS:
      return { loading: false, currencyRec: payload };
    case CURRENCY_LIST_FAIL:
      return { loading: false, error: payload };
    default:
      return state;
  }
};

export const currencySaveReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case CURRENCY_INSERT_REQUEST:
      return { loading: true };
    case CURRENCY_INSERT_SUCCESS:
      return { loading: false, success: payload };
    case CURRENCY_INSERT_FAIL:
      return { loading: false, error: payload };
    case CURRENCY_INSERT_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const currencyUpdateReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case CURRENCY_UPDATE_REQUEST:
      return { loading: true };
    case CURRENCY_UPDATE_SUCCESS:
      return { loading: false, success: payload };
    case CURRENCY_UPDATE_FAIL:
      return { loading: false, error: payload };
    case CURRENCY_UPDATE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const currencyDeleteReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case CURRENCY_DELETE_REQUEST:
      return { loading: true };
    case CURRENCY_DELETE_SUCCESS:
      return { loading: false, success: payload };
    case CURRENCY_DELETE_FAIL:
      return { loading: false, error: payload };
    case CURRENCY_DELETE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};
