import {
  DEFAULT_BRANCH_USER_INSERT_FAIL,
  DEFAULT_BRANCH_USER_INSERT_REQUEST,
  DEFAULT_BRANCH_USER_INSERT_RESET,
  DEFAULT_BRANCH_USER_INSERT_SUCCESS,
  DEFAULT_BRANCH_USER_LIST_FAIL,
  DEFAULT_BRANCH_USER_LIST_REQUEST,
  DEFAULT_BRANCH_USER_LIST_SUCCESS,
} from "../Constants/defaultBranchUsersConstants";

export const defaultBranchUserListReducer = (state = {}, action) => {
  switch (action.type) {
    case DEFAULT_BRANCH_USER_LIST_REQUEST:
      return { loading: true };
    case DEFAULT_BRANCH_USER_LIST_SUCCESS:
      return { loading: false, defaultBranchUserRecs: action.payload };
    case DEFAULT_BRANCH_USER_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const defaultBranchUserInsertReducer = (state = {}, action) => {
  switch (action.type) {
    case DEFAULT_BRANCH_USER_INSERT_REQUEST:
      return { loading: true };
    case DEFAULT_BRANCH_USER_INSERT_SUCCESS:
      return { loading: false, success: action.payload };
    case DEFAULT_BRANCH_USER_INSERT_FAIL:
      return { loading: false, error: action.payload };
    case DEFAULT_BRANCH_USER_INSERT_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};
