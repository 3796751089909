import {
  EVENT_CREATE_FAIL,
  EVENT_CREATE_REQUEST,
  EVENT_CREATE_RESET,
  EVENT_CREATE_SUCCESS,
  EVENT_GET_FAIL,
  EVENT_GET_REQUEST,
  EVENT_GET_SUCCESS,
  EVENT_LIST_FAIL,
  EVENT_LIST_REQUEST,
  EVENT_LIST_SUCCESS,
  EVENT_UPDATE_FAIL,
  EVENT_UPDATE_REQUEST,
  EVENT_UPDATE_RESET,
  EVENT_UPDATE_SUCCESS,
  EVENT_UPLOAD_IMAGE_FAIL,
  EVENT_UPLOAD_IMAGE_REQUEST,
  EVENT_UPLOAD_IMAGE_RESET,
  EVENT_UPLOAD_IMAGE_SUCCESS,
} from "../Constants/eventConstants";

export const eventListReducer = (state = {}, { type, payload, total }) => {
  switch (type) {
    case EVENT_LIST_REQUEST: {
      return { loading: true };
    }

    case EVENT_LIST_SUCCESS: {
      return { laoding: false, eventRec: payload, total: total };
    }

    case EVENT_LIST_FAIL: {
      return { laoding: false, error: payload };
    }

    default:
      return state;
  }
};

export const eventCreateReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case EVENT_CREATE_REQUEST: {
      return { laoding: true };
    }

    case EVENT_CREATE_SUCCESS: {
      return { loading: false, success: payload };
    }

    case EVENT_CREATE_FAIL: {
      return { laoding: false, error: payload };
    }

    case EVENT_CREATE_RESET: {
      return { loading: false, success: "" };
    }

    default:
      return state;
  }
};

export const eventUpdateReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case EVENT_UPDATE_REQUEST: {
      return { loading: true };
    }

    case EVENT_UPDATE_SUCCESS: {
      return { loading: false, success: payload };
    }

    case EVENT_UPDATE_FAIL: {
      return { loading: false, error: payload };
    }

    case EVENT_UPDATE_RESET: {
      return { loading: false, success: "" };
    }

    default:
      return state;
  }
};

export const eventGetReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case EVENT_GET_REQUEST: {
      return { loading: true };
    }

    case EVENT_GET_SUCCESS: {
      return { loading: false, eventSingleRec: payload };
    }

    case EVENT_GET_FAIL: {
      return { loading: true, error: payload };
    }

    default:
      return state;
  }
};

export const eventUploadMobileImageReducer = (
  state = {},
  { type, payload }
) => {
  switch (type) {
    case EVENT_UPLOAD_IMAGE_REQUEST: {
      return { loading: true };
    }

    case EVENT_UPLOAD_IMAGE_SUCCESS: {
      return { loading: false, success: payload };
    }

    case EVENT_UPLOAD_IMAGE_FAIL: {
      return { loading: false, error: payload };
    }

    case EVENT_UPLOAD_IMAGE_RESET: {
      return { loading: false, success: "" };
    }

    default:
      return state;
  }
};
// export const setEventVisibility = (eventJson) => async (dispatch) => {
//   try {
//     dispatch({
//       type: EVENT_SET_VISIBILITY_REQUEST,
//     });

//     const { data } = await api.put(`/event/updateVisibility`, eventJson);
//     const res = data.Result;

//     dispatch({
//       type: EVENT_SET_VISIBILITY_SUCCESS,
//       payload: res,
//     });
//   } catch (error) {
//     dispatch({
//       type: EVENT_SET_VISIBILITY_FAIL,
//       payload:
//         error.response && error.response.data.Result
//           ? error.response.data.Result
//           : error.message,
//     });
//   }
// };
