import {
  MOCKTEST_CREATE_FAILED,
  MOCKTEST_CREATE_REQ,
  MOCKTEST_CREATE_SUCCESS,
  MOCKTEST_DETAILS_FAILED,
  MOCKTEST_DETAILS_REQ,
  MOCKTEST_DETAILS_SUCCESS,
  MOCKTEST_LIST_FAILED,
  MOCKTEST_LIST_REQ,
  MOCKTEST_LIST_SUCCESS,
  MOCKTEST_REMOVE_FAILED,
  MOCKTEST_REMOVE_REQ,
  MOCKTEST_REMOVE_SUCCESS,
  MOCKTEST_TOTAL_FAILED,
  MOCKTEST_TOTAL_REQ,
  MOCKTEST_TOTAL_SUCCESS,
  MOCKTEST_UPDATE_FAILED,
  MOCKTEST_UPDATE_REQ,
  MOCKTEST_UPDATE_SUCCESS,
} from "../Constants/MockTestConstant";
import api from "../core/api";

export const createMockTest = (MockTestData) => async (dispatch) => {
  try {
    dispatch({ type: MOCKTEST_CREATE_REQ });
    const { data } = await api.post("/mocktest", MockTestData);

    dispatch({ type: MOCKTEST_CREATE_SUCCESS, payload: data?.Result });
  } catch (ex) {
    dispatch({
      type: MOCKTEST_CREATE_FAILED,
      payload:
        ex.response && ex.response.data.Result
          ? ex.response.data.Result
          : ex.message,
    });
  }
};

export const totalMockTest = () => async (dispatch) => {
  try {
    dispatch({ type: MOCKTEST_TOTAL_REQ });
    const { data } = await api.get("/mocktest/total");
    dispatch({ type: MOCKTEST_TOTAL_SUCCESS, payload: data?.Result });
  } catch (ex) {
    dispatch({
      type: MOCKTEST_TOTAL_FAILED,
      payload:
        ex.response && ex?.response?.data?.Result
          ? ex.response.data.Result
          : ex.message,
    });
  }
};

export const listMockTest = () => async (dispatch) => {
  try {
    dispatch({ type: MOCKTEST_LIST_REQ });
    const { data } = await api.get("/mocktest");

    dispatch({ type: MOCKTEST_LIST_SUCCESS, payload: data?.Result });
  } catch (ex) {
    dispatch({
      type: MOCKTEST_LIST_FAILED,
      payload:
        ex.response && ex?.response?.data?.Result
          ? ex.response.data.Result
          : ex.message,
    });
  }
};

export const updateMockTest = (MockTestData, Id) => async (dispatch) => {
 
  try {
    dispatch({ type: MOCKTEST_UPDATE_REQ });
    const { data } = await api.put(`/mocktest/${Id}`, MockTestData);
    dispatch({ type: MOCKTEST_UPDATE_SUCCESS, payload: data?.Result });
  } catch (ex) {
    dispatch({
      type: MOCKTEST_UPDATE_FAILED,
      payload:
        ex.response && ex.response.data.Result
          ? ex.response.data.Result
          : ex.message,
    });
  }
};

export const removeMockTest = (Id) => async (dispatch) => {
  try {
    dispatch({ type: MOCKTEST_REMOVE_REQ });
    const { data } = await api.delete(`/mocktest/${Id}`);
    dispatch({ type: MOCKTEST_REMOVE_SUCCESS, payload: data?.Result });
  } catch (ex) {
    dispatch({
      type: MOCKTEST_REMOVE_FAILED,
      payload:
        ex.response && ex.response.data.Result
          ? ex.response.data.Result
          : ex.message,
    });
  }
};
export const mockDetails = (Id) => async (dispatch) => {
  try {
    dispatch({ type: MOCKTEST_DETAILS_REQ });
    const { data } = await api.get(`/mocktest/${Id}`);
    dispatch({ type: MOCKTEST_DETAILS_SUCCESS, payload: data?.Result });
  } catch (ex) {
    dispatch({
      type: MOCKTEST_DETAILS_FAILED,
      payload:
        ex.response && ex.response.data.Result
          ? ex.response.data.Result
          : ex.message,
    });
  }
};
