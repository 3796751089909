import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { changeHeader } from "../../Actions/commonActions";
import Notifications from "./Notifications";
import Tasks from "./Tasks";
import ReminderTotal from "./ReminderTotal";
import Meta from "../../Components/Meta";
import SidePanel from "../../Components/SidePanel";
import TopBar from "../../Components/TopBar";

function Index(props) {
  const dispatch = useDispatch();
  const MetaDes = {};

  useEffect(() => {
    dispatch(changeHeader("Dashboard"));
  }, [dispatch]);

  return (
    <>
      <Meta MetaDes={MetaDes} />
      <SidePanel page={"Dashboard"} />
      <section className="home-section">
        <TopBar Heading="Dashboard" />
        <div className="home-content" style={{ paddingTop: "100px" }}>
          <div className="container-fluid">
            <div className="inner-body">
              <div id="WindowMsg" className="modal fade">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-hidden="true"
                      >
                        &times;
                      </button>
                      <h4 className="modal-title"></h4>
                    </div>
                    <div
                      className="modal-body p-4"
                      style={{ borderBottom: "2px solid #f26524" }}
                    >
                      <p style={{ color: "#555" }}>
                        Oh! We can see that you have not completed the
                        registration. Please complete the required fields from
                        below link.
                      </p>
                      <p style={{ color: "#555" }}>
                        <a target="_blank" style={{ fontWeight: "bold" }}>
                          Click here
                        </a>
                        to complete your registration.
                      </p>

                      <button
                        className="btn btn-deep-orange waves-effect waves-light"
                        data-dismiss="modal"
                        aria-hidden="true"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ paddingBottom: "40px" }} className="dashbaord-ipad">
                <div className="sed-mx-hgt-30">
                  <div className="row">
                    <div className="col-md-5 col-lg-5">
                      <div className="">
                        <div
                          className="col-md-12 text-left body-content-with-md-9-title Dashboard-sticky"
                          style={{ margin: "0px" }}
                        >
                          <i className="fas fa-bell"></i>
                          <h3 style={{ display: "inline" }}>Notifications</h3>
                        </div>
                        <div className="center-details-body center-body center-details custom-scroll-right">
                          <Notifications />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-7 col-lg-7">
                      <div className="">
                        <div
                          className="col-md-12 text-left body-content-with-md-9-title Dashboard-sticky"
                          style={{ margin: "0px" }}
                        >
                          <i className="far fa-clock"></i>
                          <h3 style={{ display: "inline" }} className="h3-none">
                            Reminders
                          </h3>

                          <ReminderTotal />
                        </div>

                        <div className="center-details-body center-body">
                          <div className="coctacts-table">
                            <Tasks />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Index;
