export const STUDY_MATERIAL_LIST_REQUEST = "STUDY_MATERIAL_LIST_REQUEST";
export const STUDY_MATERIAL_LIST_SUCCESS = "STUDY_MATERIAL_LIST_SUCCESS";
export const STUDY_MATERIAL_LIST_FAIL = "STUDY_MATERIAL_LIST_FAIL";

export const STUDY_MATERIAL_GET_REQUEST = "STUDY_MATERIAL_GET_REQUEST";
export const STUDY_MATERIAL_GET_SUCCESS = "STUDY_MATERIAL_GET_SUCCESS";
export const STUDY_MATERIAL_GET_FAIL = "STUDY_MATERIAL_GET_FAIL";

export const STUDY_MATERIAL_INSERT_REQUEST = "STUDY_MATERIAL_INSERT_REQUEST";
export const STUDY_MATERIAL_INSERT_SUCCESS = "STUDY_MATERIAL_INSERT_SUCCESS";
export const STUDY_MATERIAL_INSERT_FAIL = "STUDY_MATERIAL_INSERT_FAIL";
export const STUDY_MATERIAL_INSERT_RESET = "STUDY_MATERIAL_INSERT_RESET";

export const STUDY_MATERIAL_UPDATE_REQUEST = "STUDY_MATERIAL_UPDATE_REQUEST";
export const STUDY_MATERIAL_UPDATE_SUCCESS = "STUDY_MATERIAL_UPDATE_SUCCESS";
export const STUDY_MATERIAL_UPDATE_FAIL = "STUDY_MATERIAL_UPDATE_FAIL";
export const STUDY_MATERIAL_UPDATE_RESET = "STUDY_MATERIAL_UPDATE_RESET";

export const STUDY_MATERIAL_DELETE_REQUEST = "STUDY_MATERIAL_DELETE_REQUEST";
export const STUDY_MATERIAL_DELETE_SUCCESS = "STUDY_MATERIAL_DELETE_SUCCESS";
export const STUDY_MATERIAL_DELETE_FAIL = "STUDY_MATERIAL_DELETE_FAIL";
export const STUDY_MATERIAL_DELETE_RESET = "STUDY_MATERIAL_DELETE_RESET";
