import {
  NOTE_LOG_DELETE_FAIL,
  NOTE_LOG_DELETE_REQUEST,
  NOTE_LOG_DELETE_RESET,
  NOTE_LOG_DELETE_SUCCESS,
  NOTE_LOG_INSERT_FAIL,
  NOTE_LOG_INSERT_REQUEST,
  NOTE_LOG_INSERT_RESET,
  NOTE_LOG_INSERT_SUCCESS,
  NOTE_LOG_CONTACT_LIST_FAIL,
  NOTE_LOG_CONTACT_LIST_REQUEST,
  NOTE_LOG_CONTACT_LIST_SUCCESS,
  NOTE_LOG_DEAL_LIST_FAIL,
  NOTE_LOG_DEAL_LIST_REQUEST,
  NOTE_LOG_DEAL_LIST_SUCCESS,
  NOTE_LOG_UPDATE_FAIL,
  NOTE_LOG_UPDATE_REQUEST,
  NOTE_LOG_UPDATE_RESET,
  NOTE_LOG_UPDATE_SUCCESS,
  NOTE_LOG_SUBAGENT_LIST_REQUEST,
  NOTE_LOG_SUBAGENT_LIST_SUCCESS,
  NOTE_LOG_SUBAGENT_LIST_FAIL,
} from "../Constants/noteLogConstants";

export const noteLogContactListReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case NOTE_LOG_CONTACT_LIST_REQUEST:
      return { loading: true };
    case NOTE_LOG_CONTACT_LIST_SUCCESS:
      return { loading: false, noteLogContactRec: payload };
    case NOTE_LOG_CONTACT_LIST_FAIL:
      return { loading: false, error: payload };
    default:
      return state;
  }
};

export const noteLogDealListReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case NOTE_LOG_DEAL_LIST_REQUEST:
      return { loading: true };
    case NOTE_LOG_DEAL_LIST_SUCCESS:
      return { loading: false, noteLogDealRec: payload };
    case NOTE_LOG_DEAL_LIST_FAIL:
      return { loading: false, error: payload };
    default:
      return state;
  }
};

export const noteLogSubAgentListReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case NOTE_LOG_SUBAGENT_LIST_REQUEST:
      return { loading: true };
    case NOTE_LOG_SUBAGENT_LIST_SUCCESS:
      return { loading: false, noteLogSubAgentRec: payload };
    case NOTE_LOG_SUBAGENT_LIST_FAIL:
      return { loading: false, error: payload };
    default:
      return state;
  }
};

export const noteLogSaveReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case NOTE_LOG_INSERT_REQUEST:
      return { loading: true };
    case NOTE_LOG_INSERT_SUCCESS:
      return { loading: false, success: payload };
    case NOTE_LOG_INSERT_FAIL:
      return { loading: false, error: payload };
    case NOTE_LOG_INSERT_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const noteLogUpdateReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case NOTE_LOG_UPDATE_REQUEST:
      return { loading: true };
    case NOTE_LOG_UPDATE_SUCCESS:
      return { loading: false, success: payload };
    case NOTE_LOG_UPDATE_FAIL:
      return { loading: false, error: payload };
    case NOTE_LOG_UPDATE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const noteLogDeleteReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case NOTE_LOG_DELETE_REQUEST:
      return { loading: true };
    case NOTE_LOG_DELETE_SUCCESS:
      return { loading: false, success: payload };
    case NOTE_LOG_DELETE_FAIL:
      return { loading: false, error: payload };
    case NOTE_LOG_DELETE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};
