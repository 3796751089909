export const MOCKTEST_CREATE_REQ = "MOCKTEST_CREATE_REQ";
export const MOCKTEST_CREATE_FAILED = "MOCKTEST_CREATE_FAILED";
export const MOCKTEST_CREATE_SUCCESS = "MOCKTEST_CREATE_SUCCESS";
export const MOCKTEST_CREATE_RESET = "MOCKTEST_CREATE_RESET";

export const MOCKTEST_LIST_REQ = "MOCKTEST_LIST_REQ";
export const MOCKTEST_LIST_SUCCESS = "MOCKTEST_LIST_SUCCESS";
export const MOCKTEST_LIST_FAILED = "MOCKTEST_LIST_FAILED";

export const MOCKTEST_TOTAL_REQ = "MOCKTEST_TOTAL_REQ";
export const MOCKTEST_TOTAL_SUCCESS = "MOCKTEST_TOTAL_SUCCESS";
export const MOCKTEST_TOTAL_FAILED = "MOCKTEST_TOTAL_FAILED";

export const MOCKTEST_DETAILS_REQ = "MOCKTEST_DETAILS_REQ";
export const MOCKTEST_DETAILS_SUCCESS = "MOCKTEST_DETAILS_SUCCESS";
export const MOCKTEST_DETAILS_FAILED = "MOCKTEST_DETAILS_FAILED";
export const MOCKTEST_DETAILS_RESET = "MOCKTEST_DETAILS_RESET";

export const MOCKTEST_UPDATE_REQ = "MOCKTEST_UPDATE_REQ";
export const MOCKTEST_UPDATE_SUCCESS = "MOCKTEST_UPDATE_SUCCESS";
export const MOCKTEST_UPDATE_FAILED = "MOCKTEST_UPDATE_FAILED";
export const MOCKTEST_UPDATE_RESET = "MOCKTEST_UPDATE_RESET";

export const MOCKTEST_REMOVE_REQ = "MOCKTEST_REMOVE_REQ";
export const MOCKTEST_REMOVE_SUCCESS = "MOCKTEST_REMOVE_SUCCESS";
export const MOCKTEST_REMOVE_FAILED = "MOCKTEST_REMOVE_FAILED";
export const MOCKTEST_REMOVE_RESET = "MOCKTEST_REMOVE_RESET";
