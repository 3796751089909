export const CONTACT_RATING_LIST_REQUEST='CONTACT_RATING_LIST_REQUEST'
export const CONTACT_RATING_LIST_SUCCESS='CONTACT_RATING_LIST_SUCCESS'
export const CONTACT_RATING_LIST_FAIL='CONTACT_RATING_LIST_FAIL'

export const CONTACT_RATING_GET_REQUEST='CONTACT_RATING_GET_REQUEST'
export const CONTACT_RATING_GET_SUCCESS='CONTACT_RATING_GET_SUCCESS'
export const CONTACT_RATING_GET_FAIL='CONTACT_RATING_GET_FAIL'

export const CONTACT_RATING_INSERT_REQUEST='CONTACT_RATING_INSERT_REQUEST'
export const CONTACT_RATING_INSERT_SUCCESS='CONTACT_RATING_INSERT_SUCCESS'
export const CONTACT_RATING_INSERT_FAIL='CONTACT_RATING_INSERT_FAIL'
export const CONTACT_RATING_INSERT_RESET='CONTACT_RATING_INSERT_RESET'

export const CONTACT_RATING_UPDATE_REQUEST='CONTACT_RATING_UPDATE_REQUEST'
export const CONTACT_RATING_UPDATE_SUCCESS='CONTACT_RATING_UPDATE_SUCCESS'
export const CONTACT_RATING_UPDATE_FAIL='CONTACT_RATING_UPDATE_FAIL'
export const CONTACT_RATING_UPDATE_RESET='CONTACT_RATING_UPDATE_RESET'

export const CONTACT_RATING_DELETE_REQUEST='CONTACT_RATING_DELETE_REQUEST'
export const CONTACT_RATING_DELETE_SUCCESS='CONTACT_RATING_DELETE_SUCCESS'
export const CONTACT_RATING_DELETE_FAIL='CONTACT_RATING_DELETE_FAIL'
export const CONTACT_RATING_DELETE_RESET='CONTACT_RATING_DELETE_RESET'