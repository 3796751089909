export const COURSE_LIST_REQUEST = "COURSE_LIST_REQUEST";
export const COURSE_LIST_SUCCESS = "COURSE_LIST_SUCCESS";
export const COURSE_LIST_FAIL = "COURSE_LIST_FAIL";

export const COURSE_LIST_ALL_REQUEST = "COURSE_LIST_ALL_REQUEST";
export const COURSE_LIST_ALL_SUCCESS = "COURSE_LIST_ALL_SUCCESS";
export const COURSE_LIST_ALL_FAIL = "COURSE_LIST_ALL_FAIL";

export const COURSE_SINGLE_REQUEST = "COURSE_SINGLE_REQUEST";
export const COURSE_SINGLE_SUCCESS = "COURSE_SINGLE_SUCCESS";
export const COURSE_SINGLE_FAIL = "COURSE_SINGLE_FAIL";

export const COURSE_INSERT_REQUEST = "COURSE_INSERT_REQUEST";
export const COURSE_INSERT_SUCCESS = "COURSE_INSERT_SUCCESS";
export const COURSE_INSERT_FAIL = "COURSE_INSERT_FAIL";
export const COURSE_INSERT_RESET = "COURSE_INSERT_RESET";

export const COURSE_UPDATE_REQUEST = "COURSE_UPDATE_REQUEST";
export const COURSE_UPDATE_SUCCESS = "COURSE_UPDATE_SUCCESS";
export const COURSE_UPDATE_FAIL = "COURSE_UPDATE_FAIL";
export const COURSE_UPDATE_RESET = "COURSE_UPDATE_RESET";

export const COURSE_DELETE_REQUEST = "COURSE_DELETE_REQUEST";
export const COURSE_DELETE_SUCCESS = "COURSE_DELETE_SUCCESS";
export const COURSE_DELETE_FAIL = "COURSE_DELETE_FAIL";
export const COURSE_DELETE_RESET = "COURSE_DELETE_RESET";
