import {
  USER_ROLE_DELETE_FAIL,
  USER_ROLE_DELETE_REQUEST,
  USER_ROLE_DELETE_RESET,
  USER_ROLE_DELETE_SUCCESS,
  USER_ROLE_INSERT_FAIL,
  USER_ROLE_INSERT_REQUEST,
  USER_ROLE_INSERT_RESET,
  USER_ROLE_INSERT_SUCCESS,
  USER_ROLE_LIST_FAIL,
  USER_ROLE_LIST_REQUEST,
  USER_ROLE_LIST_SUCCESS,
  USER_ROLE_UPDATE_FAIL,
  USER_ROLE_UPDATE_REQUEST,
  USER_ROLE_UPDATE_RESET,
  USER_ROLE_UPDATE_SUCCESS,
} from "../Constants/userRoleConstants";

export const userRoleListReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case USER_ROLE_LIST_REQUEST:
      return { loading: true };
    case USER_ROLE_LIST_SUCCESS:
      return { loading: false, userRoleRecs: payload };
    case USER_ROLE_LIST_FAIL:
      return { loading: false, error: payload };
    default:
      return state;
  }
};

export const userRoleSaveReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case USER_ROLE_INSERT_REQUEST:
      return { loading: true };
    case USER_ROLE_INSERT_SUCCESS:
      return { loading: false, success: payload };
    case USER_ROLE_INSERT_FAIL:
      return { loading: false, error: payload };
    case USER_ROLE_INSERT_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const userRoleUpdateReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case USER_ROLE_UPDATE_REQUEST:
      return { loading: true };
    case USER_ROLE_UPDATE_SUCCESS:
      return { loading: false, success: payload };
    case USER_ROLE_UPDATE_FAIL:
      return { loading: false, error: payload };
    case USER_ROLE_UPDATE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const userRoleDeleteReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case USER_ROLE_DELETE_REQUEST:
      return { loading: true };
    case USER_ROLE_DELETE_SUCCESS:
      return { loading: false, success: payload };
    case USER_ROLE_DELETE_FAIL:
      return { loading: false, error: payload };
    case USER_ROLE_DELETE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};
