import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SettingLeftSection from "../Components/SettingLeftSection";
import DeletePopup from "../Components/DeletePoup";
import SidePanel from "../../Components/SidePanel";
import TopBar from "../../Components/TopBar";
import SEDLoading from "../../Components/SEDLoading";

import {
  createGurullyContact,
  deleteGurullyContact,
  listGurullyContact,
  updateGurullyContact,
} from "../../Actions/GurullyContactAction";
import {
  GURULLY_CONTACT_DELETE_RESET,
  GURULLY_CONTACT_INSERT_RESET,
  GURULLY_CONTACT_UPDATE_RESET,
} from "../../Constants/gurullyContactConstant";

import { gurullyContactJson } from "../../Models/GurullyModels";
import ContactDropDown from "../Components/ContactDropDown";
import PackageDropDown from "../Components/PackageDropDown";
import GurullyDropDown from "../Components/GurullyDropDown";
export default function GurullyContactContactLink() {
  const dispatch = useDispatch();
  const [GurullyContactData, setGurullyContactData] =
    useState(gurullyContactJson);
  const { loading, error, gurullyContactRecs } = useSelector(
    (s) => s.gurullyContactList
  );
  const [btnText, setbtnText] = useState("Save");
  const [cancelHide, setcancelHide] = useState(false);
  const [OpenNext, setOpenNext] = useState(false);
  const [DeleteAlert, setDeleteAlert] = useState({ msg: "", Delete: () => "" });
  const {
    loading: loadingsv,
    error: errorsv,
    success: successsv,
  } = useSelector((s) => s.gurullyContactInsert);

  const {
    loading: loadingud,
    error: errorud,
    success: successud,
  } = useSelector((s) => s.gurullyContactUpdate);

  const {
    loading: loadingdet,
    error: errordet,
    success: successdet,
  } = useSelector((s) => s.gurullyContactDelete);

  const handleChange = (e) => {
    e.preventDefault();
    setGurullyContactData({
      ...GurullyContactData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (btnText == "Save") {
      dispatch(createGurullyContact(GurullyContactData));
    } else if (btnText == "Update") {
      dispatch(
        updateGurullyContact(GurullyContactData._id, GurullyContactData)
      );
    } else alert("Error on handleSubmit");
  };

  const getGurullyContact = useCallback(() => {
    dispatch(listGurullyContact());
  }, [dispatch]);

  const handleDelete = (e, _id) => {
    e.preventDefault();
    dispatch(deleteGurullyContact(_id));
  };

  const handleEdit = (e, _id, GurullyId, PackageId, ContactId) => {
    e.preventDefault();
    setbtnText("Update");
    setcancelHide(true);
    setGurullyContactData({
      ...GurullyContactData,
      GurullyId,
      PackageId,
      ContactId,
      _id,
    });
  };

  const handleCancel = () => {
    setGurullyContactData({ ...gurullyContactJson });
    setbtnText("Save");
    setcancelHide(false);
  };

  useEffect(() => {
    getGurullyContact();
  }, [getGurullyContact]);

  useEffect(() => {
    if (successdet)
      setTimeout(() => {
        dispatch({ type: GURULLY_CONTACT_DELETE_RESET });
      }, 5000);

    if (successud)
      setTimeout(() => {
        dispatch({ type: GURULLY_CONTACT_UPDATE_RESET });
      }, 5000);

    if (successsv)
      setTimeout(() => {
        dispatch({ type: GURULLY_CONTACT_INSERT_RESET });
      }, 5000);
    if (successdet || successsv || successud) {
      getGurullyContact();
      setGurullyContactData({ ...gurullyContactJson });
      handleCancel();
    }
  }, [successdet, getGurullyContact, successsv, dispatch, successud]);

  return (
    <>
      <SidePanel page={"Gurully Contact"} />
      <section className="home-section">
        {loading ? <SEDLoading /> : ""}
        <TopBar Heading={"Gurully Contact"} />
        <div
          className="home-content"
          style={{ paddingTop: window.innerWidth <= 480 ? "20px" : "100px" }}
        >
          <div className="content-body">
            {/* <!--Body-content--> */}
            <div className="container-fluid">
              <div className="heading-title">
                <div className="row">
                  <div className="col-md-12">
                    <div className="home-table-search float-right d-flex">
                      {/* <!--    <button>right side</button> --> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="inner-body settings-details">
                <div className="row">
                  <div className="col-md-3">
                    <SettingLeftSection />
                  </div>

                  <div className="col-md-9">
                    <div className="body-content-with-md-9">
                      <div>
                        <div className="container">
                          <div className="row">
                            <div className="col-md-12 text-left body-content-with-md-9-title">
                              <h4>Gurully Contact </h4>
                            </div>
                          </div>

                          <div className="row mt-3 px-5">
                            <div className="col-md-3 pt-1">
                              <label className="form-group">Contact:</label>
                            </div>
                            <div className="col-md-9">
                              <select
                                className="form-control
                              form-control-sm"
                                name="ContactId"
                                value={GurullyContactData.ContactId}
                                onChange={(e) => handleChange(e)}
                              >
                                <option>select Contact</option>
                                <ContactDropDown />
                              </select>
                            </div>
                          </div>

                          <div className="row mt-3 px-5">
                            <div className="col-md-3 pt-1">
                              <label className="form-group">Package:</label>
                            </div>
                            <div className="col-md-9">
                              <select
                                className="form-control
                              form-control-sm"
                                name="PackageId"
                                value={GurullyContactData.PackageId}
                                onChange={(e) => handleChange(e)}
                              >
                                <option>select Package</option>
                                <PackageDropDown />
                              </select>
                            </div>
                          </div>

                          <div className="row mt-3 px-5">
                            <div className="col-md-3 pt-1">
                              <label className="form-group">Gurully:</label>
                            </div>
                            <div className="col-md-9">
                              <select
                                className="form-control
                              form-control-sm"
                                name="GurullyId"
                                value={GurullyContactData.GurullyId}
                                onChange={(e) => handleChange(e)}
                              >
                                <option>select Gurully</option>
                                <GurullyDropDown />
                              </select>
                            </div>
                          </div>

                          <div className="row">
                            <div
                              className="col-md-12"
                              style={{ textAlign: "center" }}
                            >
                              <p>
                                <a
                                  className="btn btn-deep-orange btn-md"
                                  onClick={(e) => handleSubmit(e)}
                                >
                                  {loadingsv || loadingud || loadingdet
                                    ? "Loading..."
                                    : btnText}
                                </a>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <a
                                  className="btn btn-outline-deep-orange btn-md"
                                  onClick={() => handleCancel()}
                                >
                                  Cancel
                                </a>
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="container">
                          <div className="row">
                            <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 ">
                              {errordet || errorsv || errorud ? (
                                <label className="alert-danger" id="SpanError">
                                  {errordet || errorsv || errorud}
                                </label>
                              ) : (
                                ""
                              )}
                              {successdet || successud || successsv ? (
                                <label
                                  className="alert-success"
                                  id="SpanSuccess"
                                >
                                  {successdet || successud || successsv}
                                </label>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="container">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="table-header-md-9 table-responsive">
                                <table className="table table-striped table-bordered">
                                  <thead>
                                    <tr>
                                      <th>Contact</th>
                                      <th>Package</th>
                                      <th>Gurully User Id</th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {gurullyContactRecs ? (
                                      gurullyContactRecs.map((dd) => (
                                        <tr key={dd._id}>
                                          <td>
                                            {dd.ContactId?.First_Name
                                              ? dd.ContactId?.First_Name +
                                                " " +
                                                (dd.ContactId?.First_Name
                                                  ? dd.ContactId?.Last_Name
                                                  : "")
                                              : ""}
                                          </td>
                                          <td>{dd?.PackageId?.Name}</td>

                                          <td>{dd?.GurullyId?.UserId}</td>
                                          <td align="center">
                                            <input
                                              type="button"
                                              className="btn btn-info btn-sm"
                                              value={
                                                loadingud
                                                  ? "Loading.."
                                                  : "Update"
                                              }
                                              disabled={loadingud}
                                              onClick={(e) =>
                                                handleEdit(
                                                  e,
                                                  dd._id,
                                                  dd.GurullyId?._id,
                                                  dd.PackageId?._id,
                                                  dd.ContactId?._id
                                                )
                                              }
                                            />
                                            &nbsp;&nbsp;&nbsp;
                                            <input
                                              type="button"
                                              className="btn btn-info btn-sm"
                                              value={
                                                loadingdet
                                                  ? "Loading.."
                                                  : "Delete"
                                              }
                                              disabled={loadingdet}
                                              onClick={(e) => {
                                                setDeleteAlert({
                                                  msg: `Do you really want to delete this Gurully Contact (${dd.ContactId?.First_Name} - ${dd?.GurullyId?.UserId}) ?`,
                                                  Delete: () =>
                                                    handleDelete(e, dd._id),
                                                });
                                                setOpenNext(true);
                                              }}
                                            />
                                          </td>
                                        </tr>
                                      ))
                                    ) : (
                                      <tr>
                                        <td colSpan={2}>{error}</td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!--/ body-content-with-md-9 --> */}
                  </div>
                  {/* <!--/ col-md-9 --> */}
                </div>
              </div>
            </div>
            <DeletePopup
              OpenNext={OpenNext}
              setOpenNext={setOpenNext}
              DeleteData={DeleteAlert}
            />
          </div>
        </div>
      </section>
    </>
  );
}
