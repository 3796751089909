import NavigationRoute from "./Routes/NavigationRoute";
import "react-toastify/dist/ReactToastify.css";
import "./Css/style.css";
// import "./Css/newstyle.scss";
import "@coreui/coreui/dist/css/coreui.min.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-quill/dist/quill.snow.css";
function App() {
  return (
    <div>
      <NavigationRoute />
    </div>
  );
}

export default App;
