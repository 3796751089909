export const PSYCHOMETRIC_QUESTION_CREATE_REQ =
  "PSYCHOMETRIC_QUESTION_CREATE_REQ";
export const PSYCHOMETRIC_QUESTION_CREATE_SUCCESS =
  "PSYCHOMETRIC_QUESTION_CREATE_SUCCESS";
export const PSYCHOMETRIC_QUESTION_CREATE_FAILED =
  "PSYCHOMETRIC_QUESTION_CREATE_FAILED";
export const PSYCHOMETRIC_QUESTION_CREATE_RESET =
  "PSYCHOMETRIC_QUESTION_CREATE_RESET";

export const PSYCHOMETRIC_QUESTION_LIST_REQ = "PSYCHOMETRIC_QUESTION_LIST_REQ";
export const PSYCHOMETRIC_QUESTION_LIST_SUCCESS =
  "PSYCHOMETRIC_QUESTION_LIST_SUCCESS";
export const PSYCHOMETRIC_QUESTION_LIST_FAILED =
  "PSYCHOMETRIC_QUESTION_LIST_FAILED";
export const PSYCHOMETRIC_QUESTION_LIST_RESET =
  "PSYCHOMETRIC_QUESTION_LIST_RESET";

export const PSYCHOMETRIC_QUESTION_UPDATE_REQ =
  "PSYCHOMETRIC_QUESTION_UPDATE_REQ";
export const PSYCHOMETRIC_QUESTION_UPDATE_SUCCESS =
  "PSYCHOMETRIC_QUESTION_UPDATE_SUCCESS";
export const PSYCHOMETRIC_QUESTION_UPDATE_FAILED =
  "PSYCHOMETRIC_QUESTION_UPDATE_FAILED";
export const PSYCHOMETRIC_QUESTION_UPDATE_RESET =
  "PSYCHOMETRIC_QUESTION_UPDATE_RESET";

export const PSYCHOMETRIC_QUESTION_DELETE_REQ =
  "PSYCHOMETRIC_QUESTION_DELETE_REQ";
export const PSYCHOMETRIC_QUESTION_DELETE_SUCCESS =
  "PSYCHOMETRIC_QUESTION_DELETE_SUCCESS";
export const PSYCHOMETRIC_QUESTION_DELETE_FAILED =
  "PSYCHOMETRIC_QUESTION_DELETE_FAILED";
export const PSYCHOMETRIC_QUESTION_DELETE_RESET =
  "PSYCHOMETRIC_QUESTION_DELETE_RESET";

export const PSYCHOMETRIC_RESULT_DETAILS_REQ =
  "PSYCHOMETRIC_RESULT_DETAILS_REQ";

export const PSYCHOMETRIC_RESULT_DETAILS_SUCCESS =
  "PSYCHOMETRIC_RESULT_DETAILS_SUCCESS";

export const PSYCHOMETRIC_RESULT_DETAILS_FAILED =
  "PSYCHOMETRIC_RESULT_DETAILS_FAILED";
