import {
  WORKFLOW_DELETE_FAIL,
  WORKFLOW_DELETE_REQUEST,
  WORKFLOW_DELETE_RESET,
  WORKFLOW_DELETE_SUCCESS,
  WORKFLOW_INSERT_FAIL,
  WORKFLOW_INSERT_REQUEST,
  WORKFLOW_INSERT_RESET,
  WORKFLOW_INSERT_SUCCESS,
  WORKFLOW_LIST_FAIL,
  WORKFLOW_LIST_REQUEST,
  WORKFLOW_LIST_SUCCESS,
  WORKFLOW_UPDATE_FAIL,
  WORKFLOW_UPDATE_REQUEST,
  WORKFLOW_UPDATE_RESET,
  WORKFLOW_UPDATE_SUCCESS,
} from "../Constants/workflowConstants";

export const workflowListReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case WORKFLOW_LIST_REQUEST:
      return { loading: true };
    case WORKFLOW_LIST_SUCCESS:
      return { loading: false, workflowRecs: payload };
    case WORKFLOW_LIST_FAIL:
      return { loading: false, error: payload };
    default:
      return state;
  }
};

export const workflowSaveReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case WORKFLOW_INSERT_REQUEST:
      return { loading: true };
    case WORKFLOW_INSERT_SUCCESS:
      return { loading: false, success: payload };
    case WORKFLOW_INSERT_FAIL:
      return { loading: false, error: payload };
    case WORKFLOW_INSERT_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const workflowUpdateReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case WORKFLOW_UPDATE_REQUEST:
      return { loading: true };
    case WORKFLOW_UPDATE_SUCCESS:
      return { loading: false, success: payload };
    case WORKFLOW_UPDATE_FAIL:
      return { loading: false, error: payload };
    case WORKFLOW_UPDATE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const workflowDeleteReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case WORKFLOW_DELETE_REQUEST:
      return { loading: true };
    case WORKFLOW_DELETE_SUCCESS:
      return { loading: false, success: payload };
    case WORKFLOW_DELETE_FAIL:
      return { loading: false, error: payload };
    case WORKFLOW_DELETE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};
