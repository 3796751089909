import {
  GURULLY_DELETE_FAIL,
  GURULLY_DELETE_REQUEST,
  GURULLY_DELETE_SUCCESS,
  GURULLY_INSERT_FAIL,
  GURULLY_INSERT_REQUEST,
  GURULLY_INSERT_SUCCESS,
  GURULLY_LIST_FAIL,
  GURULLY_LIST_REQUEST,
  GURULLY_LIST_SUCCESS,
  GURULLY_UPDATE_FAIL,
  GURULLY_UPDATE_REQUEST,
  GURULLY_UPDATE_SUCCESS,
} from "../Constants/gurullyConstant";
import api from "../core/api";

export const listGurully = () => async (dispatch) => {
  try {
    dispatch({
      type: GURULLY_LIST_REQUEST,
    });
    const { data } = await api.get(`/gurully`);

    var dt = data.Result;

    dispatch({
      type: GURULLY_LIST_SUCCESS,
      payload: dt,
    });
  } catch (error) {
    dispatch({
      type: GURULLY_LIST_FAIL,
      payload:
        error.response && error?.response?.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const createGurully = (inputJson) => async (dispatch) => {
  try {
    dispatch({
      type: GURULLY_INSERT_REQUEST,
    });
    const { data } = await api.post(`/gurully`, inputJson);
    var dt = data.Result;

    dispatch({
      type: GURULLY_INSERT_SUCCESS,
      payload: dt,
    });
  } catch (error) {
    dispatch({
      type: GURULLY_INSERT_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const updateGurully = (id, inputJson) => async (dispatch) => {
  try {
    dispatch({
      type: GURULLY_UPDATE_REQUEST,
    });
    const { data } = await api.put(`/gurully/${id}`, inputJson);
    var dt = data.Result;

    dispatch({
      type: GURULLY_UPDATE_SUCCESS,
      payload: dt,
    });
  } catch (error) {
    dispatch({
      type: GURULLY_UPDATE_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const deleteGurully = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GURULLY_DELETE_REQUEST,
    });
    const { data } = await api.delete(`/gurully/${id}`);
    var dt = data.Result;

    dispatch({
      type: GURULLY_DELETE_SUCCESS,
      payload: dt,
    });
  } catch (error) {
    dispatch({
      type: GURULLY_DELETE_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};
