import {
  TEAM_DELETE_FAIL,
  TEAM_DELETE_REQUEST,
  TEAM_DELETE_RESET,
  TEAM_DELETE_SUCCESS,
  TEAM_GET_FAIL,
  TEAM_GET_REQUEST,
  TEAM_GET_SUCCESS,
  TEAM_INSERT_FAIL,
  TEAM_INSERT_REQUEST,
  TEAM_INSERT_RESET,
  TEAM_INSERT_SUCCESS,
  TEAM_LIST_FAIL,
  TEAM_LIST_REQUEST,
  TEAM_LIST_SUCCESS,
  TEAM_UPDATE_FAIL,
  TEAM_UPDATE_REQUEST,
  TEAM_UPDATE_RESET,
  TEAM_UPDATE_SUCCESS,
} from "../Constants/teamConstants";

export const teamListReducer = (state = {}, action) => {
  switch (action.type) {
    case TEAM_LIST_REQUEST:
      return { loading: true };
    case TEAM_LIST_SUCCESS:
      return { loading: false, teamRecs: action.payload };
    case TEAM_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const teamGetReducer = (state = {}, action) => {
  switch (action.type) {
    case TEAM_GET_REQUEST:
      return { loading: true };
    case TEAM_GET_SUCCESS:
      return { loading: false, teamRec: action.payload };
    case TEAM_GET_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const teamInsertReducer = (state = {}, action) => {
  switch (action.type) {
    case TEAM_INSERT_REQUEST:
      return { loading: true };
    case TEAM_INSERT_SUCCESS:
      return { loading: false, success: action.payload };
    case TEAM_INSERT_FAIL:
      return { loading: false, error: action.payload };
    case TEAM_INSERT_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const teamUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case TEAM_UPDATE_REQUEST:
      return { loading: true };
    case TEAM_UPDATE_SUCCESS:
      return { loading: false, success: action.payload };
    case TEAM_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case TEAM_UPDATE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const teamDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case TEAM_DELETE_REQUEST:
      return { loading: true };
    case TEAM_DELETE_SUCCESS:
      return { loading: false, success: action.payload };
    case TEAM_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case TEAM_DELETE_RESET:
      return { loading: false, error: "", success:"" };
    default:
      return state;
  }
};
