// {
//     "Name": "Test Package",
//     "CourseId": "66fe5b2d2d28ad0d5b3730ca",
//     "Image": "testimage.png",
//     "Detail": "Test det",
//     "Description": "Test description",
//     "ActualCost": "14999",
//     "DiscountedPrice": "7999",
//     "Timings": "10Am to 11:30 Am",
//     "Duration": "4",
//     "SubCategory": "Academic",
//     "ClassMode": "1",
//     "TestType": "1",
//     "LIVE_CLASSES": "20",
//     "One_On_One_DOUBT_SESSIONS": "",
//     "DOUBT_SESSIONS": "5",
//     "SESSION_TESTS": "7",
//     "STUDY_MATERIAL_ACCESS": "Yes",
//     "COMPLETE_MOCK_TEST": "",
//     "COMPLETE_MOCK_TEST_AI": "Yes",
//     "TESTIFYI_REALITY_TEST": "",
//     "MASTER_CLASS": "",
//     "One_On_One_SPEAKING_SESSIONS": "",
//     "LANGUAGE_PRACTICE_TEST": "",
//     "GRAMMAR_TEST": "",
//     "MOCK_INTERVIEW": "",
//     "GROUP_DISCUSSIONS": "",
//     "BUSINESS_ENGLISH_SESSIONS": "",
//     "SOFT_SKILLS_SESSION": ""
//   }

export const Package_Model = {
  Name: "", //string
  CourseId: "", // Mongoose ObjectId
  Image: "", //String image url
  Detail: "", //String
  Description: "", //String
  ActualCost: "", // Number
  DiscountedPrice: "", //Number
  Timings: "", //String
  Duration: "", // Number
  SubCategory: "", //String
  ClassMode: "", // Number dropdown 1-online , 2-offline ,3 -hybrid
  TestType: "", // Number dropdown 1-paper based 2-cbt
  PackageType: "", //Number dropdown
  LIVE_CLASSES: "", // Number
  One_On_One_DOUBT_SESSIONS: "No", //dropdown yes/no
  DOUBT_SESSIONS: "No", //dropdown yes/no
  SESSION_TESTS: "No", //dropdown yes/no
  STUDY_MATERIAL_ACCESS: "No", //dropdown yes/no
  COMPLETE_MOCK_TEST: "", //Number
  COMPLETE_MOCK_TEST_AI: "", //Number
  TESTIFYI_REALITY_TEST: "", //Number
  MASTER_CLASS: "No", //dropdown yes/no
  One_On_One_SPEAKING_SESSIONS: "No", //dropdown yes/no
  LANGUAGE_PRACTICE_TEST: "", //Number //Testifyi Practice Test
  GRAMMAR_TEST: "No", //dropdown yes/no
  MOCK_INTERVIEW: "No", //dropdown yes/no
  GROUP_DISCUSSIONS: "No", //dropdown yes/no
  BUSINESS_ENGLISH_SESSIONS: "No", //dropdown yes/no
  SOFT_SKILLS_SESSION: "No", //dropdown yes/no
};

export const Package_Image_Model = [
  {
    Name: "IELTS - Compuer",
    Image: "/assets/image/Package_Image/Ielts_comp.webp",
  },

  {
    Name: "IELTS - Paper",
    Image: "/assets/image/Package_Image/Ielts_paper.webp",
  },

  {
    Name: "Material",
    Image: "/assets/image/Package_Image/material.webp",
  },

  {
    Name: "PTE_Computer",
    Image: "/assets/image/Package_Image/PTE_Comp.webp",
  },

  {
    Name: "Genral English",
    Image: "/assets/image/Package_Image/Genral_English.webp",
  },

  {
    Name: "Duolingo",
    Image: "/assets/image/Package_Image/Duolingo.webp",
  },
  {
    Name: "Crash Course",
    Image: "/assets/image/Package_Image/crash_course.webp",
  },
];

export const SubCategory_Model = [
  "Academic",
  "General",
  "PTE Academic",
  "PTE CORE",
];

export const Package_Det_Validator = (data) => {
  let Error = "";
  if (
    data["Name"] === "" ||
    data["Name"] === undefined ||
    data["Name"] === null
  ) {
    Error += "Name is required";
  }

  if (
    data["CourseId"] === "" ||
    data["CourseId"] === undefined ||
    data["CourseId"] === null
  ) {
    Error += " Course is required";
  }

  if (Error) {
    return "Error - " + Error;
  } else {
    return "success";
  }
};



export const Package_Type_Model = [
  {
    name: "Basic",
    id: "1",
    color: "#8FA1FE",
  },
  {
    name: "Star",
    id: "2",
    color: "#3CCE63",
  },
  {
    name: "Premium",
    id: "3",
    color: "#E12ADB",
  },
  {
    name: "Advance",
    id: "4",
    color: "#6C1BE5",
  },
];

export const Get_Package_Type = (Id) => {
  for (let i = 0; i < Package_Type_Model?.length; i++) {
    if (Package_Type_Model[i].id == Id) {
      return Package_Type_Model[i];
    }
  }

  return {};
};