export const DOCUMENTS_LIST_REQUEST = "DOCUMENTS_LIST_REQUEST";
export const DOCUMENTS_LIST_SUCCESS = "DOCUMENTS_LIST_SUCCESS";
export const DOCUMENTS_LIST_FAIL = "DOCUMENTS_LIST_FAIL";

export const DOCUMENTS_GET_REQUEST = "DOCUMENTS_GET_REQUEST";
export const DOCUMENTS_GET_SUCCESS = "DOCUMENTS_GET_SUCCESS";
export const DOCUMENTS_GET_FAIL = "DOCUMENTS_GET_FAIL";

export const DOCUMENTS_INSERT_REQUEST = "DOCUMENTS_INSERT_REQUEST";
export const DOCUMENTS_INSERT_SUCCESS = "DOCUMENTS_INSERT_SUCCESS";
export const DOCUMENTS_INSERT_FAIL = "DOCUMENTS_INSERT_FAIL";
export const DOCUMENTS_INSERT_RESET = "DOCUMENTS_INSERT_RESET";

export const DOCUMENTS_UPDATE_REQUEST = "DOCUMENTS_UPDATE_REQUEST";
export const DOCUMENTS_UPDATE_SUCCESS = "DOCUMENTS_UPDATE_SUCCESS";
export const DOCUMENTS_UPDATE_FAIL = "DOCUMENTS_UPDATE_FAIL";
export const DOCUMENTS_UPDATE_RESET = "DOCUMENTS_UPDATE_RESET";

export const DOCUMENTS_DELETE_REQUEST = "DOCUMENTS_DELETE_REQUEST";
export const DOCUMENTS_DELETE_SUCCESS = "DOCUMENTS_DELETE_SUCCESS";
export const DOCUMENTS_DELETE_FAIL = "DOCUMENTS_DELETE_FAIL";
export const DOCUMENTS_DELETE_RESET = "DOCUMENTS_DELETE_RESET";

export const DOCUMENT_CHECKED_LIST_REQUEST = "DOCUMENT_CHECKED_LIST_REQUEST";
export const DOCUMENT_CHECKED_LIST_SUCCESS = "DOCUMENT_CHECKED_LIST_SUCCESS";
export const DOCUMENT_CHECKED_LIST_FAILED = "DOCUMENT_CHECKED_LIST_FAILED";




export const DOCUMENT_CHECKED_LIST_CREATE_REQUEST =
  "DOCUMENT_CHECKED_LIST_CREATE_REQUEST";
export const DOCUMENT_CHECKED_LIST_CREATE_SUCCESS =
  "DOCUMENT_CHECKED_LIST_CREATE_SUCCESS";
export const DOCUMENT_CHECKED_LIST_CREATE_FAILED =
  "DOCUMENT_CHECKED_LIST_CREATE_FAILED";
export const DOCUMENT_CHECKED_LIST_CREATE_RESET =
  "DOCUMENT_CHECKED_LIST_CREATE_RESET";
