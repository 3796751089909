export const REASON_DID_NOT_QUALIFY_LIST_REQUEST = 'REASON_DID_NOT_QUALIFY_LIST_REQUEST'
export const REASON_DID_NOT_QUALIFY_LIST_SUCCESS = 'REASON_DID_NOT_QUALIFY_LIST_SUCCESS'
export const REASON_DID_NOT_QUALIFY_LIST_FAIL = 'REASON_DID_NOT_QUALIFY_LIST_FAIL'

export const REASON_DID_NOT_QUALIFY_INSERT_REQUEST = 'REASON_DID_NOT_QUALIFY_INSERT_REQUEST'
export const REASON_DID_NOT_QUALIFY_INSERT_SUCCESS = 'REASON_DID_NOT_QUALIFY_INSERT_SUCCESS'
export const REASON_DID_NOT_QUALIFY_INSERT_FAIL = 'REASON_DID_NOT_QUALIFY_INSERT_FAIL'
export const REASON_DID_NOT_QUALIFY_INSERT_RESET = 'REASON_DID_NOT_QUALIFY_INSERT_RESET'

export const REASON_DID_NOT_QUALIFY_UPDATE_REQUEST = 'REASON_DID_NOT_QUALIFY_UPDATE_REQUEST'
export const REASON_DID_NOT_QUALIFY_UPDATE_SUCCESS = 'REASON_DID_NOT_QUALIFY_UPDATE_SUCCESS'
export const REASON_DID_NOT_QUALIFY_UPDATE_FAIL = 'REASON_DID_NOT_QUALIFY_UPDATE_FAIL'
export const REASON_DID_NOT_QUALIFY_UPDATE_RESET = 'REASON_DID_NOT_QUALIFY_UPDATE_RESET'

export const REASON_DID_NOT_QUALIFY_DELETE_REQUEST = 'REASON_DID_NOT_QUALIFY_DELETE_REQUEST'
export const REASON_DID_NOT_QUALIFY_DELETE_SUCCESS = 'REASON_DID_NOT_QUALIFY_DELETE_SUCCESS'
export const REASON_DID_NOT_QUALIFY_DELETE_FAIL = 'REASON_DID_NOT_QUALIFY_DELETE_FAIL'
export const REASON_DID_NOT_QUALIFY_DELETE_RESET = 'REASON_DID_NOT_QUALIFY_DELETE_RESET'
