import {
  PSYCHOMETRIC_QUESTION_CREATE_FAILED,
  PSYCHOMETRIC_QUESTION_CREATE_REQ,
  PSYCHOMETRIC_QUESTION_CREATE_RESET,
  PSYCHOMETRIC_QUESTION_CREATE_SUCCESS,
  PSYCHOMETRIC_QUESTION_DELETE_FAILED,
  PSYCHOMETRIC_QUESTION_DELETE_REQ,
  PSYCHOMETRIC_QUESTION_DELETE_RESET,
  PSYCHOMETRIC_QUESTION_DELETE_SUCCESS,
  PSYCHOMETRIC_QUESTION_LIST_FAILED,
  PSYCHOMETRIC_QUESTION_LIST_REQ,
  PSYCHOMETRIC_QUESTION_LIST_RESET,
  PSYCHOMETRIC_QUESTION_LIST_SUCCESS,
  PSYCHOMETRIC_QUESTION_UPDATE_FAILED,
  PSYCHOMETRIC_QUESTION_UPDATE_REQ,
  PSYCHOMETRIC_QUESTION_UPDATE_RESET,
  PSYCHOMETRIC_QUESTION_UPDATE_SUCCESS,
  PSYCHOMETRIC_RESULT_DETAILS_FAILED,
  PSYCHOMETRIC_RESULT_DETAILS_REQ,
  PSYCHOMETRIC_RESULT_DETAILS_SUCCESS,
} from "../Constants/PsychometricQuestionConstant";

export const CreatePsychometricQuestionReducer = (
  state = {},
  { type, payload }
) => {
  switch (type) {
    case PSYCHOMETRIC_QUESTION_CREATE_REQ: {
      return { loading: true };
    }

    case PSYCHOMETRIC_QUESTION_CREATE_SUCCESS: {
      return { loading: false, success: payload };
    }

    case PSYCHOMETRIC_QUESTION_CREATE_FAILED: {
      return { loading: false, error: payload };
    }

    case PSYCHOMETRIC_QUESTION_CREATE_RESET: {
      return { loading: false, error: "", success: "" };
    }
    default:
      return state;
  }
};

export const listPsychometricQuestionReducer = (
  state = {},
  { type, payload }
) => {
  switch (type) {
    case PSYCHOMETRIC_QUESTION_LIST_REQ: {
      return { loading: true };
    }

    case PSYCHOMETRIC_QUESTION_LIST_SUCCESS: {
      return { loading: false, QuestionList: payload };
    }

    case PSYCHOMETRIC_QUESTION_LIST_FAILED: {
      return { loading: false, error: payload };
    }

    case PSYCHOMETRIC_QUESTION_LIST_RESET: {
      return {};
    }

    default:
      return state;
  }
};

export const UpdatePsychometricQuestionReducer = (
  state = {},
  { type, payload }
) => {
  switch (type) {
    case PSYCHOMETRIC_QUESTION_UPDATE_REQ: {
      return { loading: true };
    }

    case PSYCHOMETRIC_QUESTION_UPDATE_SUCCESS: {
      return { loading: false, success: payload };
    }

    case PSYCHOMETRIC_QUESTION_UPDATE_FAILED: {
      return { loading: false, error: payload };
    }

    case PSYCHOMETRIC_QUESTION_UPDATE_RESET: {
      return { loading: false, error: "", success: "" };
    }

    default:
      return state;
  }
};

export const DeletePsychometricQuestionReducer = (
  state = {},
  { type, payload }
) => {
  switch (type) {
    case PSYCHOMETRIC_QUESTION_DELETE_REQ: {
      return { loading: true };
    }

    case PSYCHOMETRIC_QUESTION_DELETE_SUCCESS: {
      return { loading: false, success: payload };
    }

    case PSYCHOMETRIC_QUESTION_DELETE_FAILED: {
      return { loading: false, error: payload };
    }

    case PSYCHOMETRIC_QUESTION_DELETE_RESET: {
      return { loading: false, error: "", success: "" };
    }

    default:
      return state;
  }
};

export const PsychometricResultReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case PSYCHOMETRIC_RESULT_DETAILS_REQ: {
      return { loading: true };
    }

    case PSYCHOMETRIC_RESULT_DETAILS_SUCCESS: {
      return { loading: false, TestResult: payload };
    }

    case PSYCHOMETRIC_RESULT_DETAILS_FAILED: {
      return { loading: false, error: payload };
    }

    default:
      return state;
  }
};
