export const CONTACT_LIST_REQUEST = "CONTACT_LIST_REQUEST";
export const CONTACT_LIST_SUCCESS = "CONTACT_LIST_SUCCESS";
export const CONTACT_LIST_FAIL = "CONTACT_LIST_FAIL";
export const CONTACT_LIST_RESET = "CONTACT_LIST_RESET";

export const CONTACT_TOTAL_LIST_REQUEST = "CONTACT_TOTAL_LIST_REQUEST";
export const CONTACT_TOTAL_LIST_SUCCESS = "CONTACT_TOTAL_LIST_SUCCESS";
export const CONTACT_TOTAL_LIST_FAIL = "CONTACT_TOTAL_LIST_FAIL";

export const CONTACT_CREATE_REQUEST = "CONTACT_CREATE_REQUEST";
export const CONTACT_CREATE_SUCCESS = "CONTACT_CREATE_SUCCESS";
export const CONTACT_CREATE_FAIL = "CONTACT_CREATE_FAIL";
export const CONTACT_CREATE_RESET = "CONTACT_CREATE_RESET";

export const CONTACT_GET_REQUEST = "CONTACT_GET_REQUEST";
export const CONTACT_GET_SUCCESS = "CONTACT_GET_SUCCESS";
export const CONTACT_GET_FAIL = "CONTACT_GET_FAIL";
export const CONTACT_GET_RESET = "CONTACT_GET_RESET";

export const CONTACT_UPDATE_REQUEST = "CONTACT_UPDATE_REQUEST";
export const CONTACT_UPDATE_SUCCESS = "CONTACT_UPDATE_SUCCESS";
export const CONTACT_UPDATE_FAIL = "CONTACT_UPDATE_FAIL";
export const CONTACT_UPDATE_RESET = "CONTACT_UPDATE_RESET";

export const CONTACT_DET_BY_IDS_REQUEST = "CONTACT_DET_BY_IDS_REQUEST";
export const CONTACT_DET_BY_IDS_SUCCESS = "CONTACT_DET_BY_IDS_SUCCESS";
export const CONTACT_DET_BY_IDS_FAIL = "CONTACT_DET_BY_IDS_FAIL";
