import {
  APPLICATION_CREATE_FAIL,
  APPLICATION_CREATE_REQUEST,
  APPLICATION_CREATE_RESET,
  APPLICATION_CREATE_SUCCESS,
  APPLICATION_DET_BY_IDS_FAIL,
  APPLICATION_DET_BY_IDS_REQUEST,
  APPLICATION_DET_BY_IDS_SUCCESS,
  APPLICATION_GET_FAIL,
  APPLICATION_GET_REQUEST,
  APPLICATION_GET_SUCCESS,
  APPLICATION_LIST_FAIL,
  APPLICATION_LIST_REQUEST,
  APPLICATION_LIST_SUCCESS,
  APPLICATION_UPDATE_FAIL,
  APPLICATION_UPDATE_REQUEST,
  APPLICATION_UPDATE_RESET,
  APPLICATION_UPDATE_SUCCESS,
} from "../Constants/applicationConstants";

export const applicationListReducer = (
  state = {},
  { type, payload, Total }
) => {
  switch (type) {
    case APPLICATION_LIST_REQUEST: {
      return { loading: true };
    }

    case APPLICATION_LIST_SUCCESS: {
      return { loading: false, applicationRecs: payload, Total: Total };
    }

    case APPLICATION_LIST_FAIL: {
      return { loading: false, error: payload };
    }

    default:
      return state;
  }
};

export const applicationCreateReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case APPLICATION_CREATE_REQUEST: {
      return { loading: true };
    }
    case APPLICATION_CREATE_SUCCESS: {
      return { loading: false, success: payload };
    }
    case APPLICATION_CREATE_FAIL: {
      return { loading: false, error: payload };
    }
    case APPLICATION_CREATE_RESET: {
      return { loading: false, success: "" };
    }
    default:
      return state;
  }
};

export const applicationDetReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case APPLICATION_GET_REQUEST: {
      return { loading: true };
    }

    case APPLICATION_GET_SUCCESS: {
      return { loading: false, applicationDet: payload };
    }

    case APPLICATION_GET_FAIL: {
      return { loading: false, error: payload };
    }
    default:
      return state;
  }
};

export const updateApplicationReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case APPLICATION_UPDATE_REQUEST: {
      return { loading: true };
    }

    case APPLICATION_UPDATE_SUCCESS: {
      return { loading: false, success: payload };
    }

    case APPLICATION_UPDATE_FAIL: {
      return { loading: false, error: payload };
    }

    case APPLICATION_UPDATE_RESET: {
      return { loading: false, success: "" };
    }

    default:
      return state;
  }
};

export const applicationDetByIdsReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case APPLICATION_DET_BY_IDS_REQUEST: {
      return { loading: true };
    }
    case APPLICATION_DET_BY_IDS_SUCCESS: {
      return { loading: false, applicationDetByIds: payload };
    }
    case APPLICATION_DET_BY_IDS_FAIL: {
      return { loading: false, error: payload };
    }
    default:
      return state;
  }
};
