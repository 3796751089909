import {
  DOCUMENTS_BULK_INSERT_FAIL,
  DOCUMENTS_BULK_INSERT_REQUEST,
  DOCUMENTS_BULK_INSERT_RESET,
  DOCUMENTS_BULK_INSERT_SUCCESS,
  DOCUMENTS_BULK_LIST_FAIL,
  DOCUMENTS_BULK_LIST_REQUEST,
  DOCUMENTS_BULK_LIST_SUCCESS,
  DOCUMENTS_BULK_UPDATE_FAIL,
  DOCUMENTS_BULK_UPDATE_REQUEST,
  DOCUMENTS_BULK_UPDATE_RESET,
  DOCUMENTS_BULK_UPDATE_SUCCESS,
} from "../Constants/documentBulkConstant";

export const documentBulkListReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case DOCUMENTS_BULK_LIST_REQUEST:
      return { loading: true };
    case DOCUMENTS_BULK_LIST_SUCCESS:
      return { loading: false, documentBulkRecs: payload };
    case DOCUMENTS_BULK_LIST_FAIL:
      return { loading: false, error: payload };
    default:
      return state;
  }
};

export const documentBulkSaveReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case DOCUMENTS_BULK_INSERT_REQUEST:
      return { loading: true };
    case DOCUMENTS_BULK_INSERT_SUCCESS:
      return { loading: false, success: payload };
    case DOCUMENTS_BULK_INSERT_FAIL:
      return { loading: false, error: payload };
    case DOCUMENTS_BULK_INSERT_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const documentBulkUpdateReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case DOCUMENTS_BULK_UPDATE_REQUEST:
      return { loading: true };
    case DOCUMENTS_BULK_UPDATE_SUCCESS:
      return { loading: false, success: payload };
    case DOCUMENTS_BULK_UPDATE_FAIL:
      return { loading: false, error: payload };
    case DOCUMENTS_BULK_UPDATE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};
