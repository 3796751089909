import {
  COUNTRY_DELETE_FAIL,
  COUNTRY_DELETE_REQUEST,
  COUNTRY_DELETE_SUCCESS,
  COUNTRY_INSERT_FAIL,
  COUNTRY_INSERT_REQUEST,
  COUNTRY_INSERT_SUCCESS,
  COUNTRY_LIST_FAIL,
  COUNTRY_LIST_REQUEST,
  COUNTRY_LIST_SUCCESS,
  COUNTRY_UPDATE_FAIL,
  COUNTRY_UPDATE_REQUEST,
  COUNTRY_UPDATE_SUCCESS,
} from "../Constants/countryConstants";
import api from "../core/api";

export const listCountry = (SubAgentLocation) => async (dispatch) => {
  try {
    dispatch({ type: COUNTRY_LIST_REQUEST });
    const { data } = await api.get(
      SubAgentLocation ? "/country/forsubagent" : `/country`
    );
    dispatch({
      type: COUNTRY_LIST_SUCCESS,
      payload: data.Result,
    });
  } catch (error) {
    dispatch({
      type: COUNTRY_LIST_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const saveCountryDetails = (InputJson) => async (dispatch) => {
  try {
    dispatch({ type: COUNTRY_INSERT_REQUEST });
    const { data } = await api.post(`/country`, InputJson);
    dispatch({
      type: COUNTRY_INSERT_SUCCESS,
      payload: data.Result,
    });
  } catch (error) {
    dispatch({
      type: COUNTRY_INSERT_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const updateCountryDetails = (id, InputJson) => async (dispatch) => {
  try {
    dispatch({ type: COUNTRY_UPDATE_REQUEST });
    const { data } = await api.put(`/country/${id}`, InputJson);
    dispatch({
      type: COUNTRY_UPDATE_SUCCESS,
      payload: data.Result,
    });
  } catch (error) {
    dispatch({
      type: COUNTRY_UPDATE_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const deleteCountryDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: COUNTRY_DELETE_REQUEST });
    const { data } = await api.delete(`/country/${id}`);
    dispatch({
      type: COUNTRY_DELETE_SUCCESS,
      payload: data.Result,
    });
  } catch (error) {
    dispatch({
      type: COUNTRY_DELETE_FAIL,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};
