export const CALL_OUTCOME_LIST_REQUEST = 'CALL_OUTCOME_LIST_REQUEST'
export const CALL_OUTCOME_LIST_SUCCESS = 'CALL_OUTCOME_LIST_SUCCESS'
export const CALL_OUTCOME_LIST_FAIL = 'CALL_OUTCOME_LIST_FAIL'

export const CALL_OUTCOME_GET_REQUEST = 'CALL_OUTCOME_GET_REQUEST'
export const CALL_OUTCOME_GET_SUCCESS = 'CALL_OUTCOME_GET_SUCCESS'
export const CALL_OUTCOME_GET_FAIL = 'CALL_OUTCOME_GET_FAIL'

export const CALL_OUTCOME_INSERT_REQUEST = 'CALL_OUTCOME_INSERT_REQUEST'
export const CALL_OUTCOME_INSERT_SUCCESS = 'CALL_OUTCOME_INSERT_SUCCESS'
export const CALL_OUTCOME_INSERT_FAIL = 'CALL_OUTCOME_INSERT_FAIL'
export const CALL_OUTCOME_INSERT_RESET = 'CALL_OUTCOME_INSERT_RESET'

export const CALL_OUTCOME_UPDATE_REQUEST = 'CALL_OUTCOME_UPDATE_REQUEST'
export const CALL_OUTCOME_UPDATE_SUCCESS = 'CALL_OUTCOME_UPDATE_SUCCESS'
export const CALL_OUTCOME_UPDATE_FAIL = 'CALL_OUTCOME_UPDATE_FAIL'
export const CALL_OUTCOME_UPDATE_RESET = 'CALL_OUTCOME_UPDATE_RESET'

export const CALL_OUTCOME_DELETE_REQUEST = 'CALL_OUTCOME_DELETE_REQUEST'
export const CALL_OUTCOME_DELETE_SUCCESS = 'CALL_OUTCOME_DELETE_SUCCESS'
export const CALL_OUTCOME_DELETE_FAIL = 'CALL_OUTCOME_DELETE_FAIL'
export const CALL_OUTCOME_DELETE_RESET = 'CALL_OUTCOME_DELETE_RESET'
