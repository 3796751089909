import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API,
});
api.interceptors.request.use((config) => {
  const authToken = localStorage.getItem("_Tssat");
  if (authToken) config.headers.Authorization = `Bearer ${authToken}`;
  return config;
});

//Also add/ configure interceptors && all the other cool stuff

export default api;
