import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listCallOutcome } from "../../Actions/callOutcomeActions";

export default function CallOutcomeDropdown() {
  const dispatch = useDispatch();
  const { calloutcomeRec } = useSelector((s) => s.callOutcomeList);
  useEffect(() => {
    dispatch(listCallOutcome());
  }, []);
  return calloutcomeRec ? (
    calloutcomeRec.map((recs) => (
      <option value={recs._id} key={recs._id}>
        {recs.CallOutcome}
      </option>
    ))
  ) : (
    <></>
  );
}
