import {
  TASK_LOG_LIST_FAIL,
  TASK_LOG_LIST_REQUEST,
  TASK_LOG_LIST_SUCCESS,
  TASK_REMINDER_LIST_DASHBOARD_FAIL,
  TASK_REMINDER_LIST_DASHBOARD_REQUEST,
  TASK_REMINDER_LIST_DASHBOARD_SUCCESS,
  TASK_REMINDER_TOTAL_FAIL,
  TASK_REMINDER_TOTAL_REQUEST,
  TASK_REMINDER_TOTAL_SUCCESS,
} from "../Constants/taskLogConstants";
import api from "../core/api";

export const listTasks = (taskJson) => async (dispatch) => {
  try {
    dispatch({
      type: TASK_LOG_LIST_REQUEST,
    });
    const { data } = await api.post(`/tasklog`, taskJson);
    const res = data.Result;
    dispatch({
      type: TASK_LOG_LIST_SUCCESS,
      payload: res,
    });
  } catch (ex) {
    dispatch({
      type: TASK_LOG_LIST_FAIL,
      payload: "Error - " + ex.message,
    });
  }
};

export const remindersTotal = (userid) => async (dispatch) => {
  try {
    dispatch({
      type: TASK_REMINDER_TOTAL_REQUEST,
    });
    const { data } = await api.get(`/tasklog/ReminderTotal/${userid}`);
    const res = data.Result;
    dispatch({
      type: TASK_REMINDER_TOTAL_SUCCESS,
      payload: res,
    });
  } catch (ex) {
    dispatch({
      type: TASK_REMINDER_TOTAL_FAIL,
      payload: "Error - " + ex.message,
    });
  }
};

export const remindersListDashboard = (userid) => async (dispatch) => {
  try {
    dispatch({
      type: TASK_REMINDER_LIST_DASHBOARD_REQUEST,
    });
    const { data } = await api.get(`/taskLog/GetReminders/${userid}`);
    const res = data.Result;
   
    dispatch({
      type: TASK_REMINDER_LIST_DASHBOARD_SUCCESS,
      payload: res,
    });
  } catch (ex) {
    dispatch({
      type: TASK_REMINDER_LIST_DASHBOARD_FAIL,
      payload: "Error - " + ex.message,
    });
  }
};
