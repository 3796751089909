import {
  DEAL_LIST_REQUEST,
  DEAL_LIST_SUCCESS,
  DEAL_LIST_FAIL,
  DEAL_CREATE_REQUEST,
  DEAL_CREATE_SUCCESS,
  DEAL_CREATE_FAIL,
  DEAL_CREATE_RESET,
  DEAL_UPDATE_REQUEST,
  DEAL_UPDATE_SUCCESS,
  DEAL_UPDATE_FAIL,
  DEAL_UPDATE_RESET,
  DEAL_GET_REQUEST,
  DEAL_GET_SUCCESS,
  DEAL_GET_FAIL,
  DEAL_GET_ALL_REQUEST,
  DEAL_GET_ALL_SUCCESS,
  DEAL_GET_ALL_FAIL,
} from "../Constants/dealConstants";

export const dealListReducer = (state = {}, { type, payload, total }) => {
  switch (type) {
    case DEAL_LIST_REQUEST:
      return { loading: true };

    case DEAL_LIST_SUCCESS:
      return { loading: false, deals: payload, total: total };
    case DEAL_LIST_FAIL:
      return { loading: false, error: payload };
    default:
      return state;
  }
};

export const dealCreateReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case DEAL_CREATE_REQUEST:
      return { loading: true };
    case DEAL_CREATE_SUCCESS:
      return { loading: false, success: payload };
    case DEAL_CREATE_FAIL:
      return { loading: false, error: payload };
    case DEAL_CREATE_RESET:
      return { loading: false, success: "" };
    default:
      return state;
  }
};

export const dealUpdateReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case DEAL_UPDATE_REQUEST:
      return { loading: true };
    case DEAL_UPDATE_SUCCESS:
      return { loading: false, success: payload };
    case DEAL_UPDATE_FAIL:
      return { loading: false, error: payload };
    case DEAL_UPDATE_RESET:
      return { loading: false, success: "" };
    default:
      return state;
  }
};

export const dealDetailReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case DEAL_GET_REQUEST:
      return { loading: true };
    case DEAL_GET_SUCCESS:
      return { loading: false, dealDet: payload };
    case DEAL_GET_FAIL:
      return { loading: false, error: payload };
    default:
      return state;
  }
};

export const listDealByContactReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case DEAL_GET_ALL_REQUEST: {
      return { laoding: true };
    }

    case DEAL_GET_ALL_SUCCESS: {
      return { loading: false, dealbyContactRec: payload };
    }

    case DEAL_GET_ALL_FAIL: {
      return { loading: false, error: payload };
    }

    default:
      return state;
  }
};
