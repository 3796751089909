import {
  RESUME_DELETE_FAIL,
  RESUME_DELETE_REQUEST,
  RESUME_DELETE_RESET,
  RESUME_DELETE_SUCCESS,
  RESUME_INSERT_FAIL,
  RESUME_INSERT_REQUEST,
  RESUME_INSERT_RESET,
  RESUME_INSERT_SUCCESS,
  RESUME_LIST_FAIL,
  RESUME_LIST_REQUEST,
  RESUME_LIST_SUCCESS,
  RESUME_UPDATE_FAIL,
  RESUME_UPDATE_REQUEST,
  RESUME_UPDATE_RESET,
  RESUME_UPDATE_SUCCESS,
} from "../Constants/resumeConstants";

export const resumeListReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case RESUME_LIST_REQUEST:
      return { loading: true };
    case RESUME_LIST_SUCCESS:
      return { loading: false, resumeRecs: payload };
    case RESUME_LIST_FAIL:
      return { loading: false, error: payload };
    default:
      return state;
  }
};

export const resumeSaveReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case RESUME_INSERT_REQUEST:
      return { loading: true };
    case RESUME_INSERT_SUCCESS:
      return { loading: false, success: payload };
    case RESUME_INSERT_FAIL:
      return { loading: false, error: payload };
    case RESUME_INSERT_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const resumeUpdateReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case RESUME_UPDATE_REQUEST:
      return { loading: true };
    case RESUME_UPDATE_SUCCESS:
      return { loading: false, success: payload };
    case RESUME_UPDATE_FAIL:
      return { loading: false, error: payload };
    case RESUME_UPDATE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const resumeDeleteReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case RESUME_DELETE_REQUEST:
      return { loading: true };
    case RESUME_DELETE_SUCCESS:
      return { loading: false, success: payload };
    case RESUME_DELETE_FAIL:
      return { loading: false, error: payload };
    case RESUME_DELETE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};
