import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  listCallLogContact,
  listCallLogDeal,
  listCallLogSubagent,
  saveCallLogDetails,
  updateCallLogDetails,
} from "../../../Actions/callLogActions";

import { callLogJson } from "../../../Models/CallLogModels";
import {
  CALL_LOG_INSERT_RESET,
  CALL_LOG_UPDATE_RESET,
} from "../../../Constants/callLogConstants";
import CallOutcomeDropdown from "../CallOutcomeDropdown";
import ReactQuill from "react-quill";
import {
  getContactActivity,
  getDealActivity,
  getSubAgentActivity,
} from "../../../Actions/activityActions";
import {
  toastOptions,
  toolbarOptions,
} from "../../../Models/ToastEditiorOption";
import { toast } from "react-toastify";

export default function CallInsert({
  callEdit,
  contactId,
  subagentId,
  dealId,
  closePopup,
}) {
  const dispatch = useDispatch();
  const [CallData, setCallData] = useState(callLogJson);
  const [CallDetail, setCallDetail] = useState("");
  const { loading, error, success } = useSelector((s) => s.callLogSave);
  const {
    loading: laodingUpdt,
    success: successUpdt,
    error: errorUpdt,
  } = useSelector((s) => s.callLogUpdate);

  const getActivities = useCallback(() => {
    if (subagentId) {
      dispatch(getSubAgentActivity(subagentId));
    } else if (contactId) {
      dispatch(getContactActivity(contactId));
    } else {
      dispatch(getDealActivity(dealId));
    }
  }, [dispatch, contactId, dealId, subagentId]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      saveCallLogDetails({
        ...CallData,
        CallDetail: CallDetail,
        ContactId: contactId,
        // Deal_Id: dealId,
      })
    );
  };
  const handleChange = (e) => {
    e.preventDefault();
    setCallData({
      ...CallData,
      ContactId: contactId,
      // SubAgent_Id: subagentId,
      // Deal_Id: dealId,
      [e.target.name]: e.target.value,
    });
  };
  useEffect(() => {
    if (success) {
      toast.success(success, toastOptions);
      if (contactId) dispatch(listCallLogContact(contactId));
      else if (dealId) dispatch(listCallLogDeal(dealId));
      else if (subagentId) dispatch(listCallLogSubagent(subagentId));
      getActivities();
      setCallData({ ...callLogJson });
      setCallDetail("");
      setTimeout(() => {
        dispatch({ type: CALL_LOG_INSERT_RESET });
        closePopup();
      }, 3000);
    }

    if (error) {
      toast.error(error, toastOptions);
    }
  }, [success, error]);

  useEffect(() => {
    if (callEdit) {
      setCallData(callEdit);
      setCallDetail(callEdit?.CallDetail);
    } else {
      setCallData(callLogJson);
      setCallDetail("");
    }
  }, [callEdit]);

  // Call Log Update

  const handleEmailUpdate = (e) => {
    e.preventDefault();
    dispatch(
      updateCallLogDetails(callEdit?._id, {
        ...CallData,
        CallDetail: CallDetail,
      })
    );
  };

  useEffect(() => {
    if (successUpdt) {
      toast.success(successUpdt, toastOptions);
      if (contactId) dispatch(listCallLogContact(contactId));
      else if (dealId) dispatch(listCallLogDeal(dealId));
      else if (subagentId) dispatch(listCallLogSubagent(subagentId));
      getActivities();
      setTimeout(() => {
        setCallData({ ...callLogJson });
        setCallDetail("");
        dispatch({ type: CALL_LOG_UPDATE_RESET });
        closePopup();
      }, 3000);
    }

    if (errorUpdt) {
      toast.error(errorUpdt, toastOptions);
    }
  }, [successUpdt]);

  return (
    <>
      <div className="overlay-note-content">
        <div className="note-title">
          <h4 className="text-left text-white pl-3">
            {callEdit ? "Update Call" : "Create Call"}
          </h4>
          <div className="closebtn">
            {/* <Link
              id="hide-note-mini"
              className="text-right text-white"
              onClick="minimizeNote()"
            >
              <i className="fas fa-minus"></i>
            </Link> */}
            <Link
              id="hide-note-max"
              className="text-right text-white"
              // onClick={closePopup}
            >
              <i className="fas fa-minus"></i>
            </Link>
            <Link className="text-right text-white" onClick={closePopup}>
              <i className="fas fa-times"></i>
            </Link>
          </div>
        </div>
        <div className="note-body" id="noteBody">
          <div className="log-call">
            <div className="row">
              <div className="col-md-5 " style={{ marginLeft: "10px" }}>
                <select
                  name="CallOutcome"
                  value={CallData.CallOutcome}
                  onChange={handleChange}
                  className="form-control"
                >
                  <option value="">select an outcome</option>
                  <CallOutcomeDropdown />
                </select>
              </div>
              {window.innerWidth <= 480 ? (
                <>
                  {" "}
                  <br />
                  <br />{" "}
                </>
              ) : (
                ""
              )}

              <div className="col-md-12 mt-3 rmv-toolbar">
                <ReactQuill
                  placeholder={"Enter Email Message"}
                  modules={{ toolbar: toolbarOptions }}
                  name="CallDetail"
                  value={CallDetail}
                  onChange={(e) => setCallDetail(e)}
                  required
                />
              </div>
            </div>

            {/* <div className="add-comment text-right">
              <textarea
                className="add-comment-field"
                placeholder="Add comments"
                rows="1"
                style={{
                  resize: "none",
                  outline: "none",
                  border: "none",
                  width: "90%",
                }}
                name="Comments"
                value={CallData.Comments}
                onChange={handleChange}
              ></textarea>
            </div> */}
          </div>
          <hr />

          <div className="log-call-footer">
            {callEdit ? (
              <button
                className="btn btn-sm btn-deep-orange waves-effect waves-light"
                onClick={handleEmailUpdate}
                disabled={laodingUpdt}
              >
                {laodingUpdt ? "Loading..." : "Update Call"}
              </button>
            ) : (
              <button
                className="btn btn-sm btn-deep-orange waves-effect waves-light"
                onClick={handleSubmit}
                disabled={loading}
              >
                {loading ? "Loading..." : "Save Call"}
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
