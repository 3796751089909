export const MEDIUM_OF_EVENT_LIST_REQUEST = 'MEDIUM_OF_EVENT_LIST_REQUEST'
export const MEDIUM_OF_EVENT_LIST_SUCCESS = 'MEDIUM_OF_EVENT_LIST_SUCCESS'
export const MEDIUM_OF_EVENT_LIST_FAIL = 'MEDIUM_OF_EVENT_LIST_FAIL'

export const MEDIUM_OF_EVENT_INSERT_REQUEST = 'MEDIUM_OF_EVENT_INSERT_REQUEST'
export const MEDIUM_OF_EVENT_INSERT_SUCCESS = 'MEDIUM_OF_EVENT_INSERT_SUCCESS'
export const MEDIUM_OF_EVENT_INSERT_FAIL = 'MEDIUM_OF_EVENT_INSERT_FAIL'
export const MEDIUM_OF_EVENT_INSERT_RESET = 'MEDIUM_OF_EVENT_INSERT_RESET'

export const MEDIUM_OF_EVENT_UPDATE_REQUEST = 'MEDIUM_OF_EVENT_UPDATE_REQUEST'
export const MEDIUM_OF_EVENT_UPDATE_SUCCESS = 'MEDIUM_OF_EVENT_UPDATE_SUCCESS'
export const MEDIUM_OF_EVENT_UPDATE_FAIL = 'MEDIUM_OF_EVENT_UPDATE_FAIL'
export const MEDIUM_OF_EVENT_UPDATE_RESET = 'MEDIUM_OF_EVENT_UPDATE_RESET'

export const MEDIUM_OF_EVENT_DELETE_REQUEST = 'MEDIUM_OF_EVENT_DELETE_REQUEST'
export const MEDIUM_OF_EVENT_DELETE_SUCCESS = 'MEDIUM_OF_EVENT_DELETE_SUCCESS'
export const MEDIUM_OF_EVENT_DELETE_FAIL = 'MEDIUM_OF_EVENT_DELETE_FAIL'
export const MEDIUM_OF_EVENT_DELETE_RESET = 'MEDIUM_OF_EVENT_DELETE_RESET'
