import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SettingLeftSection from "../Components/SettingLeftSection";
import { ImCross } from "react-icons/im";
import SidePanel from "../../Components/SidePanel";
import TopBar from "../../Components/TopBar";
import SEDLoading from "../../Components/SEDLoading";
import { MdDoneOutline } from "react-icons/md";
import { TLat_Result } from "../../Actions/TLatResultAction";
import { useParams } from "react-router-dom";

export default function TLATResult() {
  const dispatch = useDispatch();
  const { ContactId } = useParams();
  const { AnsweDet, loading } = useSelector((s) => s.TLat_ResultReducer);

  useEffect(() => {
    dispatch(TLat_Result(ContactId));
  }, [dispatch, ContactId]);

  //   useEffect(() => {
  //     // dispatch(Test_Result());
  //   }, [dispatch]);

  return (
    <>
      <SidePanel page={"User"} />
      <section className="home-section">
        {loading ? <SEDLoading /> : ""}
        <TopBar Heading={"T-LAT Review"} />
        <div
          className="home-content"
          style={{ paddingTop: window.innerWidth <= 550 ? "20px" : "100px" }}
        >
          <div className="content-body">
            {/* <!--Body-content--> */}
            <div className="container-fluid">
              <div className="heading-title">
                <div className="row">
                  <div className="col-md-12">
                    {/* <div className="home-table-search float-right d-flex">
                    </div> */}
                  </div>
                </div>
              </div>

              <div className="inner-body settings-details">
                <div className="row">
                  <div className="col-md-3">
                    <SettingLeftSection />
                  </div>

                  <div className="col-md-9">
                    <div className="body-content-with-md-9">
                      <div>
                        <div className="container">
                          <div className="row">
                            <div className="col-md-12 text-left body-content-with-md-9-title">
                              <h4>T-LAT Review </h4>
                            </div>
                          </div>
                          <div
                            style={{ padding: "10px" }}
                            className={"OnlineClassTopSection"}
                          >
                            <div>
                              <p
                                style={{ fontWeight: "600", fontSize: "18px" }}
                              ></p>
                            </div>
                            <table className={"QuestionSec2"}>
                              <tbody>
                                <tr>
                                  <td rowspan={1}>
                                    {AnsweDet?.map((ev, i) => (
                                      <div>
                                        <div>
                                          <h4 style={{ fontSize: "20px" }}>
                                            Q:{i + 1}
                                          </h4>
                                          <p className={"Questiondet"}>
                                            {ev?.Question}
                                          </p>
                                        </div>
                                        <div
                                          style={{ margin: "10px 0" }}
                                          className={"OptionContainer"}
                                        >
                                          {ev?.Options?.map((el, j) => (
                                            <div className={"Options"}>
                                              <input
                                                type="radio"
                                                disabled={true}
                                                checked={
                                                  ev?.AttemptedAnswer === el
                                                    ? true
                                                    : false
                                                }
                                              />
                                              <p
                                                style={{
                                                  color:
                                                    ev?.CorrectAnswer === el
                                                      ? "rgb(64, 225, 64)"
                                                      : ev?.AttemptedAnswer ===
                                                          el &&
                                                        ev?.CorrectAnswer !=
                                                          ev?.AttemptedAnswer
                                                      ? "red"
                                                      : "black",
                                                }}
                                              >
                                                {el}
                                              </p>
                                            </div>
                                          ))}
                                          {!ev?.AttemptedAnswer ? (
                                            <p style={{ color: "grey" }}>
                                              {" "}
                                              <ImCross /> Not Attempted
                                            </p>
                                          ) : ev?.CorrectAnswer ==
                                            ev?.AttemptedAnswer ? (
                                            <p
                                              style={{
                                                color: "rgb(64, 225, 64)",
                                              }}
                                            >
                                              {" "}
                                              <MdDoneOutline /> Correct Answer
                                            </p>
                                          ) : (
                                            <p style={{ color: "red" }}>
                                              {" "}
                                              <ImCross /> Wrong Answer
                                            </p>
                                          )}
                                        </div>{" "}
                                      </div>
                                    ))}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
