import {
  MODE_OF_PAYMENT_DELETE_FAIL,
  MODE_OF_PAYMENT_DELETE_REQUEST,
  MODE_OF_PAYMENT_DELETE_RESET,
  MODE_OF_PAYMENT_DELETE_SUCCESS,
  MODE_OF_PAYMENT_INSERT_FAIL,
  MODE_OF_PAYMENT_INSERT_REQUEST,
  MODE_OF_PAYMENT_INSERT_RESET,
  MODE_OF_PAYMENT_INSERT_SUCCESS,
  MODE_OF_PAYMENT_LIST_FAIL,
  MODE_OF_PAYMENT_LIST_REQUEST,
  MODE_OF_PAYMENT_LIST_SUCCESS,
  MODE_OF_PAYMENT_UPDATE_FAIL,
  MODE_OF_PAYMENT_UPDATE_REQUEST,
  MODE_OF_PAYMENT_UPDATE_RESET,
  MODE_OF_PAYMENT_UPDATE_SUCCESS,
} from "../Constants/modeOfPaymentConstants";

export const modeOfPaymentListReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case MODE_OF_PAYMENT_LIST_REQUEST:
      return { loading: true };
    case MODE_OF_PAYMENT_LIST_SUCCESS:
      return { loading: false, modeOfPaymentRecs: payload };
    case MODE_OF_PAYMENT_LIST_FAIL:
      return { loading: false, error: payload };
    default:
      return state;
  }
};

export const modeOfPaymentSaveReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case MODE_OF_PAYMENT_INSERT_REQUEST:
      return { loading: true };
    case MODE_OF_PAYMENT_INSERT_SUCCESS:
      return { loading: false, success: payload };
    case MODE_OF_PAYMENT_INSERT_FAIL:
      return { loading: false, error: payload };
    case MODE_OF_PAYMENT_INSERT_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const modeOfPaymentUpdateReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case MODE_OF_PAYMENT_UPDATE_REQUEST:
      return { loading: true };
    case MODE_OF_PAYMENT_UPDATE_SUCCESS:
      return { loading: false, success: payload };
    case MODE_OF_PAYMENT_UPDATE_FAIL:
      return { loading: false, error: payload };
    case MODE_OF_PAYMENT_UPDATE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const modeOfPaymentDeleteReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case MODE_OF_PAYMENT_DELETE_REQUEST:
      return { loading: true };
    case MODE_OF_PAYMENT_DELETE_SUCCESS:
      return { loading: false, success: payload };
    case MODE_OF_PAYMENT_DELETE_FAIL:
      return { loading: false, error: payload };
    case MODE_OF_PAYMENT_DELETE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};
