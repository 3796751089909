import {
  MARITAL_STATUS_DELETE_FAIL,
  MARITAL_STATUS_DELETE_REQUEST,
  MARITAL_STATUS_DELETE_RESET,
  MARITAL_STATUS_DELETE_SUCCESS,
  MARITAL_STATUS_INSERT_FAIL,
  MARITAL_STATUS_INSERT_REQUEST,
  MARITAL_STATUS_INSERT_RESET,
  MARITAL_STATUS_INSERT_SUCCESS,
  MARITAL_STATUS_LIST_FAIL,
  MARITAL_STATUS_LIST_REQUEST,
  MARITAL_STATUS_LIST_SUCCESS,
  MARITAL_STATUS_UPDATE_FAIL,
  MARITAL_STATUS_UPDATE_REQUEST,
  MARITAL_STATUS_UPDATE_RESET,
  MARITAL_STATUS_UPDATE_SUCCESS,
} from "../Constants/maritalStatusConstants";

export const maritalStatusListReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case MARITAL_STATUS_LIST_REQUEST:
      return { loading: true };
    case MARITAL_STATUS_LIST_SUCCESS:
      return { loading: false, maritalStatusRecs: payload };
    case MARITAL_STATUS_LIST_FAIL:
      return { loading: false, error: payload };
    default:
      return state;
  }
};

export const maritalStatusSaveReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case MARITAL_STATUS_INSERT_REQUEST:
      return { loading: true };
    case MARITAL_STATUS_INSERT_SUCCESS:
      return { loading: false, success: payload };
    case MARITAL_STATUS_INSERT_FAIL:
      return { loading: false, error: payload };
    case MARITAL_STATUS_INSERT_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const maritalStatusUpdateReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case MARITAL_STATUS_UPDATE_REQUEST:
      return { loading: true };
    case MARITAL_STATUS_UPDATE_SUCCESS:
      return { loading: false, success: payload };
    case MARITAL_STATUS_UPDATE_FAIL:
      return { loading: false, error: payload };
    case MARITAL_STATUS_UPDATE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const maritalStatusDeleteReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case MARITAL_STATUS_DELETE_REQUEST:
      return { loading: true };
    case MARITAL_STATUS_DELETE_SUCCESS:
      return { loading: false, success: payload };
    case MARITAL_STATUS_DELETE_FAIL:
      return { loading: false, error: payload };
    case MARITAL_STATUS_DELETE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};
