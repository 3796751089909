import {
  WEBSITE_PAGE_DELETE_FAIL,
  WEBSITE_PAGE_DELETE_REQUEST,
  WEBSITE_PAGE_DELETE_RESET,
  WEBSITE_PAGE_DELETE_SUCCESS,
  WEBSITE_PAGE_INSERT_FAIL,
  WEBSITE_PAGE_INSERT_REQUEST,
  WEBSITE_PAGE_INSERT_RESET,
  WEBSITE_PAGE_INSERT_SUCCESS,
  WEBSITE_PAGE_LIST_FAIL,
  WEBSITE_PAGE_LIST_REQUEST,
  WEBSITE_PAGE_LIST_SUCCESS,
  WEBSITE_PAGE_UPDATE_FAIL,
  WEBSITE_PAGE_UPDATE_REQUEST,
  WEBSITE_PAGE_UPDATE_RESET,
  WEBSITE_PAGE_UPDATE_SUCCESS,
} from "../Constants/websitePageConstants";

export const websitePagesListReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case WEBSITE_PAGE_LIST_REQUEST:
      return { loading: true };
    case WEBSITE_PAGE_LIST_SUCCESS:
      return { loading: false, websitePagesRecs: payload };
    case WEBSITE_PAGE_LIST_FAIL:
      return { loading: false, error: payload };
    default:
      return state;
  }
};

export const websitePagesSaveReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case WEBSITE_PAGE_INSERT_REQUEST:
      return { loading: true };
    case WEBSITE_PAGE_INSERT_SUCCESS:
      return { loading: false, success: payload };
    case WEBSITE_PAGE_INSERT_FAIL:
      return { loading: false, error: payload };
    case WEBSITE_PAGE_INSERT_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const websitePagesUpdateReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case WEBSITE_PAGE_UPDATE_REQUEST:
      return { loading: true };
    case WEBSITE_PAGE_UPDATE_SUCCESS:
      return { loading: false, success: payload };
    case WEBSITE_PAGE_UPDATE_FAIL:
      return { loading: false, error: payload };
    case WEBSITE_PAGE_UPDATE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const websitePagesDeleteReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case WEBSITE_PAGE_DELETE_REQUEST:
      return { loading: true };
    case WEBSITE_PAGE_DELETE_SUCCESS:
      return { loading: false, success: payload };
    case WEBSITE_PAGE_DELETE_FAIL:
      return { loading: false, error: payload };
    case WEBSITE_PAGE_DELETE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};
