import {
  FBTOKENS_INSERT_FAIL,
  FBTOKENS_INSERT_REQUEST,
  FBTOKENS_INSERT_RESET,
  FBTOKENS_INSERT_SUCCESS,
  FBTOKENS_GET_FAIL,
  FBTOKENS_GET_REQUEST,
  FBTOKENS_GET_SUCCESS,
} from "../Constants/fbTokensConstants";

export const fbTokenGetReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case FBTOKENS_GET_REQUEST:
      return { loading: true };
    case FBTOKENS_GET_SUCCESS:
      return { loading: false, fbtokenrec: payload };
    case FBTOKENS_GET_FAIL:
      return { loading: false, error: payload };
    default:
      return state;
  }
};

export const fbTokenSaveReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case FBTOKENS_INSERT_REQUEST:
      return { loading: true };
    case FBTOKENS_INSERT_SUCCESS:
      return { loading: false, success: payload };
    case FBTOKENS_INSERT_FAIL:
      return { loading: false, error: payload };
    case FBTOKENS_INSERT_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};
