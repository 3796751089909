import {
  CALL_LOG_DELETE_FAIL,
  CALL_LOG_DELETE_REQUEST,
  CALL_LOG_DELETE_RESET,
  CALL_LOG_DELETE_SUCCESS,
  CALL_LOG_INSERT_FAIL,
  CALL_LOG_INSERT_REQUEST,
  CALL_LOG_INSERT_RESET,
  CALL_LOG_INSERT_SUCCESS,
  CALL_LOG_CONTACT_LIST_FAIL,
  CALL_LOG_CONTACT_LIST_REQUEST,
  CALL_LOG_CONTACT_LIST_SUCCESS,
  CALL_LOG_DEAL_LIST_FAIL,
  CALL_LOG_DEAL_LIST_REQUEST,
  CALL_LOG_DEAL_LIST_SUCCESS,
  CALL_LOG_UPDATE_FAIL,
  CALL_LOG_UPDATE_REQUEST,
  CALL_LOG_UPDATE_RESET,
  CALL_LOG_UPDATE_SUCCESS,
  CALL_LOG_SUBAGENT_LIST_REQUEST,
  CALL_LOG_SUBAGENT_LIST_SUCCESS,
  CALL_LOG_SUBAGENT_LIST_FAIL,
} from "../Constants/callLogConstants";

export const callLogContactListReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case CALL_LOG_CONTACT_LIST_REQUEST:
      return { loading: true };
    case CALL_LOG_CONTACT_LIST_SUCCESS:
      return { loading: false, callLogContactRec: payload };
    case CALL_LOG_CONTACT_LIST_FAIL:
      return { loading: false, error: payload };
    default:
      return state;
  }
};

export const callLogDealListReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case CALL_LOG_DEAL_LIST_REQUEST:
      return { loading: true };
    case CALL_LOG_DEAL_LIST_SUCCESS:
      return { loading: false, callLogDealRec: payload };
    case CALL_LOG_DEAL_LIST_FAIL:
      return { loading: false, error: payload };
    default:
      return state;
  }
};

export const callLogSubAgentListReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case CALL_LOG_SUBAGENT_LIST_REQUEST:
      return { loading: true };
    case CALL_LOG_SUBAGENT_LIST_SUCCESS:
      return { loading: false, callLogSubAgentRec: payload };
    case CALL_LOG_SUBAGENT_LIST_FAIL:
      return { loading: false, error: payload };
    default:
      return state;
  }
};

export const callLogSaveReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case CALL_LOG_INSERT_REQUEST:
      return { loading: true };
    case CALL_LOG_INSERT_SUCCESS:
      return { loading: false, success: payload };
    case CALL_LOG_INSERT_FAIL:
      return { loading: false, error: payload };
    case CALL_LOG_INSERT_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const callLogUpdateReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case CALL_LOG_UPDATE_REQUEST:
      return { loading: true };
    case CALL_LOG_UPDATE_SUCCESS:
      return { loading: false, success: payload };
    case CALL_LOG_UPDATE_FAIL:
      return { loading: false, error: payload };
    case CALL_LOG_UPDATE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};

export const callLogDeleteReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case CALL_LOG_DELETE_REQUEST:
      return { loading: true };
    case CALL_LOG_DELETE_SUCCESS:
      return { loading: false, success: payload };
    case CALL_LOG_DELETE_FAIL:
      return { loading: false, error: payload };
    case CALL_LOG_DELETE_RESET:
      return { loading: false, error: "", success: "" };
    default:
      return state;
  }
};
